import { gql } from '@apollo/client/core'

const RESPONSE_OPTION = gql`
  fragment ResponseOption on ResponseOption {
    responseOptionId
    rotype
    value
    createdDate
  }
`

export default RESPONSE_OPTION
