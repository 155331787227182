import { useState } from 'react'

/**
 * Wrapper for useState which resets back to defaultValue whenever it changes –
 * makes having state with default values based on props more ergonomic
 *
 * @warning defaultValue must be stable or this will infinite re-render
 */
export function useDefaultState<T>(defaultValue: T) {
  const [prevValue, setPrevValue] = useState(defaultValue)
  const [currentValue, setCurrentValue] = useState(defaultValue)

  if (prevValue !== defaultValue) {
    setPrevValue(defaultValue)
    setCurrentValue(defaultValue)
  }

  return [currentValue, setCurrentValue] as const
}
