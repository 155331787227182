import { gql } from '@apollo/client/core'
import DRAFT_AUDIENCE_MEMBER from './draftAudienceMember'

export const AUDIENCE_QUESTION = gql`
  fragment AudienceQuestion on Question {
    questionId
    text
    createdDate
  }
`

const DRAFT_AUDIENCE = gql`
  fragment DraftAudience on DraftFieldworkAudience {
    surveyId
    createdDate
    sinceDate
    members {
      ...DraftAudienceMember
    }
    validationErrors {
      __typename
      ...CustomCriterionValidationErrors
      ...StandardCriterionValidationErrors
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`

export default DRAFT_AUDIENCE
