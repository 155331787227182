import { useAppDispatch } from '../../../App.store'
import { useUpdateResponseOption } from '../BasicQuestion/ResponseOptions/ResponseOptions.hooks'
import { useUpdateMatrixRow } from '../MatrixQuestion/MatrixQuestionRows/MatrixQuestionRows.hooks'
import {
  matrixRowPinToggled,
  responseOptionPinToggled
} from '../Questionnaire.slice'

export const useSetPinnedMatrixRow = (entryId: string) => {
  const dispatch = useAppDispatch()
  const updateMatrixRow = useUpdateMatrixRow(entryId, {
    fetchPolicy: 'no-cache'
  })

  const setPinnedMatrixRow = (
    matrixTitleLk: string,
    questionLk: string,
    isPinned: boolean
  ): void => {
    dispatch(
      matrixRowPinToggled({
        matrixTitleLk,
        questionLk,
        pinned: isPinned
      })
    )

    updateMatrixRow({
      questionId: questionLk,
      pinned: isPinned
    })
  }

  return setPinnedMatrixRow
}

export const usePinResponseOption = (entryId: string) => {
  const dispatch = useAppDispatch()
  const updateResponseOption = useUpdateResponseOption(entryId)
  const pinResponseOption = (
    responseOptionLk: string,
    isPinned: boolean
  ): void => {
    dispatch(
      responseOptionPinToggled({
        questionLk: entryId,
        responseOptionLk,
        pinned: isPinned
      })
    )

    updateResponseOption({
      responseOptionId: responseOptionLk,
      pinned: isPinned
    })
  }

  return pinResponseOption
}
