import { Theme, makeStyles } from '@focaldata/cin-ui-components'

interface StyleProps {
  maxWidth?: string | number
  height?: string | number
}

const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    root: {
      maxWidth: props.maxWidth,
      height: props.height
    },
    leftChild: {
      height: '100%'
    },
    rightChild: {
      height: '100%'
    },
    spaceBetweenChildren: {
      marginLeft: theme.spacing(2.25),
      height: '100%'
    },
    divider: {
      height: theme.spacing(4)
    }
  })
)

export default useStyles
