import { StoreObject } from '@apollo/client'
import { useCallback } from 'react'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import { EntrySettingValue } from '../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useUpdateQuestionnaireEntry } from './useUpdateQuestionnaireEntry'

const useRemoveQuestionnaireSetting = (entry: DraftQuestionnaireEntry) => {
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id)

  const removeQuestionnaireSetting = useCallback(
    async (
      questionSettingCodes: (QuestionSettingCode | string)[],
      fetchPolicy = 'network-only'
    ) => {
      if (!('settingValues' in entry.entryItem)) {
        throw new Error('Cannot change settings as missing settingValues')
      }
      await updateQuestionnaireEntry(
        {
          settingValues: entry.entryItem.settingValues
            .map((s) => ({
              settingValue: s.value,
              questionSettingCode: s.code
            }))
            .filter(
              (s) => !questionSettingCodes.includes(s.questionSettingCode)
            )
        },
        {
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'removeQuestionnaireSettingValue',
              error
            )
          },
          onCompleted: () => {
            questionBeingEditedId(entry.id)
          },
          update:
            fetchPolicy === 'no-cache'
              ? undefined
              : (cache) => {
                  const cacheId = cache.identify(
                    entry.entryItem as unknown as StoreObject
                  )
                  cache.modify({
                    id: cacheId,
                    fields: {
                      settingValues(settingValues) {
                        return settingValues.filter(
                          (setting: EntrySettingValue) =>
                            !questionSettingCodes.includes(setting.code)
                        )
                      }
                    }
                  })
                }
        }
      )
    },
    [entry.entryItem, entry.id, updateQuestionnaireEntry]
  )

  return removeQuestionnaireSetting
}
export default useRemoveQuestionnaireSetting
