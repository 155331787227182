import { useFragment } from '@apollo/client'
import { SECTION_ITEM_LOOPING_CONFIG } from '../../../hooks/questionnaire/loopingConfig.questionnaire.gql'

const useLoopingConfigData = (sectionId: string | undefined) => {
  const loopingConfigFragment = useFragment({
    fragment: SECTION_ITEM_LOOPING_CONFIG,
    fragmentName: 'SectionItemLoopingConfig',
    from: {
      __typename: 'DraftSectionItem',
      /**
       * useFragment does not support `skip`, but a warning can be logged if this
       * isn't defined, so we put a fake value in
       *
       * @see https://github.com/apollographql/apollo-feature-requests/issues/350
       */
      sectionId: sectionId ?? 'DOES_NOT_EXIST'
    },
    canonizeResults: true
  })

  return loopingConfigFragment.complete
    ? {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        loopingConfig: loopingConfigFragment.data?.loopingConfig,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        isLoopingEnabled: loopingConfigFragment.data?.isLoopingEnabled
      }
    : null
}

export default useLoopingConfigData
