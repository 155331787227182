import {
  Close,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LinkButton,
  TextHighlight,
  TextSize,
  TextWeight,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './RandomiseSectionsHeader.styles'

interface Props {
  hasRandomisedSections?: boolean
  outputText: string
  tooltipText: string
  onClickRandomiseSections: () => void
  onClickRemoveRandomisation: () => void
}

export const RandomiseSectionsHeaderComponent: React.FC<Props> = ({
  hasRandomisedSections,
  outputText,
  tooltipText,
  onClickRandomiseSections,
  onClickRemoveRandomisation
}: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <div className="fd-grid fd-container fd-align-items-center">
      <div className="fd-grid fd-item fd-xs-9">
        {hasRandomisedSections ? (
          <div className="fd-grid fd-container">
            <div className="fd-grid fd-item fd-xs-1">
              <Icon
                name={IconName.Shuffle}
                iconColor={IconColor.Text}
                size={IconSize.Medium}
                className={classes.randomiseSectionsIcon}
              />
            </div>
            <div className="fd-grid fd-item fd-xs-11">
              <Tooltip
                title={tooltipText}
                tipPlacement={TipPlacement.BottomStart}
              >
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeMs,
                    textClasses.highlightStandard,
                    textClasses.marginLeft
                  )}
                >
                  {outputText}
                </p>
              </Tooltip>
            </div>
          </div>
        ) : (
          <LinkButton
            highlight={TextHighlight.Emphasis}
            size={TextSize.ms}
            weight={TextWeight.SemiBold}
            onClick={onClickRandomiseSections}
            noPadding
          >
            Randomise sections
          </LinkButton>
        )}
      </div>
      {hasRandomisedSections && (
        <>
          <div className="fd-grid fd-item fd-xs-2">
            <LinkButton
              highlight={TextHighlight.Emphasis}
              size={TextSize.s}
              weight={TextWeight.Regular}
              onClick={onClickRandomiseSections}
              noPadding
            >
              Edit
            </LinkButton>
          </div>
          <div
            className={classNames(
              'fd-grid fd-item fd-xs-1',
              classes.removeRandomisationButton
            )}
          >
            <Tooltip
              title="Cancel randomisation"
              tipPlacement={TipPlacement.BottomStart}
            >
              <LinkButton
                highlight={TextHighlight.Emphasis}
                size={TextSize.s}
                weight={TextWeight.Regular}
                onClick={onClickRemoveRandomisation}
                noPadding
              >
                <Close />
              </LinkButton>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}
