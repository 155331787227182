import { ApolloCache, Reference } from '@apollo/client'
import {
  DraftMatrixRow,
  EntryType
} from '../../../../data/gql-gen/questionnaire/graphql'
import DRAFT_MATRIX_ROW from '../../../../data/gql/questionnaire/fragments/draftMatrixRow'
import { DraftQuestionnaireEntry } from '../../../../data/model/questionnaire'

const incrementMatrixRowPosition = (row: DraftMatrixRow | null) => {
  if (row) {
    return {
      ...row,
      position: row.position + 1
    }
  }
}

const incrementRowPositionInCacheFactory = (cache: ApolloCache<object>) => {
  return function incrementRowPositionInCache(rowRef: Reference) {
    const rowId = cache.identify(rowRef)
    if (rowId) {
      cache.updateFragment<DraftMatrixRow>(
        {
          id: rowId,
          fragmentName: 'DraftMatrixRow',
          fragment: DRAFT_MATRIX_ROW
        },
        incrementMatrixRowPosition
      )
    }
  }
}

export const modifyMatrixRowsCache = (
  cache: ApolloCache<object>,
  entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>,
  matrixRow: DraftMatrixRow
) => {
  const entryId = cache.identify(entry.entryItem)
  const newRowPosition = matrixRow.position

  if (!entryId) {
    return
  }

  const incrementRowPositionInCache = incrementRowPositionInCacheFactory(cache)

  cache.modify<{ matrixRows: Reference[] }>({
    id: entryId,
    fields: {
      matrixRows(existingRowsRefs) {
        const newRowRef = cache.writeFragment<DraftMatrixRow>({
          data: matrixRow,
          fragment: DRAFT_MATRIX_ROW,
          fragmentName: 'DraftMatrixRow'
        })

        if (!newRowRef) {
          return existingRowsRefs
        }

        const newRowsRefs = existingRowsRefs.toSpliced(
          newRowPosition,
          0,
          newRowRef
        )

        const newRowNextSiblings = newRowsRefs.slice(newRowPosition + 1)
        newRowNextSiblings.forEach(incrementRowPositionInCache)

        return newRowsRefs
      }
    }
  })
}
