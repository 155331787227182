import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  resultsText: {
    paddingRight: theme.spacing(4)
  },
  requestData: {
    padding: theme.spacing(0, 1, 0, 2),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter
    })
  },
  kebabMenu: {
    paddingRight: theme.spacing(1)
  },
  rightContainer1: {
    marginRight: `-${theme.spacing(1)}`
  },
  rightContainer3: {
    marginRight: `-${theme.spacing(3)}`
  },
  fullLaunch: {
    padding: theme.spacing(0, 1, 0, 2),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter
    })
  }
}))

export default useStyles
