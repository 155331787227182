import { MutationHookOptions, useMutation } from '@apollo/client'
import { RemoveQuestionnaireEntriesV2Mutation } from '../../data/gql-gen/questionnaire/graphql'
import { REMOVE_QUESTIONNAIRE_ENTRIES } from '../../data/gql/questionnaire/mutations/removeQuestionnaireEntries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useRemoveQuestionnaireEntries = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [removeQuestionnaireEntryMutation] =
    useMutation<RemoveQuestionnaireEntriesV2Mutation>(
      REMOVE_QUESTIONNAIRE_ENTRIES,
      {
        context: { clientName: 'questionnaire' }
      }
    )

  const removeQuestionnaireEntries = (
    entryIds: string[],
    options?: MutationHookOptions
  ) => {
    return removeQuestionnaireEntryMutation({
      variables: {
        input: {
          projectId,
          surveyId,
          entryIds
        }
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'detachQuestionnaireEntry',
          error
        )
      },
      ...options
    })
  }

  return removeQuestionnaireEntries
}
