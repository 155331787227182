import {
  Button,
  Grid,
  LinkButton,
  Text,
  TextAlign,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { ChartDataType } from '../../../FdChat.model'
import BarChart from '../BarChart'
import InfoCard from '../InfoCard'
import Markdown from '../Markdown'

const ResearchQuestionCard = ({
  direction = 'row',
  captionIcon,
  caption,
  title,
  description,
  chartData,
  linkText,
  handleLinkClick,
  primaryButtonText,
  handlePrimaryButtonClick
}: {
  direction?: 'row' | 'row-reverse'
  captionIcon: string
  caption: string
  title: string
  description: string
  chartData: ChartDataType
  linkText?: string
  handleLinkClick?: () => void
  primaryButtonText?: string
  handlePrimaryButtonClick?: () => void
}) => {
  const showLink = linkText && handleLinkClick
  const showPrimaryButton = primaryButtonText && handlePrimaryButtonClick

  return (
    <InfoCard component="button">
      <Grid
        container
        spacing={'60px'}
        direction={direction}
        alignItems={'center'}
      >
        <Grid container direction={'column'} gap={'14px'} item lg={6}>
          <Grid container alignItems={'center'} gap={'10px'}>
            <img src={captionIcon} height={'32px'} width={'32px'} alt="" />
            <Text size={TextSize.ms} align={TextAlign.Left}>
              {caption}
            </Text>
          </Grid>
          <Text
            size={TextSize.xl}
            weight={TextWeight.Bold}
            align={TextAlign.Left}
          >
            {title}
          </Text>
          <Markdown>{description}</Markdown>
          <Grid container gap={'20px'} alignItems={'center'} marginTop={'10px'}>
            {showLink && (
              <LinkButton
                highlight={TextHighlight.Primary}
                onClick={handleLinkClick}
                noPadding
              >
                {linkText}
              </LinkButton>
            )}
            {showPrimaryButton && (
              <Button
                style={{ width: 'max-content' }}
                onClick={handlePrimaryButtonClick}
              >
                {primaryButtonText}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container item lg={6} alignContent={'flex-start'} gap={'1em'}>
          <BarChart {...chartData} />
        </Grid>
      </Grid>
    </InfoCard>
  )
}

export default ResearchQuestionCard
