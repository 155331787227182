import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import { SurveyOrderStatus } from '../../../data/gql-gen/dashboard/graphql'
import { UpdateSurveyOrderData } from '../../../data/gql/order/mutations/updateSurveyOrder'
import { SurveyOrder } from '../../../data/model/order'

export const PauseOrderCacheUpdate: (
  cache: ApolloCache<UpdateSurveyOrderData>,
  isLive: boolean
) => void = (cache, isLive) => {
  cache.modify({
    fields: {
      surveyOrder(existing) {
        return produce(existing, (draft: SurveyOrder) => {
          draft.status = isLive
            ? SurveyOrderStatus.Paused
            : SurveyOrderStatus.Live
        })
      }
    }
  })
}
