import { additionalPalette, theme } from '@focaldata/cin-ui-components'
import { useTheme } from '@mui/material'
import React from 'react'
import ReactWordcloud, { Optional, Options } from 'react-wordcloud'
import { Word, WordCloudChartData } from '../Chart.model'

export interface Props extends React.HTMLAttributes<HTMLElement> {
  data: WordCloudChartData
}

const createTooltip = (word: string | null, value?: number) => {
  const tooltipEl = document.createElement('div')

  Object.assign(tooltipEl.style, {
    'background-color': 'rgba(0,0,0,0.8)',
    'border-radius': '6px',
    padding: '6px',
    opacity: 1,
    'font-family': theme.typography.fontFamily
  })

  const span = document.createElement('span')

  Object.assign(span.style, {
    color: '#fff'
  })
  span.append(`${word} (${value})`)
  tooltipEl.append(span)

  return tooltipEl
}

const WordCloudChart: React.FC<Props> = (props: Props) => {
  const { data }: Props = props
  const theme = useTheme()

  const createTooltipContent = (word: Word) => {
    return createTooltip(word.text, word.value)
  }

  const wordCloudOptions: Optional<Options> = {
    fontFamily: theme.typography.fontFamily,
    fontSizes: [12, 64],
    colors: [additionalPalette.liveBlue.main, additionalPalette.darkBlue.main],
    rotations: 0,
    padding: 5,
    deterministic: true
  }

  return (
    <ReactWordcloud
      callbacks={{ getWordTooltip: createTooltipContent }}
      options={wordCloudOptions}
      words={data.words}
    />
  )
}

export default WordCloudChart
