import {
  Text,
  TextAlign,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'

interface Props {
  text: string
}

export const QuestionsSelectorCounter = ({ text }: Props): JSX.Element => {
  return (
    <Text
      highlight={TextHighlight.Primary}
      size={TextSize.ms}
      align={TextAlign.Center}
      marginLeft
      marginRight
    >
      {text}
    </Text>
  )
}
