import { useFragment } from '@apollo/client'
import DRAFT_QUESTIONNAIRE_ENTRY from '../../data/gql/questionnaire/fragments/draftQuestionnaireEntry'
import { DraftQuestionnaireEntry } from '../../data/model/questionnaire'

export const useDraftQuestionnaireEntry = (entryId: string) => {
  const { data, complete } = useFragment<DraftQuestionnaireEntry>({
    fragment: DRAFT_QUESTIONNAIRE_ENTRY,
    fragmentName: 'DraftQuestionnaireEntry',
    from: { __typename: 'DraftQuestionnaireEntry', id: entryId },
    canonizeResults: true
  })

  return complete ? data : undefined
}
