import { gql } from '@apollo/client/core'

export const RESPONSE_OPTIONS_LOGIC = gql`
  fragment ResponseOptionsLogic on ResponseOptionsLogic {
    maxResponseOptionsToDisplay
    exemptResponseOptionIds
    condition {
      ... on LoopQuotaPriority {
        sectionId
        responseOptionLoopSourcePairs {
          responseOptionId
          loopSourceId
        }
      }
      __typename
    }
  }
`
