import {
  InfoIcon,
  InfoIconColor,
  Input,
  InputType,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo, useState } from 'react'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import {
  DraftQuestionItem,
  SliderValidationError
} from '../../../data/model/questionnaire'
import { ValidationResult } from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import { NumberFormatSliderIncrement } from '../../../utils/NumberFormatCustom'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import useStyles from './SliderQuestion.styles'

interface Props extends WithEntry {
  onChangeNumbers?: (
    minNumber: number,
    maxNumber: number,
    stepNumber: number
  ) => void
  validationError: ValidationResult
}

const SliderQuestionControl: React.FC<Props> = (props: Props) => {
  const { entry, onChangeNumbers, validationError } = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const defaultLowValue =
    (entry.entryItem as DraftQuestionItem).questionScale?.range.min || 0
  const defaultHighValue =
    (entry.entryItem as DraftQuestionItem).questionScale?.range.max || 100
  const defaultIncrementValue =
    (entry.entryItem as DraftQuestionItem).questionScale?.range.step || 1
  const [lowestNumber, setLowestNumber] = useState<number>(defaultLowValue)
  const [highestNumber, setHighestNumber] = useState<number>(defaultHighValue)
  const [stepNumber, setStepNumber] = useState<number>(defaultIncrementValue)

  useDebounceEffect<number>(
    () => {
      if (onChangeNumbers && lowestNumber !== defaultLowValue) {
        onChangeNumbers(lowestNumber, highestNumber, stepNumber)
      }
    },
    lowestNumber,
    { delay: 500 }
  )

  useDebounceEffect<number>(
    () => {
      if (onChangeNumbers && highestNumber !== defaultHighValue) {
        onChangeNumbers(lowestNumber, highestNumber, stepNumber)
      }
    },
    highestNumber,
    { delay: 500 }
  )

  useDebounceEffect<number | undefined>(
    () => {
      if (onChangeNumbers && stepNumber !== defaultIncrementValue) {
        onChangeNumbers(lowestNumber, highestNumber, stepNumber)
      }
    },
    stepNumber,
    { delay: 500 }
  )

  const hasRangeError =
    validationError.hasError &&
    validationError.errorType === SliderValidationError.SliderInvalidRangeError
  const hasStepError =
    validationError.hasError &&
    validationError.errorType === SliderValidationError.SliderInvalidStepError

  return (
    <div className="fd-grid fd-container fd-sm-12 fd-xl-6 fd-md-9">
      <div
        className={classNames(
          'fd-grid fd-item fd-column fd-xs-4',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Lowest number
        </p>
        <Input
          inputType={InputType.Number}
          isError={hasRangeError}
          helperText=""
          defaultValue={defaultLowValue}
          onChange={(event) => {
            if (onChangeNumbers) {
              setLowestNumber(parseInt(event.target.value, 10))
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
      <div
        className={classNames(
          'fd-grid fd-item fd-column fd-xs-4',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Highest number
        </p>
        <Input
          inputType={InputType.Number}
          isError={hasRangeError}
          helperText=""
          defaultValue={defaultHighValue}
          onChange={(event) => {
            if (onChangeNumbers) {
              setHighestNumber(parseInt(event.target.value, 10))
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
      <div
        className={classNames(
          'fd-grid fd-item fd-column fd-xs-2',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Increment
        </p>
        <Input
          defaultValue={defaultIncrementValue}
          isError={hasStepError}
          helperText=""
          customFormat={NumberFormatSliderIncrement}
          onChange={(event) => {
            if (onChangeNumbers) {
              setStepNumber(parseFloat(event.target.value))
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
      <div className={classNames('fd-grid', classes.errorContainer)}>
        {validationError.hasError && (
          <InfoIcon
            style={{ paddingTop: '40px' }}
            iconColor={InfoIconColor.Error}
            text={validationError.errorMessage || ''}
          />
        )}
      </div>
    </div>
  )
}

export default memo(SliderQuestionControl, propsAreEqual)
