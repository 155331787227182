import {
  AskAIAnswerResponseInput,
  AskAICitationInput
} from '@focaldata/askai-client'
import { useEffect, useState } from 'react'
import { LogAmplitudeEvent } from '../../../../../../../amplitude'
import { EventType } from '../../../../../../../amplitude/eventType'
import { useSurveyId } from '../../../../../../../hooks/useSurveyId'
import { useAskAiV3 } from '../../../../../../../modules/FdChat/Answers/Answers.hooks'
import { useAskAiDrawer } from '../../../../../../../modules/FdChat/AskAi/AskAi.hooks'
import AiAssistantCard from '../AiAssistantCard/AiAssistantCard'
import SourcesCard from '../SourcesCard/SourcesCard'
import styles from './Answer.module.css'

const Answer = ({
  question,
  label,
  onFeedbackClick
}: {
  question: string
  label?: string
  onFeedbackClick: ({ answer }: { answer: AskAIAnswerResponseInput }) => void
}) => {
  const { answer, loading, error } = useAskAiV3({ question })
  const [aiAssistantLoading, setAiAssistantLoading] = useState(true)
  const surveyId = useSurveyId()
  const { goToSources, goToTranscript } = useAskAiDrawer()

  // once the sources have loaded, continue loading the ai assistant for 2 seconds
  useEffect(() => {
    if (!loading) {
      if (!!error) {
        setAiAssistantLoading(false)
      } else {
        const timeout = setTimeout(() => {
          setAiAssistantLoading(false)
        }, 2000)
        return () => clearTimeout(timeout)
      }
    }
  }, [loading, error])

  const onSourcesCardClick = () => {
    LogAmplitudeEvent(EventType.ClickedFdChatAskAiSourcesCard, {
      surveyId
    })
    if (answer?.answer_id) {
      goToSources({ answerId: answer.answer_id, answer })
    }
  }

  const onCitationClick = ({ citation }: { citation: AskAICitationInput }) => {
    LogAmplitudeEvent(EventType.ClickedFdChatAskAiCitation, {
      surveyId
    })
    if (answer?.answer_id) {
      goToTranscript({
        respondentId: citation.respondent.focaldata_respondent_id,
        // @ts-expect-error can remove this comment once askai-client has been updated to return Output types instead of Input types
        interactionId: citation.interaction.interaction_id,
        answerId: answer.answer_id,
        answer
      })
    }
  }

  return (
    <div className={styles.answer}>
      <h1 className={styles.question}>{label ?? question}</h1>
      <SourcesCard
        loading={loading}
        hasError={!!error}
        onSourcesCardClick={onSourcesCardClick}
        sources={answer?.answer.source_references}
      />
      <AiAssistantCard
        summaryResponse={answer?.answer.response.summary_response}
        subPoints={answer?.answer.response.subpoints}
        loading={aiAssistantLoading}
        hasError={!!error}
        onCitationClick={onCitationClick}
        onFeedbackClick={() => {
          if (answer) {
            onFeedbackClick({ answer })
          }
        }}
      />
    </div>
  )
}

export default Answer
