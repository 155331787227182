import { useContext, useEffect } from 'react'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceContext, {
  setAudienceMembers,
  setAudienceValidationErrors
} from '../Audience.context'
import { useAddCustomAudience } from '../Audience.hooks'
import { MAX_AUDIENCE_COUNT } from '../AudienceCount/AudienceCount.control'
import SurveyAudienceListControl from './SurveyAudienceList.control'
import SurveyAudienceListLoading from './SurveyAudienceListLoadingLoading'

const SurveyAudienceList = ({ height }: { height?: string }) => {
  const { dispatch, audienceState } = useContext(AudienceContext)
  const { isFdChat } = useProjectType()
  const { addCustomAudience } = useAddCustomAudience()

  const { draftAudience, loading, error } = useGetDraftAudience()

  useEffect(() => {
    if (draftAudience) {
      dispatch(setAudienceMembers(draftAudience.members))
      dispatch(setAudienceValidationErrors(draftAudience.validationErrors))
    }
  }, [dispatch, draftAudience])

  if (error) return <div>Error loading custom audiences</div>
  if (loading || !draftAudience) return <SurveyAudienceListLoading />

  return (
    <SurveyAudienceListControl
      audiences={audienceState.audienceMembers}
      height={height}
      emptyTitle={
        isFdChat ? 'Add audience questions and screening criteria' : undefined
      }
      emptyDescription={
        isFdChat
          ? 'Add a new question from the left-hand side and use the qualifying toggle if you want to screen certain respondents out. Any questions created will be asked as audience survey questions before the interview.'
          : undefined
      }
      onAddCustomAudience={isFdChat ? addCustomAudience : undefined}
      disableAddCustomAudience={
        isFdChat && draftAudience.members.length >= MAX_AUDIENCE_COUNT
      }
    />
  )
}

export default SurveyAudienceList
