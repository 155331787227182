import { gql } from '@apollo/client/core'
import RESPONSE_OPTION from './responseOption'

const DRAFT_STANDARD_CRITERION_OPTION = gql`
  fragment DraftStandardCriterionOption on StandardAudienceCriterionOption {
    criterionOption {
      code
      responseOption {
        ...ResponseOption
      }
      createdDate
      sinceDate
    }
    position
    state
    qualification {
      createdDate
      sinceDate
    }
    quota {
      percent
      createdDate
      sinceDate
    }
  }
  ${RESPONSE_OPTION}
`

export default DRAFT_STANDARD_CRITERION_OPTION
