import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionTypeCode
} from '../../../data/model/questionnaire'
import { BasicQuestionContainer } from '../BasicQuestion'
import { ForkContainer } from '../Fork'
import { FreeTextQuestionContainer } from '../FreeTextQuestion'
import { MatrixQuestionContainer } from '../MatrixQuestion'
import MaxDiffQuestionContainer from '../MaxDiffQuestion/MaxDiffQuestion.container'
import { SliderQuestionContainer } from '../SliderQuestion'
import { TextCardContainer } from '../TextCard'

export const getEntryCard = (
  entry: DraftQuestionnaireEntry,
  newlyAddedEntryNumber?: number
) => {
  const isNewlyCreated = newlyAddedEntryNumber === entry.number
  const { entryItem, entryType } = entry

  switch (entryType) {
    case EntryType.QuestionEntryType:
      switch (entryItem.questionTypeCode) {
        case QuestionTypeCode.Basic:
        case QuestionTypeCode.Ranked:
          return (
            <BasicQuestionContainer
              key={entryItem.questionLk}
              entry={entry}
              shouldTitleInputFocus={isNewlyCreated}
            />
          )
        case QuestionTypeCode.FreeText:
          return (
            <FreeTextQuestionContainer
              key={entryItem.questionLk}
              entry={entry}
              shouldTitleInputFocus={isNewlyCreated}
            />
          )
        case QuestionTypeCode.Scale:
          return (
            <SliderQuestionContainer
              key={entryItem.questionLk}
              entry={entry}
              shouldTitleInputFocus={isNewlyCreated}
            />
          )
        case QuestionTypeCode.MaxDiff:
          return (
            <MaxDiffQuestionContainer
              key={entryItem.questionLk}
              entry={entry}
              shouldTitleInputFocus={isNewlyCreated}
            />
          )
      }
      break
    case EntryType.TextCardEntryType:
      return (
        <TextCardContainer
          key={entryItem.textCardLk}
          entry={entry}
          shouldTitleInputFocus={isNewlyCreated}
        />
      )
    case EntryType.MatrixEntryType:
      return (
        <MatrixQuestionContainer
          key={entryItem.matrixTitleLk}
          entry={entry}
          shouldTitleInputFocus={isNewlyCreated}
        />
      )
    case EntryType.ForkEntryType:
      return <ForkContainer entry={entry} />
  }

  return null
}
