import { useMutation } from '@apollo/client'
import { ClipboardEvent } from 'react'
import {
  PasteAudienceMemberResponseOptionValuesV2Mutation,
  PasteAudienceMemberResponseOptionValuesV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import { PASTE_AUDIENCE_RESPONSE_OPTION_VALUES_V2 } from '../../data/gql/questionnaire/mutations/pasteAudienceResponseOptionValuesV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import { DraftCustomAudience } from '../../data/model/audience'
import { captureApolloError } from '../../utils/HelperFunctions'
import {
  replacingTextAt,
  splitTextIntoLinesAndCleanup
} from '../copyPaste/utils'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'

export const usePasteCustomCriterionOptionV2 = (
  questionId: string,
  position: number
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { draftAudience } = useGetDraftAudience()

  const [pasteCustomCriterionOptionV2] = useMutation<
    PasteAudienceMemberResponseOptionValuesV2Mutation,
    PasteAudienceMemberResponseOptionValuesV2MutationVariables
  >(PASTE_AUDIENCE_RESPONSE_OPTION_VALUES_V2, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'PasteAudienceResponseOptionValuesV2',
        error
      )
    }
  })

  return (event: ClipboardEvent<HTMLInputElement>) => {
    const { criterionOptions } = draftAudience?.members.find(
      (m) => m.id === questionId
    )?.memberItem as DraftCustomAudience

    const text = event.clipboardData.getData('text')
    const { selectionStart, selectionEnd } = event.target as HTMLTextAreaElement

    const responseOptionValues = splitTextIntoLinesAndCleanup(text)
    const responseOptionToBeUpdated = criterionOptions[position].responseOption

    responseOptionValues[0] = replacingTextAt(
      responseOptionToBeUpdated.value,
      responseOptionValues[0],
      selectionStart,
      selectionEnd
    )

    return pasteCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId: questionId,
          position,
          responseOptionValues
        }
      }
    })
  }
}
