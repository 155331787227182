import { useQuery } from '@apollo/client'

import { GET_COUNTRIES_V2 } from '../../data/gql/questionnaire/queries/getCountries'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

export const useAudienceCountries = () => {
  const { data, loading, error } = useQuery(GET_COUNTRIES_V2, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'countries', error)
    }
  })

  return { data: data?.getCountriesV2, loading, error }
}
