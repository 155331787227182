import {
  IconButton,
  IconColor,
  IconName,
  IconSize,
  MuiMenu,
  MuiMenuItem,
  Text,
  TextHighlight,
  TextSize,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'

interface Props {
  onRemoveForceDisplay: () => void
}

const ForceDisplay: React.FC<Props> = ({ onRemoveForceDisplay }: Props) => {
  const [forceDisplayAnchorEl, setForceDisplayAnchorEl] =
    useState<null | HTMLElement>(null)

  const handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void = (
    event
  ) => {
    setForceDisplayAnchorEl(event.currentTarget)

    return false
  }

  const handleCloseForceDisplayMenu: () => void = () => {
    setForceDisplayAnchorEl(null)

    return false
  }

  const handleDisableForceDisplay = () => {
    setForceDisplayAnchorEl(null)
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    onRemoveForceDisplay?.()
  }

  return (
    <Tooltip title="Force display" tipPlacement={TipPlacement.Bottom}>
      <>
        <IconButton
          smallHoverShadowSize
          iconSize={IconSize.Large}
          iconName={IconName.Visibility}
          iconColor={IconColor.Background}
          onClick={handleOpenMenu}
        />
        <MuiMenu
          elevation={1}
          anchorEl={forceDisplayAnchorEl}
          open={Boolean(forceDisplayAnchorEl)}
          onClose={handleCloseForceDisplayMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MuiMenuItem onClick={handleDisableForceDisplay}>
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Remove force display
            </Text>
          </MuiMenuItem>
        </MuiMenu>
      </>
    </Tooltip>
  )
}

export default ForceDisplay
