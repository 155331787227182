import { useReactiveVar } from '@apollo/client'
import { List } from '@focaldata/cin-ui-components'
import { memo, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { emptyArr } from '../../../../constants/misc'
import {
  DraftEntryResponseOption,
  EntryType
} from '../../../../data/gql-gen/questionnaire/graphql'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import {
  WithEntryOfType,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import {
  useCreateResponseOption,
  useUpdateResponseOption
} from '../../BasicQuestion/ResponseOptions/ResponseOptions.hooks'
import {
  responseOptionAdded,
  responseOptionsReoredered,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import MatrixResponseOption from './MatrixResponseOption'

const MatrixResponseOptions = (
  props: WithEntryOfType<EntryType.MatrixEntryType>
) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const dispatch = useAppDispatch()
  const { id: entryId } = props.entry
  const createResponseOption = useCreateResponseOption(entryId)
  const updateResponseOption = useUpdateResponseOption(entryId)
  const matrixResponseOptions =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOptionsByQuestion[entryId] ?? emptyArr
  const { validateMatrixColumnText } = useQuestionnaireValidation()

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()

  const flatEntries = flattenEntries(draftQuestionnaireEntries)
  const maskingEnabled = flatEntries.length > 0 && entryId !== flatEntries[0].id

  const handleAddMatrixResponseOption = (newPosition: number) => {
    dispatch(
      responseOptionAdded({
        questionLk: entryId,
        position: newPosition
      })
    )
    createResponseOption(newPosition)
  }

  const handleOnReorderedMatrixResponseOptions: (
    destinationIndex: number,
    sourceIndex: number
  ) => void = useCallback(
    (destinationIndex, sourceIndex) => {
      dispatch(
        responseOptionsReoredered({
          questionLk: entryId,
          destinationIndex,
          sourceIndex
        })
      )
      updateResponseOption({
        responseOptionId: matrixResponseOptions[sourceIndex].responseOptionLk,
        position: destinationIndex
      })
    },
    [dispatch, entryId, updateResponseOption, matrixResponseOptions]
  )
  const newlyAddedResponseOptionLk = useReactiveVar(responseOptionLkNewlyAdded)

  const getMatrixResponse = (
    matrixResponseOptions: DraftEntryResponseOption[]
  ) => {
    return matrixResponseOptions.map((matrixColumn, index) => {
      const shouldAutoFocus =
        newlyAddedResponseOptionLk === matrixColumn.responseOptionLk

      return (
        <MatrixResponseOption
          key={matrixColumn.responseOptionLk + matrixColumn.position}
          index={index}
          maskingEnabled={maskingEnabled}
          entryId={entryId}
          matrixColumn={matrixColumn}
          shouldAutoFocus={shouldAutoFocus}
          responseOptionsCount={matrixResponseOptions.length}
          onEnter={handleAddMatrixResponseOption}
          validateMatrixColumnText={validateMatrixColumnText}
        />
      )
    })
  }

  const responseOptionInputs = getMatrixResponse(matrixResponseOptions)

  return (
    <List
      canReorderItems
      droppableId="droppable1"
      onReorderedItems={(event) => {
        if (event.destination) {
          handleOnReorderedMatrixResponseOptions(
            event.destination.index,
            event.source.index
          )
        }
      }}
    >
      {responseOptionInputs}
    </List>
  )
}

export default memo(MatrixResponseOptions, propsAreEqual)
