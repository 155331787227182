import {
  Filter,
  FilterButtonVariant,
  FilterOption
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { SurveyState } from '../../data/gql-gen/dashboard/graphql'

interface Props {
  setSelectedFilter?: any
}

interface FilterState {
  all?: boolean
  draft?: boolean
  live?: boolean
  paused?: boolean
  complete?: boolean
}

const ProjectListFilters: React.FC<Props> = (props: Props) => {
  const { setSelectedFilter } = props
  const [filterStates, setFilterStates] = useState<FilterState>({
    all: true
  })

  const setFilterState = (option: FilterOption) => {
    const optionName = option.name.toLowerCase()
    setFilterStates({ [optionName]: true })
  }

  const handleOnClickFilter = (surveyState: SurveyState | undefined) => {
    setSelectedFilter(surveyState)
  }

  const filtersOptions: FilterOption[] = [
    {
      name: 'All',
      buttonVariant: filterStates.all
        ? FilterButtonVariant.Selected
        : FilterButtonVariant.Selectable,
      onClickFilter: (option) => {
        setFilterState(option)
        handleOnClickFilter(undefined)
      }
    },
    {
      name: 'Draft',
      buttonVariant: filterStates.draft
        ? FilterButtonVariant.Selected
        : FilterButtonVariant.Selectable,
      onClickFilter: (option) => {
        setFilterState(option)
        handleOnClickFilter(SurveyState.Draft)
      }
    },
    {
      name: 'Live',
      buttonVariant: filterStates.live
        ? FilterButtonVariant.Selected
        : FilterButtonVariant.Selectable,
      onClickFilter: (option) => {
        setFilterState(option)
        handleOnClickFilter(SurveyState.Live)
      }
    },
    {
      name: 'Paused',
      buttonVariant: filterStates.paused
        ? FilterButtonVariant.Selected
        : FilterButtonVariant.Selectable,
      onClickFilter: (option) => {
        setFilterState(option)
        handleOnClickFilter(SurveyState.Paused)
      }
    },
    {
      name: 'Complete',
      buttonVariant: filterStates.complete
        ? FilterButtonVariant.Selected
        : FilterButtonVariant.Selectable,
      onClickFilter: (option) => {
        setFilterState(option)
        handleOnClickFilter(SurveyState.Completed)
      }
    }
  ]

  return <Filter options={filtersOptions} />
}

export default ProjectListFilters
