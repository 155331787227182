import {
  Grid,
  InfoIcon,
  InfoIconColor,
  TooltipPlacement,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './AudienceCount.styles'

interface Props {
  audienceCount?: number
  typeLabel?: 'criteria' | 'questions'
}

export const MAX_AUDIENCE_COUNT = 10

const AudienceCount = ({
  audienceCount = 0,
  typeLabel = 'criteria'
}: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const hasError = audienceCount > MAX_AUDIENCE_COUNT

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.audienceCount}
    >
      <p
        className={classNames(
          textClasses.default,
          textClasses.weightSemiBold,
          hasError
            ? textClasses.highlightError
            : textClasses.highlightBackground
        )}
      >
        {`${audienceCount}/${MAX_AUDIENCE_COUNT} ${typeLabel} used`}
      </p>
      <InfoIcon
        iconColor={hasError ? InfoIconColor.Error : InfoIconColor.Primary}
        tooltipPlacement={TooltipPlacement.Right}
        text={
          hasError
            ? `You’ve used ${audienceCount} ${typeLabel} out of a maximum of ${MAX_AUDIENCE_COUNT}. You’ll need to remove ${typeLabel} before running your survey`
            : `You've used ${audienceCount} ${typeLabel} out of a maximum of ${MAX_AUDIENCE_COUNT}`
        }
      />
    </Grid>
  )
}

export default AudienceCount
