import { useReactiveVar } from '@apollo/client'
import {
  AudienceType,
  BadgeAudience,
  Grid,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  Menu,
  MenuOption,
  MenuVariant
} from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TextHighlight, TextSize, TextWeight } from '../../../constants/text'
import { usePasteCustomCriterionV2 } from '../../../hooks/audience/pasteCustomCriterionV2'
import { IsNonManualUIChange } from '../../../hooks/copyPaste/useCopyPasteComplete'
import CardTitle from '../../Questionnaire/CardTitle/CardTitle.control'
import useStyles from './AudienceQuestionCardHeader.styles'

interface Props {
  questionLk?: string
  defaultTitle: string
  hasError?: boolean
  isTitleEditable?: boolean
  placeholder?: string
  quotaName?: string
  shouldTitleInputFocus?: boolean
  isStandardAudienceSingleChoice?: boolean
  isCustomAudienceSingleChoice?: boolean
  onChange?: (inputText: string) => void
  onClickLeftIcon?: () => void
  onSwitchQuestionType?: (isSingleSelect: boolean) => void
  isFdchatProject?: boolean
  hasDisqualifyingOption?: boolean
}

const AudienceQuestionCardHeader: React.FC<Props> = (props: Props) => {
  const {
    questionLk = '',
    defaultTitle,
    hasError = false,
    isTitleEditable = true,
    placeholder,
    quotaName,
    shouldTitleInputFocus,
    isStandardAudienceSingleChoice,
    isCustomAudienceSingleChoice,
    onChange,
    onClickLeftIcon,
    onSwitchQuestionType,
    isFdchatProject,
    hasDisqualifyingOption
  } = props

  const [titleValue, setTitleValue] = useState<string>(defaultTitle)
  const isAfterPaste = useReactiveVar<boolean>(IsNonManualUIChange)

  useEffect(() => {
    if (isAfterPaste) {
      setTitleValue(defaultTitle)
      IsNonManualUIChange(false)
    }
  }, [defaultTitle, isAfterPaste])

  const handleOnChangeDebounced = useMemo(
    () =>
      debounce((titleValue: string) => {
        onChange?.(titleValue)
      }, 1000),
    [onChange]
  )

  const handleOnChange = useCallback(
    (title: string) => {
      if (!onChange) {
        return
      }
      setTitleValue(title)
      handleOnChangeDebounced(title)
    },
    [handleOnChangeDebounced, onChange]
  )

  const pasteInCustomCriterionText = usePasteCustomCriterionV2(
    questionLk,
    titleValue,
    handleOnChange
  )

  const { classes } = useStyles()

  const simpleMenu: MenuOption[] = [
    {
      id: 0,
      options: [
        {
          text: 'Single select',
          onClick: () => {
            if (onSwitchQuestionType) {
              onSwitchQuestionType(true)
            }
          },
          iconName: IconName.SingleChoice
        },
        {
          text: 'Multi select',
          onClick: () => {
            if (onSwitchQuestionType) {
              onSwitchQuestionType(false)
            }
          },
          iconName: IconName.MultipleChoice
        }
      ]
    }
  ]

  const getEntryTypeName: (
    isTitleEditable: boolean,
    quotaName: string | undefined
  ) => JSX.Element | string | undefined = (isTitleEditable, quotaName) => {
    if (!isTitleEditable) {
      return quotaName
    }
    return (
      <Menu
        menuOptions={simpleMenu}
        title={isCustomAudienceSingleChoice ? 'Single select' : 'Multi select'}
        titleWidth={90}
        variant={MenuVariant.QuestionType}
      />
    )
  }

  const getBadgeAudienceQuestion = (isTitleEditable: boolean) => {
    if (isFdchatProject) {
      // if it's an fdchat project and has a disqualifying option, show the screening badge
      // else show no badge
      if (hasDisqualifyingOption) {
        return <BadgeAudience badgeType={AudienceType.Screening} />
      } else {
        return
      }
    }

    if (isTitleEditable) {
      return (
        <BadgeAudience
          badgeType={AudienceType.Screening}
          tooltipText="Qualify people out before reaching your survey. Toggle on quotas if you need a representative sample. When the project is complete, you can use any screening question as a filter or cross-break for your data."
        />
      )
    }
    if (isStandardAudienceSingleChoice) {
      return (
        <BadgeAudience
          badgeType={AudienceType.Targeting}
          tooltipText="Use the tick boxes to pick which groups to target or exclude. Toggle on quotas where available if you need a representative sample."
        />
      )
    }
    return (
      <BadgeAudience
        badgeType={AudienceType.Targeting}
        tooltipText="Use the tick boxes to pick which groups to target or exclude. Toggle on quotas where available if you need a representative sample."
      />
    )
  }

  const placeholderText = placeholder || 'Type your question'
  const entryTypeName = getEntryTypeName(isTitleEditable, quotaName)
  const badgeAudienceQuestion = getBadgeAudienceQuestion(isTitleEditable)

  return (
    <Grid container>
      <Grid container item xs={10} direction="row" alignItems="center">
        <Grid item xs="auto" className={classes.questionType}>
          <p
            style={{
              color: hasError ? TextHighlight.Error : TextHighlight.Background
            }}
          >
            {entryTypeName}
          </p>
        </Grid>
        <Grid item>{badgeAudienceQuestion}</Grid>
      </Grid>
      <Grid
        container
        item
        xs={2}
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
      >
        <Grid item xs="auto" className={classes.middleIcon}>
          <IconButton
            iconName={IconName.DeleteOutline}
            iconSize={IconSize.Large}
            iconColor={IconColor.Background}
            onClick={onClickLeftIcon}
            negativePadding
            ariaLabel="Audience question delete"
          />
        </Grid>
      </Grid>

      <Grid container>
        {isTitleEditable && (
          <CardTitle
            titleValue={titleValue}
            ariaLabel="Custom audience input"
            shouldTitleInputFocus={shouldTitleInputFocus}
            hasError={!titleValue && hasError}
            placeholder={placeholderText}
            onChange={handleOnChange}
            onPaste={pasteInCustomCriterionText}
          />
        )}
        {!isTitleEditable && (
          <p
            style={{
              fontSize: TextSize.m,
              fontWeight: TextWeight.SemiBold,
              color: TextHighlight.Background
            }}
          >
            {titleValue}
          </p>
        )}
      </Grid>
    </Grid>
  )
}

export default memo(AudienceQuestionCardHeader)
