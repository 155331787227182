import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import { Project, SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import { UpdateSurveyOrderData } from '../../../data/gql/order/mutations/updateSurveyOrder'
import { SearchProjects } from '../../../modules/Project/Project.query'

export const PauseSurveyCacheUpdate: (
  cache: ApolloCache<UpdateSurveyOrderData>,
  selectedProject: Project | undefined,
  isLive: boolean
) => void = (cache, selectedProject, isLive) => {
  cache.modify({
    fields: {
      searchProjects(existing) {
        const searchProjectsCache = existing as SearchProjects
        const index: number = searchProjectsCache.projects.findIndex(
          (proj: Project) => proj.projectId === selectedProject?.projectId
        )

        return produce(searchProjectsCache, (draft) => {
          draft.projects[index].surveys[0].state = isLive
            ? SurveyState.Paused
            : SurveyState.Live
        })
      },
      projectBySurveyId(existing) {
        const projectCache = existing as Project
        return produce(projectCache, (draft) => {
          draft.surveys[0].state = isLive
            ? SurveyState.Paused
            : SurveyState.Live
        })
      }
    }
  })
}
