import { Paragraph, convertMillimetersToTwip } from 'docx'
import { EntryType } from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { chain } from '../lodashChain'
import { getQuestionEnabledSettings } from '../questionnaireUtils'
import { createResponseLogicText } from './createResponseLogicText'
import { createResponseOptions } from './createResponseOptions'
import { Order } from './model'

export const createEntryContentMatrix = (
  entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>,
  entries: DraftQuestionnaireEntry[]
): Paragraph[] => {
  const entryItem = entry.entryItem
  const enabledSettings = getQuestionEnabledSettings(entryItem.settingValues)
  const randomisedRowsEnabled = enabledSettings.has(
    QuestionSettingCode.MatrixRandomiseRows
  )

  const rowsParagraphs = chain(entryItem.matrixRows)
    .filter('question.text')
    .sortBy('position')
    .map(({ question, pinned, maskingRules }, i) => {
      const rowText = `${i + 1}. ${question?.text}`
      const pinnedPostfixIfNeeded =
        pinned && randomisedRowsEnabled ? ' [pinned]' : ''
      return new Paragraph({
        text: `${rowText}${pinnedPostfixIfNeeded}`,
        children: createResponseLogicText(maskingRules, entries),
        indent: {
          start: convertMillimetersToTwip(6.3)
        }
      })
    })
    .value()

  if (randomisedRowsEnabled) {
    rowsParagraphs.push(new Paragraph(Order.Randomise))
  }

  const columnsParagraphs = [
    new Paragraph({
      text: 'Columns',
      spacing: {
        before: 200
      }
    }),
    ...createResponseOptions(entryItem, entries)
  ]

  if (enabledSettings.has(QuestionSettingCode.MatrixRandomiseResponseOptions)) {
    columnsParagraphs.push(new Paragraph(Order.Randomise))
  }

  if (enabledSettings.has(QuestionSettingCode.MatrixFlipOptions)) {
    columnsParagraphs.push(new Paragraph(Order.Reverse))
  }

  return [...rowsParagraphs, ...columnsParagraphs]
}
