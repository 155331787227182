import { makeStyles, theme } from '@focaldata/cin-ui-components'

export const useStyles = makeStyles()({
  contentConfirmation: {
    padding: theme.spacing(6, 4, 0, 4),
    marginBottom: theme.spacing(5)
  },
  leftContainer: {
    paddingRight: theme.spacing(2)
  },
  rightContainer: {
    paddingLeft: theme.spacing(2)
  },
  dateSelection: {
    paddingBottom: theme.spacing(2)
  },
  costBreakdown: {
    paddingBottom: theme.spacing(4)
  }
})
