import {
  Select,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { emptyArr } from '../../../../constants/misc'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { chain } from '../../../../utils/lodashChain'
import { flattenEntries } from '../../Questionnaire.utils'
import {
  selectCurrentLoopingSectionNumberRequired,
  selectInitialSourceEntryNumber,
  selectSourceEntryNumber,
  setSourceEntry
} from '../Looping.slice'
import { entryHasStaticLooping, isPossibleSourceEntry } from '../Looping.utils'

const defaultSourceSelectorOption = {
  id: 'id',
  name: 'Select source question',
  value: -1
}

const usePossibleSourceEntries = () => {
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const currentLoopingSectionNumber = useAppSelector(
    selectCurrentLoopingSectionNumberRequired
  )

  const entries = draftQuestionnaire?.entries ?? emptyArr
  const currentSection = entries.find(
    (entry) => entry.number === currentLoopingSectionNumber
  )

  const possibleSourceEntries = useMemo(
    () =>
      chain(entries)
        .takeWhile((entry) => entry !== currentSection)
        .thru(flattenEntries)
        .filter(isPossibleSourceEntry)
        .value(),
    [currentSection, entries]
  )

  return possibleSourceEntries
}

const getTitleValue = (entry: DraftQuestionnaireEntry) => {
  if (entry.entryType === EntryType.QuestionEntryType) {
    return entry.entryItem.question?.text ?? ''
  }

  if (entry.entryType === EntryType.MatrixEntryType) {
    return entry.entryItem.matrixTitle?.title ?? ''
  }

  return ''
}

export const LoopingSourceSelector = () => {
  const dispatch = useAppDispatch()
  const sourceEntryNumber = useAppSelector(selectSourceEntryNumber)
  const initialSourceEntryNumber = useAppSelector(
    selectInitialSourceEntryNumber
  )

  const possibleSourceEntries = usePossibleSourceEntries()

  // Because static looping is actually a setting on the question, not the loop,
  // we don't want the same question used for multiple loops, so we hide it for
  // loops where it is already used for static looping.
  const nonEnabledSourceEntries = possibleSourceEntries.filter(
    (entry) =>
      !entryHasStaticLooping(entry) || entry.number === initialSourceEntryNumber
  )

  const sourceSelectorOptions = useMemo(
    () => [
      defaultSourceSelectorOption,
      ...nonEnabledSourceEntries.map((possibleSource) => {
        const { contextPosition, entryId, number } = possibleSource
        const title = getTitleValue(possibleSource)
        return {
          id: entryId,
          name: `Q${contextPosition + 1} ${title}`,
          value: number
        }
      })
    ],
    [nonEnabledSourceEntries]
  )

  const entriesMapByEntryNumber = useMemo(
    () =>
      new Map(
        nonEnabledSourceEntries.map((entry) => [entry.number, entry] as const)
      ),
    [nonEnabledSourceEntries]
  )

  const handleChangeSourceEntryNumber = useCallback(
    (entryNumber: number) => {
      const entry = entriesMapByEntryNumber.get(entryNumber) ?? null

      return dispatch(setSourceEntry(entry))
    },
    [dispatch, entriesMapByEntryNumber]
  )

  return (
    <Select
      variant={SelectVariant.Filled}
      fullWidth
      textSize={TextSize.ms}
      selectedTextHighlight={TextHighlight.Background}
      selectedTextWeight={TextWeight.Regular}
      selectedTextFontSize={TextSize.m}
      options={sourceSelectorOptions}
      optionValue={sourceEntryNumber}
      onChange={(event) => {
        handleChangeSourceEntryNumber(Number(event.target.value))
      }}
    />
  )
}
