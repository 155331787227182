import { ScrollElement } from '@focaldata/cin-ui-components'
import { DialogDeleteSection } from '../../../components/Section/DialogDeleteSection'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import EntryPlaceholder from '../EntryPlaceholder'
import { GenericEntryContainer } from '../GenericEntry'
import { MatrixQuestionScreeningDialog } from '../MatrixQuestionScreeningDialog/MatrixQuestionScreeningDialog'
import { PipingDialogContainer } from '../PipingDialog'
import { QuestionTimerDialog } from '../QuestionTimerDialog'
import { getScrollId } from '../Questionnaire.utils'
import { SectionEntryContainer } from '../SectionEntry'
import { PlaceholderHeightsById } from './QuestionsList.hooks'

interface Props {
  entries?: DraftQuestionnaireEntry[]
  numbersToRender: number[]
  placeholderHeightsById: PlaceholderHeightsById
  onChangeVisible: (index: number, isVisible: boolean) => void
}

export const QuestionsList = ({
  entries,
  numbersToRender,
  placeholderHeightsById,
  onChangeVisible
}: Props) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()

  return (
    <>
      {entries?.map((entry) => {
        const shouldShowEntry = numbersToRender.includes(entry.number)
        const currentIndex = flattenedEntries.findIndex(
          (flattennedListEntry) => flattennedListEntry === entry
        )

        if (entry.entryType === EntryType.SectionEntryType) {
          return (
            <ScrollElement
              elementId={getScrollId(entry.id, EntryType.SectionEntryType)}
              key={entry.number}
            >
              <SectionEntryContainer
                entry={entry}
                placeholderHeightsById={placeholderHeightsById}
                onChangeVisible={onChangeVisible}
              />
            </ScrollElement>
          )
        }

        return (
          <ScrollElement
            elementId={getScrollId(entry.id)}
            key={entry.number}
            partialVisibility
            onChangeVisible={(isVisible) =>
              onChangeVisible(currentIndex, isVisible)
            }
          >
            {shouldShowEntry ? (
              <GenericEntryContainer entry={entry} />
            ) : (
              <EntryPlaceholder
                entry={entry}
                height={placeholderHeightsById[entry.id]}
              />
            )}
          </ScrollElement>
        )
      })}
      <PipingDialogContainer />
      <DialogDeleteSection />
      <QuestionTimerDialog />
      <MatrixQuestionScreeningDialog />
    </>
  )
}
