import {
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LinkButton,
  Paper,
  PaperGroupItem,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SurveyParams } from '../../data/model/surveyParams'
import { AudienceSummaryContainer } from '../../modules/Audience/AudienceSummary'
import useStyles from './styles'

const ConfirmationReviewDataQuality: React.FC = () => {
  const navigate = useNavigate()
  const { surveyId } = useParams<keyof SurveyParams>()

  const { classes } = useStyles()

  const goToAudienceCriteria = () => {
    navigate(`/survey/${surveyId}/audience`)
  }

  return (
    <Paper>
      <PaperGroupItem>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              2
            </Text>
          </Grid>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              Review data quality
            </Text>
          </Grid>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column" spacing={1}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text size={TextSize.m} weight={TextWeight.SemiBold}>
              Respondent quality checks
            </Text>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Icon
              name={IconName.TickCheck}
              size={IconSize.Large}
              iconColor={IconColor.Emphasis}
            />
            <Text
              size={TextSize.ms}
              highlight={TextHighlight.Background}
              weight={TextWeight.SemiBold}
            >
              Speeders&nbsp;
            </Text>
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Remove respondents who complete the survey too quickly
            </Text>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Icon
              name={IconName.TickCheck}
              size={IconSize.Large}
              iconColor={IconColor.Emphasis}
            />
            <Text
              size={TextSize.ms}
              highlight={TextHighlight.Background}
              weight={TextWeight.SemiBold}
            >
              Response quality&nbsp;
            </Text>
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Remove respondents who give nonsense and other poor-quality
              responses
            </Text>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Icon
              name={IconName.TickCheck}
              size={IconSize.Large}
              iconColor={IconColor.Emphasis}
            />
            <Text
              size={TextSize.ms}
              highlight={TextHighlight.Background}
              weight={TextWeight.SemiBold}
            >
              Bot detection&nbsp;
            </Text>
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Remove bots and other fake respondents
            </Text>
          </Grid>
        </Grid>
        <Grid item className={classes.dividerGrid}>
          <Divider />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Quotas and sample quality
          </Text>
          <LinkButton
            highlight={TextHighlight.Emphasis}
            weight={TextWeight.SemiBold}
            size={TextSize.ms}
            onClick={goToAudienceCriteria}
          >
            Edit audience
          </LinkButton>
        </Grid>
        <AudienceSummaryContainer />
      </PaperGroupItem>
    </Paper>
  )
}

export default ConfirmationReviewDataQuality
