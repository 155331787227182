import {
  Grid,
  LinkButton,
  TextHighlight,
  TextWeight,
  additionalPalette
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useFdChatContainerContent, useFdChatResults } from '../FdChat.hooks'
import RequestFindingsDialog from './Findings/RequestFindingsDialog'
import ExportTranscriptsButton from './components/ExportTranscriptsButton'

const links = [
  {
    text: 'Overview',
    path: 'overview'
  },
  {
    text: 'Ask Ai',
    path: 'ask-ai'
  },
  {
    text: 'Findings',
    path: 'findings'
  }
]

const FdChatResultsContainer = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { hasFindings } = useFdChatResults()
  const [requestFindingsDialogOpen, setRequestFindingsDialogOpen] =
    useState(false)
  const { contentRef } = useFdChatContainerContent()

  return (
    <div
      className="fd-chat-results-container"
      style={{
        backgroundColor: additionalPalette.lightBlue.main,
        minHeight: '100%'
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1
        }}
      >
        <Grid
          container
          component={'nav'}
          alignItems={'center'}
          style={{
            padding: '12px 32px'
          }}
        >
          {links.map((link, index) => (
            <Grid display={'flex'} key={link.text}>
              <div
                style={{
                  pointerEvents: pathname.endsWith(link.path) ? 'none' : 'auto'
                }}
              >
                <LinkButton
                  weight={
                    pathname.includes(link.path) ? TextWeight.Bold : undefined
                  }
                  highlight={
                    pathname.includes(link.path)
                      ? TextHighlight.Standard
                      : undefined
                  }
                  noPadding
                  onClick={() => {
                    if (!hasFindings && link.path === 'findings') {
                      setRequestFindingsDialogOpen(true)
                    } else {
                      navigate(link.path)
                    }
                  }}
                  dataDdActionName={`${link.text} (tab)`}
                >
                  {link.text}
                </LinkButton>
              </div>
              {index !== links.length - 1 && (
                <Divider
                  orientation="vertical"
                  style={{ height: '1.5em', margin: '0px 12px' }}
                />
              )}
            </Grid>
          ))}
          <div style={{ marginLeft: 'auto' }}>
            <ExportTranscriptsButton />
          </div>
        </Grid>
        <Divider />
      </div>
      <Outlet context={{ contentRef }} />
      <RequestFindingsDialog
        open={requestFindingsDialogOpen}
        onClose={() => setRequestFindingsDialogOpen(false)}
      />
    </div>
  )
}

export default FdChatResultsContainer
