import {
  AlignInputText,
  Grid,
  Input,
  InputVariant
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useEffect, useState } from 'react'
import useStyles from './CustomAgeRanges.styles'

const isValidInputValue = (value: string, maxDigits: number) => {
  const digitsRegex = new RegExp(`^[1-9]\\d{0,${maxDigits - 1}}$`)
  return !value || digitsRegex.test(value)
}

type Range = {
  min: string
  max: string
}

interface Props {
  hasError?: boolean
  min?: string
  max?: string
  maxDigits?: number
  onChange?: (range: Range) => void
  onBlur?: (range: Range) => void
}

export const AgeRangeInput = ({
  hasError = false,
  min = '',
  max = '',
  maxDigits = 3,
  onChange,
  onBlur
}: Props) => {
  const [minValueState, setMinValueState] = useState(min)
  const [maxValueState, setMaxValueState] = useState(max)
  const { classes, cx: classNames } = useStyles()

  useEffect(() => {
    setMinValueState(min)
  }, [min])

  useEffect(() => {
    setMaxValueState(max)
  }, [max])

  const handleChangeMin = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!isValidInputValue(value, maxDigits)) {
      return
    }

    setMinValueState(value)
    onChange?.({ min: value, max: maxValueState })
  }

  const handleChangeMax = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!isValidInputValue(value, maxDigits)) {
      return
    }

    setMaxValueState(value)
    onChange?.({ min: minValueState, max: value })
  }

  const handleBlur = () => {
    const rangeChanged = minValueState !== min || maxValueState !== max

    if (rangeChanged) {
      onBlur?.({ min: minValueState, max: maxValueState })
    }
  }

  return (
    <Grid alignItems="center" display="flex">
      <Input
        value={minValueState}
        onChange={handleChangeMin}
        variant={InputVariant.Outlined}
        onBlur={handleBlur}
        supressHelperText
        alignInputText={AlignInputText.Center}
        className={classNames({
          [classes.ageRangeInput]: !hasError,
          [classes.ageRangeInputError]: hasError
        })}
      />
      <span className={classes.ageRangeInputSeparator} />
      <Input
        value={maxValueState}
        onChange={handleChangeMax}
        variant={InputVariant.Outlined}
        onBlur={handleBlur}
        supressHelperText
        alignInputText={AlignInputText.Center}
        className={classNames({
          [classes.ageRangeInput]: !hasError,
          [classes.ageRangeInputError]: hasError
        })}
      />
    </Grid>
  )
}
