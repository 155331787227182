import { Theme, makeStyles } from '@focaldata/cin-ui-components'

interface StyleProps {
  isConfirmationPage: boolean
  isResultsPage: boolean
  isFDChatResultsPage: boolean
}

// breakpoint between lg and xl
const lxl = 1281

type Breakpoint = Theme['breakpoints']['keys'][number]

const titleMaxWidths = {
  XXL: 400,
  XL: 380,
  LG: 240,
  MD: 220,
  SM: 160
}

const calculateProjectNameMaxWidth = (
  styleProps: StyleProps,
  breakpoint: Breakpoint | number,
  up?: boolean
) => {
  const { isConfirmationPage, isFDChatResultsPage, isResultsPage } = styleProps

  if (isFDChatResultsPage) {
    return titleMaxWidths.XXL
  }

  if (isConfirmationPage) {
    return titleMaxWidths.MD
  }

  if (breakpoint === 'xl') {
    return up || !isResultsPage ? titleMaxWidths.XL : titleMaxWidths.LG
  }

  if (breakpoint === lxl) {
    return isResultsPage ? titleMaxWidths.SM : titleMaxWidths.LG
  }

  return 'unset'
}

const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    projectName: {
      paddingRight: theme.spacing(2.25),
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('xl')]: {
        maxWidth: calculateProjectNameMaxWidth(props, 'xl', true)
      },
      [theme.breakpoints.down('xl')]: {
        maxWidth: calculateProjectNameMaxWidth(props, 'xl')
      },
      [theme.breakpoints.down(lxl)]: {
        maxWidth: calculateProjectNameMaxWidth(props, lxl)
      }
    },
    projectNameText: {
      cursor: 'pointer'
    },
    rootFullWidth: {
      height: '100%',
      maxWidth: '100%'
    }
  })
)

export default useStyles
