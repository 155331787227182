import { useQuery } from '@apollo/client'
import { LoggerErrorType } from '../data/logger'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData
} from '../modules/Project/Project.query'
import { captureApolloError } from '../utils/HelperFunctions'
import { useSurveyId } from './useSurveyId'

const useGetSurveyState = () => {
  const surveyId = useSurveyId()

  const { data: dataProject, loading } = useQuery<ProjectData>(
    PROJECT_BY_SURVEY_ID,
    {
      variables: {
        surveyId
      },
      context: { clientName: 'dashboard' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'projectBySurveyId',
          error
        )
      }
    }
  )

  return { surveyState: dataProject?.project.surveys[0].state, loading }
}

export default useGetSurveyState
