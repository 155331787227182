import {
  Grid,
  IconButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './AddCustomAudienceButton.styles'

interface Props {
  onAddCustomAudience: (isMulti: boolean) => void
  label?: string
  disabled?: boolean
}

const AddCustomAudienceButton: React.FC<Props> = (props: Props) => {
  const {
    onAddCustomAudience,
    label = 'Screening questions',
    disabled
  }: Props = props
  const { classes } = useStyles()

  const allKebabMenuOptions: KebabMenuOption[] = [
    {
      id: 0,
      textItem: 'Single select',
      onClickItem: () => onAddCustomAudience(false),
      disabled
    },
    {
      id: 1,
      textItem: 'Multi select',
      onClickItem: () => onAddCustomAudience(true),
      disabled
    }
  ]

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Text
        size={TextSize.ms}
        weight={TextWeight.SemiBold}
        highlight={TextHighlight.Emphasis}
      >
        {label}
      </Text>
      <KebabMenu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        kebabMenuOptions={allKebabMenuOptions}
        iconColor={IconColor.Background}
        className={classes.iconButton}
        menuButton={
          <IconButton
            ariaLabel="Create new custom audience criterion"
            iconName={IconName.Add}
            iconColor={IconColor.Primary}
            className={classes.iconButton}
          />
        }
        iconPosition={KebabMenuIconPosition.Left}
      />
    </Grid>
  )
}

export default AddCustomAudienceButton
