import { Badge, Variant } from '@focaldata/cin-ui-components'
import React from 'react'
import { useGetSoftLaunchOrder } from '../../../components/SoftLaunch'
import useStyles from './SoftLaunchBadge.styles'

const SoftLaunchBadgeContainer: React.FC = () => {
  const {
    softLaunchSamplesNeeded,
    softLaunchState,
    softLaunchStatus,
    isSoftLaunchDisabled,
    isSoftLaunchOrder,
    isFullLaunchOrder
  } = useGetSoftLaunchOrder()

  const { classes } = useStyles()

  const label = `Soft launch ${softLaunchState}`
  const tooltipText = `Soft launch = ${softLaunchSamplesNeeded} complete(s)`

  const showBadge =
    !isSoftLaunchDisabled && isSoftLaunchOrder && !isFullLaunchOrder

  return showBadge ? (
    <Badge
      status={softLaunchStatus}
      label={label}
      variant={Variant.filled}
      className={classes.root}
      tooltipText={tooltipText}
    />
  ) : null
}

export default SoftLaunchBadgeContainer
