import {
  Button,
  ButtonVariant,
  Icon,
  IconColor,
  IconName,
  IconSize,
  KebabMenu,
  textStyleUtils
} from '@focaldata/cin-ui-components'

const LoopingActionsMenuComponent = ({
  sourceEntryPrefix,
  onEditLooping,
  onDisableLooping,
  staticLooping
}: {
  sourceEntryPrefix: string
  onEditLooping: (() => void) | null
  onDisableLooping: () => void
  staticLooping: boolean
}) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  return (
    <KebabMenu
      kebabMenuOptions={[
        {
          id: 0,
          textItem: onEditLooping
            ? 'Edit looping'
            : 'Edit looping disabled as source question missing',
          onClickItem: onEditLooping ?? (() => {}),
          disabled: !onEditLooping
        },
        {
          id: 1,
          textItem: 'Disable looping',
          onClickItem: onDisableLooping
        }
      ]}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      menuButton={
        <Button
          variant={ButtonVariant.SecondaryOutlined}
          startIcon={
            <Icon
              name={IconName.Repeat}
              iconColor={IconColor.Emphasis}
              size={IconSize.Large}
            />
          }
        >
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.highlightEmphasis
            )}
          >
            {staticLooping ? <>Static looping</> : <>Looping</>} based on{' '}
            {sourceEntryPrefix}
          </p>
        </Button>
      }
    />
  )
}

export default LoopingActionsMenuComponent
