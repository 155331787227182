import { additionalPalette } from '@focaldata/cin-ui-components'
import type { Identifier } from 'dnd-core'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { DropTargetMonitor, XYCoord, useDrag, useDrop } from 'react-dnd'
import { Path, getDropPosition } from './NestedDragAndDrop.utils'

interface CollectedProps {
  handlerId: Identifier | null
  isOver: boolean
}

export interface DragAndDropProps {
  position: number
  id?: string
  type: string
  path?: string
  accept: string[]
  draggable?: boolean
  parentPosition?: number
  childrenCount?: number
  moveCard?: (itemId: string, source: Path, destination: Path) => void
}

interface DragItem {
  position: number
  id: string
  type: string
  path: string
  parentPosition?: number
}

const DragAndDrop: FC<PropsWithChildren<DragAndDropProps>> = ({
  id,
  type,
  position,
  path,
  accept,
  draggable,
  parentPosition,
  childrenCount = 0,
  moveCard,
  children
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOverTop, setIsOverTop] = useState(false)
  const [isOverBottom, setIsOverBottom] = useState(false)
  const [showTopBorder, setShowTopBorder] = useState(false)
  const [showBottomBorder, setShowBottomBorder] = useState(false)

  const autoScroll = (monitor: DropTargetMonitor<DragItem, void>) => {
    const scrollDOM = document.querySelector('[class*="-sidebar"]')

    if (scrollDOM) {
      const cursor = monitor.getClientOffset()
      if (!cursor) {
        return
      }
      const rect = scrollDOM.getBoundingClientRect()
      const proximityArea = 150
      const cursorTopPosition = cursor.y - rect.top

      if (cursorTopPosition < proximityArea) {
        scrollDOM.scrollTop -= 6
      }
      if (rect.bottom - cursor.y < proximityArea) {
        scrollDOM.scrollTop += 6
      }
    }
  }

  const [{ handlerId, isOver }, drop] = useDrop<DragItem, void, CollectedProps>(
    {
      accept: [...accept],
      collect: (monitor) => {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver({ shallow: true })
        }
      },
      drop: (dragItem, monitor) => {
        const dropPosition = getDropPosition(
          dragItem.position,
          position,
          showTopBorder,
          showBottomBorder,
          childrenCount,
          dragItem.parentPosition,
          id
        )

        if (!monitor.didDrop())
          moveCard?.(
            dragItem.id,
            { position: dragItem.position, path: dragItem.path },
            { position: dropPosition, path: path || '' }
          )
      },
      hover: (dragItem, monitor) => {
        if (!ref.current) return

        const dragPosition = dragItem.position
        const hoverPosition = position

        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        const clientOffset = monitor.getClientOffset()
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
          setIsOverBottom(true)
        } else setIsOverBottom(false)

        if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
          setIsOverTop(true)
        } else setIsOverTop(false)

        autoScroll(monitor)
      }
    }
  )

  const [{ isDragging, dragPosition, dragPath }, drag] = useDrag({
    type,
    item: () => {
      return { id, position, path, parentPosition }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      dragPosition: monitor.getItem()?.position ?? -1,
      dragPath: monitor.getItem()?.path ?? '',
      dragParentPosition: monitor.getItem()?.parentPosition ?? ''
    })
  })

  useEffect(() => {
    setShowBottomBorder(
      isOver &&
        dragPath !== path &&
        !isOverTop &&
        (dragPosition < position || isOverBottom)
    )
    setShowTopBorder(
      isOver &&
        dragPath !== path &&
        !isOverBottom &&
        (dragPosition > position || isOverTop)
    )
  }, [dragPosition, dragPath, position, isOver, isOverBottom, isOverTop, path])

  const opacity = isDragging ? 0 : 1
  const droppable = drop(ref)

  if (draggable) drag(droppable)

  return (
    <div
      ref={ref}
      className="fd-grid fd-container fd-item"
      style={{
        opacity,
        cursor: draggable ? 'pointer' : 'initial',
        color: additionalPalette.grey.main,
        borderStyle: 'dashed',
        borderWidth:
          isOver && id === undefined
            ? '2px'
            : `${showTopBorder ? '2px' : '0'} 0 ${
                showBottomBorder ? '2px' : '0'
              } 0`,
        boxSizing: 'content-box',
        width: '100%'
      }}
      data-handler-id={handlerId}
    >
      {children}
    </div>
  )
}

export default DragAndDrop
