import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import {
  CustomAudienceCriteriaData,
  GET_CUSTOM_CRITERIA
} from '../../../data/gql/questionnaire/queries/customCriteria'
import {
  DraftCustomCriterion,
  EnabledState
} from '../../../data/model/audience'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceContext, { setAudienceMembers } from '../Audience.context'
import { useAddCustomAudience } from '../Audience.hooks'
import { MAX_AUDIENCE_COUNT } from '../AudienceCount/AudienceCount.control'
import { AddCustomAudienceButton } from './AddCustomAudienceButton'
import CustomAudienceListControl from './CustomAudienceList.control'

const CustomAudienceList = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { dispatch } = useContext(AudienceContext)
  const { isFdChat } = useProjectType()
  const { addCustomAudience } = useAddCustomAudience()

  const { data, error } = useQuery<CustomAudienceCriteriaData>(
    GET_CUSTOM_CRITERIA,
    {
      context: { clientName: 'questionnaire' },
      variables: {
        projectId,
        surveyId
      },
      fetchPolicy: 'cache-and-network'
    }
  )
  const { draftAudience } = useGetDraftAudience()
  const { toggleCustomAudienceMemberState } = useUpdateAudienceMemberV2()

  useEffect(() => {
    if (draftAudience) {
      dispatch(setAudienceMembers(draftAudience.members))
    }
  }, [draftAudience, dispatch])

  const handleToggleCustomCriterion: (
    criterion: DraftCustomCriterion
  ) => void = (criterion) => {
    toggleCustomAudienceMemberState(
      criterion.questionLk,
      criterion.state === EnabledState.Disabled
    )
  }

  if (error) return <div>Error loading custom criteria</div>

  return (
    <>
      <AddCustomAudienceButton
        onAddCustomAudience={addCustomAudience}
        label={isFdChat ? 'Add a new question' : undefined}
        disabled={
          isFdChat &&
          draftAudience &&
          draftAudience.members.length >= MAX_AUDIENCE_COUNT
        }
      />
      {data && (
        <CustomAudienceListControl
          customCriteria={data.customCriteria}
          validationErrors={draftAudience?.validationErrors}
          onCustomAudienceToggleCriterion={handleToggleCustomCriterion}
        />
      )}
    </>
  )
}

export default CustomAudienceList
