import { useQuery } from '@apollo/client'
import { DEFAULT_POLLING_INTERVAL } from '../constants/misc'
import { GET_COST_QUOTE } from '../data/gql/questionnaire/queries/getCostQuote'
import { LoggerErrorType } from '../data/logger'
import { captureApolloError } from '../utils/HelperFunctions'
import { useProjectId } from './useProjectId'
import { useSurveyId } from './useSurveyId'

interface GetCostQuoteData {
  costPerIncidence: number
  baseAmount: number
  roundedCostPerIncidence: number
}

const usePollCostQuote = (loi: number) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const {
    data: costDetails,
    loading: loadingCostDetails,
    startPolling: startPollingGetCostQuote,
    stopPolling: stopPollingGetCostQuote,
    refetch: refetchGetCostQuote
  } = useQuery(GET_COST_QUOTE, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId,
      lengthOfInterview: loi
    },
    pollInterval: DEFAULT_POLLING_INTERVAL,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'getCostQuote', error)
    }
  })

  const { amount: costPerIncidence = 0, baseAmount = 0 } =
    costDetails?.getCostQuote?.cpi ?? {}
  const roundedCostPerIncidence = parseFloat(costPerIncidence.toFixed(2))

  const getCostQuoteData: GetCostQuoteData = {
    costPerIncidence,
    baseAmount,
    roundedCostPerIncidence
  }

  return {
    getCostQuoteData,
    loadingCostDetails,
    startPollingGetCostQuote,
    stopPollingGetCostQuote,
    refetchGetCostQuote
  }
}

export default usePollCostQuote
