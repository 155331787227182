import { useFragment } from '@apollo/client'
import { DraftMatrixItem } from '../../data/gql-gen/questionnaire/graphql'
import { DRAFT_MATRIX_ITEM } from '../../data/gql/questionnaire/fragments/draftMatrixItem'

export const useDraftMatrixItem = (
  matrixTitleLk: string
): DraftMatrixItem | null => {
  const matrixItemFragment = useFragment<DraftMatrixItem>({
    fragment: DRAFT_MATRIX_ITEM,
    fragmentName: 'DraftMatrixItem',
    from: { __typename: 'DraftMatrixItem', matrixTitleLk },
    canonizeResults: true
  })

  return matrixItemFragment.complete ? matrixItemFragment.data : null
}
