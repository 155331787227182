import {
  Grid,
  LinkButton,
  Paper,
  PaperGroupItem,
  ScrollElement,
  Select,
  SelectOption,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { produce } from 'immer'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { memo, useCallback, useMemo, useState } from 'react'
import QuotaEditorFooter from '../../../controls/QuotaEditorFooter'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  FieldworkAudience,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import { useNotifyingState } from '../../../hooks/useNotifyingState'
import { percentToProportion } from '../../../utils/HelperFunctions'
import { ResponseOrderingOptions } from '../../../utils/questionnaireUtils'
import { AudienceQuestionCardFooter } from '../AudienceQuestionCardFooter'
import { AudienceQuestionCardHeader } from '../AudienceQuestionCardHeader'
import { OptionEditorHeader } from '../OptionEditorHeader'
import { SCROLL_ID_PREFIX } from '../constants'
import {
  getIsEnabledInSettings,
  getIsValueInSettings,
  getScreeningQuestionResponseLimit,
  isQuotaSumValid
} from '../utils'
import CustomAudienceResponseOptionsList from './CustomAudienceResponsesList.container'

interface QuotaInfo {
  [responseOptionLk: string]: {
    enabled: boolean
    value: number
  }
}

const calculateTotalQuota = (quotaInfo: QuotaInfo | undefined) => {
  let totalAssignedQuota = 0

  if (quotaInfo) {
    for (const quota of Object.values(quotaInfo)) {
      if (
        quota.enabled &&
        !Number.isNaN(quota.value) &&
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        quota.value !== undefined
      ) {
        totalAssignedQuota += quota.value
      }
    }
  }

  return totalAssignedQuota
}

const useQuotaInfo = (
  criterionOptions: DraftCustomAudienceCriterionOption[]
) => {
  const initialQuotaValues = useMemo(
    (): QuotaInfo =>
      mapValues(keyBy(criterionOptions, 'responseOptionLk'), (option) => ({
        value: option.quota?.percent ?? 0,
        enabled: !!option.qualification
      })),
    [criterionOptions]
  )
  const [prevQuotaInfo, setPrevQuotaInfo] = useState(initialQuotaValues)
  const [quotaInfo, setQuotaInfo] = useState(initialQuotaValues)

  if (initialQuotaValues !== prevQuotaInfo) {
    setPrevQuotaInfo(initialQuotaValues)
    setQuotaInfo(initialQuotaValues)
  }

  return [quotaInfo, setQuotaInfo] as const
}

const responseOrderingOptions: SelectOption[] = [
  {
    id: '0',
    value: ResponseOrderingOptions.Regular,
    name: 'Fixed order'
  },
  {
    id: '1',
    value: ResponseOrderingOptions.Random,
    name: 'Randomised'
  }
]

interface AudienceCustomQuestionCardProps {
  audience: FieldworkAudience | undefined
  isAudienceInvalid?: boolean
  noQualificationsError?: boolean
  ordering?: ResponseOrderingOptions
  shouldTitleInputFocus?: boolean
  onDelete: () => void
  onUpdateTitle: (newTitle: string) => void
  onToggleQuotas: () => void
  onAddOption: () => void
  onLeavingCard?: () => void
  onSumQuotas: () => Promise<void>
  onSwitchQuestionType?: (isSingleSelect: boolean) => void
  onOrderingChange?: (ordering: ResponseOrderingOptions) => void
  onSetScreeningQuestionResponseLimit?: (limit: number) => void
  onToggleOffScreeningQuestionResponseLimit?: () => void
  isHardDisqualification: boolean
  onIsHardDisqualificationChange: (isHardDisqualification: boolean) => void
  isFdchatProject?: boolean
}

const AudienceCustomQuestionCard = ({
  audience,
  isAudienceInvalid,
  noQualificationsError,
  ordering = ResponseOrderingOptions.Regular,
  shouldTitleInputFocus,
  onDelete,
  onUpdateTitle,
  onAddOption,
  onToggleQuotas,
  onLeavingCard,
  onSumQuotas,
  onSwitchQuestionType,
  onOrderingChange,
  onSetScreeningQuestionResponseLimit,
  onToggleOffScreeningQuestionResponseLimit,
  isHardDisqualification,
  onIsHardDisqualificationChange,
  isFdchatProject
}: AudienceCustomQuestionCardProps) => {
  const customAudience = audience?.memberItem as DraftCustomAudience
  const { question, questionLk, settingValues, criterionOptions } =
    customAudience
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const [title, setTitle] = useNotifyingState(question?.text, onUpdateTitle)
  const [quotaInfo, setQuotaInfo] = useQuotaInfo(criterionOptions)
  const totalAssignedQuota = calculateTotalQuota(quotaInfo)

  const qualifyingEnabled = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.Qualifying
  )
  const quotasEnabled = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.Quotas
  )

  const isSingleChoice = getIsValueInSettings(
    settingValues,
    MemberSettingCode.CustomAudienceChoice,
    SettingValue.SingleChoice
  )

  const handleUpdateQuotaValues = useCallback(
    (responseOptionLk: string, value: number) => {
      setQuotaInfo(
        produce((newQuotaValues) => {
          newQuotaValues[responseOptionLk].value =
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            value === 0 || Number.isNaN(value) || value === undefined
              ? 0
              : percentToProportion(value)
        })
      )
    },
    [setQuotaInfo]
  )

  const qualifyingResponseOptionsCount = criterionOptions.filter(
    (criterionOption) => criterionOption.qualification
  ).length
  const disqualifyingResponseOptionsCount =
    criterionOptions.length - qualifyingResponseOptionsCount

  return (
    <ScrollElement elementId={`${SCROLL_ID_PREFIX}${questionLk}`}>
      <Grid
        item
        key={questionLk}
        id={`screeningCard-${questionLk}`}
        onFocus={onLeavingCard}
        style={{
          margin: isFdchatProject ? '2px 32px 32px 32px' : '32px'
        }}
      >
        <Paper>
          <PaperGroupItem>
            <AudienceQuestionCardHeader
              questionLk={questionLk}
              defaultTitle={title}
              hasError={isAudienceInvalid}
              isTitleEditable
              isCustomAudienceSingleChoice={isSingleChoice}
              shouldTitleInputFocus={shouldTitleInputFocus}
              onSwitchQuestionType={onSwitchQuestionType}
              onClickLeftIcon={onDelete}
              onChange={setTitle}
              isFdchatProject={isFdchatProject}
              hasDisqualifyingOption={disqualifyingResponseOptionsCount > 0}
            />
          </PaperGroupItem>
          <PaperGroupItem>
            <Select
              minWidth={130}
              noWhiteSpaceAfterSelection
              optionValue={ordering}
              variant={SelectVariant.Underlined}
              options={responseOrderingOptions}
              onChange={(event) => {
                const option = event.target.value as ResponseOrderingOptions
                if (onOrderingChange) {
                  onOrderingChange(option)
                }
              }}
            />
          </PaperGroupItem>
          <PaperGroupItem paddingTopBottom={2}>
            <OptionEditorHeader
              hasQualifications={qualifyingEnabled}
              hasQuotas={quotasEnabled}
              hasQualificationError={noQualificationsError}
            />
            <CustomAudienceResponseOptionsList
              questionLk={questionLk}
              isAudienceInvalid={isAudienceInvalid}
              onSetQuotaInfo={handleUpdateQuotaValues}
            />
            <QuotaEditorFooter
              button={
                <LinkButton
                  highlight={TextHighlight.Emphasis}
                  size={TextSize.m}
                  weight={TextWeight.SemiBold}
                  onClick={onAddOption}
                  noPadding
                >
                  Add response option
                </LinkButton>
              }
              totalPercentage={totalAssignedQuota}
              showTotal={quotasEnabled}
              isCustomQuestion
              stickRight={quotasEnabled && !qualifyingEnabled}
            />
          </PaperGroupItem>
          {!isFdchatProject && (
            <PaperGroupItem>
              {isSingleChoice ? (
                <AudienceQuestionCardFooter
                  questionLk={questionLk}
                  isSingleChoice={true}
                  defaultQuotasState={quotasEnabled}
                  showQuotaSumSpread={
                    quotasEnabled && !isQuotaSumValid(totalAssignedQuota)
                  }
                  onToggleQuotasState={onToggleQuotas}
                  onSumQuotas={onSumQuotas}
                  isSumQuotasButtonDisabled={
                    qualifyingResponseOptionsCount <= 0
                  }
                />
              ) : (
                <AudienceQuestionCardFooter
                  questionLk={questionLk}
                  qualifyingResponsesCount={qualifyingResponseOptionsCount}
                  isSingleChoice={false}
                  isHardDisqualification={isHardDisqualification}
                  onIsHardDisqualificationChange={
                    onIsHardDisqualificationChange
                  }
                  screeningQuestionResponseLimit={getScreeningQuestionResponseLimit(
                    settingValues
                  )}
                  onSetScreeningQuestionResponseLimit={
                    onSetScreeningQuestionResponseLimit
                  }
                  onToggleOffScreeningQuestionResponseLimit={
                    onToggleOffScreeningQuestionResponseLimit
                  }
                />
              )}
            </PaperGroupItem>
          )}
        </Paper>
      </Grid>
    </ScrollElement>
  )
}

export default memo(AudienceCustomQuestionCard)
