import { makeStyles, theme } from '@focaldata/cin-ui-components'

const { palette } = theme

const useStyles = makeStyles()({
  sectionLayoutFooter: {
    display: 'flex',
    p: {
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', -2, 'auto'),
      padding: theme.spacing(0.5, 2),
      backgroundColor: palette.background.default,
      textTransform: 'uppercase'
    }
  },
  randomiseQuestionsIndicator: {
    marginRight: theme.spacing(1)
  }
})

export default useStyles
