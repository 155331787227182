import {
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './DialogDuplicateProject.styles'

const DialogDuplicateProject: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Icon
          name={IconName.PenAndPaper}
          iconColor={IconColor.Background}
          size={IconSize.Medium}
        />
        <Icon
          name={IconName.ArrowForwardRounded}
          iconColor={IconColor.Background}
          size={IconSize.Small}
        />
        <Icon
          name={IconName.Folder}
          iconColor={IconColor.Background}
          size={IconSize.Medium}
        />
      </Grid>
      <Grid item>
        <Text
          size={TextSize.ml}
          className={classes.infoDuplicate}
          highlight={TextHighlight.Background}
        >
          If you duplicate, you will be taken to a copy of this project that you
          can then edit and launch.
        </Text>
      </Grid>
    </Grid>
  )
}

export default DialogDuplicateProject
