import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  switchLabel: {
    padding: theme.spacing(3, 0, 1, 2)
  },
  divider: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4.5)
  }
})

export default useStyles
