import {
  Dialog,
  DialogMaxWidth,
  Grid,
  Input,
  InputFontSize,
  InputVariant,
  InputWeight,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React, { useState } from 'react'
import { MAILTO_LINK } from '../../modules/Questionnaire/constants'
import useStyles from './styles'

interface Props {
  isDialogFollowUpOpen: boolean
  handleRequestFollowUp: (respondentIds: string[]) => void
  onClose: () => void
  loading: boolean
}

const DialogFollowUp: React.FC<Props> = (props: Props) => {
  const [respondentIds, setRespondentIds] = useState<string[]>([])
  const { isDialogFollowUpOpen, handleRequestFollowUp, onClose, loading } =
    props

  const handleChange: (text: string) => void = (text) => {
    // Splits string by white space, commas and new lines. Removes any empty strings
    const respondentIds = text
      .replace(/,\s+/g, ',')
      .split(/[\n,\s+]/)
      .filter((id) => id.length !== 0)
    setRespondentIds(respondentIds)
  }

  const { classes } = useStyles()
  const isRequestFollowUpButtonDisabled = respondentIds.length === 0
  return (
    <Dialog
      open={isDialogFollowUpOpen}
      onClose={() => onClose()}
      primaryButtonClick={() => {
        handleRequestFollowUp(respondentIds)
      }}
      title="Follow up with respondents"
      primaryButtonText="Request follow up"
      maxWidth={DialogMaxWidth.Sm}
      onClickOutside={() => onClose()}
      primaryButtonDisabled={isRequestFollowUpButtonDisabled}
      primaryButtonLoading={loading}
    >
      <Grid item className={classes.textContainer}>
        <Text size={TextSize.ml} highlight={TextHighlight.Background}>
          Copy across the respondent IDs (e.g. fd7057223951) you want to follow
          up with. You can find the IDs in the raw data file.
        </Text>
      </Grid>
      <Input
        variant={InputVariant.Outlined}
        placeholder="Add respondent IDs here"
        fullWidth
        rows={7}
        multiline
        inputWeight={InputWeight.Regular}
        inputFontSize={InputFontSize.m}
        onChange={(event) => {
          handleChange(event.target.value)
        }}
      />
      <Grid item className={classes.supportEmailContainer}>
        <Divider />
      </Grid>
      <Grid container className={classes.supportTextContainer}>
        <Text
          size={TextSize.m}
          highlight={TextHighlight.Background}
          marginRight
        >
          Email
        </Text>
        <Text size={TextSize.m} weight={TextWeight.SemiBold} marginRight>
          <a className={classes.mailtoLink} href={`mailto:${MAILTO_LINK}`}>
            {MAILTO_LINK}
          </a>
        </Text>
        <Text size={TextSize.m} highlight={TextHighlight.Background}>
          if you need any help.
        </Text>
      </Grid>
    </Dialog>
  )
}

export default DialogFollowUp
