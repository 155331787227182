import { Grid, Skeleton, textStyleUtils } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import useSubheaderSizing from '../../hooks/useSubheaderSizing'
import useStyles from './styles'

const CompleteInfoHeaderLoader: React.FC = () => {
  const { isSubheaderCondensed, subheaderRightSideMaxWidth } =
    useSubheaderSizing({ isPublic: false })
  const { classes } = useStyles({
    styleProps: { width: subheaderRightSideMaxWidth }
  })

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs="auto">
        <Grid container item direction="row">
          {!isSubheaderCondensed && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight
              )}
            >
              Country:{' '}
            </p>
          )}
          <Skeleton width={20} height={21} />
        </Grid>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.dividerFielderwork}
        />
      </Grid>
      <Grid item xs="auto">
        <Grid container item direction="row">
          {!isSubheaderCondensed && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight,
                textClasses.alignCenter
              )}
            >
              Fieldwork:{' '}
            </p>
          )}
          <Skeleton width={210} height={21} />
        </Grid>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.dividerCost}
        />
      </Grid>
      <Grid item xs="auto" className={classes.costContainer}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.costContainer}
        >
          <Skeleton width={50} height={21} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompleteInfoHeaderLoader
