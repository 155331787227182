import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EventType } from '../../../../amplitude/eventType'
import { LogPublicAmplitudeEvent } from '../../../../amplitude/public'
import useSurveyResult from '../../../../apollo/localState/useSurveyResult'
import { ProjectMetadata } from '../../../../data/model/results'
import { SurveyParams } from '../../../../data/model/surveyParams'
import { downloadXlsxFile } from '../../../../utils/chartUtils'
import { downloadPptxFile } from '../../../../utils/pptxUtils'
import DialogExportToPowerPoint from '../../../Results/DialogExportToPowerPoint/DialogExportToPowerPoint'
import PublicResultsOptionsControl from './PublicResultsOptions.control'

interface Props {
  isMultiMarket: boolean
  disableShowQuotas: boolean
  projectMetadata: ProjectMetadata
  setShowQuotas: (show: boolean) => void
}

const PublicResultsOptionsContainer: React.FC<Props> = ({
  isMultiMarket,
  disableShowQuotas,
  projectMetadata,
  setShowQuotas
}: Props) => {
  const [isExportToPptxDialogOpen, setIsExportToPptxDialogOpen] =
    useState<boolean>(false)
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { readSurveyResult } = useSurveyResult()

  const handleDownloadChartData = (): void => {
    const surveyResult = readSurveyResult()

    if (!surveyResult.length) {
      return
    }

    downloadXlsxFile({
      data: surveyResult,
      fileName: `${projectMetadata.projectName}.xlsx`
    })
    LogPublicAmplitudeEvent(EventType.DownloadChartDataFromPublicResults, {
      surveyId
    })
  }

  const openExportToPptxDialog = (): void => {
    setIsExportToPptxDialogOpen(true)
  }

  const handleExportToPptx = (): void => {
    const surveyResult = readSurveyResult()

    if (!surveyResult.length) {
      return
    }

    downloadPptxFile(
      surveyResult,
      projectMetadata.fieldwork,
      projectMetadata.projectName
    )

    setIsExportToPptxDialogOpen(false)
    LogPublicAmplitudeEvent(EventType.DownloadPptxFromPublicResults, {
      surveyId
    })
  }

  return (
    <>
      <PublicResultsOptionsControl
        isMultiMarket={isMultiMarket}
        disableShowQuotas={disableShowQuotas}
        onShowQuotasChange={setShowQuotas}
        onClickChartData={handleDownloadChartData}
        onClickExportToPptx={openExportToPptxDialog}
      />
      <DialogExportToPowerPoint
        isDialogOpen={isExportToPptxDialogOpen}
        onExportToPptx={handleExportToPptx}
        onClose={() => setIsExportToPptxDialogOpen(false)}
      />
    </>
  )
}

export default PublicResultsOptionsContainer
