import { Badge, Status, Variant, theme } from '@focaldata/cin-ui-components'

interface Props {
  increment?: string
}

const IncrementBadgeContainer: React.FC<Props> = (props: Props) => {
  const { increment } = props

  return increment ? (
    <Badge
      variant={Variant.filled}
      label={`${increment} Increments`}
      status={Status.LIVE}
      style={{ marginLeft: theme.spacing(2) }}
    />
  ) : null
}

export default IncrementBadgeContainer
