import React, { useContext, useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { AudienceCriterionState } from '../../../data/gql-gen/questionnaire/graphql'
import { StandardCriterion } from '../../../data/model/audience'
import { useCreateAudienceMemberV2 } from '../../../hooks/audience/createAudienceMemberV2'
import { useRemoveAudienceMemberV2 } from '../../../hooks/audience/removeAudienceMemberV2'
import useStandardAudienceListGqlOperations from '../../../hooks/audience/useStandardAudienceListGqlOperations'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import AudienceContext, { setNewAudienceQuestionLk } from '../Audience.context'
import StandardAudienceListControl from './StandardAudienceList.control'

const StandardAudienceList: React.FC = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [showAdvancedCriteria, setShowAdvancedCriteria] =
    useState<boolean>(false)
  const { audienceCategories, audienceQuestions } =
    useStandardAudienceListGqlOperations()
  const createAudienceMemberV2 = useCreateAudienceMemberV2()
  const removeAudienceMemberV2 = useRemoveAudienceMemberV2()

  const { dispatch } = useContext(AudienceContext)

  const handleAudienceChange: (criterion: StandardCriterion) => void = (
    criterion
  ) => {
    if (criterion.state === AudienceCriterionState.Enabled) {
      removeAudienceMemberV2(criterion.criterion.question.questionId)
    } else {
      const audienceQuestionsNr: number | undefined =
        audienceQuestions?.members.length
      if (audienceQuestionsNr !== undefined) {
        createAudienceMemberV2({
          variables: {
            input: {
              projectId,
              surveyId,
              position: audienceQuestionsNr || 0,
              audienceCriterionCode: criterion.criterion.code
            }
          }
        })

        dispatch(setNewAudienceQuestionLk(undefined))

        LogAmplitudeEvent(EventType.AddedTargetingCriteria, { surveyId })
      }
    }
  }

  const handleShowAdvanceCriteria = () => {
    setShowAdvancedCriteria(!showAdvancedCriteria)
  }

  const standardAudienceCategories =
    audienceCategories &&
    audienceCategories
      .slice() // "Because the array is frozen in strict mode, you'll need to copy the array before sorting it"
      .sort((categoryA, categoryB) => categoryA.position - categoryB.position)

  const defaultCriteria = standardAudienceCategories?.slice(0, 2) // Demographic and Geographic are in position 0 and 1

  return (
    <StandardAudienceListControl
      showAdvancedCriteria={showAdvancedCriteria}
      standardAudienceCategories={
        showAdvancedCriteria ? standardAudienceCategories : defaultCriteria
      }
      validationErrors={audienceQuestions?.validationErrors}
      onAudienceChange={handleAudienceChange}
      onShowAdvancedCriteria={handleShowAdvanceCriteria}
    />
  )
}

export default StandardAudienceList
