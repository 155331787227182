import {
  Grid,
  Radio,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction } from 'react'
import { DataFormat, OutputType } from '../../data/model/shared'
import useStyles from './styles'

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  dataFormat: DataFormat
  // eslint-disable-next-line react/no-unused-prop-types
  setDataFormat: Dispatch<SetStateAction<DataFormat>>
  outputType: OutputType
  setOutputType: Dispatch<SetStateAction<OutputType>>
}

const DialogDataTables: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  const { outputType, setOutputType } = props
  // TODO: uncomment after the below todo is done
  // const { fileFormat, outputType, setFileFormat, setOutputType } = props

  return (
    <Grid container direction="column">
      <Grid item direction="column">
        <Text size={TextSize.ml} highlight={TextHighlight.Background}>
          After you submit your request, we&apos;ll contact you to confirm the
          specific details, including cross-breaks, costs and timings.
        </Text>
        <br />
        <Text size={TextSize.ml} highlight={TextHighlight.Background}>
          We charge a flat fee of £500, regardless of complexity, which includes
          two re-runs. Should you require any additional runs, a charge of £200
          will apply.
        </Text>
        <br />
        <Text size={TextSize.ml} highlight={TextHighlight.Background}>
          We aim to deliver data tables within one business day.
        </Text>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        className={classes.tableOutputType}
      >
        <Radio
          label="Weighted"
          checked={outputType === OutputType.Weighted}
          onChange={() => {
            setOutputType(OutputType.Weighted)
          }}
        />
        <Radio
          label="Unweighted"
          checked={outputType === OutputType.Unweighted}
          onChange={() => {
            setOutputType(OutputType.Unweighted)
          }}
        />
      </Grid>
      {/* TODO: Add back once we have the funcionality for it */}
      {/* <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Radio
          label="PDF"
          checked={fileFormat === FileFormat.PDF}
          onChange={() => {
            setFileFormat(FileFormat.PDF)
          }}
        />
        <Radio
          label="XLSX (Excel)"
          checked={fileFormat === FileFormat.XLSX}
          onChange={() => {
            setFileFormat(FileFormat.XLSX)
          }}
        />
      </Grid> */}
    </Grid>
  )
}

export default DialogDataTables
