import { ScrollTo } from '@focaldata/cin-ui-components'
import React, { memo, useContext, useEffect } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceCriterionOption,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import { useCreateStandardCriterionOptionV2 } from '../../../hooks/audience/createStandardCriterionOptionV2'
import { useRemoveAudienceMemberV2 } from '../../../hooks/audience/removeAudienceMemberV2'
import { useRemoveCriterionOptionV2 } from '../../../hooks/audience/removeCustomCriterionOptionV2'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { percentToProportion } from '../../../utils/HelperFunctions'
import AudienceContext, { setNewAudienceQuestionLk } from '../Audience.context'
import { addOrUpdateSettingValue } from '../CustomAudienceList/CustomAudienceList.utils'
import { AUDIENCE_SCROLL_CONTAINER_ID, SCROLL_ID_PREFIX } from '../constants'
import {
  getIsEnabledInSettings,
  getStandardAudienceValidationErrors
} from '../utils'
import { AgeRangeType } from './AgeRangeSwitch'
import AudienceQuestionCardControl from './AudienceQuestionCard.control'
import { useSumQuotas, useSurveyLengthData } from './AudienceQuestionCard.hooks'

interface Props {
  audience: FieldworkAudience
}

const AudienceQuestionCard: React.FC<Props> = (props: Props) => {
  const { audience }: Props = props
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { loi, totalCompletes } = useSurveyLengthData()
  const sumQuotas = useSumQuotas(audience, loi)
  const createStandardCriterionOptionV2 = useCreateStandardCriterionOptionV2()
  const removeCriterionOptionV2 = useRemoveCriterionOptionV2()
  const { draftAudience } = useGetDraftAudience()

  const { dispatch, audienceState } = useContext(AudienceContext)
  const entry: DraftStandardAudience =
    audience.memberItem as DraftStandardAudience

  const {
    updateAudienceMemberV2,
    updateAudienceMemberV2Settings,
    updateStandardMemberV2Quotas
  } = useUpdateAudienceMemberV2()
  const removeAudienceMemberV2 = useRemoveAudienceMemberV2()

  const validationErrors = getStandardAudienceValidationErrors(
    draftAudience?.validationErrors,
    entry.criterion.code
  )
  useEffect(() => {
    if (
      entry.criterion.question.questionId ===
      audienceState.newAudienceQuestionLk
    ) {
      setTimeout(() => {
        ScrollTo(
          `${SCROLL_ID_PREFIX}${entry.criterion.code}`,
          AUDIENCE_SCROLL_CONTAINER_ID
        )
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDelete: () => void = () => {
    removeAudienceMemberV2(entry.criterion.question.questionId)

    const filteredStandardAudienceCount =
      (draftAudience?.members.filter(
        (aud) => aud.memberType === FieldworkAudienceType.Standard
      ).length as number) - 1

    if (filteredStandardAudienceCount === 0)
      LogAmplitudeEvent(EventType.DeletedAllTargetingVariables, { surveyId })
  }

  const handleOptionChanged = async (
    option: FieldworkAudienceCriterionOption
  ) => {
    if (option.qualification) {
      await removeCriterionOptionV2(
        entry.criterion.question.questionId,
        option.criterionOption.responseOption.responseOptionId
      )
    } else {
      await createStandardCriterionOptionV2(
        entry.criterion.question.questionId,
        option.criterionOption.responseOption.responseOptionId
      )
    }
  }

  const handleToggleQuotas: () => void = () => {
    const newSetting = {
      code: MemberSettingCode.Quotas,
      value: getIsEnabledInSettings(
        entry.settingValues,
        MemberSettingCode.Quotas
      )
        ? SettingValue.Disabled
        : SettingValue.Enabled
    }
    const newSettingValues = addOrUpdateSettingValue(
      entry.settingValues,
      newSetting
    )
    updateAudienceMemberV2Settings(
      entry.criterion.question.questionId,
      newSettingValues.map((s) => ({ code: s.code, value: s.value }))
    )
  }

  const handleToggleAddedToSurvey = (): void => {
    const isHideQuestionEnabled = getIsEnabledInSettings(
      entry.settingValues,
      MemberSettingCode.HideSurveyQuestion
    )
    const newSetting = {
      code: MemberSettingCode.HideSurveyQuestion,
      value: isHideQuestionEnabled
        ? SettingValue.Disabled
        : SettingValue.Enabled
    }
    const newSettingValues = addOrUpdateSettingValue(
      entry.settingValues,
      newSetting
    )
    updateAudienceMemberV2Settings(
      entry.criterion.question.questionId,
      newSettingValues.map((s) => ({ code: s.code, value: s.value }))
    )
    LogAmplitudeEvent(
      isHideQuestionEnabled
        ? EventType.RemoveTargetingFromSurvey
        : EventType.AddTargetingToSurvey,
      {
        surveyId,
        audienceCriterionCode: entry.criterion.code
      }
    )
  }

  const handleOptionQuotaChanged: (
    option: FieldworkAudienceCriterionOption,
    newValue: number
  ) => void = (option, newValue) => {
    updateStandardMemberV2Quotas(entry.criterion.question.questionId, [
      {
        quota: Number.isNaN(newValue) ? 0 : percentToProportion(newValue),
        responseOptionId: option.criterionOption.responseOption.responseOptionId
      }
    ])
  }

  const handleEnteringCard: () => void = () => {
    if (audienceState.newAudienceQuestionLk !== entry.criterion.code) {
      dispatch(setNewAudienceQuestionLk(undefined))
    }
  }

  const handleChangeAll: (selected: boolean) => void = (selected) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId: entry.criterion.question.questionId,
          updateStandardMember: {
            setAllStandardQualification: selected
          }
        }
      }
    })
  }

  const handleAgeRangeTypeChange = (value: AgeRangeType) => {
    const newSetting = {
      code: MemberSettingCode.CustomizedCriterionOptions,
      value: value === 'custom' ? SettingValue.Enabled : SettingValue.Disabled
    }
    const newSettingValues = addOrUpdateSettingValue(
      entry.settingValues,
      newSetting
    )
    updateAudienceMemberV2Settings(
      entry.criterion.question.questionId,
      newSettingValues.map((s) => ({ code: s.code, value: s.value }))
    )
  }

  return (
    <AudienceQuestionCardControl
      audience={audience}
      onEnteringCard={handleEnteringCard}
      validationErrors={validationErrors}
      totalCompletes={totalCompletes}
      onDelete={handleDelete}
      onOptionChanged={handleOptionChanged}
      onChangeAll={handleChangeAll}
      onOptionQuotaChanged={handleOptionQuotaChanged}
      onToggleQuotasState={handleToggleQuotas}
      onSumQuotas={sumQuotas}
      onToggleAddedToSurveyState={handleToggleAddedToSurvey}
      onAgeRangeTypeChange={handleAgeRangeTypeChange}
    />
  )
}

export default memo(AudienceQuestionCard)
