import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import { TextHighlight, TextWeight } from '../../../constants/text'
import useStyles from './OptionEditorHeader.styles'

interface Props {
  hasQuotas?: boolean
  hasQualificationError?: boolean
  hasQualifications?: boolean
}

const getInputGridColumnNr: (
  hasQuota: boolean,
  hasQualification: boolean
) => 9 | 10 | 11 | 12 = (hasQuota, hasQualification) => {
  if (hasQuota) {
    return hasQualification ? 9 : 10
  }

  return hasQualification ? 11 : 12
}

const OptionEditorHeader: React.FC<Props> = (props: Props) => {
  const {
    hasQuotas = false,
    hasQualifications = false,
    hasQualificationError = false
  }: Props = props

  const { classes } = useStyles()

  return (
    <Grid container direction="row" alignItems="center">
      <Grid
        container
        item
        xs={getInputGridColumnNr(hasQuotas, hasQualifications)}
      />
      {hasQuotas && (
        <Grid container item xs={2} justifyContent="center">
          <Grid container item xs={7}>
            <Grid container item xs={10} justifyContent="center">
              <p
                style={{
                  fontWeight: TextWeight.SemiBold,
                  color: TextHighlight.Background
                }}
                className={classes.quotasTitle}
              >
                QUOTAS
              </p>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      )}
      {hasQualifications && (
        <Grid container item xs={1} justifyContent="center">
          <Grid container item xs={7} justifyContent="center">
            <p
              style={{
                fontWeight: TextWeight.SemiBold,
                color: hasQualificationError
                  ? TextHighlight.Error
                  : TextHighlight.Background
              }}
            >
              QUALIFY
            </p>
          </Grid>
          <Grid item xs={5} />
        </Grid>
      )}
    </Grid>
  )
}

export default OptionEditorHeader
