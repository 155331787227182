import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  footerPlaceholder: {
    paddingTop: theme.spacing(3)
  },
  marginBottom: {
    paddingBottom: theme.spacing(1)
  },
  root: {
    paddingBottom: theme.spacing(3)
  },
  switchesPlaceholder: {
    width: 260
  },
  sectionPlaceholder: {
    width: 'auto',
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`
  }
})

export default useStyles
