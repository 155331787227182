import { useRef, useState } from 'react'
import { FileDropZone } from './FileDropZone'
import { FileSelected } from './FileSelected'

interface Props {
  onFileSelected?: (file: File) => void
  onFileRemoved?: () => void
}

const FilePicker: React.FC<Props> = ({
  onFileSelected,
  onFileRemoved
}: Props) => {
  const [file, setFile] = useState<File>()
  const [isValidFile, setIsValidFile] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)

  const onSelectFile = (file: File) => {
    setFile(file)
    onFileSelected?.(file)
  }

  const onRemoveFile = () => {
    setIsValidFile(false)
    setFile(undefined)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    onFileRemoved?.()
  }

  return (
    <>
      {isValidFile && file ? (
        <FileSelected file={file} onRemove={onRemoveFile} />
      ) : (
        <FileDropZone
          maxSizeMb={10}
          extensions={['docx']}
          setSelectedFile={onSelectFile}
          setIsValidFile={setIsValidFile}
          setErrorMessage={setErrorMessage}
          inputRef={inputRef}
          loading={false}
          errorMessage={errorMessage}
        />
      )}
    </>
  )
}

export default FilePicker
