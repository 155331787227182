import { useQuery } from '@apollo/client'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { SidebarQuestionsQueryVariables } from '../../../../data/gql-gen/questionnaire/graphql'
import { useProjectId } from '../../../../hooks/useProjectId'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import SidebarQuestionnaireItemListControl from './SidebarQuestionnaireItemList.control'
import {
  FieldworkAudienceData,
  SIDEBAR_QUESTIONS,
  SidebarEntry
} from './SidebarQuestionnaireItemList.query'
import {
  getFlatSidebarEntries,
  getNestedSidebarEntries,
  reorderEntries
} from './SidebarQuestionnaireItemListControl.utils'

const SidebarQuestionnaireItemList: React.FC = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const { data, loading } = useQuery<
    FieldworkAudienceData,
    SidebarQuestionsQueryVariables
  >(SIDEBAR_QUESTIONS, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    },
    skip: !surveyId
  })

  const questionItems = useMemo(
    () => data?.draftQuestionnaire.entries || [],
    [data]
  )

  const [entries, setEntries] = useState<SidebarEntry[]>(questionItems)
  useEffect(() => {
    setEntries(questionItems)
  }, [questionItems])

  const handleReordereItems = (
    from: number,
    to: number,
    sectionId?: string
  ) => {
    setEntries((prevEntries) => {
      const flatEntries = getFlatSidebarEntries(prevEntries)
      const reorderedEntries = reorderEntries(flatEntries, from, to, sectionId)

      const updatedPositions = reorderedEntries.map(
        (entry, index) => ({ ...entry, position: index }) as SidebarEntry
      )

      return getNestedSidebarEntries(updatedPositions)
    })
  }

  return (
    <SidebarQuestionnaireItemListControl
      loading={loading}
      questionItems={entries}
      onReorderItems={handleReordereItems}
    />
  )
}

export default memo(SidebarQuestionnaireItemList)
