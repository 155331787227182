import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const DUPLICATE_QUESTIONNAIRE_ENTRY = questionnaireGql(/* GraphQL */ `
  mutation duplicateQuestionnaireEntryV2(
    $input: DuplicateQuestionnaireEntryV2Input!
  ) {
    duplicateQuestionnaireEntryV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)
