import { useQuery } from '@apollo/client'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { LOOPING_DATA } from '../../Audience/AudienceQuestionCardFooter/LoopingData.questionnaire.gql'

// @todo return a discriminated union for entry.entryType/entry.entryItem
export const useQuestionnaireSectionsLoopingData = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { data: loopingData } = useQuery(LOOPING_DATA, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    },
    skip: !surveyId
  })

  return loopingData?.draftQuestionnaire?.entries ?? []
}
