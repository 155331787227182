import { Theme, makeStyles } from '@focaldata/cin-ui-components'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles()((theme: Theme) => {
  const OPACITY = 0.25

  return {
    primaryColor: {
      backgroundColor: alpha(theme.palette.primary.main, OPACITY)
    },
    secondaryColor: {
      backgroundColor: alpha(theme.palette.secondary.main, OPACITY)
    },
    textColor: {
      backgroundColor: alpha(theme.palette.text.primary, OPACITY)
    },
    backgroundColor: {
      backgroundColor: alpha(theme.palette.text.secondary, OPACITY)
    },
    emphasisColor: {
      backgroundColor: alpha(theme.palette.secondary.main, OPACITY)
    },
    disabledColor: {
      backgroundColor: alpha(theme.palette.text.disabled, OPACITY)
    },
    enabledColor: {
      backgroundColor: alpha(theme.palette.success.main, OPACITY)
    },
    errorColor: {
      backgroundColor: alpha(theme.palette.error.main, OPACITY)
    },
    warningColor: {
      backgroundColor: alpha(theme.palette.warning.main, OPACITY)
    },
    whiteColor: {
      backgroundColor: alpha(theme.palette.background.default, OPACITY)
    },

    primaryBarColor: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 5
    },
    secondaryBarColor: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 5
    },
    textBarColor: {
      backgroundColor: theme.palette.text.primary,
      borderRadius: 5
    },
    backgroundBarColor: {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: 5
    },
    emphasisBarColor: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 5
    },
    disabledBarColor: {
      backgroundColor: theme.palette.text.disabled,
      borderRadius: 5
    },
    enabledBarColor: {
      backgroundColor: theme.palette.success.main,
      borderRadius: 5
    },
    errorBarColor: {
      backgroundColor: theme.palette.error.main,
      borderRadius: 5
    },
    warningBarColor: {
      backgroundColor: theme.palette.warning.main,
      borderRadius: 5
    },
    whiteBarColor: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 5
    }
  }
})

export default useStyles
