import { FetchPolicy, useQuery } from '@apollo/client'
import { Status } from '@focaldata/cin-ui-components'
import { useParams } from 'react-router-dom'
import { LoggerErrorType } from '../../data/logger'
import { PanelSupplierOrder } from '../../data/model/order'
import { SurveyParams } from '../../data/model/surveyParams'
import useGetSurveysProgress from '../../hooks/useGetSurveysProgress'
import { useSurveyId } from '../../hooks/useSurveyId'
import { captureApolloError } from '../../utils/HelperFunctions'
import {
  GET_SURVEY_ORDER_SOFT_LAUNCH,
  SoftLaunchSurveyOrderData
} from './SoftLaunch.dashboard.query'
import { SoftLaunchState } from './SoftLaunch.model'
import { GET_FIELDWORK_SOFT_LAUNCH } from './SoftLaunch.questionnaire.query'

export interface UseGetSoftLaunch {
  isConfiguredForSoftLaunch: boolean
  softLaunchSamplesNeeded: number
  remainingSamplesNeeded: number
  samplesNeeded: number
  softLaunchState: SoftLaunchState
  softLaunchStatus: Status
  isSoftLaunchCompleted: boolean
  isSoftLaunchActive: boolean
  isSoftLaunchInProgress: boolean
  isSoftLaunchDisabled: boolean
}

export interface UseGetSoftLaunchOrder extends UseGetSoftLaunch {
  isSurveyOrderLoading: boolean
  isSoftLaunchOrder: boolean
  isFullLaunchOrder: boolean
}

export interface UseGetSurveyOrder {
  surveyOrderData: SoftLaunchSurveyOrderData | undefined
  surveyOrderLoading: boolean | undefined
  surveyOrderPlaced: boolean | undefined
  panelSupplierOrders: PanelSupplierOrder[] | undefined
  isSoftLaunch: boolean | undefined
  isFullLaunch: boolean | undefined
}

export function useGetSoftLaunch(
  firstFetchPolicy?: FetchPolicy
): UseGetSoftLaunch {
  const surveyId = useSurveyId()

  const { surveyProgress } = useGetSurveysProgress([surveyId])

  const { data: fieldworkSoftLaunchData } = useQuery(
    GET_FIELDWORK_SOFT_LAUNCH,
    {
      context: { clientName: 'questionnaire' },
      variables: {
        surveyId
      },
      fetchPolicy: firstFetchPolicy,
      onError: (error) => {
        captureApolloError(LoggerErrorType.ApolloQuery, 'fieldwork', error)
      }
    }
  )

  const progress =
    surveyProgress && surveyProgress.length > 0 && surveyProgress[0].progress

  const collectedCompletes = (progress && progress.samplesCollected) || 0

  const fieldwork = fieldworkSoftLaunchData?.fieldwork
  const fieldworkSoftLaunch =
    fieldwork?.softLaunch !== null ? fieldwork?.softLaunch : undefined
  const fieldworkSamplesNeededAdjusted = fieldwork?.samplesNeeded.adjusted || 0

  const softLaunchSamplesNeeded =
    (fieldworkSoftLaunch && fieldworkSoftLaunch.samplesNeeded) || 0
  const remainingSamplesNeeded =
    fieldworkSamplesNeededAdjusted - softLaunchSamplesNeeded
  const isConfiguredForSoftLaunch = softLaunchSamplesNeeded > 0

  const stateAndStatus = {
    softLaunchState: SoftLaunchState.Disabled,
    softLaunchStatus: Status.DRAFT
  }

  const isSoftLaunchCompleted =
    isConfiguredForSoftLaunch && collectedCompletes >= softLaunchSamplesNeeded
  const isSoftLaunchActive =
    isConfiguredForSoftLaunch && collectedCompletes < softLaunchSamplesNeeded
  const isSoftLaunchInProgress =
    isConfiguredForSoftLaunch && collectedCompletes > 0 && isSoftLaunchActive
  const isSoftLaunchDisabled = !isSoftLaunchCompleted && !isSoftLaunchActive

  if (isSoftLaunchCompleted) {
    stateAndStatus.softLaunchState = SoftLaunchState.Completed
    stateAndStatus.softLaunchStatus = Status.COMPLETE
  } else if (isSoftLaunchActive) {
    stateAndStatus.softLaunchState = SoftLaunchState.Active
    stateAndStatus.softLaunchStatus = Status.LIVE
  }

  return {
    isConfiguredForSoftLaunch,
    softLaunchSamplesNeeded,
    remainingSamplesNeeded,
    samplesNeeded: fieldworkSamplesNeededAdjusted,
    ...stateAndStatus,
    isSoftLaunchCompleted,
    isSoftLaunchActive,
    isSoftLaunchInProgress,
    isSoftLaunchDisabled
  }
}

export function useGetSurveyOrder(): UseGetSurveyOrder {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const { data: surveyOrderData, loading: surveyOrderLoading } =
    useQuery<SoftLaunchSurveyOrderData>(GET_SURVEY_ORDER_SOFT_LAUNCH, {
      context: { clientName: 'dashboard' },
      variables: {
        surveyId
      }
    })

  const surveyOrderPlaced = !!surveyOrderData
  const panelSupplierOrders = surveyOrderData?.surveyOrder.panelSupplierOrders
  const isSoftLaunch = surveyOrderData?.surveyOrder.isSoftLaunch
  const isFullLaunch = panelSupplierOrders && panelSupplierOrders.length > 1

  return {
    surveyOrderData,
    surveyOrderLoading,
    surveyOrderPlaced,
    panelSupplierOrders,
    isSoftLaunch,
    isFullLaunch
  }
}

export function useGetSoftLaunchOrder(
  firstFetchPolicy?: FetchPolicy
): UseGetSoftLaunchOrder {
  const getSoftLaunchData = useGetSoftLaunch(firstFetchPolicy)

  const { isSoftLaunch, isFullLaunch, surveyOrderLoading } = useGetSurveyOrder()

  return {
    ...getSoftLaunchData,
    isSoftLaunchOrder: isSoftLaunch || false,
    isFullLaunchOrder: isFullLaunch || false,
    isSurveyOrderLoading: surveyOrderLoading || false
  }
}
