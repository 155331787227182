import { useMediaQuery, useTheme } from '@mui/material'

interface SubheaderSizing {
  subheaderLeftSideMaxWidth: string
  subheaderRightSideMaxWidth: string
  isSubheaderCondensed: boolean
}

interface Props {
  isPublic: boolean
}

export const BASE_RIGHT_SIDE_WIDTH = 520
export const BASE_RIGHT_SIDE_WIDTH_CONDENSED = 420
export const EXTRA_RIGHT_SIDE_WIDTH_PUBLIC = 50
const EXTRA_LEFT_SIDE_WIDTH = 116
const EXTRA_LEFT_SIDE_WIDTH_PUBLIC = 136

// The private right side has a condensed width of 400 and a non-condensed width of 500
// The public one has 50px extra. The EXTRA_RIGHT_SIDE_WIDTH_PUBLIC
const getRightSideContinerSize = (
  isPublic: boolean,
  condensed: boolean
): string => {
  const sizeWithoughExtraPublicSpace = condensed
    ? BASE_RIGHT_SIDE_WIDTH_CONDENSED
    : BASE_RIGHT_SIDE_WIDTH
  if (isPublic) {
    return `${sizeWithoughExtraPublicSpace + EXTRA_RIGHT_SIDE_WIDTH_PUBLIC}px`
  }

  return `${sizeWithoughExtraPublicSpace}px`
}

// This container is 100vw - the right side + some space for paddings and margins of the app.
// this space differs from public to private. EXTRA_LEFT_SIDE_WIDTH and EXTRA_LEFT_SIDE_WIDTH_PUBLIC
const getLeftSideContainerSizeDeduction = (
  isPublic: boolean,
  condensed: boolean
): string => {
  const sizeWithoughExtraPublicSpace = condensed
    ? BASE_RIGHT_SIDE_WIDTH_CONDENSED
    : BASE_RIGHT_SIDE_WIDTH
  if (isPublic) {
    return `${
      sizeWithoughExtraPublicSpace +
      EXTRA_RIGHT_SIDE_WIDTH_PUBLIC +
      EXTRA_LEFT_SIDE_WIDTH_PUBLIC
    }px`
  }

  return `${sizeWithoughExtraPublicSpace + EXTRA_LEFT_SIDE_WIDTH}px`
}

const useSubheaderSizing = ({ isPublic }: Props): SubheaderSizing => {
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

  return {
    isSubheaderCondensed: !lgAndUp,
    subheaderLeftSideMaxWidth: `calc(100vw - ${getLeftSideContainerSizeDeduction(
      isPublic,
      !lgAndUp
    )})`,
    subheaderRightSideMaxWidth: getRightSideContinerSize(isPublic, !lgAndUp)
  }
}

export default useSubheaderSizing
