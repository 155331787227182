import { Option } from '@focaldata/cin-ui-components'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import {
  DraftQuestionnaireEntry,
  EntrySettingValue,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { isSettingEnabled } from '../../../utils/questionnaireUtils'
import { flattenEntries } from '../../Questionnaire/Questionnaire.utils'
import { useQuestionnaireSectionsLoopingData } from '../../Questionnaire/SectionHooks/Section.hooks'

export type LoopBasisOption = Pick<Option, 'id' | 'name'> & {
  value: string
}

export const useLoopBasisOptions = (): LoopBasisOption[] => {
  const questionnaireEntries = useQuestionnaireSectionsLoopingData()

  const flattenedEntries = flattenEntries(
    questionnaireEntries as DraftQuestionnaireEntry[]
  )

  const sectionsWithLooping = questionnaireEntries.filter(
    ({ entryItem }) =>
      entryItem.__typename === 'DraftSectionItem' &&
      entryItem.loopingConfig &&
      isSettingEnabled(
        entryItem.settingValues as EntrySettingValue[],
        QuestionSettingCode.Looping
      )
  )

  const options: LoopBasisOption[] = [
    {
      id: 'Random',
      name: 'Random selection',
      value: 'Random'
    }
  ]

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const loopBasisOptions = sectionsWithLooping?.flatMap(
    ({ entryItem, contextPosition }) => {
      const { loopingConfig, sectionId } = entryItem as DraftSectionItem
      const sourceEntry = flattenedEntries.find(
        (entry) => entry.number === loopingConfig?.sourceEntryNumber
      )

      if (sourceEntry?.entryType !== EntryType.MatrixEntryType) {
        return []
      }

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const sourceEntryPrefix = sourceEntry
        ? `Q${sourceEntry.contextPosition + 1}`
        : ''

      return [
        {
          id: sectionId,
          name: `S${contextPosition + 1} - Loop based on ${sourceEntryPrefix}`,
          value: sectionId
        }
      ]
    }
  )

  return [...options, ...loopBasisOptions]
}
