import {
  Theme,
  additionalPalette,
  makeStyles
} from '@focaldata/cin-ui-components'
import { CSSProperties } from 'react'
import { isMasterSurveyPage } from '../../utils/HelperFunctions'

const combinedHeaderHeight = 97
const footerHeight = 56
const sectionsRandomisationButtonHeight = 54
const masterSurveyHeaderHeight = 187

interface StyleProps {
  maxWidth: number
  height?: CSSProperties['height']
  sidebarHeight?: CSSProperties['height']
}

export const useStyles = makeStyles<{ styleProps: StyleProps }>()((
  theme: Theme,
  { styleProps: props }
) => {
  const pageHeaderHeight = isMasterSurveyPage()
    ? masterSurveyHeaderHeight
    : combinedHeaderHeight
  const content = {
    width: 'calc(100% - 336px)',
    padding: theme.spacing(5.25, 3.5),
    height: '100%',
    overflowY: 'scroll' as any
  }
  const sidebar = {
    paddingLeft: theme.spacing(2),
    backgroundColor: additionalPalette.lightBlue.main,
    maxWidth: props.maxWidth,
    height:
      props.sidebarHeight ??
      `calc(100vh - ${pageHeaderHeight}px - ${footerHeight}px)`
  }
  return {
    content,
    contentWithHeader: {
      ...content,
      height: '100%'
    },
    contentNoPadding: {
      width: 'calc(100% - 336px)',
      padding: 0,
      height: '100%',
      overflowY: 'scroll'
    },
    root: {
      height: props.height ?? '100%'
    },
    sidebar,
    sidebarWithHeader: {
      ...sidebar,
      height:
        props.sidebarHeight ??
        `calc(100vh - ${pageHeaderHeight}px - ${footerHeight}px - ${sectionsRandomisationButtonHeight}px)`
    },
    sidebarWrapper: {
      backgroundColor: additionalPalette.lightBlue.main,
      maxWidth: props.maxWidth
    }
  }
})
