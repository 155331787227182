import { useEffect } from 'react'
import { useAppDispatch } from '../App.store'
import { loadQuestionnaire } from '../middleware/apollo'
import {
  checkIsFDChatResultsPage,
  checkIsResultsPage
} from '../utils/HelperFunctions'
import { useProjectId } from './useProjectId'
import { useSurveyId } from './useSurveyId'

const usePrepareApolloMiddleware = () => {
  const surveyId = useSurveyId()
  const projectId = useProjectId()
  const isResultsPage = checkIsResultsPage()
  const isFDChatResultsPage = checkIsFDChatResultsPage()
  const appDispatch = useAppDispatch()

  useEffect(() => {
    appDispatch(
      loadQuestionnaire(
        isResultsPage || isFDChatResultsPage ? null : { surveyId, projectId }
      )
    )
  }, [appDispatch, projectId, surveyId, isResultsPage, isFDChatResultsPage])

  useEffect(() => {
    return () => {
      appDispatch(loadQuestionnaire(null))
    }
  }, [appDispatch])
}

export default usePrepareApolloMiddleware
