import {
  Alert,
  AlertType,
  Dialog,
  DialogMaxWidth,
  Grid,
  InfoIcon,
  Radio,
  Switch,
  Text,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { DataFormat, OutputType } from '../../data/model/shared'
import useStyles from './DialogRawData.styles'

enum Format {
  Long = 0,
  Wide = 1,
  SPSS = 2,
  Excel = 3
}

const getDataFormat = (encoded: boolean, format: Format): DataFormat => {
  if (encoded === false && format === Format.Long) {
    return DataFormat.LONG_UNENCODED
  }
  if (encoded === true && format === Format.Long) {
    return DataFormat.LONG_ENCODED
  }
  if (encoded === false && format === Format.Wide) {
    return DataFormat.WIDE_UNENCODED
  }
  if (encoded === true && format === Format.Wide) {
    return DataFormat.WIDE_ENCODED
  }
  if (format === Format.SPSS) {
    return DataFormat.SPSS
  }
  if (format === Format.Excel) {
    return DataFormat.XLSX_UNENCODED
  }

  return DataFormat.WIDE_UNENCODED
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onRequestData: (outputType: OutputType, dataFormat: DataFormat) => void
  loading: boolean
}

const RawData: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, onRequestData, loading }: Props = props
  const { classes } = useStyles()

  const [encoded, setEncoded] = useState<boolean>(false)
  const [format, setFormat] = useState<Format>(Format.Wide)

  const isFormatSPSS = format === Format.SPSS
  const hiddenEncodedSwitch = isFormatSPSS || format === Format.Excel
  const encodedSwitchValue = isFormatSPSS ? false : encoded

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      primaryButtonClick={() => {
        onRequestData(OutputType.Unweighted, getDataFormat(encoded, format))
      }}
      title="Export data"
      primaryButtonText="Export"
      maxWidth={DialogMaxWidth.Sm}
      onClickOutside={onClose}
      primaryButtonDisabled={loading}
    >
      <Grid container direction="column">
        <Grid container className={classes.optionContainer} direction="column">
          <Radio
            condensed
            onChange={() => setFormat(Format.Wide)}
            checked={format === Format.Wide}
            label={<Text weight={TextWeight.Bold}>Wide (.csv)</Text>}
          />

          <Text className={classes.radioSubtext}>
            One row per respondent. Columns are questions.
          </Text>
          <Radio
            condensed
            onChange={() => setFormat(Format.Long)}
            checked={format === Format.Long}
            label={<Text weight={TextWeight.Bold}>Long (.csv)</Text>}
          />
          <Text className={classes.radioSubtext}>
            One row for each individual response.
          </Text>
          <Radio
            condensed
            onChange={() => setFormat(Format.Excel)}
            checked={format === Format.Excel}
            label={<Text weight={TextWeight.Bold}>Excel (.xlsx)</Text>}
          />

          <Text className={classes.radioSubtext}>
            Wide format. One row per respondent. Columns are questions.
          </Text>
          <Radio
            condensed
            onChange={() => {
              setFormat(Format.SPSS)
            }}
            checked={isFormatSPSS}
            label={<Text weight={TextWeight.Bold}>SPSS (.sav)</Text>}
          />

          <Text className={classes.radioSubtext}>
            Wide format. Ready for SPSS data analysis.
          </Text>
        </Grid>
        {isFormatSPSS && (
          <Grid
            container
            alignItems="center"
            className={classes.alertContainer}
          >
            <Alert severity={AlertType.WARNING}>
              Please note that SPSS files truncate variable and value labels
              that exceed 255 and 120 characters, respectively.
            </Alert>
          </Grid>
        )}
        {!hiddenEncodedSwitch && (
          <Grid
            container
            alignItems="center"
            className={classes.switchContainer}
          >
            <Switch
              checked={encodedSwitchValue}
              disabled={hiddenEncodedSwitch}
              onChange={() => setEncoded(!encoded)}
              label="Encode data"
              first
              last
            />
            <InfoIcon text="Encoded data gives numeric values for response options and questions. You receive a separate sheet for the title and response option text." />
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default RawData
