import { MaskingRuleClause } from '../../../../data/gql-gen/questionnaire/graphql'

export interface MaskingKey {
  questionLk: string
  responseOptionLk: string | null
  matrixTitleLk: string | null
  __typename: string
}

export interface MaskingRuleForDialog {
  clause: MaskingRuleClause
  matrixTitleLk?: string
  questionLk: string
  sourceResponseOptions: string[]
  negated: boolean
  isSourceFork?: boolean
}

export enum MaskingRuleNegation {
  IS_ONE_OF = 'is one of',
  IS_NOT_ONE_OF = 'is NOT one of'
}
