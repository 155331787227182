import { AnswersApi, AskAIAnswerResponseInput } from '@focaldata/askai-client'
import { useSnackbar } from '@focaldata/cin-ui-components'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v5 as uuidv5 } from 'uuid'
import { useSurveyId } from '../../../hooks/useSurveyId'
import UserSession from '../../../utils/UserSession'
import { fdChatAnalyticsApi } from '../FdChat.utils'

const answersApi = new AnswersApi(undefined, undefined, fdChatAnalyticsApi)

type AskAIAnswerRequestPayload = {
  query: string
  survey_id: string
  user_id: string
  thread_id?: string | undefined
}

export const useAskAiV3 = ({ question }: { question: string }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [answer, setAnswer] = useState<AskAIAnswerResponseInput>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const threadId = searchParams.get('threadId')

  const surveyId = useSurveyId()
  const { enqueueSnackbar } = useSnackbar()
  const userEmail = UserSession.getEmail()

  // Generate a user ID based on the user's email address
  // Is deterministic and will always return the same UUID for the same email address and namespace
  const userId = uuidv5(
    userEmail as string,
    process.env.REACT_APP_USER_ID_NAMESPACE as string
  )

  const fetchAnswer = useCallback(async () => {
    setLoading(true)
    try {
      const requestPayload: AskAIAnswerRequestPayload = {
        query: question,
        survey_id: surveyId,
        user_id: userId,
        thread_id: threadId || undefined
      }

      const response = await answersApi.askaiV1({
        askAIAnswerRequest: requestPayload
      })

      setLoading(false)
      setAnswer(response.data)
      if (!threadId && response.data.thread_id) {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('threadId', response.data.thread_id)
        setSearchParams(newSearchParams)
      }
    } catch (e: unknown) {
      const error = e as AxiosError
      setError(error.message)
      enqueueSnackbar(
        'Sorry, an error occurred. Please try asking your question again.',
        {
          variant: 'error'
        }
      )
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, question, surveyId, userId])

  useEffect(() => {
    fetchAnswer()
  }, [fetchAnswer])

  return {
    answer,
    loading,
    error,
    threadId
  }
}

export const useAskAiV3Answer = ({ answerId }: { answerId: string }) => {
  const [answer, setAnswer] = useState<AskAIAnswerResponseInput>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()

  const fetchAnswer = useCallback(async () => {
    setLoading(true)
    try {
      const response = await answersApi.getAnswerV1({
        answerId
      })
      setLoading(false)
      setAnswer(response.data)
    } catch (e: unknown) {
      const error = e as AxiosError
      setError(error.message)
      enqueueSnackbar('Sorry, an error occurred. Please try again later.', {
        variant: 'error'
      })
    }

    setLoading(false)
  }, [answerId, enqueueSnackbar])

  useEffect(() => {
    fetchAnswer()
  }, [fetchAnswer])

  return {
    answer,
    loading,
    error
  }
}
