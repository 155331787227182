import { useMutation } from '@apollo/client'
import { UpdateSurveyMutationVariables } from '../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { UPDATE_SURVEY, UpdateSurveyData } from '../Project/Project.mutations'

export const useUpdateSurvey = () => {
  const [updateSurvey, { loading: updateSurveyLoading }] = useMutation<
    UpdateSurveyData,
    UpdateSurveyMutationVariables
  >(UPDATE_SURVEY, {
    context: { clientName: 'dashboard' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'updateSurvey', error)
    }
  })

  return {
    updateSurvey,
    updateSurveyLoading
  }
}
