import { Button, ButtonVariant } from '@focaldata/cin-ui-components'
import { AxiosError } from 'axios'
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { CreateInviteRequest } from '../../../data/rest/accountUser'
import UserSession from '../../../utils/UserSession'
import { AccessToken, LoginLocationState } from '../../../utils/authUtils'
import { DuplicateInviteRequestError } from './DuplicateInviteRequestError'
import { InviteRequestGenericError } from './InviteRequestGenericError'
import InviteTeamDialogControl from './InviteTeamDialog.control'

const InviteTeamDialog: React.FC = () => {
  const [inviteTeamOpen, setInviteTeamOpen] = useState<boolean>(false)
  const location = useLocation()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { firstLogin } = (location.state as LoginLocationState) || {
    firstLogin: false
  }

  useEffect(() => {
    if (firstLogin) {
      setInviteTeamOpen(true)
    }
  }, [firstLogin])

  const handleSendInvites = async (emails: string[]) => {
    try {
      const accessToken = AccessToken.get()
      const email = UserSession.getEmail()
      const accountId = UserSession.maybeGetAccountId()
      if (accessToken && accountId && email) {
        await CreateInviteRequest(accessToken, accountId, email, emails)
        LogAmplitudeEvent(EventType.InviteTeam, {
          creatorEmailAddress: email,
          inviteeEmailAddresses: emails
        })
        return true
      }
    } catch (error: unknown) {
      const ex = error as AxiosError<{ message: string }>
      if (ex.response && ex.response.status === 409) {
        return new DuplicateInviteRequestError(ex.response.data.message)
      }
      return new InviteRequestGenericError(ex.response?.data.message)
    }
    return false
  }

  return (
    <>
      <Button
        disabled={false}
        variant={ButtonVariant.PrimaryNoOutline}
        style={{ marginRight: '10px' }}
        dataCy="invite-team-button"
        onClick={() => setInviteTeamOpen(true)}
      >
        Invite team
      </Button>
      <InviteTeamDialogControl
        dialogOpen={inviteTeamOpen}
        onCloseDialog={() => setInviteTeamOpen(false)}
        onSendInvites={handleSendInvites}
      />
    </>
  )
}

export default InviteTeamDialog
