import {
  Paper,
  PaperGroupItem,
  Skeleton,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../../data/model/questionnaire'
import { DEFAULT_CARD_PLACEHOLDER_HEIGHT } from '../constants'
import useStyles from './styles'

interface Props {
  entry: DraftQuestionnaireEntry
  height?: number
}

const EntryPlaceholder: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const { entry, height = DEFAULT_CARD_PLACEHOLDER_HEIGHT } = props

  const getLetterPrefix: (entryType: EntryType) => string = (entryType) => {
    switch (entryType) {
      case EntryType.TextCardEntryType:
        return 'T'
      case EntryType.ForkEntryType:
        return 'A/B'
      case EntryType.SectionEntryType:
        return 'S'
      default:
        return 'Q'
    }
  }

  const getDisplayNumber: (entry: DraftQuestionnaireEntry) => number | null = (
    entry
  ) => {
    if (entry.entryType === EntryType.ForkEntryType) {
      return null
    }
    return entry.contextPosition + 1
  }

  const letterPrefix = getLetterPrefix(entry.entryType)
  const displayNumber = getDisplayNumber(entry)
  const isSectionType = entry.entryType === EntryType.SectionEntryType

  return (
    <div
      data-testid="question-card-placeholder"
      className={classNames('fd-grid fd-container', classes.root, {
        [classes.sectionPlaceholder]: isSectionType
      })}
      style={{ height }}
    >
      <Paper>
        <PaperGroupItem>
          <div
            className={classNames('fd-grid fd-container', classes.marginBottom)}
          >
            <div className="fd-grid fd-item fd-xs-auto">
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.highlightBackground,
                  textClasses.weightBold
                )}
              >
                {letterPrefix}
                {displayNumber}
              </p>
            </div>
            <div className="fd-grid fd-item fd-xs-12">
              <Skeleton height={30} />
            </div>
          </div>
        </PaperGroupItem>
      </Paper>
    </div>
  )
}

export default EntryPlaceholder
