import {
  QuestionKind,
  QuestionTypeCode,
  SettingInputInput
} from '../../data/model/questionnaire'

export interface Option {
  id: string
  position?: number
  value?: string
  isNew?: boolean
  isChecked?: boolean
}

export type Row = Option

export enum EntryType {
  BasicType = 'BasicType',
  FreeTextType = 'FreeTextType',
  MatrixType = 'MatrixType',
  RankedType = 'RankedType',
  ScaleType = 'ScaleType',
  TextCardType = 'TextCardType'
}

export type RawQuestionnaireEntry = {
  id: string
  position?: number
  entryType: EntryType
  kind: QuestionKind
  questionType: QuestionTypeCode
  title: string
  styling?: string
  subtitle?: string
  options: Option[]
  setting?: SettingInputInput
  rows: Row[]
  qNum?: string
  isNew?: boolean
  isChecked?: boolean
}

export type RawQuestionnaireEntryInput = Omit<
  RawQuestionnaireEntry,
  'id' | 'isChecked' | 'qNum'
>
