import { Status } from '@focaldata/cin-ui-components'
import { ProjectType, SurveyState } from '../data/gql-gen/dashboard/graphql'

interface DownloadFileArgs {
  dataUrl?: string
  text?: string
  fileName?: string
}

export const downloadFile = ({
  dataUrl = '',
  text = '',
  fileName
}: DownloadFileArgs): void => {
  const anchor = document.createElement('a')
  anchor.href = text ? `data:text/plain;charset=utf-8,${text}` : dataUrl
  anchor.download = fileName || dataUrl
  anchor.style.display = 'none'
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const statusMap = new Map<string, Status>([
  [SurveyState.Draft, Status.DRAFT],
  [SurveyState.Completed, Status.COMPLETE],
  [SurveyState.Live, Status.LIVE],
  [SurveyState.Paused, Status.PAUSED],
  [SurveyState.Halted, Status.HALTED],
  [SurveyState.Scheduled, Status.SCHEDULED]
])

export const stringStatusMap = new Map<Status, string>([
  [Status.DRAFT, 'DRAFT'],
  [Status.COMPLETE, 'COMPLETE'],
  [Status.LIVE, 'LIVE'],
  [Status.PAUSED, 'PAUSED'],
  [Status.HALTED, 'HALTED'],
  [Status.SCHEDULED, 'SCHEDULED']
])

export const projectTypeMap = new Map<ProjectType, string>([
  [ProjectType.AdHoc, 'AD HOC'],
  [ProjectType.MultiMarket, 'MULTI MARKET']
])

export const isMultiMarketProjectType: (projectType: ProjectType) => boolean = (
  projectType
) => projectType === ProjectType.MultiMarket

export const getNextText: () => string = () => {
  const secondNow = new Date().getSeconds()
  if (secondNow % 6 < 2) {
    return 'Building columns'
  }
  if (secondNow % 6 < 4) {
    return 'Adding rows'
  }
  return 'Sprinkling numbers'
}
