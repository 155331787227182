import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'

export interface StyleProps {
  isSelected: boolean | undefined
}

const useStyles = makeStyles<{ styleProps?: StyleProps }>()((
  theme,
  { styleProps: props }
) => {
  const { palette, typography } = theme
  const questionTitle = {
    fontFamily: typography.fontFamily,
    fontSize: typography.body1.fontSize,
    fontWeight: typography.fontWeightRegular as any,
    color: palette.text.secondary,
    margin: 0,
    lineHeight: 1.5
  }

  return {
    listItem: {
      backgroundColor: additionalPalette.lightBlue.main,
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    listItemDragging: {
      backgroundColor: theme.palette.background.default,
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      '& svg': {
        color: additionalPalette.lightGrey.main
      }
    },
    draggableDiv: {
      width: '100%',
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      marginTop: theme.spacing(0.75),
      backgroundColor: props?.isSelected
        ? theme.palette.background.default
        : additionalPalette.transparent,
      '&:hover': {
        backgroundColor: theme.palette.background.default
      },
      '&:focus': {
        outline: 0
      }
    },
    nonDraggableDiv: {
      width: '100%',
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      backgroundColor: props?.isSelected
        ? theme.palette.background.default
        : additionalPalette.transparent,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer'
      },
      '&:focus': {
        outline: 0
      }
    },
    dragHandleContainer: {
      flexFlow: 'column',
      paddingTop: theme.spacing(1.25)
    },
    dragHandle: {
      color: additionalPalette.lightBlue.main
    },
    infoContainer: {
      width: '100%',
      padding: theme.spacing(1.5, 0, 1.5, 2.25)
    },
    iconContainer: {
      flexFlow: 'column',
      paddingTop: theme.spacing(1.5)
    },
    icon: {
      color: theme.palette.error.main
    },
    questionTitle,
    questionNumber: {
      ...questionTitle,
      fontWeight: typography.fontWeightMedium as any,
      color: palette.text.secondary
    },
    questionItemError: {
      color: palette.error.main
    },
    questionItemSelected: {
      color: palette.secondary.main
    }
  }
})

export default useStyles
