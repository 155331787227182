import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  iconButton: {
    margin: theme.spacing(-1.375, 0)
  },
  infoIcon: {
    display: 'inherit',
    alignItems: 'center'
  },
  label: {
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  labelPlacementStart: {
    marginLeft: 0
  },
  errorIcon: {
    marginRight: `-${theme.spacing(2.5)}`,
    marginTop: theme.spacing(1)
  },
  inputErrorClassName: {
    outline: `${theme.spacing(0.25)} solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(0.25)
  }
})

export default useStyles
