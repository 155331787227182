import { Checkbox, InfoIcon } from '@focaldata/cin-ui-components'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { EntryType } from '../../../../data/model/questionnaire'
import {
  useLoopingSelectedEntry,
  useOtherLoopCountDependingOnSourceEntry
} from '../Looping.hooks'
import {
  selectSourceChosen,
  selectStaticLoopingEnabled,
  toggleStaticLoopingForCurrentSourceEntry
} from '../Looping.slice'
import { entryHasStaticLooping } from '../Looping.utils'
import useStyles from './DialogLooping.styles'

export const StaticLoopingSection = () => {
  const dispatch = useAppDispatch()
  const staticLoopingEnabled = useAppSelector(selectStaticLoopingEnabled)
  const sourceEntry = useLoopingSelectedEntry()
  const sourceChosen = useAppSelector(selectSourceChosen)
  const loopCount = useOtherLoopCountDependingOnSourceEntry(sourceEntry?.number)
  const { classes } = useStyles()

  const anotherLoopUsesThisQuestionForStaticLooping =
    !staticLoopingEnabled &&
    loopCount > 0 &&
    sourceEntry &&
    !entryHasStaticLooping(sourceEntry)
  const sourceIsMatrix = sourceEntry?.entryType === EntryType.MatrixEntryType

  return (
    <div className={classes.staticLoopingSection}>
      <Checkbox
        label={
          <>
            Enable static looping
            <InfoIcon
              className={classes.infoIcon}
              text="Static looping uses every response option as a potential loop iteration instead of asking respondents to choose. Static looping cannot be enabled for matrix questions or if multiple loops use the same source question. WARNING: This will hide the question from respondents"
            />
          </>
        }
        checked={staticLoopingEnabled}
        disabled={
          !sourceChosen ||
          sourceIsMatrix ||
          anotherLoopUsesThisQuestionForStaticLooping
        }
        onChange={() => {
          dispatch(toggleStaticLoopingForCurrentSourceEntry())
        }}
      />
    </div>
  )
}
