import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { useLoopMaxIterationOptions } from '../Looping.hooks'
import { maxIterationsSet, selectMaxIterations } from '../Looping.slice'
import LoopIterationsSelectorComponent from './LoopIterationsSelector.component'

const LoopIterationsSelector = () => {
  const dispatch = useAppDispatch()
  const maxIterations = useAppSelector(selectMaxIterations)
  const maxIterationOptions = useLoopMaxIterationOptions()

  // if max iterations is not set or its value is bigger than maximum iteration options amount, make 'All' as default
  useEffect(() => {
    if (!maxIterations || maxIterations > maxIterationOptions.length) {
      dispatch(maxIterationsSet(maxIterationOptions.length))
    }
  }, [maxIterationOptions, maxIterations, dispatch])

  const handleChangeMaxIterations = useCallback(
    (maxIterations: number) => dispatch(maxIterationsSet(maxIterations)),
    [dispatch]
  )

  return (
    <LoopIterationsSelectorComponent
      maxIterations={maxIterations}
      maxIterationOptions={maxIterationOptions}
      onChangeMaxIterations={handleChangeMaxIterations}
    />
  )
}

export default LoopIterationsSelector
