import { Button, ButtonVariant } from '@focaldata/cin-ui-components'
import Box from '@mui/material/Box'
import React from 'react'
import { truncate } from '../../../utils/HelperFunctions'
import useStyles from './styles'

const NUMBER_OF_CHARACTERS = 7

interface OwnProps {
  onClickRightButton?: () => void
  rightButtonDisabled?: boolean
  rightButtonText?: string
  secondaryRightButtonText?: string
  onClickSecondaryRightButton?: () => void
  secondaryRightButtonDisabled?: boolean
}

export type Props = OwnProps & React.HTMLAttributes<HTMLElement>
const BottomNav: React.FC<Props> = (props: Props) => {
  const {
    onClickRightButton,
    rightButtonDisabled = false,
    rightButtonText,
    secondaryRightButtonText,
    secondaryRightButtonDisabled = false,
    onClickSecondaryRightButton
  }: Props = props

  const { classes } = useStyles()

  return (
    <div className="fd-grid fd-container fd-justify-content-flex-end fd-align-items-center">
      {secondaryRightButtonText && onClickSecondaryRightButton && (
        <Button
          className={classes.marginLeft}
          disabled={secondaryRightButtonDisabled}
          onClick={onClickSecondaryRightButton}
          variant={ButtonVariant.PrimaryOutlined}
        >
          <Box display={{ xs: 'block', md: 'none' }}>
            {truncate(secondaryRightButtonText, NUMBER_OF_CHARACTERS)}
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>
            {secondaryRightButtonText}
          </Box>
        </Button>
      )}
      {rightButtonText && onClickRightButton && (
        <Button
          className={classes.marginLeft}
          disabled={rightButtonDisabled}
          onClick={onClickRightButton}
          variant={ButtonVariant.PrimaryFilled}
        >
          <Box display={{ xs: 'block', md: 'none' }}>
            {truncate(rightButtonText, NUMBER_OF_CHARACTERS)}
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>{rightButtonText}</Box>
        </Button>
      )}
    </div>
  )
}

export default BottomNav
