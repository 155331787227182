import { datadogLogs } from '@datadog/browser-logs'
import axios, { AxiosError, AxiosResponse } from 'axios'

export const fdChatAnalyticsApi = axios.create({
  baseURL:
    process.env.REACT_APP_URL_FD_CHAT_ANALYTICS ??
    process.env.REACT_APP_REST_DOMAIN
})
fdChatAnalyticsApi.interceptors.response.use(
  (response) => {
    datadogLogs.logger.info(
      `[FDChat ${response.config.url}] Response received`,
      {
        response: parseAxiosResponse(response)
      }
    )
    return response
  },
  (error: AxiosError) => {
    const errorMessage = `[FDChat${error.config?.url}] ${error.message}`
    logAxiosErrorToDatadog({
      message: errorMessage,
      error
    })
    return Promise.reject(error)
  }
)

/**
 * Parses the Axios response and returns a modified response object.
 * @param response The Axios response object to be parsed.
 * @returns The modified response object with the `config.data` property parsed as JSON if it exists.
 */
export const parseAxiosResponse = (response: AxiosResponse) => {
  return {
    ...response,
    config: {
      ...response.config,
      data: response.config.data ? JSON.parse(response.config.data) : undefined
    }
  }
}

/**
 * Logs an Axios error to Datadog.
 * @param {Object} params - The parameters for logging the error.
 * @param {string} params.message - The error message.
 * @param {AxiosError} params.error - The Axios error object.
 */
export const logAxiosErrorToDatadog = ({
  message,
  error
}: {
  message: string
  error: AxiosError
}) => {
  datadogLogs.logger.error(
    message,
    {
      config: error.config
        ? {
            ...error.config,
            data: error.config.data ? JSON.parse(error.config.data) : undefined
          }
        : undefined,
      response: error.response ? parseAxiosResponse(error.response) : undefined
    },
    error
  )
}
