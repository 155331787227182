import {
  EditorInput,
  Grid,
  Media as MediaControl,
  PaperGroupItem,
  RawDraftContentState,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { MediaType } from '../../data/model/questionnaire'
import { formatThousandsWithCommas } from '../../utils/HelperFunctions'
import useStyles from './styles'

export interface Props {
  positionLabel?: string
  questionLabel?: string
  chartTitle?: string
  chartRawTitle?: string | null
  completes?: number
  mediaType?: MediaType
  mediaUrl?: string
  thumbnailUrl?: string
}

const getRawTitleValue = (
  rawTitle?: string | null
): RawDraftContentState | undefined => {
  try {
    return JSON.parse(rawTitle || 'null') ?? undefined
  } catch (e) {
    return undefined
  }
}

const ResultsChartHeader: React.FC<Props> = (props: Props) => {
  const {
    positionLabel,
    questionLabel,
    chartTitle,
    chartRawTitle,
    completes,
    mediaType,
    mediaUrl,
    thumbnailUrl
  }: Props = props
  const { classes } = useStyles()
  const hasMedia = mediaType && mediaUrl && thumbnailUrl
  return (
    <Grid item>
      <PaperGroupItem>
        <Grid container direction="row">
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              direction="row"
            >
              <Grid item xs="auto">
                <Text
                  size={TextSize.ms}
                  highlight={TextHighlight.Background}
                  weight={TextWeight.SemiBold}
                  marginRight
                >
                  {positionLabel || '...'}
                </Text>
              </Grid>
              <Grid item xs="auto">
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  {questionLabel || '...'}
                </Text>
              </Grid>
              <Grid item xs="auto">
                <div className={classes.divider} />
              </Grid>
              <Grid item xs="auto">
                <Grid container>
                  <Text
                    size={TextSize.ms}
                    highlight={TextHighlight.Background}
                    marginRight
                  >
                    {completes
                      ? `${formatThousandsWithCommas(
                          Math.round(completes)
                        )} responses`
                      : ''}
                  </Text>
                </Grid>
              </Grid>
              {hasMedia && (
                <Grid item className={classes.media}>
                  <MediaControl
                    mediaType={mediaType}
                    mediaUrl={mediaUrl}
                    thumbnailUrl={thumbnailUrl}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <EditorInput
                readOnly
                value={chartTitle || '...'}
                rawValue={getRawTitleValue(chartRawTitle)}
              />
            </Grid>
          </Grid>
        </Grid>
      </PaperGroupItem>
    </Grid>
  )
}

export default ResultsChartHeader
