import { gql } from '@apollo/client/core'

const MAX_DIFF_SPECIFICATION = gql`
  fragment MaxDiffSpecification on MaxDiffSpecification {
    mostLabel {
      text
      language
      country
    }
    leastLabel {
      text
      language
      country
    }
    attributeLabel {
      text
      language
      country
    }
    experimentalDesigns {
      version
      sets {
        setNumber
        responseOptions
      }
    }
    __typename
  }
`

export default MAX_DIFF_SPECIFICATION
