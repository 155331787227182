import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    padding: theme.spacing(1, 0)
  },
  input: {
    width: '80px'
  },
  letter: {
    width: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  percentText: {
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(1.5)
  },
  lastBranch: {
    paddingBottom: theme.spacing(2)
  }
})

export default useStyles
