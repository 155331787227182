import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Box from '@mui/material/Box'
import useStyles from './LoopingHint.styles'

const LoopingHint = () => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()

  return (
    <>
      <Box alignSelf="flex-start">
        <Icon
          name={IconName.InfoOutlinedIcon}
          iconColor={IconColor.Emphasis}
          size={IconSize.Medium}
        />
      </Box>

      <p
        className={classNames(
          textClasses.default,
          textClasses.displayInline,
          textClasses.sizeMs,
          textClasses.highlightEmphasis,
          classes.hintText
        )}
      >
        To complete looping set up, pipe the relevant text into one or more
        questions in the section.
      </p>
    </>
  )
}

export default LoopingHint
