import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import React from 'react'

import useStyles from './styles'

const QuotaEditorHeader: React.FC = () => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={9} />
      <Grid container item xs={2}>
        <Grid container item xs={7}>
          <Grid container item xs={10} justifyContent="center">
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.weightSemiBold,
                textClasses.highlightBackground,
                classes.quotasTitle
              )}
            >
              QUOTAS
            </p>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid item xs={5} />
      </Grid>
      <Grid container item xs={1} justifyContent="center">
        <Grid container item xs={7} justifyContent="center">
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.weightSemiBold,
              textClasses.highlightBackground
            )}
          >
            COMPLETES
          </p>
        </Grid>
        <Grid item xs={5} />
      </Grid>
    </Grid>
  )
}

export default QuotaEditorHeader
