import { datadogLogs } from '@datadog/browser-logs'
import { AskAICitationInput, SummaryBlockInput } from '@focaldata/askai-client'
import { useSnackbar } from '@focaldata/cin-ui-components'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import Tooltip from '@mui/material/Tooltip'
import { LogAmplitudeEvent } from '../../../../../../../amplitude'
import { EventType } from '../../../../../../../amplitude/eventType'
import CopyIcon from '../../../../../../../assets/copy.svg'
import InsightsImg from '../../../../../../../assets/fdchat/results/insights-blue.svg'
import ThumbsDownIcon from '../../../../../../../assets/thumbs-down.svg'
import { useSurveyId } from '../../../../../../../hooks/useSurveyId'
import styles from './AiAssistantCard.module.css'
import TextWithCitations from './TextWithCitations/TextWithCitations'

const AiAssistantCard = ({
  summaryResponse,
  subPoints,
  loading,
  hasError,
  onCitationClick,
  onFeedbackClick
}: {
  summaryResponse?: SummaryBlockInput
  subPoints?: SummaryBlockInput[]
  loading?: boolean
  hasError?: boolean
  onCitationClick?: ({ citation }: { citation: AskAICitationInput }) => void
  onFeedbackClick?: () => void
}) => {
  const surveyId = useSurveyId()
  const { enqueueSnackbar } = useSnackbar()
  let citationCounter = summaryResponse?.citations.length ?? 0

  const copyAnswer = async () => {
    const data = `
      ${[
        summaryResponse?.summary_text,
        ...(subPoints?.map((subpoint) => subpoint.summary_text) ?? [])
      ].join('<br>')}
    `
    const logData = { surveyId, data }

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([data], { type: 'text/html' }),
          'text/plain': new Blob([data], { type: 'text/plain' })
        })
      ])
      enqueueSnackbar('Answer copied to clipboard', { variant: 'success' })
      LogAmplitudeEvent(EventType.ClickedFdChatAskAiCopyAnswer, logData)
    } catch (error: any) {
      const errorMessage = 'Failed to copy answer to clipboard'
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
      datadogLogs.logger.error(`[FDChat] ${errorMessage}`, logData, error)
    }
  }

  const footerButtons = [
    {
      title: 'Copy answer',
      icon: CopyIcon,
      onClick: copyAnswer
    },
    {
      title: 'Report answer',
      icon: ThumbsDownIcon,
      onClick: onFeedbackClick
    }
  ]

  return (
    <div className={styles['ai-assistant-card']}>
      <div className={styles.header}>
        {loading ? (
          <CircularProgress size={'16px'} />
        ) : (
          <img src={InsightsImg} alt="" className={styles.icon} />
        )}
        <h2 className={styles.title}>AI Assistant</h2>
      </div>
      <div>
        {loading || hasError ? (
          <Loading hasError={hasError} />
        ) : (
          <>
            {summaryResponse && (
              <div className={styles['summary-response']}>
                <TextWithCitations
                  text={summaryResponse.summary_text}
                  citations={summaryResponse.citations}
                  onCitationClick={onCitationClick}
                />
              </div>
            )}
            {subPoints && subPoints.length > 0 && (
              <ul className={styles.subpoints}>
                {subPoints.map((subpoint, index) => {
                  citationCounter +=
                    index === 0 ? 1 : subPoints[index - 1].citations.length
                  return (
                    <li key={index} className={styles.subpoint}>
                      <TextWithCitations
                        text={subpoint.summary_text}
                        citations={subpoint.citations}
                        citationsCounterStart={citationCounter}
                        onCitationClick={onCitationClick}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </>
        )}
      </div>
      <div className={styles.footer}>
        {footerButtons.map((button) => (
          <Tooltip
            key={button.title}
            title={button.title}
            disableFocusListener={loading || hasError}
            disableHoverListener={loading || hasError}
            disableTouchListener={loading || hasError}
          >
            <button
              onClick={button.onClick}
              className={styles['footer-button']}
              disabled={loading || hasError}
            >
              <img src={button.icon} alt={button.title} />
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

export default AiAssistantCard

const Loading = ({ hasError }: { hasError?: boolean }) => {
  return (
    <div className={styles.loading}>
      {[1, 2, 3].map((_, i) => (
        <div key={i}>
          {['35%', '100%', '100%', '80%', '25%'].map((width, j) => (
            <Skeleton
              sx={{ fontSize: '16px' }}
              width={width}
              animation={hasError ? false : 'pulse'}
              key={j}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
