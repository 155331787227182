import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const CREATE_CUSTOMIZED_CRITERION_OPTION = questionnaireGql(
  /* GraphQL */ `
    mutation createCustomizedCriterionOptionV2(
      $input: CreateCustomizedCriterionOptionV2Input!
    ) {
      createCustomizedCriterionOptionV2(input: $input) {
        ...DraftAudienceMember
      }
    }
  `
)

export const UPDATE_CUSTOMIZED_CRITERION_OPTION = questionnaireGql(
  /* GraphQL */ `
    mutation updateCustomizedCriterionOptionV2(
      $input: UpdateCustomizedCriterionOptionV2Input!
    ) {
      updateCustomizedCriterionOptionV2(input: $input) {
        ...DraftStandardCriterionOption
      }
    }
  `
)

export const REMOVE_CUSTOMIZED_CRITERION_OPTION = questionnaireGql(
  /* GraphQL */ `
    mutation removeCustomizedCriterionOptionV2(
      $input: RemoveCustomizedCriterionOptionV2Input!
    ) {
      removeCustomizedCriterionOptionV2(input: $input) {
        ...DraftAudienceMember
      }
    }
  `
)
