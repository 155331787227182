import { useMutation } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { useState } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { useAddFdChatSpecification } from '../../containers/Page/FdChat/FdChat.hooks'
import { defaultFdChatSpecification } from '../../containers/Page/FdChat/FdChat.model'
import { getDefaultCompletes } from '../../controls/ProjectTypesPanel/ProjectTypesPanel.utils'
import { ProjectType } from '../../data/gql-gen/dashboard/graphql'
import { CREATE_QUESTIONNAIRE } from '../../data/gql/questionnaire/mutations/createQuestionnaire'
import { LoggerErrorType } from '../../data/logger'
import { CREATE_PROJECT } from '../../modules/Project/Project.mutations'
import { SEARCH_PROJECTS_QUERY_NAME } from '../../modules/Project/Project.query'
import { captureApolloError } from '../../utils/HelperFunctions'
import UserSession from '../../utils/UserSession'
import { useCreateFieldworkV2 } from '../audience/createOrUpdateFieldworkV2'
import useGetUserAndAccount from '../useGetUserAndAccount'
import { useNavigateToInitialProjectPage } from './useNavigateToInitialProjectPage'

export const useCreateProject = () => {
  const [loadingCreateProject, setLoadingCreateProject] = useState(false)
  const [createProject] = useMutation(CREATE_PROJECT, {
    context: { clientName: 'dashboard' },
    refetchQueries: [SEARCH_PROJECTS_QUERY_NAME],
    onCompleted: (data) => {
      datadogLogs.logger.info('Project created', {
        data
      })
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'createProject', error)
    }
  })
  const {
    userName,
    loading: loadingGetUserAndAccount,
    markup
  } = useGetUserAndAccount()
  const { createFieldworkV2 } = useCreateFieldworkV2()
  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRE, {
    context: { clientName: 'questionnaire' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createQuestionnaire',
        error
      )
    }
  })

  const { addFdChatSpecification } = useAddFdChatSpecification()
  const navigateToInitialProjectPage = useNavigateToInitialProjectPage()

  const handleCreateProject = async ({
    title,
    projectType,
    countryCode,
    countryLanguage,
    samplesNeeded
  }: {
    title?: string
    projectType: ProjectType
    countryCode?: string
    countryLanguage?: string
    samplesNeeded?: number
  }) => {
    setLoadingCreateProject(true)

    const createProjectResult = await createProject({
      variables: {
        name: title || 'My Project',
        description: '',
        projectType: projectType,
        accountId: UserSession.getAccountId(),
        solutionId: UserSession.getSolutionId(),
        creator: userName || 'N/A'
      }
    })

    const createdProject = createProjectResult.data?.createProject

    if (!createdProject) {
      throw new Error('No project created')
    }

    const projectId = createdProject.projectId
    const surveyId = createdProject.surveys[0].surveyId || 'N/A'

    LogAmplitudeEvent(EventType.CreatedNewProject, { surveyId })

    const isFdChat = projectType === ProjectType.FdChat

    await createQuestionnaire({
      variables: {
        projectId,
        surveyId
      }
    })

    await createFieldworkV2({
      variables: {
        input: {
          surveyId,
          countryCode: countryCode || 'GB',
          countryLanguage: countryLanguage || 'en',
          samplesNeeded: samplesNeeded ?? getDefaultCompletes(projectType),
          accountMarkup: markup,
          panelSupplierCode: isFdChat ? 'prolific' : 'cint',
          audienceDescription: isFdChat ? '' : undefined
        }
      }
    })

    // if the project is an FD Chat project, add the default FD Chat specification and set the preset audience to Custom
    if (isFdChat) {
      await addFdChatSpecification({
        variables: {
          input: {
            projectId,
            surveyId,
            fdChatSpecification: { value: { ...defaultFdChatSpecification } }
          }
        }
      })
    }

    navigateToInitialProjectPage(createdProject)

    setLoadingCreateProject(false)
  }

  return {
    loadingGetUserAndAccount,
    loadingCreateProject,
    handleCreateProject
  }
}

export default useCreateProject
