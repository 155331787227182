import { datadogLogs } from '@datadog/browser-logs'
import {
  TranscriptResponseInput,
  TranscriptsApi
} from '@focaldata/askai-client'
import { useSnackbar } from '@focaldata/cin-ui-components'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSurveyId } from '../../../hooks/useSurveyId'
import LRUCache from '../../../utils/LRUCache'
import { fdChatAnalyticsApi } from '../FdChat.utils'

const transcriptsApi = new TranscriptsApi(
  undefined,
  undefined,
  fdChatAnalyticsApi
)

const transcriptCache = new LRUCache(50)

export const useTranscript = ({ respondentId }: { respondentId: string }) => {
  const surveyId = useSurveyId()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [transcript, setTranscript] = useState<TranscriptResponseInput>()
  const { enqueueSnackbar } = useSnackbar()

  const fetchTranscript = useCallback(async () => {
    setLoading(true)
    const cachedTranscript = transcriptCache.get(`${surveyId}-${respondentId}`)
    if (cachedTranscript) {
      setTranscript(cachedTranscript)
      datadogLogs.logger.info('[FDChat] Transcript fetched from cache', {
        surveyId,
        respondentId,
        transcript: cachedTranscript
      })
    } else {
      try {
        const response = await transcriptsApi.getTranscriptV1({
          surveyId,
          respondentId
        })
        setLoading(false)
        setTranscript(response.data)
        transcriptCache.put(`${surveyId}-${respondentId}`, response.data)
      } catch (e: unknown) {
        const error = e as AxiosError
        setError(error.message)
        // show snackbar error message
        enqueueSnackbar('Sorry, an error occurred. Please try again later.', {
          variant: 'error'
        })
      }
    }
    setLoading(false)
  }, [enqueueSnackbar, respondentId, surveyId])

  useEffect(() => {
    fetchTranscript()
  }, [fetchTranscript])

  return {
    transcript,
    loading,
    error
  }
}
