import { List } from '@focaldata/cin-ui-components'
import React from 'react'
import ListItemProjectLoading from '../../../controls/ProjectListItem/ProjectListItem.loading'

const ProjectListLoading: React.FC = () => {
  return (
    <List>
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
      <ListItemProjectLoading />
    </List>
  )
}

export default ProjectListLoading
