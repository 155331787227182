import styles from './Drawer.module.css'

const Drawer = ({
  open,
  children
}: {
  open?: boolean
  children?: React.ReactNode
}) => {
  return (
    <div
      className={styles.drawer}
      style={{
        transform: open
          ? 'translateX(0)'
          : 'translateX(var(--drawer-max-width))'
      }}
    >
      {children}
    </div>
  )
}

export default Drawer
