import {
  Button,
  ButtonVariant,
  Grid,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './FileInput.styles'

interface Props {
  file: File
  onRemove: () => void
}

export const FileSelected = ({ file, onRemove }: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      data-testid="file-selected"
      container
      direction="row"
      justifyContent="space-between"
      className={classes.validImageContainer}
    >
      <Grid item container xs={10} direction="row" alignItems="center">
        <Grid className={classes.statsContainer}>
          <p
            data-testid="file-name-caption"
            className={classNames(
              textClasses.default,
              textClasses.weightSemiBold,
              classes.nameContainer
            )}
          >
            {file.name}
          </p>
          <p
            data-testid="file-size-caption"
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.highlightBackground
            )}
          >
            {`File size: ${Math.round(file.size / 1024)}KB`}
          </p>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant={ButtonVariant.SecondaryNoOutline} onClick={onRemove}>
          Remove
        </Button>
      </Grid>
    </Grid>
  )
}
