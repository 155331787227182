import {
  Select,
  SelectIconColor,
  SelectOption,
  SelectVariant,
  TextSize
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { SortOrder } from '../../modules/Project/Project.query'

interface Props {
  onChange: (value: SortOrder) => void
}

export const ProjectOrderSelect: React.FC<Props> = (props: Props) => {
  const { onChange } = props
  const [optionValue, setOptionValue] = useState<string>('option 0')
  const options: SelectOption[] = [
    { id: '0', name: 'Last updated: most recent first', value: 'option 0' },
    { id: '1', name: 'Last updated: oldest first', value: 'option 1' }
  ]

  const handleOnChange: (value: string) => void = (value) => {
    setOptionValue(value)
    if (value === 'option 0') {
      onChange(SortOrder.Desc)
    }
    if (value === 'option 1') {
      onChange(SortOrder.Asc)
    }
  }

  return (
    <Select
      defaultOptionValue={optionValue}
      options={options}
      variant={SelectVariant.Filled}
      iconColor={SelectIconColor.Primary}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange(event.target.value)
      }}
      minWidth={264}
      textSize={TextSize.m}
    />
  )
}
