import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  supportEmailContainer: {
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  textContainer: {
    paddingBottom: theme.spacing(4)
  },
  supportTextContainer: {
    marginBottom: `-${theme.spacing(2)}`
  },
  mailtoLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

export default useStyles
