import { theme } from '@focaldata/cin-ui-components'
import { Chart } from 'chart.js'
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'
import React, { useLayoutEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { BarChartData } from '../Chart.model'
import {
  calculateChartHeightWithLegend,
  getAdjustedMaxBarValueIfNeeded,
  getBarColor,
  getDefaultOptions
} from '../Chart.utils'
import { externalTooltipHandler } from './StackedBarChart.utils'

export type StackedBarChartProps = React.HTMLAttributes<HTMLElement> & {
  data: BarChartData
}

const percentFormatterStackedChart = (
  optionValue: number,
  context: Context
) => {
  const optionPercent = optionValue * 100 // The sum of each option value is 1
  const widthChart = context.chart.width as number

  if (optionPercent < 8 && widthChart && widthChart < 610) {
    return ''
  }

  if (optionPercent < 4) {
    return ''
  }

  return `${optionPercent.toFixed(0)}%`
}

const StackedBarChart = ({ data }: StackedBarChartProps) => {
  const [height] = useState(() =>
    calculateChartHeightWithLegend(data.labels.length)
  )

  // The max for a stacked bar is 100%. If it is less than 100% adjusted value is applied to scale the chart
  const maxBarValue = getAdjustedMaxBarValueIfNeeded(1, data)

  const options = getDefaultOptions(theme, maxBarValue)

  options.indexAxis = 'y'

  options.plugins = {
    ...options.plugins,
    datalabels: {
      ...options.plugins.datalabels,
      color: theme.palette.background.default,
      clip: true,
      clamp: true,
      anchor: 'center',
      align: 'center',
      formatter: percentFormatterStackedChart
    },
    tooltip: {
      ...options.plugins.tooltip,
      axis: 'y',
      mode: 'index',
      position: 'average',
      enabled: false,
      external: externalTooltipHandler
    }
  }

  const defaultX = options.scales.x

  const defaultY = options.scales.y

  options.scales = {
    x: { ...defaultX, stacked: true },
    y: { ...defaultY, stacked: true }
  }

  const dataWithColors = {
    ...data,
    datasets: data.datasets.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: getBarColor(index),
        hoverBackgroundColor: getBarColor(index),
        totalSamples: data.totalSamples,
        completesPerRow: data.completesPerRow
      }
    })
  }

  useLayoutEffect(() => {
    Chart.register(ChartDataLabels)
  }, [])

  return (
    <Bar
      height={height}
      data={dataWithColors}
      options={options}
      updateMode="resize"
    />
  )
}

export default StackedBarChart
