import {
  Button,
  ButtonVariant,
  Grid,
  Icon,
  IconButton,
  IconColor,
  IconName,
  Paper,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { Children, ReactNode, memo } from 'react'

export const ResponseOptionsGroup = memo(function ResponseOptionsGroup({
  title,
  children,
  onDelete,
  onAdd
}: {
  title: string
  children: ReactNode
  onDelete?: () => void
  onAdd?: () => void
}) {
  return (
    <Paper sx={{ boxSizing: 'border-box', paddingX: 3, paddingY: 2 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 1 }}
      >
        <Text size={TextSize.l} weight={TextWeight.SemiBold}>
          {title}
        </Text>
        {onDelete ? (
          <IconButton
            ariaLabel="Delete response options group"
            smallHoverShadowSize
            iconName={IconName.DeleteOutline}
            onClick={onDelete}
          />
        ) : null}
      </Grid>
      <Text size={TextSize.s}>Number</Text>
      {Children.map(children, (child) => {
        return <Grid marginY={1}>{child}</Grid>
      })}
      {onAdd ? (
        <Grid display="flex" justifyContent="center" marginY={2}>
          <Button
            variant={ButtonVariant.SecondaryFilled}
            startIcon={<Icon name={IconName.Add} iconColor={IconColor.White} />}
            onClick={onAdd}
          >
            Add
          </Button>
        </Grid>
      ) : null}
    </Paper>
  )
})
