import {
  ApolloQueryResult,
  FetchPolicy,
  LazyQueryExecFunction,
  useLazyQuery
} from '@apollo/client'
import { createContext, useContext, useLayoutEffect, useMemo } from 'react'
import { DraftQuestionnaireV2QueryVariables } from '../../data/gql-gen/questionnaire/graphql'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftQuestionnaire,
  DraftQuestionnaireEntry
} from '../../data/model/questionnaire'
import { flattenEntries } from '../../modules/Questionnaire/Questionnaire.utils'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

interface UseGetDraftQuestionnaire {
  draftQuestionnaire: DraftQuestionnaire | undefined
  questionnaireLoading: boolean
  draftQuestionnaireEntries: DraftQuestionnaireEntry[]
  flattenedEntries: DraftQuestionnaireEntry[]
  fetchQuestionnaire: LazyQueryExecFunction<
    QuestionnaireData,
    DraftQuestionnaireV2QueryVariables
  >
  refetchQuestionnaire: (
    variables?: Partial<DraftQuestionnaireV2QueryVariables> | undefined
  ) => Promise<ApolloQueryResult<QuestionnaireData>>
}

export const DraftQuestionnaireContext =
  createContext<UseGetDraftQuestionnaire | null>(null)

/**
 * @deprecated You should use useQuery/useFragment with the *specific* query you want to use instead of this hook.
 */
export const useGetDraftQuestionnaireValue = (): UseGetDraftQuestionnaire => {
  const [
    fetchQuestionnaire,
    {
      data: questionnaireData,
      loading: questionnaireLoading,
      refetch: refetchQuestionnaire
    }
  ] = useLazyQuery<QuestionnaireData, DraftQuestionnaireV2QueryVariables>(
    QUESTIONNAIRE,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'draftQuestionnaire',
          error
        )
      }
    }
  )

  const draftQuestionnaire = questionnaireData?.draftQuestionnaire
  // TODO flatten + check if it doesn't break anything after backend fixes for sections are done
  const draftQuestionnaireEntries = useMemo(() => {
    return [
      ...(draftQuestionnaire?.audienceEntries || []),
      ...(draftQuestionnaire?.entries || [])
    ]
  }, [draftQuestionnaire?.audienceEntries, draftQuestionnaire?.entries])
  const flattenedEntries = useMemo(() => {
    return flattenEntries(draftQuestionnaire?.entries ?? [])
  }, [draftQuestionnaire?.entries])

  return useMemo(
    () => ({
      draftQuestionnaireEntries,
      draftQuestionnaire,
      flattenedEntries,
      questionnaireLoading,
      refetchQuestionnaire,
      fetchQuestionnaire
    }),
    [
      draftQuestionnaire,
      draftQuestionnaireEntries,
      flattenedEntries,
      questionnaireLoading,
      refetchQuestionnaire,
      fetchQuestionnaire
    ]
  )
}

/**
 * @deprecated You should use useQuery/useFragment with the *specific* query you want to use instead of this hook.
 */
const useGetDraftQuestionnaire = (fetchPolicy: FetchPolicy = 'cache-first') => {
  const value = useContext(DraftQuestionnaireContext)
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  if (!value) {
    throw new Error('Must wrap in QuestionnaireContextProvider')
  }

  const { fetchQuestionnaire } = value

  useLayoutEffect(() => {
    if (surveyId) {
      fetchQuestionnaire({ variables: { projectId, surveyId }, fetchPolicy })
    }
  }, [projectId, surveyId, fetchPolicy, fetchQuestionnaire])

  return value
}

export default useGetDraftQuestionnaire
