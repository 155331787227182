import {
  Text,
  TextAlign,
  TextDisplay,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import ReactMarkdown from 'react-markdown'

const MarkdownHeading = ({
  children,
  colour
}: {
  children: React.ReactNode
  colour?: TextHighlight
}) => (
  <div style={{ marginBottom: '8px' }}>
    <Text
      weight={TextWeight.SemiBold}
      size={TextSize.ml}
      highlight={colour}
      align={TextAlign.Left}
    >
      {children}
    </Text>
  </div>
)

const Markdown = ({
  children,
  colour
}: {
  children: string
  colour?: TextHighlight
}) => (
  <ReactMarkdown
    // eslint-disable-next-line react/no-children-prop
    children={children}
    components={{
      p: ({ children }) => (
        <div style={{ marginBottom: '16px' }}>
          <Text highlight={colour} align={TextAlign.Left}>
            {children}
          </Text>
        </div>
      ),
      strong: ({ children }) => (
        <Text
          weight={TextWeight.Bold}
          highlight={colour}
          display={TextDisplay.Inline}
          align={TextAlign.Left}
        >
          {children}
        </Text>
      ),
      li: ({ children }) => (
        <li>
          <Text highlight={colour} align={TextAlign.Left}>
            {children}
          </Text>
        </li>
      ),
      ul: ({ children }) => (
        <ul style={{ margin: '0px', marginBottom: '8px' }}>{children}</ul>
      ),
      h1: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      ),
      h2: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      ),
      h3: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      ),
      h4: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      ),
      h5: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      ),
      h6: ({ children }) => (
        <MarkdownHeading colour={colour}>{children}</MarkdownHeading>
      )
    }}
  />
)

export default Markdown
