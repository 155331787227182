import {
  CircularProgress,
  Grid,
  Skeleton,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './ProjectListItem.styles'

interface Props {
  label: string
  value: string | undefined
  loadingValue?: boolean
  showProgress?: boolean
  percent?: number
}

const ProjectListItemDetails: React.FC<Props> = ({
  label,
  value,
  loadingValue,
  showProgress,
  percent
}: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      className={classes.projectItem}
    >
      {!loadingValue && showProgress && percent !== undefined && (
        <Grid item sm={2} xs={3}>
          <CircularProgress
            total={100}
            current={percent}
            size={24}
            style={{ paddingTop: 4 }}
          />
        </Grid>
      )}
      <Grid container item direction="column" xs={9} sm={10}>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeS,
            textClasses.highlightBackground,
            textClasses.weightSemiBold
          )}
        >
          {label}:&nbsp;
        </p>
        {loadingValue ? (
          <Skeleton width={70} height={20} />
        ) : (
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeS,
              textClasses.highlightBackground,
              textClasses.weightRegular
            )}
          >
            {value}&nbsp;
          </p>
        )}
      </Grid>
    </Grid>
  )
}

export default ProjectListItemDetails
