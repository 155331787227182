import {
  Grid,
  Switch,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import useGetDraftAudience from '../../../../hooks/audience/useGetDraftAudience'
import useAudienceQuestionsToggleVar from '../../../../hooks/localState/useAudienceQuestionsToggleVar'

export const AudienceQuestionsToggle = () => {
  const { shouldShowAudienceQuestions, toggleShowAudienceQuestions } =
    useAudienceQuestionsToggleVar()
  const { draftAudience } = useGetDraftAudience()
  const hasAudienceQuestions = (draftAudience?.members.length || 0) > 0

  if (!hasAudienceQuestions) {
    return null
  }

  return (
    <Grid item xs="auto">
      <Grid container>
        <Text size={TextSize.ms} highlight={TextHighlight.Background}>
          Show audience questions
        </Text>
        <Switch
          ariaLabel="Show audience questions switch"
          checked={shouldShowAudienceQuestions}
          onChange={toggleShowAudienceQuestions}
        />
      </Grid>
    </Grid>
  )
}
