import { useCallback, useEffect, useState } from 'react'
import { SurveyProgress } from '../data/model/surveyResults'
import { getSurveysProgress } from '../data/rest/surveyResults'

interface SurveyProgressResponse {
  surveyProgress: SurveyProgress[] | undefined
  surveyProgressError: Error | undefined
  loading: boolean
}

const useGetSurveysProgress: (
  surveyIds: string[] | undefined
) => SurveyProgressResponse = (surveyIds) => {
  const [progress, setProgress] = useState<SurveyProgress[] | undefined>(
    undefined
  )
  const [progressError, setProgressError] = useState<Error | undefined>(
    undefined
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [prevSurveyIds, setPrevSurveyIds] = useState<string[] | undefined>(
    undefined
  )

  const callSurveyProgress: () => Promise<void> = useCallback(async () => {
    setLoading(true)
    try {
      if (surveyIds) {
        const response = await getSurveysProgress(surveyIds)
        setProgress(response.data)
        setLoading(false)
      }
    } catch (err) {
      setProgressError(err as unknown as Error)
      setLoading(false)
    }
  }, [surveyIds])

  useEffect(() => {
    if (surveyIds?.toString() !== prevSurveyIds?.toString()) {
      callSurveyProgress()
      setPrevSurveyIds(surveyIds)
    }
  }, [callSurveyProgress, prevSurveyIds, surveyIds])

  return {
    surveyProgress: progress,
    surveyProgressError: progressError,
    loading
  }
}

export default useGetSurveysProgress
