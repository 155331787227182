import { Option } from '@focaldata/cin-ui-components'
import set from 'lodash/set'
import { useMemo } from 'react'
import { useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  LoopingConfigInput,
  SettingInputInput
} from '../../../data/gql-gen/questionnaire/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftMatrixItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { useDraftMatrixItem } from '../../../hooks/questionnaire/useDraftMatrixItem'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { useProjectId } from '../../../hooks/useProjectId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { selectResponseOptionsByQuestion } from '../Questionnaire.slice'
import { useQuestionnaireSectionsLoopingData } from '../SectionHooks/Section.hooks'
import {
  selectCurrentLoopingSectionNumberRequired,
  selectSourceEntryNumber
} from './Looping.slice'
import { entryHasStaticLooping } from './Looping.utils'

const useSetLooping = ({
  sectionEntryNumber,
  sourceEntry,
  prevSourceEntry
}: {
  sectionEntryNumber: number
  sourceEntry?: DraftQuestionnaireEntry
  prevSourceEntry?: DraftQuestionnaireEntry
}) => {
  const projectId = useProjectId()
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const entries = draftQuestionnaire?.entries ?? []
  const questionnaireId = draftQuestionnaire?.questionnaireId ?? ''
  const setNextSourceSetting = useSetQuestionnaireSetting(sourceEntry)
  const setPrevSourceSetting = useSetQuestionnaireSetting(prevSourceEntry)

  const sectionEntry = entries.find(
    (entry) => entry.number === sectionEntryNumber
  )

  if (!sectionEntry || sectionEntry.entryType !== EntryType.SectionEntryType) {
    throw new Error(
      `Section entry with "${sectionEntryNumber}" number not found`
    )
  }

  const { updateQuestionnaireEntry: updateSectionEntry } =
    useUpdateQuestionnaireEntry(sectionEntry.id, {
      onError: (error) => {
        captureApolloError(LoggerErrorType.ApolloMutation, 'setLooping', error)
      },
      refetchQueries: [draftAudienceRefetchQuery(projectId, questionnaireId)]
    })

  const setLooping = async ({
    enabled,
    loopingConfig,
    enableStaticLooping = false
  }: {
    enabled: boolean
    loopingConfig?: LoopingConfigInput
    enableStaticLooping?: boolean
  }) => {
    if (prevSourceEntry && entryHasStaticLooping(prevSourceEntry)) {
      await setPrevSourceSetting([
        {
          settingCode: QuestionSettingCode.AutoAnswerAll,
          settingValue: SettingValue.Disabled
        }
      ])
    }

    if (enabled && enableStaticLooping) {
      await setNextSourceSetting([
        {
          settingCode: QuestionSettingCode.AutoAnswerAll,
          settingValue: SettingValue.Enabled
        }
      ])
    }

    const addOrUpdateSettingValue = (settingCode: string, value: string) => {
      const { settingValues } = sectionEntry.entryItem
      const settingValuesInput = settingValues.map(
        (s): SettingInputInput => ({
          questionSettingCode: s.code,
          settingValue: s.value
        })
      )

      const settingIndex = settingValuesInput.findIndex(
        (setting) => setting.questionSettingCode === settingCode
      )

      return set(
        settingValuesInput,
        `${settingIndex > -1 ? settingIndex : settingValues.length}`,
        {
          questionSettingCode: settingCode,
          settingValue: value
        }
      )
    }

    const result = await updateSectionEntry(
      {
        updateSection: {
          loopingConfig
        },
        settingValues: addOrUpdateSettingValue(
          'looping',
          enabled ? 'enabled' : 'disabled'
        )
      },
      {
        onCompleted() {
          LogAmplitudeEvent(
            enabled ? EventType.EnabledLooping : EventType.DisabledLooping,
            {
              questionnaireId,
              sectionId: sectionEntry.id
            }
          )
        }
      }
    )

    return result.data?.updateQuestionnaireEntryV2
  }

  return setLooping
}

export const useEntryByEntryNumber = (sourceEntryNumber: number | null) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()

  const selectedEntry = useMemo(
    () => flattenedEntries.find(({ number }) => number === sourceEntryNumber),
    [flattenedEntries, sourceEntryNumber]
  )
  return selectedEntry
}

export const useOtherLoopCountDependingOnSourceEntry = (
  sourceEntryNumber: number | null | undefined
) => {
  const draftQuestionnaireEntries = useQuestionnaireSectionsLoopingData()
  const currentLoopingSectionNumber = useAppSelector(
    selectCurrentLoopingSectionNumberRequired
  )

  return draftQuestionnaireEntries.filter(
    (entry) =>
      entry.entryType === EntryType.SectionEntryType &&
      entry.number !== currentLoopingSectionNumber &&
      // Shouldn't need to check this, but useQuestionnaireSectionsLoopingData
      // doesn't return a discriminated union for entry.entryType/entry.entryItem
      entry.entryItem.__typename === 'DraftSectionItem' &&
      entry.entryItem.loopingConfig?.sourceEntryNumber === sourceEntryNumber
  ).length
}

const useLoopingSelectedEntry = (): DraftQuestionnaireEntry | undefined => {
  const sourceEntryNumber = useAppSelector(selectSourceEntryNumber)
  const selectedEntry = useEntryByEntryNumber(sourceEntryNumber)

  return selectedEntry
}

const useLoopingSourceResponseOptions = () => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const selectedEntry = useLoopingSelectedEntry()
  const sourceEntryType = selectedEntry?.entryType

  const loopingSourceResponseOptions = useMemo(() => {
    if (!selectedEntry) {
      return []
    }

    switch (sourceEntryType) {
      case EntryType.QuestionEntryType:
        return (
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          responseOptionsByQuestion[selectedEntry.entryItem.questionLk] ?? []
        )
      case EntryType.MatrixEntryType:
        return (
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          responseOptionsByQuestion[selectedEntry.entryItem.matrixTitleLk] ?? []
        )
    }

    return []
  }, [responseOptionsByQuestion, selectedEntry, sourceEntryType])

  return loopingSourceResponseOptions
}

const useLoopingSourceMatrixRows = () => {
  const selectedEntry = useLoopingSelectedEntry()

  const matrixItem = useDraftMatrixItem(
    (selectedEntry?.entryItem as DraftMatrixItem).matrixTitleLk
  )

  const loopingSourceMatrixRows = useMemo(() => {
    return matrixItem?.matrixRows ?? []
  }, [matrixItem?.matrixRows])

  return loopingSourceMatrixRows
}

const useLoopMaxIterationOptions = (): Option[] => {
  const loopingSelectedEntry = useLoopingSelectedEntry()
  const loopingSourceResponseOptions = useLoopingSourceResponseOptions()
  const loopingSourceMatrixRows = useLoopingSourceMatrixRows()

  const loopIds: string[] =
    loopingSelectedEntry?.entryType === EntryType.MatrixEntryType
      ? loopingSourceMatrixRows.map((row) => row.questionLk)
      : loopingSourceResponseOptions.map((ro) => ro.responseOptionLk)

  return loopIds.map((id, index) => {
    const maxIterationsValue = index + 1
    const maxIterationsName =
      maxIterationsValue === loopIds.length ? 'All' : `${maxIterationsValue}`
    return {
      id,
      name: maxIterationsName,
      value: maxIterationsValue
    }
  })
}

export {
  useSetLooping,
  useLoopingSelectedEntry,
  useLoopingSourceResponseOptions,
  useLoopingSourceMatrixRows,
  useLoopMaxIterationOptions
}
