import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_COUNTRIES_V2 = questionnaireGql(/* GraphQL */ `
  query getCountriesV2 {
    getCountriesV2 {
      name
      countryCode
      languageName
      locale {
        language
        country
      }
    }
  }
`)
