import { AskAIAnswerResponseInput } from '@focaldata/askai-client'
import { useLocation, useSearchParams } from 'react-router-dom'
import { LocationState } from '../../../containers/Page/FdChat/ResultsPage/AskAi/Search/Search'
import { paramsToObject } from './AskAi.utils'

export const useAskAiDrawer = () => {
  const location = useLocation()
  const locationState = location.state as LocationState
  const [searchParams, setSearchParams] = useSearchParams()

  const addKeyIfExists = ({ key, value }: any) => {
    return value || searchParams.get(key)
      ? { [key]: value ?? searchParams.get(key) ?? undefined }
      : {}
  }

  const goToTranscript = ({
    respondentId,
    interactionId,
    answerId,
    answer
  }: {
    respondentId: string
    interactionId?: string
    answerId?: string
    answer?: AskAIAnswerResponseInput
  }) => {
    setSearchParams(
      {
        drawer: 'open',
        ...addKeyIfExists({ key: 'answerId', value: answerId }),
        respondentId,
        ...addKeyIfExists({ key: 'interactionId', value: interactionId }),
        ...addKeyIfExists({ key: 'threadId', value: undefined })
      },
      {
        state: {
          ...locationState,
          answer: answer ?? locationState?.answer
        }
      }
    )
  }

  const goToSources = ({
    answerId,
    answer
  }: { answerId?: string; answer?: AskAIAnswerResponseInput } = {}) => {
    setSearchParams(
      {
        drawer: 'open',
        ...addKeyIfExists({ key: 'answerId', value: answerId }),
        ...addKeyIfExists({ key: 'threadId', value: undefined })
      },
      {
        state: {
          ...locationState,
          answer: answer ?? locationState?.answer
        }
      }
    )
  }

  const closeDrawer = () => {
    const searchParamsObject = paramsToObject(searchParams)
    // first remove the drawer query param as we want to close the drawer
    delete searchParamsObject.drawer
    setSearchParams({ ...searchParamsObject })
    // after 270ms seconds, remove all search params as this is the time taken for the drawer close animation
    setTimeout(() => {
      setSearchParams(
        {
          ...addKeyIfExists({ key: 'threadId', value: undefined })
        },
        {
          state: {
            ...locationState,
            answer: undefined
          }
        }
      )
    }, 270)
  }

  return {
    drawerOpen: searchParams.get('drawer') === 'open',
    viewer: searchParams.get('respondentId') ? 'transcript' : 'sources',
    goToTranscript,
    goToSources,
    closeDrawer
  }
}
