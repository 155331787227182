import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const MATRIX_TITLE = questionnaireGql(/* GraphQL */ `
  fragment MatrixTitle on MatrixTitle {
    matrixTitleId
    title
    titleStyling
  }
`)

export const DRAFT_MATRIX_ITEM = questionnaireGql(/* GraphQL */ `
  fragment DraftMatrixItem on DraftMatrixItem {
    matrixTitleLk
    matrixTitle {
      ...MatrixTitle
    }
    matrixRows {
      ...DraftMatrixRow
    }
    settingValues {
      code
      value
      createdDate
    }
    questionLogic {
      ...DraftLogicClauseProposition
    }
    responseOptions {
      ...DraftEntryResponseOption
    }
    ...DraftMatrixItemQualification
    __typename
  }
`)
