import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const MatrixScreeningMenu_DraftMatrixItemFragment = questionnaireGql(
  /* GraphQL */ `
    fragment MatrixScreeningMenu_DraftMatrixItemFragment on DraftMatrixItem {
      matrixTitleLk
      matrixQualification {
        __typename
      }
    }
  `
)
