import maxBy from 'lodash/maxBy'
import { ChartEntry } from '../../../data/model/chart'
import { useFieldwork } from '../../../hooks/useFieldwork'
import useGetSurveyState from '../../../hooks/useGetSurveyState'
import useSummaryData from '../../../hooks/useSummaryData'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { WeightingData } from '../../../utils/resultsUtils'
import SurveyCompletionProgressControl from './SurveyCompletionProgress.control'
import SurveyCompletionProgressLoading from './SurveyCompletionProgress.loading'

// some questions are not answered by everyone because of forks, question logic, looping
// so we need to get this value from whichever question has the most
const getMaxSamplesCollected = (summaryData: ChartEntry[]): number => {
  return (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (summaryData && maxBy(summaryData, (sd) => sd.samplesCollected))
      ?.samplesCollected || 0
  )
}

const SurveyCompletionProgress = () => {
  const surveyId = useSurveyId()
  const {
    data: summaryData,
    weightingScheme,
    loading
  } = useSummaryData(surveyId)
  const { surveyState } = useGetSurveyState()
  const { fieldwork } = useFieldwork()

  if (loading || !surveyState) {
    return <SurveyCompletionProgressLoading />
  }

  const collectedCompletes = getMaxSamplesCollected(summaryData)

  const wantedCompletes =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (summaryData && summaryData.length > 0 && summaryData[0].samplesWanted) || 0

  const weightingQuestions =
    weightingScheme &&
    (weightingScheme.questions
      .map((questionId) => {
        const question = summaryData.find(
          (question) => question.id === questionId
        )
        if (question) {
          return {
            number: question.contextPosition,
            text: question.text
          }
        }

        return undefined
      })
      .filter((wq) => wq !== undefined) as WeightingData[])

  const incidenceRate = fieldwork?.incidenceRate || 100
  const nonUK = fieldwork?.audience && fieldwork.audience[0].countryId > 1

  const increment =
    fieldwork?.samplesNeededIncreaseFrequency?.toString() || undefined

  return (
    <SurveyCompletionProgressControl
      wantedCompletes={wantedCompletes}
      weightingScheme={weightingQuestions}
      collectedCompletes={collectedCompletes}
      surveyState={surveyState}
      incidenceRate={incidenceRate}
      nonUK={nonUK}
      increment={increment}
    />
  )
}

export default SurveyCompletionProgress
