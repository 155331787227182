import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  divider: {
    width: '25%',
    borderColor: theme.palette.primary.main
  },
  emptyPaperStyle: {
    width: '100%'
  }
}))

export default useStyles
