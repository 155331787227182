import React, { useCallback } from 'react'
import {
  RootState,
  useAppDispatch,
  useAppSelector
} from '../../../../App.store'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  SettingValue
} from '../../../../data/model/questionnaire'
import useHandleMultiSelectLimits from '../../../../hooks/questionnaire/useHandleIntervalSettingsChange'
import { useUpdateQuestionnaireEntry } from '../../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import {
  WithEntry,
  checkIfRankedChoice,
  getQuestionSettings
} from '../../../../utils/questionnaireUtils'
import {
  responseOptionAdded,
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { scaleConfig } from '../../constants'
import { useCreateResponseOption } from '../ResponseOptions/ResponseOptions.hooks'
import MultiSelectChoiceLimit from './MultiSelectChoiceLimit'
import QuestionCardFooter from './QuestionCardFooter'
import RankChoiceRequisite from './RankChoiceRequisite'

const QuestionCardFooterContainer: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const entryItem: DraftQuestionItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem

  const dispatch = useAppDispatch()
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const selectById = useCallback(
    (state: RootState) => selectSettingsByQuestionId(state, questionLk),
    [questionLk]
  )
  const settings = useAppSelector(selectById)

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const responseOptionsCount = (responseOptionsByQuestion[questionLk] ?? [])
    .length

  const createResponseOption = useCreateResponseOption(entry.id)
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id)

  const { handleIntervalSettingsChange, handleQuestionLimitChange } =
    useHandleMultiSelectLimits(entry)
  const handleClickAddResponseOption: () => void = useCallback(() => {
    const newResponseOptionPosition = responseOptionsCount
    dispatch(
      responseOptionAdded({ questionLk, position: newResponseOptionPosition })
    )
    createResponseOption(newResponseOptionPosition)
  }, [createResponseOption, dispatch, questionLk, responseOptionsCount])

  const handleChangeRankChoiceRequisite: (settingValue: number) => void =
    useCallback(
      (settingValue) =>
        handleQuestionLimitChange(
          QuestionSettingCode.RankChoiceRequisite,
          settingValue
        ),
      [handleQuestionLimitChange]
    )

  const handleAddScale = useCallback(async () => {
    await updateQuestionnaireEntry({
      replaceResponseOptions: scaleConfig.Agree1To5
    })
  }, [updateQuestionnaireEntry])

  const handleChangeChoiceLimit: (settingValue: number) => void = useCallback(
    (settingValue) =>
      handleQuestionLimitChange(QuestionSettingCode.ChoiceLimit, settingValue),
    [handleQuestionLimitChange]
  )

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const questionSettings = getQuestionSettings(settings || [])

  const isMultipleChoice =
    questionSettings.get(QuestionSettingCode.BasicChoice) ===
    SettingValue.MultipleChoice

  const isSingleChoice =
    questionSettings.get(QuestionSettingCode.BasicChoice) ===
    SettingValue.SingleChoice

  const isRankedChoice = checkIfRankedChoice(entryItem)

  const choiceLimitValue = questionSettings.get(QuestionSettingCode.ChoiceLimit)
  const choiceLimit = choiceLimitValue && parseInt(choiceLimitValue, 10)

  const choiceIntervalMinValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMin
  )
  const choiceIntervalMin =
    choiceIntervalMinValue && parseInt(choiceIntervalMinValue, 10)

  const choiceIntervalMaxValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMax
  )
  const choiceIntervalMax =
    choiceIntervalMaxValue && parseInt(choiceIntervalMaxValue, 10)

  const rankChoiceRequisite = parseInt(
    questionSettings.get(QuestionSettingCode.RankChoiceRequisite) || '0',
    10
  )

  return (
    <QuestionCardFooter
      entry={entry}
      onClickAddResponseOption={handleClickAddResponseOption}
      showAddScale={isSingleChoice}
      rankChoiceRequisite={
        isRankedChoice ? (
          <RankChoiceRequisite
            rankChoiceRequisite={rankChoiceRequisite}
            responseOptionsCount={responseOptionsCount}
            onChangeRankChoiceRequisite={handleChangeRankChoiceRequisite}
          />
        ) : undefined
      }
      multiSelectChoiceLimit={
        isMultipleChoice ? (
          <MultiSelectChoiceLimit
            entry={entry}
            responseOptionsCount={responseOptionsCount}
            choiceLimit={choiceLimit}
            choiceIntervalMin={choiceIntervalMin}
            choiceIntervalMax={choiceIntervalMax}
            onChangeChoiceLimit={handleChangeChoiceLimit}
            onChangeIntervalRange={handleIntervalSettingsChange}
          />
        ) : undefined
      }
      onAddScale={handleAddScale}
    />
  )
}

export default QuestionCardFooterContainer
