import { useRef, useState } from 'react'
import { LogAmplitudeEvent } from '../../../../../../../amplitude'
import { EventType } from '../../../../../../../amplitude/eventType'
import { useSurveyId } from '../../../../../../../hooks/useSurveyId'
import AskAiBar from '../../AskAiBar'

const AskAiBarContainer = ({
  onAskAi
}: {
  onAskAi: ({ question }: { question: string }) => void
}) => {
  const [question, setQuestion] = useState('')
  const surveyId = useSurveyId()

  const handleAskAiCalledRef = useRef(false)

  const handleAskAi = () => {
    // log event to amplitude
    LogAmplitudeEvent(EventType.ClickedFdChatAskAiButton, {
      surveyId,
      question
    })

    onAskAi({ question })

    handleAskAiCalledRef.current = true
    // clear the input
    setQuestion('')
  }

  return (
    <AskAiBar
      inputValue={question}
      onInputChange={(_, value) => {
        if (handleAskAiCalledRef.current) {
          handleAskAiCalledRef.current = false
        } else {
          setQuestion(value)
        }
      }}
      placeholder={'Ask a follow up question'}
      onAskAi={handleAskAi}
      hideOptions
    />
  )
}

export default AskAiBarContainer
