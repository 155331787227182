import { PaperGroupItem } from '@focaldata/cin-ui-components'
import React from 'react'
import { CardBackgroundContainer } from '../../modules/Questionnaire/CardBackground'

interface Props {
  displayLogic?: JSX.Element
  questionCardHeader: JSX.Element
  switches?: JSX.Element
  responseOptions: JSX.Element
  questionCardFooter?: JSX.Element
  defaultOptions?: JSX.Element | null
  entryId: string
}

const BasicQuestionLayout: React.FC<Props> = (props: Props) => {
  const {
    displayLogic,
    questionCardHeader,
    switches,
    responseOptions,
    questionCardFooter,
    defaultOptions,
    entryId
  } = props

  return (
    <CardBackgroundContainer entryId={entryId}>
      {displayLogic && <PaperGroupItem>{displayLogic}</PaperGroupItem>}
      <PaperGroupItem>{questionCardHeader}</PaperGroupItem>
      {switches && (
        <PaperGroupItem paddingTopBottom={2}>{switches}</PaperGroupItem>
      )}
      <PaperGroupItem paddingTopBottom={2}>
        {responseOptions}
        {questionCardFooter}
      </PaperGroupItem>
      {defaultOptions && (
        <PaperGroupItem paddingTopBottom={2}>{defaultOptions}</PaperGroupItem>
      )}
    </CardBackgroundContainer>
  )
}

export default BasicQuestionLayout
