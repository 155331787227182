import { DEFAULT_FEASIBILITY_LENGTH_OF_INTERVIEW } from '../constants/feasibility'
import { PollingStatus } from '../data/model/costAndFeasibility'
import { useFieldwork } from './useFieldwork'
import { useGetFeasibilityEstimate } from './useGetFeasibilityEstimate'

const usePollIncidenceRate = () => {
  // How often do we want to poll ir rate and feasibility
  const pollInterval = 2 * 60000
  const { fieldwork } = useFieldwork({ pollInterval })
  const incidenceRate = fieldwork?.incidenceRate
  const completes = fieldwork?.samplesNeeded.unadjusted
  // Hardcode LOI to 5 minutes for now regardless of the true LOI
  // to address feasible audiences being flagged as infeasible.
  // This is because CINT lowers the feasibility score while LOI increases.
  const loi = DEFAULT_FEASIBILITY_LENGTH_OF_INTERVIEW

  const { feasibilityEstimate } = useGetFeasibilityEstimate({
    variables: {
      lengthOfInterview: loi
    },
    pollInterval,
    skip: !completes
  })

  const getIncidenceRate = () => {
    return {
      integerIncidenceRate: incidenceRate,
      stringIncidenceRate: incidenceRate?.toString()
    }
  }

  const getFeasibility = () => {
    return {
      isFieldworkFeasible: feasibilityEstimate?.isFeasible,
      estimatedDeliveryTime: feasibilityEstimate?.estimatedDeliveryTime,
      isDonePolling: feasibilityEstimate?.pollingStatus === PollingStatus.DONE
    }
  }

  return {
    getIncidenceRate,
    getFeasibility
  }
}

export default usePollIncidenceRate
