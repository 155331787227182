import { useMutation } from '@apollo/client'
import {
  CreateStandardCriterionOptionV2Mutation,
  CreateStandardCriterionOptionV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import { CREATE_STANDARD_CRITERION_OPTION_V2 } from '../../data/gql/questionnaire/mutations/criterionOptionV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useCreateStandardCriterionOptionV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [createStandardCriterionOptionV2] = useMutation<
    CreateStandardCriterionOptionV2Mutation,
    CreateStandardCriterionOptionV2MutationVariables
  >(CREATE_STANDARD_CRITERION_OPTION_V2, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'CreateStandardCriterionOptionV2',
        error
      )
    }
  })

  return (questionId: string, responseOptionId: string) =>
    createStandardCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          responseOptionId,
          questionId
        }
      }
    })
}
