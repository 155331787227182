import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  footerPlaceholder: {
    paddingTop: theme.spacing(3)
  },
  marginBottom: {
    paddingBottom: theme.spacing(1)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3)
  },
  switchesPlaceholder: {
    width: 260
  }
})

export default useStyles
