import {
  AlignInputText,
  EditorInput,
  EditorInputState,
  Input,
  InputFontSize,
  InputVariant,
  RawDraftContentState
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { splitText } from '../../../hooks/copyPaste/utils'
import { getRawValueFromJsonString } from './CardTitle.utils'

type CardTitleProps = {
  titleValue?: string
  titleStyling?: string
  ariaLabel?: string
  shouldTitleInputFocus?: boolean
  hasError?: boolean
  placeholder?: string
  helperText?: string
  hasEditor?: boolean
  shouldHandlePasteManually?: boolean
  shouldPasteOneLine?: boolean
  onEditorChange?: (rawTitleStyling: string) => void
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void
  onEditorPaste?: (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => void
  onFocus?: () => void
  onBlur?: (value: string) => void
} & (
  | { readOnly: true; onChange?: undefined }
  | { readOnly?: false | undefined; onChange?: (value: string) => void }
)

const CardTitle = ({
  titleValue,
  titleStyling,
  ariaLabel = 'Question header input',
  shouldTitleInputFocus,
  hasError,
  placeholder = 'Type your question',
  helperText = 'Please add a question title',
  hasEditor = false,
  shouldHandlePasteManually = false,
  shouldPasteOneLine,
  readOnly = false,
  onEditorChange,
  onPaste,
  onEditorPaste,
  onFocus,
  onChange,
  onBlur
}: CardTitleProps) => {
  const [rawValue, setRawValue] = useState<RawDraftContentState | undefined>(
    getRawValueFromJsonString(titleStyling, titleValue)
  )

  useEffect(() => {
    setRawValue((previousState) => {
      if (titleStyling === previousState) {
        return previousState
      }
      return getRawValueFromJsonString(titleStyling)
    })
  }, [titleStyling])

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleValueOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event.target.value)
    }
  }

  const handleEditorChange = (inputState: EditorInputState) => {
    const newRawTitleStyling = JSON.stringify(inputState.toRaw())
    if (onEditorChange && newRawTitleStyling !== titleStyling) {
      onEditorChange(newRawTitleStyling)
    }
  }

  const handleOnEditorPaste = (
    inputState: EditorInputState,
    pastedText: string
  ) => {
    const parsedContent = splitText(pastedText)

    if (!parsedContent) return

    const rawTextData = inputState.toRaw()

    const newQuestionTitleStyledText = JSON.stringify(rawTextData)

    const newQuestionTitleText = rawTextData.blocks
      .map((block) => block.text)
      .join('\n')

    onEditorPaste?.(
      newQuestionTitleText,
      newQuestionTitleStyledText,
      parsedContent.responseOptions
    )
  }

  const shouldShowRegularInput = !hasEditor

  return shouldShowRegularInput ? (
    <Input
      ariaLabel={`${ariaLabel} input`}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldTitleInputFocus}
      multiline
      readOnly={readOnly}
      isError={titleValue !== undefined && hasError}
      helperText={helperText}
      value={titleValue as string}
      fullWidth
      variant={InputVariant.Underlined}
      placeholder={placeholder}
      alignInputText={AlignInputText.Left}
      onChange={handleValueChange}
      inputFontSize={InputFontSize.m}
      onFocus={onFocus}
      onBlur={handleValueOnBlur}
      onPaste={onPaste}
    />
  ) : (
    <EditorInput
      shouldHandlePasteManually={shouldHandlePasteManually}
      shouldPasteOneLine={shouldPasteOneLine}
      rawValue={rawValue}
      ariaLabel={`${ariaLabel} input`}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldTitleInputFocus}
      isError={hasError}
      helperText={helperText}
      placeholder={placeholder}
      onChange={handleEditorChange}
      onFocus={onFocus}
      onPaste={handleOnEditorPaste}
    />
  )
}

export default CardTitle

// export default memo(
//   CardTitle,
//   ({ hasEditor, titleValue, titleStyling, hasError }, next) => {
//     if (hasError !== next.hasError) {
//       return false
//     }
//     const hasRawTitle = typeof titleStyling === 'object'
//     // this is done first not for performance upgrade, but to remove unneeded re-renders because of
//     // issue in draft-js https://github.com/facebook/draft-js/issues/1198
//     // this, in turn, makes weird behavior each time components re-renders, so that cursor starts jumping
//     if (hasEditor && hasRawTitle && next.titleStyling) {
//       return areRawTitlesSame(
//         JSON.parse(titleStyling || '""') as RawDraftContentState,
//         JSON.parse(next.titleStyling || '""') as RawDraftContentState
//       )
//     }

//     return titleValue === next.titleValue
//   }
// )
