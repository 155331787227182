import { gql } from '@apollo/client/core'
import { DRAFT_SECTION_ITEM } from './draftSectionItem'

const DRAFT_QUESTIONNAIRE_ENTRY = gql`
  fragment DraftQuestionnaireEntry on DraftQuestionnaireEntry {
    id
    number
    position
    contextPosition
    questionKind
    entryId @client
    sectionId
    entryItem {
      ...DraftEntryItem
      ...DraftSectionItem
    }
    entryType
    forks {
      forkId
      branchNumber
    }
    __typename
  }
  ${DRAFT_SECTION_ITEM}
`

export default DRAFT_QUESTIONNAIRE_ENTRY
