import { EntryType } from '../../../../data/model/questionnaire'
import {
  SidebarEntry,
  SidebarForkItem,
  SidebarMatrixItem,
  SidebarQuestionItem,
  SidebarSectionItem,
  SidebarTextCardItem
} from './SidebarQuestionnaireItemList.query'

export const getEntryListItemPropValues = (
  entry: SidebarEntry
): [string, string, string] => {
  const { entryType, contextPosition } = entry
  if (entryType === EntryType.TextCardEntryType) {
    const entryItem = entry.entryItem as SidebarTextCardItem
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const questionTitle = entryItem.textCard && entryItem.textCard.title
    const displayNumber = `T${contextPosition + 1}`
    return [entryItem.textCardLk, questionTitle, displayNumber]
  }
  if (entryType === EntryType.MatrixEntryType) {
    const entryItem = entry.entryItem as SidebarMatrixItem
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const questionTitle = entryItem.matrixTitle && entryItem.matrixTitle.title
    const displayNumber = `Q${contextPosition + 1}`
    return [entryItem.matrixTitleLk, questionTitle, displayNumber]
  }
  if (entryType === EntryType.ForkEntryType) {
    const entryItem = entry.entryItem as SidebarForkItem
    const title = entryItem.fork.name
    return [entryItem.fork.forkId, title, 'A/B']
  }
  if (entryType === EntryType.SectionEntryType) {
    const entryItem = entry.entryItem as SidebarSectionItem
    const title = `Section ${contextPosition + 1}`
    const displayNumber = `S${contextPosition + 1}`
    return [entryItem.sectionId, title, displayNumber]
  }
  const entryItem = entry.entryItem as SidebarQuestionItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const questionTitle = entryItem.question && entryItem.question.text
  const displayNumber = `Q${contextPosition + 1}`
  return [entryItem.questionLk, questionTitle, displayNumber]
}

export const getFlatSidebarEntries = (entries: SidebarEntry[]) => {
  return entries.flatMap((entry) => {
    if (entry.entryType === EntryType.SectionEntryType) {
      const sectionItem = entry.entryItem as SidebarSectionItem
      const newSectionItem = { ...sectionItem, entries: [] }
      const sectionEntry = {
        ...entry,
        entryItem: newSectionItem
      } as SidebarEntry
      return [sectionEntry, ...sectionItem.entries]
    }
    return entry
  })
}

export const getNestedSidebarEntries = (entries: SidebarEntry[]) => {
  return entries.reduce((previousValue, currentEntry) => {
    if (currentEntry.sectionId) {
      const section = previousValue.find(
        (a) =>
          (a.entryItem as SidebarSectionItem).sectionId ===
          currentEntry.sectionId
      )
      if (section) {
        const { entries } = section.entryItem as SidebarSectionItem
        entries.push(currentEntry)
        const newSectionItem = {
          ...section.entryItem,
          entries
        } as SidebarEntry['entryItem']
        const newSection = {
          ...section,
          entryItem: newSectionItem
        } as SidebarEntry
        const sectionIndex = previousValue.indexOf(section)
        previousValue.splice(sectionIndex, 1, newSection)
      }
      return previousValue
    }

    previousValue.push(currentEntry)
    return previousValue
  }, [] as SidebarEntry[])
}

export const reorderEntries = (
  flatEntries: SidebarEntry[],
  from: number,
  to: number,
  sectionId?: string
) => {
  let itemToMoveCount = 1
  const item = flatEntries[from]
  if (item.entryType === EntryType.SectionEntryType) {
    // if a section is moved all of its children should be also moved to new positions
    const childrenCount = flatEntries.filter(
      (e) => e.sectionId === (item.entryItem as SidebarSectionItem).sectionId
    ).length
    itemToMoveCount += childrenCount
  }
  const [itemToMove, ...childrenItems] = flatEntries.splice(
    from,
    itemToMoveCount
  )
  const updated = { ...itemToMove, sectionId } as SidebarEntry
  // if a section is moved drop position should be adjusted with the number of children
  const dropPosition =
    from < to && item.entryType === EntryType.SectionEntryType
      ? to - itemToMoveCount + 1
      : to
  flatEntries.splice(dropPosition, 0, updated, ...childrenItems)
  return flatEntries
}
