import FullLaunchDialogControl from './FullLaunchDialog.control'

interface Props {
  showDialog: boolean
  toggleOpenDialog: (open: boolean) => void
  onConfirm: () => void
  isPlacingOrder?: boolean
}

const FullLaunchDialogContainer: React.FC<Props> = (props: Props) => {
  const {
    showDialog,
    toggleOpenDialog,
    onConfirm,
    isPlacingOrder = false
  } = props

  return (
    <FullLaunchDialogControl
      isOpen={showDialog}
      toggleOpenDialog={toggleOpenDialog}
      onConfirm={onConfirm}
      loading={isPlacingOrder}
    />
  )
}

export default FullLaunchDialogContainer
