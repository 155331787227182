import { ComponentProps, useCallback, useContext, useMemo } from 'react'
import { ResponseOptionType } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  FieldworkAudienceType,
  MemberSettingCode
} from '../../../data/model/audience'
import { useRemoveCriterionOptionV2 } from '../../../hooks/audience/removeCustomCriterionOptionV2'
import { useUpdateCustomCriterionOptionV2 } from '../../../hooks/audience/updateCustomCriterionOptionV2'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { percentToProportion } from '../../../utils/HelperFunctions'
import AudienceContext from '../Audience.context'
import {
  getCustomAudienceValidationErrors,
  getIsEnabledInSettings
} from '../utils'
import CustomAudienceResponseOptionsListControl from './CustomAudienceResponsesList.control'

const CustomAudienceResponseOptionsList = ({
  questionLk,
  isAudienceInvalid,
  onSetQuotaInfo
}: {
  questionLk: string
  isAudienceInvalid?: boolean
  onSetQuotaInfo?: (responseOptionLk: string, value: number) => void
}) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const questionMutationVariable = useMemo(
    () => ({
      projectId,
      surveyId,
      questionId: questionLk
    }),
    [projectId, questionLk, surveyId]
  )
  const { audienceState } = useContext(AudienceContext)
  const customAudience = audienceState.audienceMembers.find(
    (m) =>
      m.memberType === FieldworkAudienceType.Custom &&
      (m.memberItem as DraftCustomAudience).questionLk === questionLk
  )?.memberItem as DraftCustomAudience

  const updateCustomCriterionOptionV2 = useUpdateCustomCriterionOptionV2()
  const removeCustomCriterionOptionV2 = useRemoveCriterionOptionV2()

  const qualifyingEnabled = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.Qualifying
  )

  const quotasEnabled = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.Quotas
  )

  const handleUpdateOption = useCallback<
    ComponentProps<
      typeof CustomAudienceResponseOptionsListControl
    >['onUpdateOptionText']
  >(
    async (responseOption, newText) => {
      await updateCustomCriterionOptionV2({
        variables: {
          input: {
            ...questionMutationVariable,
            responseOptionId: responseOption.responseOptionLk,
            responseOption: {
              responseOptionValue: newText,
              responseOptionType: ResponseOptionType.RoString
            }
          }
        }
      })
    },
    [questionMutationVariable, updateCustomCriterionOptionV2]
  )

  const handleSetCustomQuota = useCallback(
    async (responseOptionLk: string, percent: number) => {
      const value = Number.isNaN(percent) ? 0 : percentToProportion(percent) // on the server it's 0-1
      await updateCustomCriterionOptionV2({
        variables: {
          input: {
            ...questionMutationVariable,
            responseOptionId: responseOptionLk,
            quota: {
              value
            }
          }
        }
      })
    },
    [questionMutationVariable, updateCustomCriterionOptionV2]
  )

  const handleMoveCustomOption = useCallback(
    (responseOptionLk: string, toPosition: number) => {
      updateCustomCriterionOptionV2({
        variables: {
          input: {
            ...questionMutationVariable,
            responseOptionId: responseOptionLk,
            position: toPosition
          }
        }
      })
    },
    [questionMutationVariable, updateCustomCriterionOptionV2]
  )

  const handleDeleteOption = useCallback(
    (responseOptionLk: string) => {
      removeCustomCriterionOptionV2(questionLk, responseOptionLk)
    },
    [questionLk, removeCustomCriterionOptionV2]
  )

  const handleToggleQualification = useCallback(
    (option: DraftCustomAudienceCriterionOption, enable: boolean) => {
      updateCustomCriterionOptionV2({
        variables: {
          input: {
            ...questionMutationVariable,
            responseOptionId: option.responseOptionLk,
            quota: !option.quota ? { value: 0 } : undefined,
            isOptionQualifying: enable
          }
        }
      })
    },
    [questionMutationVariable, updateCustomCriterionOptionV2]
  )

  const validationErrors = getCustomAudienceValidationErrors(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    audienceState?.validationErrors,
    customAudience.questionLk
  )

  return (
    <CustomAudienceResponseOptionsListControl
      questionLk={customAudience.questionLk}
      responseOptions={customAudience.criterionOptions}
      validationErrors={validationErrors}
      qualifyingEnabled={qualifyingEnabled}
      quotasEnabled={quotasEnabled}
      isAudienceInvalid={isAudienceInvalid}
      onToggleQualification={handleToggleQualification}
      onUpdateQuotaValues={onSetQuotaInfo}
      onDeleteOption={handleDeleteOption}
      onMoveOption={handleMoveCustomOption}
      onSetCustomQuota={handleSetCustomQuota}
      onUpdateOptionText={handleUpdateOption}
    />
  )
}

export default CustomAudienceResponseOptionsList
