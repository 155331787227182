import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  item: {
    padding: theme.spacing(0.75, 0),
    maxWidth: 600
  },
  optionContainer: {
    display: 'flex'
  }
})

export default useStyles
