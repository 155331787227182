import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  hintText: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.25)
  }
})

export default useStyles
