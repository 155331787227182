import {
  CustomCriterionValidationError,
  CustomOptionValidationError
} from '../gql-gen/fieldwork/graphql'
import { QuestionItem } from '../gql-gen/questionnaire/graphql'

export interface Locale {
  language: string
  country: string
}

export type FieldworkAudienceType =
  | 'CintAudience'
  | 'ProlificAudience'
  | 'CustomAudience'

export interface Fieldwork {
  surveyId: string
  locale: Locale
  samplesNeeded: {
    adjusted: number
    unadjusted: number
  }
  lengthOfInterview: {
    adjusted: number
    unadjusted: number
  }
  incidenceRate: number
  duration: {
    startDate: string
    endDate: string
  }
  audience: {
    __typename: FieldworkAudienceType
    countryId?: number
    description?: string
  }[]
  createdDate: Date
  activePresetAudience: string
  presetAudience: ActivePresetAudience
  sinceDate: Date
  markup: number
}

export interface ActivePresetAudience {
  presetAudienceId: string
  name: string
}

export interface Country {
  countryCode: string
  name: string
  locale: Locale
}

export enum EntryType {
  QuestionEntryType = 'QuestionEntryType',
  TextCardEntryType = 'TextCardEntryType',
  MatrixEntryType = 'MatrixEntryType'
}

export interface LocalisedMatrixTitle {
  matrixTitleId: string
  language: string
  country: string
  title: string
  createdDate: string
  localisedDate: string
}

export interface LocalisedQuestion {
  questionId: string
  questionTypeCode: string
  language: string
  country: string
  text: string
  createdDate: string
  localisedDate: string
}

enum ResponseOptionType {
  RoDouble,
  RoInt,
  RoLink,
  RoString
}
export interface LocalisedResponseOption {
  responseOptionId: string
  language: string
  country: string
  rotype: ResponseOptionType
  value: string
  createdDate: string
  localisedDate: string
}

export interface Qualification {
  createdDate: string
  sinceDate: string
}

export interface Route {
  createdDate: string
  sinceDate: string
  targetNumber: number
}

export interface EntryResponseOption {
  position: number
  createdDate: string
  sinceDate: string
  responseOption: LocalisedResponseOption
  qualification: Qualification
  route: Route
}

export interface MatrixRow {
  position: number
  createdDate: string
  sinceDate: string
  question: LocalisedQuestion
}

export interface EntrySettingValue {
  code: string
  value: string
  createdDate: string
  sinceDate: string
}

export interface MatrixItem {
  matrixTitle: LocalisedMatrixTitle
  matrixRows: MatrixRow[]
  responseOptions: EntryResponseOption[]
  settingValues: EntrySettingValue[]
}

export interface LocalisedTextCard {
  textCardId: string
  language: string
  country: string
  title: string
  subtitle: string
  body: string
  createdDate: string
  localisedDate: string
}

export interface TextCardItem {
  textCard: LocalisedTextCard
}

export interface QuestionnaireEntry {
  number: number
  position: number
  contextPosition: number
  createdDate: string
  sinceDate: string
  entryType: EntryType
  entryItem: QuestionItem | TextCardItem | MatrixItem
}

export interface QuotaKey {
  questionId: string
  responseOptionId: string
}

export interface FieldworkQuota {
  surveyId: string
  key: QuotaKey
  percent: number
  respondentCount: number
  createdDate: string
  sinceDate: string
}

export interface FieldworkAudience {
  surveyId: string
  createdDate: string
  sinceDate: string
  entries: QuestionnaireEntry[]
  quotas: FieldworkQuota[]
}
export interface CustomCriterionValidationErrors {
  questionLk: string
  customCriterionErrors: CustomCriterionValidationError[]
  customCriterionOptionsErrors: CustomCriterionOptionValidationErrors[]
}

export interface StandardCriterionValidationErrors {
  code: string
  standardCriterionErrors: StandardCriterionValidationError[]
  standardCriterionOptionsErrors: StandardCriterionOptionValidationErrors[]
}

export type CriterionValidationErrors =
  | CustomCriterionValidationErrors
  | StandardCriterionValidationErrors

export interface CustomCriterionOptionValidationErrors {
  responseOptionLk: string
  errors: CustomOptionValidationError[]
}

export enum StandardCriterionValidationError {
  InvalidQuotaSumError = 'InvalidQuotaSumError',
  MissingQualificationError = 'MissingQualificationError',
  MissingQualifyingSettingWarning = 'MissingQualifyingSettingWarning',
  MissingQuotasSettingWarning = 'MissingQuotasSettingWarning'
}

export interface StandardCriterionOptionValidationErrors {
  code: string
  // erros may have dynamic strings, e.g. `MinAllowedAgeError_N` where N is a number
  errors: (StandardOptionValidationError | string)[]
}

export enum StandardOptionValidationError {
  InvalidQualificationError = 'InvalidQualificationError',
  QuotaIsZeroError = 'QuotaIsZeroError',
  MinAgeBiggerThanMaxError = 'MinAgeBiggerThanMaxError',
  InvalidCustomizedCriterionOptionCodeError = 'InvalidCustomizedCriterionOptionCodeError',
  AgeRangeOverlapError = 'AgeRangeOverlapError',
  AgeBandsGapError = 'AgeBandsGapError'
}
