import { Checkbox, TextHighlight, TextSize } from '@focaldata/cin-ui-components'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  DraftQuestionItem,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { getSettingValue } from '../../../utils/HelperFunctions'
import {
  WithEntry,
  getQuestionEnabledSettings
} from '../../../utils/questionnaireUtils'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import useStyles from './QuestionCardDefaultOptions.styles'

const DefaultOptions: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const entryItem: DraftQuestionItem = entry.entryItem as DraftQuestionItem
  const { classes, cx: classNames } = useStyles()
  const dispatch = useAppDispatch()
  const settings = useAppSelector((state) =>
    selectSettingsByQuestionId(state, entryItem.questionLk)
  )

  const textSize = TextSize.ms
  const surveyId = useSurveyId()

  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const handleChangeNoneOfThese: (settingValue: string) => void = (
    settingValue
  ) => {
    dispatch(
      setQuestionSetting({
        questionLk: entryItem.questionLk,
        code: QuestionSettingCode.NotApplicable,
        value: settingValue
      })
    )
    handleSettingChange(
      [{ settingCode: QuestionSettingCode.NotApplicable, settingValue }],
      'no-cache'
    )
  }

  const enabledSettings = getQuestionEnabledSettings(settings)

  const shouldShowNotApplicable = enabledSettings.has(
    QuestionSettingCode.NotApplicable
  )

  return (
    <div className={classNames('fd-grid fd-container', classes.root)}>
      <Checkbox
        checked={shouldShowNotApplicable}
        ariaLabel="Not applicable"
        label="Not applicable"
        textSize={textSize}
        textHighlight={TextHighlight.Background}
        onChange={(event) => {
          const settingValue = getSettingValue(event.target.checked)
          handleChangeNoneOfThese(settingValue)

          if (settingValue === 'enabled') {
            LogAmplitudeEvent(EventType.TickedDefaultResponseOption, {
              surveyId,
              label: 'Not applicable'
            })
          }
        }}
      />
    </div>
  )
}

export default DefaultOptions
