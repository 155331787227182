import { Paragraph } from 'docx'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import {
  getQuestionEnabledSettings,
  getQuestionSettings
} from '../questionnaireUtils'
import { addMediaIfNeeded } from './addMediaUrlIfNeeded'
import { createResponseOptions } from './createResponseOptions'
import { Order } from './model'

export const createEntryContentBasic = async (
  entry: DraftQuestionnaireEntry,
  entries: DraftQuestionnaireEntry[]
): Promise<Paragraph[]> => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const paragraphs = createResponseOptions(entryItem, entries)

  await addMediaIfNeeded(entryItem, paragraphs)

  const enabledSettings = getQuestionEnabledSettings(entryItem.settingValues)
  const questionSettings = getQuestionSettings(entryItem.settingValues)

  if (enabledSettings.has(QuestionSettingCode.RandomiseOptions)) {
    paragraphs.push(new Paragraph(Order.Randomise))
  }

  if (enabledSettings.has(QuestionSettingCode.FlipOptions)) {
    paragraphs.push(new Paragraph(Order.Reverse))
  }

  if (questionSettings.has(QuestionSettingCode.ChoiceLimit)) {
    const choiceLimit = questionSettings.get(QuestionSettingCode.ChoiceLimit)
    paragraphs.push(new Paragraph(`Choice limit: ${choiceLimit}`))
  }

  if (
    questionSettings.has(QuestionSettingCode.ChoiceIntervalMin) &&
    questionSettings.has(QuestionSettingCode.ChoiceIntervalMax)
  ) {
    const min = questionSettings.get(QuestionSettingCode.ChoiceIntervalMin)
    const max = questionSettings.get(QuestionSettingCode.ChoiceIntervalMax)
    paragraphs.push(new Paragraph(`Choice range: ${min}-${max}`))
  }

  return paragraphs
}
