import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { Grid } from '@mui/material'
import React from 'react'
import useStyles from '../Auth.styles'

interface Props {
  isLengthValid: boolean
  isUsingNumbers: boolean
  isUsingCapitals: boolean
}

const PasswordChecklist: React.FC<Props> = (props: Props) => {
  const { isLengthValid, isUsingNumbers, isUsingCapitals } = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const getCheckedIcon: (isValid: boolean) => JSX.Element = (isValid) => {
    if (isValid) {
      return (
        <Icon
          name={IconName.CheckCircle}
          iconColor={IconColor.Enabled}
          size={IconSize.Large}
        />
      )
    }

    return <div className={classes.uncheckedCircle} />
  }

  const lengthCheckIcon = getCheckedIcon(isLengthValid)
  const numberCheckIcon = getCheckedIcon(isUsingNumbers)
  const caseCheckIcon = getCheckedIcon(isUsingCapitals)

  return (
    <Grid container direction="column" className={classes.passwordChecklist}>
      <Grid
        container
        xs="auto"
        alignItems="center"
        className={classes.checkListTitle}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightSemiBold
          )}
        >
          Your password must have:
        </p>
      </Grid>
      <Grid
        container
        xs="auto"
        alignItems="center"
        className={classes.checkListItem}
      >
        {lengthCheckIcon}
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.marginLeft
          )}
        >
          8 or more characters
        </p>
      </Grid>
      <Grid
        container
        xs="auto"
        alignItems="center"
        className={classes.checkListItem}
      >
        {numberCheckIcon}
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.marginLeft
          )}
        >
          At least one number (0-9)
        </p>
      </Grid>
      <Grid
        container
        xs="auto"
        alignItems="center"
        className={classes.checkListItem}
      >
        {caseCheckIcon}
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.marginLeft
          )}
        >
          Upper and lowercase letters
        </p>
      </Grid>
    </Grid>
  )
}

export default PasswordChecklist
