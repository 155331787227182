import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  questionType: {
    paddingRight: theme.spacing(2)
  },
  identifier: {
    padding: theme.spacing(0, 1.5, 0.75, 0)
  },
  quotaRow: {
    paddingTop: theme.spacing(2),
    height: '100%'
  },
  subtitle: {
    paddingBottom: theme.spacing(1.5)
  },
  optionText: {
    height: '100%',
    borderBottom: `1px solid ${additionalPalette.lightGrey.main}`
  },
  quotaPercentage: {
    height: '100%',
    width: '100%'
  },
  textQuotaPercentage: {
    height: '100%',
    width: '60%',
    borderBottom: `1px solid ${additionalPalette.lightGrey.main}`
  },
  progressBar: {
    paddingTop: theme.spacing(2)
  },
  quotaCardContainer: {
    marginBottom: theme.spacing(4)
  },
  quotaEmptyState: {
    paddingBottom: theme.spacing(4)
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(4),
    height: theme.spacing(0.25),
    backgroundColor: theme.palette.secondary.main
  }
})

export default useStyles
