import { Select, SelectVariant } from '@focaldata/cin-ui-components'
import { useUpdateEffect } from 'react-use'
import {
  RANK_CHOICE_REQUISITE_SELECTOR,
  RESPONSE_OPTIONS_LIMIT_RESET_OPTION
} from './constants'
import { rankChoiceRequisiteOptions } from './utils'

interface Props {
  rankChoiceRequisite?: number
  responseOptionsCount?: number
  onChangeRankChoiceRequisite?: (settingValue: number) => void
}

const RankChoiceRequisite: React.FC<Props> = (props: Props) => {
  const {
    rankChoiceRequisite = 0,
    responseOptionsCount = 0,
    onChangeRankChoiceRequisite
  } = props

  // This will limit the number of options in the dropdown to 30
  const rankingChoiceRequisiteLength =
    responseOptionsCount >= 30 ? 31 : responseOptionsCount

  const resetRankIfNeeded = () => {
    if (
      rankChoiceRequisite &&
      onChangeRankChoiceRequisite &&
      responseOptionsCount <= rankChoiceRequisite
    ) {
      onChangeRankChoiceRequisite(RESPONSE_OPTIONS_LIMIT_RESET_OPTION)
    }
  }

  useUpdateEffect(
    function onRankChange() {
      resetRankIfNeeded()
    },
    [responseOptionsCount]
  )

  return (
    <div className="fd-grid fd-container fd-item fd-justify-content-end">
      <Select
        ariaLabel={RANK_CHOICE_REQUISITE_SELECTOR}
        optionValue={rankChoiceRequisite}
        minWidth={142}
        options={rankChoiceRequisiteOptions(rankingChoiceRequisiteLength)}
        variant={SelectVariant.Filled}
        onChange={(event) => {
          if (onChangeRankChoiceRequisite) {
            const eventValue = parseInt(event.target.value, 10)
            onChangeRankChoiceRequisite(eventValue)
          }
        }}
      />
    </div>
  )
}

export default RankChoiceRequisite
