import { PinResponse } from '@focaldata/cin-ui-components'
import {
  usePinResponseOption,
  useSetPinnedMatrixRow
} from './PinResponse.hooks'
import { PinnedItemType } from './PinResponse.model'

const getTooltipMessage = (
  itemType: PinnedItemType,
  pinned: boolean
): string => {
  switch (itemType) {
    case PinnedItemType.MatrixRow: {
      if (pinned) {
        return 'Unpin row'
      }

      return 'Pin row'
    }
    case PinnedItemType.ResponseOption:
    default: {
      if (pinned) {
        return 'Unpin response option'
      }

      return 'Pin response option'
    }
  }
}

const PinResponseContainer = (
  props: {
    isPinned: boolean
    entryId: string
  } & (
    | {
        itemType: PinnedItemType.ResponseOption
        responseOptionLk: string
      }
    | {
        itemType: PinnedItemType.MatrixRow
        questionLk: string
      }
  )
) => {
  const { isPinned, entryId, itemType } = props
  const pinResponseOption = usePinResponseOption(entryId)
  const setPinnedMatrixRow = useSetPinnedMatrixRow(entryId)

  const handleTogglePinned = (): void => {
    switch (itemType) {
      case PinnedItemType.ResponseOption: {
        pinResponseOption(props.responseOptionLk, !isPinned)
        break
      }
      case PinnedItemType.MatrixRow: {
        setPinnedMatrixRow(entryId, props.questionLk, !isPinned)
        break
      }
      default:
        break
    }
  }

  return (
    <PinResponse
      tooltip={getTooltipMessage(itemType, isPinned)}
      onTogglePinned={handleTogglePinned}
    />
  )
}

export default PinResponseContainer
