interface HeightWidth {
  height: number
  width: number
}

export const getImageDimensionsAsync = (src: string): Promise<HeightWidth> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ height: img.height, width: img.width })
    img.onerror = reject
    img.src = src
  })
}

export const getScaledImageDimensions = (
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number
) => {
  const widthRatio = maxWidth / width
  const heightRatio = maxHeight / height
  const ratio = Math.min(widthRatio, heightRatio)
  return { width: width * ratio, height: height * ratio }
}
