import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  equalIcon: {
    color: additionalPalette.lightGrey.main
  },
  listItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75)
  },
  inputStyle: {
    '& .Mui-disabled': {
      WebkitTextFillColor: theme.palette.text.primary
    },
    '& input.MuiInputBase-input': {
      padding: theme.spacing(1.25, 2),
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.text.secondary
    },
    borderRadius: theme.spacing(0.25)
  },
  inputRootStyle: {
    '& input.MuiInputBase-input': {
      color: theme.palette.text.secondary,
      WebkitTextFillColor: theme.palette.text.secondary
    },

    '&.MuiOutlinedInput-root > fieldset': {
      borderColor: additionalPalette.lightBlue.secondary
    },
    '&.MuiOutlinedInput-root:hover > fieldset': {
      borderColor: additionalPalette.lightBlue.secondary
    },
    '&.MuiOutlinedInput-root.Mui-disabled > fieldset': {
      borderColor: additionalPalette.lightBlue.secondary
    },
    '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
      borderColor: additionalPalette.lightBlue.secondary,
      borderWidth: 1
    }
  }
}))

export default useStyles
