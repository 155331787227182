import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  titleContainer: {
    display: 'inherit'
  },
  audienceCard: {
    '&.MuiGrid-item': {
      margin: theme.spacing(4)
    }
  },
  loadingContentPlaceholder: {
    height: 300
  },
  ageRangeSwitch: {
    width: '100%',
    padding: theme.spacing(0, 1)
  }
})

export default useStyles
