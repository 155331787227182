import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import ContentPageLayout from '../ContentPageLayout'

interface Props {
  headers: JSX.Element
  content: JSX.Element
  bottomNav?: JSX.Element
  hasOneSubheader: boolean
  hasOneSubheaderNoFooter: boolean
  isQuestionnairePage?: boolean
}

const SurveyLayout: React.FC<Props> = (props: Props) => {
  const {
    headers,
    content,
    bottomNav,
    hasOneSubheader,
    hasOneSubheaderNoFooter,
    isQuestionnairePage
  }: Props = props

  return (
    <Grid container direction="column">
      {headers}
      <ContentPageLayout
        content={content}
        hasOneSubheader={hasOneSubheader}
        hasOneSubheaderNoFooter={hasOneSubheaderNoFooter}
        isQuestionnairePage={isQuestionnairePage}
      />
      {bottomNav}
    </Grid>
  )
}

export default SurveyLayout
