import {
  Button,
  ButtonVariant,
  Grid,
  Paper,
  Skeleton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './styles'

const ConfirmationCostBreakdownLoader: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Paper>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.content}
      >
        <Grid item className={classes.purchaseGrid}>
          <Button variant={ButtonVariant.PrimaryFilled} fullWidth disabled>
            Confirm
          </Button>
        </Grid>
        <Grid item className={classes.costBreakdownOuterGrid}>
          <Grid
            container
            item
            className={classes.costBreakdownInnerGrid}
            spacing={1}
          >
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Cost per complete:
                </Text>
              </Grid>
              <Grid item>
                <Skeleton width={40} height={21} />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Number of completes:
                </Text>
              </Grid>
              <Grid item>
                <Skeleton width={40} height={21} />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                  Total cost for completes:
                </Text>
              </Grid>
              <Grid item>
                <Skeleton width={40} height={21} />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Hosting:
                </Text>
              </Grid>
              <Grid item>
                <Skeleton width={40} height={21} />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-between">
              <Grid item>
                <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                  Raw data:
                </Text>
              </Grid>
              <Grid item>
                <Skeleton width={40} height={21} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.purchaseGrid}>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                Total cost:
              </Text>
            </Grid>
            <Grid item>
              <Skeleton width={40} height={21} />
            </Grid>
          </Grid>
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item>
              <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                Completion date:
              </Text>
            </Grid>
            <Grid item>
              <Skeleton width={40} height={21} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ConfirmationCostBreakdownLoader
