import {
  Dialog,
  Grid,
  Input,
  InputType,
  InputVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction, useState } from 'react'

interface Props {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  onClickSave: (title: string) => void
  projectTitle: string | undefined
}

const EditProjectNameDialog: React.FC<Props> = (props: Props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    onClickSave,
    projectTitle = ''
  } = props
  const [title, setTitle] = useState<string>(projectTitle)

  const isSaveButtonDisabled = title.length === 0

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
      primaryButtonClick={() => {
        onClickSave(title)
        setIsDialogOpen(false)
      }}
      primaryButtonText="Save"
      primaryButtonDisabled={isSaveButtonDisabled}
      title="Edit project title"
      onClickOutside={() => setIsDialogOpen(false)}
    >
      <Grid container direction="column" justifyContent="space-between">
        <Grid item xs={12}>
          <Text gutterBottom size={TextSize.m} weight={TextWeight.SemiBold}>
            Project title
          </Text>

          {projectTitle && (
            <Input
              ariaLabel="Project title"
              defaultValue={projectTitle}
              fullWidth
              inputType={InputType.Text}
              onChange={(event) => setTitle(event.target.value)}
              variant={InputVariant.Filled}
              dataCy="dialog-project-edit-title"
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default EditProjectNameDialog
