import { createContext } from 'react'

export interface MaskingDialogContextData {
  isDialogOpen: boolean
  setIsDialogOpen: (isOpen: boolean) => void
}

export const MaskingDialogContext = createContext<MaskingDialogContextData>({
  isDialogOpen: false,
  setIsDialogOpen: () => null
})
