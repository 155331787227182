import { Fragment } from 'react'
import { TextHighlight, TextSize } from '../../../../constants/text'
import {
  DraftMaskingRule,
  ForkTag,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionKind
} from '../../../../data/model/questionnaire'
import { getForkItem, questionTextTo } from '../../DisplayLogicHeader/util'
import { ResponseOptionsByQuestion } from '../../Questionnaire.slice'

interface QuestionAndRoText {
  text: string
  responseOptions: {
    roLk: string
    roText: string
  }[]
}

interface MatrixAndRoText {
  text: string
  questions: {
    questionLk: string
    text: string
  }[]
  responseOptions: {
    roLk: string
    text: string
  }[]
}

const createQuestionAndRoTextMap = (
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): Map<string, QuestionAndRoText> => {
  const questionTextMap = entries.reduce((acc, entry) => {
    if (entry.entryType === EntryType.QuestionEntryType) {
      const question = entry.entryItem as DraftQuestionItem
      const key = question.questionLk
      const prefix =
        entry.questionKind === QuestionKind.QuestionnaireKind ? 'Q' : 'A'
      const value: QuestionAndRoText = {
        text: `${prefix}${entry.contextPosition + 1}`,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        responseOptions: responseOptionsByQuestion[question.questionLk]?.map(
          (ro) => {
            return {
              roLk: ro.responseOptionLk,
              roText: ro.responseOption?.value ?? ''
            }
          }
        )
      }

      acc.set(key, value)
    }

    return acc
  }, new Map<string, QuestionAndRoText>())

  return questionTextMap
}

const createMatrixAndRoTextMap = (
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): Map<string, MatrixAndRoText> => {
  const matrixTextMap = entries.reduce((acc, entry) => {
    if (entry.entryType === EntryType.MatrixEntryType) {
      const matrix = entry.entryItem
      const key = matrix.matrixTitleLk
      const value: MatrixAndRoText = {
        text: `Q${entry.contextPosition + 1}`,
        questions: matrix.matrixRows.map((mr, index) => {
          return { text: `.${index + 1}`, questionLk: mr.questionLk }
        }),
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        responseOptions: responseOptionsByQuestion[matrix.matrixTitleLk]?.map(
          (ro) => {
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            return {
              text: ro.responseOption?.value ?? '',
              roLk: ro.responseOptionLk
            }
          }
        )
      }

      acc.set(key, value)
    }

    return acc
  }, new Map<string, MatrixAndRoText>())

  return matrixTextMap
}
// this should just go through the masking rules and show stuff
export const getMaskingSummary = (
  maskingRules: DraftMaskingRule[],
  entries: DraftQuestionnaireEntry[] | undefined,
  isResponseOption: boolean,
  responseOptionsByQuestion: ResponseOptionsByQuestion,
  forks?: ForkTag[]
): JSX.Element[] | JSX.Element => {
  const maskedItemType = isResponseOption ? 'response' : 'row'
  if (
    !entries ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ((!maskingRules || maskingRules.length === 0) &&
      (!forks || forks.length === 0))
  ) {
    return (
      <p
        style={{
          color: TextHighlight.White,
          fontSize: TextSize.s
        }}
      >
        Add display logic to this {`${maskedItemType}`}
      </p>
    )
  }

  const questionTextMap = createQuestionAndRoTextMap(
    entries,
    responseOptionsByQuestion
  )
  const matrixTextMap = createMatrixAndRoTextMap(
    entries,
    responseOptionsByQuestion
  )

  const prefix: JSX.Element = (
    <p
      style={{
        color: TextHighlight.White,
        display: 'inline',
        fontSize: TextSize.s
      }}
    >
      Display this{` ${maskedItemType} `}
    </p>
  )

  const forkContent: JSX.Element[] =
    forks?.map((fork, index) => (
      <Fragment key={fork.forkId}>
        {getForkItem(fork, entries, TextSize.s, TextHighlight.White)}
        {index !== forks.length - 1 && (
          <p
            style={{
              color: TextHighlight.White,
              display: 'inline',
              fontSize: TextSize.s
            }}
          >
            {' '}
            OR{' '}
          </p>
        )}
        {index === forks.length - 1 &&
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          maskingRules &&
          maskingRules.length > 0 && (
            <p
              style={{
                color: TextHighlight.White,
                display: 'inline',
                fontSize: TextSize.s
              }}
            >
              {' '}
              AND{' '}
            </p>
          )}
      </Fragment>
    )) || []

  const content: JSX.Element[] = maskingRules.map((maskingRule, index) => {
    const qLk: string =
      (maskingRule.maskingKeys.length > 0 &&
        maskingRule.maskingKeys[0].questionLk) ||
      ''
    const mLk: string =
      (maskingRule.maskingKeys.length > 0 &&
        maskingRule.maskingKeys[0].matrixTitleLk) ||
      ''

    // they should never both be defined
    const value = questionTextMap.get(qLk)
    const matrixValue = matrixTextMap.get(mLk)
    const negationText = maskingRule.negated ? 'is NOT' : 'is'

    if (value) {
      const newValue = {
        ...value,
        responseOptions: value.responseOptions
          .filter((ro) =>
            maskingRule.maskingKeys.some(
              (mk) => mk.responseOptionLk === ro.roLk
            )
          )
          .map((ro) => ro.roText)
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {questionTextTo({
            questionText: newValue.text,
            roTexts: newValue.responseOptions,
            highlight: TextHighlight.White,
            size: TextSize.s,
            operator: index > 0 ? maskingRule.clause : undefined,
            negationText
          })}
        </Fragment>
      )
    }

    if (matrixValue) {
      const newValue = {
        ...matrixValue,
        text:
          matrixValue.text +
          matrixValue.questions.find((question) => question.questionLk === qLk)
            ?.text,
        responseOptions: matrixValue.responseOptions
          .filter((ro) =>
            maskingRule.maskingKeys.some(
              (mk) => mk.responseOptionLk === ro.roLk
            )
          )
          .map((ro) => ro.text)
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {questionTextTo({
            questionText: newValue.text,
            roTexts: newValue.responseOptions,
            highlight: TextHighlight.White,
            size: TextSize.s,
            operator: index > 0 ? maskingRule.clause : undefined,
            negationText
          })}
        </Fragment>
      )
    }

    return <></>
  })

  return (
    <>
      {prefix}
      {forkContent}
      {content}
    </>
  )
}

export const getEntryPosition = (
  entryType: EntryType | undefined,
  entries: DraftQuestionnaireEntry[] | undefined,
  questionLk: string | undefined
) => {
  return (
    (entries &&
      entries.find((entry) => {
        if (entryType === EntryType.QuestionEntryType) {
          return (
            (entry.entryItem as DraftQuestionItem).questionLk === questionLk
          )
        }
        if (entryType === EntryType.MatrixEntryType) {
          return (
            (entry.entryItem as DraftMatrixItem).matrixTitleLk === questionLk
          )
        }
        return false
      })?.position) ??
    -1
  )
}

export const toDraftMaskingRule = (
  rule: MaskingRuleInput
): DraftMaskingRule => {
  return {
    __typename: 'DraftMaskingRule',
    negated: rule.negated,
    maskingKeys: rule.maskingKeys.map((mk) => {
      return {
        __typename: 'DraftMaskingKey',
        questionLk: mk.questionId,
        matrixTitleLk: mk.matrixTitleId,
        responseOptionLk: mk.responseOptionId
      }
    }),
    clause: rule.clause
  }
}
