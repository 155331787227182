import { gql } from '@apollo/client/core'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

const PASTE_IN_AUDIENCE_TITLE_V2 = gql`
  mutation pasteAudienceMemberTextFieldsV2(
    $input: PasteAudienceMemberTextFieldsArgsInput!
  ) {
    pasteAudienceMemberTextFieldsV2(input: $input) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
export default PASTE_IN_AUDIENCE_TITLE_V2
