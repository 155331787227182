import { makeStyles } from '@focaldata/cin-ui-components'

interface StyleProps {
  width?: string | number
}

const useStyles = makeStyles<{ styleProps?: StyleProps }>()(
  (theme, { styleProps: props }) => ({
    childStyle: {
      paddingLeft: theme.spacing(0.75)
    },
    container: {
      maxWidth: props?.width,
      height: '100%',
      marginRight: `-${theme.spacing(2.5)}`
    },
    multiMarketContainer: {
      maxWidth: props?.width,
      height: theme.spacing(6)
    },
    costContainer: {
      height: theme.spacing(6)
    },
    dividerCost: {
      height: theme.spacing(4),
      marginLeft: theme.spacing(2)
    },
    dividerFielderwork: {
      height: theme.spacing(4),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  })
)

export default useStyles
