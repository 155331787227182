import { Ref, forwardRef } from 'react'
import NumberFormat from 'react-number-format'

export const NumberFormatCustom = forwardRef(function NumberFormatCustom(
  props: any,
  ref: Ref<HTMLInputElement>
) {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      decimalScale={1}
      isNumericString
      allowNegative={false}
      allowedDecimalSeparators={[',', '.']}
    />
  )
})

export const PositiveIntegerNumberFormatCustom = forwardRef(
  function PositiveIntegerNumberFormatCustom(
    props: any,
    ref: Ref<HTMLInputElement>
  ) {
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        isNumericString
        decimalScale={0}
        allowNegative={false}
      />
    )
  }
)
export const NumberFormatSliderIncrement = forwardRef(
  function NumberFormatSliderIncrement(props: any, ref: Ref<HTMLInputElement>) {
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        decimalScale={1}
        isNumericString
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
      />
    )
  }
)
