import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  divider: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4.5)
  },
  skeleton: {
    paddingBottom: theme.spacing(0.75)
  },
  scrollableSidebar: {
    flexGrow: 1,
    overflowY: 'scroll',
    paddingTop: '16px'
  }
})

export default useStyles
