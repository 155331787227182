import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const AUDIENCE_VALIDATION_ERRORS = questionnaireGql(/* GraphQL */ `
  query audienceValidationErrors($projectId: ID!, $surveyId: ID!) {
    draftAudienceV2(projectId: $projectId, surveyId: $surveyId) {
      validationErrors {
        __typename
        ...CustomCriterionValidationErrors
        ...StandardCriterionValidationErrors
      }
    }
  }
`)
