import {
  Grid,
  InfoIcon,
  InfoIconColor,
  TooltipPlacement,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import { proportionToPercent } from '../../utils/HelperFunctions'
import useStyles from './styles'

interface Props {
  isCustomQuestion?: boolean
  isCustomAudience?: boolean
  stickRight?: boolean
  showTotal?: boolean
  totalPercentage?: number | undefined
  button?: React.ReactNode
}

const QuotaEditorFooter: React.FC<Props> = (props: Props) => {
  const {
    showTotal = true,
    stickRight = false,
    isCustomQuestion = false,
    isCustomAudience = false,
    totalPercentage: totalProportion = 0,
    button
  } = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const totalPercentage = proportionToPercent(totalProportion)

  const TotalPercentage = (
    <Grid container item xs={2} direction="row">
      <Grid container item xs={8}>
        <Grid
          container
          item
          xs={isCustomQuestion ? 10 : 9}
          justifyContent="center"
          className={
            totalPercentage === 100
              ? classes.correctPercentage
              : classes.wrongPercentage
          }
        >
          <p
            className={classNames(
              textClasses.default,
              textClasses.weightSemiBold,
              textClasses.highlightBackground
            )}
          >
            {totalPercentage}
          </p>
        </Grid>
        <Grid
          container
          item
          xs={2}
          justifyContent="flex-end"
          alignContent="center"
        >
          {totalPercentage !== 100 ? (
            <InfoIcon
              className={classes.errorIcon}
              iconColor={InfoIconColor.Error}
              text="Quotas have to add up to 100%"
              tooltipPlacement={TooltipPlacement.Right}
            />
          ) : (
            <p
              className={classNames(
                textClasses.default,
                classes.percentageText
              )}
            >
              %
            </p>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.totalPercentageGrid}
    >
      <Grid item xs={isCustomAudience ? 6 : 9}>
        {button}
      </Grid>
      {showTotal &&
        (stickRight ? (
          <>
            <Grid item container xs={1} />
            {TotalPercentage}
          </>
        ) : (
          <>
            {TotalPercentage}
            <Grid item container xs={1} />
          </>
        ))}
    </Grid>
  )
}

export default QuotaEditorFooter
