import { questionnaireGql } from '../../../gql-gen/questionnaire'

const QUESTION_MEDIA = questionnaireGql(/* GraphQL */ `
  fragment QuestionMedia on QuestionMedia {
    mediaName
    mediaUrl
    mediaType
    createdDate
    sinceDate
    renderedMediaUrl
    __typename
  }
`)

export default QUESTION_MEDIA
