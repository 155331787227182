import { useState } from 'react'
import StagingInfoButton from './StagingInfoButton'
import StagingInfoLabel from './StagingInfoLabel'

interface Props {
  branch: string
  version: string
}

const StagingInfoBanner = ({ branch, version }: Props) => {
  const [infoToggled, setInfoToggled] = useState(true)

  return infoToggled ? (
    <StagingInfoLabel
      branch={branch}
      version={version}
      onClose={() => setInfoToggled(false)}
    />
  ) : (
    <StagingInfoButton onClick={() => setInfoToggled(true)} />
  )
}

export default StagingInfoBanner
