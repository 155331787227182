import { useEffect, useState } from 'react'
import {
  GetAccountsByEmail,
  GetUserByEmail,
  Refresh
} from '../data/rest/accountUser'
import { Datadog } from '../tracking/datadog'
import UserSession from '../utils/UserSession'
import { AccessToken, getLoginRedirectPath } from '../utils/authUtils'

const useGetUserAndAccount = () => {
  const [account, setAccount] = useState<string | undefined>(undefined)
  const [userName, setUserName] = useState<string | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [markup, setMarkup] = useState<number>(0.0)

  const email = UserSession.getEmail()

  useEffect(() => {
    const setUserDetails = async (accessToken: string) => {
      if (!email) {
        return
      }

      const responseAccount = await GetAccountsByEmail(accessToken, email)
      const { markup, name } = responseAccount.data[0]
      setAccount(name)
      setMarkup(markup)
      const responseUser = await GetUserByEmail(accessToken, email)
      const userName = `${responseUser.data.firstName} ${responseUser.data.lastName}`
      setUserName(userName)
      Datadog.updateUserSession({
        name: userName
      })
    }
    const getUserAndAccount: () => Promise<void> = async () => {
      if (email) {
        try {
          setLoading(true)
          const { token, valid } = AccessToken.verify()
          if (valid) {
            await setUserDetails(token)
          } else {
            const response = await Refresh()
            await setUserDetails(response.data.accessToken)
          }
        } catch (err) {
          setTimeout(() => {
            getUserAndAccount()
          }, 3000)
          setError(true)
        }
      } else {
        const redirectTo = getLoginRedirectPath()
        window.location.assign(`${window.location.origin}${redirectTo}`)
      }
    }

    getUserAndAccount().finally(() => {
      setLoading(false)
    })
  }, [email])

  return { userName, account, error, loading, markup }
}

export default useGetUserAndAccount
