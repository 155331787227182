import { SpinnerDialog, textStyleUtils } from '@focaldata/cin-ui-components'

export const MappingProgressDialog = ({
  inProgress
}: {
  inProgress: boolean
}) => {
  const { classes, cx: classNames } = textStyleUtils.useTextStyles()

  return (
    <SpinnerDialog
      isVisible={inProgress}
      transparentBackground
      text="Mapping your questions..."
      subtext={
        <>
          <span
            className={classNames(classes.default, classes.displayBlock)}
            style={{ marginTop: '24px' }}
          >
            This may take up to 60 seconds.
          </span>
          <span className={classNames(classes.default)}>
            Please do not refresh this page.
          </span>
        </>
      }
    />
  )
}
