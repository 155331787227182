import {
  EntryType,
  MatrixLoopingConfigInput,
  SourceMatrixRowQuotaInput
} from '../../../data/gql-gen/questionnaire/graphql'
import { MatrixLoopingConfig } from '../../../modules/Questionnaire/Looping/Looping.model'
import { useDraftQuestionnaireEntry } from '../useDraftQuestionnaireEntry'
import { useUpdateQuestionnaireEntry } from '../useUpdateQuestionnaireEntry'

const toMatrixLoopingConfigInput = (
  matrixLoopingConfig: MatrixLoopingConfig | undefined
): MatrixLoopingConfigInput | undefined => {
  if (!matrixLoopingConfig) {
    return undefined
  }
  return {
    qualifyingResponseOptionIds:
      matrixLoopingConfig.qualifyingResponseOptionIds,
    sourceMatrixRowQuotas: matrixLoopingConfig.sourceMatrixRowQuotas.reduce<
      SourceMatrixRowQuotaInput[]
    >((quota, { questionId, quotaTarget }) => {
      if (quotaTarget === undefined) {
        return quota
      }

      return [
        ...quota,
        {
          questionId,
          quotaTarget
        }
      ]
    }, [])
  }
}

export const useSetLoopSpecificDisplayLogic = (entryId: string) => {
  const entry = useDraftQuestionnaireEntry(entryId)
  const entrySection = useDraftQuestionnaireEntry(entry?.sectionId ?? '')
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(
    entrySection?.id ?? ''
  )
  const setLoopSpecificDisplayLogic = async (sourceIds: string[] | null) => {
    if (!entry?.sectionId) {
      throw new Error(
        'Entry does not have a sectionId. Loop-based display logic can only be set on section entries.'
      )
    }
    if (entrySection?.entryType !== EntryType.SectionEntryType) {
      throw new Error(
        'Loop-based display logic can only be set on section entries.'
      )
    }
    const loopSpecificDisplayLogics =
      entrySection.entryItem.loopingConfig?.loopSpecificDisplayLogics ?? []

    const sectionLoopingConfig = entrySection.entryItem.loopingConfig

    if (!sectionLoopingConfig) {
      throw new Error(
        'Section does not have loopingConfig. Loop-based display logic can only be set on sections with looping enabled.'
      )
    }

    const getNewLoopSpecificDisplayLogics = () => {
      // remove existing logic for entry
      if (sourceIds === null) {
        return loopSpecificDisplayLogics.filter(
          (logic) => logic.entryNumber !== entry.number
        )
      }

      const existingLogicIndex = loopSpecificDisplayLogics.findIndex(
        (logic) => logic.entryNumber === entry.number
      )

      // update existing logic for entry
      if (existingLogicIndex > -1) {
        return loopSpecificDisplayLogics.map((logic, index) =>
          index === existingLogicIndex ? { ...logic, sourceIds } : logic
        )
      }

      // add new logic for entry
      return [
        ...loopSpecificDisplayLogics,
        {
          entryNumber: entry.number,
          sourceIds
        }
      ]
    }

    await updateQuestionnaireEntry({
      updateSection: {
        loopingConfig: {
          loopSpecificDisplayLogics: getNewLoopSpecificDisplayLogics(),
          matrixLoopingConfig: toMatrixLoopingConfigInput(
            sectionLoopingConfig.matrixLoopingConfig
          ),
          loopBasis: sectionLoopingConfig.loopBasis,
          loopRandomisationEnabled:
            sectionLoopingConfig.loopRandomisationEnabled,
          maxIterations: sectionLoopingConfig.maxIterations,
          sourceEntryNumber: sectionLoopingConfig.sourceEntryNumber
        }
      }
    })
  }

  return setLoopSpecificDisplayLogic
}
