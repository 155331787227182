import { PureQueryOptions, gql } from '@apollo/client/core'
import { DraftCustomCriterion } from '../../../model/audience'
import DRAFT_CUSTOM_CRITERION from '../../questionnaire/fragments/draftCustomCriterion'

export interface CustomAudienceCriteriaData {
  customCriteria: DraftCustomCriterion[]
}

export const GET_CUSTOM_CRITERIA = gql`
  query customCriteriaV2($projectId: ID!, $surveyId: ID!) {
    customCriteria: customCriteriaV2(
      projectId: $projectId
      surveyId: $surveyId
    ) {
      ...DraftCustomCriterion
    }
  }
  ${DRAFT_CUSTOM_CRITERION}
`

export const customCriteriaRefetchQuery = (
  projectId: string,
  surveyId: string
): PureQueryOptions => {
  return {
    query: GET_CUSTOM_CRITERIA,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId }
  }
}
