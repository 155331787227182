import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { addCustomResponseOptionCacheUpdate } from '../../apollo/cacheModify/Fieldwork/addCustomResponseOption'
import {
  CreateCustomCriterionOptionV2Mutation,
  CreateCustomCriterionOptionV2MutationVariables,
  DraftCustomAudienceCriterionOption
} from '../../data/gql-gen/questionnaire/graphql'
import { CREATE_CUSTOM_CRITERION_OPTION_V2 } from '../../data/gql/questionnaire/mutations/criterionOptionV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import AudienceContext from '../../modules/Audience/Audience.context'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useCreateCustomCriterionOptionV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { audienceState } = useContext(AudienceContext)

  const [createCustomCriterionOptionV2] = useMutation<
    CreateCustomCriterionOptionV2Mutation,
    CreateCustomCriterionOptionV2MutationVariables
  >(CREATE_CUSTOM_CRITERION_OPTION_V2, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createCustomCriterionOptionV2',
        error
      )
    },
    update: (cache, { data }) => {
      const { newAudienceQuestionLk } = audienceState
      if (data && newAudienceQuestionLk) {
        const newOption =
          data.createCustomCriterionOptionV2 as DraftCustomAudienceCriterionOption
        addCustomResponseOptionCacheUpdate(
          cache,
          newOption,
          newAudienceQuestionLk
        )
      }
    }
  })

  return createCustomCriterionOptionV2
}
