import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  text: {
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.57',
    fontFamily: theme.typography.fontFamily
  },
  sentIcon: {
    fontSize: theme.spacing(8)
  },
  subtitleContainer: {
    paddingBottom: theme.spacing(2)
  },
  sentText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    color: theme.palette.success.main
  },
  failedText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    color: theme.palette.error.main
  },
  email: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  emailError: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.error.main
  },
  emailContainer: {
    paddingBottom: theme.spacing(0.5)
  },
  infoText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  }
})

export default useStyles
