import Skeleton from '@mui/material/Skeleton'
import { useFdChatFieldwork } from '../../../../containers/Page/FdChat/FdChat.hooks'
import { getLangFromLangName } from '../../../../data/seed/languages'
import { useCountryName } from '../../../Audience/Audience.hooks'
import { AudienceSummaryContainer } from '../../../Audience/AudienceSummary'
import Accordion from '../Accordion/Accordion'
import styles from './AudienceCard.module.css'

const AudienceCard = () => {
  const { fieldwork, isCustomAudience, fieldworkLoading } = useFdChatFieldwork()
  const countryName = useCountryName()

  const audienceType = isCustomAudience ? 'Custom' : 'Focaldata'
  const audience = fieldwork?.audience[0]
  const audienceDescription =
    audience?.__typename !== 'CintAudience' && audience?.description
      ? ` (${audience.description})`
      : ''

  return (
    <div className={styles['audience-card']}>
      <h2 className={styles.title}>Audience</h2>
      <ul className={styles.list}>
        <li className={styles['list-item']}>
          <span className={styles.label}>Country:</span> {countryName}
        </li>
        <li className={styles['list-item']}>
          <span className={styles.label}>Language:</span>{' '}
          {getLangFromLangName(fieldwork?.locale.language)}
        </li>
        <li className={styles['list-item']}>
          <span className={styles.label}>Audience:</span>{' '}
          {fieldworkLoading ? (
            <Skeleton
              variant="text"
              width={100}
              sx={{ display: 'inline-block' }}
            />
          ) : (
            `${audienceType}${audienceDescription}`
          )}
        </li>
        <li>
          <Accordion
            id="qualifying-criteria-accordion"
            summaryContent={<p className={styles.label}>Qualifying criteria</p>}
            detailsContent={
              <>
                <p className={styles['audience-summary-description']}>
                  The following response options qualify respondents for your
                  project.
                </p>
                <AudienceSummaryContainer />
              </>
            }
          />
        </li>
      </ul>
    </div>
  )
}

export default AudienceCard
