import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  input: {
    width: 74,
    '& input.MuiInputBase-input': {
      textAlign: 'center',
      backgroundColor: additionalPalette.lightGrey.secondary
    }
  },
  root: {
    width: 154
  }
})

export default useStyles
