import React from 'react'
import DragAndDrop from '../../../components/DragAndDrop-react-dnd/DragAndDrop'
import { useDragAndDrop } from '../useDragAndDrop.hooks'
import useStyles from './EmptySection.styles'

interface Props {
  sectionId: string
  nextPosition: number
}

const EmptySectionSideBar: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()
  const { sectionId, nextPosition } = props
  const moveCard = useDragAndDrop()

  return (
    <DragAndDrop
      position={nextPosition}
      type="section"
      moveCard={moveCard}
      path={`${sectionId}/${nextPosition}`}
      accept={['basic']}
    >
      <div className="fd-grid fd-container fd-item fd-xs-12">
        <p className={classNames(classes.sectionEmptyList)}>
          Drag and drop questions into this section using the sidebar
        </p>
      </div>
    </DragAndDrop>
  )
}

export default EmptySectionSideBar
