import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import { useStyles } from './styles'

interface Props {
  leftChild?: JSX.Element
  middleChild?: JSX.Element
  rightChild?: JSX.Element
}

const NavigationSubheaderLayout: React.FC<Props> = (props: Props) => {
  const {
    leftChild = <p />,
    middleChild = <p />,
    rightChild = <p />
  }: Props = props

  const { classes } = useStyles()

  return (
    <Grid
      className={classes.shadow}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={4}>
        {leftChild}
      </Grid>
      <Grid container item xs={4} justifyContent="center">
        {middleChild}
      </Grid>
      <Grid item xs={4}>
        {rightChild}
      </Grid>
    </Grid>
  )
}

export default NavigationSubheaderLayout
