import { gql } from '@apollo/client/core'
import { questionnaireGql } from '../../../gql-gen/questionnaire'
import DRAFT_RESPONSE_OPTION from './draftEntryResponseOption'
import DRAFT_LOOPING_CONFIG from './draftLoopingConfig'
import MAX_DIFF_SPECIFICATION from './maxDiffSpecification'

export const QUESTION_FRAGMENT = questionnaireGql(/* GraphQL */ `
  fragment Question on Question {
    __typename
    questionId
    text
    textStyling
    createdDate
  }
`)

export const DRAFT_QUESTION_ITEM = gql`
  fragment DraftQuestionItem on DraftQuestionItem {
    questionTypeCode
    questionLk
    question {
      ...Question
    }
    questionMedia {
      ...QuestionMedia
    }
    questionScale {
      range {
        min
        max
        step
        startingPosition
      }
      labels {
        label {
          text
          language
          country
          createdDate
          sinceDate
        }
        position
      }
    }
    questionLogic {
      ...DraftLogicClauseProposition
    }
    responseOptions {
      ...DraftEntryResponseOption
    }
    maxDiffSpecification {
      ...MaxDiffSpecification
    }
    settingValues {
      code
      value
      createdDate
      sinceDate
    }
    __typename
  }
  ${DRAFT_LOOPING_CONFIG}
  ${DRAFT_RESPONSE_OPTION}
  ${MAX_DIFF_SPECIFICATION}
`
