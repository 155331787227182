import { Checkbox, TextHighlight, TextSize } from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { SettingValue } from '../../../data/model/questionnaire'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { getSettingValue } from '../../../utils/HelperFunctions'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import useStyles from './QuestionCardDefaultOptions.styles'

interface Props extends WithEntry {
  shouldShowDontKnow?: boolean
  shouldShowPeferNotToSay?: boolean
  shouldShowNoneOfThese?: boolean
  onChangeDontKnow?: (
    settingValue: SettingValue.Enabled | SettingValue.Disabled
  ) => void
  onChangeNoneOfThese?: (
    settingValue: SettingValue.Enabled | SettingValue.Disabled
  ) => void
  onChangePreferNotToSay?: (
    settingValue: SettingValue.Enabled | SettingValue.Disabled
  ) => void
}

const QuestionCardDefaultOptions: React.FC<Props> = (props: Props) => {
  const {
    shouldShowDontKnow,
    shouldShowPeferNotToSay,
    shouldShowNoneOfThese,
    onChangeDontKnow,
    onChangeNoneOfThese,
    onChangePreferNotToSay
  } = props

  const surveyId = useSurveyId()

  const { classes, cx: classNames } = useStyles()

  const textSize = TextSize.ms

  return (
    <div className={classNames('fd-grid fd-container', classes.root)}>
      <div className="fd-grid fd-item fd-xs-auto">
        <Checkbox
          ariaLabel="Don't know"
          checked={shouldShowDontKnow}
          label="Don't know"
          textSize={textSize}
          textHighlight={TextHighlight.Background}
          onChange={(event) => {
            const settingValue = getSettingValue(event.target.checked)
            if (onChangeDontKnow) {
              onChangeDontKnow(settingValue)
            }

            if (settingValue === 'enabled') {
              LogAmplitudeEvent(EventType.TickedDefaultResponseOption, {
                surveyId,
                label: "Don't know"
              })
            }
          }}
        />
      </div>
      <div className="fd-grid fd-item fd-xs-auto">
        <Checkbox
          checked={shouldShowPeferNotToSay}
          ariaLabel="Prefer not to say"
          label="Prefer not to say"
          textSize={textSize}
          textHighlight={TextHighlight.Background}
          onChange={(event) => {
            const settingValue = getSettingValue(event.target.checked)
            if (onChangePreferNotToSay) {
              onChangePreferNotToSay(settingValue)
            }

            if (settingValue === 'enabled') {
              LogAmplitudeEvent(EventType.TickedDefaultResponseOption, {
                surveyId,
                label: 'Prefer not to say'
              })
            }
          }}
        />
      </div>
      <div className="fd-grid fd-item fd-xs-auto">
        <Checkbox
          checked={shouldShowNoneOfThese}
          ariaLabel="None of these"
          label="None of these"
          textSize={textSize}
          textHighlight={TextHighlight.Background}
          onChange={(event) => {
            const settingValue = getSettingValue(event.target.checked)
            if (onChangeNoneOfThese) {
              onChangeNoneOfThese(settingValue)
            }

            if (settingValue === 'enabled') {
              LogAmplitudeEvent(EventType.TickedDefaultResponseOption, {
                surveyId,
                label: 'None of these'
              })
            }
          }}
        />
      </div>
    </div>
  )
}

export default memo(QuestionCardDefaultOptions, propsAreEqual)
