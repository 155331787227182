import { makeStyles, theme } from '@focaldata/cin-ui-components'

export const useStyles = makeStyles()({
  shadow: {
    boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.25)} -${theme.spacing(
      0.25
    )} rgba(0,0,0,0.1)`,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    minHeight: theme.spacing(6),
    zIndex: 3
  }
})
