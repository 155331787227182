import { IconButton, IconName, theme } from '@focaldata/cin-ui-components'
import { InputAdornment, OutlinedInput } from '@mui/material'
import { ChangeEvent } from 'react'

const ListInput = ({
  value,
  defaultValue,
  autoFocus,
  onChange,
  onRemove,
  placeholder,
  canRemove
}: {
  value?: string
  defaultValue?: string
  autoFocus?: boolean
  onChange: (text: string) => void
  onRemove: () => void
  placeholder?: string
  canRemove?: boolean
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value)

  return (
    <OutlinedInput
      fullWidth
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      size="small"
      sx={{
        '&.MuiOutlinedInput-root': {
          backgroundColor: '#F6F9FF',
          borderRadius: 0.5,
          fontSize: 14,
          color: '#333',
          marginBottom: 2,
          fontFamily: theme.typography.fontFamily
        },
        '&.MuiOutlinedInput-root > fieldset': {
          border: 'none'
        },
        '&.MuiOutlinedInput-root:hover > fieldset': {
          border: 'none'
        },
        '&.MuiOutlinedInput-root.Mui-disabled > fieldset': {
          border: 'none'
        },
        '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
          border: 'none'
        }
      }}
      endAdornment={
        canRemove && (
          <InputAdornment position="end">
            <IconButton
              smallHoverShadowSize
              iconName={IconName.DeleteOutline}
              onClick={onRemove}
            />
          </InputAdornment>
        )
      }
    />
  )
}

export default ListInput
