import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  infoCost: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3)
  },
  mailtoLink: {
    color: 'inherit'
  }
})

export default useStyles
