import {
  DraftCustomAudienceCriterionOption,
  DraftCustomCriterion,
  EnabledState,
  FieldworkAudience,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import { ResponseOptionType } from '../../../data/model/shared'

const ENTRY_ID = 'questionLk'

export const createEmptyCustomAudience = (
  isMulti: boolean
): FieldworkAudience => ({
  id: ENTRY_ID,
  number: Infinity,
  position: Infinity,
  contextPosition: 0,
  memberType: FieldworkAudienceType.Custom,
  isWeighted: false,
  memberItem: {
    questionLk: ENTRY_ID,
    question: {
      questionId: ENTRY_ID,
      text: ''
    },
    criterionOptions: Array(2)
      .fill(null)
      .map(
        (_, idx) =>
          ({
            responseOptionLk: `responseOptionLk${idx + 1}`,
            responseOption: {
              responseOptionId: `responseOptionLk${idx + 1}`,
              rotype: ResponseOptionType.RoString,
              value: '',
              createdDate: new Date()
            },
            position: idx,
            qualification: {
              createdDate: new Date(),
              sinceDate: new Date()
            },
            quota: {
              percent: 0,
              createdDate: new Date(),
              sinceDate: new Date(),
              __typename: 'Quota'
            },
            pinned: false,
            exclusive: false,
            createdDate: new Date(),
            sinceDate: new Date(),
            weight: {
              percent: 0,
              createdDate: new Date(),
              sinceDate: new Date()
            },
            __typename: 'DraftCustomAudienceCriterionOption'
          }) satisfies DraftCustomAudienceCriterionOption
      ),
    settingValues: [
      {
        code: MemberSettingCode.StandardAudienceChoice,
        value: isMulti
          ? SettingValue.MultipleChoice
          : SettingValue.SingleChoice,
        createdDate: '',
        sinceDate: ''
      },
      {
        code: MemberSettingCode.Qualifying,
        value: SettingValue.Enabled,
        createdDate: '',
        sinceDate: ''
      },
      {
        code: MemberSettingCode.Quotas,
        value: SettingValue.Disabled,
        createdDate: '',
        sinceDate: ''
      }
    ],
    createdDate: new Date(),
    sinceDate: new Date()
  }
})

export const createEmptyCustomCriterion = (): DraftCustomCriterion => {
  return {
    questionLk: '',
    number: Infinity,
    question: {
      questionId: '',
      text: ''
    },
    state: EnabledState.Enabled,
    createdDate: new Date(),
    sinceDate: new Date(),
    __typename: 'DraftCustomCriterion'
  }
}

export const addOrUpdateSettingValue = (
  settingValues: {
    code: string
    value: string
  }[],
  newSettingValue: { code: string; value: string }
) => {
  const hasSettingValue = settingValues.some(
    (sv) => sv.code === newSettingValue.code
  )
  if (hasSettingValue) {
    return settingValues.map((sv) =>
      sv.code === newSettingValue.code ? newSettingValue : sv
    )
  } else {
    return [...settingValues, newSettingValue]
  }
}
