import { Button, ButtonVariant, Grid, List } from '@focaldata/cin-ui-components'
import React from 'react'
import { ProjectListItem } from '../../../controls/ProjectListItem'
import { Project } from '../../../data/gql-gen/dashboard/graphql'
import { SurveyProgress } from '../../../data/model/surveyResults'
import dayjs from '../../../utils/dayjs'
import ProjectListLoading from './ProjectList.loading'
import useStyles from './ProjectList.styles'

interface Props {
  projects?: Project[]
  surveysProgress?: SurveyProgress[]
  onClickDuplicate: (project: Project) => void
  onClickDelete: (project: Project) => void
  onClickPause: (project: Project) => void
  onClickUnpause: (project: Project) => void
  onLoadMore?: () => void
  loadingMore?: boolean
  loadingProgress?: boolean
  showLoadMore?: boolean
}

const ProjectList: React.FC<Props> = (props: Props) => {
  const {
    projects,
    surveysProgress,
    loadingMore = false,
    loadingProgress = false,
    onClickDuplicate,
    onClickDelete,
    onClickPause,
    onClickUnpause,
    onLoadMore,
    showLoadMore
  }: Props = props
  const { classes } = useStyles()

  return (
    <>
      <List>
        {projects &&
          projects.map((project, index) => (
            <ProjectListItem
              key={project.projectId}
              project={{
                projectId: project.projectId,
                status: project.status,
                projectType: project.projectType,
                projectMetadata: project.projectMetadata,
                name: project.name,
                completionDate:
                  (project.surveys[0].fieldworkDates.endDate &&
                    dayjs(project.surveys[0].fieldworkDates.endDate)
                      .format('D MMM YY')
                      .toUpperCase()) ||
                  undefined,
                lastUpdate: dayjs
                  .max(
                    dayjs(project.sinceDate),
                    dayjs(project.surveys[0].sinceDate),
                    dayjs(project.solution.sinceDate)
                  )
                  .format('D MMM YY')
                  .toUpperCase(),
                creator: project.creator,
                surveyProgressList: surveysProgress,
                loadingProgress,
                surveys: project.surveys
              }}
              onDuplicateProject={() => {
                onClickDuplicate(project)
              }}
              onDeleteProject={() => {
                onClickDelete(project)
              }}
              onPauseProject={() => {
                onClickPause(project)
              }}
              onUnpauseProject={() => {
                onClickUnpause(project)
              }}
              dataCy={`project-list-item-${index}`}
            />
          ))}
      </List>
      {loadingMore && <ProjectListLoading />}
      {showLoadMore && (
        <Grid
          className={classes.buttonContainer}
          container
          justifyContent="center"
        >
          <Button variant={ButtonVariant.PrimaryOutlined} onClick={onLoadMore}>
            Load more
          </Button>
        </Grid>
      )}
    </>
  )
}

export default ProjectList
