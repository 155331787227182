import {
  Dialog,
  DialogMaxWidth,
  Grid,
  LinkButton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import partition from 'lodash/partition'
import React, { useState } from 'react'
import { useAppSelector } from '../../../../App.store'
import {
  DraftMaskingRule,
  ForkTag,
  MaskingRuleClause,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import { DraftForkItem, EntryType } from '../../../../data/model/questionnaire'
import { getIdsFromSelectedQuestionLk } from '../../../../utils/HelperFunctions'
import { selectResponseOptionsByQuestion } from '../../Questionnaire.slice'
import { MaskingRuleForDialog } from '../MaskingButton'
import { MaskingRuleSection } from '../MaskingRuleSection'
import {
  convertMaskingRulesForBackend,
  convertMaskingRulesForDialog,
  convertMaskingRulesToForkTags,
  getOptions
} from '../MaskingRuleSection/MaskingRuleSection.utils'
import { Question } from './MaskingDialog.model'
import useStyles from './MaskingDialog.styles'
import { EMPTY_QUESTION_ID } from './constants'

interface Props {
  questionPosition: number
  responseOptionText: string | undefined
  maskingRules: DraftMaskingRule[]
  rulesFromForks: MaskingRuleForDialog[]
  questions: Question[] | undefined
  allowForks?: boolean
  onSave: (
    newRules: MaskingRuleInput[],
    displayLogicForks: ForkTag[]
  ) => Promise<void>
  onCloseDialog: () => void
}

const MaskingDialog: React.FC<Props> = ({
  questionPosition,
  responseOptionText,
  maskingRules,
  rulesFromForks,
  questions,
  allowForks,
  onSave,
  onCloseDialog
}: Props) => {
  const emptyMaskingRuleForDialog: MaskingRuleForDialog = {
    questionLk: EMPTY_QUESTION_ID,
    clause: MaskingRuleClause.And,
    sourceResponseOptions: [],
    negated: false
  }

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const [saving, setSaving] = useState<boolean>(false)

  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const [rules, setRules] = useState<MaskingRuleForDialog[]>(() => {
    const newRules = [
      ...rulesFromForks,
      ...convertMaskingRulesForDialog(maskingRules)
    ]

    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return newRules?.length ? newRules : [emptyMaskingRuleForDialog]
  })

  const handleSave: () => Promise<void> = async () => {
    setSaving(true)
    const [forks, maskingRulesForDialog] = partition(rules, 'isSourceFork')
    const forkTags = convertMaskingRulesToForkTags(forks)
    const maskingRules = convertMaskingRulesForBackend(maskingRulesForDialog)

    await onSave(maskingRules, forkTags)
    setSaving(false)
  }

  const handleAddNewEmptyRule: () => void = () => {
    // this sets the clause to whatever the second rule had on it for everything after the
    // second rule
    const clause =
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (rules.length > 1 && rules[1].clause) || MaskingRuleClause.And
    setRules([...rules, { ...emptyMaskingRuleForDialog, clause }])
  }

  const getForkResponseOptions = (forkId?: string) => {
    const item = questions?.find(
      (q) =>
        q.entryType === EntryType.ForkEntryType &&
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (q.entryItem as DraftForkItem)?.fork?.forkId === forkId
    )?.entryItem as DraftForkItem

    return (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      item?.fork?.branches?.map((branch) => ({
        id: branch.branchNumber.toString(),
        value: branch.label
      }))
    )
  }

  const isFork = (questionLk: string) =>
    questions?.some(
      (q) =>
        q.entryType === EntryType.ForkEntryType &&
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (q.entryItem as DraftForkItem)?.fork?.forkId === questionLk
    )

  const getQuestionResponseOptions = (rule: MaskingRuleForDialog) => {
    const { questionLk, matrixTitleLk } = rule
    const responseOptions =
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-condition
      responseOptionsByQuestion[matrixTitleLk!] || // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      responseOptionsByQuestion[questionLk!]
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return responseOptions?.map((option) => ({
      id: option.responseOptionLk,
      value: option.responseOption?.value ?? ''
    }))
  }

  const getResponseOptions = (rule: MaskingRuleForDialog) => {
    if (rule.isSourceFork) {
      return getForkResponseOptions(rule.questionLk)
    }
    return getQuestionResponseOptions(rule)
  }

  const handleDeleteMaskingRule = (index: number) => {
    const newMaskingRules = [...rules]
    newMaskingRules.splice(index, 1)
    setRules(newMaskingRules)
  }

  const handleMaskingRuleChanged = (
    newRule: MaskingRuleForDialog,
    index: number
  ) => {
    const newRules = [...rules]
    newRules.splice(index, 1, newRule)
    setRules(newRules)
  }

  const handleClauseChanged = (clause: MaskingRuleClause) => {
    const newRules = rules.map((rule) => ({ ...rule, clause }))
    setRules(newRules)
  }

  const handleResponseOptionChanged = (
    rule: MaskingRuleForDialog,
    responseOptionLk: string,
    checked: boolean,
    index: number
  ) => {
    const sourceResponseOptions = checked
      ? [...rule.sourceResponseOptions, responseOptionLk]
      : [...rule.sourceResponseOptions.filter((r) => r !== responseOptionLk)]
    const newRule = { ...rule, sourceResponseOptions }
    handleMaskingRuleChanged(newRule, index)
  }

  const handleQuestionOptionChanged = (
    newQuestionLk: string,
    index: number
  ) => {
    const [questionLk, matrixTitleLk] =
      getIdsFromSelectedQuestionLk(newQuestionLk)
    const isSourceFork = isFork(questionLk)
    const newRule: MaskingRuleForDialog = {
      questionLk,
      matrixTitleLk,
      clause: rules.length === 0 ? MaskingRuleClause.And : rules[0].clause,
      sourceResponseOptions: [],
      negated: false,
      isSourceFork
    }
    handleMaskingRuleChanged(newRule, index)
  }

  const shouldShowForks = (index: number) => {
    return index === 0 && allowForks
  }

  const hasMutualExclusiveRules = (rules: MaskingRuleForDialog[]) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return rules.some((currentRule, index) => {
      rules
        .slice(index + 1) // only check rules after the current one
        .some((rule) => {
          const isRuleDuplicate =
            rule.questionLk === currentRule.questionLk &&
            rule.sourceResponseOptions.some((s) =>
              currentRule.sourceResponseOptions.includes(s)
            )

          return isRuleDuplicate && rule.negated !== currentRule.negated
        })
    })
  }

  const isLogicInvalid = hasMutualExclusiveRules(rules)

  const validationError = isLogicInvalid
    ? 'You selected 2 or more mutually exclusive rules'
    : ''

  const saveButtonDisabled =
    rules.some((rule) => {
      return rule.sourceResponseOptions.length === 0
    }) || isLogicInvalid

  return (
    <Dialog
      title="Display logic"
      primaryButtonText="Save"
      open
      fullWidth
      primaryButtonLoading={saving}
      primaryButtonDisabled={saveButtonDisabled}
      maxWidth={DialogMaxWidth.Md}
      primaryButtonClick={handleSave}
      onClose={onCloseDialog}
    >
      <Grid>
        <Text
          size={TextSize.m}
          highlight={TextHighlight.Background}
          className={classes.subtitleContainer}
        >
          <>
            Only show<strong>{` ${responseOptionText} `}</strong>if the
            following conditions are met:
          </>
        </Text>
        {rules.map((rule, index) => (
          <MaskingRuleSection
            key={rule.questionLk}
            index={index}
            maskingRule={rule}
            questionOptions={getOptions(
              questions,
              questionPosition,
              shouldShowForks(index)
            )}
            responseOptions={getResponseOptions(rule)}
            forceAndClause={rules.some((r) => r.isSourceFork)}
            onDeleteMaskingRule={() => handleDeleteMaskingRule(index)}
            onChangeNegation={(newRule: MaskingRuleForDialog) =>
              handleMaskingRuleChanged(newRule, index)
            }
            onQuestionOptionChanged={(newQuestionId) =>
              handleQuestionOptionChanged(newQuestionId, index)
            }
            onResponseOptionChanged={(responseOptionLk, checked) =>
              handleResponseOptionChanged(
                rule,
                responseOptionLk,
                checked,
                index
              )
            }
            onClauseChanged={handleClauseChanged}
          />
        ))}
        <div className="fd-grid fd-container fd-item fd-justify-content-start">
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.weightRegular,
              classes.errorText
            )}
          >
            {validationError}
          </p>
        </div>
        <Grid className={classes.linkButton}>
          <LinkButton
            highlight={TextHighlight.Emphasis}
            size={TextSize.m}
            weight={TextWeight.SemiBold}
            noPadding
            onClick={handleAddNewEmptyRule}
          >
            Add condition
          </LinkButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default MaskingDialog
