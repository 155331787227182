export interface Path {
  position: number
  path: string
}

export const getDropPosition = (
  dragPosition: number,
  dropPosition: number,
  showTopBorder: boolean,
  showBottomBorder: boolean,
  childrenCount: number,
  dragParentPosition?: number,
  itemId?: string
) => {
  if (itemId === undefined) {
    if (dragPosition < dropPosition) return dropPosition - 1

    return dropPosition
  }

  if (showTopBorder && dragPosition < dropPosition) {
    return dropPosition - 1
  }

  if (showBottomBorder && dragPosition > dropPosition) {
    if (dragParentPosition === dropPosition) {
      return dropPosition + childrenCount
    }

    return dropPosition + childrenCount + 1
  }

  if (showBottomBorder && dragPosition < dropPosition) {
    return dropPosition + childrenCount
  }

  return dropPosition
}
