import { Grid, InfoIcon, InfoIconColor } from '@focaldata/cin-ui-components'
import { TextHighlight, TextWeight } from '../../../constants/text'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  MemberSettingCode
} from '../../../data/model/audience'
import { proportionToPercent } from '../../../utils/HelperFunctions'
import { getIsEnabledInSettings } from '../utils'
import useStyles from './CustomAudienceSummaryItem.styles'

interface Props {
  audience: DraftCustomAudience
  hasError?: boolean
  showQuotas?: boolean
}

const CustomAudienceSummaryItem = ({
  audience,
  hasError,
  showQuotas
}: Props) => {
  const { classes } = useStyles()
  const qualifyingOptions: DraftCustomAudienceCriterionOption[] =
    audience.criterionOptions.filter((option) => option.qualification)
  const areQuotasOn = getIsEnabledInSettings(
    audience.settingValues,
    MemberSettingCode.Quotas
  )

  const lastIndex: number = qualifyingOptions.length - 1

  return (
    <Grid className={classes.item}>
      <Grid container direction="row" alignItems="center">
        <p style={{ fontWeight: TextWeight.SemiBold }}>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {audience.question && audience.question.text}
        </p>
        {hasError && (
          <InfoIcon
            iconColor={InfoIconColor.Error}
            text="There are errors in your targeting and sampling. You’ll need to resolve them before launch."
          />
        )}
      </Grid>
      <Grid container direction="row">
        {qualifyingOptions.map(
          (option: DraftCustomAudienceCriterionOption, index: number) => (
            <div
              className={classes.optionContainer}
              key={option.responseOptionLk}
            >
              <p
                style={{
                  fontWeight: TextWeight.SemiBold,
                  color: TextHighlight.Background
                }}
              >
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {option.responseOption && option.responseOption?.value}
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {showQuotas && areQuotasOn && option.quota && (
                  <span style={{ fontWeight: 400 }}>
                    {`\xa0(${proportionToPercent(option.quota.percent)}%)`}
                  </span>
                )}
                {index < lastIndex && ',\xa0'}
              </p>
            </div>
          )
        )}
      </Grid>
    </Grid>
  )
}

export default CustomAudienceSummaryItem
