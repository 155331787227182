import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: `-${theme.spacing(3)}`,
    width: 334,
    flexGrow: 1
  },
  close: {
    marginRight: `-${theme.spacing(1.5)}`
  },
  refresh: {
    marginLeft: `-${theme.spacing(1.5)}`
  },
  previewContainer: {
    margin: theme.spacing(1, 0, 0, 0),
    border: `2px solid ${additionalPalette.lightGrey.main}`,
    borderRadius: theme.spacing(0.25),
    backgroundColor: additionalPalette.lightBlue.main,
    height: 200,
    flexGrow: 1,
    overflow: 'scroll'
  }
}))

export default useStyles
