import { Paragraph, TextRun } from 'docx'
import {
  DraftForkItem,
  DraftQuestionnaireEntry
} from '../../data/model/questionnaire'
import { proportionToPercent } from '../HelperFunctions'

export const createEntryContentFork = (
  entry: DraftQuestionnaireEntry
): Paragraph[] => {
  const entryItem = entry.entryItem as DraftForkItem
  return entryItem.fork.branches.map(({ label, percentage }) => {
    return new Paragraph({
      bullet: {
        level: 0
      },
      children: [
        new TextRun(`${label}: `),
        new TextRun(`${proportionToPercent(percentage)}%`)
      ]
    })
  })
}
