import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import {
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { SurveyParams } from '../../../data/model/surveyParams'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSetSectionSettingValue } from '../../../hooks/questionnaire/useSetSectionSettingValue.mutations'
import RandomisationActionMenuControl from './RandomisationActionMenu.control'

interface Props {
  sectionEntryNumber: number
}

const RandomizationActionMenu = ({ sectionEntryNumber }: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()

  const sectionEntry = draftQuestionnaireEntries.find(
    (entry) => entry.number === sectionEntryNumber
  )?.entryItem as DraftSectionItem

  const setSectionSettingValue = useSetSectionSettingValue(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    sectionEntry?.sectionId || ''
  )

  const removeSectionQuestionsRandomisation = () => {
    setSectionSettingValue(
      QuestionSettingCode.SectionRandomiseQuestions,
      SettingValue.Disabled
    ).then(() => {
      LogAmplitudeEvent(EventType.SectionQuestionsRandomisation, {
        surveyId,
        sectionId: sectionEntry.sectionId,
        enabled: false
      })
    })
  }

  return (
    <RandomisationActionMenuControl
      onRemoveQuestionOrderRandomised={removeSectionQuestionsRandomisation}
    />
  )
}

export default RandomizationActionMenu
