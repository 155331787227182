import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Signup as SignupControl } from '../../../components/Auth'
import { DynamicError } from '../../../components/DynamicError'
import { LogSignedUpEvent } from '../../../data/amplitude'
import {
  AutoSignup,
  GetInviteDetailsRequest,
  RequestAccess,
  RequestInviteSignup,
  VerifyAutoSignup
} from '../../../data/rest/accountUser'
import { useLoginRedirect } from '../../../hooks/useLoginRedirect'
import UserSession from '../../../utils/UserSession'
import { AccessToken, setAccountId } from '../../../utils/authUtils'
import { useURLSearchParams } from '../paramHelper'

const Signup: React.FC = () => {
  const navigate = useNavigate()
  const query = useURLSearchParams()
  const inviteId = query.get('iid')
  const autoSignupId = query.get('auto-signup')
  const [email, setEmail] = useState<string | undefined>()
  const [organisation, setOrganisation] = useState<string | undefined>()
  const [displayError, setDisplayError] = useState<boolean | undefined>()
  const [dynamicErrorBody, setDynamicErrorBody] = useState('')
  const { loginRedirect } = useLoginRedirect()

  useEffect(() => {
    async function getInviteDetails() {
      if (inviteId) {
        try {
          const details = await GetInviteDetailsRequest(inviteId)
          setOrganisation(details.data.accountName)
          setEmail(details.data.inviteeEmailAddress)
        } catch (ex) {
          setDynamicErrorBody(
            'Sorry, this link is either invalid or expired. Please contact your team mate for a new invite.'
          )
          setDisplayError(true)
        }
      }
    }
    getInviteDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId])

  useEffect(() => {
    async function verifyAutoSignup() {
      if (autoSignupId) {
        try {
          await VerifyAutoSignup(autoSignupId)
        } catch (ex) {
          setDynamicErrorBody(
            'Sorry, this link is invalid. Please email support@focaldata.com for a new link.'
          )
          setDisplayError(true)
        }
      }
    }
    verifyAutoSignup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSignupId])

  const handleErrorClose = () => {
    setDisplayError(false)
  }

  const handleClickPrivacyPolicy: () => void = () => {
    const privacyPolicyUrl =
      'https://app.termly.io/document/privacy-policy/967cdedb-6db2-44da-aff1-ac87a48fd8e7'
    window.open(privacyPolicyUrl, '_blank')
  }
  const handleClickTerms: () => void = () => {
    const domain = process.env.PUBLIC_URL
    const fdSurveyUrl = `${domain}/terms`
    window.open(fdSurveyUrl, '_blank')
  }
  const handleGoToLogin: () => void = () => {
    navigate('/login')
  }
  const onSignup: (
    firstName: string,
    lastName: string,
    email: string,
    organisation: string,
    password: string | undefined
  ) => Promise<boolean | Error> = async (
    firstName,
    lastName,
    email,
    organisation,
    password
  ) => {
    if (autoSignupId && password) {
      try {
        const result = await AutoSignup(
          firstName,
          lastName,
          email,
          organisation,
          password,
          autoSignupId
        )
        if (result.status === 200) {
          UserSession.setEmail(result.data.emailAddress)
          AccessToken.set(result.data.accessToken)
          const gotAccountId: boolean = await setAccountId(
            result.data.emailAddress,
            result.data.accessToken
          )
          loginRedirect({ firstLogin: true })
          return gotAccountId
        }
      } catch (err: any) {
        return err
      }
    } else {
      try {
        const response = await RequestAccess(
          firstName,
          lastName,
          email,
          organisation
        )
        if (response.status === 200) {
          navigate({
            pathname: `/success-message/${email}/${response.data.requestId}`
          })
        }
        LogSignedUpEvent(email, organisation)
      } catch (err) {
        return err as Error
      }
    }

    return false
  }

  const onInviteSignup: (
    firstName: string,
    lastName: string,
    email: string,
    organisation: string,
    password: string | undefined
  ) => Promise<boolean | Error> = async (
    firstName,
    lastName,
    email,
    organisation,
    password
  ) => {
    try {
      if (inviteId && password) {
        const response = await RequestInviteSignup(
          inviteId,
          firstName,
          lastName,
          password
        )
        UserSession.setEmail(response.data.emailAddress)
        AccessToken.set(response.data.accessToken)
        await setAccountId(
          response.data.emailAddress,
          response.data.accessToken
        )
        LogSignedUpEvent(response.data.emailAddress, '', {
          inviteId
        })
        loginRedirect()
        return Promise.resolve(true)
      }
    } catch (err) {
      return Promise.resolve<Error>(err as Error)
    }
    return Promise.resolve(false)
  }

  return (
    <>
      {displayError && (
        <DynamicError
          title=""
          body={dynamicErrorBody}
          onClose={handleErrorClose}
        />
      )}
      <SignupControl
        predefinedEmail={email}
        predefinedOrganisation={organisation}
        showPasswordInput={!!autoSignupId || !!inviteId}
        disableEmailInput={!!inviteId}
        disableOrganisationInput={!!inviteId}
        onClickPrivacyPolicy={handleClickPrivacyPolicy}
        onClickTerms={handleClickTerms}
        onGoToLogin={handleGoToLogin}
        onSignup={inviteId ? onInviteSignup : onSignup}
      />
    </>
  )
}

export default Signup
