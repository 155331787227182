import { Theme, makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme: Theme) => {
  const { palette, typography } = theme
  const sectionTitle = {
    fontFamily: typography.fontFamily,
    fontSize: typography.body1.fontSize,
    fontWeight: typography.fontWeightRegular as any,
    color: palette.text.secondary,
    margin: 0,
    lineHeight: 1.5
  }

  const infoContainer = {
    width: '100%',
    padding: theme.spacing(1.5, 0, 1.5, 2.25)
  }

  return {
    root: {
      padding: theme.spacing(8)
    },
    text: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(2)
    },
    paper: {
      backgroundColor: 'initial',
      marginBottom: theme.spacing(4)
    },
    sectionTitle,
    sectionEmptyList: {
      ...sectionTitle,
      ...infoContainer,
      padding: theme.spacing(1.5, 2.25)
    },
    emptyIcon: {
      marginBottom: theme.spacing(1.5)
    }
  }
})

export default useStyles
