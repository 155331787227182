/**
 * Converts URLSearchParams to an object.
 * @param entries - The URLSearchParams to convert.
 * @returns The converted object.
 */
export const paramsToObject = (
  entries: URLSearchParams
): Record<string, string> => {
  const result: Record<string, string> = {}
  for (const entry of entries) {
    result[entry[0]] = entry[1]
  }
  return result
}
