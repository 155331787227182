import { RawDraftContentState } from '@focaldata/cin-ui-components'
import { nanoid } from 'nanoid'
import { QuestionDialogItem } from '../../../components/QuestionsDialog'
import {
  DraftCustomAudience,
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionTypeCode
} from '../../../data/model/questionnaire'
import { checkIfSingleChoice } from '../../../utils/questionnaireUtils'

export const isSingleSelectItem = (
  item: DraftQuestionnaireEntry | FieldworkAudience
) => {
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('entryType')) {
    const questionnaireItem = item as DraftQuestionnaireEntry
    return (
      questionnaireItem.entryType === EntryType.QuestionEntryType &&
      checkIfSingleChoice(questionnaireItem.entryItem as DraftQuestionItem)
    )
  }

  const audienceItem = item as FieldworkAudience
  return (
    audienceItem.memberItem.settingValues.find(
      ({ code }) =>
        code === MemberSettingCode.CustomAudienceChoice ||
        code === MemberSettingCode.StandardAudienceChoice
    )?.value === SettingValue.SingleChoice
  )
}

export const isFreeTextItem = (entry: DraftQuestionnaireEntry): boolean => {
  const entryItem = entry.entryItem as DraftQuestionItem
  return entryItem.questionTypeCode === QuestionTypeCode.FreeText
}

export const getQuestionsDialogItem = (
  entry: DraftQuestionnaireEntry | FieldworkAudience,
  index: number
): QuestionDialogItem => {
  let item
  let entryType
  let text

  // eslint-disable-next-line no-prototype-builtins
  if (entry.hasOwnProperty('entryType')) {
    item = entry as DraftQuestionnaireEntry
    entryType = item.entryType
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    text = (item.entryItem as DraftQuestionItem).question?.text ?? ''
  } else {
    item = entry as FieldworkAudience
    entryType = item.memberType
    switch (entryType) {
      case FieldworkAudienceType.Custom:
        text = (item.memberItem as DraftCustomAudience).question.text
        break
      case FieldworkAudienceType.Standard:
        text = (item.memberItem as DraftStandardAudience).criterion.question
          .text
        break
      default:
        text = ''
    }
  }
  return {
    id: `${index}`,
    text,
    number: entry.number,
    entryType,
    position: entry.position,
    contextPosition: entry.contextPosition
  }
}

export const createLoopFieldDialogItem = (
  { number, entryType, position, contextPosition }: DraftQuestionnaireEntry,
  sourceEntryContextPosition: number
): QuestionDialogItem => {
  return {
    id: nanoid(),
    text: `Loop field based on Q${sourceEntryContextPosition + 1}`,
    number,
    entryType,
    position,
    contextPosition
  }
}

export const getPipingMarker = (questionDialogItem: QuestionDialogItem) => {
  const itemNumberPrefix = questionDialogItem.contextPosition + 1
  let itemLetterPrefix: 'A' | 'Q' | 'S' = 'Q'
  switch (questionDialogItem.entryType) {
    case FieldworkAudienceType.Standard:
    case FieldworkAudienceType.Custom:
      itemLetterPrefix = 'A'
      break
    case EntryType.SectionEntryType:
      itemLetterPrefix = 'S'
      break
    default:
      break
  }
  return `{${itemLetterPrefix}${itemNumberPrefix}}` as const
}

const getRawEmptyTitle = (): RawDraftContentState => {
  return {
    blocks: [
      {
        key: 'empty_block_key',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  }
}

export const getTitleStyling = (
  marker: string,
  titleStyling?: string | null
): string => {
  let rawTitle = getRawEmptyTitle()

  rawTitle = titleStyling ? JSON.parse(titleStyling) : rawTitle
  const lastBlock = rawTitle.blocks[rawTitle.blocks.length - 1]
  lastBlock.text = `${lastBlock.text}${marker}`

  return JSON.stringify(rawTitle)
}
