import {
  Grid,
  Paper,
  PaperGroupItem,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'

import useStyles from './AudiencePageLayout.styles'

interface Props {
  countryPicker: JSX.Element
  audienceSummary: JSX.Element
  customiseAudience: JSX.Element
  audiencePresetPicker: JSX.Element
  showAudienceDetails?: boolean
}

const AudiencePageLayout: React.FC<Props> = (props: Props) => {
  const {
    countryPicker,
    audienceSummary,
    customiseAudience,
    audiencePresetPicker,
    showAudienceDetails = false
  } = props

  const { classes } = useStyles()

  return (
    <Grid container className={classes.selectAudienceContainer}>
      <Grid
        container
        item
        xs={5}
        direction="column"
        className={classes.leftContainer}
      >
        <Paper>
          <PaperGroupItem>
            <Grid item container direction="column" xs={12}>
              <Grid
                container
                item
                direction="column"
                className={classes.countryPicker}
              >
                {countryPicker}
              </Grid>
              <Grid
                container
                item
                direction="column"
                className={classes.presetAudiencePicker}
              >
                {audiencePresetPicker}
              </Grid>
            </Grid>
          </PaperGroupItem>
        </Paper>
      </Grid>
      <Grid
        container
        item
        xs={7}
        direction="column"
        className={classes.rightContainer}
      >
        <Paper>
          {showAudienceDetails && (
            <PaperGroupItem>
              <Grid direction="column" container>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                    Your sample
                  </Text>
                  <div className={classes.customiseAudienceContainer}>
                    {customiseAudience}
                  </div>
                </Grid>
                <Divider className={classes.divider} />
                {audienceSummary}
              </Grid>
            </PaperGroupItem>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AudiencePageLayout
