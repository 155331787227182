import Grid from '@mui/material/Grid/Grid'
import React from 'react'

import useStyles from './Subheader.styles'

interface Props {
  leftChild?: JSX.Element
  rightChild?: JSX.Element
  shadow?: boolean
  topBottomPadding?: boolean
  leftRightPadding?: boolean
}

const Subheader: React.FC<Props> = (props: Props) => {
  const {
    leftChild,
    rightChild,
    topBottomPadding = false,
    leftRightPadding = true,
    shadow = false
  }: Props = props

  const { classes, cx: classNames } = useStyles({
    styleProps: {
      topBottomPadding: topBottomPadding ? 1 : 0,
      leftRightPadding: leftRightPadding ? 8 : 4
    }
  })

  const className = shadow
    ? classNames(classes.subheader, classes.shadow)
    : classes.subheader

  return (
    <Grid
      className={className}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      {leftChild}
      {rightChild}
    </Grid>
  )
}

export default Subheader
