import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  MutationUpdaterFunction,
  OperationVariables,
  isReference
} from '@apollo/client'
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions'
import partition from 'lodash/partition'
import { useCallback } from 'react'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  EntryType,
  UpdateQuestionnaireEntryV2Mutation
} from '../../data/gql-gen/questionnaire/graphql'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import { useUpdateQuestionnaireEntry } from './useUpdateQuestionnaireEntry'

const useSetQuestionnaireSetting = (
  entry: DraftQuestionnaireEntry | null | undefined
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(
    entry?.id ?? '',
    {
      onCompleted: () => {
        if (entry) {
          questionBeingEditedId(entry.id)
        }
      },
      onError: (error: ApolloError) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'setQuestionnaireSettingValue',
          error
        )
      }
    }
  )

  const handleSettingChange = useCallback(
    async (
      settings: {
        settingCode: QuestionSettingCode
        settingValue: SettingValue | string
      }[],
      fetchPolicy: MutationFetchPolicy = 'network-only'
    ): Promise<void> => {
      if (!surveyId) {
        throw new Error('Cannot change settings as missing questionnaire ID')
      }

      if (!entry) {
        return
      }

      if (!('settingValues' in entry.entryItem)) {
        throw new Error('Cannot change settings as missing settingValues')
      }

      const refetchQueries =
        fetchPolicy === 'no-cache'
          ? undefined
          : [draftQuestionnaireRefetchQuery(projectId, surveyId)]
      const { settingValues } = entry.entryItem
      const [existingSettingsToUpdate, newSettings] = partition(
        settings,
        (setting) => settingValues.some((s) => s.code === setting.settingCode)
      )

      await updateQuestionnaireEntry(
        {
          settingValues: settingValues
            .map((settingValue) => {
              const foundSettingToUpdate = existingSettingsToUpdate.find(
                (s) => s.settingCode === settingValue.code
              )
              return {
                questionSettingCode: settingValue.code,
                settingValue: foundSettingToUpdate
                  ? foundSettingToUpdate.settingValue
                  : settingValue.value
              }
            })
            .concat(
              newSettings.map((s) => ({
                questionSettingCode: s.settingCode,
                settingValue: s.settingValue
              }))
            )
        },
        {
          refetchQueries,
          onCompleted: () => {
            questionBeingEditedId(entry.id)
          },
          update: ((cache) => {
            if (
              (entry.entryType === EntryType.QuestionEntryType ||
                entry.entryType === EntryType.MatrixEntryType) &&
              settings.some(
                (s) =>
                  (s.settingCode === QuestionSettingCode.BasicChoice ||
                    s.settingCode === QuestionSettingCode.MatrixChoice) &&
                  s.settingValue === SettingValue.SingleChoice
              )
            ) {
              const id =
                entry.entryType === EntryType.MatrixEntryType
                  ? `DraftMatrixItem:{"matrixTitleLk":"${entry.entryItem.matrixTitleLk}"}`
                  : `DraftQuestionItem:{"questionLk":"${entry.entryItem.questionLk}"}`

              cache.modify<DraftMatrixItem | DraftQuestionItem>({
                id,
                fields: {
                  settingValues: (settingValues) =>
                    settingValues.map((questionSetting) => {
                      if (isReference(questionSetting)) {
                        throw new Error('Unexpected reference found')
                      }

                      if (
                        questionSetting.code ===
                        QuestionSettingCode.AutoAnswerAll
                      ) {
                        return {
                          ...questionSetting,
                          value: SettingValue.Disabled
                        }
                      }

                      return questionSetting
                    })
                }
              })

              for (const responseOption of entry.entryItem.responseOptions) {
                cache.modify({
                  id: `DraftEntryResponseOption:{"responseOptionLk":"${responseOption.responseOptionLk}"}`,
                  fields: {
                    exclusive: () => false
                  }
                })
              }
            }
          }) as MutationUpdaterFunction<
            UpdateQuestionnaireEntryV2Mutation,
            OperationVariables,
            DefaultContext,
            ApolloCache<unknown>
          >
        }
      )
    },
    [surveyId, projectId, entry, updateQuestionnaireEntry]
  )

  return handleSettingChange
}

export default useSetQuestionnaireSetting
