export const combinedQuestions = [
  {
    id: '1',
    position: 1,
    question: 'How financially-savvy are you?'
  },
  {
    id: '2',
    position: 2,
    question: 'How much do you spend on groceries?'
  },
  {
    id: '3',
    position: 3,
    question: 'What is your household annual income?'
  }
]

export const audienceQuestions = [
  {
    id: '1',
    position: 1,
    question: 'How financially-savvy are you?',
    market: 'Spain'
  },
  {
    id: '2',
    position: 2,
    question: 'How much do you spend on groceries?',
    market: 'France'
  },
  {
    id: '3',
    position: 3,
    question: 'What is your household annual income?',
    market: 'Germany'
  }
]
