import { gql } from '@apollo/client'
import { Survey } from '../../../../modules/Project/Project.model'

export interface UpdateSurveyOrderData {
  updateSurvey: Survey
}

export const UPDATE_SURVEY_ORDER = gql`
  mutation updateSurveyOrder($surveyId: ID!, $orderStatus: SurveyOrderStatus!) {
    updateSurveyOrder(surveyId: $surveyId, orderStatus: $orderStatus)
  }
`
