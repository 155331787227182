import {
  Grid,
  Skeleton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import usePollIncidenceRate from '../../../hooks/usePollIncidenceRate'
import {
  checkIsConfirmationPage,
  checkIsQuestionnairePage,
  getDaysSuffix
} from '../../../utils/HelperFunctions'
import AudienceErrorPanel from './AudienceErrorPanel'
import QuestionnaireErrorPanel from './QuestionnaireErrorPanel'
import useStyles from './SurveyStats.styles'

const getEstimatedDeliveryTimeText = (
  estimatedDeliveryTime?: number | null
) => {
  return `${estimatedDeliveryTime !== null ? estimatedDeliveryTime : '14+'} ${
    estimatedDeliveryTime !== null && estimatedDeliveryTime !== undefined
      ? getDaysSuffix(estimatedDeliveryTime)
      : 'days'
  }`
}
export const SurveyErrorPanel = () => {
  const { classes } = useStyles()

  const { hasUnclearedValidationErrors } = useQuestionnaireValidation()
  const { invalidQuestionnaire } = hasUnclearedValidationErrors()

  const { draftAudience } = useGetDraftAudience()

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: draftAudience?.validationErrors
  })

  const { getFeasibility } = usePollIncidenceRate()
  const { isFieldworkFeasible, estimatedDeliveryTime, isDonePolling } =
    getFeasibility()

  const loading = !isDonePolling
  const showInvalidQuestionnairePanel =
    invalidQuestionnaire && checkIsQuestionnairePage()
  const showAudienceErrorInfoPanel =
    isAudienceInvalid && !checkIsConfirmationPage()
  const validInfoPanel =
    !showAudienceErrorInfoPanel &&
    !showInvalidQuestionnairePanel &&
    isFieldworkFeasible

  let errorPanelToShow = null

  if (!loading && !validInfoPanel) {
    const getErrorText = (): string => {
      if (showInvalidQuestionnairePanel) {
        return 'INVALID QUESTIONNAIRE'
      }
      if (showAudienceErrorInfoPanel) {
        return 'INVALID AUDIENCE'
      }
      if (!isFieldworkFeasible) {
        return 'INFEASIBLE AUDIENCE'
      }

      return ''
    }
    const errorText = getErrorText()

    // audience error panel will show on audience, questionnaire and confirmation pages
    errorPanelToShow = (
      <AudienceErrorPanel
        text={errorText}
        invalidAudience={isAudienceInvalid}
        isFeasible={isFieldworkFeasible}
        estimatedDeliveryInDays={estimatedDeliveryTime}
      />
    )

    // questionnaire error panel will take precedence over audience error panel on questionnaire and confirmation pages
    if (showInvalidQuestionnairePanel) {
      errorPanelToShow = <QuestionnaireErrorPanel text={errorText} />
    }
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.rightChild}
    >
      <Grid item xs="auto">
        {loading && <Skeleton width={50} />}
        {errorPanelToShow ||
          (!checkIsConfirmationPage() && (
            <Text
              data-testid="estimated-delivery"
              size={TextSize.ms}
              weight={TextWeight.SemiBold}
              highlight={TextHighlight.Standard}
            >
              {getEstimatedDeliveryTimeText(estimatedDeliveryTime)}
            </Text>
          ))}
      </Grid>
    </Grid>
  )
}
