import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

interface StyleProps {
  leftRightPadding: number
  topBottomPadding: number
}

const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    subheader: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1300, // The MUI Drawer has a z-index of 1200
      padding: theme.spacing(props.topBottomPadding, props.leftRightPadding),
      flexFlow: 'row nowrap'
    },
    shadow: {
      boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(
        0.25
      )} -${theme.spacing(0.25)} rgba(0,0,0,0.1)`
    }
  })
)

export default useStyles
