import React from 'react'
import { useStyles } from './styles'

interface Props {
  content: JSX.Element
  hasOneSubheader: boolean
  hasOneSubheaderNoFooter: boolean
  isQuestionnairePage?: boolean
}

const ContentPageLayout: React.FC<Props> = (props: Props) => {
  const {
    content,
    hasOneSubheader,
    hasOneSubheaderNoFooter,
    isQuestionnairePage
  }: Props = props

  const { classes } = useStyles({ styleProps: { isQuestionnairePage } })

  const selectClassPageDivision = (
    hasOnlyOneSubheader: boolean,
    hasOnlyOneSubheaderNoFooter: boolean
  ): string => {
    if (hasOnlyOneSubheader) {
      return classes.contentWithOneSubheader
    }
    if (hasOnlyOneSubheaderNoFooter) {
      return classes.contentWithOneSubheaderNoFooter
    }

    return classes.contentWithTwoSubheaders
  }

  return (
    <div
      className={selectClassPageDivision(
        hasOneSubheader,
        hasOneSubheaderNoFooter
      )}
    >
      {content}
    </div>
  )
}

export default ContentPageLayout
