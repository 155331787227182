import { TransactionDatadog } from './Transaction'

export const addQuestionTransactionDatadog = new TransactionDatadog(
  'AddQuestion'
)

export const duplicateQuestionTransactionDatadog = new TransactionDatadog(
  'DuplicateQuestion'
)

export const deleteQuestionTransactionDatadog = new TransactionDatadog(
  'DeleteQuestion'
)

export const addResponseOptionTransactionDatadog = new TransactionDatadog(
  'AddResponseOption'
)

export const deleteResponseOptionTransactionDatadog = new TransactionDatadog(
  'DeleteResponseOption'
)

export const pasteInResponseOptionTransactionDatadog = new TransactionDatadog(
  'PasteInResponseOption'
)

export const moveResponseOptionTransactionDatadog = new TransactionDatadog(
  'MoveResponseOption'
)

export const pasteInTitleTransactionDatadog = new TransactionDatadog(
  'PasteInTitle'
)

export const addMatrixQuestionResponseOptionTransactionDatadog =
  new TransactionDatadog('AddMatrixQuestionResponseOption')

export const addMatrixQuestionRowTransactionDatadog = new TransactionDatadog(
  'AddMatrixQuestionRow'
)

export const moveMatrixQuestionResponseOptionTransactionDatadog =
  new TransactionDatadog('MoveMatrixQuestionResponseOption')

export const deleteMatrixQuestionResponseOptionTransactionDatadog =
  new TransactionDatadog('DeleteMatrixQuestionResponseOption')

export const pasteInMatrixQuestionColumnTransactionDatadog =
  new TransactionDatadog('PasteInMatrixQuestionColumn')

export const pasteInMatrixQuestionRowTransactionDatadog =
  new TransactionDatadog('PasteInMatrixQuestionRow')

export const deleteMatrixQuestionRowTransactionDatadog = new TransactionDatadog(
  'DeleteMatrixQuestionRow'
)

export const QuestionnaireRenderingTransactionDatadog = new TransactionDatadog(
  'QuestionnaireRenderingTransaction',
  { skipShorterThan: 1000 }
)
