import { TextRun } from 'docx'

export const createMultilinedTextRuns = (text: string) => {
  return text.split('\n').flatMap((questionTextPart, i) => {
    const isFirstPart = i === 0
    // make sure we did not miss any empty line (e.g. splitting 'two\n\nlines' will give us ['two', '', 'lines'])
    if (!isFirstPart && questionTextPart) {
      return [
        new TextRun({
          text: '',
          break: 1
        }),
        new TextRun({
          text: questionTextPart
        })
      ]
    }
    return [
      new TextRun({
        text: questionTextPart,
        break: questionTextPart ? 0 : 1
      })
    ]
  })
}
