import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import useStyles from './CustomAgeRanges.styles'

interface Props {
  hasCriterionOptions: boolean
  quotasEnabled: boolean
}

export const CustomAgeRangesHeader = ({
  hasCriterionOptions,
  quotasEnabled
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classNames({
        [classes.hidden]: !hasCriterionOptions
      })}
    >
      <Grid item xs={6} />
      <Grid
        container
        item
        xs={1}
        className={classNames({ [classes.hidden]: !quotasEnabled })}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightSemiBold,
            textClasses.highlightBackground,
            textClasses.marginLeft
          )}
        >
          QUOTAS
        </p>
      </Grid>
      <Grid
        container
        item
        xs={2}
        justifyContent="center"
        className={classNames({ [classes.hidden]: !quotasEnabled })}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightSemiBold,
            textClasses.highlightBackground,
            classes.completesCaption
          )}
        >
          COMPLETES
        </p>
      </Grid>
      <Grid container item xs={2} justifyContent="center">
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightSemiBold,
            textClasses.highlightBackground
          )}
        >
          QUALIFY
        </p>
      </Grid>
    </Grid>
  )
}
