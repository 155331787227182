import { Grid, List } from '@focaldata/cin-ui-components'
import React from 'react'
import BasicQuestionChartCardLoading from '../BasicQuestionChartCard/loading'

const CompleteChartListLoading: React.FC = () => {
  return (
    <Grid item>
      <List>
        <BasicQuestionChartCardLoading />
        <BasicQuestionChartCardLoading />
      </List>
    </Grid>
  )
}

export default CompleteChartListLoading
