import { makeVar, useReactiveVar } from '@apollo/client'

export const audienceQuestionsShownVar = makeVar(false)

const useAudienceQuestionsToggleVar = () => {
  const toggleShowAudienceQuestions = () =>
    audienceQuestionsShownVar(!audienceQuestionsShownVar())
  return {
    shouldShowAudienceQuestions: useReactiveVar(audienceQuestionsShownVar),
    toggleShowAudienceQuestions
  }
}

export default useAudienceQuestionsToggleVar
