import { v4 as uuidv4 } from 'uuid'
import {
  QuestionKind,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingInputInput,
  SettingValue
} from '../../../../data/model/questionnaire'
import {
  EntryType,
  Option,
  RawQuestionnaireEntry,
  Row
} from '../../../../hooks/import/models'
import { Order } from '../../../../utils/surveyDocBuilder/model'
const getTextFromParagraph = (paragraph: Element) => {
  const runsXml = paragraph.getElementsByTagName('w:r')
  const texts = Array.from(runsXml).reduce((fullText, r) => {
    const textsXml = r.childNodes
    const textsInRun = Array.from(textsXml).reduce((textInRun, t) => {
      if (t.nodeName === 'w:t') {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return textInRun + (t?.textContent ?? '')
      }
      if (t.nodeName === 'w:br' && fullText !== '') {
        return `${textInRun}\n`
      }
      return textInRun
    }, '')
    return fullText + textsInRun
  }, '')
  return texts
}

const checkIfFullTextIsQuestion = (fullText: string) => {
  const audienceQ = /^(A\d+\s)/
  const questionnaireQ = /^(Q\d+\s)/
  const textQ = /^(T\d+\s)/
  if (
    audienceQ.test(fullText) ||
    questionnaireQ.test(fullText) ||
    textQ.test(fullText)
  ) {
    return true
  }
  return false
}

const isNotForImport = (fullText: string) => {
  const sectionStart = /^S\d+\s\(Section\s-\sstart\)/
  const sectionEnd = /^S\d+\s\(Section\s-\sstart\)/
  const fork = /^Fork\s\d+/
  if (
    sectionStart.test(fullText) ||
    sectionEnd.test(fullText) ||
    fork.test(fullText) ||
    fullText.startsWith('Question Behaviour Summary:') ||
    fullText.startsWith('Questions with display logic:') ||
    fullText.startsWith('Responses with display logic/masking:')
  ) {
    return true
  }

  return false
}

interface QuestionTypeInfo {
  questionType: QuestionTypeCode
  settings?: SettingInputInput
  qNum?: string
}

const getQuestionNumber = (text: string) => {
  const regEx = /[AQT]\d+/
  const match = text.match(regEx)
  if (match) return match[0]
  return 0
}

const tryGetQuestionType = (text: string) => {
  const qNum = getQuestionNumber(text)
  if (text.includes('Single select')) {
    return {
      questionType: QuestionTypeCode.Basic,
      settings: {
        questionSettingCode: QuestionSettingCode.BasicChoice,
        settingValue: 'single_choice'
      },
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Multi select')) {
    return {
      questionType: QuestionTypeCode.Basic,
      settings: {
        questionSettingCode: QuestionSettingCode.BasicChoice,
        settingValue: 'multiple_choice'
      },
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Matrix - single select')) {
    return {
      questionType: QuestionTypeCode.Matrix,
      settings: {
        questionSettingCode: QuestionSettingCode.MatrixChoice,
        settingValue: 'single_choice'
      },
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Matrix - multi select')) {
    return {
      questionType: QuestionTypeCode.Matrix,
      settings: {
        questionSettingCode: QuestionSettingCode.MatrixChoice,
        settingValue: 'multiple_choice'
      },
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Ranked')) {
    return {
      questionType: QuestionTypeCode.Ranked,
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Slider')) {
    return {
      questionType: QuestionTypeCode.Scale,
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Text input')) {
    return {
      questionType: QuestionTypeCode.FreeText,
      settings: {
        questionSettingCode: QuestionSettingCode.ContentStructure,
        settingValue: 'unstructured'
      },
      qNum
    } as QuestionTypeInfo
  }
  if (text.includes('Text instruction')) {
    return {
      questionType: QuestionTypeCode.TextCard,
      qNum
    } as QuestionTypeInfo
  }
  return undefined
}

const defaultResponseOptions = [
  "Don't know",
  'Prefer not to say',
  'None of these'
]
const filterOption = (text: string) => {
  const randomiseOrder = Order.Randomise.toString()
  const reverseOrder = Order.Reverse.toString()
  const choiceLimit = /^Choice\slimit:\s\d+$/
  const choiceRange = /^Choice\srange:\s\d+-\d+$/
  return !(
    text === randomiseOrder ||
    text === reverseOrder ||
    choiceLimit.test(text) ||
    choiceRange.test(text)
  )
}
/// 1. each item in the array represents a response option
/// 2. remove the number and the dot from the beginning of the text
/// 3. remove the [text entry enabled] marker
/// 4. remove the [pinned] marker
/// 5. remove the [exclusive] marker
const getOptionsFromText = (text: string[]) => {
  return text
    .filter(filterOption)
    .map((t) => {
      const textWithMarkersRemoved = t
        .replace(/^(\d+\.\s)/g, '')
        .replace(/(\s+\[text entry enabled\])/g, '')
        .replace(/(\s+\[pinned\])/g, '')
        .replace(/(\s+\[exclusive\])/g, '')
      return {
        id: uuidv4(),
        value: textWithMarkersRemoved
      } as Option
    })
    .filter((t) => !defaultResponseOptions.includes(t.value || ''))
}

export const processBasicQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.BasicType,
    questionType: QuestionTypeCode.Basic,
    title: text[index],
    options: getOptionsFromText(text.slice(index + 1)),
    kind: questionKind,
    rows: []
  }
  return question
}

export const processMatrixQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const columnsIndex = text.findIndex((t) => t.includes('Columns'))
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.MatrixType,
    questionType: QuestionTypeCode.Matrix,
    title: text[index],
    rows: text
      .slice(index + 1, columnsIndex)
      .filter(filterOption)
      .map(
        (t) => ({ id: uuidv4(), value: t.replace(/^(\d+\.\s)/g, '') }) as Row
      ),
    kind: questionKind,
    options: getOptionsFromText(text.slice(columnsIndex + 1))
  }
  return question
}

export const processRankedQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.RankedType,
    questionType: QuestionTypeCode.Ranked,
    title: text[index],
    options: getOptionsFromText(text.slice(index + 1)),
    kind: questionKind,
    rows: []
  }
  return question
}

export const processScaleQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.ScaleType,
    questionType: QuestionTypeCode.Scale,
    title: text[index],
    options: [],
    kind: questionKind,
    rows: []
  }
  return question
}

const getFreeTextSettings = (text: string[]) => {
  const settings: SettingInputInput[] = []
  text.forEach((t) => {
    if (t.includes('free text')) {
      settings.push({
        questionSettingCode: QuestionSettingCode.ContentStructure,
        settingValue: 'unstructured'
      })
    }
    if (t.includes('number')) {
      settings.push({
        questionSettingCode: QuestionSettingCode.ContentStructure,
        settingValue: SettingValue.Numeric1
      })
    }
    if (t.includes('postal code')) {
      settings.push({
        questionSettingCode: QuestionSettingCode.ContentStructure,
        settingValue: SettingValue.PostCode
      })
    }
    if (t.includes('email')) {
      settings.push({
        questionSettingCode: QuestionSettingCode.ContentStructure,
        settingValue: SettingValue.Email
      })
    }
  })

  return settings[0]
}

export const processFreeTextQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const setting = getFreeTextSettings(text.slice(index + 1))
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.FreeTextType,
    questionType: QuestionTypeCode.FreeText,
    title: text[index],
    options: [],
    kind: questionKind,
    setting,
    rows: []
  }
  return question
}

export const processTextCardQuestion = (
  text: string[],
  index: number,
  questionKind: QuestionKind
) => {
  const question: RawQuestionnaireEntry = {
    id: uuidv4(),
    entryType: EntryType.TextCardType,
    questionType: QuestionTypeCode.TextCard,
    title: text[index],
    subtitle: text[index + 1],
    options: [],
    kind: questionKind,
    rows: []
  }
  return question
}

const processByQuestionType = (
  text: string[],
  index: number,
  type: QuestionTypeCode,
  questionKind: QuestionKind
) => {
  switch (type) {
    case QuestionTypeCode.Basic:
      return processBasicQuestion(text, index, questionKind)
    case QuestionTypeCode.Matrix:
      return processMatrixQuestion(text, index, questionKind)
    case QuestionTypeCode.Ranked:
      return processRankedQuestion(text, index, questionKind)
    case QuestionTypeCode.Scale:
      return processScaleQuestion(text, index, questionKind)
    case QuestionTypeCode.FreeText:
      return processFreeTextQuestion(text, index, questionKind)
    case QuestionTypeCode.TextCard:
      return processTextCardQuestion(text, index, questionKind)
    default:
      return undefined
  }
}

const isQuestionLogicHeader = (text: string) => {
  return text.startsWith('Display this question ')
}

export const getQuestionsFromDoc = (doc: Document) => {
  const paragraphs = doc.getElementsByTagName('w:p')
  let started = false
  const result = Array.from(paragraphs).reduce((acc, p) => {
    const fullText = getTextFromParagraph(p)
    const isNewQuestion =
      isQuestionLogicHeader(fullText) || checkIfFullTextIsQuestion(fullText)
    if (isNewQuestion) {
      if (isNotForImport(fullText)) {
        started = false
      } else started = true
    }
    if (started) {
      if (isNewQuestion) {
        if (fullText !== '') acc.push([fullText])
      } else {
        const lastItem = acc[acc.length - 1]
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (lastItem && fullText !== '') {
          lastItem.push(fullText)
          acc.splice(acc.length - 1, 1, lastItem)
        }
      }
    }
    return acc
  }, [] as string[][])
  // create questions
  const questions = result
    .filter((r) => r.some((text) => checkIfFullTextIsQuestion(text)))
    .map((r) => {
      const hasDisplayLogic = r.some((text) =>
        text.includes('Display this question IF')
      )
      const qInfo = hasDisplayLogic ? r[1] : r[0] // if has display logic, the question info is in the second item
      const qKind = qInfo.startsWith('A')
        ? QuestionKind.AudienceKind
        : QuestionKind.QuestionnaireKind

      const qTitleIndex = hasDisplayLogic ? 2 : 1
      const { questionType, settings, qNum } = tryGetQuestionType(
        qInfo
      ) as QuestionTypeInfo
      let question = processByQuestionType(
        r,
        qTitleIndex,
        questionType,
        qKind
      ) as RawQuestionnaireEntry
      question = {
        ...question,
        questionType,
        setting: settings,
        qNum
      }
      return question as RawQuestionnaireEntry
    })

  return questions
}
