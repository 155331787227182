import { Reference } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { Middleware, createAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../App.store'
import {
  ConfigQueriesDraftQuestionnaireV2Args,
  DraftMatrixRow
} from '../data/gql-gen/questionnaire/graphql'
import DRAFT_RESPONSE_OPTION from '../data/gql/questionnaire/fragments/draftEntryResponseOption'
import DRAFT_MATRIX_ROW from '../data/gql/questionnaire/fragments/draftMatrixRow'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../data/gql/questionnaire/queries'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftTextCardItem,
  EntryType
} from '../data/model/questionnaire'
import {
  QuestionnaireState,
  questionnaireLoaded
} from '../modules/Questionnaire/Questionnaire.slice'
import { flattenAllEntries } from '../modules/Questionnaire/Questionnaire.utils'

export const loadQuestionnaire = createAction<{
  projectId: string
  surveyId: string
} | null>('loadQuestionnaire')

const syncApolloCacheWithRedux = (
  apolloCache: InMemoryCache,
  // entryTypes aren't stored in Redux, so we need to pass them in from apollo
  entryTypes: Map<string, EntryType>,
  questionnaireState: QuestionnaireState
) => {
  for (const [entryId, entryType] of entryTypes) {
    const settings = questionnaireState.settingsByQuestion[entryId]
    const questionLogic = questionnaireState.questionLogicByQuestion[entryId]
    const responseOptions =
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      questionnaireState.responseOptionsByQuestion[entryId] ?? []

    const responseOptionRefs: Reference[] = []

    for (const responseOption of responseOptions) {
      const referenceId = `DraftEntryResponseOption:{"responseOptionLk":"${responseOption.responseOptionLk}"}`
      responseOptionRefs.push({ __ref: referenceId })
      apolloCache.writeFragment({
        fragmentName: 'DraftEntryResponseOption',
        fragment: DRAFT_RESPONSE_OPTION,
        data: responseOption
      })
      apolloCache.release(referenceId)
    }

    switch (entryType) {
      case EntryType.QuestionEntryType: {
        apolloCache.modify<DraftQuestionItem>({
          id: `DraftQuestionItem:{"questionLk":"${entryId}"}`,
          fields: {
            responseOptions: () => responseOptionRefs,
            settingValues: () => settings,
            questionLogic: () => questionLogic
          }
        })

        break
      }

      case EntryType.MatrixEntryType: {
        const matrixRows =
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          questionnaireState.matrixRowsByQuestion[entryId] ?? []

        const matrixRowRefs: Reference[] = []

        for (const matrixRow of matrixRows) {
          const referenceId = `DraftMatrixRow:{"questionLk":"${matrixRow.questionLk}"}`
          matrixRowRefs.push({ __ref: referenceId })
          apolloCache.writeFragment<DraftMatrixRow>({
            fragmentName: 'DraftMatrixRow',
            fragment: DRAFT_MATRIX_ROW,
            data: matrixRow
          })
          apolloCache.release(referenceId)
        }

        apolloCache.modify<DraftMatrixItem>({
          id: `DraftMatrixItem:{"matrixTitleLk":"${entryId}"}`,
          fields: {
            responseOptions: () => responseOptionRefs,
            matrixRows: () => matrixRowRefs,
            settingValues: () => settings,
            questionLogic: () => questionLogic
          }
        })
        break
      }

      case EntryType.TextCardEntryType:
        apolloCache.modify<DraftTextCardItem>({
          id: `DraftTextCardItem:{"textCardLk":"${entryId}"}`,
          fields: {
            settingValues: () => settings,
            questionLogic: () => questionLogic
          }
        })
        break
    }
  }
}

type ApolloMiddlewareState = {
  teardown: () => void
  apolloUpdateInProgress: boolean
  questionnaire: null | {
    id: string
    entryTypes: Map<string, EntryType>
  }
}

/**
 * This middleware is responsible for keeping the apollo cache in sync with the
 * redux store, including ingesting the server-side data into the redux store
 *
 * @todo remove this when we have only one source of truth
 */
export const apolloMiddleware =
  (
    apolloClient: ApolloClient<unknown>,
    apolloCache: InMemoryCache
  ): Middleware<
    // We're not extending dispatch – {} does not behave as expected, so this is equivalent
    Record<string, never>,
    RootState
  > =>
  (store) => {
    const dispatch = store.dispatch as AppDispatch

    let middlewareState: ApolloMiddlewareState | null = null

    return (next) => (action) => {
      if (loadQuestionnaire.match(action)) {
        const { projectId, surveyId } = action.payload || {}

        if (middlewareState) {
          middlewareState.teardown()
          middlewareState = null
        }

        if (projectId && surveyId) {
          const query = apolloClient.watchQuery<
            QuestionnaireData,
            ConfigQueriesDraftQuestionnaireV2Args
          >({
            context: { clientName: 'questionnaire' },
            query: QUESTIONNAIRE,
            variables: { projectId, surveyId }
          })

          // Keeping this in a local variable means we don't have to handle the
          // possibility that it's null within the subscription, like
          // middlewareState in theory could be
          const nextMiddlewareState: ApolloMiddlewareState = {
            apolloUpdateInProgress: false,
            questionnaire: null,
            // This depends on the subscription, so we'll set this later
            teardown: () => {}
          }

          middlewareState = nextMiddlewareState

          const subscription = query.subscribe((result) => {
            // This means we triggered this notification by updating the cache
            // ourselves to sync with Redux. This means Redux is already up-to-
            // date and doesn't need to know about the change
            if (nextMiddlewareState.apolloUpdateInProgress) {
              nextMiddlewareState.apolloUpdateInProgress = false
            } else {
              const { draftQuestionnaire } = result.data

              nextMiddlewareState.questionnaire = {
                // This isn't currently used, but has been in previous
                // implementations of the middleware and is a useful thing to
                // keep around
                id: draftQuestionnaire.questionnaireId,
                // These are used by the syncApolloCacheWithRedux function and
                // it's cheaper to calculate them only when the draft
                // questionnaire has actually changed
                entryTypes: new Map(
                  flattenAllEntries(draftQuestionnaire).map((entry) => [
                    entry.entryId,
                    entry.entryType
                  ])
                )
              }

              dispatch(questionnaireLoaded(draftQuestionnaire))
            }
          })

          nextMiddlewareState.teardown = () => {
            subscription.unsubscribe()
            apolloClient.cache.evict({
              id: `DraftQuestionnaire:{"questionnaireId":"${surveyId}"}`
            })
            apolloClient.cache.evict({
              id: `FieldworkAudience:{"surveyId":"${surveyId}"}`
            })
            apolloClient.cache.evict({
              id: `DraftFieldworkAudience:{"surveyId":"${surveyId}"}`
            })
            apolloClient.cache.evict({
              id: `Fieldwork:{"surveyId":"${surveyId}"}`
            })
            apolloClient.cache.gc()
          }
        }
        // We don't need to update apollo if we haven't yet set up an apollo subscription
      } else if (
        !middlewareState?.questionnaire ||
        questionnaireLoaded.match(action)
      ) {
        return next(action)
      } else {
        const prevState = store.getState()
        const result = next(action)
        const nextState = store.getState()

        if (prevState.questionnaire !== nextState.questionnaire) {
          // This will trigger a new subscription notification,
          // but we don't want to process it as we're already updating apollo
          middlewareState.apolloUpdateInProgress = true
          syncApolloCacheWithRedux(
            apolloCache,
            middlewareState.questionnaire.entryTypes,
            nextState.questionnaire
          )
        }

        return result
      }
    }
  }
