import { useContext } from 'react'
import QuestionnaireContext from '../../../../modules/Questionnaire/Questionnaire.context'
import { QuestionsSelectorCounter } from './QuestionsSelectorCounter'

const QuestionsSelectorCounterContainer = (): JSX.Element | null => {
  const { questionnaireState } = useContext(QuestionnaireContext)
  const numberOfSelectedQuestions = questionnaireState.selectedCards.size

  if (!numberOfSelectedQuestions) {
    return null
  }
  const text = `${numberOfSelectedQuestions} selected`
  return <QuestionsSelectorCounter text={text} />
}

export default QuestionsSelectorCounterContainer
