import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Icon from '@mui/material/Icon'
import { DraftEntryResponseOption } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionTypeCode
} from '../../../data/model/questionnaire'
import { RawQuestionnaireEntry } from '../../../hooks/import/models'
import { getEntryId } from '../Questionnaire.utils'
import OptionPreview from './OptionPreview'
import {
  GenericPair,
  getQuestionTypeText,
  getQuestionTypeTextFromRaw
} from './import.utils'

interface Props {
  entry?: DraftQuestionnaireEntry
  rawEntry?: RawQuestionnaireEntry
  responsePairs?: GenericPair<string, string>[]
  rowPairs?: GenericPair<string, string>[]
  selected?: boolean
  disabled?: boolean
  isQuestionnaireEmpty?: boolean
  showUpArrow?: boolean
  showDownArrow?: boolean
  selectionChanged?: (entryId: string, selected: boolean) => void
  responseSelectionChanged?: (
    entryId: string,
    responseId: string,
    selected: boolean
  ) => void
  rowSelectionChanged?: (
    entryId: string,
    rowId: string,
    selected: boolean
  ) => void
  handleMoveUp?: (id: string) => void
  handleMoveDown?: (id: string) => void
  handleMoveResponseUp?: (id: string, responseId: string) => void
  handleMoveResponseDown?: (id: string, responseId: string) => void
  handleMoveRowUp?: (id: string, rowId: string) => void
  handleMoveRowDown?: (id: string, rowId: string) => void
}

const QuestionPreview: React.FC<Props> = ({
  entry,
  rawEntry,
  responsePairs,
  rowPairs,
  selected,
  disabled,
  isQuestionnaireEmpty,
  showUpArrow,
  showDownArrow,
  selectionChanged,
  responseSelectionChanged,
  rowSelectionChanged,
  handleMoveUp,
  handleMoveDown,
  handleMoveResponseUp,
  handleMoveResponseDown,
  handleMoveRowUp,
  handleMoveRowDown
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const getQuestionNumberingText = (entry: DraftQuestionnaireEntry): string =>
    `${entry.entryType === EntryType.TextCardEntryType ? 'T' : 'Q'}${
      entry.contextPosition + 1
    }`

  const getQuestionText = (entry: DraftQuestionnaireEntry): string => {
    switch (entry.entryType) {
      case EntryType.MatrixEntryType:
        return entry.entryItem.matrixTitle?.title ?? ''
      case EntryType.TextCardEntryType:
        return entry.entryItem.textCard.title
      case EntryType.QuestionEntryType:
        return (entry.entryItem as DraftQuestionItem).question?.text ?? ''
      default:
        return ''
    }
  }

  const getEntryResponses = (
    entry: DraftQuestionnaireEntry,
    responsePairs?: GenericPair<string, string>[]
  ) => {
    let responses = [] as DraftEntryResponseOption[]
    switch (entry.entryType) {
      case EntryType.MatrixEntryType:
        responses = entry.entryItem.responseOptions
        break
      case EntryType.QuestionEntryType:
        responses = entry.entryItem.responseOptions
        break
      default:
        break
    }
    return (
      <>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {responses &&
          responsePairs?.map(({ item1: responseOptionId }) => {
            const responseOption = responses.find(
              (r) => r.responseOptionLk === responseOptionId
            )
            return (
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line react/jsx-key
              <OptionPreview
                key={responseOptionId}
                value={responseOption?.responseOption?.value}
                position={responseOption?.position}
                isChecked
                isDisabled
                showActions={false}
              />
            )
          })}
      </>
    )
  }

  const getMatrixRows = (
    entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>,
    rowPairs?: GenericPair<string, string>[]
  ) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const entryItem = entry?.entryItem
    return (
      <>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {entryItem &&
          rowPairs?.map(({ item1: rowId }) => {
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const row = entryItem?.matrixRows?.find(
              (r) => r.questionLk === rowId
            )
            return (
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line react/jsx-key
              <OptionPreview
                value={row?.question?.text}
                position={row?.position}
                isChecked
                isDisabled
                showActions={false}
              />
            )
          })}
      </>
    )
  }

  const getRawResponses = (
    rawEntry: RawQuestionnaireEntry,
    responsePairs?: GenericPair<string, string>[]
  ) => {
    return (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      rawEntry &&
      responsePairs?.map(
        ({ item1: sourceResponseId, item2: responseOptionId }, index) => {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const responseOption = rawEntry?.options?.find(
            (r) => r.id === responseOptionId
          )
          return (
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line react/jsx-key
            <OptionPreview
              value={responseOption?.value}
              position={responseOption?.position}
              isChecked={responseOption?.isChecked}
              isDisabled={disabled}
              showActions={responseOption && !isQuestionnaireEmpty}
              showMoveUp={
                sourceResponseId !== undefined ||
                (responsePairs[index - 1]?.item1 !== undefined &&
                  responsePairs[index - 1]?.item2 === undefined)
              }
              showMoveDown={
                sourceResponseId !== undefined ||
                responsePairs
                  .slice(index + 1) // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  ?.some((r) => r.item1 !== undefined)
              }
              onCheckedChange={(isChecked) => {
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (rawEntry)
                  responseSelectionChanged?.(
                    // @todo Legacy eslint violation – fix this when editing
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    rawEntry?.id as string,
                    responseOption?.id as string,
                    isChecked
                  )
              }}
              onMoveUp={() =>
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                rawEntry &&
                handleMoveResponseUp?.(
                  rawEntry.id,
                  responseOption?.id as string
                )
              }
              onMoveDown={() =>
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                rawEntry &&
                handleMoveResponseDown?.(
                  rawEntry.id,
                  responseOption?.id as string
                )
              }
            />
          )
        }
      )
    )
  }

  const getRawMatrixRows = (
    rawEntry: RawQuestionnaireEntry,
    rowPairs?: GenericPair<string, string>[]
  ) => {
    return (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      rawEntry &&
      rowPairs?.map(({ item1: sourceRowId, item2: rowId }, index) => {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const row = rawEntry?.rows?.find((r) => r.id === rowId)
        return (
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line react/jsx-key
          <OptionPreview
            value={row?.value}
            position={row?.position}
            isChecked={row?.isChecked}
            isDisabled={disabled}
            showActions={row && !isQuestionnaireEmpty}
            showMoveUp={
              sourceRowId !== undefined ||
              (rowPairs[index - 1]?.item1 !== undefined &&
                rowPairs[index - 1]?.item2 === undefined)
            }
            showMoveDown={
              sourceRowId !== undefined ||
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              rowPairs.slice(index + 1)?.some((r) => r.item1 !== undefined)
            }
            onCheckedChange={(isChecked) => {
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (rawEntry)
                rowSelectionChanged?.(
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  rawEntry?.id as string,
                  row?.id as string,
                  isChecked
                )
            }}
            onMoveUp={() =>
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              rawEntry && handleMoveRowUp?.(rawEntry.id, row?.id as string)
            }
            onMoveDown={() =>
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              rawEntry && handleMoveRowDown?.(rawEntry.id, row?.id as string)
            }
          />
        )
      })
    )
  }

  const renderEntryDetails = () => {
    if (
      entry?.entryType === EntryType.QuestionEntryType ||
      entry?.entryType === EntryType.MatrixEntryType ||
      rawEntry?.questionType === QuestionTypeCode.Basic ||
      rawEntry?.questionType === QuestionTypeCode.Ranked ||
      rawEntry?.questionType === QuestionTypeCode.Matrix
    ) {
      return (
        <>
          {entry && (
            <Grid container spacing={1} direction="column">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {entry?.entryType === EntryType.MatrixEntryType && (
                <>
                  Rows
                  {getMatrixRows(entry, rowPairs)}
                  Columns
                </>
              )}
              {getEntryResponses(entry, responsePairs)}
            </Grid>
          )}

          {rawEntry && (
            <Grid container spacing={1} direction="column">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {rawEntry?.questionType === QuestionTypeCode.Matrix && (
                <>
                  Rows
                  {getRawMatrixRows(rawEntry, rowPairs)}
                  Columns
                </>
              )}
              {getRawResponses(rawEntry, responsePairs)}
            </Grid>
          )}
        </>
      )
    }
    return null
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid container item sm={11} direction="row">
          <Grid>
            <Grid item sm="auto">
              <input
                type="checkbox"
                checked={selected || rawEntry?.isChecked}
                disabled={disabled}
                onChange={(e) => {
                  if (entry)
                    selectionChanged?.(getEntryId(entry), e.target.checked)
                  if (rawEntry)
                    // @todo Legacy eslint violation – fix this when editing
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    selectionChanged?.(rawEntry?.id as string, e.target.checked)
                }}
              />
              {entry && getQuestionTypeText(entry)}
              {rawEntry && getQuestionTypeTextFromRaw(rawEntry)}
            </Grid>
            <Grid container item sm>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.displayInline
                )}
              >
                {entry &&
                  `${getQuestionNumberingText(entry)} ${getQuestionText(
                    entry
                  )} - ${entry.position}`}
                {rawEntry && (
                  <>
                    {rawEntry.qNum} {rawEntry.title} - {rawEntry.position}
                  </>
                )}
              </p>
            </Grid>
          </Grid>
        </Grid>
        {rawEntry && !isQuestionnaireEmpty && (
          <Grid container item sm={1} style={{ paddingTop: '0' }}>
            <Grid container item sm="auto" direction="column">
              <Grid
                item
                alignItems="flex-end"
                style={{
                  maxHeight: '20px'
                }}
              >
                <Icon
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  onClick={() => rawEntry && handleMoveUp?.(rawEntry.id)}
                  style={{ display: showUpArrow ? 'unset' : 'none' }}
                >
                  <KeyboardArrowUp sx={{ marginBottom: '-5px' }} />
                </Icon>
              </Grid>
              <Grid
                item
                alignItems="flex-start"
                style={{
                  maxHeight: '20px'
                }}
              >
                <Icon
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  onClick={() => rawEntry && handleMoveDown?.(rawEntry.id)}
                  style={{ display: showDownArrow ? 'unset' : 'none' }}
                >
                  <KeyboardArrowDown sx={{ marginTop: '-5px' }} />
                </Icon>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {renderEntryDetails()}
    </>
  )
}

export default QuestionPreview
