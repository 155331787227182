import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'
import {
  ACCORDION_QUESTION_TYPES_WIDTH,
  ACCORDION_SUMMARY_WIDTH,
  EDIT_TARGETING_FIXED_CONTENT_HEIGHT
} from '../constants'

const useStyles = makeStyles()({
  wrapper: {
    height: 'calc(100% - 57px)'
  },
  root: {
    position: 'relative',
    height: '100%'
  },
  audienceTypes: {
    width: ACCORDION_QUESTION_TYPES_WIDTH,
    position: 'relative',
    height: `calc(100vh - ${EDIT_TARGETING_FIXED_CONTENT_HEIGHT}px)`,
    overflow: 'auto',
    backgroundColor: additionalPalette.lightBlue.main
  },
  customAudienceList: {
    margin: theme.spacing(1, 2.5, 0, 4),
    padding: theme.spacing(1.5, 0, 1.5, 0)
  },
  summary: {
    width: ACCORDION_SUMMARY_WIDTH,
    padding: theme.spacing(3, 4),
    overflow: 'auto',
    height: `calc(100vh - ${EDIT_TARGETING_FIXED_CONTENT_HEIGHT}px)`,
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  customAudienceTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.75)
  }
})

export default useStyles
