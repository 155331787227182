import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  iconButton: {
    margin: theme.spacing(-1.375, 0)
  },
  infoIcon: {
    display: 'inherit',
    alignItems: 'center'
  },
  labelSamplesNeeded: {
    marginTop: 24
  },
  inputSamplesNeeded: {
    width: 80,
    '& input.MuiInputBase-input': {
      fontSize: '14px !important'
    }
  },
  errorIcon: {
    marginRight: `-${theme.spacing(2.5)}`,
    marginTop: theme.spacing(1)
  },
  inputErrorClassName: {
    outline: `${theme.spacing(0.25)} solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(0.25)
  }
})

export default useStyles
