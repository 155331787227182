import { useAppDispatch, useAppSelector } from '../../../App.store'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  DraftQuestionItem,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  ResponseOrderingOptions,
  WithEntry,
  checkIfSingleChoice,
  getOrdering,
  getQuestionEnabledSettings
} from '../../../utils/questionnaireUtils'
import {
  selectSettingsByQuestionId,
  setQuestionSetting
} from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'

interface QuestionCardSwitchesProps extends WithEntry {
  randomiseQuestionSettingCode: QuestionSettingCode
  flipOrderQuestionSettingCode?: QuestionSettingCode
}

const useQuestionCardSwitches = (props: QuestionCardSwitchesProps) => {
  const surveyId = useSurveyId()
  const { entry, randomiseQuestionSettingCode, flipOrderQuestionSettingCode } =
    props
  const entryId = getEntryId(entry)
  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []

  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const changeSettingValues = async (
    settingValues: {
      settingCode: QuestionSettingCode
      settingValue: SettingValue
    }[]
  ) => {
    settingValues.forEach(({ settingCode, settingValue }) => {
      dispatch(
        setQuestionSetting({
          questionLk: entryId,
          code: settingCode,
          value: settingValue
        })
      )
    })

    await handleSettingChange(settingValues, 'no-cache')
  }

  const getLogEvent = (ordering: ResponseOrderingOptions) => {
    if (ordering === ResponseOrderingOptions.Random) {
      return EventType.RandomisedOrder
    }
    if (ordering === ResponseOrderingOptions.FlipOrder) {
      return EventType.FlippedOrder
    }
    return EventType.FixedOrder
  }

  const onOrderingChange = async (ordering: ResponseOrderingOptions) => {
    const newSettingValues: {
      settingCode: QuestionSettingCode
      settingValue: SettingValue
    }[] = []
    const addSettingValue = (
      settingCode: QuestionSettingCode | undefined,
      settingValue: SettingValue
    ) => {
      if (settingCode) {
        newSettingValues.push({
          settingCode,
          settingValue
        })
      }
    }
    switch (ordering) {
      case ResponseOrderingOptions.Random:
        addSettingValue(randomiseQuestionSettingCode, SettingValue.Enabled)
        addSettingValue(flipOrderQuestionSettingCode, SettingValue.Disabled)
        break
      case ResponseOrderingOptions.FlipOrder:
        addSettingValue(flipOrderQuestionSettingCode, SettingValue.Enabled)
        addSettingValue(randomiseQuestionSettingCode, SettingValue.Disabled)
        break
      default:
        addSettingValue(randomiseQuestionSettingCode, SettingValue.Disabled)
        addSettingValue(flipOrderQuestionSettingCode, SettingValue.Disabled)
        break
    }

    await changeSettingValues(newSettingValues)

    const logEvent = getLogEvent(ordering)

    LogAmplitudeEvent(logEvent, {
      surveyId,
      questionId: entryId
    })
  }

  const onChangeMatrixChoice: (
    settingValue: SettingValue
  ) => Promise<void> = async (settingValue) => {
    const newSettingValues = [
      {
        settingCode: QuestionSettingCode.MatrixChoice,
        settingValue
      }
    ]
    if (settingValue === SettingValue.MultipleChoice) {
      newSettingValues.push({
        settingCode: QuestionSettingCode.MatrixFlipOptions,
        settingValue: SettingValue.Disabled
      })
    }
    await changeSettingValues(newSettingValues)
  }

  const enabledSettings = getQuestionEnabledSettings(settingValues)

  const isRandomiseOn = enabledSettings.has(randomiseQuestionSettingCode)
  const isFlipOrderOn =
    flipOrderQuestionSettingCode &&
    enabledSettings.has(flipOrderQuestionSettingCode)

  const matrixChoiceDefaultSettingValue = settingValues.find(
    (settingValue) => settingValue.code === QuestionSettingCode.MatrixChoice
  )?.value

  const isSingleChoice =
    checkIfSingleChoice({
      settingValues
    } as DraftQuestionItem) ||
    matrixChoiceDefaultSettingValue === SettingValue.SingleChoice

  const showFlipOrder =
    isSingleChoice && flipOrderQuestionSettingCode !== undefined

  const ordering = getOrdering(isRandomiseOn, isFlipOrderOn)

  return {
    ordering,
    matrixChoiceDefaultSettingValue,
    showFlipOrder,
    onOrderingChange,
    onChangeMatrixChoice
  }
}

export default useQuestionCardSwitches
