import gql from 'graphql-tag'
import { PanelSupplierOrder } from '../../../data/model/order'

export interface AddPanelSupplierOrderData {
  addPanelSupplierOrders: PanelSupplierOrder
}

export const ADD_PANEL_SUPPLIER_ORDER = gql`
  mutation addPanelSupplierOrder(
    $surveyId: ID!
    $orderDescription: String!
    $samplesNeeded: Int!
  ) {
    addPanelSupplierOrder(
      panelSupplierCode: "cint"
      surveyId: $surveyId
      orderDescription: $orderDescription
      samplesNeeded: $samplesNeeded
    ) {
      orderId
      panelSupplierCode
      panelSupplierOrderId {
        value
      }
      status
      cpi
      orderNumber
      createdDate
      sinceDate
    }
  }
`
