import { theme } from '@focaldata/cin-ui-components'
import { Chart } from 'chart.js'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { BarChartData } from '../Chart.model'
import {
  calculateChartHeight,
  getBarColor,
  getDefaultOptions
} from '../Chart.utils'
import {
  buildTooltipBody,
  buildTooltipTitle,
  createCaret,
  createTooltip
} from './BarChart.utils'

interface Props extends React.HTMLAttributes<HTMLElement> {
  data?: BarChartData
  colorIndex?: number
}

const BarChart: React.FC<Props> = (props: Props) => {
  const { data, colorIndex }: Props = props
  const height = calculateChartHeight(data?.labels.length || 0)
  const maxBarValue: number =
    (data?.datasets[0].data && Math.max(...data.datasets[0].data)) || 0

  const options = getDefaultOptions(theme, maxBarValue)

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (options) {
    options.indexAxis = 'y'
    options.plugins = {
      datalabels: {
        ...options.plugins.datalabels,
        color: theme.palette.text.primary,
        anchor: 'end',
        align: 'right'
      },
      legend: {
        display: false
      },
      tooltip: {
        ...options.plugins.tooltip,
        intersect: false,
        axis: 'y',
        mode: 'index',
        enabled: false,
        external: ({ tooltip: tooltipModel, chart }): void => {
          const { canvas } = chart
          const tooltipEl = createTooltip(canvas, tooltipModel)

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0'

            return
          }

          const caretEl = createCaret(tooltipModel)

          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (tooltipModel.body) {
            const titleEl = document.createElement('div')
            const bodyEl = document.createElement('div')

            buildTooltipTitle(titleEl, tooltipModel)
            buildTooltipBody(bodyEl, tooltipModel)

            tooltipEl.innerHTML = ''
            tooltipEl.append(titleEl, bodyEl, caretEl)
          }
        }
      }
    }
  }

  const getSingleMultipleColor: (index: number) => string = (index) => {
    const indexModulo = index % 10 // 10 are the primary colors for the charts
    const selectedColor = getBarColor(indexModulo)

    return selectedColor
  }

  const dataCallback = {
    ...data,
    datasets: [
      {
        totalSamples: data?.totalSamples,
        data: data?.datasets[0].data,
        backgroundColor: getSingleMultipleColor(colorIndex || 0),
        hoverBackgroundColor: getSingleMultipleColor(colorIndex || 0)
      }
    ]
  }

  Chart.register(ChartDataLabels)

  return (
    <Bar
      height={height}
      data={dataCallback}
      options={options}
      updateMode="resize"
    />
  )
}

export default BarChart
