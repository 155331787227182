import { gql } from '@apollo/client/core'
import { AUDIENCE_QUESTION } from './draftAudience'

const DRAFT_CUSTOM_CRITERION = gql`
  fragment DraftCustomCriterion on DraftCustomCriterion {
    questionLk
    number
    question {
      ...AudienceQuestion
    }
    state
    createdDate
    sinceDate
  }
  ${AUDIENCE_QUESTION}
`

export default DRAFT_CUSTOM_CRITERION
