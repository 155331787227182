import { textStyleUtils } from '@focaldata/cin-ui-components'
import { Grid, IconButton } from '@mui/material'
import Paper from '@mui/material/Paper'
import { Close } from 'mdi-material-ui'
import React from 'react'
import useStyles from './DynamicError.styles'

interface OwnProps {
  body: string
  title: string
  onClose?: () => void
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>

const DynamicError: React.FC<Props> = (props: Props) => {
  const { title, body, onClose }: Props = props
  const { classes, theme } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs>
            <p
              className={classNames(
                textClasses.default,
                textClasses.displayBlock,
                textClasses.highlightError,
                textClasses.sizeMl,
                textClasses.alignCenter
              )}
            >
              {title}
            </p>
            <p
              className={classNames(
                textClasses.default,
                textClasses.displayBlock,
                textClasses.highlightBackground,
                textClasses.sizeS,
                textClasses.alignCenter,
                classes.marginTop
              )}
            >
              {body}
            </p>
          </Grid>
          {onClose && (
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                size="small"
              >
                <Close sx={{ fontSize: theme.spacing(2) }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DynamicError
