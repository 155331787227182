import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  optionContainer: {
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.divider}`
  },
  switchContainer: {
    marginTop: theme.spacing(4)
  },
  radioSubtext: {
    marginLeft: theme.spacing(2.25)
  },
  alertContainer: {
    marginTop: theme.spacing(2)
  }
})

export default useStyles
