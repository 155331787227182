import { gql } from '@apollo/client'

export const ADD_MARKET = gql`
  mutation addMarket(
    $name: String!
    $projectId: ID!
    $countryCode: String!
    $language: String!
    $completes: Int!
  ) {
    addMarket(
      name: $name
      projectId: $projectId
      countryCode: $countryCode
      language: $language
      completes: $completes
    ) @client
  }
`
