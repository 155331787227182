import gql from 'graphql-tag'
import { SurveyOrder } from '../../../model/order'

export interface SuveryOrderData {
  surveyOrder: SurveyOrder
}

export const GET_SURVEY_ORDER = gql`
  query surveyOrder($surveyId: ID!) {
    surveyOrder(surveyId: $surveyId) {
      surveyId
      customerCPI
      status
      feasibility
      orderType
      createdDate
      sinceDate
    }
  }
`
