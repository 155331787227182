type ObjectPredicate<T> = (value: T[keyof T], key: keyof T) => boolean

/**
 * Vanilla implementation of lodash's pickBy (more efficient):
 * Apply @param fn to every key of the @param object. Return a new object
 * with keys for which @param fn returns a truthy value.
 *
 * @param object
 * @param fn
 * @returns result
 */
export function pickBy<T extends Record<string, any>>(
  object: T,
  fn: ObjectPredicate<T>
): Partial<T> {
  const keys = Object.keys(object) as (keyof T)[]
  const result: Partial<T> = {}

  for (const key of keys) {
    if (fn(object[key], key)) {
      result[key] = object[key]
    }
  }

  return result
}
