import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const CUSTOM_CRITERION_VALIDATION_ERRORS = questionnaireGql(
  /* GraphQL */ `
    fragment CustomCriterionValidationErrors on CustomCriterionValidationErrors {
      questionLk
      customCriterionErrors: errors
      customCriterionOptionsErrors {
        responseOptionLk
        errors
      }
    }
  `
)

export const STANDARD_CRITERION_VALIDATION_ERRORS = questionnaireGql(
  /* GraphQL */ `
    fragment StandardCriterionValidationErrors on StandardCriterionValidationErrors {
      code
      standardCriterionErrors: errors
      standardCriterionOptionsErrors {
        code
        errors
      }
    }
  `
)
