type SortOrder = 'asc' | 'desc'

interface SortByOptions<T> {
  key: keyof T
  order?: SortOrder
}

/**
 * Typescript implementation of Lodash's sortBy, more performant.
 * @param arr
 * @param options
 * @returns
 */
export function sortBy<T>(arr: T[], options: SortByOptions<T>): T[] {
  const { key, order = 'asc' } = options

  const compareFunction = (a: T, b: T): number => {
    const aValue = a[key]
    const bValue = b[key]

    if (aValue < bValue) return order === 'asc' ? -1 : 1
    if (aValue > bValue) return order === 'asc' ? 1 : -1
    return 0
  }

  return [...arr].sort(compareFunction)
}
