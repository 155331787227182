import {
  DraftMatrixItem,
  DraftQuestionItem
} from '../../../data/gql-gen/questionnaire/graphql'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'

const useLoopSourceOptions = (sourceQuestionNumber: number | undefined) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()

  for (const entry of flattenedEntries) {
    if (entry.number !== sourceQuestionNumber) {
      continue
    }

    switch (entry.entryType) {
      case 'QuestionEntryType':
        return (entry.entryItem as DraftQuestionItem).responseOptions.map(
          ({ responseOption, media }) => ({
            id: responseOption?.responseOptionId,
            title: responseOption?.value,
            media
          })
        )
      case 'MatrixEntryType':
        return (entry.entryItem as DraftMatrixItem).matrixRows.map(
          ({ question, questionMedia }) => ({
            id: question?.questionId,
            title: question?.text,
            media: questionMedia
          })
        )
    }
  }

  return []
}

export default useLoopSourceOptions
