import { useReactiveVar } from '@apollo/client'
import {
  Dialog,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemQuestionnaireResponseOption,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMount, useUnmount } from 'react-use'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import { DraftMatrixRow } from '../../../../data/gql-gen/questionnaire/graphql'
import {
  MediaType,
  PositionTextSelection,
  QuestionSettingCode
} from '../../../../data/model/questionnaire'
import {
  IsNonManualUIChange,
  onChangePasteAcceptingInput
} from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useCopyPasteMatrix from '../../../../hooks/copyPaste/useCopyPasteMatrix'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import useQuestionnaireValidation, {
  isNonEmptyRowError
} from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { useDisplayLogicDetector } from '../../../../hooks/useDisplayLogicDetector.hooks'
import {
  addMatrixQuestionRowTransactionDatadog,
  deleteMatrixQuestionRowTransactionDatadog,
  pasteInMatrixQuestionRowTransactionDatadog
} from '../../../../tracking/perf/transactions'
import {
  isSettingEnabled,
  newEntryId
} from '../../../../utils/questionnaireUtils'
import {
  MaskingDialogContext,
  MatrixMaskingButton
} from '../../Masking/MaskingButton'
import RowMediaUploader from '../../MediaUploader/MediaUploader'
import {
  PinResponse,
  PinnedItemType,
  useSetPinnedMatrixRow
} from '../../PinResponse'
import {
  matrixRowImageRemoved,
  matrixRowImageSet,
  matrixRowPasted,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { useMatrixEntryContext } from '../MatrixQuestion.container'
import { useMatrixRowMediaActions } from './MatrixRowMedia.hooks'

interface Props {
  index: number
  matrixRow: DraftMatrixRow
  shouldAutoFocus: boolean
  rowCount: number
  matrixTitleLk: string
  maskingEnabled: boolean
  onEnter?: (position: number) => void
  onChangeMatrixQuestionRow: (inputValue: string, questionLk: string) => void
  onDeleteMatrixQuestionRow: (questionLk: string) => void
}

const MatrixQuestionRow: React.FC<Props> = (props: Props) => {
  const {
    index,
    matrixRow,
    shouldAutoFocus,
    rowCount,
    matrixTitleLk,
    maskingEnabled,
    onEnter,
    onChangeMatrixQuestionRow,
    onDeleteMatrixQuestionRow
  }: Props = props
  const entryItem = useMatrixEntryContext()
  const setPinnedMatrixRow = useSetPinnedMatrixRow(matrixTitleLk)
  const { validateMatrixRowText } = useQuestionnaireValidation()
  const [matrixRowText, setMatrixRowText] = useState<string>(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    matrixRow.question?.text ?? ''
  )
  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)
  const [isImageDialogOpened, setIsImageDialogOpened] = useState(false)
  const [isMaskingDialogOpen, setIsMaskingDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [positionSelectedText, setPositionSelectedText] = useState<
    PositionTextSelection | undefined
  >(undefined)
  const newlyAddedEntryId = useReactiveVar(newEntryId)
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false)
  const isAfterPaste = useReactiveVar<boolean>(IsNonManualUIChange)

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const getQPrefixForQsWithDisplayLogicBasedOnId = useDisplayLogicDetector()

  const questionsPrefix = useMemo(
    () => getQPrefixForQsWithDisplayLogicBasedOnId(matrixRow.questionLk),
    [matrixRow.questionLk, getQPrefixForQsWithDisplayLogicBasedOnId]
  )

  const hasTempId = /^questionLk-\d+$/.test(matrixRow.questionLk)
  const isEditable = matrixRow.createdDate !== '' && !hasTempId
  const questionnaireId = useDraftQuestionnaireIdCache()
  const { setMatrixRowMedia, removeMatrixRowMedia } =
    useMatrixRowMediaActions(matrixTitleLk)
  const dispatch = useAppDispatch()
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, matrixTitleLk)
    ) || []
  const { pasteToMatrixRow } = useCopyPasteMatrix(matrixTitleLk)

  const onPasteInMatrixRow: (
    text: string,
    matrixRow: DraftMatrixRow,
    positionSelectedText?: PositionTextSelection
  ) => void = useCallback(
    async (text, matrixRow, positionSelectedText) => {
      pasteInMatrixQuestionRowTransactionDatadog.start()
      const { position } = matrixRow
      questionBeingEditedId(matrixTitleLk)
      dispatch(
        matrixRowPasted({
          matrixTitleLk,
          textToPaste: text,
          positionSelectedText,
          position
        })
      )
      await pasteToMatrixRow(
        text,
        matrixTitleLk,
        matrixRow.position,
        positionSelectedText
      )
    },
    [dispatch, matrixTitleLk, pasteToMatrixRow]
  )

  const debounceDelayMs = 400

  const handleOnFocusedWithDelay: (delay?: number) => void = (
    delay = debounceDelayMs
  ) => {
    if (hasBeenFocused) return

    setTimeout(() => {
      setHasBeenFocused(true)
    }, delay)
  }

  useDebounceEffect<string | undefined>(
    () => {
      // when a new matrix is added all the row texts are undefined. Don't update text in this case
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (matrixRowText === undefined) {
        return
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!matrixRow.question || matrixRowText !== matrixRow.question.text) {
        onChangeMatrixQuestionRow(matrixRowText, matrixRow.questionLk)
        handleOnFocusedWithDelay(100)
      }
    },
    matrixRowText,
    { delay: debounceDelayMs, leading: true }
  )

  useEffect(() => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isAfterPaste && matrixRow.question) {
      setMatrixRowText(matrixRow.question.text)
    }
  }, [matrixRow.question, isAfterPaste])

  useEffect(() => {
    setPendingDeletionPosition(undefined)
  }, [rowCount])

  useMount(() => {
    addMatrixQuestionRowTransactionDatadog.end()
    pasteInMatrixQuestionRowTransactionDatadog.end()
  })

  useUnmount(() => {
    deleteMatrixQuestionRowTransactionDatadog.end()
  })

  const { hasError, errorMessage } = validateMatrixRowText(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem?.matrixTitleLk,
    matrixRow.questionLk
  )

  const placeHolderTextMap = new Map<number, string>([
    [0, 'Nelson Mandela'],
    [1, 'Angela Merkel']
  ])

  if (matrixRow.position === pendingDeletionPosition) {
    return null
  }

  const isRandomiseOn = isSettingEnabled(
    settingValues,
    QuestionSettingCode.MatrixRandomiseRows
  )

  const { maskingRules, forks } = matrixRow

  const handleSelectedMatrixRow = (
    selectionStart?: number | null,
    selectionEnd?: number | null
  ) => {
    setPositionSelectedText({
      startPositionSelectedText: selectionStart,
      endPositionSelectedText: selectionEnd
    })
  }

  const deleteRow = () => {
    IsNonManualUIChange(true)
    setPendingDeletionPosition(matrixRow.position)
    onDeleteMatrixQuestionRow(matrixRow.questionLk)
  }

  const handleDelete = () => {
    if (questionsPrefix.length > 0) {
      setIsDeleteDialogOpen(true)
    } else {
      deleteRow()
    }
  }

  const handleDeleteDialogConfirm = () => {
    setIsDeleteDialogOpen(false)
    deleteRow()
  }

  const handlePinResponseOption = () => {
    setPinnedMatrixRow(entryItem.matrixTitleLk, matrixRow.questionLk, true)
  }

  const handleOpenMaskingDialog = () => {
    setIsMaskingDialogOpen(true)
  }

  const responseActions: KebabMenuOption[] = [
    {
      id: 2,
      textItem: 'Add display logic',
      iconName: IconName.Visibility,
      disabled:
        // OR if this question is the first one and there are no other questions before it
        !maskingEnabled ||
        maskingRules.length > 0 ||
        (!!forks && forks.length > 0),
      onClickItem: handleOpenMaskingDialog
    },
    {
      id: 3,
      textItem: 'Pin response',
      disabled: matrixRow.pinned || !isRandomiseOn,
      iconName: IconName.PushPin,
      onClickItem: handlePinResponseOption
    },
    {
      id: 4,
      textItem: 'Add image',
      disabled: !!matrixRow.questionMedia,
      iconName: IconName.Image,
      onClickItem: () => setIsImageDialogOpened(true)
    }
  ]

  const handleMediaUpload = async (
    questionnaireId: string,
    matrixTitleLk: string,
    questionLk: string,
    mediaUrl: string,
    mediaName: string,
    mediaType: MediaType,
    renderedMediaUrl: string
  ) => {
    dispatch(
      matrixRowImageSet({
        matrixTitleLk,
        questionLk,
        mediaName,
        mediaUrl,
        renderedMediaUrl
      })
    )
    await setMatrixRowMedia(questionLk, {
      mediaUrl,
      mediaName,
      mediaType,
      renderedMediaUrl
    })
  }

  const handleRemoveMedia = async (
    matrixTitleLk: string,
    questionLk: string
  ) => {
    dispatch(
      matrixRowImageRemoved({
        matrixTitleLk,
        questionLk
      })
    )
    await removeMatrixRowMedia(questionLk)
  }

  const closeDeleteResponseDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  let deleteDialogText = ''
  if (questionsPrefix.length > 0) {
    deleteDialogText = `Deleting this row will also delete the display logic related to this row at ${questionsPrefix.join(
      ', '
    )}.`
  }

  const isNewlyAddedEntryId = newlyAddedEntryId === matrixTitleLk
  const canShowError =
    hasError &&
    (hasBeenFocused || !isNewlyAddedEntryId || isNonEmptyRowError(errorMessage))

  return (
    <>
      <ListItemQuestionnaireResponseOption
        responseOptionId={matrixRow.questionLk}
        responseActionsMenu={
          responseActions.length > 0 ? (
            <KebabMenu
              kebabMenuOptions={responseActions}
              horizontal
              iconPosition={KebabMenuIconPosition.Left}
              iconColor={IconColor.Background}
              tooltipText="Configure this response option"
            />
          ) : undefined
        }
        ariaLabel="Matrix row"
        mediaButton={
          <RowMediaUploader
            questionnaireId={questionnaireId}
            questionLk={entryItem.matrixTitleLk}
            responseOptionLk={matrixRow.questionLk}
            mediaUrl={matrixRow.questionMedia?.mediaUrl}
            renderedMediaUrl={matrixRow.questionMedia?.renderedMediaUrl}
            mediaType={matrixRow.questionMedia?.mediaType}
            imageDialogOpened={isImageDialogOpened}
            onDialogClose={() => setIsImageDialogOpened(false)}
            onMediaUpload={handleMediaUpload}
            onRemoveMedia={handleRemoveMedia}
          />
        }
        maskingIconButton={
          <MaskingDialogContext.Provider
            value={{
              isDialogOpen: isMaskingDialogOpen,
              setIsDialogOpen: setIsMaskingDialogOpen
            }}
          >
            <MatrixMaskingButton
              matrixTitleLk={entryItem.matrixTitleLk}
              questionLk={matrixRow.questionLk}
            />
          </MaskingDialogContext.Provider>
        }
        index={matrixRow.position}
        onFocus={() => {
          questionBeingEditedId(matrixTitleLk)
        }}
        onBlur={() => {
          setHasBeenFocused(true)
        }}
        onEnter={() => {
          if (onEnter) {
            onEnter(matrixRow.position)
          }
        }}
        pinningIconButton={
          matrixRow.pinned && isRandomiseOn ? (
            <PinResponse
              entryId={matrixTitleLk}
              questionLk={matrixRow.questionLk}
              itemType={PinnedItemType.MatrixRow}
              isPinned={matrixRow.pinned}
            />
          ) : undefined
        }
        isError={canShowError}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={shouldAutoFocus}
        helperText={canShowError ? errorMessage : ''}
        key={matrixRow.questionLk}
        editable={isEditable}
        cannotDelete={rowCount <= 2}
        onDelete={handleDelete}
        disableGutters
        draggableId={`dr${matrixRow.questionLk}`}
        canBeReordered
        value={matrixRowText}
        placeholder={placeHolderTextMap.get(index) || 'Type a row title'}
        onChange={(value: string) => {
          onChangePasteAcceptingInput(() => setMatrixRowText(value))
        }}
        onPaste={(value) => {
          IsNonManualUIChange(true)
          onPasteInMatrixRow(value, matrixRow, positionSelectedText)
        }}
        onSelect={handleSelectedMatrixRow}
      />
      <Dialog
        title="Delete this row?"
        open={isDeleteDialogOpen}
        onClose={closeDeleteResponseDialog}
        primaryButtonText="Delete row"
        primaryButtonClick={handleDeleteDialogConfirm}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold
          )}
        >
          {deleteDialogText}
        </p>
      </Dialog>
    </>
  )
}

export default MatrixQuestionRow
