import gql from 'graphql-tag'
import { SurveyOrder } from '../../../model/order'

export interface CreateSurveyOrderData {
  createSurveyOrder: SurveyOrder
  createSurveyOrderV2: SurveyOrder
}

export const CREATE_SURVEY_ORDER = gql`
  mutation createSurveyOrder(
    $surveyId: ID!
    $feasibility: Float!
    $customerCPI: Float!
    $panelSupplierCode: String!
    $baseCPI: Float
  ) {
    createSurveyOrder(
      panelSupplierCode: $panelSupplierCode
      surveyId: $surveyId
      feasibility: $feasibility
      customerCPI: $customerCPI
      baseCPI: $baseCPI
    ) {
      surveyId
      customerCPI
      status
      feasibility
      panelSupplierOrders {
        orderId
        panelSupplierCode
        panelSupplierOrderId {
          value
          idType
          panelSupplierCode
        }
        status
        cpi
        orderNumber
        createdDate
        sinceDate
      }
      orderType
      createdDate
      sinceDate
    }
  }
`

export const CREATE_SURVEY_ORDER_V2 = gql`
  mutation createSurveyOrderV2(
    $surveyId: ID!
    $feasibility: Float!
    $customerCPI: Float!
    $panelSupplierCode: String!
    $baseCPI: Float
  ) {
    createSurveyOrderV2(
      panelSupplierCode: $panelSupplierCode
      surveyId: $surveyId
      feasibility: $feasibility
      customerCPI: $customerCPI
      baseCPI: $baseCPI
    ) {
      surveyId
      customerCPI
      status
      feasibility
      panelSupplierOrders {
        orderId
        panelSupplierCode
        panelSupplierOrderId {
          value
          idType
          panelSupplierCode
        }
        status
        cpi
        orderNumber
        createdDate
        sinceDate
      }
      orderType
      createdDate
      sinceDate
    }
  }
`
