import { useParams } from 'react-router-dom'
import { SurveyParams } from '../data/model/surveyParams'

export const useSurveyId = () => {
  const { surveyId } = useParams<keyof SurveyParams>()

  if (!surveyId) {
    throw new Error('Missing survey ID required for route')
  }

  return surveyId
}
