import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { GetDraftCombinedQuestionQuery } from '../../../../../../data/gql-gen/local/graphql'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const deleteMappingMarketQuestion: Resolver = (
  _,
  { id }: { id: string },
  { cache }: { cache: InMemoryCache }
) => {
  cache.updateQuery(
    {
      query: GET_DRAFT_COMBINED_QUESTION
    },
    (
      data: GetDraftCombinedQuestionQuery | null
    ): GetDraftCombinedQuestionQuery | void => {
      if (!data) {
        throw new Error('No draft combined question found in cache')
      }
      return produce(data, ({ draftCombinedQuestion }) => {
        const { marketQuestions } = draftCombinedQuestion
        draftCombinedQuestion.marketQuestions = marketQuestions.filter(
          (marketQuestion) => marketQuestion.id !== id
        )
      })
    }
  )

  return id
}
