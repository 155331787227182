import {
  IconColor,
  KebabMenu,
  KebabMenuOption
} from '@focaldata/cin-ui-components'

interface Props {
  actionMenuOptions: KebabMenuOption[]
}

export const ActionsMenu = ({ actionMenuOptions }: Props): JSX.Element => {
  return (
    <>
      {/* shift kebab menu outside parent element so that 3 dots icon (instead of right edge of the button) will be aligned with the elements above */}
      <div style={{ marginRight: -14 }}>
        <KebabMenu
          kebabMenuOptions={actionMenuOptions}
          iconColor={IconColor.Background}
        />
      </div>
    </>
  )
}
