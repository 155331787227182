import { theme } from '@focaldata/cin-ui-components'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ChartDataType } from '../../FdChat.model'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const backgroundColours = [
  theme.palette.primary.main,
  theme.palette.primary.light,
  theme.palette.primary.dark
]

const BarChart = ({
  title,
  xLabel,
  yLabel,
  stacked,
  labels,
  datasets
}: ChartDataType) => (
  <Bar
    options={{
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: title,
          padding: {
            bottom: 20
          }
        },
        legend: {
          display: datasets.length > 1
        },
        tooltip: {
          callbacks: {
            title: (tooltTipItems) => {
              return labels[tooltTipItems[0].dataIndex]
            }
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: xLabel,
            padding: {
              top: 10
            }
          },
          stacked,
          grid: {
            display: false
          }
        },
        y: {
          title: {
            display: true,
            text: yLabel,
            padding: {
              bottom: 10
            }
          },
          stacked,
          grid: {
            display: false
          }
        }
      }
    }}
    data={{
      // splits labels into array of words max 10 chars so that that words appear under one another if too long
      labels: labels.map((label) => {
        const substringMaxLength = 10
        const regex = new RegExp(
          `\\b\\w(?:[\\w\\s]{${substringMaxLength - 1},}?(?=\\s)|.*$)`,
          'g'
        )
        return label.match(regex)
      }),
      datasets: datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: backgroundColours[index]
      }))
    }}
  />
)

export default BarChart
