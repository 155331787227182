import {
  Dialog,
  Grid,
  RadioGroup,
  makeStyles,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { MapAudienceQuestionCard } from '../MapAudienceQuestionCard'

const useStyles = makeStyles()(() => ({
  contentContainer: { display: 'flex', flexDirection: 'column' }
}))

export enum MappingOption {
  NewCombinedQuestion = 'new_combined_question',
  ExistingCombinedQuestion = 'existing_combined_question'
}

export const MapAudienceDialog = ({
  open,
  combinedQuestions,
  onClose,
  onConfirmMapping
}: {
  open: boolean
  combinedQuestions: {
    id: string
    position: number
    question: string
  }[]
  onClose: () => void
  onConfirmMapping: (mappingOption: MappingOption, questionId?: string) => void
}) => {
  const [selectedMappingOption, setSelectedMappingOption] =
    useState<MappingOption>(MappingOption.NewCombinedQuestion)
  const [selectedCombinedQuestion, setSelectedCombinedQuestion] = useState<
    string | undefined
  >(undefined)

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const handleChangeMappingOption = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as MappingOption

    setSelectedMappingOption(value)

    if (value === MappingOption.NewCombinedQuestion) {
      setSelectedCombinedQuestion(undefined)
    }
  }

  const handleSelectCombinedQuestion = (id: string) => (selected: boolean) => {
    if (selected) {
      setSelectedCombinedQuestion(id)
    }
  }

  const createNewCombinedQuestionSelected =
    selectedMappingOption === MappingOption.NewCombinedQuestion

  const confirmButtonDisabled =
    !createNewCombinedQuestionSelected && !selectedCombinedQuestion

  const handleConfirmMapping = () => {
    onConfirmMapping(selectedMappingOption, selectedCombinedQuestion)
  }

  return (
    <Dialog
      title="Mapping"
      fullWidth
      primaryButtonText="Confirm"
      primaryButtonDisabled={confirmButtonDisabled}
      open={open}
      contentContainerClassName={classes.contentContainer}
      onClose={onClose}
      primaryButtonClick={handleConfirmMapping}
    >
      <RadioGroup
        value={selectedMappingOption}
        onChange={handleChangeMappingOption}
        items={[
          {
            label: (
              <span
                className={classNames(
                  textClasses.default,
                  textClasses.weightSemiBold
                )}
              >
                Create new combined question
              </span>
            ),
            value: MappingOption.NewCombinedQuestion
          },
          {
            label: (
              <span
                className={classNames(
                  textClasses.default,
                  textClasses.weightSemiBold
                )}
              >
                Map to existing combined question
              </span>
            ),
            value: MappingOption.ExistingCombinedQuestion
          }
        ]}
      />
      <Grid
        container
        sx={{
          marginTop: 1,
          marginLeft: 3.75,
          maxWidth: 'fit-content'
        }}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            {
              [textClasses.highlightBackground]:
                createNewCombinedQuestionSelected,
              [textClasses.highlightStandard]:
                !createNewCombinedQuestionSelected
            }
          )}
        >
          Select custom screening question
        </p>
      </Grid>
      <Grid sx={{ overflow: 'auto', paddingY: 0.375, paddingX: 3.75 }} item>
        {combinedQuestions.map(({ question, id, position }) => {
          return (
            <Grid key={id} sx={{ marginY: 3 }}>
              <MapAudienceQuestionCard
                variant="radio"
                selected={selectedCombinedQuestion === id}
                disabled={createNewCombinedQuestionSelected}
                header={`A${position} Combined`}
                question={question}
                onSelect={handleSelectCombinedQuestion(id)}
              />
            </Grid>
          )
        })}
      </Grid>
    </Dialog>
  )
}
