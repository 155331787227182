import { useFragment } from '@apollo/client'
import { IconColor, IconName, KebabMenu } from '@focaldata/cin-ui-components'
import { useContext } from 'react'
import { LoggerErrorType } from '../../../data/logger'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { captureApolloError } from '../../../utils/HelperFunctions'
import QuestionnaireContext, {
  openMatrixScreeningDialog
} from '../Questionnaire.context'
import { MatrixScreeningMenu_DraftMatrixItemFragment } from './MatrixScreeningMenu.questionnaire.gql'

enum MatrixScreeningMenuOptions {
  EDIT_SCREENING,
  REMOVE_SCREENING
}

export const MatrixScreeningMenu = ({
  matrixTitleLk
}: {
  matrixTitleLk: string
}) => {
  const fragment = useFragment({
    fragment: MatrixScreeningMenu_DraftMatrixItemFragment,
    fragmentName: 'MatrixScreeningMenu_DraftMatrixItemFragment',
    from: {
      __typename: 'DraftMatrixItem',
      matrixTitleLk
    },
    canonizeResults: true
  })

  const matrixItem = fragment.complete ? fragment.data : null

  const { dispatch } = useContext(QuestionnaireContext)

  const { updateQuestionnaireEntry, loading } = useUpdateQuestionnaireEntry(
    matrixTitleLk,
    {
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'removeMatrixQualification',
          error
        )
      }
    }
  )

  if (!matrixItem) {
    throw new Error('Cannot get matrix item')
  }

  if (!matrixItem.matrixQualification) {
    return null
  }

  return (
    <div style={{ marginRight: 10, marginBottom: -2 }}>
      <KebabMenu
        kebabMenuOptions={[
          {
            id: MatrixScreeningMenuOptions.EDIT_SCREENING,
            textItem: 'Edit screening',
            disabled: loading,
            onClickItem() {
              dispatch(openMatrixScreeningDialog(matrixItem.matrixTitleLk))
            }
          },
          {
            id: MatrixScreeningMenuOptions.REMOVE_SCREENING,
            textItem: 'Remove screening',
            disabled: loading,
            async onClickItem() {
              await updateQuestionnaireEntry({
                updateMatrixQuestion: {
                  matrixQualification: {
                    value: null
                  }
                }
              })
            }
          }
        ]}
        disabled={loading}
        customIcon={IconName.FilterAltOutlined}
        iconColor={IconColor.Green}
      />
    </div>
  )
}
