import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { CompleteOrderCacheUpdate } from '../../../apollo/cacheModify/Project/endOrder'
import { CompleteProjectCacheUpdate } from '../../../apollo/cacheModify/Project/endProject'
import DialogCloseProject from '../../../controls/DialogEndProject'
import {
  Project,
  SurveyOrderStatus,
  SurveyState
} from '../../../data/gql-gen/dashboard/graphql'
import { UpdateSurveyOrderMutationVariables } from '../../../data/gql-gen/dashboard/graphql'
import {
  UPDATE_SURVEY_ORDER,
  UpdateSurveyOrderData
} from '../../../data/gql/order/mutations/updateSurveyOrder'
import { LoggerErrorType } from '../../../data/logger'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  captureApolloError,
  getResultsPath
} from '../../../utils/HelperFunctions'
import { useUpdateSurvey } from '../../Survey/Survey.hooks'

interface Props {
  isOpen: boolean
  toggleIsOpen: (open: boolean) => void
  selectedProject?: Project
}

const EndProject = ({ isOpen, toggleIsOpen, selectedProject }: Props) => {
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const { updateSurvey } = useUpdateSurvey()

  const handleCompleteProject: () => void = () => {
    if (selectedProject) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const surveyId = selectedProject?.surveys[0].surveyId
      updateSurvey({
        variables: {
          surveyId,
          state: SurveyState.Completed
        },
        update: (cache) => {
          CompleteProjectCacheUpdate(cache, selectedProject)
        }
      })
    }
    toggleIsOpen(false)
  }

  const [updateSurveyOrder] = useMutation<
    UpdateSurveyOrderData,
    UpdateSurveyOrderMutationVariables
  >(UPDATE_SURVEY_ORDER, {
    context: { clientName: 'dashboard' },
    onCompleted: () => {
      handleCompleteProject()
      navigate(getResultsPath(surveyId))
    },
    update: (cache) => {
      CompleteOrderCacheUpdate(cache, selectedProject)
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateSurveyOrder',
        error
      )
    }
  })

  const handleCompleteOrder: () => void = () => {
    if (selectedProject) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const surveyId = selectedProject?.surveys[0].surveyId
      updateSurveyOrder({
        variables: {
          surveyId,
          orderStatus: SurveyOrderStatus.Completed
        }
      })
    }
    toggleIsOpen(false)
  }

  return (
    <DialogCloseProject
      isOpen={isOpen}
      toggleOpenDialog={toggleIsOpen}
      onEndProject={handleCompleteOrder}
    />
  )
}

export default EndProject
