import exceljs from 'exceljs'
import { saveAs } from 'file-saver'
// eslint-disable-next-line no-restricted-imports
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import {
  BarChartData,
  MeanChartData,
  WordCloudChartData
} from '../components/Chart'
import {
  ChartEntry,
  QuestionKind,
  QuestionType,
  Row,
  RowItem
} from '../data/model/chart'
import { formatThousandsWithCommas } from './HelperFunctions'
import {
  formatMatrixDataset,
  formatMatrixMultiSelectDataset,
  formatRankedDataset,
  getDisplayNameFromQuestionType,
  safeDivision
} from './chartTypeUtils'
import { chain } from './lodashChain'
import { matrixHasDifferentRowCounts } from './resultsUtils'

export const LABEL_IDEAL_LENGTH = 35

export const addNumberSeperators = (number: number | undefined): string => {
  if (!number) {
    return '0'
  }

  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const convertEntryDataOrFilteredRowsToBarChartData: (
  chartEntry: ChartEntry,
  filteredRows?: Row[]
) => BarChartData = (chartEntry, filteredRows) => {
  // check if rows is not undefined
  const rows = filteredRows || chartEntry.rows
  // then we extract datasets
  // then we sort them
  // then we set the labels from the row text
  const rowItems: Omit<RowItem, 'id' | 'position'>[] = rows.map((row: Row) => {
    const rowItem = row.rowItems.find((rowItem) => rowItem.text === 'Selected')
    return { completes: rowItem?.completes ?? 0, text: row.text }
  })

  if (chartEntry.hasRandomisedOptions) {
    rowItems.sort((a, b) => b.completes - a.completes)
  }

  return {
    labels: rowItems.map((row) => row.text),
    totalSamples: chartEntry.samplesCollectedFiltered,
    datasets: [
      {
        data: rowItems.map((row) =>
          safeDivision(row.completes, chartEntry.samplesCollectedFiltered)
        )
      }
    ]
  }
}

export const convertRankedEntryDataToStackedChartData: (
  entry: ChartEntry
) => BarChartData = (entry) => {
  const { formattedDataset, nonDefaultOptionsRows } = formatRankedDataset(entry)
  return {
    totalSamples: entry.samplesCollectedFiltered,
    labels: nonDefaultOptionsRows.map((row: Row) => row.text),
    datasets: formattedDataset
  }
}

export const convertRankedEntryDataDefaultOptionsToBarChartData: (
  chartEntry: ChartEntry
) => BarChartData = (chartEntry) => {
  // first we extract default option rows only
  const defaultOptionsRows = chartEntry.rows.filter((row) => {
    return row.rowItems.some((ri) => {
      return Number.isNaN(parseInt(ri.text, 10))
    })
  })

  return convertEntryDataOrFilteredRowsToBarChartData(
    chartEntry,
    defaultOptionsRows
  )
}

export const convertMatrixEntryDataToStackedChartData: (
  entry: ChartEntry
) => BarChartData = (entry) => {
  const { formattedDataset, samplesCollectedPerRow } =
    formatMatrixDataset(entry)

  return {
    completesPerRow: samplesCollectedPerRow,
    totalSamples: entry.samplesCollectedFiltered,
    labels: entry.rows.map((row: Row) => row.text),
    datasets: formattedDataset
  }
}

export const convertEntryDataToGroupedChartData: (
  entry: ChartEntry
) => BarChartData = (entry) => {
  const hasDifferentRowCounts = matrixHasDifferentRowCounts(entry)
  const samplesCollectedPerRow = entry.rows.map(
    (row) => row.samplesCollectedFiltered
  )
  const { formattedDataset } = formatMatrixMultiSelectDataset(entry)

  return {
    completesPerRow: samplesCollectedPerRow,
    totalSamples: entry.samplesCollectedFiltered,
    labels: entry.rows.map((row: Row) => {
      const rowLabel = [row.text]

      if (hasDifferentRowCounts) {
        const respondentsCount = `${formatThousandsWithCommas(
          Math.round(row.samplesCollectedFiltered)
        )} respondents`
        rowLabel.unshift(respondentsCount)
      }
      return rowLabel
    }),
    datasets: formattedDataset
  }
}

export const convertEntryDataToWordCloudChartData: (
  entry: ChartEntry
) => WordCloudChartData = (entry) => {
  const words = entry.rows.map((row: Row) => {
    const rowItem = row.rowItems.find((rowItem) => rowItem.text === 'Count')
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return { text: row?.text, value: rowItem?.completes ?? 0 }
  })

  return {
    words
  }
}

export const convertEntryDataToMeanChartData = (
  entry: ChartEntry
): MeanChartData => {
  const stringMean = (
    Math.round(parseFloat(entry.rows[0].text) * 100) / 100
  ).toString()
  return {
    mean: stringMean,
    scale: entry.scaleInfo
  }
}

const getPercentFromCompletes = (
  completes: number,
  samplesCollected: number
): number => {
  return Math.round(safeDivision(completes, samplesCollected) * 100)
}

type ChartDataRow = (string | number)[]

const getQuestionChartDataRowsBasic = (
  chartEntry: ChartEntry
): ChartDataRow[] => {
  return chartEntry.rows.map((response) => {
    const selected = response.rowItems.find((ri) => ri.text === 'Selected')
    const completes = selected?.completes || 0
    const questionPrefix =
      chartEntry.questionKind === QuestionKind.Audience ? 'A' : 'Q'

    return [
      `${questionPrefix}${chartEntry.contextPosition}`,
      chartEntry.text,
      getDisplayNameFromQuestionType(chartEntry),
      response.text,
      'Selected',
      completes,
      chartEntry.samplesCollectedFiltered,
      getPercentFromCompletes(completes, chartEntry.samplesCollectedFiltered)
    ]
  })
}

const getQuestionChartDataRowsRanked = (
  chartEntry: ChartEntry
): ChartDataRow[] => {
  const allRanks = chartEntry.rows.flatMap((r) =>
    r.rowItems.map((ri) => ri.text)
  )
  const allDistinctRanks = uniq(allRanks)
  const allSortedDistinctRanks = sortBy(allDistinctRanks, (label) =>
    Number(label)
  )

  return chartEntry.rows.flatMap((response) => {
    return chain(allSortedDistinctRanks)
      .map((rank) => {
        // on each rank, find the completes in the response and return
        const responseCategory = response.rowItems.find(
          (ri) => ri.text === rank
        )
        const completes = responseCategory?.completes || 0
        return [
          `Q${chartEntry.contextPosition}`,
          chartEntry.text,
          getDisplayNameFromQuestionType(chartEntry),
          response.text,
          rank,
          completes,
          chartEntry.samplesCollectedFiltered,
          getPercentFromCompletes(
            completes,
            chartEntry.samplesCollectedFiltered
          )
        ]
      })
      .value()
  })
}

const getQuestionChartDataRowsMatrix = (
  chartEntry: ChartEntry
): ChartDataRow[] => {
  return chartEntry.rows.flatMap((response) => {
    return chain(response.rowItems)
      .map((responseCategory) => {
        return [
          `Q${chartEntry.contextPosition}`,
          chartEntry.text,
          getDisplayNameFromQuestionType(chartEntry),
          response.text,
          responseCategory.text,
          responseCategory.completes,
          chartEntry.samplesCollectedFiltered,
          getPercentFromCompletes(
            responseCategory.completes,
            chartEntry.samplesCollectedFiltered
          )
        ]
      })
      .filter((row: ChartDataRow) => row[3] !== 'NotSelected')
      .value()
  })
}

const getQuestionChartDataRows = (chartEntry: ChartEntry): ChartDataRow[] => {
  if (chartEntry.questionType === QuestionType.ranked) {
    return getQuestionChartDataRowsRanked(chartEntry)
  }

  if (chartEntry.questionType === QuestionType.matrix) {
    return getQuestionChartDataRowsMatrix(chartEntry)
  }

  // QuestionType.basic, QuestionType.custom_audience, QuestionType.standard_audience
  return getQuestionChartDataRowsBasic(chartEntry)
}

export const downloadXlsxFile = async ({
  data,
  fileName
}: {
  data: ChartEntry[]
  fileName: string
}): Promise<void> => {
  const wb = new exceljs.Workbook()
  const sheet = wb.addWorksheet()

  sheet.columns = [
    {
      header: 'question number',
      key: 'question number',
      width: 15
    },
    {
      header: 'question',
      key: 'question',
      width: 30
    },
    {
      header: 'question type',
      key: 'question type',
      width: 30
    },
    {
      header: 'response text',
      key: 'response text',
      width: 30
    },
    {
      header: 'response category',
      key: 'response category',
      width: 15
    },
    {
      header: 'count',
      key: 'count',
      width: 15
    },
    {
      header: 'total',
      key: 'question',
      width: 15
    },
    {
      header: 'percent',
      key: 'percent',
      width: 15
    }
  ]

  sheet.addRows(
    data
      .filter(
        // exclude non-graphical questions, e.g. slider, text
        ({ questionType }) =>
          ![QuestionType.free_text, QuestionType.scale].includes(questionType)
      )
      .flatMap(getQuestionChartDataRows)
  )

  const buffer = await wb.xlsx.writeBuffer()

  saveAs(
    new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }),
    fileName
  )
}
export const pluralise = (count: number, word: string, plural = `${word}s`) =>
  `${count !== 1 ? plural : word}`

export const getChartLongestLabelsLength: (
  labels: (string | string[])[]
) => number = (labels) => Math.max(...labels.map((el) => el.length))
