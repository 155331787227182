import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { isFocaldataUser, isUserWithEmail } from '../../../utils/authUtils'
import QuestionCardSwitches from '../QuestionCardSwitches'

export const MatrixQuestionRowsSwitches = ({
  entry
}: {
  entry: DraftQuestionnaireEntry<EntryType.MatrixEntryType>
}) => {
  return (
    <QuestionCardSwitches
      entry={entry}
      randomiseQuestionSettingCode={QuestionSettingCode.MatrixRandomiseRows}
      hasMobileViewOnlySwitch={
        isFocaldataUser() ||
        isUserWithEmail('Josephine.Lichteblau@fgsglobal.com') ||
        isUserWithEmail('TMiller@forbes-tate.com') ||
        isUserWithEmail('DUsher@forbes-tate.com') ||
        isUserWithEmail('felix.schramm@fgsglobal.com')
      }
      title="ROWS"
    />
  )
}
