import { AlignInputText, Input } from '@focaldata/cin-ui-components'
import { ChangeEvent, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import {
  getInvalidRangeError,
  getIsIntervalValid,
  getIsRangeLimitValid
} from './intervalInputUtils'

interface Props {
  min?: number
  max?: number
  rangeLimit?: number
  invalidRangeErrorMessage?: string
  onIntervalChanged?: (min: number, max: number) => void
  onError?: (error?: string) => void
}

const NumberFormatCustom = (props: any) => {
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      allowNegative={false}
      isNumericString
      allowedDecimalSeparators={[',', '.']}
    />
  )
}

const IntervalInput: React.FC<Props> = (props: Props) => {
  const {
    min = 0,
    max,
    rangeLimit,
    invalidRangeErrorMessage,
    onIntervalChanged,
    onError
  } = props
  const [intervalMin, setMin] = useState<number>(min)
  const [intervalMax, setMax] = useState<number | undefined>(max)
  const [isIntervalValid, setIsIntervalValid] = useState<boolean>(true)
  const [isRangeLimitValid, setIsRangeLimitValid] = useState<boolean>(true)
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isRangeDefined = intervalMin !== undefined && intervalMax !== undefined

  const handleIntervalChanged = (min: number, max: number) => {
    const isIntervalValid = getIsIntervalValid(min, max)

    const isRangeLimitValid = getIsRangeLimitValid(min, max, rangeLimit)

    setIsRangeLimitValid(isRangeLimitValid)
    setIsIntervalValid(isIntervalValid)
    if (isIntervalValid && isRangeLimitValid) {
      onIntervalChanged?.(min, max)
    }
    if (isRangeDefined)
      onError?.(
        getInvalidRangeError(min, max, rangeLimit, invalidRangeErrorMessage)
      )
  }

  useEffect(() => {
    setMin(min)
  }, [min])

  useEffect(() => {
    setMax(max)
  }, [max])

  const onMinChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newMin = Number.isNaN(event.target.value)
      ? undefined
      : Number(event.target.value)

    if (newMin !== undefined) {
      handleIntervalChanged(newMin, intervalMax || 0)
      setMin(newMin)
    }
  }

  const onMaxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newMax = Number.isNaN(event.target.value)
      ? undefined
      : Number(event.target.value)

    if (newMax !== undefined) {
      handleIntervalChanged(intervalMin, newMax)
      setMax(newMax)
    }
  }
  const isError = !(isIntervalValid && isRangeLimitValid) && isRangeDefined

  return (
    <div className="fd-grid fd-container fd-justify-content-end fd-spacing-1 fd-row">
      <div className="fd-grid fd-item">
        <Input
          ariaLabel="interval-min"
          isError={isError}
          placeholder="Min"
          onChange={onMinChanged}
          value={intervalMin}
          dataCy="interval-min"
          customFormat={NumberFormatCustom}
          width={60}
          supressHelperText
          alignInputText={AlignInputText.Center}
        />
      </div>
      <span className="fd-grid fd-display-flex fd-item fd-align-items-center fd-justify-content-center">
        -
      </span>
      <div className="fd-grid fd-item">
        <Input
          ariaLabel="interval-max"
          isError={isError}
          placeholder="Max"
          onChange={onMaxChanged}
          value={intervalMax}
          dataCy="interval-max"
          customFormat={NumberFormatCustom}
          width={60}
          supressHelperText
          alignInputText={AlignInputText.Center}
        />
      </div>
    </div>
  )
}

export default IntervalInput
