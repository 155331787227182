import {
  Menu,
  MenuOption,
  MenuVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import { CHOICE_TYPE_MENU_BUTTON } from '../BasicQuestion/constants'
import useStyles from './IdContent.styles'

export type QuestionTypeString =
  | 'Single select'
  | 'Multi select'
  | 'Ranked'
  | 'Slider'
  | 'Free text'
  | 'Matrix'
  | 'Text'
  | ''
  | 'Section'
  | 'Max Diff'

interface Props {
  hasError: boolean
  letterPrefix: string
  isBasicQuestion: boolean
  typeName: QuestionTypeString | undefined
  simpleMenu: MenuOption[]
  contextPosition: number
}

const IdContent: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const {
    hasError,
    letterPrefix,
    isBasicQuestion,
    typeName,
    simpleMenu,
    contextPosition
  } = props

  const contextPositionValue = contextPosition + 1

  return (
    <>
      <p
        className={classNames(
          classes.id,
          textClasses.default,
          textClasses.sizeMs,
          textClasses.weightBold,
          hasError
            ? textClasses.highlightError
            : textClasses.highlightBackground
        )}
      >
        {letterPrefix}
        {contextPositionValue}
      </p>
      <div className={classNames(classes.questionType)}>
        {isBasicQuestion && (
          <Menu
            dataTestid={CHOICE_TYPE_MENU_BUTTON}
            menuOptions={simpleMenu}
            title={typeName || ''}
            titleWidth={90}
            variant={MenuVariant.QuestionType}
          />
        )}
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.marginRight,
            hasError
              ? textClasses.highlightError
              : textClasses.highlightBackground
          )}
        >
          {!isBasicQuestion && typeName}
        </p>
      </div>
    </>
  )
}

export default IdContent
