import {
  Grid,
  IconName,
  List,
  ScrollElement
} from '@focaldata/cin-ui-components'
import React from 'react'
import { EmptyState } from '../../components/EmptyState'
import {
  CUSTOM_QUESTION_KEY,
  SCROLL_ID_PREFIX
} from '../../containers/Page/Survey/Complete/constants'
import { ChartEntry, QuestionKind } from '../../data/model/chart'
import BasicQuestionChartCard from '../BasicQuestionChartCard'
import CompleteChartListLoading from './loading'
import useStyles from './styles'

interface Props {
  chartData: ChartEntry[]
  isLoading: boolean
  showAudienceCriteriaQuestions: boolean
}

const CompleteChartList: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { chartData, isLoading, showAudienceCriteriaQuestions } = props

  if (isLoading) {
    return <CompleteChartListLoading />
  }

  const audienceChartData = chartData.filter(
    (chartEntry) => chartEntry.questionKind === QuestionKind.Audience
  )

  const questionnaireChartData = chartData.slice(audienceChartData.length)
  const emptyState = questionnaireChartData.length === 0 &&
    audienceChartData.length === 0 && (
      <Grid className={classes.emptyState}>
        <EmptyState
          iconName={IconName.BarChart}
          title="Your results will appear here."
          hasElevation
        />
      </Grid>
    )

  return (
    <>
      <List>
        {showAudienceCriteriaQuestions &&
          audienceChartData.map((chartEntry) => {
            return (
              <ScrollElement
                elementId={`${SCROLL_ID_PREFIX}${chartEntry.id}${CUSTOM_QUESTION_KEY}`}
                key={chartEntry.entryPosition}
              >
                <BasicQuestionChartCard
                  key={chartEntry.entryPosition}
                  positionLabel={`A${chartEntry.contextPosition}`}
                  chartTitle={chartEntry.text}
                  completes={chartEntry.samplesCollectedFiltered}
                  entry={chartEntry}
                />
              </ScrollElement>
            )
          })}
        {questionnaireChartData.map((chartEntry) => {
          return (
            <ScrollElement
              key={chartEntry.entryPosition}
              elementId={`${SCROLL_ID_PREFIX}${chartEntry.id}`}
            >
              <BasicQuestionChartCard
                key={chartEntry.entryPosition}
                positionLabel={`Q${chartEntry.contextPosition}`}
                chartTitle={chartEntry.text}
                completes={chartEntry.samplesCollectedFiltered}
                entry={chartEntry}
              />
            </ScrollElement>
          )
        })}
      </List>
      {emptyState}
    </>
  )
}

export default React.memo(CompleteChartList)
