import {
  Dialog,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'

interface Props {
  dialogTitle: string
  deleteDialogText: string
  isDialogOpen: boolean
  openDialog: () => void
  closeDialog: () => void
  deleteQuestion: () => void
}

const DeleteQuestionButton = ({
  dialogTitle,
  deleteDialogText,
  isDialogOpen,
  openDialog,
  closeDialog,
  deleteQuestion
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  return (
    <>
      <IconButton
        iconName={IconName.DeleteOutline}
        iconSize={IconSize.Large}
        iconColor={IconColor.Background}
        onClick={openDialog}
        negativePadding
        smallHoverShadowSize
      />
      <Dialog
        title={dialogTitle}
        open={isDialogOpen}
        onClose={closeDialog}
        primaryButtonText="Delete card"
        primaryButtonClick={deleteQuestion}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold
          )}
        >
          {deleteDialogText}
        </p>
      </Dialog>
    </>
  )
}

export default DeleteQuestionButton
