/* eslint-disable no-underscore-dangle */
import { TooltipModel } from 'chart.js'

const TOOLTIP_ID = 'chartjs-tooltip'
const TOOLTIP_CARET_ID = 'chartjs-tooltip-caret'

export const buildTooltipTitle = (
  titleEl: HTMLDivElement,
  tooltipModel: TooltipModel<'bar'>
): void => {
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const titleLines = tooltipModel.title || []

  Object.assign(titleEl.style, {
    color: tooltipModel.options.titleColor,
    fontSize: `${(tooltipModel.options.titleFont as any)._proxy.size}px`,
    fontWeight: `${(tooltipModel.options.titleFont as any)._proxy.style}`,
    textAlign: tooltipModel.options.titleAlign
  })

  if (titleLines.length) {
    Object.assign(titleEl.style, {
      marginBottom: `${tooltipModel.options.titleMarginBottom}px`
    })

    titleLines.forEach((title) => {
      const row = document.createElement('div')

      titleEl.append(row)
      row.textContent = title
    })
  }
}

export const createCaret = (tooltipModel: TooltipModel<'bar'>): HTMLElement => {
  let caretEl = document.getElementById(TOOLTIP_CARET_ID)

  if (caretEl) {
    // remove existing caret to create styles from scratch and escape any styling collisions
    caretEl.remove()
  }

  caretEl = document.createElement('div')
  caretEl.id = TOOLTIP_CARET_ID

  Object.assign(caretEl.style, {
    position: 'absolute',
    border: `${tooltipModel.options.caretSize}px solid transparent`
  })

  switch (tooltipModel.yAlign) {
    case 'top':
      Object.assign(caretEl.style, {
        top: `${-2 * parseFloat(tooltipModel.options.caretSize.toString())}px`,
        left: '50%',
        marginLeft: `${
          -0.5 * parseFloat(tooltipModel.options.caretSize.toString())
        }px`,
        borderBottomColor: tooltipModel.options.backgroundColor
      })
      break
    case 'center':
      Object.assign(caretEl.style, {
        top: '50%',
        marginTop: `${-tooltipModel.options.caretSize}px`,
        left: tooltipModel.xAlign === 'left' ? '0' : '100%',
        [tooltipModel.xAlign === 'left'
          ? 'borderRightColor'
          : 'borderLeftColor']: tooltipModel.options.backgroundColor,
        [tooltipModel.xAlign === 'left' ? 'marginLeft' : 'marginRight']: `${
          -2 * parseFloat(tooltipModel.options.caretSize.toString())
        }px`
      })

      break
    default:
      Object.assign(caretEl.style, {
        top: '100%',
        left: '50%',
        marginLeft: `${
          -0.5 * parseFloat(tooltipModel.options.caretSize.toString())
        }px`,
        borderTopColor: tooltipModel.options.backgroundColor
      })
  }

  return caretEl
}

export const createTooltip = (
  canvas: HTMLCanvasElement,
  tooltipModel: TooltipModel<'bar'>
): HTMLElement => {
  const position = canvas.getBoundingClientRect()
  let tooltipEl = document.getElementById(TOOLTIP_ID)

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = TOOLTIP_ID
    document.body.append(tooltipEl)
  }

  Object.assign(tooltipEl.style, {
    opacity: '1',
    position: 'absolute',
    left: `${position.left + window.pageXOffset + tooltipModel.x}px`,
    top: `${position.top + window.pageYOffset + tooltipModel.y}px`,
    color: tooltipModel.options.bodyColor,
    borderRadius: `${tooltipModel.options.cornerRadius}px`,
    padding: `${tooltipModel.options.padding}px`,
    pointerEvents: 'none',
    backgroundColor: tooltipModel.options.backgroundColor,
    borderWidth: `${tooltipModel.options.borderWidth}px`,
    borderColor: tooltipModel.options.borderColor,
    fontFamily: `${(tooltipModel.options.bodyFont as any)._proxy.family}`
  })

  return tooltipEl
}

export const buildTooltipBody = (
  bodyEl: HTMLDivElement,
  tooltipModel: TooltipModel<'bar'>
): void => {
  const bodyLines = tooltipModel.body.map((bodyItem) => bodyItem.lines)

  bodyLines.forEach((bodyLine, i) => {
    const color = tooltipModel.labelColors[i] as unknown as {
      borderColor: string
      backgroundColor: string
    }
    const labelMark = document.createElement('span')
    const labelText = document.createElement('span')
    const bodyLineEl = document.createElement('div')

    Object.assign(bodyEl.style, {
      color: tooltipModel.options.bodyColor,
      fontSize: `${(tooltipModel.options.bodyFont as any)._proxy.size}px`
    })

    Object.assign(labelMark.style, {
      backgroundColor: color.backgroundColor,
      borderColor: color.borderColor,
      display: 'inline-block',
      boxSizing: 'border-box',
      width: `${(tooltipModel.options.titleFont as any)._proxy.size}px`,
      height: `${(tooltipModel.options.titleFont as any)._proxy.size}px`,
      margin: `${tooltipModel.options.titleSpacing}px`,
      verticalAlign: 'middle',
      border: `1px solid ${tooltipModel.labelTextColors[i]}`
    })

    labelText.append(bodyLine[0])
    labelText.style.verticalAlign = 'middle'
    bodyLineEl.append(labelMark, labelText)
    bodyEl.append(bodyLineEl)
  })
}
