import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const CREATE_QUESTIONNAIRE_ENTRY_V2 = questionnaireGql(/* GraphQL */ `
  mutation createQuestionnaireEntryV2(
    $input: CreateQuestionnaireEntryV2Input!
  ) {
    createQuestionnaireEntryV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)
