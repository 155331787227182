import { Grid, Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'

export const ResponseOptionsSourceColumnHeader = ({
  header,
  subheader
}: {
  header: string
  subheader: string
}) => {
  return (
    <>
      <Text size={TextSize.lxl} weight={TextWeight.SemiBold}>
        {header}
      </Text>
      <Grid marginY={1}>
        <Text>{subheader}</Text>
      </Grid>
    </>
  )
}
