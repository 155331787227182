import { datadogLogs } from '@datadog/browser-logs'
import {
  DraftCustomAudienceItem,
  DraftFieldworkAudienceMember,
  InputMaybe,
  Maybe,
  ResponseOptionsLogic,
  ResponseOptionsLogicInputInput
} from '../../../data/gql-gen/questionnaire/graphql'
import { AUDIENCE_VALIDATION_ERRORS } from '../../../data/gql/questionnaire/queries/audienceValidationErrors'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'

const isDraftCustomAudienceItem = (
  memberItem: unknown
): memberItem is DraftCustomAudienceItem => {
  return (
    !!memberItem &&
    typeof memberItem === 'object' &&
    (memberItem as { __typename: string }).__typename ===
      'DraftCustomAudienceItem'
  )
}

export const useCustomCriterionOptionLogicActions = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { updateAudienceMemberV2 } = useUpdateAudienceMemberV2()

  const setCustomCriterionOptionsLogic = (
    memberId: string,
    value: InputMaybe<ResponseOptionsLogicInputInput>
  ): Promise<Maybe<ResponseOptionsLogic> | undefined> => {
    return updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId,
          updateCustomMember: {
            responseOptionsLogic: {
              value: value
            }
          }
        }
      },
      refetchQueries: [AUDIENCE_VALIDATION_ERRORS],
      onError: (error) => {
        datadogLogs.logger.error(
          `[updateCustomCriterionOptionsLogic] ${error.message}`,
          error
        )
      }
    }).then((result) => {
      const memberItem = (
        result.data?.updateAudienceMemberV2 as DraftFieldworkAudienceMember
      ).memberItem
      return isDraftCustomAudienceItem(memberItem)
        ? memberItem.responseOptionsLogic
        : undefined
    })
  }

  return setCustomCriterionOptionsLogic
}
