import { useReactiveVar } from '@apollo/client'
import {
  ExclusiveButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemQuestionnaireResponseOption
} from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useMount, useUnmount } from 'react-use'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import { DraftEntryResponseOption } from '../../../../data/gql-gen/questionnaire/graphql'
import {
  EntryType,
  PositionTextSelection,
  QuestionSettingCode
} from '../../../../data/model/questionnaire'
import {
  IsNonManualUIChange,
  onChangePasteAcceptingInput
} from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useCopyPasteMatrix from '../../../../hooks/copyPaste/useCopyPasteMatrix'
import {
  ValidationResult,
  isNonEmptyResponseError
} from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { useDefaultState } from '../../../../hooks/useDefaultState'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  addMatrixQuestionResponseOptionTransactionDatadog,
  deleteMatrixQuestionResponseOptionTransactionDatadog,
  moveMatrixQuestionResponseOptionTransactionDatadog,
  pasteInMatrixQuestionColumnTransactionDatadog
} from '../../../../tracking/perf/transactions'
import {
  checkIfMatrixMultipleChoice,
  isSettingEnabled,
  newEntryId
} from '../../../../utils/questionnaireUtils'
import {
  useRemoveResponseOptionWithSettingsUpdate,
  useUpdateResponseOption
} from '../../BasicQuestion/ResponseOptions/ResponseOptions.hooks'
import {
  MaskingButton,
  MaskingDialogContext
} from '../../Masking/MaskingButton'
import { PinResponse, PinnedItemType } from '../../PinResponse'
import { usePinResponseOption } from '../../PinResponse/PinResponse.hooks'
import {
  responseOptionExclusiveToggled,
  responseOptionUpdated,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { useMatrixEntryContext } from '../MatrixQuestion.container'

interface Props {
  index: number
  matrixColumn: DraftEntryResponseOption
  shouldAutoFocus: boolean
  responseOptionsCount: number
  entryId: string
  maskingEnabled: boolean
  onEnter?: (position: number) => void
  validateMatrixColumnText: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult
}

const MatrixResponseOption: React.FC<Props> = (props: Props) => {
  const {
    index,
    matrixColumn,
    shouldAutoFocus,
    responseOptionsCount,
    entryId,
    maskingEnabled,
    onEnter: createResponseOption,
    validateMatrixColumnText
  }: Props = props
  const surveyId = useSurveyId()
  const entryItem = useMatrixEntryContext()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const updateResponseOption = useUpdateResponseOption(entryId)
  const removeResponseOption =
    useRemoveResponseOptionWithSettingsUpdate(entryId)
  const pinResponseOption = usePinResponseOption(entryId)

  const {
    responseOptionLk,
    responseOption,
    exclusive,
    maskingRules,
    position,
    pinned,
    createdDate
  } = matrixColumn

  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)
  const [matrixColumnText, setMatrixColumnText] = useState<string>(
    responseOption?.value ?? ''
  )
  const [positionSelectedText, setPositionSelectedText] = useState<
    PositionTextSelection | undefined
  >(undefined)
  const newlyAddedEntryId = useReactiveVar(newEntryId)
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false)

  const [isExclusiveResponse, setIsExclusiveResponse] =
    useDefaultState<boolean>(exclusive ?? false)

  const { pasteToMatrixColumn } = useCopyPasteMatrix(entryId)

  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []

  const isAfterPaste = useReactiveVar<boolean>(IsNonManualUIChange)

  const hasTempId = /^responseOptionLk-\d+$/.test(responseOptionLk)
  const isEditable = createdDate !== '' && !hasTempId

  const debounceDelayMs = 400

  const handleOnFocusedWithDelay: (delay?: number) => void = (
    delay = debounceDelayMs
  ) => {
    if (hasBeenFocused) return

    setTimeout(() => {
      setHasBeenFocused(true)
    }, delay)
  }

  const triggerResponseOptionChanged: () => void = () => {
    if (!responseOption || matrixColumnText !== responseOption.value) {
      dispatch(
        responseOptionUpdated({
          questionLk: entryItem.matrixTitleLk,
          responseOptionLk,
          value: matrixColumnText
        })
      )
      updateResponseOption({
        responseOptionId: responseOptionLk,
        responseOptionValue: matrixColumnText
      })
      handleOnFocusedWithDelay(100)
    }
  }

  const onPasteMatrixResponseOption: (
    text: string,
    position: number,
    positionSelectedText?: PositionTextSelection
  ) => void = useCallback(
    async (text, position, positionSelectedText) => {
      pasteInMatrixQuestionColumnTransactionDatadog.start()
      questionBeingEditedId(entryId)

      await pasteToMatrixColumn({
        text,
        matrixTitleLk: entryId,
        position,
        positionSelectedText
      })
    },
    [pasteToMatrixColumn, entryId]
  )

  useDebounceEffect<string | undefined>(
    () => {
      triggerResponseOptionChanged()
    },
    matrixColumnText,
    { delay: debounceDelayMs }
  )

  useEffect(() => {
    if (isAfterPaste && responseOption) {
      setMatrixColumnText(responseOption.value)
    }
  }, [responseOption, isAfterPaste])

  useEffect(() => {
    setPendingDeletionPosition(undefined)
  }, [responseOptionsCount])

  useMount(() => {
    addMatrixQuestionResponseOptionTransactionDatadog.end()
    pasteInMatrixQuestionColumnTransactionDatadog.end()
    moveMatrixQuestionResponseOptionTransactionDatadog.end()
  })

  useUnmount(() => {
    deleteMatrixQuestionResponseOptionTransactionDatadog.end()
  })

  const { hasError, errorMessage } = validateMatrixColumnText(
    entryItem.matrixTitleLk,
    responseOptionLk
  )

  const placeHolderTextMap = new Map<number, string>([
    [0, 'Very inspiring'],
    [1, 'Quite inspiring']
  ])

  if (position === pendingDeletionPosition) {
    return null
  }

  const isRandomiseOn = isSettingEnabled(
    settingValues,
    QuestionSettingCode.MatrixRandomiseResponseOptions
  )

  const handleSelectedMatrixResponseOptionText = (
    selectionStart?: number | null,
    selectionEnd?: number | null
  ) => {
    setPositionSelectedText({
      startPositionSelectedText: selectionStart,
      endPositionSelectedText: selectionEnd
    })
  }

  const handleDelete = () => {
    IsNonManualUIChange(true)
    setPendingDeletionPosition(position)
    removeResponseOption(responseOptionLk)
  }

  const handlePinResponseOption = () => {
    pinResponseOption(responseOptionLk, true)
  }

  const handleOpenMaskingDialog = () => {
    setIsDialogOpen(true)
  }

  const responseActions: KebabMenuOption[] = [
    {
      id: 2,
      textItem: 'Add display logic',
      iconName: IconName.Visibility,
      disabled:
        // OR if this question is the first one and there are no other questions before it
        !maskingEnabled || maskingRules.length > 0,
      onClickItem: handleOpenMaskingDialog
    },
    {
      id: 3,
      textItem: 'Pin response',
      disabled: pinned || !isRandomiseOn,
      iconName: IconName.PushPin,
      onClickItem: handlePinResponseOption
    }
  ]

  const setExclusive = (isExclusive: boolean) => {
    dispatch(
      responseOptionExclusiveToggled({
        questionLk: entryId,
        responseOptionLk,
        exclusive: isExclusive
      })
    )

    updateResponseOption({
      responseOptionId: responseOptionLk,
      exclusive: isExclusive
    })
  }

  const isMultipleChoice = checkIfMatrixMultipleChoice(settingValues)

  if (isMultipleChoice) {
    responseActions.push({
      id: 4,
      textItem: 'Make exclusive',
      iconName: IconName.Filter1Outlined,
      disabled: isExclusiveResponse,
      onClickItem: () => {
        setIsExclusiveResponse(true)
        setExclusive(true)

        LogAmplitudeEvent(EventType.MadeResponseExclusive, {
          surveyId,
          responseOptionValue: responseOption?.value
        })
      }
    })
  }

  const handelExclusiveIconClick = isMultipleChoice
    ? () => {
        setIsExclusiveResponse(false)
        setExclusive(false)
      }
    : undefined

  const isNewlyAddedEntryId = newlyAddedEntryId === entryId
  const canShowError =
    hasError &&
    (hasBeenFocused ||
      !isNewlyAddedEntryId ||
      isNonEmptyResponseError(errorMessage))

  return (
    <ListItemQuestionnaireResponseOption
      responseOptionId={responseOptionLk}
      responseActionsMenu={
        responseActions.length > 0 ? (
          <KebabMenu
            kebabMenuOptions={responseActions}
            horizontal
            iconPosition={KebabMenuIconPosition.Left}
            iconColor={IconColor.Background}
            tooltipText="Configure this response option"
          />
        ) : undefined
      }
      ariaLabel="Matrix response option"
      maskingIconButton={
        <MaskingDialogContext.Provider
          value={{ isDialogOpen, setIsDialogOpen }}
        >
          <MaskingButton
            questionLk={entryItem.matrixTitleLk}
            responseOptionLk={responseOptionLk}
            entryType={EntryType.MatrixEntryType}
          />
        </MaskingDialogContext.Provider>
      }
      index={position}
      key={responseOptionLk}
      isError={canShowError}
      helperText={canShowError ? errorMessage : ''}
      onFocus={() => {
        questionBeingEditedId(entryId)
      }}
      onBlur={() => {
        setHasBeenFocused(true)
      }}
      onEnter={() => {
        if (createResponseOption) {
          createResponseOption(position + 1)
        }
      }}
      pinningIconButton={
        pinned ? (
          <PinResponse
            entryId={entryId}
            responseOptionLk={responseOptionLk}
            itemType={PinnedItemType.ResponseOption}
            isPinned={pinned}
          />
        ) : undefined
      }
      exclusiveButton={
        isExclusiveResponse ? (
          <ExclusiveButton onExclusiveIconClick={handelExclusiveIconClick} />
        ) : undefined
      }
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldAutoFocus}
      editable={isEditable}
      cannotDelete={responseOptionsCount <= 2}
      onDelete={handleDelete}
      disableGutters
      draggableId={`dr${responseOptionLk}`}
      canBeReordered
      value={matrixColumnText}
      placeholder={placeHolderTextMap.get(index) || 'Type an option'}
      onChange={(value) => {
        onChangePasteAcceptingInput(() => setMatrixColumnText(value))
      }}
      onPaste={(value) => {
        IsNonManualUIChange(true)
        onPasteMatrixResponseOption(value, position, positionSelectedText)
      }}
      onSelect={handleSelectedMatrixResponseOptionText}
    />
  )
}

export default memo(MatrixResponseOption)
