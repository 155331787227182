import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_AUDIENCE_CATEGORIES = questionnaireGql(/* GraphQL */ `
  query listCategoriesV2($projectId: ID!, $surveyId: ID!) {
    listCategoriesV2(projectId: $projectId, surveyId: $surveyId) {
      category {
        code
        name
        createdDate
      }
      position
      standardCriteria {
        state
        createdDate
        sinceDate
        criterion {
          code
          name
          createdDate
          question {
            questionId
            text
            createdDate
            textStyling
            parentId
          }
        }
      }
    }
  }
`)

export const categoriesRefetchQuery = (projectId: string, surveyId: string) => {
  return {
    query: GET_AUDIENCE_CATEGORIES,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId }
  }
}
