import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const PASTE_ENTRY_TEXT_FIELDS_V2 = questionnaireGql(/* GraphQL */ `
  mutation pasteEntryTextFieldsV2($input: PasteEntryTextFieldsArgsInput!) {
    pasteEntryTextFieldsV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)

export const PASTE_RESPONSE_OPTION_VALUES_V2 = questionnaireGql(/* GraphQL */ `
  mutation pasteResponseOptionValuesV2(
    $input: PasteResponseOptionValuesArgsInput!
  ) {
    pasteResponseOptionValuesV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)

export const PASTE_MATRIX_ROW_VALUES_V2 = questionnaireGql(/* GraphQL */ `
  mutation pasteMatrixRowValuesV2($input: PasteMatrixRowValuesArgsInput!) {
    pasteMatrixRowValuesV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)
