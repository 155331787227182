import { List } from '@focaldata/cin-ui-components'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import React, { useEffect, useState } from 'react'
import ListItemQuota from '../../components/ListItemQuota'
import { FieldworkAudienceCriterionOption } from '../../data/model/audience'
import { useDebounceEffect } from '../../hooks/useDebounce'
import {
  numberToFixedString,
  percentToProportion,
  proportionToPercent
} from '../../utils/HelperFunctions'
import QuotaEditorFooter from '../QuotaEditorFooter'
import QuotaEditorHeader from '../QuotaEditorHeader'

interface QuotaValues {
  [responseOptionId: string]: number
}

interface Props {
  totalCompletes: number
  options?: FieldworkAudienceCriterionOption[]
  onQuotaChange?: (
    option: FieldworkAudienceCriterionOption,
    newValue: number
  ) => void
}

const QuotaEditor: React.FC<Props> = (props: Props) => {
  const { options, totalCompletes, onQuotaChange } = props
  const [quotaValues, setQuotaValues] = useState<QuotaValues | undefined>()
  const [quotaBeingEdited, setQuotaBeingEdited] = useState<{
    value: number
    option: FieldworkAudienceCriterionOption
  }>()

  const enabledOptions = options?.filter((option) => !!option.qualification)
  const initialQuotaValues = mapValues(
    keyBy(enabledOptions, 'criterionOption.responseOption.responseOptionId'),
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (option) => option?.quota?.percent ?? 0
  )

  const totalPercentage: number | undefined = Object.values(
    initialQuotaValues
  ).reduce((acc, num) => acc + num, 0)

  const triggerOnQuotaChange: () => void = () => {
    if (onQuotaChange && quotaBeingEdited) {
      onQuotaChange(quotaBeingEdited.option, quotaBeingEdited.value)
    }
  }

  useDebounceEffect(
    () => {
      triggerOnQuotaChange()
    },
    quotaBeingEdited,
    { delay: 500 }
  )

  const handleUpdateQuotaValues: (
    responseOptionId: string,
    value: number
  ) => void = (responseOptionId, value) => {
    const newQuotaValues = quotaValues ? { ...quotaValues } : {}
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === 0 || Number.isNaN(value) || value === undefined) {
      delete newQuotaValues[responseOptionId]
      setQuotaValues(newQuotaValues)
      return null
    }
    newQuotaValues[responseOptionId] = percentToProportion(value)
    setQuotaValues(newQuotaValues)
    return null
  }

  useEffect(() => {
    setQuotaValues(initialQuotaValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <QuotaEditorHeader />
      <List>
        {enabledOptions?.map((option) => (
          <ListItemQuota
            key={option.criterionOption.code}
            disableGutters
            totalCompletes={totalCompletes}
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            questionText={option.criterionOption.responseOption?.value}
            defaultQuotaValue={
              option.quota
                ? numberToFixedString(proportionToPercent(option.quota.percent))
                : '0'
            }
            onQuotaChange={(ev) => {
              setQuotaBeingEdited({
                value: parseFloat(ev.target.value),
                option
              })
              handleUpdateQuotaValues(
                option.criterionOption.responseOption.responseOptionId,
                parseFloat(ev.target.value)
              )
            }}
            onQuotaBlur={(value) => {
              handleUpdateQuotaValues(
                option.criterionOption.responseOption.responseOptionId,
                parseFloat(value)
              )
              onQuotaChange?.(option, Number(value))
            }}
          />
        ))}
      </List>
      <QuotaEditorFooter totalPercentage={totalPercentage} />
    </>
  )
}

export default QuotaEditor
