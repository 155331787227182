import { useMutation } from '@apollo/client'
import { Dialog, DialogMaxWidth } from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction } from 'react'
import { Project } from '../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../data/logger'
import { useNavigateToInitialProjectPage } from '../../../hooks/project/useNavigateToInitialProjectPage'
import useGetUserAndAccount from '../../../hooks/useGetUserAndAccount'
import { captureApolloError } from '../../../utils/HelperFunctions'
import UserSession from '../../../utils/UserSession'
import { CREATE_PROJECT } from '../Project.mutations'
import { SEARCH_PROJECTS_QUERY_NAME } from '../Project.query'
import { DUPLICATE_QUESTIONNAIRE_V2 } from '../Questionnaire.mutations'
import DialogDuplicateProject from './DialogDuplicateProject'

interface Props {
  isDialogDuplicateOpen: boolean
  setIsDialogDuplicateOpen: Dispatch<SetStateAction<boolean>>
  selectedProject: Project | undefined
}

const DuplicateProject: React.FC<Props> = (props: Props) => {
  const { isDialogDuplicateOpen, setIsDialogDuplicateOpen, selectedProject } =
    props

  const {
    userName,
    loading: loadingGetUserAndAccount,
    markup
  } = useGetUserAndAccount()

  const toggleDuplicateDialog = () => {
    setIsDialogDuplicateOpen(!isDialogDuplicateOpen)
  }

  const [duplicateQuestionnaire] = useMutation(DUPLICATE_QUESTIONNAIRE_V2, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [SEARCH_PROJECTS_QUERY_NAME],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'duplicateQuestionnaire',
        error
      )
    }
  })

  const navigateToInitialProjectPage = useNavigateToInitialProjectPage()

  const [createProject] = useMutation(CREATE_PROJECT, {
    context: { clientName: 'dashboard' },
    onCompleted: async ({ createProject: createdProject }) => {
      if (selectedProject && createdProject) {
        const oldProjectId = selectedProject.projectId
        const newProjectId = createdProject.projectId
        const newSurveyId = createdProject.surveys[0].surveyId

        if (!newSurveyId) {
          throw new Error('No new survey id after duplication.')
        }

        await duplicateQuestionnaire({
          variables: {
            newSurveyId,
            projectId: oldProjectId,
            newProjectId: newProjectId,
            accountMarkup: markup
          }
        })

        setIsDialogDuplicateOpen(false)
        navigateToInitialProjectPage(createdProject)
      }
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'createProject', error)
    }
  })

  const handleDuplicateProject = (project: Project) => {
    createProject({
      variables: {
        name: `Copy of ${project.name}`,
        description: project.description,
        accountId: UserSession.getAccountId(),
        solutionId: UserSession.getSolutionId(),
        creator: userName || 'N/A',
        projectType: project.projectType
      }
    })
  }

  return (
    <Dialog
      open={isDialogDuplicateOpen}
      onClose={toggleDuplicateDialog}
      primaryButtonClick={() => {
        return selectedProject && handleDuplicateProject(selectedProject)
      }}
      primaryButtonText="Duplicate survey"
      primaryButtonDisabled={loadingGetUserAndAccount}
      title="Duplicate survey"
      maxWidth={DialogMaxWidth.Sm}
      onClickOutside={toggleDuplicateDialog}
    >
      <DialogDuplicateProject />
    </Dialog>
  )
}

export default DuplicateProject
