import { Grid, Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import BarChart from '../components/BarChart'
import InfoCard from '../components/InfoCard'
import Markdown from '../components/Markdown'
import ResearchQuestionCard from '../components/ResearchQuestionCard/ResearchQuestionCard'
import { questionImg } from './researchQuestionIcons'
import results from './results'

const FdChatFindings = () => {
  const navigate = useNavigate()

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedFdChatFindingsPage)
  }, [])

  return (
    <Grid
      container
      gap={'36px'}
      padding={'32px'}
      display={'grid'}
      maxHeight={'100dvh'}
      className="fd-chat-findings"
      style={{
        overflowY: 'auto'
      }}
    >
      <InfoCard>
        <Grid container direction={'column'}>
          <Text size={TextSize.ms}>RESEARCH GOAL</Text>
          <div
            style={{
              maxWidth: '1000px',
              marginTop: '24px',
              marginBottom: '40px'
            }}
          >
            <Text size={TextSize.xxl} weight={TextWeight.Bold}>
              {results.research_goal.headline}
            </Text>
          </div>
          <Grid container spacing={'50px'} alignItems={'center'}>
            <Grid container item xs={12} lg={6} alignItems={'center'}>
              <BarChart {...results.research_goal.chart_data} />
            </Grid>
            <Grid item xs={12} lg={6} paddingRight={'90px'}>
              <Markdown>{results.research_goal.description}</Markdown>
            </Grid>
          </Grid>
        </Grid>
      </InfoCard>
      {results.research_questions.map((question, index) => (
        <ResearchQuestionCard
          direction={index % 2 === 0 ? 'row' : 'row-reverse'}
          captionIcon={questionImg[index + 1]}
          caption={question.text}
          title={question.headline}
          description={question.short_description}
          chartData={question.chart_data}
          primaryButtonText="View Findings"
          handlePrimaryButtonClick={() => {
            LogAmplitudeEvent(EventType.ClickedViewFindings, {
              researchQuestionId: question.id,
              researchQuestionText: question.text,
              researchQuestionHeadline: question.headline
            })
            navigate(question.id)
          }}
          key={question.id}
        />
      ))}
    </Grid>
  )
}

export default FdChatFindings
