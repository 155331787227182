import Skeleton from '@mui/material/Skeleton'
import { useTimelineSteps } from '../Overview.hooks'
import styles from './TimelineCard.module.css'

const TimelineCard = () => {
  const { timelineSteps, loading } = useTimelineSteps()

  return (
    <div className={styles['timeline-card']}>
      <ol
        className={styles.timeline}
        style={{
          marginLeft: loading
            ? '0px'
            : 'calc(var(--spacing-xl) + var(--step-size))'
        }}
      >
        {timelineSteps.map((step) =>
          loading ? (
            <div className={styles['loading-container']} key={step.title}>
              <Skeleton variant="circular" width={24} height={24} />
              <div>
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
              </div>
            </div>
          ) : (
            <li
              className={styles[`timeline-step-${step.status}`]}
              key={step.title}
            >
              <p className={styles['timeline-step-title']}>{step.title}</p>
              <p className={styles['timeline-step-description']}>
                {step.description}
              </p>
            </li>
          )
        )}
      </ol>
    </div>
  )
}

export default TimelineCard
