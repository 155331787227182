import {
  Badge,
  Grid,
  IconName,
  IconSize,
  KebabMenu,
  KebabMenuOption,
  Paper,
  Status,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import ListItem from '@mui/material/ListItem'
import { useNavigate } from 'react-router-dom'
import { stringToStatusMap } from '../../../../controls/ProjectListItem/ProjectListItem.utils'
import { SurveyState } from '../../../../data/gql-gen/dashboard/graphql'

import ProjectListItemDetails from '../../../../controls/ProjectListItem/ProjectListItemDetails'

import useStyles from '../../../../controls/ProjectListItem/ProjectListItem.styles'

enum MarketCardOption {
  Duplicate,
  Delete,
  Pause,
  Unpause
}

export const getKebabMenuOptions = (
  surveyState: SurveyState
): KebabMenuOption[] => {
  const duplicateOption: KebabMenuOption = {
    id: MarketCardOption.Duplicate,
    textItem: 'Duplicate market',
    iconName: IconName.Duplicate,
    iconSize: IconSize.Large
  }
  const deleteOption: KebabMenuOption = {
    id: MarketCardOption.Delete,
    textItem: 'Delete market',
    iconName: IconName.DeleteOutline,
    iconSize: IconSize.Large
  }
  const pauseOption: KebabMenuOption = {
    id: MarketCardOption.Pause,
    textItem: 'Pause market',
    iconName: IconName.PauseCircleOutline,
    iconSize: IconSize.Large
  }
  const unpauseOption: KebabMenuOption = {
    id: MarketCardOption.Unpause,
    textItem: 'Unpause market',
    iconName: IconName.PlayCircleOutline,
    iconSize: IconSize.Large
  }

  switch (surveyState) {
    case SurveyState.Draft:
      return [duplicateOption, deleteOption]
    case SurveyState.Live:
      return [pauseOption, duplicateOption]
    case SurveyState.Paused:
      return [unpauseOption, duplicateOption]
    case SurveyState.Completed:
    default:
      return [duplicateOption]
  }
}

export const MarketCard = ({
  marketId,
  title,
  lastUpdate,
  creator,
  status
}: {
  marketId: string
  title: string
  lastUpdate: string
  creator: string
  status: SurveyState
}) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const navigate = useNavigate()

  const badgeLabel = status.toUpperCase()
  const badgeStatus = stringToStatusMap.get(status) ?? Status.COMPLETE
  const kebabMenuOptions = getKebabMenuOptions(status)

  return (
    <ListItem
      onClick={() => {
        navigate(
          status === SurveyState.Draft
            ? `${marketId}/audience`
            : `${marketId}/results`
        )
      }}
      className={classes.listItemStyle}
      component="li"
      disablePadding
    >
      <Paper className={classes.cardStyle} elevation={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardContentStyle}
        >
          <Grid container direction="column" item xs={9}>
            <Grid container alignItems="center" direction="row">
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeM,
                  textClasses.weightSemiBold,
                  classes.noTextDecoration
                )}
              >
                {title}
              </p>
            </Grid>
            <Grid
              data-testid="project-list-item-details"
              container
              item
              direction="row"
              className={classes.projectDetails}
            >
              <ProjectListItemDetails
                label="Last status update"
                value={lastUpdate}
              />
              <ProjectListItemDetails label="Creator" value={creator} />
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Grid container item justifyContent="center" alignItems="center">
              <Badge
                label={badgeLabel}
                status={badgeStatus}
                className={classes.statusDiv}
              />
              <KebabMenu kebabMenuOptions={kebabMenuOptions} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ListItem>
  )
}
