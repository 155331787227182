import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceCountControl from './AudienceCount.control'

const AudienceCount = () => {
  const { isFdChat } = useProjectType()
  const { draftAudience } = useGetDraftAudience()

  return (
    <AudienceCountControl
      audienceCount={draftAudience?.members.length}
      typeLabel={isFdChat ? 'questions' : undefined}
    />
  )
}

export default AudienceCount
