import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  questionItem: {
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2
  },
  questionSubItem: {
    padding: theme.spacing(1.5)
  },
  questionSubItem1: {
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bulkActionButton: {
    cursor: 'pointer'
  },
  backChevron: {
    marginLeft: theme.spacing(0)
  },
  questionContainer: {
    marginTop: theme.spacing(2)
  },
  questionTextContainer: {
    marginLeft: theme.spacing(-1)
  }
})

export default useStyles
