import {
  Checkbox,
  InfoIcon,
  PaperGroupItem,
  Select,
  SelectVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import { SettingValue } from '../../../data/model/questionnaire'
import { ValidationResult } from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import { useFieldworkData } from '../../Audience/Audience.hooks'
import {
  ContentStructureSettingValues,
  FreeTextMinSettingValue,
  FreeTextSettingValue,
  PostalCodeSettingValue
} from './FreeTextQuestion.model'
import useStyles from './FreeTextQuestion.styles'
import {
  INPUT_FORMAT_SELECTOR,
  MAX_CHARACTER_LIMIT_SELECTOR,
  MIN_CHARACTER_LIMIT_SELECTOR,
  POSTAL_CODE_VALIDATION_SELECTOR,
  freeTextStructureOptions,
  limitOptions,
  minOptions,
  postalCodeValidationOptions
} from './constants'

interface Props extends WithEntry {
  typeSetting: ContentStructureSettingValues
  onChangeType: (newType: ContentStructureSettingValues) => void
  characterLimit?: FreeTextSettingValue
  characterMin?: FreeTextMinSettingValue
  validationError: ValidationResult
  onChangeSettingValue?: (settingValue: FreeTextSettingValue) => void
  onChangePostalCodeValidation?: (settingValue: PostalCodeSettingValue) => void
  onChangeMinSettingValue?: (settingValue: FreeTextMinSettingValue) => void
}

const FreeTextQuestion: React.FC<Props> = (props: Props) => {
  const {
    typeSetting = SettingValue.Unstructured,
    onChangeType,
    characterLimit = SettingValue.TwoHundred,
    characterMin = SettingValue.Two,
    validationError,
    onChangeSettingValue,
    onChangePostalCodeValidation,
    onChangeMinSettingValue
  } = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const { fieldworkData } = useFieldworkData()

  const locale = fieldworkData?.locale
  const isUK = locale?.country === 'GB'

  let typeSettingOptionValue
  if (typeSetting === SettingValue.PostCodeFull) {
    typeSettingOptionValue = SettingValue.PostCode
  } else if (typeSetting === SettingValue.Category) {
    typeSettingOptionValue = SettingValue.Unstructured
  } else {
    typeSettingOptionValue = typeSetting
  }

  const hasFreeTextRangeError = validationError.hasError

  return (
    <>
      <PaperGroupItem>
        <div
          className={classNames(
            'fd-grid fd-container fd-column',
            classes.paddingBottom
          )}
        >
          <p
            className={classNames(
              textClasses.default,
              textClasses.gutterBottom,
              textClasses.weightSemiBold
            )}
          >
            Input format
          </p>
          <div className={classes.selectContainer}>
            <Select
              ariaLabel={INPUT_FORMAT_SELECTOR}
              fullWidth
              options={freeTextStructureOptions}
              variant={SelectVariant.Filled}
              optionValue={typeSettingOptionValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (onChangeType) {
                  onChangeType(
                    event.target.value as ContentStructureSettingValues
                  )
                }
              }}
            />
          </div>
          {typeSettingOptionValue === SettingValue.Unstructured && (
            <Checkbox
              className={classes.marginTop}
              label={
                <>
                  Does this question require respondents to enter proper nouns
                  or brand names (e.g. Coca-Cola, United States, John Doe)?
                  <InfoIcon
                    className={classes.infoIcon}
                    text="This makes it simpler for our system to detect low-quality responses"
                  />
                </>
              }
              checked={typeSetting === SettingValue.Category}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (onChangeType) {
                  onChangeType(
                    event.target.checked
                      ? SettingValue.Category
                      : SettingValue.Unstructured
                  )
                }
              }}
            />
          )}
        </div>
      </PaperGroupItem>
      {typeSettingOptionValue === SettingValue.Unstructured && (
        <PaperGroupItem>
          <div className="fd-grid fd-container">
            <p
              className={classNames(
                'fd-grid fd-item fd-xs-12',
                textClasses.default,
                textClasses.weightSemiBold,
                classes.responseRange
              )}
            >
              Response range
              <InfoIcon
                className={classes.infoIcon}
                text="Use the dropdowns below to set limits for the min/max number of characters required for free text responses."
              />
            </p>
            <div className="fd-grid fd-container fd-row fd-xl-10">
              <div
                className={classNames(
                  'fd-grid fd-container fd-column fd-xs-12 fd-lg-6 fd-xl-5',
                  classes.paddingBottom,
                  classes.responseRangeContainer
                )}
              >
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.gutterBottom,
                    textClasses.weightRegular,
                    textClasses.sizeMs
                  )}
                >
                  Min
                </p>
                <div
                  className={classNames(classes.responseRangeSelectContainer, {
                    [classes.selectContainerError]: hasFreeTextRangeError
                  })}
                >
                  <Select
                    ariaLabel={MIN_CHARACTER_LIMIT_SELECTOR}
                    fullWidth
                    options={minOptions}
                    variant={SelectVariant.Filled}
                    optionValue={characterMin}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChangeMinSettingValue) {
                        onChangeMinSettingValue(
                          event.target.value as FreeTextMinSettingValue
                        )
                      }
                    }}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  'fd-grid fd-container fd-column fd-xs-12 fd-lg-6 fd-xl-5',
                  classes.paddingBottom,
                  classes.responseRangeContainer
                )}
              >
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.gutterBottom,
                    textClasses.weightRegular,
                    textClasses.sizeMs
                  )}
                >
                  Max
                </p>
                <div
                  className={classNames(classes.responseRangeSelectContainer, {
                    [classes.selectContainerError]: hasFreeTextRangeError
                  })}
                >
                  <Select
                    ariaLabel={MAX_CHARACTER_LIMIT_SELECTOR}
                    fullWidth
                    options={limitOptions}
                    variant={SelectVariant.Filled}
                    optionValue={characterLimit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChangeSettingValue) {
                        onChangeSettingValue(
                          event.target.value as FreeTextSettingValue
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {hasFreeTextRangeError && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.gutterBottom,
                textClasses.highlightError
              )}
            >
              {validationError.errorMessage}
            </p>
          )}
        </PaperGroupItem>
      )}
      {typeSettingOptionValue === SettingValue.PostCode && isUK && (
        <PaperGroupItem>
          <div
            className={classNames(
              'fd-grid fd-container fd-column',
              classes.paddingBottom
            )}
          >
            <p
              className={classNames(
                textClasses.default,
                textClasses.gutterBottom,
                textClasses.weightSemiBold
              )}
            >
              Postal code validation
            </p>
            <div className={classes.selectContainer}>
              <Select
                ariaLabel={POSTAL_CODE_VALIDATION_SELECTOR}
                fullWidth
                options={postalCodeValidationOptions}
                variant={SelectVariant.Filled}
                optionValue={typeSetting}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (onChangePostalCodeValidation) {
                    onChangePostalCodeValidation(
                      event.target.value as PostalCodeSettingValue
                    )
                  }
                }}
              />
            </div>
          </div>
        </PaperGroupItem>
      )}
    </>
  )
}

export default memo(FreeTextQuestion, propsAreEqual)
