import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    paddingTop: theme.spacing(1)
  },
  skeletonMargin: {
    marginBottom: theme.spacing(2)
  },
  paddingTop: {
    paddingTop: theme.spacing(1)
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(0.75, 0),
    maxWidth: 600
  }
})

export default useStyles
