import {
  Button,
  ButtonVariant,
  Checkbox,
  Grid,
  Text,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { MULTI_MARKET_HEADER_HEIGHT } from '../MultiMarketPage'
import { useSetDraftCombinedQuestion } from '../ResponseOptionsMappingPage/DraftCombinedQuestionState/DraftCombinedQuestion.hooks'
import { useConfirmMapping } from './AudienceMappingPage.hooks'
import { MapAudienceDialog } from './MapAudienceDialog'
import { MappingOption } from './MapAudienceDialog/MapAudienceDialog'
import { MapAudienceQuestionCard } from './MapAudienceQuestionCard'
import { MappingProgressDialog } from './MappingProgressDialog'
import { audienceQuestions, combinedQuestions } from './mockData'

export const AudienceMappingPage = () => {
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false)
  const [selectedQuestionsIds, setSelectedQuestionsIds] = useState<
    Set<(typeof audienceQuestions)[number]['id']>
  >(new Set())
  const { mappingInProgress, confirmMapping } = useConfirmMapping()

  const setDraftCombinedQuestion = useSetDraftCombinedQuestion()

  const { classes, cx: classNames } = textStyleUtils.useTextStyles()

  const handleOpenMappingDialog = () => {
    setMappingDialogOpen(true)
  }

  const handleCloseMappingDialog = () => {
    setMappingDialogOpen(false)
  }

  const handleConfirmMapping = async (
    mappingOption: MappingOption,
    existingQuestionId?: string
  ) => {
    handleCloseMappingDialog()

    const combinedQuestion = await confirmMapping(
      mappingOption,
      existingQuestionId
    )

    setDraftCombinedQuestion(combinedQuestion)
  }

  const handleSelectUnmappedQuestion = (id: string) => (selected: boolean) => {
    setSelectedQuestionsIds((selectedQuestions) => {
      selected ? selectedQuestions.add(id) : selectedQuestions.delete(id)
      return new Set(selectedQuestions)
    })
  }

  const handleSelectAllUnmappedQuestions = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedQuestionsIds(() => {
      return e.target.checked
        ? new Set(audienceQuestions.map(({ id }) => id))
        : new Set()
    })
  }

  const noSelectedQuestions = selectedQuestionsIds.size === 0

  return (
    <Grid
      container
      direction="column"
      sx={{
        padding: '32px 64px',
        maxHeight: `calc(100vh - ${MULTI_MARKET_HEADER_HEIGHT}px)`,
        overflow: 'auto'
      }}
    >
      <div>
        <Text size={TextSize.lxl} weight={TextWeight.SemiBold}>
          Map Audience Questions
        </Text>
        <Text size={TextSize.ms}>
          Please map your custom screening questions together below. All of your
          targeting questions have been mapped automatically by the system.
        </Text>

        <Grid
          gap={8}
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          marginTop={6}
        >
          <div>
            <Grid container height={120} display="flex" direction="column">
              <Text size={TextSize.lxl} weight={TextWeight.SemiBold}>
                Unmapped Questions
              </Text>
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                paddingLeft={3}
              >
                <Checkbox
                  label={
                    <span
                      className={classNames(
                        classes.default,
                        classes.weightSemiBold
                      )}
                    >
                      Select all
                    </span>
                  }
                  checked={
                    selectedQuestionsIds.size === audienceQuestions.length
                  }
                  onChange={handleSelectAllUnmappedQuestions}
                />
                <Button
                  variant={ButtonVariant.SecondaryFilled}
                  disabled={noSelectedQuestions}
                  onClick={handleOpenMappingDialog}
                >
                  Map{' '}
                  {noSelectedQuestions ? (
                    <></>
                  ) : (
                    <>({selectedQuestionsIds.size})</>
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid display="grid" rowGap={3} data-testid="unmapped-questions">
              {audienceQuestions.map(({ id, question, position, market }) => {
                return (
                  <MapAudienceQuestionCard
                    key={id}
                    variant="checkbox"
                    header={`A${position} ${market}`}
                    question={question}
                    selected={selectedQuestionsIds.has(id)}
                    onSelect={handleSelectUnmappedQuestion(id)}
                  />
                )
              })}
            </Grid>
          </div>
          <div>
            <Grid container height={120} display="flex" direction="column">
              <Text size={TextSize.lxl} weight={TextWeight.SemiBold}>
                Mapped Questions
              </Text>
              <Grid display="flex" alignItems="center" flex={1}>
                <Text>Custom screening mapping</Text>
              </Grid>
            </Grid>
            <Grid display="grid" rowGap={3}>
              {combinedQuestions.map(({ id, question, position }) => {
                return (
                  <MapAudienceQuestionCard
                    key={id}
                    variant="regular"
                    header={`A${position} Combined`}
                    question={question}
                  />
                )
              })}
            </Grid>
          </div>
        </Grid>
      </div>

      <MapAudienceDialog
        open={mappingDialogOpen}
        combinedQuestions={combinedQuestions}
        onClose={handleCloseMappingDialog}
        onConfirmMapping={handleConfirmMapping}
      />
      <MappingProgressDialog inProgress={mappingInProgress} />
    </Grid>
  )
}
