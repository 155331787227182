import {
  LinkButton,
  TextHighlight,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import {
  TextHighlight as Highlight,
  TextSize,
  TextWeight as Weight
} from '../../../constants/text'
import useLoopSourceOptions from '../SurveyQuestionCardHeader/useLoopSourceOptions'
import { useLoopSpecificDisplayLogicData } from '../SurveyQuestionCardHeader/useLoopSpecificDisplayLogicData'
import useLoopingConfigData from '../SurveyQuestionCardHeader/useLoopingConfigData'
import useStyles from './styles'

interface Props {
  entryId: string
  sectionId: string
  onEditDisplayLogic?: () => void
  onRemoveDisplayLogic?: () => void
}

const LoopSpecificDisplayLogicHeader: React.FC<Props> = (props: Props) => {
  const {
    entryId,
    sectionId,
    onEditDisplayLogic,
    onRemoveDisplayLogic
  }: Props = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const loopSpecificQuestionLogic = useLoopSpecificDisplayLogicData(entryId)

  const { isLoopingEnabled, loopingConfig } =
    useLoopingConfigData(sectionId) ?? {}

  const loopSourceOptions = useLoopSourceOptions(
    loopingConfig?.sourceEntryNumber
  )
  const selectedLoops = loopSourceOptions.filter(({ id }) =>
    id ? loopSpecificQuestionLogic?.sourceIds.includes(id) : false
  )

  return (
    <>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {!!selectedLoops?.length && isLoopingEnabled && (
        <div
          className={classNames(
            'fd-grid',
            'fd-container',
            'fd-row',
            'fd-justify-content-between',
            classes.container
          )}
          data-testid="display-loop-based-logic-header"
        >
          <div className="fd-grid fd-container fd-item fd-xs-10 fd-column">
            <p
              className={classNames(
                textClasses.default,
                textClasses.weightSemiBold
              )}
            >
              Display this question
            </p>
            <div>
              <p
                style={{
                  color: Highlight.Background,
                  display: 'inline',
                  fontSize: TextSize.m
                }}
              >
                IF loop being shown is{' '}
              </p>
              {selectedLoops.map(({ id, title }, index) => (
                <React.Fragment key={id}>
                  {index > 0 && (
                    <p
                      key={`${title}-or`}
                      style={{
                        color: Highlight.Background,
                        display: 'inline',
                        fontSize: TextSize.m
                      }}
                    >
                      {' OR '}
                    </p>
                  )}
                  <p
                    key={title}
                    style={{
                      color: Highlight.Background,
                      display: 'inline',
                      fontSize: TextSize.m,
                      fontWeight: Weight.SemiBold
                    }}
                  >
                    {title}
                  </p>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="fd-grid fd-container fd-item fd-xs-2 fd-justify-content-flex-end fd-align-items-center">
            <div
              className={classNames(
                'fd-grid',
                'fd-item',
                'fd-xs-auto',
                classes.edit
              )}
            >
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.SemiBold}
                onClick={() => {
                  if (onEditDisplayLogic) {
                    onEditDisplayLogic()
                  }
                }}
              >
                Edit
              </LinkButton>
            </div>
            <div className="fd-grid fd-item fd-xs-auto">
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.SemiBold}
                onClick={() => {
                  if (onRemoveDisplayLogic) {
                    onRemoveDisplayLogic()
                  }
                }}
              >
                Remove
              </LinkButton>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LoopSpecificDisplayLogicHeader
