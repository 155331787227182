import defer from 'lodash/defer'
import isEmpty from 'lodash/isEmpty'
import { memo, useContext } from 'react'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { QuestionnaireRenderingTransactionDatadog } from '../../../tracking/perf/transactions'
import AddEntryLoader from '../AddEntryLoader'
import { EmptyQuestionnaire } from '../EmptyQuestionnaire'
import QuestionnaireContext from '../Questionnaire.context'
import { QuestionsList } from './QuestionsList.component'
import {
  useChangeCardVisibilityOnScroll,
  usePlaceHolderHeightsById
} from './QuestionsList.hooks'

// @todo Legacy eslint violation – fix this when editing
// eslint-disable-next-line react/display-name
export const QuestionsListContainer = memo((): JSX.Element | null => {
  QuestionnaireRenderingTransactionDatadog.start()

  defer(() => {
    QuestionnaireRenderingTransactionDatadog.end()
  })

  const {
    questionnaireState: { entriesToRender }
  } = useContext(QuestionnaireContext)

  const { draftQuestionnaire, flattenedEntries, questionnaireLoading } =
    useGetDraftQuestionnaire()

  const placeholderHeightsById = usePlaceHolderHeightsById()
  const changeCardVisibilityOnScroll = useChangeCardVisibilityOnScroll()

  const entries = draftQuestionnaire?.entries

  if (questionnaireLoading) {
    return <AddEntryLoader />
  }

  if (isEmpty(entries)) {
    return <EmptyQuestionnaire />
  }

  const numbersToRender = entriesToRender.map(
    (entryIndex) => flattenedEntries[entryIndex]?.number
  )

  return (
    <QuestionsList
      entries={entries}
      numbersToRender={numbersToRender}
      placeholderHeightsById={placeholderHeightsById}
      onChangeVisible={changeCardVisibilityOnScroll}
    />
  )
})
