import { gql } from '@apollo/client/core'

const DRAFT_FORK = gql`
  fragment DraftFork on Fork {
    forkId
    name
    branches {
      label
      branchNumber
      percentage
    }
    __typename
  }
`

export default DRAFT_FORK
