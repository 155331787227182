import React, { memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { emptyArr } from '../../../../constants/misc'
import { MaskingRuleInput } from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useUpdateResponseOption } from '../../BasicQuestion/ResponseOptions/ResponseOptions.hooks'
import {
  maskingRulesSet,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import MaskingButtonControl from './MaskingButton.control'
import { toDraftMaskingRule } from './MaskingButton.utils'

interface Props {
  questionLk: string
  responseOptionLk: string
  entryType: EntryType
}

const MaskingButton: React.FC<Props> = ({
  questionLk,
  responseOptionLk,
  entryType
}: Props) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  const dispatch = useAppDispatch()
  const surveyId = useSurveyId()
  const updateResponseOption = useUpdateResponseOption(questionLk)

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const responseOptionMasking = questionLk
    ? responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )?.maskingRules ?? emptyArr
    : emptyArr

  const handleSave: (newRules: MaskingRuleInput[]) => Promise<void> = async (
    newRules
  ) => {
    dispatch(
      maskingRulesSet({
        questionLk: questionLk,
        responseOptionLk,
        maskingRules: newRules.map(toDraftMaskingRule),
        flattenAllEntries: flatDraftQuestionnaireEntries
      })
    )
    updateResponseOption({
      responseOptionId: responseOptionLk,
      maskingRules: newRules
    })

    const negatedMaskingRules = newRules.filter((newRule) => newRule.negated)

    LogAmplitudeEvent(EventType.AddedResponseDisplayLogic, {
      surveyId,
      questionLk,
      responseOptionLk,
      entryType,
      hasNegatedMaskingRules: negatedMaskingRules.length > 0,
      numOfNegatedMaskingRules: negatedMaskingRules.length
    })
  }

  return (
    <MaskingButtonControl
      entryType={entryType}
      isResponseOption
      entries={flatDraftQuestionnaireEntries}
      onSave={handleSave}
      entryItemLk={questionLk}
      maskedItemLk={responseOptionLk}
      maskingRules={responseOptionMasking}
      responseOptionsByQuestion={responseOptionsByQuestion}
    />
  )
}

export default memo(MaskingButton)
