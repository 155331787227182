import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionTypeCode
} from '../data/model/questionnaire'

export const countNumberQuestionsByType = (
  questionnaireEntries: DraftQuestionnaireEntry[] | undefined,
  questionTypeCode: QuestionTypeCode
) => {
  if (questionnaireEntries) {
    const numberOfQuestionByType = questionnaireEntries.filter(
      (entry) =>
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (entry?.entryItem as DraftQuestionItem)?.questionTypeCode ===
        questionTypeCode
    ).length

    return numberOfQuestionByType
  }
  return 0
}
