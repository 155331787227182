import { useMutation } from '@apollo/client'
import {
  AudienceCriterionState,
  CustomQuotaInputInput,
  UpdateAudienceMemberV2Mutation,
  UpdateAudienceMemberV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import { UPDATE_AUDIENCE_MEMBER_V2 } from '../../data/gql/questionnaire/mutations/updateAudienceMemberV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { customCriteriaRefetchQuery } from '../../data/gql/questionnaire/queries/customCriteria'
import { fieldworkAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/fieldworkAudience'
import { getFeasibilityEstimateRefetchQuery } from '../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../../data/logger'
import { useSurveyLengthData } from '../../modules/Audience/AudienceQuestionCard/AudienceQuestionCard.hooks'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useUpdateAudienceMemberV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { loi } = useSurveyLengthData()
  const [updateAudienceMemberV2] = useMutation<
    UpdateAudienceMemberV2Mutation,
    UpdateAudienceMemberV2MutationVariables
  >(UPDATE_AUDIENCE_MEMBER_V2, {
    context: { clientName: 'questionnaire' },
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'UPDATEAudienceMemberV2',
        error
      )
    }
  })

  const updateAudienceMemberV2Settings = (
    memberId: string,
    newSettings: UpdateAudienceMemberV2MutationVariables['input']['settings']
  ) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId,
          settings: newSettings
        }
      },
      refetchQueries: [
        draftAudienceRefetchQuery(projectId, surveyId),
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
      ]
    })
  }

  const updateStandardMemberV2Quotas = (
    memberId: string,
    quotas: CustomQuotaInputInput[]
  ) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId,
          updateStandardMember: {
            standardQuotas: quotas
          }
        }
      },
      refetchQueries: [
        draftAudienceRefetchQuery(projectId, surveyId),
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
      ]
    }).catch((err) =>
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateAudienceMemberV2',
        err
      )
    )
  }

  const updateAudienceMemberV2Position = (
    memberId: string,
    position: number
  ) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId,
          position
        }
      },
      refetchQueries: [
        draftAudienceRefetchQuery(projectId, surveyId),
        getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
      ]
    }).catch((err) =>
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateAudienceMemberV2',
        err
      )
    )
  }

  const toggleCustomAudienceMemberState = (
    memberId: string,
    enabled: boolean
  ) => {
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId,
          updateCustomMember: {
            state: enabled
              ? AudienceCriterionState.Enabled
              : AudienceCriterionState.Disabled
          }
        }
      },
      refetchQueries: [
        draftAudienceRefetchQuery(projectId, surveyId),
        fieldworkAudienceRefetchQuery(surveyId),
        customCriteriaRefetchQuery(projectId, surveyId)
      ]
    })
  }

  return {
    updateAudienceMemberV2,
    updateAudienceMemberV2Settings,
    updateStandardMemberV2Quotas,
    updateAudienceMemberV2Position,
    toggleCustomAudienceMemberState
  }
}
