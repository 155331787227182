import {
  Grid,
  InfoIcon,
  Text,
  TextHighlight,
  TextSize,
  TextWeight,
  additionalPalette
} from '@focaldata/cin-ui-components'
import React from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceCount from '../AudienceCount/AudienceCount.container'
import { AudienceSummaryContainer } from '../AudienceSummary'
import { CustomAudienceListContainer } from '../CustomAudienceList'
import { StandardAudienceListContainer } from '../StandardAudienceList'
import { SurveyAudienceListContainer } from '../SurveyAudienceList'
import { MIN_WIDTH_BEFORE_RESIZING } from '../constants'
import useStyles from './AudienceCriteriaConfigurator.styles'

// This may end up just being a layout
const AudienceCriteriaConfigurator: React.FC = () => {
  const { classes } = useStyles()
  const { windowWidth } = useWindowSize()
  const { isFdChat } = useProjectType()

  const isWindowResized = windowWidth < MIN_WIDTH_BEFORE_RESIZING
  const height = isFdChat ? 'calc(100vh - 255px)' : undefined

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.root}
      >
        <Grid
          item
          className={classes.audienceTypes}
          style={{
            height,
            overflowY: 'auto'
          }}
        >
          <Grid
            container
            direction="column"
            height={'100%'}
            flexWrap={'nowrap'}
          >
            {!isFdChat && (
              <div
                style={{
                  borderBottom: `1px solid ${additionalPalette.lightGrey.main}`
                }}
              >
                <StandardAudienceListContainer />
              </div>
            )}
            <Grid className={classes.customAudienceList}>
              <Grid
                container
                className={classes.customAudienceTitle}
                direction="row"
                alignItems="center"
              >
                <Text
                  weight={TextWeight.Bold}
                  highlight={TextHighlight.Standard}
                  size={TextSize.m}
                >
                  {isFdChat ? 'Profiling & Screening' : 'Screening'}
                </Text>
                <InfoIcon
                  text={
                    isFdChat
                      ? 'Add a new question below and use the qualifying toggle if you want to screen certain respondents out. Any questions created will be asked as audience survey questions before the interview.'
                      : 'Use screening questions to qualify respondents out of the survey. Add quotas to ensure a representative sample. Adding screening questions will increase the cost of the survey because a higher % of respondents will be turned away before answering your survey.'
                  }
                />
              </Grid>
              <CustomAudienceListContainer />
            </Grid>
            {isFdChat && (
              <Grid
                container
                alignItems={'center'}
                gap={'5px'}
                style={{ marginTop: 'auto', padding: '32px' }}
              >
                <AudienceCount />
              </Grid>
            )}
          </Grid>
        </Grid>
        <SurveyAudienceListContainer height={height} />
        {!isWindowResized && (
          <Grid
            item
            className={classes.summary}
            style={{
              height,
              overflowY: 'auto'
            }}
          >
            <Text weight={TextWeight.Bold} size={TextSize.m}>
              Audience summary
            </Text>
            <AudienceSummaryContainer />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default AudienceCriteriaConfigurator
