import {
  Checkbox,
  Grid,
  Paper,
  Radio,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'

type MapAudienceQuestionCardVariant = 'regular' | 'radio' | 'checkbox'

export const MapAudienceQuestionCard = ({
  variant,
  header,
  question,
  selected,
  disabled,
  onSelect
}: {
  variant: MapAudienceQuestionCardVariant
  header: string
  question: string
  selected?: boolean
  disabled?: boolean
  onSelect?: (value: boolean) => void
}) => {
  const handleCardClick = () => {
    if (disabled) {
      return
    }
    onSelect?.(!selected)
  }

  const selectorComponent = (() => {
    const disabledColor = '#72758D'
    switch (variant) {
      case 'radio':
        return (
          <Grid
            item
            sx={{
              '& input:disabled + span svg': {
                fill: disabledColor
              }
            }}
          >
            <Radio disabled={disabled} checked={selected} />
          </Grid>
        )
      case 'checkbox':
        return (
          <Grid
            item
            sx={{
              '& input:disabled + svg': {
                fill: disabledColor
              }
            }}
          >
            <Checkbox disabled={disabled} checked={selected} />
          </Grid>
        )
      case 'regular':
      default:
        return null
    }
  })()

  const textHighlight = disabled
    ? TextHighlight.Background
    : TextHighlight.Standard

  return (
    <Paper
      data-testid="map-audience-question-card"
      elevation={1}
      sx={{
        paddingY: 2,
        paddingX: 3,
        boxSizing: 'border-box',
        cursor: !!onSelect && !disabled ? 'pointer' : 'default'
      }}
      onClick={handleCardClick}
    >
      <Grid container>
        {selectorComponent}
        <Grid item>
          <Text highlight={textHighlight} weight={TextWeight.SemiBold}>
            {header}
          </Text>
          <Text highlight={textHighlight} size={TextSize.ms}>
            {question}
          </Text>
        </Grid>
      </Grid>
    </Paper>
  )
}
