import { Input, textStyleUtils } from '@focaldata/cin-ui-components'
import React, { memo, useState } from 'react'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import { DraftQuestionItem } from '../../../../data/model/questionnaire'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { WithEntry, propsAreEqual } from '../../../../utils/questionnaireUtils'
import useStyles from './styles'

interface Props extends WithEntry {
  onChangeSliderLabels?: (label: string, position: number) => void
}

enum LabelPosition {
  Left = 0,
  Middle = 1,
  Right = 2
}

const SliderQuestionControl: React.FC<Props> = (props: Props) => {
  const { entry, onChangeSliderLabels } = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [middleLabel, setMiddleLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  useDebounceEffect(
    () => {
      if (onChangeSliderLabels && leftLabel !== undefined) {
        onChangeSliderLabels(leftLabel, LabelPosition.Left)
      }
    },
    leftLabel,
    { delay: 500 }
  )

  useDebounceEffect(
    () => {
      if (onChangeSliderLabels && middleLabel !== undefined) {
        onChangeSliderLabels(middleLabel, LabelPosition.Middle)
      }
    },
    middleLabel,
    { delay: 500 }
  )

  useDebounceEffect(
    () => {
      if (onChangeSliderLabels && rightLabel !== undefined) {
        onChangeSliderLabels(rightLabel, LabelPosition.Right)
      }
    },
    rightLabel,
    { delay: 500 }
  )

  const getLabelFromPosition = (position: number) => {
    const questionScaleLabels = (entry.entryItem as DraftQuestionItem)
      .questionScale?.labels

    if (questionScaleLabels) {
      const label = questionScaleLabels.find(
        (questionScaleLabel) => questionScaleLabel.position === position
      )
      return (label && label.label.text) || ''
    }
    return ''
  }

  return (
    <div className="fd-grid fd-container fd-row">
      <div
        className={classNames(
          'fd-grid fd-container fd-column fd-xs-4',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Left-hand label
        </p>
        <Input
          defaultValue={getLabelFromPosition(LabelPosition.Left)}
          onChange={(event) => {
            if (onChangeSliderLabels) {
              setLeftLabel(event.target.value)
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
      <div
        className={classNames(
          'fd-grid fd-container fd-column fd-xs-4',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Central label
        </p>
        <Input
          defaultValue={getLabelFromPosition(LabelPosition.Middle)}
          onChange={(event) => {
            if (onChangeSliderLabels) {
              setMiddleLabel(event.target.value)
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
      <div
        className={classNames(
          'fd-grid fd-container fd-column fd-xs-4',
          classes.inputContainer
        )}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            classes.inputText
          )}
        >
          Right-hand label
        </p>
        <Input
          defaultValue={getLabelFromPosition(LabelPosition.Right)}
          onChange={(event) => {
            if (onChangeSliderLabels) {
              setRightLabel(event.target.value)
            }
          }}
          onFocus={() => {
            questionBeingEditedId(entry.id)
          }}
        />
      </div>
    </div>
  )
}

export default memo(SliderQuestionControl, propsAreEqual)
