import MuiLinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress'
import React from 'react'

import Collapse from '@mui/material/Collapse'
import useStyles from './LoadingBar.styles'

export enum LoadingBarColor {
  Primary,
  Secondary,
  Text,
  Background,
  Emphasis,
  Disabled,
  Enabled,
  Error,
  Warning,
  White
}

interface LoadingBarProps extends LinearProgressProps {
  barColor?: LoadingBarColor
  isLoading?: boolean
  timeout?: number
}

export const LoadingBar: React.FC<LoadingBarProps> = (
  props: LoadingBarProps
) => {
  const { classes } = useStyles()
  const {
    barColor = LoadingBarColor.Primary,
    isLoading = true,
    timeout = 750,
    ...restOfProps
  } = props

  const loadingBarColors = new Map<LoadingBarColor, string>([
    [LoadingBarColor.Primary, classes.primaryBarColor],
    [LoadingBarColor.Secondary, classes.secondaryBarColor],
    [LoadingBarColor.Text, classes.textBarColor],
    [LoadingBarColor.Background, classes.backgroundBarColor],
    [LoadingBarColor.Emphasis, classes.emphasisBarColor],
    [LoadingBarColor.Disabled, classes.disabledBarColor],
    [LoadingBarColor.Enabled, classes.enabledBarColor],
    [LoadingBarColor.Error, classes.errorBarColor],
    [LoadingBarColor.Warning, classes.warningBarColor],
    [LoadingBarColor.White, classes.whiteBarColor]
  ])
  const loadingColors = new Map<LoadingBarColor, string>([
    [LoadingBarColor.Primary, classes.primaryColor],
    [LoadingBarColor.Secondary, classes.secondaryColor],
    [LoadingBarColor.Text, classes.textColor],
    [LoadingBarColor.Background, classes.backgroundColor],
    [LoadingBarColor.Emphasis, classes.emphasisColor],
    [LoadingBarColor.Disabled, classes.disabledColor],
    [LoadingBarColor.Enabled, classes.enabledColor],
    [LoadingBarColor.Error, classes.errorColor],
    [LoadingBarColor.Warning, classes.warningColor],
    [LoadingBarColor.White, classes.whiteColor]
  ])

  const loadingBarColorCSS = loadingBarColors.get(barColor)
  const loadingColorCSS = loadingColors.get(barColor)

  return (
    <Collapse in={isLoading} timeout={timeout}>
      <MuiLinearProgress
        classes={{
          barColorPrimary: loadingBarColorCSS,
          colorPrimary: loadingColorCSS
        }}
        variant="indeterminate"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      />
    </Collapse>
  )
}

export default LoadingBar
