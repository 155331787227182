import { DOMParser } from '@xmldom/xmldom'
import PizZip from 'pizzip'
import { RawQuestionnaireEntry } from '../../../../hooks/import/models'
import { getQuestionsFromDoc } from './DocxProcessor.utils'

export function str2xml(str: any) {
  if (str.charCodeAt(0) === 65279) {
    // remove BOM sequence before parsing the xml
    // eslint-disable-next-line no-param-reassign
    str = str.substr(1)
  }
  return new DOMParser().parseFromString(str, 'text/xml')
}

const useDocxProcessor = () => {
  const getRawQuestionnaireFromDocx = (
    file: File
  ): Promise<RawQuestionnaireEntry[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event: any) => {
        try {
          const content = event.target.result
          const zip = new PizZip(content)
          const doc = str2xml(zip.files['word/document.xml'].asText())
          const getQuestionsFromXml = getQuestionsFromDoc(doc)
          resolve(getQuestionsFromXml)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = (error) => reject(error)

      reader.readAsBinaryString(file)
    })
  }

  return getRawQuestionnaireFromDocx
}

export default useDocxProcessor
