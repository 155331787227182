import { theme } from '@focaldata/cin-ui-components'

const { palette, typography } = theme

export const TextHighlight = {
  Background: palette.text.secondary,
  Error: palette.error.main,
  White: palette.common.white
}

export const TextSize = {
  ms: typography.body1.fontSize,
  m: typography.subtitle2.fontSize,
  s: typography.body2.fontSize
}

export const TextWeight = {
  SemiBold: typography.fontWeightMedium
}
