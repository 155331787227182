import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'

import useStyles from './styles'

const TermsAndConditions: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.content}>
      <Grid item className={classes.styleText}>
        <Grid container justifyContent="center">
          <h2>
            <strong>FOCALDATA TERMS AND CONDITIONS</strong>
          </h2>
        </Grid>
        <p>
          This agreement sets out the terms on which Focaldata Limited, a
          company incorporated and registered in England and Wales with company
          number 10550795 whose registered office is at 143 Mare Street,
          Hackney, London, United Kingdom, E8 3FW (<strong>Focaldata</strong>),
          will provide market research services to you (Customer).
        </p>
        <p>
          The Customer agrees to these terms which will bind the Customer if it
          either:
        </p>
        <ol>
          <li className={classes.noList}>
            1. clicks on the &ldquo;accept&rdquo; button on the &ldquo;Confirm
            Page&rdquo; when purchasing the Services (as defined below) through
            the Focaldata Platform; or
          </li>
          <li className={classes.noList}>
            2. indicates its acceptance to them, after Focaldata has sent a copy
            of these terms to the Customer, in writing including by email.
          </li>
        </ol>
        <p>BACKGROUND</p>
        <ul>
          <li className={classes.noList}>
            (A) Focaldata provides market research services, including obtaining
            survey responses using its software platform, and using multilevel
            regression with poststratification data modelling and other
            techniques to predict public opinion.
          </li>
          <li className={classes.noList}>
            (B) Focaldata has agreed to provide the Services on the terms set
            out in this Agreement.
          </li>
        </ul>
        <p>
          <strong>Agreed terms</strong>
        </p>
        <ol>
          <li className={classes.noList}>1. Interpretation</li>
        </ol>
        <ul>
          <li className={classes.noList}>
            1.1 The definitions and rules of interpretation in this clause apply
            in this Agreement and in any other agreement between the parties.
          </li>
          <li className={classes.noList}>
            <strong>Analytical Data:</strong> the data or information produced
            from an analysis of the Survey Responses carried out by Focaldata,
            in whatever form including images, still and moving, and sound
            recordings.
          </li>
          <li className={classes.noList}>
            <strong>Business Day:</strong> a day other than a Saturday, Sunday
            or public holiday in England when banks in London are open for
            business.
          </li>
          <li className={classes.noList}>
            <strong>Charges:</strong> the charges calculated in accordance with
            clause 5.2, or as otherwise stated in a statement of work in respect
            of Consultancy Services.
          </li>
          <li className={classes.noList}>
            <strong>Commencement Date:</strong> the date of acceptance by the
            Customer of these terms.
          </li>
          <li className={classes.noList}>
            <strong>Confidential Information:</strong> all confidential
            information (however recorded or preserved) disclosed by a party or
            its Representatives to the other party and that party&apos;s
            Representatives in connection with this Agreement, which is either
            labelled as such or else which should reasonably be considered as
            confidential because of its nature and the manner of its disclosure.
          </li>
          <li className={classes.noList}>
            <strong>Consultancy Services:</strong> consulting the Customer about
            the subject the subject matter described in the relevant statement
            of work, considering the Customer&rsquo;s requirements and providing
            advice to the Customer over and above allowing the Customer access
            to the Focaldata Platform and providing the Customer with the Survey
            Responses and Analytical Data. Consultancy Services may include
            advising the Customer on scripting Surveys, creating data tables,
            advising on the most appropriate Targeting Criteria for the
            Customer&rsquo;s requirements as indicated to Focaldata, and
            carrying out any additional services required to provide the
            Customer with analysis of the Survey Responses in excess of the
            Analytical Data.
          </li>
          <li className={classes.noList}>
            <strong>Conversion Rate:</strong> refers to the ratio calculated by
            the Focaldata Platform as:
          </li>
          <li className={classes.noList}>
            number of Survey Responses / number of respondents who started the
            Survey.
          </li>
          <li className={classes.noList}>
            <strong>Customer System:</strong> any information technology system
            or systems owned or operated by the Customer from which data is
            received in accordance with this Agreement.
          </li>
          <li className={classes.noList}>
            <strong>Customer User:</strong> any employee of the Customer
            authorised by the Customer to access and use the Services (wholly or
            in part), using their own unique identifier provided by Focaldata.
          </li>
          <li className={classes.noList}>
            <strong>Customer User Restrictions:</strong> the obligations set out
            in clause 7.3.
          </li>
          <li className={classes.noList}>
            <strong>Data:</strong> the Survey Responses and Analytical Data.
          </li>
          <li className={classes.noList}>
            <strong>Derived Data:</strong> any Data (wholly or in part)
            Manipulated to such a degree that it:
            <ul>
              <li className={classes.noList}>
                (a) cannot be identified as originating or deriving directly
                from the Data or the Services and cannot be reverse-engineered
                such that it can be so identified; and
              </li>
              <li className={classes.noList}>
                (b) is not capable of use substantially as a substitute for the
                Data or the Services.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>Drop Out Rate</strong> or <strong>DOR</strong>: refers to
            the ratio calculated by the Focaldata Platform as:
          </li>
          <li className={classes.noList}>
            Number of Timed Out Respondents / (number of Survey Responses +
            number of Screened Respondents + Number of Timed out Respondents)
          </li>
          <li className={classes.noList}>
            <strong>Focaldata Platform:</strong> Focaldata&rsquo;s web
            application accessible at focaldata.com, through which the Services
            are provided, and through which the Data is made available to the
            Customer.
          </li>
          <li className={classes.noList}>
            <strong>Incidence Rate</strong> or <strong>IR</strong>: the ratio
            calculated by the Focaldata Platform as:
          </li>
          <li className={classes.noList}>
            number of Survey Responses / (number of Survey Responses + number of
            Screened Respondents).
          </li>
          <li className={classes.noList}>
            <strong>Intellectual Property Rights:</strong> all patents, rights
            to inventions, utility models, copyright and related rights, trade
            marks, service marks, trade, business and domain names, rights in
            trade dress or get-up, rights in goodwill or to sue for passing off,
            unfair unfair competition rights, rights in designs, rights in
            computer software, database rights, semi-conductor topography
            rights, moral rights, rights in confidential information (including
            know-how and trade secrets) and any other intellectual property
            rights, in each case whether registered or unregistered and
            including all applications for and renewals or extensions of such
            rights, and all similar or equivalent rights or forms of protection
            in any part of the world.
          </li>
          <li className={classes.noList}>
            <strong>Manipulate:</strong> to combine or aggregate the Data
            (wholly or in part) with other data or information or to adapt the
            Data (wholly or in part).
          </li>
          <li className={classes.noList}>
            <strong>Normal Business Hours:</strong> 8.30 am to 6.00 pm GMT on a
            Business Day.
          </li>
          <li className={classes.noList}>
            <strong> Number of Timed Out Respondents:</strong> refers to the
            number of respondents who did not complete a Survey but began a
            Survey session.
          </li>
          <li className={classes.noList}>
            <strong>Representatives:</strong> in respect of a party, that
            party&apos;s employees, officers, representatives, advisers or
            sub-contractors involved in the provision or receipt of the Services
            who need to know the confidential information in question.
          </li>
          <li className={classes.noList}>
            <strong>Schedule:</strong> the schedule attached to these terms.
          </li>
          <li className={classes.noList}>
            <strong>Screened Respondents:</strong> refers to the respondents to
            a Survey that do not fit the Targeting Criteria and are screened out
            of a Survey.
          </li>
          <li className={classes.noList}>
            <strong>Security Feature:</strong> any security feature including
            any key, PIN, password, token or smartcard.
          </li>
          <li className={classes.noList}>
            <strong>Services:</strong> the market research services to be
            supplied by Focaldata under this Agreement, including:
            <ul>
              <li className={classes.noList}>
                (a) allowing the Customer access to the Focaldata Platform;
              </li>
              <li className={classes.noList}>
                (b) providing the Customer with the Survey Responses and
                Analytical Data in respect of the Survey; and
              </li>
              <li className={classes.noList}>
                (c) where agreed in writing between the parties, Consultancy
                Services.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>Support:</strong> the technical support to be supplied by
            Focaldata, namely using reasonable efforts to maintain the Focaldata
            Platform in good working order and to restore the Focaldata Platform
            (if (if unavailable). For the avoidance of doubt Support does not
            include any consultancy or professional services provided by
            Focaldata, including assistance or training in using the Platform,
            uploading the Survey, and scripting the Survey.
          </li>
          <li className={classes.noList}>
            <strong>Survey:</strong> the survey created by the Customer and
            uploaded to the Focaldata Platform.
          </li>
          <li className={classes.noList}>
            <strong>Survey Length</strong> or <strong>SL</strong>: the average
            length of the Survey calculated in minutes by the Focaldata
            Platform.
          </li>
          <li className={classes.noList}>
            <strong>Survey Responses:</strong> the completed responses obtained
            by Focaldata to the Survey from respondents who have not been
            screened out and which are retained at the end of the Survey.
          </li>
          <li className={classes.noList}>
            <strong>Survey Volume-Full Level:</strong> refers to the percentage
            by which the Survey Volume has been exceeded.
          </li>
          <li className={classes.noList}>
            <strong>Survey Volume:</strong> the approximate number of Survey
            Responses required by the Customer.
          </li>
          <li className={classes.noList}>
            <strong>Start Date(s):</strong> the approximate date(s) on which the
            Customer would like the Survey to be made available to potential
            respondents.
          </li>
          <li className={classes.noList}>
            <strong>Targeting Criteria:</strong> the criteria and
            characteristics that the Customer requires the respondents to the
            Survey to satisfy.
          </li>
          <li className={classes.noList}>
            <strong>Virus:</strong> any thing or device (including any software,
            code, file or programme) which may: prevent, impair or otherwise
            adversely affect the operation of any computer software, hardware or
            network, any telecommunications service, equipment or network or any
            other service or device; prevent, impair or otherwise adversely
            affect access to or the operation of any programme or data,
            including the reliability of any programme or data (whether by
            rearranging, altering or erasing the programme or data in whole or
            part or otherwise); or adversely affect the user experience,
            including worms, Trojan horses, viruses and other similar things or
            devices.
          </li>
          <li className={classes.noList}>
            <strong>Vulnerability:</strong> a weakness in the computational
            logic (for example, code) found in software and hardware components
            that, when exploited, results in a negative impact to
            confidentiality, integrity, or availability.
            <ul>
              <li className={classes.noList}>
                1.2 A person includes a natural person, corporate or
                unincorporated body (whether or not having separate legal
                personality) and that person&apos;s personal representatives,
                successors and permitted assigns. A reference to a statute or
                statutory provision is a reference to it as amended, extended or
                re-enacted from time to time. A reference to a statute or
                statutory provision shall include all subordinate legislation
                made from time to time under that statute or statutory
                provision.
              </li>
              <li className={classes.noList}>
                1.3 A reference to writing or written includes e-mail. Any words
                following the terms including, include, in particular, for
                example or any similar expression shall be construed as
                illustrative and shall not limit the sense of the words,
                description, definition, phrase or term preceding those terms.
                References to clauses are to the clauses of this Agreement.
              </li>
            </ul>
          </li>
        </ul>
        <ol>
          <li className={classes.noList}>
            <strong>2. Scope of this Agreement</strong>
            <ul>
              <li className={classes.noList}>
                2.1 These terms and conditions apply to the provision of
                Services by Focaldata to the Customer in respect of one Survey,
                and any other Consultancy Services Focaldata agrees to provide
                in a statement of work, and the payment by the Customer to
                Focaldata of the Charges (the{' '}
                <strong>&ldquo;Agreement&rdquo;</strong>), and will have effect
                from the Commencement Date.
              </li>
              <li className={classes.noList}>
                2.2 Focaldata requires that the Customer accept these terms and
                conditions each time it requests the provision of the Services
                in respect of a Survey via the Focaldata Platform, or otherwise
                in writing including by email where Consultancy Services are
                purchased.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>3. Services</strong>
            <ul>
              <li className={classes.noList}>
                3.1 The Customer shall purchase the Services through the
                Focaldata Platform in respect of the Survey.
              </li>
              <li className={classes.noList}>
                3.2 The Customer shall input the details of the Services
                required into the Focaldata Platform, including:
                <ul>
                  <li className={classes.noList}>
                    (a) the Targeting Criteria;
                  </li>
                  <li className={classes.noList}>
                    (b) the approximate Survey Volume; and
                  </li>
                  <li className={classes.noList}>(c) the Start Date(s),</li>
                </ul>
                <p>
                  together the &ldquo;<strong>Project Details</strong>&rdquo;.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <ul>
          <li className={classes.noList}>
            3.3 Where the Survey is deemed feasible by Focaldata, and using the
            Project Details provided, Focaldata will calculate the IR and
            provide the Customer with:
            <ul>
              <li className={classes.noList}>
                (a) the rate at which the Charges for deploying the Survey will
                be calculated, and an estimate of the likely Charges for
                deploying the Survey based on the Project Details; and
              </li>
              <li className={classes.noList}>
                (b) an estimated date for delivery to the Customer of the Data.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            3.4 The Customer acknowledges and accepts that the estimated date
            for delivery is an estimate only and Focaldata cannot predict with
            complete certainty the feasibility of the Survey. The Customer will
            not hold Focaldata liable in the event that the Survey Volume is not
            achieved by the estimated delivery date. In the event that the
            Survey Volume is not achieved by the estimated delivery date,
            Focaldata will inform the Customer as such, and the Customer will
            have the option of continuing to deploy the Survey, or ending its
            deployment on that date and paying only for the Survey Responses
            delivered, in accordance with clause 5.
          </li>
          <li className={classes.noList}>
            3.5 Focaldata shall use its reasonable endeavours to provide the
            Services in accordance with the Project Details.
          </li>
          <li className={classes.noList}>
            3.6 Focaldata may change at any time, with as much prior notice to
            the Customer as is reasonably practicable:
            <ul>
              <li className={classes.noList}>
                (a) the content, format or nature of the Focaldata Platform; and
              </li>
              <li className={classes.noList}>
                (b) the means of access to the Focaldata Platform, the Data or
                the Services.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            3.7 Focaldata will, as part of the Services and at no additional
            cost to the Customer, provide the Customer with the Support during
            Normal Business Hours. The Customer may purchase enhanced support
            services separately at Focaldata&apos;s then current rates.
          </li>
          <li className={classes.noList}>
            3.8 Where agreed between the parties in writing in a statement of
            work, and such statement of work may be included in an email,
            Focaldata may provide Consultancy Services to the Customer in
            accordance with that statement of work, which shall be subject to
            the terms of this Agreement. Unless the statement of work
            specifically states that Charges for Consultancy Services are
            &lsquo;fixed&rsquo;, the Charges for Consultancy Services are
            calculated on a time and materials basis and any Charges specified
            in the statement of work are estimated.
          </li>
        </ul>
        <ol>
          <li className={classes.noList}>
            <strong>4. Deployment of Surveys</strong>
            <ul>
              <li className={classes.noList}>
                4.1 Focaldata reserves the right to pause deployment of the
                Survey for, but not limited to, the following reasons:
                <ul>
                  <li className={classes.noList}>
                    (a) the IR changes 25% from the IR originally estimated (as
                    presented via the Focaldata Platform);
                  </li>
                  <li className={classes.noList}>
                    (b) the SL changes 25% from the SL originally estimated;
                  </li>
                  <li className={classes.noList}>(c) the DOR exceeds 25%;</li>
                  <li className={classes.noList}>
                    (d) the Survey Volume-Full Level exceeds 25%; or
                  </li>
                  <li className={classes.noList}>
                    (e) the Conversion Rate falls below 5%.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                4.2 Focaldata reserves the right to permanently stop deployment
                of the Survey for, but not limited to, the following reasons:
                <ul>
                  <li className={classes.noList}>
                    (a) Focaldata&rsquo;s country SL or IR rules are broken;
                  </li>
                  <li className={classes.noList}>
                    (b) the DOR reaches or exceeds 40%;
                  </li>
                  <li className={classes.noList}>
                    (c) the Survey Volume-Full Level reaches or exceeds 35%; or
                  </li>
                  <li className={classes.noList}>
                    (d) the Conversion Rate reaches or falls below 4%.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                4.3 Focaldata reserves the right to charge the Customer for any
                delivered Survey Responses where deployment of the Survey has
                been stopped pursuant to clause 4.2 or other reasonable grounds.
              </li>
              <li className={classes.noList}>
                4.4 Provided Focaldata gives the Customer prior written notice
                via email, Focaldata reserves the right to pause deployment of a
                Survey to troubleshoot issues related to the DOR if the DOR in
                respect of any given Survey exceeds 25%. The parties will work
                together to troubleshoot issues and reduce the DOR to an
                acceptable level, and once steps have been taken to reduce the
                DOR Focaldata will promptly recommence deployment of the Survey.
                If the DOR in respect of any given Survey reaches 40%, Focaldata
                reserves the right to cancel deployment of the Survey entirely
                after giving notice to the Customer via email. An additional fee
                of 1 EUR/GBP/USD per respondent may be charged on a case-by-case
                basis if the DOR exceeds 25% as a result of any act or omission
                of the Customer.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>5. Charges</strong>
            <ul>
              <li className={classes.noList}>
                5.1 For the performance of the Services, the Customer shall pay
                to Focaldata the Charges.
              </li>
              <li className={classes.noList}>
                5.2 The Charges (other than for Consultancy Services) shall be
                calculated on the basis of the number of Survey Responses
                delivered to the Customer in accordance with the rate indicated
                to the Customer by the Focaldata Platform.
              </li>
              <li className={classes.noList}>
                5.3 The Customer acknowledges and agrees that it shall pay the
                Charges for all Survey Responses delivered, regardless of
                whether the Survey Volume is reached. No refund, cancellation or
                reduction of the Charges shall apply:
                <ul>
                  <li className={classes.noList}>
                    (a) where the Customer cancels the Survey at any time, or
                    otherwise pauses the Survey and fails to resume it within 30
                    days (and in such circumstances the Charges charged will be
                    as quoted by the Focaldata Platform unless the actual SL
                    differs more than +/-10% from the estimated SL and/or the IR
                    differs more than +/-10% from the estimated IR at the time
                    of quoting, and Focaldata may charge the Customer a
                    cancellation fee not exceeding 500 EUR/GBP/USD);
                  </li>
                  <li className={classes.noList}>
                    (b) for any late delivery or failure of the Data to comply
                    with the Project Details (e.g., the Survey Volume is not
                    achieved);
                  </li>
                  <li className={classes.noList}>
                    (c) for any failure of the Services to yield the results
                    desired by the Customer where such failure is caused by the
                    information provided by the Customer, the nature of the
                    Survey created and uploaded by the Customer, or any failure
                    by the Customer to use the Focaldata Platform.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                5.4 The Customer shall ahead of the Commencement Date provide to
                Focaldata valid up-to-date and complete contact and billing
                details.
              </li>
              <li className={classes.noList}>
                5.5 Focaldata shall be entitled to invoice for the Charges that
                relate to the Consultancy Services at the end of each month in
                which the Consultancy Services are delivered. The Customer shall
                pay such invoices within 30 days of receipt.
              </li>
              <li className={classes.noList}>
                5.6 The Customer agrees that Focaldata may, without liability to
                the Customer, disable the Customer’s password, account and
                access to all or part of the Focaldata Platform in the event
                that Focaldata does not receive full payment within the agreed
                invoice period.
              </li>
              <li className={classes.noList}>
                5.7 Focaldata may charge interest at an annual rate of 4% above
                the base rate of NatWest, calculated on a daily basis in respect
                of any sum which is due and unpaid, that interest to run from
                the date on which that sum is due and payable until receipt by
                Focaldata of the full amount, whether before or after judgment.
              </li>
              <li className={classes.noList}>
                5.8 All Charges are exclusive of VAT or any other applicable
                sales tax, which shall be paid by the Customer at the rate and
                in the manner for the time being prescribed by law.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>6. Audit</strong>
            <ul>
              <li className={classes.noList}>
                6.1 The Customer shall keep, in paper and electronic form, at
                its normal place of business detailed, accurate and up-to-date
                records (<strong>&ldquo;Records&rdquo;</strong>) showing, during
                the previous three years the steps taken by the Customer to
                comply with the Customer User Restrictions. The Customer shall
                ensure that the Records are sufficient to enable Focaldata to
                verify the Customer&apos;s compliance with its obligations under
                this clause 6.
              </li>
              <li className={classes.noList}>
                6.2 The Customer shall permit Focaldata and its third-party
                representatives (including its designated auditor), on
                reasonable notice during Normal Business Hours, but without
                notice in case of any reasonably suspected breach of the
                Customer User Restrictions, to:
                <ul>
                  <li className={classes.noList}>
                    (a) gain (physical and remote electronic) access to, and
                    take copies of, the Records and any other information held
                    at the Customer&apos;s premises or on the Customer System;
                    and
                  </li>
                  <li className={classes.noList}>
                    (b) inspect all Records and Customer Systems relating to the
                    use, permissioning and control of the Data and the Services,
                  </li>
                </ul>
                <p>
                  for the purpose of auditing the accuracy of the Records and
                  the Customer&apos;s compliance with its obligations under this
                  Agreement including the Customer User Restrictions. Such audit
                  rights shall continue for three years after termination of
                  this Agreement. The Customer shall give all necessary
                  assistance to the conduct of such audits during the term of
                  this Agreement and for a period of three years after
                  termination of this Agreement.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>7. Customer Responsibilities</strong>
            <ul>
              <li className={classes.noList}>
                7.1 The Customer shall provide Focaldata with:
                <ul>
                  <li className={classes.noList}>
                    (a) all necessary co-operation in relation to this
                    Agreement; and
                  </li>
                  <li className={classes.noList}>
                    (b) all necessary access to such information as may be
                    required by Focaldata;
                  </li>
                </ul>
                <p>
                  in order to provide the Services, including but not limited to
                  Project Details;
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <ul>
          <li className={classes.noList}>
            7.2 If any unauthorised use is made of the Data or Focaldata
            Platform and such use is attributable to the act or default of, or
            through, the Customer (including breach of any Customer User
            Restrictions) then, without prejudice to Focaldata&apos;s other
            rights and remedies, the Customer shall immediately be liable to pay
            Focaldata an amount equal to the Charges that Focaldata would have
            charged, had Focaldata or the Customer (as the case may be)
            authorised the unauthorised use at the beginning of the period of
            that unauthorised use together with interest at the rate provided
            for in clause 5.7 from the date of that unauthorised use to the date
            of payment.
          </li>
          <li className={classes.noList}>
            7.3 The Customer shall:
            <ul>
              <li className={classes.noList}>
                (a) limit access to the Services to the Customer Users;
              </li>
              <li className={classes.noList}>
                (b) be solely responsible for all Survey content. The Customer
                agrees that all Survey content will:
                <ul>
                  <li className={classes.noList}>
                    (i) comply with all applicable international, national and
                    local laws and regulations, including but not limited to
                    laws and regulations regarding the interviewing of children;
                    and
                  </li>
                  <li className={classes.noList}>
                    (ii) contain the proper language translations;
                  </li>
                  <li className={classes.noList}>
                    (iii) not contain inappropriate or offensive language;
                  </li>
                  <li className={classes.noList}>
                    (iv) be complete, accurate, and tested;
                  </li>
                  <li className={classes.noList}>
                    (v) not infringe any third party intellectual property
                    rights; and
                  </li>
                  <li className={classes.noList}>
                    (vi) comply with applicable market research industry codes
                    (including but not limited to ESOMAR, www.esomar.org and the
                    Insights Association, www.insightsassociation.org),
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              and Focaldata reserves the right to remove any Survey content that
              violates the terms of this Agreement;
            </p>
          </li>
        </ul>
        <ul>
          <li className={classes.noList}>
            (c) be responsible for informing Focaldata if there are any
            questions included in the Survey that collect personal data;
          </li>
          <li className={classes.noList}>
            (d) only make copies of the Data to the extent reasonably necessary
            for the following purposes: back-up, mirroring (and similar
            availability enhancement techniques), security, disaster recovery
            and testing;
          </li>
          <li className={classes.noList}>
            (e) not use the Data or Focaldata Platform for any purpose contrary
            to any law or regulation or any regulatory code, guidance or
            request;
          </li>
          <li className={classes.noList}>
            (f) not extract, reutilise, use, exploit, redistribute,
            re-disseminate, copy or store the Data for any purpose not expressly
            permitted by this Agreement;
          </li>
          <li className={classes.noList}>
            (g) not copy, modify, decompile, reverse engineer or create
            derivative works from the Focaldata Platform, except to the extent
            permitted by any applicable law;
          </li>
          <li className={classes.noList}>
            (h) not do anything which may damage the reputation of Focaldata,
            the Data or the Focaldata Platform, including by way of using the
            Data (wholly or in part) in any manner which is pornographic, racist
            or that incites religious hatred or violence;
          </li>
          <li className={classes.noList}>
            (i) not access all or any part of the Focaldata Platform in order to
            build a product or service which competes with the Services;
          </li>
          <li className={classes.noList}>
            (j) not use the Services and/or Focaldata Platform to provide
            services to third parties;
          </li>
          <li className={classes.noList}>
            (k) not license, sell, rent, lease, transfer, assign, distribute,
            display, disclose, or otherwise commercially exploit, or otherwise
            make the Services and/or Focaldata Platform available to any third
            party except the Customer Users,
          </li>
          <li className={classes.noList}>
            (l) not attempt to obtain, or assist third parties in obtaining,
            access to the Services and/or Focaldata Platform, other than as
            provided under this Agreement;
          </li>
          <li className={classes.noList}>
            (m) not introduce or permit the introduction of, any Virus or
            Vulnerability into Focaldata&apos;s network and information systems.
          </li>
        </ul>
        <ul>
          <li className={classes.noList}>
            7.4 Focaldata is not liable for any delay or inability to use or
            access the Services or Focaldata Platform in whole or part directly
            or indirectly caused by the Customer, or any failure to comply with
            the Customer obligations under this Agreement and without prejudice
            to any other right or remedy it may have, Focaldata shall be allowed
            an extension of time to perform its obligations equal to the delay
            so caused.
          </li>
        </ul>
        <ol>
          <li className={classes.noList}>
            <strong>8. Confidentiality</strong>
            <ul>
              <li className={classes.noList}>
                8.1 The term Confidential Information does not include any
                information that:
                <ul>
                  <li className={classes.noList}>
                    (a) is or becomes generally available to the public (other
                    than as a result of its disclosure by the receiving party or
                    its Representatives in breach of this clause 8);
                  </li>
                  <li className={classes.noList}>
                    (b) was available to the receiving party on a
                    non-confidential basis before disclosure by the disclosing
                    party;
                  </li>
                  <li className={classes.noList}>
                    (c) was, is, or becomes, available to the receiving party on
                    a non-confidential basis from a person who, to the receiving
                    party&apos;s knowledge, is not bound by a confidentiality
                    agreement with the disclosing party or otherwise prohibited
                    from disclosing the information to the receiving party;
                  </li>
                  <li className={classes.noList}>
                    (d) was known to the receiving party before the information
                    was disclosed to it by the disclosing party; or
                  </li>
                  <li className={classes.noList}>
                    (e) the parties agree in writing is not confidential or may
                    be disclosed.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                8.2 Each party shall keep the other party&apos;s Confidential
                Information confidential and shall not:
                <ul>
                  <li className={classes.noList}>
                    (a) use any Confidential Information except for the purpose
                    of exercising or performing its rights and obligations under
                    this Agreement <strong>(Permitted Purpose)</strong>; or
                  </li>
                  <li className={classes.noList}>
                    (b) disclose any Confidential Information in whole or in
                    part to any third party, except as expressly permitted by
                    this clause.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                8.3 A party may disclose the other party&apos;s Confidential
                Information to those of its Representatives who need to know
                that Confidential Information for the Permitted Purpose,
                provided that:
                <ul>
                  <li className={classes.noList}>
                    (a) it informs those Representatives of the confidential
                    nature of the Confidential Information before disclosure;
                    and
                  </li>
                  <li className={classes.noList}>
                    (b) at all times, it is responsible for the
                    Representatives&apos; compliance with the confidentiality
                    obligations set out in this clause 8.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                8.4 The Customer acknowledges that Focaldata&apos;s Confidential
                Information includes any software or other materials created by
                Focaldata in connection with the Services.
              </li>
              <li className={classes.noList}>
                8.5 A party may disclose Confidential Information to the extent
                required by law, by any governmental or other regulatory
                authority, or by a court or other authority of competent
                jurisdiction provided that, to the extent it is legally
                permitted to do so, it gives the other party as much notice of
                the disclosure as possible.
              </li>
              <li className={classes.noList}>
                8.6 Each party reserves all rights in its Confidential
                Information. No rights or obligations in respect of a
                party&apos;s Confidential Information, other than those
                expressly stated in this Agreement, are granted to the other
                party, or are to be implied from this Agreement.
              </li>
              <li className={classes.noList}>
                8.7 The provisions of this clause 8 shall continue to apply
                after termination of this Agreement.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>9. Data Protection, Security and passwords</strong>
            <ul>
              <li className={classes.noList}>
                9.1 Where personal data of Survey respondents is collected by or
                on behalf of the Customer, or provided to the Customer by
                Focaldata, during the course of the Services, the terms of the
                Schedule shall apply.
              </li>
              <li className={classes.noList}>
                9.2 The Customer shall ensure that the Data are kept secure by
                using the Security Features and in an encrypted form, and shall
                use the best available security practices and systems applicable
                to the use of the Data to prevent, and take prompt and proper
                remedial action against, unauthorised access, copying,
                modification, storage, reproduction, display or distribution of
                the Data.
              </li>
              <li className={classes.noList}>
                9.3 Where Focaldata uses Security Features in relation to the
                Services (wholly or in part), the Security Features must, unless
                Focaldata notifies the Customer otherwise, be kept confidential
                and not lent, shared, transferred or otherwise misused.
              </li>
              <li className={classes.noList}>
                9.4 If the Customer becomes aware of any misuse of any Data, or
                any security breach in connection with this Agreement that could
                compromise the security or integrity of the Data or the
                Focaldata Platform or otherwise adversely affect Focaldata or if
                the Customer learns or suspects that any Security Feature has
                been revealed to or obtained by any unauthorised person, the
                Customer shall, at the Customer&apos;s expense, immediately
                notify Focaldata and fully co-operate with Focaldata to remedy
                the issue as soon as reasonably practicable.
              </li>
              <li className={classes.noList}>
                9.5 The Customer agrees to co-operate with Focaldata&apos;s
                reasonable security investigations.
              </li>
              <li className={classes.noList}>
                9.6 Focaldata may change Security Features on notice to the
                Customer or the Customer Users for security reasons.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>10. Ownership of Data</strong>
            <ul>
              <li className={classes.noList}>
                10.1 Focaldata&rsquo;s business relies on its data models and
                the data Focaldata collects, and Focaldata needs to be able to
                use the data it collects in order to continue to develop and
                improve its data models. As such, the Customer acknowledges and
                agrees that Focaldata shall own the Intellectual Property Rights
                in the Data. Ownership in the Data shall vest in Focaldata on
                creation.
              </li>
              <li className={classes.noList}>
                10.2 Focaldata agrees that it will not resell the Data to other
                customers in the same format as it is provided to the Customer
                for a conflicting purpose, but may use the Data and provide it
                to other customers in the course of providing Services to them
                for the purposes of contextualisation and benchmarking, or in
                the form of Derived Data. Nothing will prohibit or restrict
                Focaldata from storing the Data, detecting fraud, using the Data
                to improve the Focaldata Platform and Services, accessing,
                viewing and Manipulating Data and using the Data to create
                Derived Data, create aggregated statistical data and reports,
                and incorporating the Survey and Data into its data models
              </li>
              <li className={classes.noList}>
                10.3 Focaldata nevertheless understands that its customers will
                need a right to use the Data as it wishes. As such, Focaldata
                shall grant to the Customer a perpetual, irrevocable,
                transferable, sub-licenseable, royalty-free, worldwide licence
                in the Data, effective on creation, to use the Data for its own
                purposes. Focaldata acknowledges and agrees that the Customer
                shall own the questions that comprise the Survey, and any other
                materials included in the Survey already owned by the Customer
                before entering into this Agreement, such as any Customer logos
                or branding (the{' '}
                <strong>&ldquo;Customer Materials&rdquo;</strong>
                ). The Customer grants Focaldata a perpetual, irrevocable,
                transferable, sub-licenseable, royalty-free, worldwide licence
                to use the Customer Materials to:
                <ul>
                  <li className={classes.noList}>
                    (a) fulfil Focaldata&rsquo;s obligations under this
                    Agreement; and
                  </li>
                  <li className={classes.noList}>
                    (b) for its own purposes including to store the Data, detect
                    fraud, improve the Focaldata Platform and Services, access,
                    view and Manipulate Data and create Derived Data, create
                    aggregated statistical data and reports, and incorporate the
                    Survey and Data into its data models.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                10.4 The Customer acknowledges that:
                <ul>
                  <li className={classes.noList}>
                    (a) all Intellectual Property Rights in the Focaldata
                    Platform, Focaldata&rsquo;s Services (including any machine
                    learning, AI or data model, statistical technique,
                    predictions, algorithm or technology), and the Derived Data
                    are the property of Focaldata or its licensors, as the case
                    may be; and
                  </li>
                  <li className={classes.noList}>
                    (b) it shall have no rights in or to the Focaldata Platform
                    and the Derived Data other than the right to use them in
                    accordance with the express terms of this Agreement.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                10.5 Any display of the Services by the Customer shall credit,
                wherever technically and commercially feasible, Focaldata, any
                licensor of Focaldata or any other source of the Data specified
                by Focaldata as the source of the Data.
              </li>
              <li className={classes.noList}>
                10.6 The Customer acknowledges that reference in any element of
                the Focaldata Platform or the Data to trade names or proprietary
                products where no specific acknowledgement of such names or
                products is made does not imply that such names or products may
                be regarded by the Customer as free for general use, outside the
                scope of the use of the Focaldata Platform or the Data
                authorised by this Agreement.
              </li>
              <li className={classes.noList}>
                10.7 The Customer hereby grants to Focaldata a non-exclusive,
                perpetual, irrevocable, royalty-free, fully paid-up,
                sub-licensable, worldwide licence to use, reproduce, display,
                publish, transmit, host, and distribute the Customer&rsquo;s
                name, trade marks (whether registered or unregistered), logos
                and other branding, and all Intellectual Property Rights
                subsisting therein, for Focaldata&rsquo;s marketing and
                promotional purposes.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>11. Intellectual property rights obligation</strong>
            <ul>
              <li className={classes.noList}>
                11.1 Focaldata undertakes to defend the Customer from and
                against any claim or action that the use of the Focaldata
                Platform (wholly or in part) infringes any UK Intellectual
                Property Right (excluding any US Intellectual Property Right) of
                a third party (<strong>&ldquo;IPR Claim&rdquo;</strong>) and
                shall be responsible for any losses, damages, costs (including
                all legal fees) and expenses incurred by or awarded against the
                Customer as a result of, or in connection with, any such IPR
                Claim, provided that, if any third party makes an IPR Claim, or
                notifies an intention to make an IPR Claim against the Customer,
                the Customer shall:
                <ul>
                  <li className={classes.noList}>(a) mitigate its loss;</li>
                  <li className={classes.noList}>
                    (b) give written notice of the IPR Claim to Focaldata as
                    soon as reasonably practicable;
                  </li>
                  <li className={classes.noList}>
                    (c) not make any admission of liability in relation to the
                    IPR Claim without the prior written consent of Focaldata;
                  </li>
                  <li className={classes.noList}>
                    (d) at Focaldata&apos;s request and expense, allow Focaldata
                    to conduct the defence of the IPR Claim including
                    settlement; and
                  </li>
                  <li className={classes.noList}>
                    at Focaldata&apos;s expense, co-operate and assist to a
                    reasonable extent with Focaldata&apos;s defence of the IPR
                    Claim.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                11.2 Clause 11.1 shall not apply where the IPR Claim in question
                is attributable to use of the Focaldata Platform by the Customer
                not in accordance with this Agreement, or the alleged
                infringement in question relates to Survey content submitted by
                the Customer.
              </li>
              <li className={classes.noList}>
                11.3 If any IPR Claim is made, or in Focaldata&apos;s reasonable
                opinion is likely to be made, against the Customer, Focaldata
                may at its sole option and expense:
                <ul>
                  <li className={classes.noList}>
                    (a) procure for the Customer the right to continue using,
                    the Focaldata Platform (wholly or in part) in accordance
                    with this Agreement;
                  </li>
                  <li className={classes.noList}>
                    (b) modify the Focaldata Platform (wholly or in part) so
                    that it ceases to be infringing;
                  </li>
                  <li className={classes.noList}>
                    (c) replace the Focaldata Platform (wholly or in part) with
                    non-infringing items; or
                  </li>
                  <li className={classes.noList}>
                    (d) terminate this Agreement immediately by notice in
                    writing to the Customer.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                11.4 This clause 11 constitutes the Customer&apos;s sole and
                exclusive remedy and Focaldata&apos;s only liability in respect
                of IPR Claims and, for the avoidance of doubt, is subject to
                clause 13.3.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>12 Warranties</strong>
            <ul>
              <li className={classes.noList}>
                12.1 Focaldata warrants that:
                <ul>
                  <li className={classes.noList}>
                    (a) it has the right to license the Data, and grant the
                    right to access and use the Focaldata Platform, as specified
                    in this Agreement; and
                  </li>
                  <li className={classes.noList}>
                    (b) the Services will be performed using reasonable care and
                    skill.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                12.2 Except as expressly stated in this Agreement, all
                warranties, conditions and terms, whether express or implied by
                statute, common law or otherwise are hereby excluded to the
                extent permitted by law.
              </li>
              <li className={classes.noList}>
                12.3 Without limiting the effect of clause 12.2, Focaldata does
                not warrant that:
                <ul>
                  <li className={classes.noList}>
                    (a) the supply of the Data or use of the Focaldata Platform
                    will be free from interruption;
                  </li>
                  <li className={classes.noList}>
                    (b) the Data is accurate, complete, reliable, secure,
                    useful, fit for purpose or timely; or
                  </li>
                  <li className={classes.noList}>
                    (c) the Data has been tested for use by the Customer or any
                    third party or that the Data will be suitable for or be
                    capable of being used by the Customer or any third party.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>13. Limitation of liability</strong>
            <ul>
              <li className={classes.noList}>
                13.1 Neither party excludes or limits liability to the other
                party for:
                <ul>
                  <li className={classes.noList}>
                    (a) fraud or fraudulent misrepresentation;
                  </li>
                  <li className={classes.noList}>
                    (b) death or personal injury caused by negligence;
                  </li>
                  <li className={classes.noList}>
                    (c) a breach of any obligations implied by section 12 of the
                    Sale of Goods Act 1979 or section 2 of the Supply of Goods
                    and Services Act 1982; or
                  </li>
                  <li className={classes.noList}>
                    (d) any matter in respect of which it would be unlawful for
                    the parties to exclude liability.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                13.2 Subject to clause 13.1, Focaldata shall not in any
                circumstances be liable whether in contract, tort (including for
                negligence and breach of statutory duty howsoever arising),
                misrepresentation (whether innocent or negligent), restitution
                or otherwise, for:
                <ul>
                  <li className={classes.noList}>
                    (a) any loss (whether direct or indirect) of profits,
                    business, business opportunities, revenue, turnover,
                    reputation or goodwill;
                  </li>
                  <li className={classes.noList}>
                    (b) any loss or corruption (whether direct or indirect) of
                    data or information;
                  </li>
                  <li className={classes.noList}>
                    (c) loss (whether direct or indirect) of anticipated savings
                    or wasted expenditure (including management time); or
                  </li>
                  <li className={classes.noList}>
                    (d) any loss or liability (whether direct or indirect) under
                    or in relation to any other contract.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                13.3 Subject to clause 13.1, Focaldata&apos;s total aggregate
                liability in contract, tort (including negligence and breach of
                statutory duty howsoever arising), misrepresentation (whether
                innocent or negligent), restitution or otherwise, arising in
                connection with the performance or contemplated performance of
                this Agreement or any collateral contract shall in all
                circumstances be limited to the total Charges paid by the
                Customer to Focaldata.
              </li>
              <li className={classes.noList}>
                13.4 Any dates quoted for delivery of the Services are
                approximate only, and the time of delivery is not of the
                essence. Focaldata shall not be liable for any delay in delivery
                of the Services that is caused by an event within the scope of
                clause 15 or the Customer&apos;s failure to provide Focaldata
                with adequate instructions or any other instructions that are
                relevant to the supply of the Services.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong> 14. Term and termination</strong>
            <ul>
              <li className={classes.noList}>
                14.1 This agreement shall commence on the Commencement Date.
                Unless terminated earlier in accordance with clause 14.2, this
                Agreement shall continue until:
                <ul>
                  <li className={classes.noList}>
                    (a) the Data has been provided to the Customer;
                  </li>
                  <li className={classes.noList}>
                    (b) where Consultancy Services are being provided, those
                    Consultancy Services have been completed; and
                  </li>
                  <li className={classes.noList}>
                    (c) the Charges have been paid to Focaldata.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                14.2 Without prejudice to any rights that have accrued under
                this Agreement or any of its rights or remedies, either party
                may terminate this Agreement with immediate effect by giving
                written notice to the other party if:
                <ul>
                  <li className={classes.noList}>
                    (a) the other party fails to pay any amount due under this
                    Agreement on the due date for payment and remains in default
                    not less than 14 days after being notified in writing to
                    make that payment;
                  </li>
                  <li className={classes.noList}>
                    (b) the other party commits a material breach of any term of
                    this Agreement (other than failure to pay any amounts due
                    under this Agreement) and (if that breach is remediable)
                    fails to remedy that breach within a period of 30 days after
                    being notified in writing to do so;
                  </li>
                  <li className={classes.noList}>
                    (c) the other party:
                    <ul>
                      <li className={classes.noList}>
                        (i) suspends, or threatens to suspend, payment of its
                        debts;
                      </li>
                      <li className={classes.noList}>
                        (ii) is unable to pay its debts as they fall due or
                        admits inability to pay its debts;
                      </li>
                      <li className={classes.noList}>
                        (iii) (being a company) is deemed unable to pay its
                        debts within the meaning of section 123 of the
                        Insolvency Act 1986;
                      </li>
                      <li className={classes.noList}>
                        (iv) (being an individual) is deemed either unable to
                        pay its debts or as having no reasonable prospect of so
                        doing, in either case, within the meaning of section 268
                        of the Insolvency Act 1986; or
                      </li>
                      <li className={classes.noList}>
                        (v) (being a partnership) has any partner to whom any of
                        clause 14.2(c)(i) apply;
                      </li>
                    </ul>
                  </li>
                  <li className={classes.noList}>
                    (d) the other party commences negotiations with all or any
                    class of its creditors with a view to rescheduling any of
                    its debts, or makes a proposal for or enters into any
                    compromise or arrangement with its creditors other than (in
                    the case of a company) for the sole purpose of a scheme for
                    a solvent amalgamation of that other party with one or more
                    other companies or the solvent reconstruction of that other
                    party;
                  </li>
                  <li className={classes.noList}>
                    (e) the other party applies to court for, or obtains, a
                    moratorium under Part A1 of the Insolvency Act 1986;
                  </li>
                  <li className={classes.noList}>
                    (f) a petition is filed, a notice is given, a resolution is
                    passed, or an order is made, for or in connection with the
                    winding up of that other party (being a company) other than
                    for the sole purpose of a scheme for a solvent amalgamation
                    of that other party with one or more other companies or the
                    solvent reconstruction of that other party;
                  </li>
                  <li className={classes.noList}>
                    (g) an application is made to court, or an order is made,
                    for the appointment of an administrator, or if a notice of
                    intention to appoint an administrator is given or if an
                    administrator is appointed, over the other party (being a
                    company, partnership or limited liability partnership);
                  </li>
                  <li className={classes.noList}>
                    (h) the holder of a qualifying floating charge over the
                    assets of that other party (being a company or limited
                    liability partnership) has become entitled to appoint or has
                    appointed an administrative receiver;
                  </li>
                  <li className={classes.noList}>
                    (i) a person becomes entitled to appoint a receiver over the
                    assets of the other party or a receiver is appointed over
                    the assets of the other party;
                  </li>
                  <li className={classes.noList}>
                    (j) a creditor or encumbrancer of the other party attaches
                    or takes possession of, or a distress, execution,
                    sequestration or other similar process is levied or enforced
                    on or sued against, the whole or any part of the other
                    party&apos;s assets and that attachment or process is not
                    discharged within 14 days;
                  </li>
                  <li className={classes.noList}>
                    (k) any event occurs or proceeding is taken with respect to
                    the other party in any jurisdiction to which it is subject
                    that has an effect equivalent or similar to any of the
                    events mentioned in clause 14.2(c) to 14.2(j) (inclusive);
                  </li>
                  <li className={classes.noList}>
                    (l) the other party suspends or ceases, or threatens to
                    suspend or cease, carrying on all or a substantial part of
                    its business; or
                  </li>
                  <li className={classes.noList}>
                    (m) the other party&apos;s financial position deteriorates
                    so far as to reasonably justify the opinion that its ability
                    to give effect to the terms of this Agreement is in
                    jeopardy.
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                14.3 Any provision of this Agreement that expressly or by
                implication is intended to come into or continue in force on or
                after termination of this Agreement shall remain in full force
                and effect.
              </li>
              <li className={classes.noList}>
                14.4 Termination or expiry of this Agreement shall not affect
                any rights, remedies, obligations or liabilities of the parties
                that have accrued up to the date of termination or expiry,
                including the right to claim damages in respect of any breach of
                the Agreement which existed at or before the date of termination
                or expiry.
              </li>
              <li className={classes.noList}>
                14.5 On any termination of this Agreement for any reason, the
                Customer shall immediately pay any outstanding amounts owed to
                Focaldata under this Agreement and ensure that there is no
                further use of the Services.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>Force majeure</strong>
          </li>
          <p>
            Neither party shall be in breach of this Agreement nor liable for
            delay in performing, or failure to perform, any of its obligations
            under this Agreement if such delay or failure result from events,
            circumstances or causes beyond its reasonable control. In such
            circumstances the affected party shall be entitled to a reasonable
            extension of the time for performing such obligations. If the period
            of delay or non-performance continues for six (6) weeks, the party
            not affected may terminate this Agreement by giving fourteen (14)
            days&apos; written notice to the affected party.
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>16 Assignment</strong>
            <ul>
              <li className={classes.noList}>
                16.1 This Agreement is personal to the Customer and it shall not
                assign, transfer, mortgage, charge, sub-contract, declare a
                trust of or deal in any other manner with any of its rights and
                obligations under this Agreement without the prior written
                consent of Focaldata (which is not to be unreasonably withheld
                or delayed).
              </li>
              <li className={classes.noList}>
                16.2 The Customer confirms it is acting on its own behalf and
                not for the benefit of any other person.
              </li>
              <li className={classes.noList}>
                16.3 Focaldata may at any time assign, transfer, mortgage,
                charge, sub-contract, declare a trust of or deal in any other
                manner with any of its rights and obligations under this
                Agreement without the consent of the Customer.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>17. Waiver</strong>
          </li>
          <p>
            No failure or delay by a party to exercise any right or remedy
            provided under this Agreement or by law shall constitute a waiver of
            that or any other right or remedy, nor shall it preclude or restrict
            the further exercise of that or any other right or remedy. No single
            or partial exercise of any right or remedy shall preclude or
            restrict the further exercise of that or any other right or remedy.
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>18. Remedies</strong>
          </li>
          <p>
            Except as expressly provided in this Agreement, the rights and
            remedies provided under this Agreement are in addition to, and not
            exclusive of, any rights or remedies provided by law.
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>19. Notice</strong>
          </li>
          <p>
            Any notice or other communication given to a party under or in
            connection with this Agreement shall be in writing, addressed to
            that party at its registered office or such other address as that
            party may have specified to the other party in writing in accordance
            with this clause, and shall be delivered personally (deemed to be
            received when left at the relevant address), or sent by pre-paid
            first class post or other next working day delivery service (deemed
            to be received at 9.00 am on the second Business Day after posting),
            or by commercial courier (deemed to be received on the date and at
            the time that the courier&apos;s delivery receipt is signed), or
            email (deemed to be received one Business Day after transmission).
            This clause does not apply to the service of any proceedings or
            other documents in any legal action or, where applicable, any
            arbitration or other method of dispute resolution.
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>20. Entire agreement</strong>
            <ul>
              <li className={classes.noList}>
                20.1 This Agreement constitutes the entire agreement between the
                parties and supersedes all previous discussions, correspondence,
                negotiations, arrangements, understandings and agreements
                between them relating to its subject matter.
              </li>
              <li className={classes.noList}>
                20.2 Each party acknowledges that in entering into this
                Agreement it does not rely on, and shall have no remedies in
                respect of, any representation or warranty (whether made
                innocently or negligently) that is not set out in this
                Agreement.
              </li>
              <li className={classes.noList}>
                20.3 Each party agrees that it shall have no claim for innocent
                or negligent misrepresentation or negligent misstatement based
                on any statement in this Agreement.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>21. Variation</strong>
          </li>
          <p>
            Except as expressly provided in this Agreement, no variation of this
            Agreement shall be effective unless it is in writing and signed by
            the parties (or their authorised representatives).
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>22. Severance</strong>
            <ul>
              <li className={classes.noList}>
                22.1 If any provision or part-provision of this Agreement is or
                becomes invalid, illegal or unenforceable, it shall be deemed
                deleted, but that shall not affect the validity and
                enforceability of the rest of this Agreement.
              </li>
              <li className={classes.noList}>
                22.2 If any provision or part-provision of this Agreement is
                deemed deleted under clause 22.1 the parties shall negotiate in
                good faith to agree a replacement provision that, to the
                greatest extent possible, achieves the intended commercial
                result of the original provision.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>23. No partnership or agency</strong>
            <ul>
              <li className={classes.noList}>
                23.1 Nothing in this Agreement is intended to, or shall be
                deemed to, establish any partnership or joint venture between
                any of the parties, constitute any party the agent of another
                party, or authorise any party to make or enter into any
                commitments for or on behalf of any other party.
              </li>
              <li className={classes.noList}>
                23.2 Each party confirms it is acting on its own behalf and not
                for the benefit of any other person.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>24. Third-party rights</strong>
          </li>
          <p>
            No one other than a party to this Agreement, their successors and
            permitted assignees, shall have any right to enforce any of its
            terms.
          </p>
        </ol>
        <ol>
          <li className={classes.noList}>
            <strong>25. Governing law and jurisdiction</strong>
            <ul>
              <li className={classes.noList}>
                25. This Agreement and any dispute or claim arising out of or in
                connection with it or its subject matter or formation (including
                non-contractual disputes or claims) shall be governed by and
                construed in accordance with the law of England and Wales.
              </li>
              <li className={classes.noList}>
                25.2 Each party irrevocably agrees that the courts of England
                and Wales shall have exclusive jurisdiction to settle any
                dispute or claim arising out of or in connection with this
                Agreement or its subject matter or formation (including
                non-contractual disputes or claims).
              </li>
            </ul>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>Schedule &ndash; Data Protection</strong>
        </p>
        <ol>
          <li className={classes.noList}>
            <strong>1. Definitions</strong>
          </li>
        </ol>
        <p>
          In addition to the definitions and rules of interpretation set out in
          the terms to which this Schedule is attached, the following
          definitions shall also apply in this Schedule:
        </p>
        <p>
          <strong>Applicable Laws and Codes: </strong>refers to all applicable
          international, national, federal, state and/or local laws, rules,
          regulations, requirements, statutes, codes, decisions and opinions,
          including but not limited to the California Consumer Protection Act
          (&quot;
          <strong>CCPA</strong>&quot;), the EU-GDPR, the German Federal Data
          Protection Act, U.S. Health Insurance Portability and Accountability
          Act of 1996, the United States CAN-SPAM Act, the Gramm-Leach-Bliley
          Act, the Children&rsquo;s On-Line Privacy Protection Act, the ethical
          codes of the Insights Association (www.insightsassociation.org) and
          ESOMAR (www.esomar.org), and any amendments thereto.
        </p>
        <p>
          <strong>Non-Panel Member: </strong>refers to an individual that is not
          a member of a Panel, but may be offered a Survey.
        </p>
        <p>
          <strong>Non-Panel Source: </strong>refers to a collection of
          individuals who have agreed to be invited to and participate in
          Surveys or other research programs that are not part of a Panel.
        </p>
        <p>
          <strong>Panel(s):</strong> refers to a collection of individuals who
          have agreed to be invited to and participate in research studies or
          Surveys.
        </p>
        <p>
          <strong>Panel Member:</strong> refers to a member of a Panel.
        </p>
        <p>
          <strong>Personal Data: </strong>(sometimes referred to as Personally
          Identifiable Information or PII) refers to any information relating to
          an identified or identifiable natural person (i.e., a private
          individual as opposed to a corporate or other comparable entity). An
          identifiable person is someone who can be identified directly or
          indirectly, in particular by reference to an identification number, or
          the person&rsquo;s physical, physiological, mental, economic, cultural
          or social characteristics.<strong>&nbsp; </strong>
        </p>
        <p>
          <strong>Personal Data Permitted Purposes: </strong>refers to the
          permitted purposes for the collection and use of Personal Data, which
          may include Personal Data or Sensitive Personal Data.
        </p>
        <p>
          <strong>Respondent:</strong> a Panel Member or Non-Panel Member that
          participates in a Survey.
        </p>
        <p>
          <strong>Sensitive Personal Data:</strong> refers to Personal Data
          that, if disclosed, could result in harm to a person.&nbsp; Such data
          includes: (a) the racial or ethnic origin of a data subject; (b)
          his/her political opinions; (c) his/her religious beliefs or other
          beliefs of a similar nature; (d) whether he/she is a member of a trade
          or labor union; (e) his/her physical or mental health or condition;
          (f) his/her sexual life; (g) the commission or alleged commission by
          him/her of any crime of offense; or (h) any proceedings for any crime
          or offense committed or alleged to have been committed by him/her; or
          the disposal of such proceedings or the sentence of any court in such
          proceedings.
        </p>
        <p>
          <strong>Supply Partner(s):</strong>&nbsp; refers to the persons or
          legal entities owning a Panel or having access to a Panel or Non-Panel
          Source.
        </p>
        <p>
          <strong>Third Party Supplier: </strong>a third-party supplier to
          Focaldata of services enabling Focaldata to provide the Services.
        </p>
        <ol>
          <li className={classes.noList}>
            <strong>2. Personal Data and Data Protection</strong>
            <ul>
              <li className={classes.noList}>
                2.1 The Customer is hereby informed and acknowledges that to the
                extent Personal Data, related to Respondents, are processed by
                Focaldata or any Third-Party Supplier, in the provision of
                Services, Focaldata and that Third-Party Supplier only process
                such Personal Data, as processors, acting on behalf of Supply
                Partners, who are the controllers of, and responsible for, the
                processing of the Personal Data.
              </li>
              <li className={classes.noList}>
                2.2 The Parties acknowledge and agree that in relation to
                Personal Data, which the Customer may access and collect about
                Respondents, through the Services and Surveys, the Customer
                shall be the controller of, and responsible for, the processing
                of the Personal Data, and ensuring that its processing complies
                with all relevant laws and regulations as well as industry
                standards.
              </li>
              <li className={classes.noList}>
                2.3 The Customer undertakes to implement measures regarding the
                security, integrity and confidentiality of all Personal Data and
                will not permit any unauthorized access to, or use, disclosure,
                publication or dissemination of, Personal Data. Furthermore, the
                Customer shall ensure that those persons authorised by the
                Customer to handle Personal Data have committed themselves to
                confidentiality or are under an appropriate statutory obligation
                of confidentiality. The Customer agrees to use the same degree
                of care and scrutiny as it uses to protect and secure its own
                information or other similar information, but in no event will
                the Customer use less than a commercially reasonable degree of
                care. These measures and procedures must reflect industry best
                standards and practices. The Customer must update their measures
                and procedures as necessary to remain compliant with industry
                best standards and practices and applicable laws, regulations
                and market research industry codes.
              </li>
              <li className={classes.noList}>
                2.4 The Customer undertakes to report any Personal Data breaches
                to Focaldata pertaining to the Personal Data that may be
                processed by it that relates to this agreement without undue
                delay.
              </li>
              <li className={classes.noList}>
                2.5 The Customer shall (automatically or manually) ensure that
                that Personal Data is not stored longer than what is allowed
                pursuant pursuant to applicable law or regulation. No Personal
                Data for which the Customer is responsible, may be stored by the
                the time permitted by law and regulation.
              </li>
              <li className={classes.noList}>
                2.6 In the event that the Customer engages third parties in
                connection with their processing of Personal Data under this
                agreement, the Customer undertakes to ensure, by entering into a
                written agreement, that each such sub-processor complies with
                terms and conditions equivalent to this Schedule.
              </li>
              <li className={classes.noList}>
                2.7 Focaldata shall be entitled to compensation on a time and
                materials basis for any work and documented costs for taking
                measures and activities according with this Schedule
                specifically required by the Customer.
              </li>
              <li className={classes.noList}>
                2.8 The Customer warrants and covenants to Focaldata that it
                will, and will cause its employees and/or agents to, abide by
                and comply with all applicable laws and regulations, in
                connection with its collection, storage, maintenance,
                processing, use and/or disclosure of Personal Data. The Customer
                further warrants that the Customer&rsquo;s use of the Focaldata
                Platform, and the Customer&rsquo;s processing of Personal Data
                as agreed under this agreement, will not breach any laws or
                regulations.
              </li>
              <li className={classes.noList}>
                2.9 The Customer acknowledges and agrees that it has enacted, or
                will enact, without undue delay following the execution or
                acceptance of this agreement, commercially reasonable privacy
                and data security policies. The Customer agrees that within ten
                (10) business days following a written request from Focaldata,
                it will provide Focaldata with copies of any and/or all such
                policies and a certification that such policies have been
                properly enacted and implanted into its business practices and
                procedures. These policies must reflect industry best standards
                and practices. The Customer must update its policies as
                necessary to remain compliant with industry best standards and
                practices and Applicable Laws and Codes.
              </li>
              <li className={classes.noList}>
                2.10 Notwithstanding any other sections in this agreement, where
                Personal Data subject to the CCPA is collected, processed or
                transferred, the following shall apply:
                <ul>
                  <li className={classes.noList}>
                    2.10.1 The Customer represents, warrants, and covenants that
                    it shall at all times: (1) maintain reasonable security
                    measures; (2) limit access to Personal Data to employees and
                    approved subcontractors who are authorized to access
                    Personal Data, and ensure that all such employees and
                    subcontractors are comprehensively trained in the care and
                    handling of Personal Data; and (3) treat Personal Data with
                    strict confidence and take all reasonable steps to ensure
                    that its employees and other agents who will process
                    Personal Data are aware of and comply with this agreement
                    and are under duties of confidentiality and security with
                    respect to Personal Data no less restrictive than those set
                    forth herein.
                  </li>
                  <li className={classes.noList}>
                    2.10.2 Without limiting the foregoing, the Customer
                    represents, warrants, and covenants that it is and shall at
                    all times remain in compliance with the CCPA. Without
                    limiting the foregoing, if the Customer is collecting
                    Personal Data, the Customer shall provide consumers in
                    California with a CCPA-compliant notice which includes: (1)
                    a list of the categories of Personal Data about consumers in
                    California to be collected; (2) for each category of
                    Personal Data, the business or commercial purpose(s) for
                    which it will be used; (3) the link titled &quot;Do Not Sell
                    My Personal Information&quot; or &quot;Do Not Sell My
                    Info&quot; required by CCPA, or in the case of offline
                    notices, the web address for the webpage to which it links;
                    and (4) a link to the Customer&apos;s privacy policy, or in
                    the case of offline notices, the web address of the
                    Customer&apos;s privacy policy. The Customer shall not
                    collect categories of Personal Data, and shall not use a
                    consumer&apos;s Personal Data for any purpose, other than
                    those disclosed in the notice at collection.
                  </li>
                  <li className={classes.noList}>
                    2.10.3 The Customer will record and retain, for a minimum of
                    two (2) years after the expiration or termination of this
                    agreement, records of any notice to, and consent or request
                    from, individuals regarding the collection, disclosure,
                    retention and use of Personal Data that is exclusive to the
                    services of this agreement. Upon Focaldata&apos;s request,
                    the Customer shall make all records, appropriate personnel,
                    and/or any location from which Personal Data can be accessed
                    available for inspection to demonstrate compliance
                    hereunder, provided that such inspection shall be carried
                    out with reasonable notice during regular business hours and
                    under a duty of confidentiality.
                  </li>
                  <li className={classes.noList}>
                    2.10.4 Unless the Supply Party instructs Focaldata and/or
                    the Third-Party Supplier to the contrary, Focaldata and/or
                    the Third-Party Supplier will respond to inquiries from
                    consumers in California that are Panel Members or Non-Panel
                    Members regarding their rights under the CCPA.
                  </li>
                  <li className={classes.noList}>
                    2.10.5 The Customer will refrain from notifying or
                    responding to any government or regulatory agency, or other
                    third party, for or on behalf of Focaldata absent written
                    consent to such notification/response, except as otherwise
                    required by the CCPA.
                  </li>
                  <li className={classes.noList}>
                    2.10.6 The Customer acknowledges and agrees that if
                    Focaldata receives a request from a government or regulatory
                    agency, it may share the terms of this agreement and other
                    information provided by the Customer to demonstrate
                    compliance with this agreement or the CCPA.
                  </li>
                  <li className={classes.noList}>
                    2.10.7 The Customer shall promptly give written notice to
                    and shall fully cooperate with Focaldata: (1) regarding any
                    complaint, inquiry, or request from a government or
                    regulatory agency regarding Personal Data received from
                    Focaldata, unless such notice is prohibited by law; (2) if
                    for any reason: (a) it cannot comply, or has not complied,
                    with any portion of this agreement; (b) it has breached or
                    may be in breach of the CCPA; or (c) the CCPA no longer
                    allows the lawful transfer of Personal Data between the
                    parties (in such cases, each party will take reasonable and
                    appropriate steps to remedy any noncompliance, or cease
                    further processing of Personal Data, and acknowledges and
                    agrees that the other party may immediately terminate this
                    agreement or access to Personal Data, or take any other
                    reasonable action).
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                2.11 In the event of any unauthorized or accidental access to,
                use or disclosure of Personal Data, the party suffering such
                breach or unauthorized access shall: (1) notify the other Party
                immediately without undue delay; (2) take all necessary and
                appropriate corrective actions, at its expense, to remedy the
                causes and consequences of the problem.
              </li>
            </ul>
          </li>
          <li className={classes.noList}>
            <strong>3. Collection of Personal Data by the Customer</strong>
            <ul>
              <li className={classes.noList}>
                3.1 Where agreed between the parties in writing, Focaldata
                grants the Customer permission to collect Personal Data, to
                enable the Customer to contact certain Respondents to perform
                research activities solely for follow up to a Survey.
              </li>
              <li className={classes.noList}>
                3.2 The Customer will ensure that it will collect Personal Data,
                subject to the terms, covenants, obligations, representations,
                warranties, and agreements contained herein. The supplying of
                Personal Data must be voluntary and only be processed upon the
                Respondent&rsquo;s consent, which must not be a condition for
                receiving an incentive or pool entry. Respondents must be given
                notification when responding to a Survey within the Survey, that
                supplying Personal Data is voluntary. The Customer shall provide
                notice of their intent to collect Personal Data at a future
                point in the Survey as soon as possible within the Survey
                process.
              </li>
              <li className={classes.noList}>
                3.3 The collection of Personal Data will be limited to the
                following data required for Survey follow up for research
                purposes:
                <ul>
                  <li className={classes.noList}>3.3.1 First name</li>
                  <li className={classes.noList}>3.3.2 Last name</li>
                  <li className={classes.noList}>3.3.3 Email address</li>
                  <li className={classes.noList}>3.3.4 number</li>
                </ul>
              </li>
              <li className={classes.noList}>
                3.4 The Customer shall use the Personal Data solely and
                exclusively for the following Personal Data Permitted Purposes:
                <ul>
                  <li className={classes.noList}>
                    3.4.1 Follow up to a Survey for research purposes
                  </li>
                </ul>
              </li>
              <li className={classes.noList}>
                3.5 The Customer agrees and acknowledges that Respondents
                participating in the Surveys must satisfy a minimum age limit of
                18 years of age. The Customer represents and warrants that the
                Customer is solely responsible for verifying that all
                Respondents participating in any Survey, e.g. product-testing,
                study, survey or focus group, whether in whole or in part,
                satisfy the minimum age limit.
              </li>
              <li className={classes.noList}>
                3.6 The Customer will not use or disclose the Personal Data for
                any other purpose than the Personal Data Permitted Purposes,
                including, without limitation, marketing, selling to or
                influencing the opinions or decisions of any Respondent. The
                Customer will ensure that Respondents are not harmed or
                otherwise adversely affected as a result of the Customer&rsquo;s
                collection, maintenance and/or use of Personal Data.
              </li>
              <li className={classes.noList}>
                3.7 The Customer will comply with the Personal Data Permitted
                Uses and all applicable laws and regulations with respect to
                data deletion and retention.
              </li>
              <li className={classes.noList}>
                3.8 The Customer will not disclose the Personal Data to any
                third party, without the prior written consent of Focaldata and
                will only disclose the Personal Data to those of its employees
                or agents who have a need to know the Personal Data in
                connection with the Personal Data Permitted Purposes.
              </li>
              <li className={classes.noList}>
                3.9 The Customer acknowledges and agrees that, where requested
                by the Customer of Focaldata, the Personal Data may include, but
                will not be limited to, Sensitive Personal Data and other
                information concerning Respondents participating in the Surveys.
              </li>
              <li className={classes.noList}>
                3.10 In the event that the Customer suffers any incident through
                which the system or any associated data, including Personal
                Data, is accessed or disclosed without proper authorization, the
                Customer shall promptly notify Focaldata. In addition, the
                Customer shall take all reasonable steps to assist Focaldata
                and/or any Third-Party Supplier and/or any law enforcement or
                governmental agency having jurisdiction in any investigation of
                such security breach.
              </li>
            </ul>
          </li>
        </ol>
      </Grid>
    </Grid>
  )
}

export default TermsAndConditions
