import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Spinner,
  SpinnerColor,
  SpinnerSize
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import HubspotForm from 'react-hubspot-form'
import useStyles from './HubspotContactForm.styles'

const cookieString = 'emailCollected'

interface Props {
  projectTitle: string
}

const HubspotContactForm: React.FC<Props> = ({ projectTitle }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(true)

  useEffect(() => {
    const { cookie } = document
    setDialogOpen(!cookie || !cookie.includes(cookieString))
  }, [])

  const { classes } = useStyles()
  return (
    <Dialog
      open={dialogOpen}
      title={projectTitle}
      transparentBackground
      maxWidth={DialogMaxWidth.Lg}
      variant={DialogVariant.NoButtons}
    >
      <div className={classes.iframe}>
        <HubspotForm
          region="na1"
          portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
          formId={process.env.REACT_APP_HUBSPOT_FORM_ID}
          onFormSubmitted={() => {
            setDialogOpen(false)
            document.cookie = cookieString
          }}
          loading={
            <Spinner size={SpinnerSize.m} color={SpinnerColor.Primary} />
          }
        />
      </div>
    </Dialog>
  )
}

export default HubspotContactForm
