import gql from 'graphql-tag'

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const UPDATE_AUDIENCE_MEMBER_V2 = gql`
  mutation updateAudienceMemberV2($input: UpdateAudienceMemberV2Input!) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateAudienceMemberV2(input: $input) {
      ...DraftAudienceMember
    }
  }
`
