import {
  Grid,
  Icon,
  IconName,
  IconSize,
  Skeleton
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './ImportQuestionsSurveyPicker.styles'

const ImportQuestionsSurveyPickerLoading: React.FC = () => {
  const { classes } = useStyles()
  return (
    <Grid container direction="row">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
        <Grid
          key={val}
          container
          direction="row"
          justifyContent="space-between"
          className={classes.projectLoading}
        >
          <Grid item sm={11}>
            <Skeleton height={24} width={160} />
          </Grid>
          <Grid item sm={1} alignItems="flex-end">
            <Icon name={IconName.ChevronRight} size={IconSize.Large} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImportQuestionsSurveyPickerLoading
