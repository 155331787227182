import { useAppDispatch } from '../../../../App.store'
import { DraftSectionItem } from '../../../../components/Section/Section.model'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSetLooping } from '../Looping.hooks'
import { LoopingConfig } from '../Looping.model'
import { openLoopingDialog } from '../Looping.slice'
import { entryHasStaticLooping } from '../Looping.utils'
import LoopingActionsMenuComponent from './LoopingActionsMenu.component'

const LoopingActionsMenu = ({
  sectionEntryNumber,
  sectionEntryItem,
  loopingConfig
}: {
  sectionEntryNumber: number
  sectionEntryItem: DraftSectionItem
  // As separate prop so we know it's not null
  loopingConfig: LoopingConfig
}) => {
  const dispatch = useAppDispatch()
  const { flattenedEntries } = useGetDraftQuestionnaire()

  const sourceEntry = flattenedEntries.find(
    (entry) => entry.number === loopingConfig.sourceEntryNumber
  )

  const setLooping = useSetLooping({
    sectionEntryNumber,
    prevSourceEntry: sourceEntry
  })

  const sourceEntryPrefix = sourceEntry
    ? `Q${sourceEntry.contextPosition + 1}`
    : ''

  const handleEditLooping = () => {
    if (!sourceEntry) {
      throw new Error('Cannot find source entry for section looping')
    }

    return dispatch(
      openLoopingDialog({ sectionEntryNumber, sectionEntryItem, sourceEntry })
    )
  }

  const handleDisableLooping = () => {
    return setLooping({ enabled: false })
  }

  return (
    <LoopingActionsMenuComponent
      sourceEntryPrefix={sourceEntryPrefix}
      onEditLooping={sourceEntry ? handleEditLooping : null}
      onDisableLooping={handleDisableLooping}
      staticLooping={!!sourceEntry && entryHasStaticLooping(sourceEntry)}
    />
  )
}

export default LoopingActionsMenu
