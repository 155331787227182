import React from 'react'
import useAudienceQuestionsToggleVar from '../../../hooks/localState/useAudienceQuestionsToggleVar'
import useStyles from './Sidebar.styles'
import { SidebarAudienceItemList } from './SidebarAudienceItemList'
import { SidebarQuestionnaireItemList } from './SidebarQuestionnaireItemList'

const Sidebar: React.FC = () => {
  const { classes } = useStyles()
  const { shouldShowAudienceQuestions } = useAudienceQuestionsToggleVar()

  return (
    <div className={classes.scrollableSidebar}>
      {shouldShowAudienceQuestions && <SidebarAudienceItemList />}
      <SidebarQuestionnaireItemList />
    </div>
  )
}

export default React.memo(Sidebar)
