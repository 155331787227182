import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_PRESET_AUDIENCES_V2 = questionnaireGql(/* GraphQL */ `
  query getPresetAudiencesV2($locale: LocaleInput!) {
    getPresetAudiencesV2(locale: $locale) {
      presetAudienceId
      name
      isWeighted
    }
  }
`)
