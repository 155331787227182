import axios, { CancelToken } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { emptyArr } from '../../constants/misc'
import { ChartEntry } from '../../data/model/chart'
import { SelectedFilter, WeightingScheme } from '../../data/model/results'
import { usePipingMarkerTransformer } from '../../hooks/usePipingMarkerTransformer'
import { getSummaryData } from '../../hooks/useSummaryData'
import { replaceCompletesOnWeightedData } from '../../utils/resultsUtils'

export const useCompleteChartListData = (
  surveyId: string,
  selectedFilters: SelectedFilter[],
  page: number,
  rowsPerPage: number
) => {
  const [filteredSummaryData, setFilteredSummaryData] =
    useState<ChartEntry[]>(emptyArr)
  const [prevFilters, setPrevFilters] = useState(selectedFilters)
  const [weightingScheme, setWeightingScheme] = useState<WeightingScheme>()
  const [totalCount, setTotalCount] = useState<number>(-1)
  const [summaryDataLoading, setSummaryDataLoading] = useState(true)

  const apiCallIdentifier = useRef(0)

  const { getCardTitleDisplayView } =
    usePipingMarkerTransformer(filteredSummaryData)

  const handleGetData = useCallback(
    async (cancelToken: CancelToken) => {
      setSummaryDataLoading(true)
      const currentApiCallIdentifier = ++apiCallIdentifier.current
      const offset = page * rowsPerPage
      const data = await getSummaryData(
        surveyId,
        offset,
        rowsPerPage,
        selectedFilters,
        cancelToken
      )
      if (data && currentApiCallIdentifier === apiCallIdentifier.current) {
        setFilteredSummaryData(data.results)
        setWeightingScheme(data.weightingScheme)
        setTotalCount(data.totalChartsCount ?? -1)
      }
      if (currentApiCallIdentifier === apiCallIdentifier.current) {
        setSummaryDataLoading(false)
      }
    },
    [page, rowsPerPage, selectedFilters, surveyId]
  )

  if (prevFilters !== selectedFilters) {
    setPrevFilters(selectedFilters)
    setFilteredSummaryData(emptyArr)
    setTotalCount(-1)
  }

  useEffect(() => {
    const source = axios.CancelToken.source()

    handleGetData(source.token)

    return () => {
      source.cancel()
    }
  }, [handleGetData])

  const chartData = filteredSummaryData.map((entry) => ({
    ...replaceCompletesOnWeightedData(entry, !!weightingScheme),
    text: getCardTitleDisplayView(entry.text),
    textStyling: getCardTitleDisplayView(entry.textStyling ?? '')
  }))

  return { chartData, totalCount, summaryDataLoading }
}
