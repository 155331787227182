import { useApolloClient } from '@apollo/client'
import difference from 'lodash/difference'
import { useContext } from 'react'
import { QUESTIONNAIRE } from '../../../../data/gql/questionnaire/queries'
import { useRemoveQuestionnaireEntries } from '../../../../hooks/questionnaire/useRemoveQuestionnaireEntries'
import QuestionnaireContext, {
  closeBulkDeleteDialog,
  openBulkDeleteDialog,
  setSelectedCards,
  unselectAllCards
} from '../../../../modules/Questionnaire/Questionnaire.context'
import { QuestionsBulkDeleteButtonControl } from './QuestionsBulkDeleteButton.control'
import { useOptimisticDeleteQuestions } from './QuestionsBulkDeleteButton.hooks'

export const QuestionsBulkDeleteButtonContainer = (): JSX.Element | null => {
  const client = useApolloClient()
  const optimisticDeleteQuestions = useOptimisticDeleteQuestions()
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const { selectedCards } = questionnaireState
  const removeQuestionnaireEntries = useRemoveQuestionnaireEntries()

  if (!selectedCards.size) {
    return null
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openBulkDeleteDialog())
  }
  const handleCloseDeleteDialog = () => {
    dispatch(closeBulkDeleteDialog())
  }
  const handleDeleteSelectedCards = async () => {
    handleCloseDeleteDialog()

    optimisticDeleteQuestions()

    dispatch(unselectAllCards())

    const allSelectedCards: string[] = Array.from(selectedCards)
    let rejectedEntryNumbers: Set<string> = new Set()
    removeQuestionnaireEntries(allSelectedCards)
      .then(({ data }) => {
        if (!data?.removeQuestionnaireEntries) {
          rejectedEntryNumbers = new Set(allSelectedCards)
        } else {
          // find entry ids for questions which could not be deleted on backend
          rejectedEntryNumbers = new Set(
            difference(allSelectedCards, data.removeQuestionnaireEntries)
          )
        }
      })
      .catch(() => {
        rejectedEntryNumbers = new Set(allSelectedCards)
      })
      .finally(() => {
        // if some cards couldn't be deleted set them selected again
        if (rejectedEntryNumbers.size) {
          dispatch(setSelectedCards(rejectedEntryNumbers))
        }

        client.refetchQueries({
          include: [QUESTIONNAIRE]
        })
      })
  }

  return (
    <QuestionsBulkDeleteButtonControl
      isDeleteDialogOpened={questionnaireState.isBulkDeleteDialogOpened}
      onClickDelete={handleOpenDeleteDialog}
      onCloseDeleteDialog={handleCloseDeleteDialog}
      onConfirmDelete={handleDeleteSelectedCards}
    />
  )
}
