import AudienceCard from '../../../../modules/FdChat/Overview/AudienceCard/AudienceCard'
import InterviewLinkCard from '../../../../modules/FdChat/Overview/InterviewLinkCard/InterviewLinkCard'
import MethodologyCard from '../../../../modules/FdChat/Overview/MethodologyCard/MethodologyCard'
import ProjectBriefCard from '../../../../modules/FdChat/Overview/ProjectBriefCard/ProjectBriefCard'
import ProjectCost from '../../../../modules/FdChat/Overview/ProjectCost/ProjectCost'
import StatusCard from '../../../../modules/FdChat/Overview/StatusCard/StatusCard'
import TimelineCard from '../../../../modules/FdChat/Overview/TimelineCard/TimelineCard'
import { useFdChatFieldwork } from '../FdChat.hooks'
import styles from './FDChatOverview.module.css'

const FdChatOverview = () => {
  const { isCustomAudience } = useFdChatFieldwork()

  return (
    <div className={styles['fd-chat-overview']}>
      <h1 className={styles.title}>Project overview</h1>
      <p className={styles.description}>
        Your project is on track to be delivered on time. See a more detailed
        timeline below.
      </p>
      <div className={styles['main-content']}>
        <div className={styles.column}>
          <StatusCard />
          <TimelineCard />
          <MethodologyCard />
        </div>
        <div className={styles.column}>
          {isCustomAudience && <InterviewLinkCard />}
          <ProjectBriefCard />
          <AudienceCard />
          <hr className={styles['project-cost-divider']} />
          <ProjectCost />
        </div>
      </div>
    </div>
  )
}

export default FdChatOverview
