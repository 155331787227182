import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import { useProjectData } from '../Project.hooks'
import { ProjectNameAndStatusEditable } from './ProjectNameAndStatus.component'
import ProjectTitleAndStatusLoading from './ProjectNameAndStatus.loading'

const ProjectNameAndStatus = () => {
  const { project, loading: projectLoading } =
    useProjectData('cache-and-network')

  if (projectLoading) {
    return <ProjectTitleAndStatusLoading />
  }

  const projectName = project?.name ?? '...'

  const projectStatus = project?.surveys[0].state ?? 'draft'
  const projectType = project?.projectType ?? ProjectType.AdHoc

  return (
    <ProjectNameAndStatusEditable
      projectId={project?.projectId}
      name={projectName}
      status={projectStatus}
      type={projectType}
    />
  )
}

export default ProjectNameAndStatus
