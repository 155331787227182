import React, { memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { emptyArr } from '../../../../constants/misc'
import {
  ForkTag,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import { useDraftMatrixRow } from '../../../../hooks/questionnaire/useDraftMatrixRow'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useUpdateMatrixRow } from '../../MatrixQuestion/MatrixQuestionRows/MatrixQuestionRows.hooks'
import {
  rowForksSet,
  rowMaskingRulesSet,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import MaskingButtonControl from './MaskingButton.control'
import { toDraftMaskingRule } from './MaskingButton.utils'

interface Props {
  questionLk: string
  matrixTitleLk: string
}

const MatrixMaskingButton: React.FC<Props> = ({
  questionLk,
  matrixTitleLk
}: Props) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const matrixRow = useDraftMatrixRow(questionLk)
  const matrixRowForks = matrixRow?.forks ?? emptyArr
  const matrixRowMasking = matrixRow?.maskingRules ?? emptyArr

  const dispatch = useAppDispatch()
  const surveyId = useSurveyId()
  const updateMatrixRow = useUpdateMatrixRow(matrixTitleLk)

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const handleSave: (
    newRules: MaskingRuleInput[],
    displayLogicForks: ForkTag[]
  ) => Promise<void> = async (newRules, forkTags) => {
    dispatch(
      rowMaskingRulesSet({
        matrixTitleLk,
        questionLk,
        maskingRules: newRules.map(toDraftMaskingRule),
        flattenAllEntries: flatDraftQuestionnaireEntries
      })
    )
    dispatch(
      rowForksSet({
        matrixTitleLk,
        questionLk,
        forkTags
      })
    )
    await updateMatrixRow({
      questionId: questionLk,
      maskingRules: newRules,
      forks: {
        value: forkTags
      }
    })

    LogAmplitudeEvent(EventType.AddedMatrixRowDisplayLogic, {
      surveyId,
      matrixTitleLk,
      questionLk
    })
  }

  return (
    <MaskingButtonControl
      entryType={EntryType.MatrixEntryType}
      entries={flatDraftQuestionnaireEntries}
      onSave={handleSave}
      entryItemLk={matrixTitleLk}
      maskedItemLk={questionLk}
      maskingRules={matrixRowMasking}
      forks={matrixRowForks}
      allowForks
      responseOptionsByQuestion={responseOptionsByQuestion}
    />
  )
}

export default memo(MatrixMaskingButton)
