import { useNavigate } from 'react-router-dom'
import {
  BreadcrumbLinkVariant,
  Breadcrumbs
} from '../../../components/Breadcrumbs'
import { useFdChatValidation } from './FdChat.hooks'

const getBreadcrumbVariant = (
  path: string,
  options?: {
    disabled: boolean
  }
): BreadcrumbLinkVariant => {
  if (options?.disabled) {
    return BreadcrumbLinkVariant.Disabled
  }
  return window.location.href.endsWith(path)
    ? BreadcrumbLinkVariant.Selected
    : BreadcrumbLinkVariant.Selectable
}

type StepType = {
  name: string
  path: 'objectives' | 'audience' | 'review'
}

export const steps: StepType[] = [
  {
    name: 'Objectives and preview',
    path: 'objectives'
  },
  { name: 'Audience', path: 'audience' },
  { name: 'Review project', path: 'review' }
]

export const FdChatBreadcrumbs = () => {
  const navigate = useNavigate()
  const { objectivesValid, audienceValid, questionnaireValid } =
    useFdChatValidation()

  const isPathDisabled = (path: string) => {
    if (path === 'audience') {
      return !objectivesValid
    }

    if (path === 'review') {
      return !audienceValid || !objectivesValid || !questionnaireValid
    }

    return false
  }

  return (
    <Breadcrumbs
      options={steps.map((step) => ({
        name: step.name,
        onClick: () => navigate(step.path),
        linkVariant: getBreadcrumbVariant(`/${step.path}`, {
          disabled: isPathDisabled(step.path)
        })
      }))}
    />
  )
}
