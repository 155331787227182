import {
  Chip,
  Grid,
  Paper,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { ProjectTypesItem } from '../../modules/Project/Project.model'

const ProjectTypeCard = ({
  title,
  description,
  showBetaLabel,
  action
}: ProjectTypesItem) => {
  return (
    <Grid
      item
      component={Paper}
      variant="outlined"
      style={{
        padding: '24px',
        display: 'grid',
        gap: '20px',
        gridAutoRows: 'max-content 1fr'
      }}
      data-testid="project-type-card"
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          alignItems: 'center'
        }}
      >
        <Text size={TextSize.m} weight={TextWeight.Bold}>
          {title}{' '}
        </Text>
        {showBetaLabel && (
          <Chip
            label="Beta"
            style={{
              fontWeight: 700,
              fontSize: '16px'
            }}
          />
        )}
      </div>
      <Text
        size={TextSize.m}
        weight={TextWeight.Regular}
        highlight={TextHighlight.Background}
      >
        {description}
      </Text>
      {action}
    </Grid>
  )
}

export default ProjectTypeCard
