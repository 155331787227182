import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  percentText: {
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(1.5)
  },
  errorIcon: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(-1.5)
  },
  input: {
    width: '80px'
  }
})

export default useStyles
