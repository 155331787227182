import {
  Menu,
  MenuOption,
  MenuVariant,
  Skeleton,
  SpinnerButton,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import { useSurveyId } from '../../../../../hooks/useSurveyId'
import { useFdChatProgress } from '../../../../../modules/FdChat/FdChatProgressProvider.context'
import { useExportFdChatTranscripts } from '../../FdChat.hooks'
import { TranscriptsFileFormatType } from '../../FdChat.model'

const ExportTranscriptsButton = () => {
  const { exportTranscripts, loading: exportingTranscripts } =
    useExportFdChatTranscripts()
  const surveyId = useSurveyId()
  const { loading, interviewsCollected } = useFdChatProgress()

  if (loading) {
    return <Skeleton variant="rectangular" width={210} height={37} />
  }

  const hasTranscripts = interviewsCollected > 1 // allowing if greater than 1 to account for ghost respondent

  const handleExportTranscriptsClick = ({
    fileFormat
  }: {
    fileFormat: TranscriptsFileFormatType
  }) => {
    LogAmplitudeEvent(EventType.ClickedExportTranscripts, {
      surveyId,
      fileFormat
    })
    exportTranscripts({ fileFormat })
  }

  const exportTranscriptsMenu: MenuOption[] = [
    {
      id: 0,
      options: [
        {
          text: 'Export .txt file',
          onClick: () => handleExportTranscriptsClick({ fileFormat: 'txt' }),
          disabled: !hasTranscripts
        },
        {
          text: 'Export .csv file',
          onClick: () => handleExportTranscriptsClick({ fileFormat: 'csv' }),
          disabled: !hasTranscripts
        }
      ]
    }
  ]

  if (exportingTranscripts)
    return (
      <SpinnerButton
        loading={true}
        disabled={true}
        style={{
          width: '210px'
        }}
      >
        Exporting transcripts
      </SpinnerButton>
    )

  return (
    <Tooltip
      title={
        hasTranscripts
          ? 'Export .txt to read through the transcripts or .csv to perform analysis on the data.'
          : 'No transcripts available. Export will become available as soon as one interview has been held.'
      }
      tipPlacement={TipPlacement.Left}
    >
      <Menu
        variant={MenuVariant.Body}
        menuOptions={exportTranscriptsMenu}
        title="Export transcripts"
        titleWidth={150}
      />
    </Tooltip>
  )
}

export default ExportTranscriptsButton
