import { ApolloCache, useApolloClient } from '@apollo/client'
import defer from 'lodash/defer'
import { useContext } from 'react'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../../../../data/gql/questionnaire/queries'
import { DraftQuestionnaireEntry } from '../../../../data/model/questionnaire'
import { useProjectId } from '../../../../hooks/useProjectId'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import QuestionnaireContext from '../../../../modules/Questionnaire/Questionnaire.context'

const updateQuestionnaireCache = (
  cache: ApolloCache<QuestionnaireData>,
  projectId: string,
  surveyId: string,
  entryNumbersToRemove: Set<number>
) => {
  const shouldKeepEntry = (entry: DraftQuestionnaireEntry) => {
    return !entryNumbersToRemove.has(entry.number)
  }
  const createNewlyPositionedEntry = (
    entry: DraftQuestionnaireEntry,
    contextPosition: number
  ) => {
    return {
      ...entry,
      contextPosition
    }
  }

  cache.updateQuery(
    {
      query: QUESTIONNAIRE,
      variables: { projectId, surveyId }
    },
    (data: QuestionnaireData | null) => {
      // This data is taken from local cache but it will give null in cases when cache is not fully ready
      const draftQuestionnaire = data?.draftQuestionnaire
      // If cache is not ready, skip optimistic update
      if (!draftQuestionnaire) {
        return undefined
      }
      return {
        draftQuestionnaire: {
          ...draftQuestionnaire,
          entries: draftQuestionnaire.entries
            .filter(shouldKeepEntry)
            .map(createNewlyPositionedEntry)
        }
      }
    }
  )
}

export const useOptimisticDeleteQuestions = () => {
  const { cache } = useApolloClient()
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { questionnaireState } = useContext(QuestionnaireContext)
  const { selectedCards } = questionnaireState
  // Defer execution to unblock UI - make components state update first (e.g. closing dialog),
  // then make cache update (e.g. updating the whole list of questions)
  return () =>
    defer(updateQuestionnaireCache, cache, projectId, surveyId, selectedCards)
}
