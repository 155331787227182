import { Paragraph } from 'docx'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { getQuestionEnabledSettings } from '../questionnaireUtils'
import { addMediaIfNeeded } from './addMediaUrlIfNeeded'
import { createResponseOptions } from './createResponseOptions'
import { Order } from './model'

export const createEntryContentRanked = async (
  entry: DraftQuestionnaireEntry,
  entries: DraftQuestionnaireEntry[]
): Promise<Paragraph[]> => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const paragraphs = createResponseOptions(entryItem, entries)

  await addMediaIfNeeded(entryItem, paragraphs)

  const enabledSettings = getQuestionEnabledSettings(entryItem.settingValues)

  if (enabledSettings.has(QuestionSettingCode.RandomiseOptions)) {
    paragraphs.push(new Paragraph(Order.Randomise))
  }

  return paragraphs
}
