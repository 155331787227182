import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import { CARD_FOOTER_ROW_HEIGHT } from './AudienceQuestionCardFooter'

export const CardFooterErrorRow = ({
  errorMessage
}: {
  errorMessage: string
}) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      display="flex"
      width="100%"
      alignItems="center"
      height={CARD_FOOTER_ROW_HEIGHT}
    >
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeMs,
          textClasses.weightRegular,
          textClasses.highlightError
        )}
      >
        {errorMessage}
      </p>
    </Grid>
  )
}
