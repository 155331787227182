import React, { useCallback, useEffect, useState } from 'react'
import { DEFAULT_POLLING_INTERVAL } from '../../../constants/misc'
import useAudienceQuestionsCounter from '../../../hooks/audience/useAudienceQuestionsCounter'
import useDisplayedLOI, {
  sharedDisplayedLOI
} from '../../../hooks/localState/useDisplayedLOI'
import useQuestionnaireQuestionsCounter from '../../../hooks/questionnaire/useQuestionnaireQuestionsCounter'
import useCalculateLengthOfInterview from '../../../hooks/useCalculateLengthOfInterview'
import { useFieldwork } from '../../../hooks/useFieldwork'
import usePollCostQuote from '../../../hooks/usePollCostQuote'
import usePollIncidenceRate from '../../../hooks/usePollIncidenceRate'
import { formatThousandsWithCommas } from '../../../utils/HelperFunctions'
import SurveyStatsControl from './SurveyStats.control'
import {
  DEFAULT_LOI,
  calculateCost,
  getQuestionsNumber
} from './SurveyStats.utils'

interface Props {
  showLiveActions?: boolean
}

const SurveyStats: React.FC<Props> = (props: Props) => {
  const { showLiveActions } = props

  const { totalNumberAudienceQuestions } = useAudienceQuestionsCounter()
  const { totalNumberQuestionnaireQuestions } =
    useQuestionnaireQuestionsCounter()

  const calculatedEstimatedLOI = useCalculateLengthOfInterview()

  const isQuestionnairePresent = totalNumberQuestionnaireQuestions
    ? totalNumberQuestionnaireQuestions > 0
    : false
  const { getIncidenceRate } = usePollIncidenceRate()
  const { stringIncidenceRate } = getIncidenceRate()

  const { displayedLOI } = useDisplayedLOI()

  const [displayedLOIValue, setDisplayedLOIValue] =
    useState<number>(DEFAULT_LOI)

  const noOfQuestions = getQuestionsNumber(displayedLOIValue)

  useEffect(() => {
    if (isQuestionnairePresent) {
      sharedDisplayedLOI(calculatedEstimatedLOI)
      setDisplayedLOIValue(DEFAULT_LOI)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalNumberQuestionnaireQuestions,
    totalNumberAudienceQuestions,
    calculatedEstimatedLOI
  ])

  const { fieldwork } = useFieldwork({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    fetchPolicy: 'no-cache'
  })

  const { getCostQuoteData } = usePollCostQuote(displayedLOI)
  const { costPerIncidence } = getCostQuoteData

  const completes: number = fieldwork?.samplesNeeded.adjusted || 0
  const lang = fieldwork?.locale.language

  const totalCost: string | undefined = formatThousandsWithCommas(
    calculateCost(costPerIncidence, completes)
  )

  const handleIncrementAdd = useCallback(() => {
    if (displayedLOIValue < 60) {
      sharedDisplayedLOI(displayedLOIValue + 1)
      setDisplayedLOIValue(displayedLOIValue + 1)
    }
  }, [displayedLOIValue])

  const handleIncrementSubtract = useCallback(() => {
    if (displayedLOIValue > 1) {
      sharedDisplayedLOI(displayedLOIValue - 1)
      setDisplayedLOIValue(displayedLOIValue - 1)
    }
  }, [displayedLOIValue])

  return (
    <SurveyStatsControl
      totalCost={totalCost}
      lang={lang}
      isCostPreview={!isQuestionnairePresent}
      cpi={costPerIncidence}
      completes={completes}
      showLiveActions={showLiveActions}
      displayIncrement={!isQuestionnairePresent}
      displayedLOI={displayedLOI}
      displayedLOIRange={`${displayedLOIValue}`}
      noOfQuestions={noOfQuestions}
      onClickIncrementAdd={handleIncrementAdd}
      onClickIncrementSubstract={handleIncrementSubtract}
      incidenceRate={stringIncidenceRate}
    />
  )
}

export default SurveyStats
