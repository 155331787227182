import {
  Badge,
  Chip,
  Grid,
  KebabMenu,
  Paper,
  Status,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import ListItem from '@mui/material/ListItem'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { Project, ProjectType } from '../../data/gql-gen/dashboard/graphql'
import { SurveyProgress } from '../../data/model/surveyResults'
import useStyles from './ProjectListItem.styles'
import {
  getKebabMenuOptions,
  getMultiMarketPath,
  getSingleMarketPath,
  stringProjectTypeEnumMap,
  stringStatusEnumMap,
  stringToStatusMap
} from './ProjectListItem.utils'
import ProjectListItemDetails from './ProjectListItemDetails'
import ProjectListItemProgress from './ProjectListItemProgress'

export type ProjectModel = Pick<
  Project,
  | 'projectId'
  | 'name'
  | 'projectType'
  | 'creator'
  | 'surveys'
  | 'projectMetadata'
  | 'status'
> & {
  completionDate?: string
  lastUpdate?: string
  surveyProgressList?: SurveyProgress[]
  loadingProgress?: boolean
}

interface Props extends React.LiHTMLAttributes<HTMLElement> {
  project: ProjectModel
  onDuplicateProject?: (project: ProjectModel) => void
  onPauseProject?: (project: ProjectModel) => void
  onUnpauseProject?: (project: ProjectModel) => void
  onDeleteProject?: (project: ProjectModel) => void
  dataCy?: string
}

const ProjectListItem: React.FC<Props> = (props: Props) => {
  const {
    project,
    style,
    onDuplicateProject,
    onPauseProject,
    onUnpauseProject,
    onDeleteProject,
    dataCy
  }: Props = props
  const {
    projectId,
    creator,
    completionDate,
    lastUpdate,
    status,
    name: projectTitle,
    projectType = ProjectType.AdHoc,
    projectMetadata,
    loadingProgress,
    surveys,
    surveyProgressList
  }: ProjectModel = project
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const navigate = useNavigate()

  if (surveys.length < 1) {
    return null
  }

  // By convention the survey is the first item in the list
  const { surveyId } = surveys[0]
  const isAdHoc = projectType === ProjectType.AdHoc
  const isFDChat = projectType === ProjectType.FdChat
  const isMultiMarket = projectType === ProjectType.MultiMarketV2

  const showCompletionDate =
    (isAdHoc || isFDChat || isMultiMarket) && completionDate
  const showCreator = (isAdHoc || isFDChat || isMultiMarket) && creator

  const badgeLabel =
    isAdHoc || isFDChat || isMultiMarket
      ? stringStatusEnumMap.get(
          stringToStatusMap.get(status) || Status.COMPLETE
        )
      : stringProjectTypeEnumMap.get(projectType)

  const badgeStatus: Status =
    isAdHoc || isFDChat || isMultiMarket
      ? stringToStatusMap.get(status) || Status.COMPLETE
      : Status.LIVE

  const chosenPath =
    new Map<ProjectType, string | null | undefined>([
      [
        ProjectType.AdHoc,
        getSingleMarketPath(projectId, surveyId, stringToStatusMap.get(status))
      ],
      [ProjectType.FdChat, `/project/${projectId}/fdchat/${surveyId}`],
      [ProjectType.Mrp, projectMetadata.mrpUrl],
      [
        ProjectType.MultiMarket,
        `/project/${projectId}/survey/${surveyId}/results`
      ],
      [
        ProjectType.MultiMarketV2,
        getMultiMarketPath(projectId, stringToStatusMap.get(status))
      ]
    ]).get(projectType) || '/projects'

  const applyLink = (content: JSX.Element) => {
    return projectType !== ProjectType.Mrp ? (
      <Link
        style={{ textDecoration: 'none', color: 'inherit', height: 72 }}
        onClick={(event) => {
          LogAmplitudeEvent(EventType.ViewedAProject, { surveyId })
          event.stopPropagation()
        }}
        to={chosenPath}
      >
        {content}
      </Link>
    ) : (
      <a
        data-testid="mrp-link"
        style={{ textDecoration: 'none', color: 'inherit', height: 72 }}
        href={chosenPath}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}
      </a>
    )
  }

  return (
    <ListItem
      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        // this is because the link does not cover the whole item. The link cannot cover the whole item because the menu wouldn't work anymore
        LogAmplitudeEvent(EventType.ViewedAProject, { surveyId })
        if (projectType === ProjectType.Mrp) {
          window.open(chosenPath, '_blank')
          event.stopPropagation()
          event.preventDefault()
        } else {
          navigate(chosenPath)
        }
        LogAmplitudeEvent(EventType.ViewedAProject, { surveyId })
      }}
      className={classes.listItemStyle}
      component="li"
      data-cy={dataCy}
      disablePadding
    >
      <Paper className={classes.cardStyle} style={style} elevation={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardContentStyle}
        >
          <Grid container direction="column" item xs={10}>
            {applyLink(
              <>
                <Grid container alignItems="center" direction="row">
                  <p
                    className={classNames(
                      textClasses.default,
                      textClasses.sizeM,
                      textClasses.weightSemiBold,
                      classes.noTextDecoration
                    )}
                  >
                    {projectTitle}
                  </p>
                  {isFDChat && (
                    <Chip label="FDChat" className={classes.projectTypeLabel} />
                  )}
                  {isMultiMarket && (
                    <Chip
                      label="Multi-Market"
                      className={classes.projectTypeLabel}
                    />
                  )}
                </Grid>
                <Grid
                  data-testid="project-list-item-details"
                  container
                  item
                  direction="row"
                  className={classes.projectDetails}
                >
                  {(isAdHoc || isFDChat || isMultiMarket) && (
                    <ProjectListItemProgress
                      surveyId={surveyId}
                      projectStatus={status}
                      surveyProgressList={surveyProgressList}
                      loadingProgress={loadingProgress}
                    />
                  )}
                  {showCompletionDate && (
                    <ProjectListItemDetails
                      label="Completion date"
                      value={completionDate}
                    />
                  )}
                  {lastUpdate && (
                    <ProjectListItemDetails
                      label="Last status update"
                      value={lastUpdate}
                    />
                  )}
                  {showCreator && (
                    <ProjectListItemDetails label="Creator" value={creator} />
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs="auto">
            <Grid container item justifyContent="center" alignItems="center">
              <Badge
                label={badgeLabel}
                status={badgeStatus}
                className={classes.statusDiv}
              />
              {(isAdHoc || isFDChat || isMultiMarket) && (
                <KebabMenu
                  kebabMenuOptions={getKebabMenuOptions({
                    project,
                    onDuplicateProject,
                    onPauseProject,
                    onUnpauseProject,
                    onDeleteProject
                  })}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ListItem>
  )
}

export default ProjectListItem
