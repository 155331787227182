import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'

import { useProjectType } from '../../../Project/Project.hooks'
import useStyles from './AddQuestionHelperText.styles'

const EmptyStateHelperText: React.FC = () => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { isFdChat } = useProjectType()

  return (
    <>
      <div className="fd-grid fd-container">
        <Icon
          name={IconName.InfoOutlinedIcon}
          size={IconSize.Large}
          iconColor={IconColor.Background}
          className={classes.icon}
        />
        <p
          className={classNames(
            textClasses.default,
            textClasses.displayInline,
            textClasses.sizeMl,
            textClasses.highlightBackground,
            textClasses.weightSemiBold,
            textClasses.gutterBottom,
            classes.title
          )}
        >
          Writing your survey
        </p>
      </div>
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeMs,
          textClasses.highlightBackground,
          classes.body
        )}
      >
        Script your research questions here. Add any further demographic
        questions to support your analysis.{' '}
        {!isFdChat
          ? 'Once you add your first question, the quote will change to a real cost estimate. This will update as you add more questions.'
          : ''}
      </p>
    </>
  )
}

export default EmptyStateHelperText
