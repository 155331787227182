import { MutationHookOptions, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import {
  UpdateQuestionnaireEntryV2Input,
  UpdateQuestionnaireEntryV2Mutation
} from '../../data/gql-gen/questionnaire/graphql'
import { UPDATE_QUESTIONNAIRE_ENTRY } from '../../data/gql/questionnaire/mutations/updateQuestionnaireEntry'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useUpdateQuestionnaireEntry = (
  entryId: string,
  options?: MutationHookOptions
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [updateQuestionnaireEntryMutation, { loading, error }] =
    useMutation<UpdateQuestionnaireEntryV2Mutation>(
      UPDATE_QUESTIONNAIRE_ENTRY,
      {
        context: { clientName: 'questionnaire' },
        ...options
      }
    )

  const updateQuestionnaireEntry = useCallback(
    (
      input: Omit<
        UpdateQuestionnaireEntryV2Input,
        'projectId' | 'surveyId' | 'entryId'
      >,
      options?: MutationHookOptions
    ) => {
      questionBeingEditedId(entryId)
      return updateQuestionnaireEntryMutation({
        variables: {
          input: {
            projectId,
            surveyId,
            entryId,
            ...input
          }
        },
        ...options
      })
    },
    [entryId, projectId, surveyId, updateQuestionnaireEntryMutation]
  )

  return { updateQuestionnaireEntry, loading, error }
}
