import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'

import { useStyles } from './styles'

interface Props {
  startDateSelection: JSX.Element
  reviewDataQuality: JSX.Element
  costBreakdown: JSX.Element
  projectOverview: JSX.Element
}

const ConfirmationLayout: React.FC<Props> = (props: Props) => {
  const {
    startDateSelection,
    reviewDataQuality,
    costBreakdown,
    projectOverview
  }: Props = props

  const { classes } = useStyles()

  return (
    <Grid container className={classes.contentConfirmation}>
      <Grid
        container
        item
        xs={8}
        direction="column"
        className={classes.leftContainer}
      >
        <Grid item className={classes.dateSelection}>
          {startDateSelection}
        </Grid>
        <Grid item>{reviewDataQuality}</Grid>
      </Grid>
      <Grid item xs={4} className={classes.rightContainer}>
        <Grid item xs={12} className={classes.costBreakdown}>
          {costBreakdown}
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {projectOverview}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConfirmationLayout
