import {
  IconButton,
  IconColor,
  IconName,
  IconSize,
  PercentageInput,
  QualificationSwitch,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { DragHandle } from '@mui/icons-material'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import useStyles from './ListItemQuota.style'
import { getQuotaFieldGridSize } from './ListItemQuota.utils'

export interface Props extends React.LiHTMLAttributes<HTMLLIElement> {
  totalCompletes: number
  disableGutters?: boolean
  defaultQuotaValue?: string
  questionText?: string
  readonly?: boolean
  quotaNameComponent?: JSX.Element
  isQualified?: boolean
  hasQualification?: boolean
  hasQuotas?: boolean
  hasQuotaError?: boolean
  onQualificationChange?: (value: boolean) => void
  onQuotaChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onQuotaBlur?: (text: string) => void
  onQuotaDelete?: () => void
}

const ListItemQuota: React.FC<Props> = (props: Props) => {
  const {
    questionText = '',
    readonly,
    disableGutters = false,
    quotaNameComponent,
    isQualified,
    hasQualification,
    hasQuotas = true,
    hasQuotaError = false,
    onQualificationChange,
    onQuotaChange,
    onQuotaBlur,
    defaultQuotaValue = '0',
    totalCompletes,
    onQuotaDelete
  }: Props = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const calculateTotalCompletes: (
    completes: number,
    percentage: number
  ) => number = (completes, percentage) =>
    Math.round((completes * percentage + Number.EPSILON) / 100)

  const TOOLTIP_CHARACTERS = 120
  const textTooltip =
    questionText.length > TOOLTIP_CHARACTERS ? questionText : ''

  const quotaFieldGridSize = getQuotaFieldGridSize({
    hasQualification: !!hasQualification,
    hasQuota: hasQuotas,
    hasDeleteIcon: !!onQuotaDelete
  })

  return (
    <ListItem disableGutters={disableGutters} className={classes.listItem}>
      <Grid container direction="row" alignItems="center">
        <Grid
          container
          item
          xs={quotaFieldGridSize}
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid item xs={hasQuotas ? 11 : 12}>
            <Tooltip title={textTooltip} placement="top-start">
              {quotaNameComponent || (
                <OutlinedInput
                  value={questionText}
                  fullWidth
                  disabled
                  className={classNames(
                    classes.inputRootStyle,
                    textClasses.default
                  )}
                  classes={{
                    root: classes.inputStyle
                  }}
                />
              )}
            </Tooltip>
          </Grid>
          {hasQuotas && (
            <Grid
              container
              item
              xs={1}
              className={classes.equalIcon}
              justifyContent="center"
            >
              <DragHandle />
            </Grid>
          )}
        </Grid>
        {hasQuotas && (
          <>
            <Grid container item direction="row" justifyContent="center" xs={2}>
              <Grid item xs={7}>
                <PercentageInput
                  readOnly={readonly}
                  isError={hasQuotaError}
                  value={defaultQuotaValue}
                  defaultValue={defaultQuotaValue}
                  onChange={onQuotaChange}
                  onFocusOut={onQuotaBlur}
                />
              </Grid>
              <Grid item xs={5} />
            </Grid>
            <Grid container item justifyContent="center" xs={1}>
              <Grid container justifyContent="center" item xs={7}>
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.highlightBackground,
                    textClasses.sizeM
                  )}
                >
                  {calculateTotalCompletes(
                    totalCompletes,
                    parseFloat(defaultQuotaValue)
                  )}
                </p>
              </Grid>
              <Grid item xs={5} />
            </Grid>
          </>
        )}
        {hasQualification && (
          <Grid container justifyContent="center" item xs={2}>
            <QualificationSwitch
              editable
              hasLayoutWrapper={false}
              hasQualification={hasQualification}
              isQualifying={isQualified}
              onQualifyingChange={onQualificationChange}
            />
          </Grid>
        )}
        {onQuotaDelete && (
          <Grid container item justifyContent="center" xs={1}>
            <IconButton
              iconName={IconName.DeleteOutline}
              iconSize={IconSize.Large}
              iconColor={IconColor.Background}
              onClick={onQuotaDelete}
              ariaLabel="Audience question delete"
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  )
}

export default ListItemQuota
