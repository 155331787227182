import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const bulkAddDraftCombinedResponseOptions: Resolver = (
  _,
  { values, insertAtIndex }: { values: string[]; insertAtIndex: number },
  { cache }: { cache: InMemoryCache }
) => {
  const data = cache.readQuery({
    query: GET_DRAFT_COMBINED_QUESTION
  })

  if (!data) {
    throw new Error('No draft combined question found in cache')
  }

  const newResponseOptions = values.map((value, index) => ({
    __typename: 'DraftCombinedResponseOption' as const,
    id: uuid(),
    mappingNumber: insertAtIndex + index + 1,
    value
  }))

  cache.writeQuery({
    query: GET_DRAFT_COMBINED_QUESTION,
    data: produce(data, (draft) => {
      const { mappedResponseOptions } = draft.draftCombinedQuestion
      // insert new response options at the specified index
      mappedResponseOptions.splice(insertAtIndex, 0, ...newResponseOptions)

      // update mapping numbers of the response options
      mappedResponseOptions.forEach((option, index) => {
        option.mappingNumber = index + 1
      })
    })
  })

  return newResponseOptions
}
