import { useApolloClient } from '@apollo/client'
import { useParams } from 'react-router'
import { SurveyQueryVariables } from '../data/gql-gen/dashboard/graphql'
import { SurveyParams } from '../data/model/surveyParams'
import { SURVEY, SurveyData } from '../modules/Project/Project.query'

const useGetSurveyFieldwork = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const client = useApolloClient()

  const readSurveyFieldwork = () => {
    const queryResult = client.readQuery<SurveyData, SurveyQueryVariables>({
      query: SURVEY,
      variables: {
        surveyId
      }
    })

    return queryResult?.survey
  }

  return { readSurveyFieldwork }
}

export default useGetSurveyFieldwork
