import { Grid, Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'
import React from 'react'
import { ProjectTypesPanel } from '../../controls/ProjectTypesPanel'
import useStyles from './styles'

interface Props {
  filters: JSX.Element
  ordering: JSX.Element
  projectsList: JSX.Element
}

const ProjectPageLayout: React.FC<Props> = (props: Props) => {
  const { filters, ordering, projectsList }: Props = props
  const { classes, cx: classnames } = useStyles()

  return (
    <Grid
      container
      className={classnames(
        classes.projectsListContent,
        'projects-page-layout'
      )}
    >
      <Grid item xs={1} />
      <Grid container item xs={10}>
        <Grid item xs={12}>
          <ProjectTypesPanel />
        </Grid>
        <Text size={TextSize.l} weight={TextWeight.Bold}>
          Recent Projects
        </Text>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.projectsListHeader}
          marginTop={1}
        >
          <Grid item xs={6}>
            {filters}
          </Grid>
          <Grid container item direction="row" justifyContent="flex-end" xs={6}>
            <Grid item xs="auto">
              {ordering}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          {projectsList}
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default ProjectPageLayout
