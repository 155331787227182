import { useMutation } from '@apollo/client'
import { Button, ButtonVariant, Grid } from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ADD_PANEL_SUPPLIER_ORDER,
  AddPanelSupplierOrderData,
  useStyles
} from '.'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  getSurveyOrderSoftLaunchRefetchQuery,
  useGetSoftLaunchOrder
} from '../../../components/SoftLaunch'
import { AddPanelSupplierOrderMutationVariables } from '../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../data/logger'
import { SurveyParams } from '../../../data/model/surveyParams'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { FullLaunchDialogContainer } from '../FullLaunchDialog'

const FullLaunchButtonContainer: React.FC = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { classes } = useStyles()

  const [isPlacingOrder, setIsPlacingOrder] = useState<boolean>(false)
  const [isFullLaunchDialogOpen, setIsFullLaunchDialogOpen] =
    useState<boolean>(false)
  const {
    remainingSamplesNeeded,
    isSurveyOrderLoading,
    isSoftLaunchOrder,
    isFullLaunchOrder
  } = useGetSoftLaunchOrder()
  const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(isFullLaunchOrder)

  const [addPanelSupplierOrder, { loading: loadingAddPanelSupplierOrder }] =
    useMutation<
      AddPanelSupplierOrderData,
      AddPanelSupplierOrderMutationVariables
    >(ADD_PANEL_SUPPLIER_ORDER, {
      context: { clientName: 'dashboard' },
      onCompleted: () => {
        setIsOrderPlaced(true)
        setIsPlacingOrder(false)
        setIsFullLaunchDialogOpen(false)

        LogAmplitudeEvent(EventType.ConfirmedFullLaunch, { surveyId })
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'addPanelSupplierOrder',
          error
        )
        setIsOrderPlaced(false)
        setIsPlacingOrder(false)
      },
      refetchQueries: [getSurveyOrderSoftLaunchRefetchQuery(surveyId)]
    })

  const handleConfirmFullLaunch: () => void = () => {
    setIsPlacingOrder(true)

    addPanelSupplierOrder({
      variables: {
        surveyId,
        orderDescription: 'Full launch',
        samplesNeeded: remainingSamplesNeeded
      }
    })
  }

  const handleOnClick = (): void => {
    setIsFullLaunchDialogOpen(true)

    LogAmplitudeEvent(EventType.ClickedFullLaunch, { surveyId })
  }

  const handleToggleOpenDialog: (open: boolean) => void = (open) => {
    setIsFullLaunchDialogOpen(open)
    setIsPlacingOrder(open)
  }

  const isFullLaunchProcessing =
    isPlacingOrder || isSurveyOrderLoading || isOrderPlaced
  const buttonText = 'Full launch'
  const isOrderNotPlacedYet = !isOrderPlaced && !isFullLaunchOrder

  return isSoftLaunchOrder && isOrderNotPlacedYet ? (
    <Grid item xs="auto" className={classes.fullLaunch}>
      <Button
        disabled={isFullLaunchProcessing || isFullLaunchDialogOpen}
        variant={ButtonVariant.PrimaryFilled}
        onClick={handleOnClick}
        style={{ marginRight: '10px' }}
      >
        {buttonText}
      </Button>
      <FullLaunchDialogContainer
        showDialog={isFullLaunchDialogOpen}
        toggleOpenDialog={handleToggleOpenDialog}
        onConfirm={handleConfirmFullLaunch}
        isPlacingOrder={isPlacingOrder || loadingAddPanelSupplierOrder}
      />
    </Grid>
  ) : null
}

export default FullLaunchButtonContainer
