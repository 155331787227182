import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const DRAFT_MATRIX_ITEM_QUALIFICATION = questionnaireGql(/* GraphQL */ `
  fragment DraftMatrixItemQualification on DraftMatrixItem {
    matrixQualification {
      qualifyingOptions
      matrixRowQualifyingCondition {
        numberOfQualifyingRows
        operator
      }
    }
  }
`)
