import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const IMPORT_QUESTIONNAIRE_V2 = questionnaireGql(/* GraphQL */ `
  mutation importQuestionnaireV2($input: ImportQuestionnaireV2Input!) {
    importQuestionnaireV2(input: $input) {
      questionnaireId
      marketIds
      entries {
        ...DraftQuestionnaireEntry
      }
      sinceDate
      createdDate
    }
  }
`)
