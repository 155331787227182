import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextHighlight,
  TextWeight,
  makeStyles
} from '@focaldata/cin-ui-components'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles()({
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    '&.active': {
      '& h6, & svg': {
        color: 'var(--primary)'
      }
    }
  },
  icon: {
    marginBottom: '4px'
  }
})

export const MultiMarketNavigationButton = ({
  to,
  iconName,
  label
}: {
  to: string
  iconName: IconName
  label: string
}) => {
  const { classes } = useStyles()
  return (
    <NavLink className={classes.link} to={to}>
      <Icon
        className={classes.icon}
        iconColor={IconColor.Text}
        name={iconName}
        size={IconSize.Large}
      />
      <Text highlight={TextHighlight.Standard} weight={TextWeight.SemiBold}>
        {label}
      </Text>
    </NavLink>
  )
}
