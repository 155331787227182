import {
  Button,
  ButtonSize,
  ButtonVariant,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import { useContext } from 'react'
import QuestionnaireContext, {
  unselectAllCards
} from '../../../../modules/Questionnaire/Questionnaire.context'

const DeselectAllCardsContainer = (): JSX.Element | null => {
  const { dispatch, questionnaireState } = useContext(QuestionnaireContext)
  const isAtLeastOneQuestionSelected = questionnaireState.selectedCards.size > 0

  return isAtLeastOneQuestionSelected ? (
    <Button
      variant={ButtonVariant.PrimaryNoOutline}
      onClick={() => dispatch(unselectAllCards())}
      buttonSize={ButtonSize.Small}
    >
      <Text highlight={TextHighlight.Standard} size={TextSize.ms}>
        Deselect all
      </Text>
    </Button>
  ) : null
}

export default DeselectAllCardsContainer
