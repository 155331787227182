import { setContext } from '@apollo/client/link/context'
import { ApolloLink } from '@apollo/client/link/core/ApolloLink'
import { RetryLink } from '@apollo/client/link/retry/retryLink'
import { fromPromise } from '@apollo/client/link/utils/fromPromise'
import { Refresh } from '../data/rest/accountUser'
import { AccessToken, getLoginRedirectPath } from '../utils/authUtils'

const routeToLogin = () => {
  const redirectTo = getLoginRedirectPath()
  window.location.assign(`${window.location.origin}${redirectTo}`)
}

export const authLink = setContext(() => {
  return () => {
    const { token, valid } = AccessToken.verify()
    if (valid) {
      return {
        headers: {
          ...AccessToken.requestHeadersWithToken(token),
          retry: false
        }
      }
    }
    return {
      headers: {
        retry: true
      }
    }
  }
})

export const retryLink = new RetryLink({
  attempts: (_, operation) => {
    operation.setContext({
      headers: {
        ...AccessToken.requestHeaders(),
        retry: true
      }
    })
    return (
      operation.getContext().headers &&
      operation.getContext().headers.retry === true
    )
  }
})

let isRetrying: boolean
export const refreshLink = new ApolloLink((operation, forward) => {
  if (
    operation.getContext().headers &&
    operation.getContext().headers.retry &&
    !isRetrying
  ) {
    isRetrying = true
    const forward$ = fromPromise(Refresh())
    forward$.subscribe(
      (response) => {
        AccessToken.set(response.data.accessToken)
        operation.setContext({
          headers: {
            ...AccessToken.requestHeaders(),
            retry: true
          }
        })
        isRetrying = false
        return forward(operation)
      },
      () => {
        isRetrying = false
        // The 'refreshToken' is invalid or the account-service is down so route the user to login page
        routeToLogin()
      }
    )
  }
  return forward(operation)
})
