import { useFragment, useQuery } from '@apollo/client'
import { CustomCriterionValidationError } from '../../../data/gql-gen/fieldwork/graphql'
import { CUSTOM_CRITERION_VALIDATION_ERRORS } from '../../../data/gql/questionnaire/fragments/criterionValidationErrors'
import { AUDIENCE_VALIDATION_ERRORS } from '../../../data/gql/questionnaire/queries/audienceValidationErrors'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'

export const useCustomCriterionValidationErrors = (questionLk: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  useQuery(AUDIENCE_VALIDATION_ERRORS, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    }
  })

  const { data } = useFragment({
    fragment: CUSTOM_CRITERION_VALIDATION_ERRORS,
    fragmentName: 'CustomCriterionValidationErrors',
    from: { __typename: 'CustomCriterionValidationErrors', questionLk },
    canonizeResults: true
  })

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { customCriterionErrors = [] } = data ?? {}

  let errorMessage = ''
  const hasInvalidResponseOptionsToDisplayError =
    customCriterionErrors.includes(
      CustomCriterionValidationError.InvalidResponseOptionsToDisplayError
    )
  const hasNoLoopBassisSelectedError = customCriterionErrors.includes(
    CustomCriterionValidationError.SectionNotFoundError
  )
  if (hasInvalidResponseOptionsToDisplayError) {
    errorMessage =
      'Max display options cannot be less than 2 or greater than total number of response options'
  } else if (hasNoLoopBassisSelectedError) {
    errorMessage =
      'Max display options basis cannot be empty. Please use dropdown for selection'
  }

  return {
    errorMessage,
    hasInvalidResponseOptionsToDisplayError,
    hasNoLoopBassisSelectedError
  }
}
