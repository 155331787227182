import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { SnackbarProvider } from '@focaldata/cin-ui-components'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// Apollo polyfills Symbol.observable, but this loads after Redux dev tools which uses Symbol.observable
// This causes React dev tools to warn about Symbol.observable being different if you don't have
// the React dev tools open when you load the page. This ensures Symbol.observable is polyfilled with the
// same polyfill as Apollo in order to avoid the warning.
import 'symbol-observable'
import App from './App'
import { InitAmplitude } from './amplitude'
import { Datadog } from './tracking/datadog'
import { AccessToken } from './utils/authUtils'

InitAmplitude()
Datadog.init()
AccessToken.migrate()

if (process.env.NODE_ENV !== 'production') {
  loadErrorMessages()
  loadDevMessages()
}

const container = document.getElementById('root')
// @todo Legacy eslint violation – fix this when editing
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider maxSnack={2} preventDuplicate dense={false}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>
)
