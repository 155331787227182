import {
  ExternalHyperlink,
  HeadingLevel,
  ImageRun,
  Paragraph,
  TextRun
} from 'docx'
import logoSrc from '../../assets/fd-logo.svg'
import dayjs from '../dayjs'

interface SummaryParams {
  projectName: string
  surveyId: string
  countryName: string
  previewUrl: string
}

export const createSummary = async ({
  projectName,
  surveyId,
  countryName,
  previewUrl
}: SummaryParams) => {
  const blob = await fetch(logoSrc).then((r) => r.blob())
  const arrayBuffer = await new Response(blob).arrayBuffer()
  const currentDateAndTimeFormatted = dayjs().format('HH:mm DD MMMM YYYY')
  const utcOffsetMinutes = dayjs().utcOffset()
  const utcOffsetFormatted = `${utcOffsetMinutes < 0 ? '' : '+'}${
    utcOffsetMinutes / 60
  }`

  return [
    new Paragraph({
      children: [
        new ImageRun({
          data: arrayBuffer,
          transformation: {
            width: 252,
            height: 38
          }
        })
      ],
      spacing: {
        after: 300
      }
    }),
    new Paragraph({
      text: projectName.toUpperCase(),
      heading: HeadingLevel.HEADING_2
    }),
    new Paragraph(`Survey ID: ${surveyId}`),
    new Paragraph(
      `Script exported: ${currentDateAndTimeFormatted} (UTC ${utcOffsetFormatted})`
    ),
    new Paragraph(`Country: ${countryName}`),
    new Paragraph({
      children: [
        new ExternalHyperlink({
          children: [
            new TextRun('Preview link: '),
            new TextRun({
              text: previewUrl,
              style: 'Hyperlink'
            })
          ],
          link: previewUrl
        })
      ],
      spacing: {
        after: 400
      }
    })
  ]
}
