import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  accordion: {
    width: '300px',
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    // TODO: expose the additionalPalette from cin-ui-components
    backgroundColor: '#F6F9FF'
  },
  container: {
    marginTop: theme.spacing(1)
  },
  standardAudienceTitle: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  advancedCriteriaButton: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(0.5)
  }
})

export default useStyles
