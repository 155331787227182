import { DraftSectionItem } from '../components/Section/Section.model'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntrySettingValue,
  EntryType,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../data/model/questionnaire'
import {
  ResponseOptionsByQuestion,
  SettingsByQuestion
} from '../modules/Questionnaire/Questionnaire.slice'
import {
  flattenEntries,
  getEntryId
} from '../modules/Questionnaire/Questionnaire.utils'
import {
  checkIfSingleChoice,
  getQuestionSettings,
  isSettingEnabled
} from './questionnaireUtils'

const convertSecondsToMinutes = (secondsTime: number): number => {
  return secondsTime / 60
}

enum EntityTime {
  SINGLE_BASIC = 15,
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MULTIPLE_BASIC = 15,
  RANKED = 20,
  FREE_TEXT = 30,
  SINGLE_MATRIX = 40,
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MULTIPLE_MATRIX = 40,
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SLIDER = 15,
  TEXT_INSTRUCTION = 17,
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PRIVACY_TERMS = 17,
  AUDIENCE_QUESTION = 10,
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MAX_DIFF = 20
}

const DEFAULT_LOOPING_ITERATIONS = 1

const getTimesForMatrix: (nrOfRows: number) => number = (nrOfRows) => {
  if (nrOfRows <= 5) return 27
  if (nrOfRows <= 10) return 38
  if (nrOfRows <= 15) return 50
  if (nrOfRows <= 20) return 72
  return 113
}

const MAX_DIFF_OVHERHEAD_TIME_MULTIPLIER = 1 // although each set is a question, it takes less time to navigate it than a normal question since there is no context change

const getTimeForMaxDiff = (maxDiffQuestion: DraftQuestionItem): number => {
  if (
    !maxDiffQuestion.maxDiffSpecification ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !maxDiffQuestion.maxDiffSpecification.experimentalDesigns ||
    maxDiffQuestion.maxDiffSpecification.experimentalDesigns.length === 0
  ) {
    return 30 // this is used as a placeholder while the maxdiff doesn't have a spec uploaded
  }
  const { experimentalDesigns } = maxDiffQuestion.maxDiffSpecification
  const firstDesign = experimentalDesigns[0]
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const numberOfSets = firstDesign.sets?.length
  return numberOfSets * MAX_DIFF_OVHERHEAD_TIME_MULTIPLIER * EntityTime.MAX_DIFF
}

const getQuestionTimerSeconds = (
  settingValues: EntrySettingValue[]
): number => {
  const settings = getQuestionSettings(settingValues)
  const timerValue =
    Number(settings.get(QuestionSettingCode.QuestionTimer)) || 0

  return timerValue
}

const calculateLOIForQuestion: (
  questionnaireEntry: DraftQuestionnaireEntry
) => number = (questionnaireEntry) => {
  if (questionnaireEntry.entryType === EntryType.QuestionEntryType) {
    const questionEntryItem = questionnaireEntry.entryItem
    if (questionEntryItem.questionTypeCode === QuestionTypeCode.Basic) {
      // SINGLE BASIC
      const isSingleChoice = checkIfSingleChoice(questionEntryItem)
      const isMultipleChoice = questionEntryItem.settingValues.some(
        (settingValue) => settingValue.value === SettingValue.MultipleChoice
      )
      if (isSingleChoice) {
        return EntityTime.SINGLE_BASIC
      }
      // MULTIPLE BASIC
      if (isMultipleChoice) {
        return EntityTime.MULTIPLE_BASIC
      }
    }
    // SLIDER
    if (questionEntryItem.questionTypeCode === QuestionTypeCode.Scale) {
      return EntityTime.SLIDER
    }
    // RANKED
    if (questionEntryItem.questionTypeCode === QuestionTypeCode.Ranked) {
      return EntityTime.RANKED
    }
    // FREE TEXT
    if (questionEntryItem.questionTypeCode === QuestionTypeCode.FreeText) {
      return EntityTime.FREE_TEXT
    }
    if (questionEntryItem.questionTypeCode === QuestionTypeCode.MaxDiff) {
      const timeMaxDiff = getTimeForMaxDiff(questionEntryItem)
      return timeMaxDiff
    }
  }
  // MATRIX
  if (questionnaireEntry.entryType === EntryType.MatrixEntryType) {
    const matrixEntryItem = questionnaireEntry.entryItem
    return getTimesForMatrix(matrixEntryItem.matrixRows.length)
  }
  // TEXT INSTRUCTION
  if (questionnaireEntry.entryType === EntryType.TextCardEntryType) {
    return EntityTime.TEXT_INSTRUCTION
  }
  return 0
}

const getLoopingIterations = (
  entry: DraftQuestionnaireEntry,
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): number => {
  const sectionEntries = entries.filter(
    (entry) => entry.entryType === EntryType.SectionEntryType
  )

  if (sectionEntries.length === 0) {
    return DEFAULT_LOOPING_ITERATIONS
  }

  const parentSection = sectionEntries.find((sectionEntry) => {
    return (
      entry.sectionId === (sectionEntry.entryItem as DraftSectionItem).sectionId
    )
  })

  if (!parentSection) {
    return DEFAULT_LOOPING_ITERATIONS
  }

  const parentSectionItem = parentSection.entryItem as DraftSectionItem

  const isLoopingEnabled = isSettingEnabled(
    parentSectionItem.settingValues,
    QuestionSettingCode.Looping
  )

  if (!isLoopingEnabled) {
    return DEFAULT_LOOPING_ITERATIONS
  }

  const sourceEntryNumber = parentSectionItem.loopingConfig?.sourceEntryNumber
  const sourceEntry = flattenEntries(entries).find(
    (entry) => entry.number === sourceEntryNumber
  )

  if (!sourceEntry) {
    return DEFAULT_LOOPING_ITERATIONS
  }

  const entryItem = sourceEntry.entryItem as DraftQuestionItem

  // in case source is single choice then looping will not be accounted, thus no multiplication is needed for LOI
  if (checkIfSingleChoice(entryItem)) {
    return DEFAULT_LOOPING_ITERATIONS
  }

  // if source entry item has less response options than inside looping configuration, use response options amount as maximum possible iteration value, otherwise use configuration value
  const maxIterationsLimit =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOptionsByQuestion[entryItem.questionLk]?.length ||
    DEFAULT_LOOPING_ITERATIONS
  const maxIterations =
    parentSectionItem.loopingConfig?.maxIterations ?? DEFAULT_LOOPING_ITERATIONS

  return Math.min(maxIterationsLimit, maxIterations)
}

const calculateLOIquestionnaireQuestions = (
  questionnaireEntries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion,
  settingsByQuestion: SettingsByQuestion
): number => {
  const flattenedEntries = flattenEntries(questionnaireEntries)

  const forks = flattenedEntries.filter(
    (entry): entry is DraftQuestionnaireEntry<EntryType.ForkEntryType> =>
      entry.entryType === EntryType.ForkEntryType
  )

  // for each branch of a fork, calculate the total LOI
  // then calculate the LOI per fork
  const timesPerFork = forks.map((forkEntry) => {
    const { fork } = forkEntry.entryItem
    const { branches } = fork
    const loiPerBranch = branches.map((branch) => {
      // for each branch, check in the questionnaire and get the questions on that branch
      const questionLOIs = flattenedEntries
        .filter(
          (entry) =>
            entry.forks.filter(
              (fork2) =>
                fork2.forkId === fork.forkId &&
                fork2.branchNumber === branch.branchNumber
            ).length > 0
        )
        .map(
          (entry2) =>
            Math.max(
              calculateLOIForQuestion(entry2),
              getQuestionTimerSeconds(
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                settingsByQuestion[getEntryId(entry2)] || []
              )
            ) *
            getLoopingIterations(
              entry2,
              questionnaireEntries,
              responseOptionsByQuestion
            )
        )

      const toReturn = {
        loi: questionLOIs.reduce(
          (sum: number, current: number) => sum + current,
          0
        ),
        percentage: branch.percentage
      }

      return toReturn
    })

    const loiForFork = loiPerBranch.reduce<number>(
      (sum, current) => sum + current.loi * current.percentage,
      0
    )

    return loiForFork
  })

  const timesArray = flattenedEntries.map((questionnaireEntry) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!questionnaireEntry.forks || questionnaireEntry.forks.length === 0) {
      return (
        Math.max(
          calculateLOIForQuestion(questionnaireEntry),
          getQuestionTimerSeconds(
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            settingsByQuestion[getEntryId(questionnaireEntry)] || []
          )
        ) *
        getLoopingIterations(
          questionnaireEntry,
          questionnaireEntries,
          responseOptionsByQuestion
        )
      )
    }
    return 0
  })

  const totalSecondsQuestionnaireQuestions = timesArray.reduce(
    (sum: number, current: number) => sum + current,
    0
  )

  const totalForForks = timesPerFork.reduce((sum, current) => sum + current, 0)

  return totalSecondsQuestionnaireQuestions + totalForForks
}

export const calculateLengthOfInterview = (
  questionnaireEntries: DraftQuestionnaireEntry[],
  audienceQuestionCount: number,
  responseOptionsByQuestion: ResponseOptionsByQuestion,
  settingsByQuestion: SettingsByQuestion
) => {
  const totalSecondsAudienceQuestions =
    audienceQuestionCount * EntityTime.AUDIENCE_QUESTION

  const totalSecondsQuestionnaireQuestions = calculateLOIquestionnaireQuestions(
    questionnaireEntries,
    responseOptionsByQuestion,
    settingsByQuestion
  )

  const totalSecondsLOI =
    totalSecondsAudienceQuestions +
    totalSecondsQuestionnaireQuestions +
    EntityTime.PRIVACY_TERMS

  const totalMinutesLOI =
    totalSecondsLOI > 0 ? convertSecondsToMinutes(totalSecondsLOI) : 0

  if (totalMinutesLOI === 0) {
    return 0
  }
  if (totalMinutesLOI <= 1) {
    return 1
  }

  return Math.ceil(totalMinutesLOI)
}
