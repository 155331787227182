const MAX_GRID_SIZE = 12

export const getQuotaFieldGridSize = ({
  hasQuota,
  hasQualification,
  hasDeleteIcon
}: {
  hasQuota: boolean
  hasQualification: boolean
  hasDeleteIcon: boolean
}): number => {
  let quotaFieldGridSize = MAX_GRID_SIZE

  if (hasQuota) {
    quotaFieldGridSize -= 3
  }

  if (hasDeleteIcon) {
    quotaFieldGridSize -= 1
  }

  if (hasQualification) {
    quotaFieldGridSize -= 2
  }

  return quotaFieldGridSize
}
