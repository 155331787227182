import { questionnaireGql } from '../../data/gql-gen/questionnaire'

export const DUPLICATE_QUESTIONNAIRE_V2 = questionnaireGql(/* GraphQL */ `
  mutation duplicateQuestionnaireV2(
    $projectId: ID!
    $newProjectId: ID!
    $newSurveyId: ID!
    $accountMarkup: Float
  ) {
    duplicateQuestionnaireV2(
      projectId: $projectId
      newProjectId: $newProjectId
      newSurveyId: $newSurveyId
      accountMarkup: $accountMarkup
    ) {
      questionnaireId
      marketIds
      createdDate
      sinceDate
      entries {
        number
        position
        contextPosition
        createdDate
        sinceDate
      }
    }
  }
`)
