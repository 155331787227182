import { useQuery } from '@apollo/client'
import { Grid } from '@focaldata/cin-ui-components'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { validate } from 'uuid'
import Subheader from '../../../components/Subheader'
import ErrorPage, { ErrorType } from '../../../controls/ErrorPage'
import {
  ProjectBySurveyIdQueryVariables,
  SurveyState
} from '../../../data/gql-gen/dashboard/graphql'
import { sharedDisplayedLOI } from '../../../hooks/localState/useDisplayedLOI'
import usePrepareApolloMiddleware from '../../../hooks/usePrepareApolloMiddleware'
import { useProjectIdValidation } from '../../../hooks/useProjectIdValidation'
import useSubheaderSizing from '../../../hooks/useSubheaderSizing'
import { useSurveyId } from '../../../hooks/useSurveyId'
import NavigationSubheaderLayout from '../../../layouts/NavigationSubheaderLayout'
import SurveyLayout from '../../../layouts/SurveyLayout'
import TwoChildRowLayout from '../../../layouts/TwoChildRowLayout'
import { Audience } from '../../../modules/Audience'
import { AudienceContextProvider } from '../../../modules/Audience/Audience.context'
import CompletesInput from '../../../modules/Audience/CompletesInput/CompletesInput.container'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData
} from '../../../modules/Project/Project.query'
import { ProjectNameAndStatus } from '../../../modules/Project/ProjectNameAndStatus'
import { Questionnaire } from '../../../modules/Questionnaire'
import ImportFromFileDialog from '../../../modules/Questionnaire/DocxImport/DocxImportDialog'
import { QuestionnaireContextProvider } from '../../../modules/Questionnaire/Questionnaire.context'
import { SurveyCompletionProgress } from '../../../modules/Survey/SurveyCompletionProgress'
import { SurveyErrorPanel } from '../../../modules/Survey/SurveyStats/SurveyErrorPanel'
import SurveyStats from '../../../modules/Survey/SurveyStats/SurveyStats.container'
import { DEFAULT_LOI } from '../../../modules/Survey/SurveyStats/SurveyStats.utils'
import { Datadog } from '../../../tracking/datadog'
import {
  checkIsAudiencePage,
  checkIsConfirmationPage,
  checkIsQuestionnairePage,
  checkIsResultsPage,
  validateSurveyPath
} from '../../../utils/HelperFunctions'
import { ActionsMenuContainer } from './ActionsMenu'
import { AudienceQuestionsToggle } from './AudienceQuestionsToggle'
import BreadcrumbNavigation from './BreadcrumbNavigation'
import CompleteHeader from './CompleteHeader'
import Confirmation from './Confirmation'
import { DeselectAllCardsContainer } from './DeselectAllCards'
import { QuestionsBulkDeleteButtonContainer } from './QuestionsBulkDeleteButton'
import { QuestionsSelectorCounterContainer } from './QuestionsSelectorCounter'
import Results from './Results/Results'

const Survey = () => {
  const { subheaderLeftSideMaxWidth } = useSubheaderSizing({ isPublic: false })
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false)
  const surveyId = useSurveyId()

  usePrepareApolloMiddleware()

  const isSurveyValid = validate(surveyId)
  const isPathValid = validateSurveyPath()

  const isConfirmationPage = checkIsConfirmationPage()
  const isResultsPage = checkIsResultsPage()
  const isQuestionnairePage = checkIsQuestionnairePage()
  const isAudiencePage = checkIsAudiencePage()

  const hasOneSubheader = isConfirmationPage

  // reset shared displayed LOI whenever visiting a new or duplicated survey
  useEffect(() => {
    sharedDisplayedLOI(DEFAULT_LOI)
  }, [])

  useEffect(() => {
    Datadog.updateUserSession({ surveyId })
  }, [surveyId])

  const { data: projectData } = useQuery<
    ProjectData,
    ProjectBySurveyIdQueryVariables
  >(PROJECT_BY_SURVEY_ID, {
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    }
  })

  const { isProjectIdValid } = useProjectIdValidation()

  // If the project state is not draft the user shouldn't have access to
  // audience/questionnaire/confirmation pages. If they are accessed, navigate to projects list
  const projectState = projectData?.project.surveys[0]?.state
  if (
    projectState &&
    projectState !== SurveyState.Draft &&
    (isQuestionnairePage || isConfirmationPage || isAudiencePage)
  ) {
    return <Navigate to="/projects" />
  }

  if (!isPathValid || !isSurveyValid || !isProjectIdValid || showErrorPage) {
    return <ErrorPage errorType={ErrorType.NotFound404} />
  }

  const contentSwitch = (
    <Routes>
      <Route
        path="audience"
        element={<Audience setShowErrorPage={setShowErrorPage} />}
      />
      <Route path="questionnaire" element={<Questionnaire />} />
      <Route path="confirm" element={<Confirmation />} />
      <Route path="results" element={<Results />} />
    </Routes>
  )

  const confirmPageSubheader = (
    <NavigationSubheaderLayout
      leftChild={<ProjectNameAndStatus />}
      middleChild={<BreadcrumbNavigation />}
      rightChild={<SurveyErrorPanel />}
    />
  )

  const audienceAndQuestionnairePageSubheader = (
    <>
      <Subheader
        leftRightPadding={false}
        topBottomPadding={false}
        leftChild={
          <TwoChildRowLayout
            maxWidth="65%"
            spaceBetweenChildren
            leftChild={<ProjectNameAndStatus />}
            rightChild={<CompletesInput />}
          />
        }
        rightChild={<SurveyStats />}
      />
      <NavigationSubheaderLayout
        leftChild={
          isQuestionnairePage ? <AudienceQuestionsToggle /> : undefined
        }
        middleChild={<BreadcrumbNavigation />}
        rightChild={
          isQuestionnairePage ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <DeselectAllCardsContainer />
              <QuestionsBulkDeleteButtonContainer />
              <QuestionsSelectorCounterContainer />
              <ImportFromFileDialog />
              <ActionsMenuContainer />
            </Grid>
          ) : undefined
        }
      />
    </>
  )

  const resultsPageSubheader = (
    <Subheader
      topBottomPadding={false}
      leftRightPadding={false}
      shadow
      leftChild={
        <TwoChildRowLayout
          maxWidth={subheaderLeftSideMaxWidth}
          spaceBetweenChildren
          leftChild={<ProjectNameAndStatus />}
          rightChild={<SurveyCompletionProgress />}
        />
      }
      rightChild={<CompleteHeader />}
    />
  )

  const getCurrentPageHeader = () => {
    if (isResultsPage) {
      return resultsPageSubheader
    }
    if (isConfirmationPage) {
      return confirmPageSubheader
    }
    return audienceAndQuestionnairePageSubheader
  }

  const currentPageHeader = getCurrentPageHeader()

  return (
    <AudienceContextProvider>
      <QuestionnaireContextProvider>
        <SurveyLayout
          headers={currentPageHeader}
          content={contentSwitch}
          hasOneSubheader={hasOneSubheader}
          hasOneSubheaderNoFooter={isResultsPage}
          isQuestionnairePage={isQuestionnairePage}
        />
      </QuestionnaireContextProvider>
    </AudienceContextProvider>
  )
}

export default Survey
