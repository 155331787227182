import {
  Increment,
  InfoIcon,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { InfoPanel, InfoPanelItem } from '../../components/InfoPanel'
import {
  formatThousandsWithCommas,
  getMinutesSuffix
} from '../../utils/HelperFunctions'
import useStyles from './styles'

interface Props {
  priceTitle?: string
  subtitle?: string
  incidenceRate?: string
  cpi?: string
  completes?: number
  isCostPreview?: boolean
  isCompletePage?: boolean
  displayIncrement: boolean
  displayedLOI?: number
  displayedLOIRange?: string
  noOfQuestions?: number
  isDraft: boolean
  samplesCollected?: number
  onClickIncrementAdd?: () => void
  onClickIncrementSubstract?: () => void
}

const CostDropdownPanel: React.FC<Props> = (props: Props) => {
  const {
    priceTitle,
    subtitle,
    incidenceRate,
    cpi,
    completes,
    samplesCollected,
    isCostPreview,
    isCompletePage = false,
    displayIncrement,
    displayedLOI,
    displayedLOIRange,
    noOfQuestions,
    isDraft,
    onClickIncrementAdd,
    onClickIncrementSubstract
  } = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const actualCompletes = isCompletePage ? samplesCollected : completes

  return (
    <InfoPanel title={priceTitle || '...'} leftSubtitle={subtitle}>
      <div className={classes.items}>
        <InfoPanelItem>
          <p
            className={classNames(
              textClasses.sizeM,
              textClasses.weightSemiBold,
              classes.infoPanelHeader
            )}
          >
            {isCostPreview ? 'Cost preview' : 'Cost'}
          </p>
        </InfoPanelItem>
        <Divider />
        <InfoPanelItem>
          <div
            className={classNames(
              'fd-grid fd-container fd-column',
              classes.itemPanelGroup
            )}
          >
            <div
              className={classNames(
                'fd-grid fd-container fd-justify-content-between',
                classes.itemPanel
              )}
            >
              <p
                className={classNames(
                  textClasses.alignLeft,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                Length of interview:
              </p>
              <div className="fd-grid fd-container fd-xs-6 fd-row fd-justify-content-flex-end">
                <p
                  className={classNames(
                    textClasses.alignRight,
                    textClasses.noWrap,
                    textClasses.sizeMs,
                    textClasses.weightSemiBold
                  )}
                >
                  {displayIncrement ? displayedLOIRange : displayedLOI}{' '}
                  {displayedLOI &&
                    getMinutesSuffix(parseInt(displayedLOI.toString(), 10))}
                </p>
                <div
                  className={classNames('fd-grid fd-item', classes.increment)}
                >
                  {displayIncrement && (
                    <Increment
                      disableRemove={displayedLOI === 1}
                      onClickAdd={() => {
                        return onClickIncrementAdd && onClickIncrementAdd()
                      }}
                      onClickRemove={() => {
                        return (
                          onClickIncrementSubstract &&
                          onClickIncrementSubstract()
                        )
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {isDraft && displayIncrement && (
              <div
                className={classNames(
                  'fd-grid fd-container fd-justify-content-between',
                  classes.itemPanel
                )}
              >
                <p
                  className={classNames(
                    textClasses.alignLeft,
                    textClasses.noWrap,
                    textClasses.sizeMs,
                    textClasses.highlightBackground
                  )}
                >
                  No. of questions:
                </p>
                <div className="fd-grid fd-container fd-xs-auto">
                  <InfoIcon text="This is an estimate only. The final number of questions will depend on questions types used, number of response options and other factors." />
                  <p
                    className={classNames(
                      textClasses.alignRight,
                      textClasses.noWrap,
                      textClasses.sizeMs,
                      textClasses.weightSemiBold,
                      classes.incidenceRateText
                    )}
                  >
                    <span style={{ fontFamily: 'sans-serif' }}>~</span>
                    {`${noOfQuestions} questions`}
                  </p>
                </div>
              </div>
            )}
            <div
              className={classNames(
                'fd-grid fd-container fd-justify-content-between',
                classes.itemPanel
              )}
            >
              <p
                className={classNames(
                  textClasses.alignLeft,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                Incidence rate:
              </p>
              <div className="fd-grid fd-container fd-xs-auto">
                {isDraft && (
                  <InfoIcon text="Incidence rate updates every 15-20 minutes" />
                )}
                <p
                  className={classNames(
                    textClasses.alignRight,
                    textClasses.noWrap,
                    textClasses.sizeMs,
                    textClasses.weightSemiBold,
                    classes.incidenceRateText
                  )}
                >
                  {incidenceRate}%
                </p>
              </div>
            </div>
            <div
              className={classNames(
                'fd-grid fd-container fd-justify-content-between',
                classes.itemPanel
              )}
            >
              <p
                className={classNames(
                  textClasses.alignLeft,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                Cost per interview (CPI):
              </p>
              <p
                className={classNames(
                  textClasses.alignRight,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.weightSemiBold
                )}
              >
                {cpi || '...'}
              </p>
            </div>
            <div className="fd-grid fd-container fd-justify-content-between">
              <p
                className={classNames(
                  textClasses.alignLeft,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                {isCompletePage ? 'Completes purchased:' : 'Completes:'}
              </p>
              <p
                className={classNames(
                  textClasses.alignRight,
                  textClasses.noWrap,
                  textClasses.sizeMs,
                  textClasses.weightSemiBold
                )}
              >
                {actualCompletes !== undefined
                  ? formatThousandsWithCommas(actualCompletes)
                  : '...'}
              </p>
            </div>
          </div>
        </InfoPanelItem>
        <Divider />
        <InfoPanelItem>
          <div
            className={classNames(
              'fd-grid fd-container fd-justify-content-between',
              classes.infoPanelFooter
            )}
          >
            <p
              className={classNames(
                textClasses.alignLeft,
                textClasses.noWrap,
                textClasses.sizeM,
                textClasses.weightSemiBold
              )}
            >
              {isCompletePage || isDraft ? 'Total' : 'Estimated'}
            </p>
            <div className="fd-grid fd-container fd-xs-auto fd-align-items-center">
              <InfoIcon text="The final amount you pay will depend on the number of completes delivered. For example, if you stop the project early, you will only pay for people who answered the survey up until that point." />
              <p
                className={classNames(
                  textClasses.alignRight,
                  textClasses.noWrap,
                  textClasses.sizeM,
                  textClasses.weightSemiBold,
                  classes.incidenceRateText
                )}
              >
                {priceTitle}
              </p>
            </div>
          </div>
        </InfoPanelItem>
      </div>
    </InfoPanel>
  )
}

export default CostDropdownPanel
