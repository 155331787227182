import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => {
  return {
    pageWrapper: {
      padding: theme.spacing(3, 4, 5, 4)
    },
    leftContainer: {
      paddingRight: theme.spacing(8)
    },
    countryPickerContainer: {
      marginBottom: theme.spacing(4)
    },
    countryPicker: {
      padding: theme.spacing(1)
    },
    accordionContainer: {
      '& .MuiAccordionSummary-gutters': {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    presetAudiencePicker: {
      padding: theme.spacing(1)
    },
    summaryContainer: {
      padding: theme.spacing(1)
    },
    summaryDetailsContainer: {
      marginBottom: theme.spacing(2),
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.875rem',
      '& [class*="-paperItemPadding"]': {
        backgroundColor: additionalPalette.lightBlue.main
      },
      '& [class*="-paperItemPadding"] > [class*="MuiGrid-root"] p': {
        marginBottom: theme.spacing(0.875)
      },
      '& [class*="-paperItemPadding"] > [class*="MuiGrid-root"]:last-child p': {
        marginBottom: 0
      }
    },
    costContainer: {
      padding: theme.spacing(1)
    },
    labelBottomMargin: {
      marginBottom: theme.spacing(2)
    },
    displayBlockEachItem: {
      marginBottom: theme.spacing(2),
      '& > *': {
        display: 'block',
        marginBottom: theme.spacing(1)
      },
      '& > .MuiGrid-root > p, & > p': {
        marginBottom: theme.spacing(0.5)
      },
      '& .MuiFormControl-root': {
        width: theme.spacing(25)
      },
      '& .MuiInputBase-root': {
        '& fieldset': {
          display: 'none'
        }
      },
      '& input.MuiInputBase-input': {
        backgroundColor: `${additionalPalette.lightBlue.main} !important`,
        width: theme.spacing(25),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.secondary
      },
      '[class*="customStyle-outlinedStyle-weightRegular-msFont"]': {
        background: 'none !important'
      }
    },
    totalCostContainer: {
      padding: theme.spacing(1)
    },
    avatarContainer: {
      textAlign: 'center',
      '& .MuiAvatar-root': {
        width: 'auto',
        height: 'auto',
        display: 'inline-block'
      }
    },
    previewInterviewContainer: {
      paddingLeft: theme.spacing(4)
    },
    footerContainer: {
      width: '100%',
      zIndex: 1,
      position: 'sticky',
      bottom: 0,
      borderRadius: 0,
      boxShadow: '0 -1px 6px 0 rgba(0,0,0,0.1)',
      padding: '8px 32px',
      backgroundColor: theme.palette.background.default
    }
  }
})

export default useStyles
