import { useMutation } from '@apollo/client'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import {
  CreateQuestionnaireEntryV2Input,
  DraftQuestionnaireEntry,
  EntryType
} from '../../data/gql-gen/questionnaire/graphql'
import { CREATE_QUESTIONNAIRE_ENTRY_V2 } from '../../data/gql/questionnaire/mutations/createQuestionnaireEntryV2'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { scrollToQuestionCard } from '../../modules/Questionnaire/QuestionCommon/QuestionCommon.utils'
import { captureApolloError } from '../../utils/HelperFunctions'
import { newEntryId } from '../../utils/questionnaireUtils'
import { useProjectId } from '../useProjectId'
import { questionNewlyCreatedNumber } from '../useResetNewlyCreatedEntry'
import { useSurveyId } from '../useSurveyId'

export const useCreateQuestionnaireEntryV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [createQuestionnaireEntryV2Mutation] = useMutation(
    CREATE_QUESTIONNAIRE_ENTRY_V2,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'removeResponseOptionMutation',
          error
        )
      },
      onCompleted: ({ createQuestionnaireEntryV2 }) => {
        const entry = createQuestionnaireEntryV2 as DraftQuestionnaireEntry
        questionBeingEditedId(undefined)
        questionNewlyCreatedNumber(entry.number)
        newEntryId(entry.id)
        scrollToQuestionCard(
          entry.id,
          entry.entryType === EntryType.SectionEntryType
            ? EntryType.SectionEntryType
            : ''
        )
      }
    }
  )

  const createQuestionnaireEntryV2 = (
    input: Omit<CreateQuestionnaireEntryV2Input, 'projectId' | 'surveyId'>
  ) => {
    createQuestionnaireEntryV2Mutation({
      variables: {
        input: {
          projectId,
          surveyId,
          ...input
        }
      }
    })
  }

  return createQuestionnaireEntryV2
}
