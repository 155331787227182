import { useParams } from 'react-router-dom'

export const useCombinedQuestionId = () => {
  const { questionId } = useParams()

  if (!questionId) {
    throw new Error('Missing question ID required for route')
  }

  return questionId
}
