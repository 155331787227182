import {
  INVALID_RANGE_ERROR,
  MAX_CANNOT_BE_ZERO_ERROR
} from '../../modules/Questionnaire/BasicQuestion/QuestionCardFooter/constants'

export const getInvalidRangeError = (
  min: number | undefined,
  max: number | undefined,
  rangeLimit: number | undefined,
  exceedRangeLimitMessage: string | undefined
) => {
  let message = ''

  if (max && rangeLimit && max > rangeLimit)
    message = exceedRangeLimitMessage || ''
  if (min && max && min > max) message = INVALID_RANGE_ERROR
  if (max === 0) message = MAX_CANNOT_BE_ZERO_ERROR

  return message
}

export const getIsIntervalValid = (
  intervalMin: number | undefined,
  intervalMax: number | undefined
) => {
  return !!(
    intervalMin !== undefined &&
    intervalMax &&
    intervalMin <= intervalMax
  )
}

export const getIsRangeLimitValid = (
  intervalMin: number | undefined,
  intervalMax: number | undefined,
  rangeLimit: number | undefined
) => {
  return (
    !!(
      intervalMin !== undefined &&
      intervalMax &&
      rangeLimit &&
      intervalMin <= rangeLimit &&
      intervalMax <= rangeLimit
    ) || rangeLimit === undefined
  )
}
