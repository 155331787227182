// TODO: get rid of this hook to use defer from lodash instead
import { useState } from 'react'

interface Args {
  setOuterState: (...args: any[]) => void
  initialState?: any
}

// Can be used for cases when we are going to handle both component's state and Apollo cache.
// Updating cache can trigger component's parent update and its render starts before child's render.
// If some handler makes internal update and also triggers parent update, it may affect time needed for internal changes.
// This happens because of asynchronous nature of components' state so that component's state changes after parent's rendering starts.
// Example:
// const someHandler = () => {
//   setState(true)
//   triggerParentUpdate()
// }
// In this example someHandler will trigger parent render and in case it is heavy, setting state internally can take some time.
// To make update in child's component first we can use hook which allows to make internal state update first:
// const {
//   setInnerState,
//   setOuterState
// } = useChildFirstRender({
//   setOuterState: triggerParentUpdate,
//   initialState: false
// })
// const someHandler = () => {
//   setInnerState(true)
//   setOuterState()
// }

const useChildFirstRender = ({ setOuterState, initialState }: Args) => {
  const [innerState, setInnerState] = useState(initialState)
  return {
    innerState,
    setInnerState,
    setOuterState: (...args: any[]) =>
      setTimeout(() => setOuterState(...args), 0)
  }
}

export default useChildFirstRender
