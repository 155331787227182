import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  id: {
    paddingRight: theme.spacing(1.5)
  },
  questionType: {
    paddingRight: theme.spacing(1)
  },
  questionTypeText: {
    paddingRight: theme.spacing(2)
  }
})

export default useStyles
