import {
  Dialog,
  Text,
  TextHighlight,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'

interface Props {
  isOpen: boolean
  toggleOpenDialog: (open: boolean) => void
  onEndProject: () => void
}

const mainText = 'End project?'
const generalExplanation = 'This will bring the project to an end.'

const additionalExplanation =
  'You will not be able to restart the project after ending it. Please note that the final number of completes may be lower than the current number after any outstanding data quality checks have completed.'

const DialogEndProject: React.FC<Props> = (props: Props) => {
  const { isOpen, toggleOpenDialog, onEndProject }: Props = props
  return (
    <Dialog
      open={isOpen}
      onClickOutside={() => toggleOpenDialog(false)}
      onClose={() => toggleOpenDialog(false)}
      title={mainText}
      primaryButtonText={mainText}
      primaryButtonClick={onEndProject}
    >
      <Text weight={TextWeight.SemiBold} gutterBottom>
        {generalExplanation}
      </Text>
      <Text highlight={TextHighlight.Background}>{additionalExplanation}</Text>
    </Dialog>
  )
}

export default DialogEndProject
