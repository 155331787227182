import {
  Icon,
  IconColor,
  IconName,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React from 'react'

interface CustomError extends Error {
  response?: {
    status: number
  }
}

interface Props {
  error: CustomError | undefined
}
const SignupErrorText: React.FC<Props> = (props: Props) => {
  const { error } = props
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isServerError = error && error?.response?.status !== 409

  if (isServerError) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Icon name={IconName.InfoOutlinedIcon} iconColor={IconColor.Error} />
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeS,
            textClasses.highlightError
          )}
        >
          There&apos;s an issue connecting to the server. Please try again.
        </p>
      </Grid>
    )
  }

  return null
}

export default SignupErrorText
