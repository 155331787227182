import React from 'react'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { getQuestionSettings } from '../../../utils/questionnaireUtils'
import { DeleteQuestionButtonContainer } from '../DeleteQuestionButton'
import { SurveyQuestionHeaderActionsContainer } from '../SurveyQuestionHeaderActions'
import { CardSelector } from './CardSelector'
import { MatrixScreeningMenu } from './MatrixScreeningMenu'
import { QuestionTimerMenu } from './QuestionTimerMenu'
import SourceMediaButton from './SourceMediaButton'
import useStyles from './SurveyQuestionCardHeader.styles'
import useEntrySettingValues from './useEntrySettingValues'

interface Props {
  entry: DraftQuestionnaireEntry
  idContent?: JSX.Element
  logicContent?: JSX.Element
  titleContent?: JSX.Element
  disabledDuplicate?: boolean
  disableDisplayLogic?: boolean
  onClickDuplicateIcon?: () => void
  onClickAddDisplayLogic?: () => void
  onClickAddLoopSpecificDisplayLogic?: () => void
  onEmptyQuestionCard?: () => void
}

const SurveyQuestionHeaderBase: React.FC<Props> = (props: Props) => {
  const {
    entry,
    idContent,
    logicContent,
    titleContent,
    disabledDuplicate = false,
    disableDisplayLogic = false,
    onClickDuplicateIcon,
    onClickAddDisplayLogic,
    onClickAddLoopSpecificDisplayLogic,
    onEmptyQuestionCard
  }: Props = props
  const { classes, cx: classNames } = useStyles()

  const hasQuestionMedia = !!(entry.entryItem as DraftQuestionItem)
    .questionMedia

  const settingValues = useEntrySettingValues(entry.number)

  const questionSettings = getQuestionSettings(settingValues)
  const hasQuestionTimer = questionSettings.has(
    QuestionSettingCode.QuestionTimer
  )

  return (
    <div className="fd-grid fd-container">
      <div
        className={
          'fd-grid fd-container fd-item fd-row fd-align-items-center fd-xs-8'
        }
      >
        {idContent}
      </div>
      <div
        className={
          'fd-grid fd-container fd-row fd-justify-content-flex-end fd-align-items-center fd-xs-4'
        }
      >
        <SourceMediaButton entry={entry} />
        {entry.entryType === EntryType.MatrixEntryType ? (
          <MatrixScreeningMenu matrixTitleLk={entry.entryId} />
        ) : null}
        {hasQuestionTimer && (
          <div style={{ marginRight: 10, marginBottom: -2 }}>
            <QuestionTimerMenu entry={entry} />
          </div>
        )}
        <div
          className={classNames(
            'fd-grid fd-item fd-xs-auto',
            classes.cardSelectorContainer
          )}
        >
          <CardSelector entryId={entry.id} />
        </div>
        <div
          className={classNames(
            'fd-grid fd-item fd-xs-auto',
            classes.deleteButton
          )}
        >
          <DeleteQuestionButtonContainer
            entryType={entry.entryType}
            entryId={entry.entryId}
          />
        </div>
        <div className={classes.actionContainer}>
          <SurveyQuestionHeaderActionsContainer
            disableDisplayLogic={disableDisplayLogic}
            hasQuestionMedia={hasQuestionMedia}
            hasQuestionTimer={hasQuestionTimer}
            onClickDuplicateQuestion={onClickDuplicateIcon}
            onClickAddDisplayLogic={onClickAddDisplayLogic}
            onClickAddLoopSpecificDisplayLogic={
              onClickAddLoopSpecificDisplayLogic
            }
            onEmptyQuestionCard={onEmptyQuestionCard}
            disabledDuplicate={disabledDuplicate}
            entry={entry}
          />
        </div>
      </div>
      {logicContent}
      {titleContent}
    </div>
  )
}

export default SurveyQuestionHeaderBase
