import {
  Button,
  ButtonSize,
  ButtonVariant,
  Dialog,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'

interface Props {
  isDeleteDialogOpened: boolean
  onClickDelete: () => void
  onConfirmDelete: () => void
  onCloseDeleteDialog: () => void
}

export const QuestionsBulkDeleteButtonControl = ({
  isDeleteDialogOpened,
  onClickDelete,
  onConfirmDelete,
  onCloseDeleteDialog
}: Props): JSX.Element => {
  return (
    <>
      <Button
        variant={ButtonVariant.PrimaryNoOutline}
        onClick={onClickDelete}
        buttonSize={ButtonSize.Small}
      >
        <Text
          highlight={TextHighlight.Standard}
          size={TextSize.ms}
          weight={TextWeight.SemiBold}
        >
          Delete
        </Text>
      </Button>
      <Dialog
        title="Delete selected cards?"
        open={isDeleteDialogOpened}
        onClose={onCloseDeleteDialog}
        primaryButtonText="Delete cards"
        primaryButtonClick={onConfirmDelete}
      >
        <Text>
          Please confirm you want to delete the selected cards. This will
          permanently remove them and any routing.
        </Text>
      </Dialog>
    </>
  )
}
