import gql from 'graphql-tag'

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const CREATE_CUSTOM_CRITERION_OPTION_V2 = gql`
  mutation createCustomCriterionOptionV2(
    $input: CreateCustomCriterionOptionV2Input!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    createCustomCriterionOptionV2(input: $input) {
      ...DraftCustomCriterionOption
    }
  }
`

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const UPDATE_CUSTOM_CRITERION_OPTION_V2 = gql`
  mutation updateCustomCriterionOptionV2(
    $input: UpdateCustomCriterionOptionV2Input!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateCustomCriterionOptionV2(input: $input) {
      ...DraftCustomCriterionOption
    }
  }
`

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const REMOVE_CRITERION_OPTION_V2 = gql`
  mutation removeCriterionOptionV2($input: RemoveCriterionOptionV2Input!) {
    recordAudienceChangedForIRSNotification @client(always: true)
    removeCriterionOptionV2(input: $input)
  }
`

// NOTE: be careful when replacing `gql` with `questionnaireGql` since this can break `recordAudienceChangedForIRSNotification` work. Double check if the `recordAudienceChangedForIRSNotification` is still working after the replacement.
export const CREATE_STANDARD_CRITERION_OPTION_V2 = gql`
  mutation createStandardCriterionOptionV2(
    $input: CreateStandardCriterionOptionV2Input!
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    createStandardCriterionOptionV2(input: $input) {
      position
      state
      metadata
      weight {
        percent
        createdDate
      }
      quota {
        percent
        createdDate
        sinceDate
      }
      qualification {
        createdDate
        sinceDate
      }
      criterionOption {
        code
        createdDate
        sinceDate
      }
    }
  }
`
