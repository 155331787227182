import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  divider: {
    borderLeft: `1px solid ${additionalPalette.lightGrey.main}`,
    height: theme.spacing(2.5),
    margin: theme.spacing(0, 2, 0, 2)
  },
  media: {
    marginLeft: 'auto'
  }
})

export default useStyles
