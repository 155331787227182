import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  correctPercentage: {
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: theme.spacing(0.25),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  wrongPercentage: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(0.25),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  totalPercentageGrid: {
    padding: theme.spacing(1, 0)
  },
  paddingLeft: {
    paddingLeft: theme.spacing(3)
  },
  percentageText: {
    marginRight: `-${theme.spacing(1.75)}`
  },
  errorIcon: {
    marginRight: `-${theme.spacing(2.5)}`
  }
}))

export default useStyles
