import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  paper: {
    backgroundColor: theme.palette.error.light,
    borderLeft: `${theme.spacing(0.25)} solid ${theme.palette.error.main}`,
    borderRadius: 0,
    boxShadow: 'none',
    elevation: 0,
    padding: theme.spacing(1.5, 0.5, 1.5, 1),
    position: 'absolute',
    top: theme.spacing(8),
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: theme.spacing(62.5)
  }
}))

export default useStyles
