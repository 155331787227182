import { Theme, makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme: Theme) => ({
  border: {
    borderRight: '1px solid',
    height: 10
  },
  loader: {
    height: '100%',
    padding: theme.spacing(0, 2.5)
  },
  textHoverClass: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  actionItemDisabled: {
    cursor: 'default',
    '&:hover': {
      cursor: 'default'
    }
  },
  actionItem: {
    cursor: 'pointer'
  },
  liveActionBottomItem: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer'
  },
  pauseItem: {
    padding: theme.spacing(2, 2, 0, 2)
  },
  endSurveyItem: {
    padding: theme.spacing(2, 2, 2, 2)
  },
  pauseIcon: {
    paddingLeft: theme.spacing(3)
  },
  liveActions: {
    marginRight: `-${theme.spacing(3)}`,
    height: '48px'
  },
  errorText: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(0.75),
    borderRadius: theme.spacing(0.25)
  },
  rightChild: {
    paddingLeft: theme.spacing(2.25),
    height: '100%'
  },
  feasibilityInfoPanel: {
    padding: theme.spacing(2),
    width: 400
  },
  paddingTop: {
    paddingTop: theme.spacing(2)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2)
  },
  inlineSemiBold: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  language: {
    paddingRight: theme.spacing(2.5)
  }
}))

export default useStyles
