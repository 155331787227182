import React from 'react'
import { useAppSelector } from '../../../../App.store'
import {
  DraftMaskingRule,
  ForkTag,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import { useDraftMatrixRow } from '../../../../hooks/questionnaire/useDraftMatrixRow'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { selectResponseOptionsByQuestion } from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import { getMaskingRulesFromForks } from '../MaskingRuleSection/MaskingRuleSection.utils'
import MaskingDialogControl from './MaskingDialog.control'
import { Question } from './MaskingDialog.model'
import { getResponseOptionValue } from './MaskingDialog.utils'

interface Props {
  entryType: EntryType | undefined
  entryItemLk: string | undefined
  maskedItemLk: string | undefined
  questionPosition: number
  maskingRules: DraftMaskingRule[]
  allowForks?: boolean
  onCloseDialog: () => void
  onSave: (
    newRules: MaskingRuleInput[],
    displayLogicForks: ForkTag[]
  ) => Promise<void>
}

const MaskingDialog: React.FC<Props> = ({
  entryType,
  entryItemLk,
  maskedItemLk,
  questionPosition,
  maskingRules,
  allowForks,
  onCloseDialog,
  onSave
}: Props) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const matrixRow = useDraftMatrixRow(maskedItemLk)

  let responseOptionText

  if (entryType === EntryType.MatrixEntryType) {
    responseOptionText = matrixRow?.question?.text
  } else {
    responseOptionText = getResponseOptionValue({
      entryType,
      entryItemLk,
      maskedItemLk,
      responseOptionsByQuestion
    })
  }

  const rulesFromForks = getMaskingRulesFromForks(matrixRow?.forks)

  const questionEntries = flatDraftQuestionnaireEntries as Question[]

  return (
    <MaskingDialogControl
      responseOptionText={responseOptionText}
      questionPosition={questionPosition}
      onSave={onSave}
      onCloseDialog={onCloseDialog}
      maskingRules={maskingRules}
      rulesFromForks={rulesFromForks}
      questions={questionEntries}
      allowForks={allowForks}
    />
  )
}

export default MaskingDialog
