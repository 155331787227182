import { makeStyles, theme } from '@focaldata/cin-ui-components'

const combinedHeaderHeightOneSubheader = 49

const useStyles = makeStyles()({
  root: {
    height: `calc(100vh - ${combinedHeaderHeightOneSubheader}px)`
  },
  centerContainer: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(4, 4, 4, 4)
  },
  optionsBar: {
    padding: theme.spacing(0, 0, 2, 0)
  },
  resultsCard: {},
  filtersContainer: {
    height: '100%',
    overflowY: 'auto',
    boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(
      0.75
    )} 0 rgba(0,0,0,0.1)`
  }
})

export default useStyles
