import { Button, ButtonVariant, Grid } from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import ListItemQuota from '../../../components/ListItemQuota'
import QuotaEditorFooter from '../../../controls/QuotaEditorFooter'
import { FieldworkAudienceCriterionOption } from '../../../data/model/audience'
import { StandardOptionValidationError } from '../../../data/model/fieldwork'
import {
  useAddCustomAgeRange,
  useCustomAgeRangesValidation,
  useDeleteCustomAgeRange,
  useUpdateCustomizedCriterionOption
} from '../../../hooks/questionnaire/CustomAgeRanges.hooks'
import {
  numberToFixedString,
  percentToProportion,
  proportionToPercent
} from '../../../utils/HelperFunctions'
import { useSurveyLengthData } from '../AudienceQuestionCard/AudienceQuestionCard.hooks'
import { AgeRangeInput } from './AgeRangeInput'
import { CustomAgeRangesError } from './CustomAgeRangesError'
import { CustomAgeRangesHeader } from './CustomAgeRangesHeader'

interface Props {
  questionId: string
  customizedCriterionOptions: FieldworkAudienceCriterionOption[]
  quotasEnabled: boolean
}

export const CustomAgeRanges = ({
  questionId,
  customizedCriterionOptions,
  quotasEnabled
}: Props) => {
  const addCustomAgeRange = useAddCustomAgeRange(questionId)
  const deleteCustomAgeRange = useDeleteCustomAgeRange(questionId)
  const updateCustomizedCriterionOption =
    useUpdateCustomizedCriterionOption(questionId)
  const { totalCompletes } = useSurveyLengthData()
  const { errorsByOptionCode, errorMessage } = useCustomAgeRangesValidation()

  const hasCriterionOptions = customizedCriterionOptions.length > 0
  const hasQualifiedOptions = customizedCriterionOptions.some(
    (co) => co.qualification
  )

  return (
    <Grid width="100%">
      <CustomAgeRangesError errorMessage={errorMessage} />
      <CustomAgeRangesHeader
        quotasEnabled={quotasEnabled}
        hasCriterionOptions={hasCriterionOptions}
      />
      {customizedCriterionOptions.map(
        ({ qualification, quota, criterionOption }) => {
          const { responseOption, code } = criterionOption
          const { responseOptionId } = responseOption
          const [min, max] = code.split('_-_')
          return (
            <ListItemQuota
              readonly={!qualification}
              key={responseOptionId}
              disableGutters
              totalCompletes={totalCompletes}
              hasQualification
              isQualified={!!qualification}
              hasQuotas={quotasEnabled}
              // @todo Legacy eslint violation – fix this when editing
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              hasQuotaError={errorsByOptionCode[code]?.errorCodes.includes(
                StandardOptionValidationError.QuotaIsZeroError
              )}
              onQuotaDelete={() => deleteCustomAgeRange(responseOptionId)}
              quotaNameComponent={
                <AgeRangeInput
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  hasError={errorsByOptionCode[code]?.hasError}
                  min={min}
                  max={max}
                  onChange={debounce(({ min, max }) => {
                    updateCustomizedCriterionOption(responseOptionId, {
                      min,
                      max,
                      qualified: !!qualification,
                      quota: quota?.percent
                    })
                  }, 500)}
                />
              }
              defaultQuotaValue={
                quota
                  ? numberToFixedString(proportionToPercent(quota.percent))
                  : '0'
              }
              onQualificationChange={(value) =>
                updateCustomizedCriterionOption(responseOptionId, {
                  min,
                  max,
                  qualified: value,
                  quota: quota?.percent
                })
              }
              onQuotaBlur={(value) => {
                updateCustomizedCriterionOption(responseOptionId, {
                  min,
                  max,
                  qualified: !!qualification,
                  quota: percentToProportion(Number.parseFloat(value))
                })
              }}
            />
          )
        }
      )}
      <QuotaEditorFooter
        button={
          <Button
            variant={ButtonVariant.SecondaryOutlined}
            onClick={addCustomAgeRange}
          >
            Add age range
          </Button>
        }
        totalPercentage={
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          customizedCriterionOptions.reduce((sum, { quota, qualification }) => {
            return sum + (qualification && quota ? quota.percent : 0)
          }, 0) ?? 0
        }
        showTotal={quotasEnabled && hasQualifiedOptions}
        isCustomAudience
      />
    </Grid>
  )
}
