import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(7)
  },
  spinner: {
    padding: theme.spacing(1)
  },
  text: {
    padding: theme.spacing(2, 0, 3, 0)
  },
  containerBackground: {
    backgroundColor: additionalPalette.darkGrey.main
  }
}))

export default useStyles
