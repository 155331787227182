import { useMutation } from '@apollo/client'
import {
  CREATE_FIELDWORKV2,
  UPDATE_FIELDWORKV2
} from '../../data/gql/questionnaire/mutations/createOrUpdateFieldworkV2'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'

export const useCreateFieldworkV2 = () => {
  const [createFieldworkV2, { loading: updateFieldworkLoading }] = useMutation(
    CREATE_FIELDWORKV2,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'createFieldworkV2',
          error
        )
      }
    }
  )
  return { createFieldworkV2, updateFieldworkLoading }
}

export const useUpdateFieldworkV2 = () => {
  const [updateFieldworkV2, { loading: updateFieldworkLoading }] = useMutation(
    UPDATE_FIELDWORKV2,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateFieldworkV2',
          error
        )
      }
    }
  )
  return { updateFieldworkV2, updateFieldworkLoading }
}
