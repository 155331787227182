import {
  IconButton,
  IconColor,
  IconName,
  IconSize
} from '@focaldata/cin-ui-components'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { SurveyParams } from '../../../data/model/surveyParams'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import QuestionnaireContext, {
  openPreviewPanel
} from '../Questionnaire.context'
import useStyles from './PreviewButtonAbsolute.styles'

const PreviewButtonAbsolute: React.FC = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const entryCount = draftQuestionnaire?.entries.length ?? 0
  const { classes, cx: classNames } = useStyles()
  if (entryCount === 0) {
    return null
  }
  return (
    <div
      className={classNames(
        'fd-grid fd-container fd-justify-content-flex-end',
        classes.root
      )}
    >
      {!questionnaireState.isPreviewPanelOpened && (
        <div
          className={classNames('fd-grid fd-item fd-xs-auto', classes.button)}
        >
          <IconButton
            onClick={() => {
              LogAmplitudeEvent(EventType.OpenedSurveyPreviewPane, { surveyId })
              dispatch(openPreviewPanel())
            }}
            iconName={IconName.PlayCircleOutline}
            iconSize={IconSize.Large}
            iconColor={IconColor.Emphasis}
          />
        </div>
      )}
    </div>
  )
}

export default PreviewButtonAbsolute
