import { useQuery } from '@apollo/client'
import { FIELDWORK_AUDIENCE_V2 } from '../../data/gql/questionnaire/queries/fieldworkAudience'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useSurveyId } from '../useSurveyId'

export const useFieldworkAudience = () => {
  const surveyId = useSurveyId()
  const { data, loading, error, refetch } = useQuery(FIELDWORK_AUDIENCE_V2, {
    context: { clientName: 'questionnaire' },
    variables: { surveyId },
    skip: !surveyId,
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'fieldworkAudienceV2',
        error
      )
    }
  })

  return {
    fieldworkAudience: data?.fieldworkAudienceV2,
    loading,
    error,
    refetch
  }
}
