import {
  ButtonType,
  LinkButton,
  Logo,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import styles from '../Auth.module.css'
import useStyles from '../Auth.styles'

interface Props {
  onSendEmail: (email: string) => Promise<boolean | Error>
  onGoToRegister?: () => void
}

const ForgotPassowrd: React.FC<Props> = (props: Props) => {
  const { onSendEmail, onGoToRegister } = props
  const [loading, onLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const handleSubmitEmail: (event: React.SyntheticEvent) => void = async (
    event
  ) => {
    event.preventDefault()

    onLoading(true)
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const sendEmailResponse = await onSendEmail(email?.toLowerCase())

    if (sendEmailResponse) {
      onLoading(false)
    }
    if (sendEmailResponse instanceof Error) {
      onLoading(false)
    }
  }

  const isDisabled = email.length === 0 || loading

  return (
    <Grid className={styles.page} container justifyContent="center">
      <Grid className={classes.maxWidth}>
        <form onSubmit={handleSubmitEmail}>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Logo width={42} type="icon" />
            <h1 className={styles.title}>Reset your password</h1>
            <p className={styles.subtitle}>
              Enter your email below and we&apos;ll send you reset instructions
            </p>
            <Grid item container className={classes.gridItem}>
              <p className={styles['input-label']}>Email</p>
              <input
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                className={styles.input}
                placeholder="Enter your email"
              />
            </Grid>
            <Grid item container className={classes.gridItem}>
              <SpinnerButton
                onClick={handleSubmitEmail}
                loading={loading}
                className={classes.button}
                fullWidth
                buttonType={ButtonType.Submit}
                disabled={isDisabled}
              >
                Send reset email
              </SpinnerButton>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.helperText}
            >
              <Grid
                container
                justifyContent="center"
                className={classes.helperText}
              >
                <p className={classNames(textClasses.default)}>
                  Don&apos;t have an account?
                </p>
                <LinkButton
                  size={TextSize.m}
                  weight={TextWeight.Bold}
                  onClick={onGoToRegister}
                  noPadding
                  underlined
                >
                  Sign up
                </LinkButton>
              </Grid>
              <Grid className={classes.paddingRight}>
                <p className={classNames(textClasses.default)}>
                  Need help? Contact
                </p>
              </Grid>
              <LinkButton
                size={TextSize.m}
                weight={TextWeight.Bold}
                noPadding
                underlined
              >
                support@focaldata.com
              </LinkButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default ForgotPassowrd
