import { makeVar } from '@apollo/client'
import { useEffect, useState } from 'react'

export const questionNewlyCreatedNumber = makeVar<number | undefined>(undefined)
export const responseOptionLkNewlyAdded = makeVar<string | undefined>(undefined)

const useResetNewlycreatedEntry = (visibleEntryIndex: number) => {
  const [entryIndex, setEntryIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    const updateEntryIndex: () => void = () => {
      if (visibleEntryIndex !== entryIndex) {
        setEntryIndex(visibleEntryIndex)
        questionNewlyCreatedNumber(undefined)
        responseOptionLkNewlyAdded(undefined)
      }
    }

    updateEntryIndex()
  }, [entryIndex, visibleEntryIndex])

  return null
}

export default useResetNewlycreatedEntry
