export function popFirst<T>(
  collection: T[],
  predicate: (value: T) => boolean
): [T | undefined, T[]] {
  const match = collection.findIndex(predicate)
  if (match !== -1) {
    return [
      collection[match],
      [...collection.slice(0, match), ...collection.slice(match + 1)]
    ]
  }
  return [undefined, collection]
}
