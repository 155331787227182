import { Grid, Paper, Skeleton } from '@focaldata/cin-ui-components'
import ListItem from '@mui/material/ListItem'
import React from 'react'
import useStyles from './ProjectListItem.styles'

const ListItemProjectLoading: React.FC = () => {
  const { classes } = useStyles()

  return (
    <ListItem className={classes.listItemStyle} component="li">
      <Paper className={classes.cardStyle} elevation={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardContentStyle}
        >
          <Grid container direction="column" item xs={10}>
            <Skeleton height={32} width={160} />
            <Grid
              container
              item
              direction="row"
              xs={12}
              className={classes.projectDetails}
            >
              <Grid container item direction="row" xs={3}>
                <Skeleton height={24} width={46} />
              </Grid>
              <Grid container item direction="row" xs={3}>
                <Skeleton height={24} width={46} />
              </Grid>
              <Grid container item direction="row" xs={3}>
                <Skeleton height={24} width={46} />
              </Grid>
              <Grid container item direction="row" xs={3}>
                <Skeleton height={24} width={46} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto" />
        </Grid>
      </Paper>
    </ListItem>
  )
}

export default ListItemProjectLoading
