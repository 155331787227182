import {
  Checkbox,
  CheckboxGroup,
  Grid,
  Paper,
  PaperGroupItem,
  ScrollElement
} from '@focaldata/cin-ui-components'
import React from 'react'
import QuotaEditor from '../../../controls/QuotaEditor'
import {
  AudienceCriterion,
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceCriterionOption,
  MemberSettingCode
} from '../../../data/model/audience'
import { StandardCriterionValidationErrors } from '../../../data/model/fieldwork'
import { SettingValue } from '../../../data/model/questionnaire'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import { AudienceQuestionCardFooter } from '../AudienceQuestionCardFooter'
import { AudienceQuestionCardHeader } from '../AudienceQuestionCardHeader'
import { AGE_RANGES_CRITERION_CODE, CustomAgeRanges } from '../CustomAgeRanges'
import { SCROLL_ID_PREFIX } from '../constants'
import {
  getIsEnabledInSettings,
  getResponseOptionValidationErrorText,
  getStandardResponseOptionQualificationInvalid,
  getTotalQuotaSum,
  isQuotaSumValid
} from '../utils'
import { AgeRangeSwitch, AgeRangeType } from './AgeRangeSwitch'
import useStyles from './AudienceQuestionCard.styles'

export interface Props {
  audience: FieldworkAudience
  totalCompletes: number
  validationErrors?: StandardCriterionValidationErrors
  onDelete?: () => void
  onEnteringCard?: () => void
  onToggleQuotasState?: () => void
  onOptionChanged?: (option: FieldworkAudienceCriterionOption) => void
  onChangeAll?: (selected: boolean) => void
  onOptionQuotaChanged?: (
    option: FieldworkAudienceCriterionOption,
    newValue: number
  ) => void
  onSumQuotas: () => Promise<void>
  onToggleAddedToSurveyState: () => void
  onAgeRangeTypeChange?: (value: AgeRangeType) => void
}

const AudienceQuestionCard: React.FC<Props> = (props: Props) => {
  const {
    audience,
    totalCompletes,
    validationErrors,
    onEnteringCard,
    onOptionChanged,
    onChangeAll,
    onDelete,
    onToggleQuotasState,
    onOptionQuotaChanged,
    onSumQuotas,
    onToggleAddedToSurveyState,
    onAgeRangeTypeChange
  }: Props = props
  const standardAudience = audience.memberItem as DraftStandardAudience
  const {
    standardCriterionOptions,
    customizedCriterionOptions = [],
    criterion,
    settingValues
  } = standardAudience
  const { name, code, question }: AudienceCriterion = criterion

  const { classes } = useStyles()

  const customizedCriterionOptionsEnabled: boolean = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.CustomizedCriterionOptions
  )

  const isAddedToSurvey = !getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.HideSurveyQuestion
  )

  const isStandardAudienceSingleChoice = settingValues.some(
    (settingValue) => settingValue.value === SettingValue.SingleChoice
  )

  const hasQuotas = (
    customizedCriterionOptionsEnabled
      ? customizedCriterionOptions
      : standardCriterionOptions
  ).some((option) => !!option.quota)

  const quotasEnabled: boolean = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.Quotas
  )

  const totalQuotas = quotasEnabled
    ? getTotalQuotaSum(
        customizedCriterionOptionsEnabled
          ? customizedCriterionOptions
          : standardCriterionOptions
      )
    : 1

  const { isAudienceInvalid } = useAudienceValidation({
    validationErrors: validationErrors ? [validationErrors] : undefined
  })

  const handleOptionChanged: (
    option: FieldworkAudienceCriterionOption
  ) => void = (option) => {
    if (!onOptionChanged) return
    onOptionChanged(option)
  }

  const isAgeRangeQuestion = code === AGE_RANGES_CRITERION_CODE

  const showQuotaSumSpread =
    hasQuotas && quotasEnabled && !isQuotaSumValid(totalQuotas)

  return (
    <ScrollElement elementId={`${SCROLL_ID_PREFIX}${criterion.code}`}>
      <Grid
        item
        className={classes.audienceCard}
        id={`targetingCard-${code}`}
        key={code}
        onFocus={onEnteringCard}
      >
        <Paper>
          <PaperGroupItem>
            <AudienceQuestionCardHeader
              defaultTitle={question.text}
              hasError={isAudienceInvalid}
              quotaName={name}
              isTitleEditable={false}
              onClickLeftIcon={onDelete}
              isStandardAudienceSingleChoice={isStandardAudienceSingleChoice}
            />
          </PaperGroupItem>
          <PaperGroupItem>
            {isAgeRangeQuestion && (
              <div className={classes.ageRangeSwitch}>
                <AgeRangeSwitch
                  customizedCriterionOptionsEnabled={
                    customizedCriterionOptionsEnabled
                  }
                  onAgeRangeTypeChange={onAgeRangeTypeChange}
                />
              </div>
            )}
            {!customizedCriterionOptionsEnabled && (
              <CheckboxGroup
                onChangeAll={onChangeAll}
                id={`targetingCardCriteria-${code}`}
              >
                {standardCriterionOptions.map(
                  (option: FieldworkAudienceCriterionOption) => {
                    const optionErrors =
                      getStandardResponseOptionQualificationInvalid(
                        option.criterionOption.code,
                        validationErrors
                      )
                    return (
                      <Checkbox
                        isGrouped
                        hasError={!!optionErrors}
                        errorText={getResponseOptionValidationErrorText(
                          optionErrors?.errors
                        )}
                        key={option.criterionOption.code}
                        onChange={() => handleOptionChanged(option)}
                        checked={!!option.qualification}
                        // @todo Legacy eslint violation – fix this when editing
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        label={option.criterionOption.responseOption?.value}
                      />
                    )
                  }
                )}
              </CheckboxGroup>
            )}
          </PaperGroupItem>
          {isStandardAudienceSingleChoice && (
            <Grid>
              <PaperGroupItem>
                {customizedCriterionOptionsEnabled && (
                  <CustomAgeRanges
                    questionId={question.questionId}
                    quotasEnabled={quotasEnabled}
                    customizedCriterionOptions={customizedCriterionOptions}
                  />
                )}
                {quotasEnabled &&
                  hasQuotas &&
                  !customizedCriterionOptionsEnabled && (
                    <QuotaEditor
                      options={standardCriterionOptions}
                      totalCompletes={totalCompletes}
                      onQuotaChange={onOptionQuotaChanged}
                    />
                  )}
              </PaperGroupItem>
            </Grid>
          )}
          <PaperGroupItem>
            <AudienceQuestionCardFooter
              defaultQuotasState={quotasEnabled}
              isTargeting
              isSingleChoice={isStandardAudienceSingleChoice}
              defaultAddedToSurveyState={isAddedToSurvey}
              showQuotaSumSpread={showQuotaSumSpread}
              onToggleQuotasState={onToggleQuotasState}
              onToggleAddedToSurveyState={onToggleAddedToSurveyState}
              onSumQuotas={onSumQuotas}
            />
          </PaperGroupItem>
        </Paper>
      </Grid>
    </ScrollElement>
  )
}

export default AudienceQuestionCard
