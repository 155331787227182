import {
  IconButton,
  IconColor,
  IconName,
  IconSize,
  MuiMenu,
  MuiMenuItem,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useContext, useState } from 'react'
import {
  DraftMaskingRule,
  ForkTag,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionKind
} from '../../../../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../../Questionnaire.slice'
import { MaskingDialog } from '../MaskingDialog'
import {
  MaskingDialogContext,
  MaskingDialogContextData
} from './MaskingButton.context'
import { getEntryPosition, getMaskingSummary } from './MaskingButton.utils'

// TODO: create a context for questionLk and responseOptionLk so there's no prop drilling
interface Props {
  entryType?: EntryType
  entries: DraftQuestionnaireEntry[]
  entryItemLk: string | undefined
  maskedItemLk: string | undefined
  maskingRules: DraftMaskingRule[]
  forks?: ForkTag[]
  allowForks?: boolean
  responseOptionsByQuestion: ResponseOptionsByQuestion
  isResponseOption?: boolean
  onSave: (
    newRules: MaskingRuleInput[],
    displayLogicForks: ForkTag[]
  ) => Promise<void>
}

const MaskingButton: React.FC<Props> = ({
  entryType,
  entries,
  entryItemLk,
  maskedItemLk,
  maskingRules,
  forks,
  allowForks,
  responseOptionsByQuestion,
  isResponseOption = false,
  onSave
}: Props) => {
  const hasMaskingRules = maskingRules.length > 0 || (forks && forks.length > 0)
  const { isDialogOpen, setIsDialogOpen } =
    useContext<MaskingDialogContextData>(MaskingDialogContext)
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const handleOpenMenuClick: (event: React.MouseEvent<HTMLElement>) => void = (
    event
  ) => {
    if (hasMaskingRules) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    } else {
      setIsDialogOpen(true)
    }

    return false
  }

  const handleCloseMenu: () => void = () => {
    setAnchorEl(null)
    return false
  }

  const handleOpenDialogClick: () => void = () => {
    setIsDialogOpen(true)
    setAnchorEl(null)
  }

  const handleCloseDialog: () => void = () => {
    setIsDialogOpen(false)
  }

  const handleSave: (
    newRules: MaskingRuleInput[],
    displayLogicForks: ForkTag[]
  ) => Promise<void> = async (newRules, displayLogicForks) => {
    await onSave(newRules, displayLogicForks)
    setIsDialogOpen(false)
  }

  const handleRemoveLogic: () => void = () => {
    onSave([], [])
    setIsDialogOpen(false)
  }

  const handleTooltipOpen: () => void = () => {
    setIsTooltipOpen(true)
  }

  const handleTooltipClose: () => void = () => {
    setIsTooltipOpen(false)
  }

  const questionPosition = getEntryPosition(entryType, entries, entryItemLk)

  // should only be disabled if it's the first question and there are no audience questions
  const isDisabled =
    questionPosition === 0 &&
    !entries.some(
      (entry: DraftQuestionnaireEntry) =>
        entry.questionKind === QuestionKind.AudienceKind
    )

  const tooltipTitle = isDisabled ? (
    <>Display logic cannot be added to the first survey question</>
  ) : (
    <>
      {isTooltipOpen &&
        getMaskingSummary(
          maskingRules,
          entries,
          isResponseOption,
          responseOptionsByQuestion,
          forks
        )}
    </>
  )
  const displayMaskingButton =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (maskingRules && maskingRules.length > 0) || (forks && forks.length > 0)

  return (
    <>
      {displayMaskingButton && (
        <>
          <Tooltip
            title={tooltipTitle}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
            open={isTooltipOpen}
            tipPlacement={TipPlacement.Bottom}
          >
            <IconButton
              smallHoverShadowSize
              disabled={isDisabled}
              iconSize={IconSize.Large}
              iconName={IconName.Visibility}
              iconColor={hasMaskingRules ? IconColor.Green : undefined}
              onClick={handleOpenMenuClick}
            />
          </Tooltip>
          <MuiMenu
            elevation={1}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MuiMenuItem onClick={handleOpenDialogClick}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                Edit display logic
              </p>
            </MuiMenuItem>
            <MuiMenuItem
              onClick={handleRemoveLogic}
              disabled={!hasMaskingRules}
            >
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.highlightBackground
                )}
              >
                Remove display logic
              </p>
            </MuiMenuItem>
          </MuiMenu>
        </>
      )}
      {isDialogOpen && (
        <MaskingDialog
          entryType={entryType}
          onSave={handleSave}
          onCloseDialog={handleCloseDialog}
          entryItemLk={entryItemLk}
          maskedItemLk={maskedItemLk}
          questionPosition={questionPosition}
          maskingRules={maskingRules}
          allowForks={allowForks}
        />
      )}
    </>
  )
}

export default MaskingButton
