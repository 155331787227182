import { Badge, Status } from '@focaldata/cin-ui-components'
import Skeleton from '@mui/material/Skeleton'
import ArrowUpRightIcon from '../../../../assets/fdchat/results/arrow-up-right.svg'
import { useInterviewLink } from '../../../../containers/Page/FdChat/FdChat.hooks'
import { SurveyState } from '../../../../data/gql-gen/dashboard/graphql'
import useGetSurveyState from '../../../../hooks/useGetSurveyState'
import { statusMap, stringStatusMap } from '../../../../utils/projectUtils'
import { useTimelineSteps } from '../Overview.hooks'
import styles from './StatusCard.module.css'

const StatusCard = () => {
  const { surveyState, loading: loadingSurveyState } = useGetSurveyState()
  const state = surveyState ?? SurveyState.Draft
  const badgeStatus = statusMap.get(state) ?? Status.DRAFT
  const badgeLabel = stringStatusMap.get(badgeStatus) ?? 'Unknown'
  const previewInterviewLink = useInterviewLink({ preview: true })
  const { inProgressStep, loading: loadingTimelineSteps } = useTimelineSteps()

  const shouldShowProgressStepTitle = ![
    SurveyState.Paused,
    SurveyState.Scheduled,
    SurveyState.Halted
  ].includes(state)

  return (
    <div className={styles['status-card']}>
      {loadingSurveyState ? (
        <Skeleton variant="rounded" width={60} height={30} />
      ) : (
        <Badge status={badgeStatus} label={badgeLabel} />
      )}
      <p className={styles.label}>
        {loadingTimelineSteps ? (
          <Skeleton variant="text" width={200} height={30} />
        ) : shouldShowProgressStepTitle ? (
          inProgressStep?.title
        ) : null}
      </p>
      <a
        href={previewInterviewLink}
        target="_blank"
        rel="noreferrer"
        className={styles['preview-interview-link']}
      >
        <span>Preview interview</span>
        <img src={ArrowUpRightIcon} alt="" />
      </a>
    </div>
  )
}

export default StatusCard
