import {
  Grid,
  Icon,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import { ImportQuestionProject } from './ImportQuestionsSurveyPicker.query'
import useStyles from './ImportQuestionsSurveyPicker.styles'

interface Props {
  projects: ImportQuestionProject[]
  onProjectSelected: (project: ImportQuestionProject) => void
}

const ImportQuestionsSurveyPickerControl: React.FC<Props> = ({
  projects,
  onProjectSelected
}: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid container>
      {projects.map((project) => (
        <Grid
          key={project.surveys[0].surveyId}
          container
          direction="row"
          justifyContent="space-between"
          className={classes.project}
          onClick={() => onProjectSelected(project)}
        >
          <Grid item sm={11}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.highlightBackground,
                classes.projectText
              )}
            >
              {project.name}
            </p>
          </Grid>
          <Grid item sm={1} alignItems="flex-end">
            <Icon name={IconName.ChevronRight} size={IconSize.xLarge} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImportQuestionsSurveyPickerControl
