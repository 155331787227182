import inRange from 'lodash/inRange'

export const calculateCost: (
  customerCPI: number,
  completes: number
) => number = (customerCPI, completes) => {
  const roundedCustomerCPI = parseFloat(customerCPI.toFixed(2))
  const cost = roundedCustomerCPI * completes
  return Math.round(cost)
}

export const DEFAULT_LOI = 2
export const estimatorRanges = [
  { range: { start: 0, end: 2 }, questions: 6 },
  { range: { start: 3, end: 5 }, questions: 15 },
  { range: { start: 6, end: 8 }, questions: 24 },
  { range: { start: 9, end: 12 }, questions: 36 },
  { range: { start: 13, end: 15 }, questions: 45 },
  { range: { start: 16, end: 20 }, questions: 60 },
  { range: { start: 21, end: 25 }, questions: 75 },
  { range: { start: 26, end: 30 }, questions: 90 },
  { range: { start: 31, end: 35 }, questions: 115 },
  { range: { start: 36, end: 40 }, questions: 120 },
  { range: { start: 41, end: 45 }, questions: 135 },
  { range: { start: 46, end: 50 }, questions: 150 },
  { range: { start: 51, end: 55 }, questions: 165 },
  { range: { start: 56, end: 60 }, questions: 180 }
]

export const getQuestionsNumber = (displayedLOIValue: number) => {
  const estimatorIndex = estimatorRanges.findIndex(
    (r) =>
      displayedLOIValue >= r.range.start && displayedLOIValue <= r.range.end
  )
  const noOfQuestions = inRange(estimatorIndex, 0, estimatorRanges.length)
    ? estimatorRanges[estimatorIndex].questions
    : estimatorRanges[estimatorRanges.length - 1].questions
  return noOfQuestions
}
