import { Paper, theme } from '@focaldata/cin-ui-components'
import { ReactNode } from 'react'

const InfoCard = ({
  children,
  component = 'div',
  handleClick
}: {
  children: ReactNode
  component?: 'div' | 'button'
  handleClick?: () => void
}) => (
  <Paper
    variant="outlined"
    sx={{
      height: '100%',
      backgroundColor: 'white',
      padding: '32px',
      boxSizing: 'border-box',
      borderRadius: 0,
      ...(component === 'button' && {
        ':hover': {
          cursor: 'pointer',
          boxShadow: `${theme.spacing(0.25)} ${theme.spacing(
            0.25
          )} ${theme.spacing(1)} ${theme.spacing(0.25)} rgba(0, 0, 0, 0.1)`
        }
      })
    }}
    component={component}
    onClick={handleClick}
  >
    {children}
  </Paper>
)

export default InfoCard
