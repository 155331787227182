import { AskAICitationInput } from '@focaldata/askai-client'
import { useSearchParams } from 'react-router-dom'
import styles from './TextWithCitations.module.css'

const TextWithCitations = ({
  text,
  citations = [],
  citationsCounterStart = 1,
  onCitationClick
}: {
  text: string
  citations?: AskAICitationInput[]
  citationsCounterStart?: number
  onCitationClick?: ({ citation }: { citation: AskAICitationInput }) => void
}) => {
  const [searchParams] = useSearchParams()

  return (
    <>
      <p className={styles.text}>{text}</p>
      {citations.length > 0 && (
        <ul className={styles['citations-list']}>
          {citations.map((citation, i) => {
            const citationNumber = citationsCounterStart + i
            return (
              <li key={i}>
                <button
                  className={styles.citation}
                  onClick={() => onCitationClick?.({ citation })}
                  data-dd-action-name={`Citation ${citationNumber}`}
                  aria-expanded={
                    searchParams.get('interactionId') ===
                    // @ts-expect-error can remove this comment once askai-client has been updated to return Output types instead of Input types
                    citation.interaction.interaction_id
                  }
                >
                  {citationNumber}
                </button>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

export default TextWithCitations
