import { gql } from '@apollo/client/core'
import DRAFT_CUSTOM_CRITERION_OPTION from './draftCustomCriterionOption'

export const DRAFT_CUSTOM_AUDIENCE_ITEM = gql`
  ${DRAFT_CUSTOM_CRITERION_OPTION}
  fragment DraftCustomAudienceItem on DraftCustomAudienceItem {
    questionLk
    question {
      questionId
      text
      createdDate
    }
    criterionOptions {
      ...DraftCustomCriterionOption
    }
    responseOptionsLogic {
      ...ResponseOptionsLogic
    }
    settingValues {
      code
      value
      createdDate
      sinceDate
    }
    createdDate
    sinceDate
  }
`
