import axios from 'axios'
import axiosRetry from 'axios-retry'
import { AccessToken } from './authUtils'

const axiosInstance = axios.create()

axiosRetry(axiosInstance, {
  retries: 10,
  retryDelay: (retryCount) => {
    return axiosRetry.exponentialDelay(retryCount)
  },
  retryCondition: (error) => {
    // it will only retry requesting when response status is 400 and above
    const resStatus = error.response?.status
    return resStatus !== undefined && resStatus >= 400
  },
  onRetry: (retryCount, error, requestConfig) => {
    if (requestConfig.headers) {
      delete requestConfig.headers.authorization
      Object.assign(requestConfig.headers, AccessToken.requestHeaders())
    }
  }
})

export default axiosInstance
