import { HeadingLevel, IParagraphOptions, Paragraph, TextRun } from 'docx'
import isEmpty from 'lodash/isEmpty'
import { DraftSectionItem } from '../../components/Section/Section.model'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionTypeCode
} from '../../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../../modules/Questionnaire/Questionnaire.slice'
import { flattenEntries } from '../../modules/Questionnaire/Questionnaire.utils'
import { asyncFlatMap } from '../asyncMaps'
import { getQuestionType } from '../questionnaireUtils'
import { createEntryContentBasic } from './createEntryContentBasic'
import { createEntryContentFork } from './createEntryContentFork'
import { createEntryContentFreeText } from './createEntryContentFreeText'
import { createEntryContentMatrix } from './createEntryContentMatrix'
import { createEntryContentRanked } from './createEntryContentRanked'
import { createEntryContentSlider } from './createEntryContentSlider'
import { createEntryContentTextCard } from './createEntryContentTextCard'
import { createLogicHeader } from './createLogicHeader'
import { createMultilinedTextRuns } from './createMultilinedTextRuns'
import { createQuestionTitle } from './createQuestionTitle'
import { paragraphTopBorderOptions } from './paragraphTopBorderOptions'

const getQuestionText = ({ entryType, entryItem }: DraftQuestionnaireEntry) => {
  switch (entryType) {
    case EntryType.TextCardEntryType:
      return entryItem.textCard.title
    case EntryType.MatrixEntryType:
      return entryItem.matrixTitle?.title ?? ''
    case EntryType.ForkEntryType:
      return entryItem.fork.name
    case EntryType.SectionEntryType:
      return entryItem.title
    default:
      return entryItem.question?.text ?? ''
  }
}

const createQuestionContent = async (
  entry: DraftQuestionnaireEntry,
  entries: DraftQuestionnaireEntry[]
): Promise<Paragraph[]> => {
  if (entry.entryType === EntryType.ForkEntryType) {
    return createEntryContentFork(entry)
  }

  if (entry.entryType === EntryType.SectionEntryType) {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: '',
            break: 1
          }),
          new TextRun({
            text: `S${entry.contextPosition + 1} `,
            bold: true
          }),
          new TextRun('(Section - end)')
        ]
      })
    ]
  }

  const questionType = getQuestionType(entry)

  switch (questionType) {
    case QuestionTypeCode.CustomAudience:
    case QuestionTypeCode.StandardAudience:
    case QuestionTypeCode.Basic:
      return createEntryContentBasic(entry, entries)
    case QuestionTypeCode.Scale:
      return createEntryContentSlider(entry)
    case QuestionTypeCode.Matrix:
      return createEntryContentMatrix(
        entry as DraftQuestionnaireEntry<EntryType.MatrixEntryType>,
        entries
      )
    case QuestionTypeCode.Ranked:
      return createEntryContentRanked(entry, entries)
    case QuestionTypeCode.TextCard:
      return createEntryContentTextCard(entry)
    case QuestionTypeCode.FreeText:
      return createEntryContentFreeText(entry)
    default:
      return []
  }
}

export const createQuestions = ({
  entries = [],
  audienceEntries = [],
  responseOptionsByQuestion,
  questionnaireEntries
}: {
  entries?: DraftQuestionnaireEntry[]
  audienceEntries?: DraftQuestionnaireEntry[]
  responseOptionsByQuestion: ResponseOptionsByQuestion
  questionnaireEntries?: DraftQuestionnaireEntry[]
}): Promise<Paragraph[]> => {
  const allEntries = questionnaireEntries || [...audienceEntries, ...entries]
  const anyEntries = !isEmpty(entries) ? entries : audienceEntries
  const flattenedAllEntries = flattenEntries(allEntries)

  return asyncFlatMap<DraftQuestionnaireEntry, Paragraph>(
    anyEntries,
    async (entry) => {
      const logicHeader = createLogicHeader(
        entry,
        flattenedAllEntries,
        responseOptionsByQuestion
      )
      const result: Paragraph[] = []

      if (logicHeader) {
        result.push(logicHeader)
      }

      if (entry.entryType !== EntryType.ForkEntryType) {
        result.push(
          createQuestionTitle({
            entry,
            shouldHaveTopBorder: !logicHeader
          })
        )
      }

      const questionTextParts = getQuestionText(entry)
      const questionTextParagraphOptions: IParagraphOptions = {
        heading: HeadingLevel.HEADING_2,
        // make empty lines if the question's text is multilined
        children: createMultilinedTextRuns(questionTextParts)
      }

      result.push(
        new Paragraph(
          entry.entryType === EntryType.ForkEntryType ||
          entry.entryType === EntryType.SectionEntryType
            ? { ...questionTextParagraphOptions, ...paragraphTopBorderOptions }
            : questionTextParagraphOptions
        )
      )

      if (entry.entryType === EntryType.SectionEntryType) {
        const entryItem = entry.entryItem as DraftSectionItem
        result.push(
          ...(await createQuestions({
            entries: entryItem.entries,
            audienceEntries,
            responseOptionsByQuestion,
            questionnaireEntries: flattenedAllEntries
          }))
        )
      }

      result.push(...(await createQuestionContent(entry, allEntries)))

      return result
    }
  )
}
