import { AxiosResponse } from 'axios'
import { useCallback, useState } from 'react'
import { QuotaResults } from '../data/model/quotaResults'
import { isURL } from '../utils/HelperFunctions'
import { AccessToken } from '../utils/authUtils'
import requestWithRetry from '../utils/requestWithRetry'

const QUOTA_RESULTS_ENDPOINT = `${
  process.env.REACT_APP_URL_SURVEY_RESULTS || '/v2/survey-results'
}/api/v1.0/quotas`

interface QuotaData {
  getSurveyQuotas: (surveyId: string) => void
  quotaResults: QuotaResults[] | undefined
  error: boolean
  loading: boolean
}

interface QuotaResultsData {
  quotas: QuotaResults[]
}

interface QuotaVars {
  surveyId: string
}

const useGetSurveyQuotas: (vars: QuotaVars) => QuotaData = () => {
  const [quotaResults, setQuotaResults] = useState<QuotaResults[] | undefined>(
    undefined
  )
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const getSurveyQuotas = useCallback(
    async (surveyId: string) => {
      if (quotaResults === undefined) {
        requestWithRetry({
          url: `${
            isURL(QUOTA_RESULTS_ENDPOINT)
              ? ''
              : process.env.REACT_APP_REST_DOMAIN
          }${QUOTA_RESULTS_ENDPOINT}`,
          method: 'get',
          getConfig: () => ({
            headers: {
              ...AccessToken.requestHeaders()
            },
            params: {
              surveyId
            }
          })
        })
          .then((response: AxiosResponse<QuotaResultsData>) => {
            if (response.status >= 200 && response.status < 400) {
              setError(false)
              setQuotaResults(response.data.quotas)
            } else {
              setError(true)
            }
          })
          .catch(() => {
            setError(true)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [quotaResults]
  )

  return { getSurveyQuotas, quotaResults, error, loading }
}

export default useGetSurveyQuotas
