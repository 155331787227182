import { RadioGroup } from '@focaldata/cin-ui-components'
import { ChangeEvent } from 'react'

export type AgeRangeType = 'standard' | 'custom'

export const AgeRangeSwitch = ({
  customizedCriterionOptionsEnabled,
  onAgeRangeTypeChange
}: {
  customizedCriterionOptionsEnabled: boolean
  onAgeRangeTypeChange?: (value: AgeRangeType) => void
}) => {
  const ageRangeType = customizedCriterionOptionsEnabled ? 'custom' : 'standard'

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as AgeRangeType
    onAgeRangeTypeChange?.(value)
  }

  return (
    <RadioGroup
      row
      value={ageRangeType}
      onChange={handleChange}
      caption="Select range"
      items={[
        { value: 'standard', label: 'Standard' },
        { value: 'custom', label: 'Custom' }
      ]}
    />
  )
}
