import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  projectsListContent: {
    maxHeight: 'calc(100vh - 57px)',
    overflow: 'auto',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  projectsListHeader: {
    paddingBottom: theme.spacing(2.75)
  }
})

export default useStyles
