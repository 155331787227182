import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  estimateLengthStyle: {
    paddingLeft: theme.spacing(1)
  },
  datePicker: {
    '& .MuiFormControl-marginNormal': {
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25)
    }
  }
})

export default useStyles
