import { Button } from '@focaldata/cin-ui-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { SurveyState } from '../../../../data/gql-gen/dashboard/graphql'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useUpdateSurvey } from '../../../../modules/Survey/Survey.hooks'
import { useFdChatPrice, usePlaceOrder } from '../FdChat.hooks'
import { getFdChatDatesFormatted } from '../FdChat.utils'
import { FdChatLaunchDialog } from './FdChatLaunchDialog'

export const FDChatLaunchButton = ({ disabled }: { disabled?: boolean }) => {
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const [launchDialogOpen, setLaunchDialogOpen] = useState(false)
  const { total } = useFdChatPrice()
  const { updateSurvey, updateSurveyLoading } = useUpdateSurvey()
  const { placingOrderLoading, placeOrder } = usePlaceOrder()

  const handleLaunchSurvey = async () => {
    await updateSurvey({
      variables: {
        surveyId,
        state: SurveyState.Live
      },
      refetchQueries: ['projectBySurveyId']
    })

    await placeOrder()

    navigate(`/fdchat/${surveyId}/launch`)
  }

  const handleButtonClick = () => {
    LogAmplitudeEvent(EventType.ClickedLaunchFdChatProject, { surveyId })
    setLaunchDialogOpen(true)
  }

  return (
    <>
      <Button disabled={disabled} onClick={handleButtonClick}>
        Launch
      </Button>
      <FdChatLaunchDialog
        startDate={getFdChatDatesFormatted().formattedStartDate}
        totalCost={total}
        isOpen={launchDialogOpen}
        primaryButtonLoading={placingOrderLoading || updateSurveyLoading}
        closeDialog={() => setLaunchDialogOpen(false)}
        launchSurvey={handleLaunchSurvey}
      />
    </>
  )
}
