//

import { makeStyles, theme } from '@focaldata/cin-ui-components'

export const useStyles = makeStyles()({
  loadingBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    width: 'calc(100% - 56px)'
  },
  loadingBarDisplay: {
    height: theme.spacing(0.6),
    opacity: 1.0,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen * 1.5
    })
  },
  loadingBarNoDisplay: {
    opacity: 0.5,
    height: theme.spacing(0),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen * 1.5
    })
  }
})
