import { datadogLogs } from '@datadog/browser-logs'
import {
  AnswersFeedbacksApi,
  AskAIAnswerResponseInput,
  FeedbackCategory
} from '@focaldata/askai-client'
import { useSnackbar } from '@focaldata/cin-ui-components'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { useSurveyId } from '../../../hooks/useSurveyId'
import UserSession from '../../../utils/UserSession'
import { fdChatAnalyticsApi } from '../FdChat.utils'

const answersFeedbackApi = new AnswersFeedbacksApi(
  undefined,
  undefined,
  fdChatAnalyticsApi
)

export const useProvideAnswerFeedback = () => {
  const surveyId = useSurveyId()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const provideFeedback = async ({
    answer,
    categories,
    feedbackMessage
  }: {
    answer: AskAIAnswerResponseInput
    categories: FeedbackCategory[]
    feedbackMessage?: string
  }) => {
    setLoading(true)
    try {
      await answersFeedbackApi.provideAnswerFeedbackV1({
        answerId: answer.answer_id!,
        askAIAnswerFeedbackRequest: {
          answer_id: answer.answer_id!,
          feedback_message: feedbackMessage || undefined,
          feedback_category: categories
        }
      })
      setLoading(false)
      enqueueSnackbar('Feedback submitted successfully', {
        variant: 'success'
      })
      LogAmplitudeEvent(EventType.SubmittedFdChatAskAiAnswerFeedback, {
        surveyId,
        answerId: answer.answer_id,
        categories,
        feedbackMessage
      })
      datadogLogs.logger.info('[FDChat] Feedback submitted successfully', {
        surveyId,
        userEmail: UserSession.getEmail(),
        answer,
        categories,
        feedbackMessage
      })
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message)
      enqueueSnackbar('Sorry, an error occurred. Please try again later.', {
        variant: 'error'
      })
      throw error
    }
  }

  return { provideFeedback, error, loading }
}
