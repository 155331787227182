import {
  Checkbox,
  Grid,
  IconButton,
  IconColor,
  IconName,
  IconSize,
  Select,
  SelectOption,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { MaskingRuleClause } from '../../../../data/gql-gen/questionnaire/graphql'
import { composeSelectedQuestionLk } from '../../../../utils/HelperFunctions'
import { MaskingRuleForDialog, MaskingRuleNegation } from '../MaskingButton'
import {
  DELETE_LOGIC_STATEMENT,
  EMPTY_QUESTION_ID,
  MASKING_LOGIC_CLAUSE_SELECTOR,
  MASKING_LOGIC_NEGATION,
  MASKING_LOGIC_QUESTION_SELECTOR
} from '../MaskingDialog/constants'
import useStyles from './MaskingRuleSection.styles'
import {
  getChecked,
  getNegationOptions,
  getOperatorOptions
} from './MaskingRuleSection.utils'

interface ResponseOption {
  id: string
  value: string
}

interface Props {
  index: number
  maskingRule: MaskingRuleForDialog
  questionOptions: SelectOption[] | undefined
  responseOptions: ResponseOption[] | undefined
  forceAndClause?: boolean
  onQuestionOptionChanged?: (questionLk: string) => void
  onResponseOptionChanged?: (responseOptionLk: string, checked: boolean) => void
  onClauseChanged?: (clause: MaskingRuleClause) => void
  onChangeNegation?: (rule: MaskingRuleForDialog) => void
  onDeleteMaskingRule?: () => void
}

// we expect that this masking rule refers only to one question
const MaskingRuleSection: React.FC<Props> = ({
  index,
  maskingRule,
  questionOptions,
  responseOptions,
  forceAndClause,
  onQuestionOptionChanged,
  onResponseOptionChanged,
  onClauseChanged,
  onChangeNegation,
  onDeleteMaskingRule
}: Props) => {
  const { classes } = useStyles()

  const { negated } = maskingRule
  const negationOption = negated
    ? MaskingRuleNegation.IS_NOT_ONE_OF
    : MaskingRuleNegation.IS_ONE_OF
  const { questionLk, matrixTitleLk } = maskingRule

  const selectedQuestionLk =
    questionLk && matrixTitleLk
      ? composeSelectedQuestionLk(questionLk, matrixTitleLk)
      : questionLk

  const handleNegationChange: (
    selectedLogicalComplement: MaskingRuleNegation
  ) => void = (selectedLogicalComplement) => {
    if (onChangeNegation) {
      const negated =
        selectedLogicalComplement === MaskingRuleNegation.IS_NOT_ONE_OF

      const newRule: MaskingRuleForDialog = {
        ...maskingRule,
        negated
      }
      onChangeNegation(newRule)
    }
  }

  const hasError =
    !questionOptions?.some((c) => c.value === selectedQuestionLk) &&
    selectedQuestionLk !== EMPTY_QUESTION_ID

  return (
    <>
      {index > 0 && (
        <Select
          variant={SelectVariant.Underlined}
          readOnly={index > 1 || forceAndClause}
          width={70}
          textSize={TextSize.ms}
          selectedTextHighlight={TextHighlight.Standard}
          selectedTextWeight={TextWeight.SemiBold}
          options={getOperatorOptions()}
          optionValue={
            forceAndClause ? MaskingRuleClause.And : maskingRule.clause
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onClauseChanged?.(event.target.value as MaskingRuleClause)
          }}
          ariaLabel={MASKING_LOGIC_CLAUSE_SELECTOR}
        />
      )}
      <Grid container className={classes.container}>
        <Grid container justifyContent="flex-end">
          <Grid item xs="auto">
            <IconButton
              ariaLabel={DELETE_LOGIC_STATEMENT}
              iconName={IconName.DeleteOutline}
              iconSize={IconSize.Medium}
              iconColor={IconColor.Emphasis}
              onClick={onDeleteMaskingRule}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item container>
            <Select
              ariaLabel={MASKING_LOGIC_QUESTION_SELECTOR}
              placeholder="Please select…"
              variant={SelectVariant.Filled}
              optionValue={selectedQuestionLk}
              defaultOptionValue={selectedQuestionLk}
              maxMenuHeight={300}
              options={questionOptions}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onQuestionOptionChanged?.(event.target.value)
              }}
              fullWidth
              hasError={hasError}
            />
          </Grid>
        </Grid>
        {responseOptions && (
          <>
            {!maskingRule.isSourceFork && (
              <Grid item xs={3} className={classes.linkContainer}>
                <Grid item container>
                  <Select
                    ariaLabel={MASKING_LOGIC_NEGATION}
                    placeholder="Please select…"
                    variant={SelectVariant.Filled}
                    defaultOptionValue={negationOption}
                    maxMenuHeight={100}
                    options={getNegationOptions()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleNegationChange(
                        event.target.value as MaskingRuleNegation
                      )
                    }}
                    minWidth={155}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={5}>
              <Grid container direction="column">
                {!hasError &&
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  responseOptions?.map((ro) => (
                    <Checkbox
                      key={ro.id}
                      label={ro.value}
                      defaultChecked={getChecked(ro.id, maskingRule)}
                      onChange={(event) =>
                        onResponseOptionChanged?.(ro.id, event.target.checked)
                      }
                    />
                  ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default MaskingRuleSection
