import {
  Button,
  ButtonVariant,
  Grid,
  Input,
  InputType,
  InputVariant,
  Paper,
  RadioGroup,
  Skeleton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { FieldworkAudienceType } from '../../../../data/model/fieldwork'
import { getLangFromLangName } from '../../../../data/seed/languages'
import useGetDraftAudience from '../../../../hooks/audience/useGetDraftAudience'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { AudienceCriteriaConfigurator } from '../../../../modules/Audience/AudienceCriteriaConfigurator'
import { CountryPickerContainer } from '../../../../modules/Audience/CountryPicker'
import { Questionnaire } from '../../../../modules/Questionnaire'
import { useFdChatFieldwork, useUpdateFdChatFieldwork } from '../FdChat.hooks'
import { FdChatPreviewInterviewButton } from '../ReviewPage/FdChatReviewQuote'
import styles from './FdChatAudience.module.css'

export const convertAudienceType = (value: FieldworkAudienceType) => {
  return value === 'CustomAudience'
    ? 'custom'
    : value === 'ProlificAudience'
      ? 'prolific'
      : 'cint'
}

export const FdChatAudience = () => {
  const surveyId = useSurveyId()
  const location = useLocation()
  const { updateFdChatFieldwork, debouncedUpdateFdChatFieldwork } =
    useUpdateFdChatFieldwork()
  const { fieldwork, fieldworkLoading } = useFdChatFieldwork()
  const [showAudienceQuestions, setShowAudienceQuestions] = useState(
    (location.state as any)?.showAudienceQuestions ?? false
  )
  const [showSurveyQuestions, setShowSurveyQuestions] = useState(
    (location.state as any)?.showSurveyQuestions ?? false
  )
  const { draftAudience } = useGetDraftAudience()

  const language = getLangFromLangName(fieldwork?.locale.language)

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedFdChatAudiencePage, { surveyId })
  }, [surveyId])

  const handleAudienceTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFdChatFieldwork({
      panelSupplierCode: convertAudienceType(
        e.target.value as Exclude<FieldworkAudienceType, 'CintAudience'>
      )
    })
  }

  return (
    <Grid
      container
      padding={'24px 32px'}
      style={{ paddingBottom: showAudienceQuestions ? '0px' : undefined }}
    >
      {!showAudienceQuestions && !showSurveyQuestions ? (
        <Grid width={'100%'}>
          <Text size={TextSize.lxl} weight={TextWeight.Bold}>
            Who do you want to learn from?
          </Text>
          <div style={{ marginBottom: '28px' }}></div>
          {fieldworkLoading ? (
            <Skeleton variant="rectangular" height={150} width={800} />
          ) : (
            <RadioGroup
              row
              items={[
                {
                  label: (
                    <div>
                      <Text
                        size={TextSize.l}
                        weight={
                          fieldwork?.audience[0].__typename === 'CustomAudience'
                            ? TextWeight.Bold
                            : TextWeight.Regular
                        }
                      >
                        Use my own audience
                      </Text>
                      <div style={{ maxWidth: '350px' }}>
                        <Text highlight={TextHighlight.Background}>
                          You will get an interview link to share with your
                          audience once you&apos;ve launched your project.
                        </Text>
                      </div>
                    </div>
                  ),
                  value: 'CustomAudience'
                },
                {
                  label: (
                    <div>
                      <Text
                        size={TextSize.l}
                        weight={
                          fieldwork?.audience[0].__typename ===
                          'ProlificAudience'
                            ? TextWeight.Bold
                            : TextWeight.Regular
                        }
                      >
                        Use Focaldata audience
                      </Text>
                      <div style={{ maxWidth: '350px' }}>
                        <Text highlight={TextHighlight.Background}>
                          We will handle data collection for you and tap into
                          one of our high-quality panels.
                        </Text>
                      </div>
                    </div>
                  ),
                  value: 'ProlificAudience'
                }
              ]}
              value={fieldwork?.audience[0].__typename}
              onChange={handleAudienceTypeChange}
              itemStyle={{ alignItems: 'flex-start' }}
              controlStyle={{ marginTop: '-8px' }}
            />
          )}
          <div style={{ marginBottom: '56px' }}></div>
          <Grid container>
            <Grid item xs={12} lg={7}>
              <CountryPickerContainer />
              <p className={styles['language-description']}>
                This interview will be conducted in {language}. Ensure audience
                and survey questions are in {language} too.
              </p>
              <div style={{ marginBottom: '24px' }}></div>
              <div>
                <Text size={TextSize.m} weight={TextWeight.SemiBold}>
                  Number of interviews
                </Text>
                {fieldworkLoading ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <Input
                    inputType={InputType.Number}
                    defaultValue={fieldwork?.samplesNeeded.unadjusted}
                    onChange={(e) => {
                      debouncedUpdateFdChatFieldwork({
                        samplesNeeded: Number(e.target.value)
                      })
                    }}
                    variant={InputVariant.Filled}
                    fullWidth
                  />
                )}
              </div>
              <div style={{ marginBottom: '24px' }}></div>
              <Paper variant="outlined">
                <Grid
                  container
                  padding={'24px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <div>
                    <Text weight={TextWeight.Bold} size={TextSize.lxl}>
                      Audience questions
                    </Text>
                    <div style={{ maxWidth: '300px' }}>
                      <Text highlight={TextHighlight.Background}>
                        Add profiling and screening questions to ensure your
                        survey only reaches your target audience.
                      </Text>
                    </div>
                  </div>
                  <Button
                    variant={ButtonVariant.PrimaryOutlined}
                    onClick={() => setShowAudienceQuestions(true)}
                  >
                    {(draftAudience?.members.length ?? 0) > 0
                      ? 'Added audience questions'
                      : 'Add audience questions'}
                  </Button>
                </Grid>
              </Paper>
              <div style={{ marginBottom: '24px' }}></div>
              <Paper variant="outlined">
                <Grid
                  container
                  padding={'24px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <div>
                    <Text weight={TextWeight.Bold} size={TextSize.lxl}>
                      Survey questions
                    </Text>
                    <div style={{ maxWidth: '300px' }}>
                      <Text highlight={TextHighlight.Background}>
                        Add any further demographic questions to support your
                        analysis.
                      </Text>
                    </div>
                  </div>
                  <Button
                    variant={ButtonVariant.PrimaryOutlined}
                    onClick={() => setShowSurveyQuestions(true)}
                  >
                    Add survey questions
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid width={'100%'}>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            marginBottom={'24px'}
          >
            <Text size={TextSize.lxl} weight={TextWeight.Bold}>
              {showAudienceQuestions ? 'Audience' : 'Survey'} questions
            </Text>
            <Grid container width={'max-content'} gap={'10px'}>
              <FdChatPreviewInterviewButton />
              <Button
                variant={ButtonVariant.PrimaryOutlined}
                onClick={() =>
                  showAudienceQuestions
                    ? setShowAudienceQuestions(false)
                    : setShowSurveyQuestions(false)
                }
              >
                Close
              </Button>
            </Grid>
          </Grid>
          {showAudienceQuestions ? (
            <AudienceCriteriaConfigurator />
          ) : (
            <Questionnaire
              height={'calc(100vh - 280px)'}
              sidebarHeight={'auto'}
              hideBottomNav
            />
          )}
        </Grid>
      )}
    </Grid>
  )
}
