import {
  IconName,
  IconSize,
  KebabMenuOption,
  Status
} from '@focaldata/cin-ui-components'
import {
  ProjectStatus,
  ProjectType,
  SurveyState
} from '../../data/gql-gen/dashboard/graphql'
import { SurveyProgress } from '../../data/model/surveyResults'
import {
  formatThousandsWithCommas,
  proportionToPercent
} from '../../utils/HelperFunctions'
import { ProjectModel } from './ProjectListItem'

export const stringToStatusMap = new Map<ProjectStatus | SurveyState, Status>([
  [ProjectStatus.Draft, Status.DRAFT],
  [ProjectStatus.Scheduled, Status.SCHEDULED],
  [ProjectStatus.Live, Status.LIVE],
  [ProjectStatus.Paused, Status.PAUSED],
  [ProjectStatus.Halted, Status.HALTED],
  [ProjectStatus.Completed, Status.COMPLETE],
  [SurveyState.Draft, Status.DRAFT],
  [SurveyState.Scheduled, Status.SCHEDULED],
  [SurveyState.Live, Status.LIVE],
  [SurveyState.Paused, Status.PAUSED],
  [SurveyState.Halted, Status.HALTED],
  [SurveyState.Completed, Status.COMPLETE]
])

export const stringStatusEnumMap = new Map<Status, string>([
  [Status.DRAFT, 'DRAFT'],
  [Status.SCHEDULED, 'SCHEDULED'],
  [Status.LIVE, 'LIVE'],
  [Status.PAUSED, 'PAUSED'],
  [Status.HALTED, 'HALTED'],
  [Status.COMPLETE, 'COMPLETE']
])

export const stringProjectTypeEnumMap = new Map<ProjectType, string>([
  [ProjectType.AdHoc, 'AD HOC'],
  [ProjectType.MultiMarket, 'MULTI MARKET'],
  [ProjectType.Mrp, 'MRP']
])

interface Args {
  project: ProjectModel
  onDuplicateProject?: (project: ProjectModel) => void
  onPauseProject?: (project: ProjectModel) => void
  onUnpauseProject?: (project: ProjectModel) => void
  onDeleteProject?: (project: ProjectModel) => void
}

export const getKebabMenuOptions: (args: Args) => KebabMenuOption[] = (
  args
) => {
  const {
    project,
    onDuplicateProject,
    onDeleteProject,
    onPauseProject,
    onUnpauseProject
  }: Args = args
  const { status, projectType }: ProjectModel = project

  const isFdChat: boolean = projectType === ProjectType.FdChat

  const defaultOptions: KebabMenuOption[] = [
    {
      id: 0,
      textItem: 'Duplicate project',
      onClickItem: () => onDuplicateProject?.(project),
      iconName: IconName.Duplicate,
      iconSize: IconSize.Large
    }
  ]

  const getDraftOptions = (): KebabMenuOption[] => [
    ...defaultOptions,
    {
      id: 1,
      textItem: 'Delete project',
      onClickItem: () => onDeleteProject?.(project),
      iconName: IconName.DeleteOutline,
      iconSize: IconSize.Large
    }
  ]

  const getLiveOptions = (): KebabMenuOption[] => {
    if (isFdChat) {
      return defaultOptions
    } else {
      return [
        {
          id: 1,
          textItem: 'Pause project',
          onClickItem: () => onPauseProject?.(project),
          iconName: IconName.PauseCircleOutline,
          iconSize: IconSize.Large
        },
        ...defaultOptions
      ]
    }
  }

  const getPausedOptions = (): KebabMenuOption[] => {
    if (isFdChat) {
      return defaultOptions
    } else {
      return [
        {
          id: 1,
          textItem: 'Unpause project',
          onClickItem: () => onUnpauseProject?.(project),
          iconName: IconName.PlayCircleOutline,
          iconSize: IconSize.Large
        },
        ...defaultOptions
      ]
    }
  }

  switch (status) {
    case ProjectStatus.Draft:
      return getDraftOptions()
    case ProjectStatus.Live:
      return getLiveOptions()
    case ProjectStatus.Paused:
      return getPausedOptions()
    default:
      return defaultOptions
  }
}

export const getProgressPercent: (
  surveyId: string,
  projectStatus: ProjectStatus,
  surveysProgress: SurveyProgress[] | undefined
) => number | undefined = (surveyId, projectStatus, surveysProgress) => {
  if (
    projectStatus === ProjectStatus.Draft ||
    projectStatus === ProjectStatus.Scheduled
  ) {
    return undefined
  }

  const progress =
    surveysProgress?.find((progress) => progress.surveyId === surveyId)
      ?.progress?.progress || 0

  // Progress can be at maximum 100%. So if it is 120% we should show 100%
  if (progress >= 1) {
    return proportionToPercent(1)
  }

  return proportionToPercent(progress)
}

export const getFormattedProgress: (
  surveyId: string,
  projectStatus: ProjectStatus,
  surveysProgress: SurveyProgress[] | undefined
) => string | undefined = (surveyId, projectStatus, surveysProgress) => {
  if (
    projectStatus === ProjectStatus.Draft ||
    projectStatus === ProjectStatus.Scheduled
  ) {
    return undefined
  }
  const samplesCollected =
    surveysProgress?.find((progress) => progress.surveyId === surveyId)
      ?.progress?.samplesCollected || 0

  const samplesWanted =
    surveysProgress?.find((progress) => progress.surveyId === surveyId)
      ?.progress?.samplesWanted || 0
  return `${formatThousandsWithCommas(
    samplesCollected
  )}/${formatThousandsWithCommas(samplesWanted)}`
}

export const getSingleMarketPath = (
  projectId: string,
  surveyId: string,
  status?: Status
) => {
  switch (status) {
    case Status.DRAFT:
      return `/project/${projectId}/survey/${surveyId}/audience`
    default:
      return `/project/${projectId}/survey/${surveyId}/results`
  }
}

export const getMultiMarketPath = (projectId: string, status?: Status) => {
  switch (status) {
    case Status.LIVE:
    case Status.COMPLETE:
      return `/project/${projectId}/results`
    default:
      return `/project/${projectId}/markets`
  }
}
