import { Grid, Skeleton, Text } from '@focaldata/cin-ui-components'
import { useNavigate } from 'react-router-dom'
import { getLangFromLangName } from '../../../../data/seed/languages'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useCountryName } from '../../../../modules/Audience/Audience.hooks'
import { useFdChatFieldwork } from '../FdChat.hooks'
import { USER_MAX_LENGTH_OF_INTERVIEW } from '../FdChat.model'
import { ReviewSection } from './FdChatReview'

const FdChatReviewAudience = ({ showEditLink }: { showEditLink?: boolean }) => {
  const navigate = useNavigate()
  const countryName = useCountryName()
  const surveyId = useSurveyId()
  const { fieldwork, fieldworkLoading, isCustomAudience } = useFdChatFieldwork()

  if (fieldworkLoading) return <Skeleton variant="rectangular" height={350} />

  const audienceType = isCustomAudience ? 'Custom' : 'Focaldata'
  const audience = fieldwork?.audience[0]

  return (
    <ReviewSection
      title="Audience overview"
      sectionLinkText={showEditLink ? 'Edit audience' : undefined}
      onSectionLinkClick={
        showEditLink
          ? () => navigate(`/fdchat/${surveyId}/setup/audience`)
          : undefined
      }
    >
      <Grid
        container
        direction={'column'}
        component={'ul'}
        style={{ listStyle: 'none', padding: 0, margin: 0 }}
        rowGap={'10px'}
      >
        <li>
          <Text>
            <b>Country:</b> {countryName}
          </Text>
        </li>
        <li>
          <Text>
            <b>Language:</b> {getLangFromLangName(fieldwork?.locale.language)}
          </Text>
        </li>
        <li>
          <Text>
            <b>Audience:</b> {audienceType}{' '}
            {audience?.__typename !== 'CintAudience' && audience?.description
              ? `(${audience.description})`
              : ''}
          </Text>
        </li>
        <li>
          <Text>
            <b>Interview length:</b> {USER_MAX_LENGTH_OF_INTERVIEW} minutes
          </Text>
        </li>
        {isCustomAudience && (
          <li>
            <Text>
              <b>Interview link:</b> Launch your study to get your interview
              link and share with your audience
            </Text>
          </li>
        )}
        <li>
          <Text>
            All interviews include our standard age, gender and location
            demographic questions
          </Text>
        </li>
      </Grid>
    </ReviewSection>
  )
}

export default FdChatReviewAudience
