import { combineReducers } from '@reduxjs/toolkit'
import loopingReducer from './modules/Questionnaire/Looping/Looping.slice'
import questionnaireReducer from './modules/Questionnaire/Questionnaire.slice'
import sectionsRandomisationReducer from './modules/Questionnaire/SectionsRandomisation/SectionsRandomisation.slice'

export const rootReducer = combineReducers({
  questionnaire: questionnaireReducer,
  looping: loopingReducer,
  sectionsRandomisation: sectionsRandomisationReducer
})

export type RootReducerType = typeof rootReducer
