/**
 * This code is extracted from Remix's cookie API to make it browser compatible
 * by removing support for signing. This also allows the API to be
 * synchronous.
 *
 * @see https://github.com/remix-run/remix/blob/main/packages/remix-server-runtime/cookies.ts
 */
import { CookieSerializeOptions, parse, serialize } from 'cookie'

export const RemixBrowserCookie = {
  set(key: string, value: unknown, options?: CookieSerializeOptions) {
    document.cookie = serialize(key, value === '' ? '' : encodeData(value), {
      path: '/',
      sameSite: 'lax',
      secure: location.protocol === 'https:',
      ...options
    })
  },
  get<T = unknown>(key: string): T | null {
    const cookies = parse(document.cookie)
    return key in cookies
      ? cookies[key] === ''
        ? ''
        : decodeData(cookies[key])
      : null
  },
  clear(key: string) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }
}

function encodeData(value: any): string {
  return btoa(myUnescape(encodeURIComponent(JSON.stringify(value))))
}

function decodeData(value: string): any {
  try {
    return JSON.parse(decodeURIComponent(myEscape(atob(value))))
  } catch (error: unknown) {
    return {}
  }
}

// See: https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.escape.js
function myEscape(value: string): string {
  const str = value.toString()
  let result = ''
  let index = 0
  let chr, code
  while (index < str.length) {
    chr = str.charAt(index++)
    if (/[\w*+\-./@]/.exec(chr)) {
      result += chr
    } else {
      code = chr.charCodeAt(0)
      if (code < 256) {
        result += '%' + hex(code, 2)
      } else {
        result += '%u' + hex(code, 4).toUpperCase()
      }
    }
  }
  return result
}

function hex(code: number, length: number): string {
  let result = code.toString(16)
  while (result.length < length) result = '0' + result
  return result
}

// See: https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.unescape.js
function myUnescape(value: string): string {
  const str = value.toString()
  let result = ''
  let index = 0
  let chr, part
  while (index < str.length) {
    chr = str.charAt(index++)
    if (chr === '%') {
      if (str.charAt(index) === 'u') {
        part = str.slice(index + 1, index + 5)
        if (/^[\da-f]{4}$/i.exec(part)) {
          result += String.fromCharCode(parseInt(part, 16))
          index += 5
          continue
        }
      } else {
        part = str.slice(index, index + 2)
        if (/^[\da-f]{2}$/i.exec(part)) {
          result += String.fromCharCode(parseInt(part, 16))
          index += 2
          continue
        }
      }
    }
    result += chr
  }
  return result
}
