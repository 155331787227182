import axios, { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { ProjectMetadata } from '../../../data/model/results'
import requestWithRetry from '../../../utils/requestWithRetry'

interface Data {
  loading: boolean
  error: SurveyMetadataError
  data: ProjectMetadata | undefined
}

interface Vars {
  surveyId: string
}

interface SurveyMetadataError {
  isError: boolean
  reason?: '404' | 'other'
}

const PROJECT_METADATA_ENDPOINT = '/project-metadata'

export const useSurveyMetadata = ({ surveyId }: Vars): Data => {
  const [error, setError] = useState<SurveyMetadataError>({ isError: false })
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ProjectMetadata>()

  useEffect(() => {
    setLoading(true)

    requestWithRetry({
      retryCount: 0,
      method: 'get',
      url: `${process.env.REACT_APP_PUBLIC_REST_DOMAIN}${PROJECT_METADATA_ENDPOINT}/${surveyId}`
    })
      .then((data: AxiosResponse<ProjectMetadata>) => {
        if (data.status >= 200 && data.status < 400) {
          setError({ isError: false })
          setData(data.data)
        } else {
          setError({ isError: true, reason: 'other' })
        }
      })
      .catch((error: AxiosError) => {
        if (!axios.isCancel(error)) {
          const reason =
            (error as AxiosError).response?.status === 404 ? '404' : 'other'
          setError({ isError: true, reason })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [surveyId])

  return { data, error, loading }
}
