import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  customAudienceItem: {
    '&.MuiGrid-item': {
      marginLeft: `-${theme.spacing(0.5)}`
    },
    '& .MuiFormControlLabel-label': {
      paddingTop: theme.spacing(0.625)
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start'
    }
  },
  listMarginTop: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5)
  }
})

export default useStyles
