import { Resolver } from '@apollo/client'
import { addDraftCombinedResponseOption } from './DraftCombinedQuestionResolvers/addDraftCombinedResponseOption'
import { bulkAddDraftCombinedResponseOptions } from './DraftCombinedQuestionResolvers/bulkAddDraftCombinedResponseOptions'
import { deleteDraftCombinedResponseOption } from './DraftCombinedQuestionResolvers/deleteDraftCombinedResponseOption'
import { deleteMappingMarketQuestion } from './DraftCombinedQuestionResolvers/deleteMappingMarketQuestion'
import { mapResponseOption } from './DraftCombinedQuestionResolvers/mapResponseOption'
import { setDraftCombinedQuestion } from './DraftCombinedQuestionResolvers/setDraftCombinedQuestion'
import { setDraftCombinedQuestionTitle } from './DraftCombinedQuestionResolvers/setDraftCombinedQuestionTitle'
import { updateDraftCombinedResponseOption } from './DraftCombinedQuestionResolvers/updateDraftCombinedResponseOption'

export const draftCombinedQuestionMutationsResolvers: Record<string, Resolver> =
  {
    setDraftCombinedQuestion,
    setDraftCombinedQuestionTitle,
    addDraftCombinedResponseOption,
    deleteDraftCombinedResponseOption,
    bulkAddDraftCombinedResponseOptions,
    updateDraftCombinedResponseOption,
    deleteMappingMarketQuestion,
    mapResponseOption
  }
