export interface ResponseOptionInputInput {
  responseOptionType: ResponseOptionType
  responseOptionValue: string
}

export enum ResponseOptionType {
  RoDouble = 'RoDouble',
  RoInt = 'RoInt',
  RoLink = 'RoLink',
  RoString = 'RoString'
}

export enum OutputType {
  Weighted = 'Weighted',
  Unweighted = 'Unweighted'
}

export enum DataFormat {
  WIDE_ENCODED = 'WIDE_ENCODED',
  WIDE_UNENCODED = 'WIDE_UNENCODED',
  LONG_ENCODED = 'LONG_ENCODED',
  LONG_UNENCODED = 'LONG_UNENCODED',
  SPSS = 'SPSS',
  XLSX_UNENCODED = 'XLSX_UNENCODED'
}
