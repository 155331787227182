import { gql } from '@apollo/client/core'
import { dashboardGql } from '../../data/gql-gen/dashboard'
import { Project } from '../../data/gql-gen/dashboard/graphql'
import { SURVEY } from './Project.fragments'
import { Survey } from './Project.model'

export const CREATE_PROJECT = dashboardGql(/* GraphQL */ `
  mutation project(
    $name: String!
    $description: String!
    $accountId: ID!
    $solutionId: ID! # $hash: String!
    $creator: String!
    $projectType: ProjectType
  ) {
    createProject(
      name: $name
      description: $description
      accountId: $accountId
      solutionId: $solutionId # hash: $hash
      creator: $creator
      projectType: $projectType
    ) {
      projectId
      projectType
      accountId
      name
      nickname
      createdDate
      sinceDate
      creator
      description
      projectMetadata {
        mrpUrl
      }
      solution {
        solutionId
        name
        createdDate
        sinceDate
      }
      surveys {
        surveyId
        createdDate
        sinceDate
        fieldworkDates {
          startDate
          endDate
        }
        name
        nickname
        state
      }
      status
    }
  }
`)

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: ID!, $accountId: ID!) {
    deleteProject(projectId: $projectId, accountId: $accountId)
  }
`

export interface UpdateProjectData {
  updateProject: Project
}

export const UPDATE_PROJECT = gql`
  mutation updateProject($projectId: ID!, $name: String!) {
    updateProject(projectId: $projectId, name: $name) {
      projectId
    }
  }
`

export interface UpdateSurveyData {
  updateSurvey: Survey
}

export const UPDATE_SURVEY = gql`
  mutation updateSurvey($surveyId: ID!, $state: SurveyState!) {
    updateSurvey(surveyId: $surveyId, state: $state) {
      ...Survey
    }
  }
  ${SURVEY}
`
