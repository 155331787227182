import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useFdChatFieldwork } from '../../../containers/Page/FdChat/FdChat.hooks'
import { SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import useGetSurveyState from '../../../hooks/useGetSurveyState'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { useFdChatProgress } from '../FdChatProgressProvider.context'

export type TimelineStep = {
  title: string
  description: string | JSX.Element
  status: 'done' | 'in-progress' | 'to-do'
}

export const useTimelineSteps = () => {
  const { surveyState, loading: loadingSurveyState } = useGetSurveyState()
  const { fieldwork } = useFdChatFieldwork()
  const {
    interviewsWanted,
    dataAvailable,
    loading: loadingFdChatProgress
  } = useFdChatProgress()

  const navigate = useNavigate()
  const surveyId = useSurveyId()

  const isDraft = surveyState === SurveyState.Draft
  const isComplete = surveyState === SurveyState.Completed
  const isHalted = surveyState === SurveyState.Halted
  const isPaused = surveyState === SurveyState.Paused
  const isScheduled = surveyState === SurveyState.Scheduled

  const isSurveyStopped = isHalted || isPaused || isScheduled

  const startDateFormatted = dayjs(fieldwork?.duration.startDate).format(
    'DD/MM/YYYY'
  )
  const endDateFormatted = dayjs(fieldwork?.duration.endDate).format(
    'DD/MM/YYYY'
  )

  const projectBriefStatus: TimelineStep['status'] = isDraft
    ? 'in-progress'
    : 'done'

  const projectLaunchStatus: TimelineStep['status'] = isDraft ? 'to-do' : 'done'

  const getConductingInterviewStatus = (): TimelineStep['status'] => {
    if (isDraft || isSurveyStopped) {
      return 'to-do'
    }

    if (isComplete || dataAvailable) {
      return 'done'
    }

    return 'in-progress'
  }

  const getExploringDataStatus = (): TimelineStep['status'] => {
    if (isComplete) {
      return 'done'
    }

    if (dataAvailable) {
      return 'in-progress'
    }

    return 'to-do'
  }

  const timelineSteps: TimelineStep[] = [
    {
      title: 'Project brief',
      description: `${startDateFormatted} - Project brief created`,
      status: projectBriefStatus
    },
    {
      title: 'Project launch',
      description: `${startDateFormatted} - Project launched for the sample (n = ${interviewsWanted})`,
      status: projectLaunchStatus
    },
    {
      title: 'Conducting interviews',
      description: `${startDateFormatted} - Interviewing ${interviewsWanted} participants`,
      status: getConductingInterviewStatus()
    },
    {
      title: 'Start exploring data',
      description: (
        <span>
          {startDateFormatted} - You can explore your initial data using{' '}
          <button
            style={{
              background: 'none',
              border: 'none',
              color: 'inherit',
              padding: 0,
              fontSize: 'inherit',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => navigate(`/fdchat/${surveyId}/results/ask-ai`)}
          >
            AskAI
          </button>
        </span>
      ),
      status: getExploringDataStatus()
    },
    {
      title: 'Project complete',
      description: `${endDateFormatted} - You now have access to the full sample`,
      status: isComplete ? 'done' : 'to-do'
    }
  ]

  const inProgressStep = timelineSteps.find(
    (step) => step.status === 'in-progress'
  )

  return {
    timelineSteps,
    inProgressStep,
    loading: loadingSurveyState || loadingFdChatProgress
  }
}
