import {
  SnackbarVariant,
  UseSnackbar,
  useSnackbar
} from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction } from 'react'
import DialogFollowUp from '../../../controls/DialogFollowUp'
import useRetargeting from '../../../hooks/useRetargeting'

interface Props {
  isDialogFollowUpOpen: boolean
  setIsDialogFollowUpOpen: Dispatch<SetStateAction<boolean>>
}

const FollowUpDialogContainer: React.FC<Props> = (props: Props) => {
  const { isDialogFollowUpOpen, setIsDialogFollowUpOpen } = props

  const { enqueueSnackbar }: UseSnackbar = useSnackbar()
  const { setRespondentIds, loading, error, isSuccess } = useRetargeting()

  const handleClose: () => void = () => {
    setIsDialogFollowUpOpen(false)
  }

  const handleRequestFollowUp = async (respondentIds: string[]) => {
    setRespondentIds(respondentIds)
  }

  if (isSuccess) {
    enqueueSnackbar(
      'Your follow up request was successful. We will contact you by email shortly.',
      {
        variant: SnackbarVariant.Success
      }
    )
    setIsDialogFollowUpOpen(false)
  }

  if (error) {
    enqueueSnackbar(
      'Something went wrong. Try again, if the error persists contact support@focaldata.com.',
      {
        variant: SnackbarVariant.Error
      }
    )
  }

  return (
    <DialogFollowUp
      isDialogFollowUpOpen={isDialogFollowUpOpen}
      handleRequestFollowUp={handleRequestFollowUp}
      loading={loading}
      onClose={() => {
        handleClose()
      }}
    />
  )
}

export default FollowUpDialogContainer
