import { gql } from '@apollo/client'

export const PROJECT = gql`
  fragment Project on Project {
    projectId
    nickname
    name
    description
    projectType
    projectMetadata {
      mrpUrl
    }
    accountId
    creator
    solution {
      solutionId
      name
      createdDate
      sinceDate
    }
    surveys {
      surveyId
      nickname
      name
      state
      fieldworkDates {
        startDate
        endDate
      }
      sinceDate
    }
    createdDate
    sinceDate
    status
  }
`

export const SURVEY = gql`
  fragment Survey on Survey {
    surveyId
    nickname
    name
    state
    createdDate
    sinceDate
  }
`
