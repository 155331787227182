import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useAppDispatch } from '../../App.store'
import {
  DraftQuestionnaireEntry,
  PasteEntryTextFieldsV2Mutation,
  PasteEntryTextFieldsV2MutationVariables,
  PasteMatrixRowValuesV2Mutation,
  PasteMatrixRowValuesV2MutationVariables,
  PasteResponseOptionValuesV2Mutation,
  PasteResponseOptionValuesV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import {
  PASTE_ENTRY_TEXT_FIELDS_V2,
  PASTE_MATRIX_ROW_VALUES_V2,
  PASTE_RESPONSE_OPTION_VALUES_V2
} from '../../data/gql/questionnaire/mutations/pasteTextFieldsV2'
import { DraftQuestionItem } from '../../data/model/questionnaire'
import { responseOptionCreated } from '../../modules/Questionnaire/Questionnaire.slice'
import { useProjectId } from '../useProjectId'

export const usePasteMutations = () => {
  const projectId = useProjectId()
  const dispatch = useAppDispatch()
  const [pasteEntryTextFieldsV2] = useMutation<
    PasteEntryTextFieldsV2Mutation,
    PasteEntryTextFieldsV2MutationVariables
  >(PASTE_ENTRY_TEXT_FIELDS_V2, {
    context: { clientName: 'questionnaire' }
  })

  const [pasteResponseOptionValuesV2] = useMutation<
    PasteResponseOptionValuesV2Mutation,
    PasteResponseOptionValuesV2MutationVariables
  >(PASTE_RESPONSE_OPTION_VALUES_V2, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const [pasteMatrixRowValuesV2] = useMutation<
    PasteMatrixRowValuesV2Mutation,
    PasteMatrixRowValuesV2MutationVariables
  >(PASTE_MATRIX_ROW_VALUES_V2, {
    context: { clientName: 'questionnaire' }
  })

  const pasteInTitleMutation = useCallback(
    (
      questionLk: string,
      text: string,
      textStyling: string,
      responseOptions: string[]
    ): void => {
      pasteEntryTextFieldsV2({
        variables: {
          input: {
            projectId,
            entryId: questionLk,
            text,
            textStyling,
            rows: responseOptions
          }
        },
        onCompleted: (data) => {
          const entry = data.pasteEntryTextFieldsV2 as DraftQuestionnaireEntry
          const entryItem = entry.entryItem as DraftQuestionItem
          entryItem.responseOptions.forEach((response) => {
            dispatch(
              responseOptionCreated({
                questionLk,
                position: response.position,
                responseOption: response
              })
            )
          })
        }
      })
    },
    [pasteEntryTextFieldsV2, projectId, dispatch]
  )

  const pasteInResponsesMutation = useCallback(
    (questionLk: string, responseOptions: string[], position: number): void => {
      pasteResponseOptionValuesV2({
        variables: {
          input: {
            projectId,
            entryId: questionLk,
            responseOptionValues: responseOptions,
            position
          }
        },
        onCompleted: (data) => {
          const entry =
            data.pasteResponseOptionValuesV2 as DraftQuestionnaireEntry
          const entryItem = entry.entryItem as DraftQuestionItem
          entryItem.responseOptions.forEach((response) => {
            dispatch(
              responseOptionCreated({
                questionLk,
                position: response.position,
                responseOption: response
              })
            )
          })
        }
      })
    },
    [pasteResponseOptionValuesV2, projectId, dispatch]
  )

  const pasteInMatrixRowsMutation = useCallback(
    (matrixTitleLk: string, rows: string[], position: number): void => {
      pasteMatrixRowValuesV2({
        variables: {
          input: {
            projectId,
            entryId: matrixTitleLk,
            matrixRowValues: rows,
            position
          }
        }
      })
    },
    [pasteMatrixRowValuesV2, projectId]
  )

  return {
    pasteInTitleMutation,
    pasteInResponsesMutation,
    pasteInMatrixRowsMutation
  }
}
