import {
  Button,
  Grid,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { ProjectType } from '../../data/gql-gen/dashboard/graphql'
import { useProjectData } from '../../modules/Project/Project.hooks'
import dayjs from '../../utils/dayjs'
import imageSrc from './assets/launch.jpg'
import useStyles from './styles'

interface Props {
  surveyLaunchDate?: string
  onButtonClick?: () => void
}

const SurveyLaunchedPage: React.FC<Props> = (props: Props) => {
  const { surveyLaunchDate, onButtonClick }: Props = props
  const { classes } = useStyles()
  const { project } = useProjectData()
  const isFdchatProject = project?.projectType === ProjectType.FdChat

  const todayDate = new Date()
  const isSurveyLaunchToday = dayjs(surveyLaunchDate).isSame(todayDate, 'day')

  return (
    <div className={classes.positioning}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          container
          item
          direction="column"
          xs={12}
          md={6}
          lg={7}
          className={classes.largePadding}
        >
          <Text
            highlight={TextHighlight.Background}
            weight={TextWeight.SemiBold}
            size={TextSize.ms}
            className={classes.smallPadding}
          >
            LAUNCH CONFIRMED
          </Text>
          <Text
            size={TextSize.lxl}
            weight={TextWeight.SemiBold}
            className={classes.smallPadding}
          >
            {`Thank you for using Focaldata to launch your ${
              isFdchatProject ? 'research project' : 'poll'
            }.`}
          </Text>
          <Text
            highlight={TextHighlight.Background}
            size={TextSize.m}
            className={classes.smallPadding}
          >
            {isSurveyLaunchToday
              ? `Your ${
                  isFdchatProject ? 'project' : 'survey'
                } is going live now.`
              : `Your ${
                  isFdchatProject ? 'project' : 'survey'
                } will go live with panellists on ${surveyLaunchDate}.`}
          </Text>
          <Grid
            container
            justifyContent="flex-start"
            className={classes.buttonPadding}
          >
            <Button className={classes.button} onClick={onButtonClick}>
              To your project
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={12}
          md={6}
          lg={5}
          className={classes.largePadding}
        >
          <img
            src={imageSrc}
            alt="Launching rocket"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default SurveyLaunchedPage
