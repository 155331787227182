import {
  Button,
  ButtonVariant,
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  List,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import dayjs from 'dayjs'
import identity from 'lodash/identity'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'uuid-random'
import ProjectListFilters from '../../../../controls/ProjectListFilters'
import { ProjectOrderSelect } from '../../../../controls/ProjectOrderSelect'
import {
  SortOrder,
  SurveyState
} from '../../../../data/gql-gen/dashboard/graphql'
import { MULTI_MARKET_HEADER_HEIGHT } from '../MultiMarketPage'
import { AddMarketsDialog, SaveMarketData } from './AddMarketsDialog'
import { MarketCard } from './MarketCard'
import { useAddMarket } from './MarketsPage.hooks'

const BOTTOM_FLOATING_BUTTON_HEIGHT = 72

export const MarketsPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [markets, setMarkets] = useState<
    {
      marketId: string
      title: string
      lastUpdate: string
    }[]
  >([])
  const addMarket = useAddMarket()
  const navigate = useNavigate()

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  const saveMarket = (marketData: SaveMarketData) => {
    setMarkets((prev) => [
      {
        marketId: uuid(),
        title: marketData.marketTitle,
        lastUpdate: new Date().toISOString()
      },
      ...prev
    ])
    addMarket(marketData)
  }

  const changeMarketsOrder = (sortOrder: SortOrder) => identity(sortOrder)
  const changeMarketStatusFilter = (marketStatus: SurveyState) =>
    identity(marketStatus)

  return (
    <Grid
      container
      sx={{
        padding: '32px 64px',
        maxHeight: `calc(100vh - ${MULTI_MARKET_HEADER_HEIGHT}px - ${BOTTOM_FLOATING_BUTTON_HEIGHT}px)`,
        overflow: 'auto'
      }}
    >
      <Grid
        item
        width="100%"
        sx={{
          borderRadius: 1,
          border: '1px solid #E5EAF5',
          padding: 3
        }}
      >
        <Grid container item display="flex" justifyContent="space-between">
          <Grid>
            <Text weight={TextWeight.SemiBold}>Markets</Text>
            <Text size={TextSize.ms}>
              Please add all the markets you intend to survey below. Then
              specify each market&apos;s audience.
            </Text>
          </Grid>
          <Grid item>
            <Button
              onClick={openDialog}
              variant={ButtonVariant.PrimaryFilled}
              startIcon={
                <Icon
                  name={IconName.Add}
                  iconColor={IconColor.White}
                  size={IconSize.Large}
                />
              }
            >
              Add Markets
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={3}
          marginBottom={3}
        >
          <Grid item xs={6}>
            <ProjectListFilters setSelectedFilter={changeMarketStatusFilter} />
          </Grid>
          <Grid container item direction="row" justifyContent="flex-end" xs={6}>
            <Grid item xs="auto">
              <ProjectOrderSelect onChange={changeMarketsOrder} />
            </Grid>
          </Grid>
        </Grid>
        <List>
          {markets.map(({ title, marketId, lastUpdate }) => {
            return (
              <MarketCard
                key={marketId}
                marketId={marketId}
                title={title}
                lastUpdate={dayjs(lastUpdate).format('D MMM YY').toUpperCase()}
                creator="Test User"
                status={SurveyState.Draft}
              />
            )
          })}
        </List>
        <AddMarketsDialog
          open={dialogOpen}
          onSave={saveMarket}
          onClose={closeDialog}
        />
      </Grid>
      <Button
        variant={ButtonVariant.SecondaryFilled}
        disabled={markets.length === 0}
        style={{ position: 'fixed', bottom: 32, right: 64 }}
        onClick={() => navigate('./../mapping')}
      >
        Map audience questions
      </Button>
    </Grid>
  )
}
