import { PureQueryOptions } from '@apollo/client/core'
import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const FIELDWORK_AUDIENCE_V2 = questionnaireGql(/* GraphQL */ `
  query fieldworkAudienceV2($surveyId: ID!) {
    fieldworkAudienceV2(surveyId: $surveyId) {
      surveyId
      createdDate
      sinceDate
      entries {
        number
        position
        contextPosition
        createdDate
        sinceDate
        entryType
        entryItem {
          ... on QuestionItem {
            questionTypeCode
            question {
              questionId
              text
              createdDate
            }
            responseOptions {
              position
              createdDate
              sinceDate
              responseOption {
                responseOptionId
                rotype
                value
                createdDate
              }
              qualification {
                createdDate
                sinceDate
              }
              route {
                sinceDate
                targetNumber
              }
            }
            settingValues {
              code
              value
              createdDate
              sinceDate
            }
          }
        }
      }
      quotas {
        key {
          questionId
          responseOptionId
        }
        percent
        respondentCount
      }
    }
  }
`)

export const fieldworkAudienceRefetchQuery: (
  surveyId: string
) => PureQueryOptions = (surveyId) => {
  return {
    query: FIELDWORK_AUDIENCE_V2,
    context: { clientName: 'questionnaire' },
    variables: { surveyId }
  }
}
