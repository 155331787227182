import {
  ButtonType,
  LinkButton,
  Logo,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import styles from '../Auth.module.css'
import useStyles from '../Auth.styles'

interface Props {
  email: string
  onClickButton: () => void
}
const SignupSuccessful: React.FC<Props> = (props: Props) => {
  const { email, onClickButton } = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid className={styles.page} container justifyContent="center">
      <Grid className={classes.maxWidth}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Logo width={42} type="icon" />
          <h1 className={styles.title}>
            Thank you for signing up with Focaldata.
          </h1>
          <p className={styles.subtitle}>
            We will send a confirmation email to {email} once your account has
            been approved.
          </p>
          <SpinnerButton
            onClick={onClickButton}
            className={classes.button}
            fullWidth
            buttonType={ButtonType.Submit}
          >
            Back to focaldata.com
          </SpinnerButton>
          <Grid
            container
            justifyContent="center"
            className={classes.helperText}
          >
            <Grid className={classes.paddingRight}>
              <p className={classNames(textClasses.default, textClasses.sizeM)}>
                Need help? Contact
              </p>
            </Grid>
            <LinkButton
              size={TextSize.m}
              weight={TextWeight.Bold}
              noPadding
              underlined
            >
              support@focaldata.com
            </LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignupSuccessful
