import { ApolloCache } from '@apollo/client'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../../data/gql/questionnaire/queries'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../data/model/questionnaire'
import { DraftSectionItem } from './Section.model'

type RemovedEntriesContextCountByType = Record<EntryType, number>

const getRemovedEntriesContextCountByType = (
  entries: DraftQuestionnaireEntry[]
): RemovedEntriesContextCountByType => {
  return entries.reduce((countByType, entry) => {
    const { entryType } = entry
    const count = countByType[entryType] ? countByType[entryType] + 1 : 1
    if (
      entryType === EntryType.QuestionEntryType ||
      entryType === EntryType.MatrixEntryType
    ) {
      countByType[EntryType.QuestionEntryType] = count
      countByType[EntryType.MatrixEntryType] = count
    }
    if (entryType === EntryType.TextCardEntryType) {
      countByType[EntryType.TextCardEntryType] = count
    }
    return countByType
  }, {} as RemovedEntriesContextCountByType)
}

interface UpdateCacheOnSectionDeleteParams {
  projectId: string
  surveyId: string
  entryId: string
  includingEntries: boolean
}

export const updateCacheOnSectionDelete = (
  cache: ApolloCache<QuestionnaireData>,
  {
    projectId,
    surveyId,
    entryId,
    includingEntries
  }: UpdateCacheOnSectionDeleteParams
) => {
  const cachedQuestionnaire = cache.readQuery<QuestionnaireData>({
    query: QUESTIONNAIRE,
    variables: {
      projectId,
      surveyId
    }
  })
  const draftQuestionnaire = cachedQuestionnaire?.draftQuestionnaire
  const sectionEntry = draftQuestionnaire?.entries.find(
    (entry) => entry.id === entryId
  )
  if (draftQuestionnaire && sectionEntry) {
    const sectionEntryItem = sectionEntry.entryItem as DraftSectionItem
    const removedEntriesContextCountByType =
      getRemovedEntriesContextCountByType(sectionEntryItem.entries)
    const filteredEntries = draftQuestionnaire.entries.flatMap((entry) => {
      // filter out section including nested entries or only section depending on `includingEntries` value
      if (entry === sectionEntry) {
        return includingEntries
          ? []
          : sectionEntryItem.entries.map((entry) => {
              return {
                ...entry,
                position: entry.position - 1
              }
            })
      }
      // decrease entry position and contextPosition for items which go after deleted one(s)
      if (entry.position > sectionEntry.position) {
        const getNewContextPosition = (): number => {
          if (entry.entryType === EntryType.SectionEntryType) {
            return entry.contextPosition - 1
          }

          if (includingEntries) {
            return (
              entry.contextPosition -
              removedEntriesContextCountByType[entry.entryType]
            )
          }

          return entry.contextPosition
        }
        const getNewPosition = () => {
          if (includingEntries) {
            return entry.position - 1 - sectionEntryItem.entries.length
          }

          return entry.position - 1
        }
        return {
          ...entry,
          contextPosition: getNewContextPosition(),
          position: getNewPosition()
        }
      }
      return entry
    })
    cache.writeQuery({
      query: QUESTIONNAIRE,
      variables: {
        projectId,
        surveyId
      },
      data: {
        draftQuestionnaire: {
          ...draftQuestionnaire,
          entries: filteredEntries
        }
      }
    })
  }
}
