import { useReactiveVar } from '@apollo/client'
import { CSSProperties, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../App.store'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import DragAndDropContextComponent from '../../components/DragAndDrop-react-dnd/DragAndDropContext'
import { SurveyParams } from '../../data/model/surveyParams'
import { audienceQuestionsShownVar } from '../../hooks/localState/useAudienceQuestionsToggleVar'
import RightDrawerContentLayout from '../../layouts/RightDrawerContentLayout'
import SidebarLayout from '../../layouts/SidebarLayout'
import { newEntryId } from '../../utils/questionnaireUtils'
import { BottomNavContainer } from './BottomNav.container'
import { CustomCriteriaQuestionsListContainer } from './CustomCriteriaQuestionsList.container'
import { DialogLooping } from './Looping/DialogLooping/DialogLooping'
import { selectLoopingModalOpen } from './Looping/Looping.slice'
import { PreviewPanelContainer } from './PreviewPanel'
import PreviewButtonAbsolute from './PreviewPanel/PreviewButtonAbsolute.container'
import QuestionnaireContext from './Questionnaire.context'
import { QuestionsListContainer } from './QuestionsList'
import { DialogRandomiseSections } from './SectionsRandomisation/DialogRandomiseSections'
import { RandomiseSectionsHeader } from './SectionsRandomisation/RandomiseSectionsHeader'
import { selectHasSectionsForRandomisation } from './SectionsRandomisation/SectionsRandomisation.slice'
import { SidebarContainer } from './Sidebar'
import { CONTAINER_ID_QUESTIONNAIRE_CONTENT } from './constants'

const Questionnaire = ({
  hideBottomNav,
  height,
  sidebarHeight
}: {
  hideBottomNav?: boolean
  height?: CSSProperties['height']
  sidebarHeight?: CSSProperties['height']
}) => {
  const showAudienceCriteriaQuestions = useReactiveVar(
    audienceQuestionsShownVar
  )

  useEffect(() => {
    questionBeingEditedId(undefined)
    newEntryId(undefined)
  }, [])

  const { surveyId } = useParams<keyof SurveyParams>()
  const { questionnaireState } = useContext(QuestionnaireContext)
  const { isPreviewPanelOpened, isRandomiseSectionsDialogOpened } =
    questionnaireState

  const loopingModalOpen = useAppSelector(selectLoopingModalOpen)

  const hasSectionsForRandomisation = useAppSelector(
    selectHasSectionsForRandomisation
  )

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedSurveyPage, { surveyId })
  }, [surveyId])

  return (
    <>
      <DragAndDropContextComponent>
        <SidebarLayout
          containerIdForScoll={CONTAINER_ID_QUESTIONNAIRE_CONTENT}
          sideBarHeader={
            hasSectionsForRandomisation ? (
              <RandomiseSectionsHeader />
            ) : undefined
          }
          sideBar={<SidebarContainer />}
          content={
            <RightDrawerContentLayout
              isDrawerOpen={isPreviewPanelOpened}
              sideBar={<PreviewPanelContainer />}
              content={
                <>
                  <PreviewButtonAbsolute />
                  {showAudienceCriteriaQuestions && (
                    <CustomCriteriaQuestionsListContainer />
                  )}
                  <QuestionsListContainer />
                </>
              }
              showFooter
            />
          }
          height={height}
          sidebarHeight={sidebarHeight}
        />
        {!hideBottomNav && <BottomNavContainer />}
      </DragAndDropContextComponent>
      {loopingModalOpen ? <DialogLooping /> : null}
      {isRandomiseSectionsDialogOpened && <DialogRandomiseSections />}
    </>
  )
}

export default Questionnaire
