import { gql } from '@apollo/client/core'
import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const TEXT_CARD = questionnaireGql(/* GraphQL */ `
  fragment TextCard on TextCard {
    __typename
    textCardId
    title
    titleStyling
    subtitle
    body
  }
`)

const DRAFT_TEXT_CARD_ITEM = gql`
  fragment DraftTextCardItem on DraftTextCardItem {
    textCardLk
    questionMedia {
      ...QuestionMedia
    }
    textCard {
      ...TextCard
    }
    settingValues {
      code
      value
    }
    questionLogic {
      ...DraftLogicClauseProposition
    }
    __typename
  }
`

export default DRAFT_TEXT_CARD_ITEM
