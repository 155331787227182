import {
  getYouTubeVideoIdFromUrl,
  isValidVimeoURL,
  isYouTubeUrlValid
} from '@focaldata/cin-ui-components'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { MediaType } from '../../model/questionnaire'

interface ImageUrl {
  image_url: string
}

const YOUTUBE_THUMBNAIL_ENDPOINT = 'https://img.youtube.com/vi'
const FULL_SIZE_IMAGE_ARGUMENT = 's0'
const THUMBNAIL_ARGUMENT = 's64'
const VIMEO_OEMBED_JSON_URL = 'https://vimeo.com/api/oembed.json?url='

const getYoutubeThumbnailUrl = (mediaUrl: string) => {
  const videoId = getYouTubeVideoIdFromUrl(mediaUrl)
  return `${YOUTUBE_THUMBNAIL_ENDPOINT}/${videoId}/default.jpg`
}

const getVimeoThumbnailUrl = async (mediaUrl: string): Promise<string> => {
  try {
    const vimeoJson = await axios.get<any>(
      `${VIMEO_OEMBED_JSON_URL}${mediaUrl}`
    )
    return vimeoJson.data.thumbnail_url
  } catch (e) {
    return Promise.reject()
  }
}

export const getImageUrl = async (mediaUrl: string): Promise<string> => {
  try {
    const imageId = mediaUrl.split('/').pop()
    const imageUrl = await axios.get<ImageUrl>(
      `${process.env.REACT_APP_IMAGE_RESIZE_ENDPOINT}${imageId}`
    )
    return imageUrl.data.image_url
  } catch (e) {
    return Promise.reject()
  }
}

const useMediaUrlAndThumbnailUrl = (
  mediaUrl?: string,
  mediaType?: MediaType,
  renderedMediaUrl?: string | null
) => {
  const [resizedMediaUrl, setResizedMediaUrl] = useState<string>()
  const [resizedThumbnailUrl, setResizedThumbnailUrl] = useState<string>()

  useEffect(() => {
    if (renderedMediaUrl) {
      setResizedMediaUrl(`${renderedMediaUrl}=${FULL_SIZE_IMAGE_ARGUMENT}`)
      setResizedThumbnailUrl(`${renderedMediaUrl}=${THUMBNAIL_ARGUMENT}`)
    } else if (mediaUrl && mediaType) {
      switch (mediaType) {
        case MediaType.Image: {
          getImageUrl(mediaUrl)
            .then((data) => {
              setResizedMediaUrl(`${data}=${FULL_SIZE_IMAGE_ARGUMENT}`)
              setResizedThumbnailUrl(`${data}=${THUMBNAIL_ARGUMENT}`)
            })
            .catch(() => {
              setResizedMediaUrl(mediaUrl)
              setResizedThumbnailUrl(mediaUrl)
            })
          break
        }
        case MediaType.Video: {
          setResizedMediaUrl(mediaUrl)
          if (isYouTubeUrlValid(mediaUrl))
            setResizedThumbnailUrl(getYoutubeThumbnailUrl(mediaUrl))
          if (isValidVimeoURL(mediaUrl)) {
            getVimeoThumbnailUrl(mediaUrl)
              .then((data) => {
                setResizedThumbnailUrl(data)
              })
              .catch(() => {
                setResizedThumbnailUrl(mediaUrl)
              })
          }
          break
        }
        default:
      }
    }
  }, [mediaUrl, mediaType, renderedMediaUrl])

  return { resizedMediaUrl, resizedThumbnailUrl }
}

export default useMediaUrlAndThumbnailUrl
