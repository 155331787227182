import { Filters } from '../../../../controls/Filters'
import { useDefaultState } from '../../../../hooks/useDefaultState'
import { useFilters } from './Results.hooks'

import { useMemo } from 'react'
import { SelectedFilter } from '../../../../data/model/results'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import {
  constructSearchableFilterOptions,
  getDefaultFilterOptions,
  getSelectedFilterCount,
  orderSelectedFilterOptions
} from '../../../../utils/resultsUtils'

interface Props {
  selectedFilters: SelectedFilter[]
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void
}

export const ResultsFilters = ({
  selectedFilters,
  setSelectedFilters
}: Props) => {
  const surveyId = useSurveyId()

  const { data: filterOptionsData, loading: loadingFilters } = useFilters({
    surveyId
  })
  const defaultSelectedFilterOptions = useMemo(
    () => getDefaultFilterOptions(filterOptionsData),
    [filterOptionsData]
  )
  const [selectedFilterOptions, setSelectedFilterOptions] = useDefaultState(
    defaultSelectedFilterOptions
  )
  const filterCount = getSelectedFilterCount(selectedFilters)
  const searchableChartEntries =
    constructSearchableFilterOptions(filterOptionsData)
  const entryFilterOptions = orderSelectedFilterOptions(selectedFilterOptions)
  return (
    <Filters
      isLoading={loadingFilters}
      filterCount={filterCount}
      searchableChartEntries={searchableChartEntries}
      entryFilterOptions={entryFilterOptions}
      selectedFilterOptions={selectedFilterOptions}
      setSelectedFilterOptions={setSelectedFilterOptions}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
    />
  )
}
