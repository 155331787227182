import { emptyArr } from '../../../constants/misc'
import { EntrySettingValue } from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'

const useEntrySettingValues = (
  entryNumber: number | undefined
): EntrySettingValue[] => {
  const { flattenedEntries } = useGetDraftQuestionnaire()

  const entryItem = flattenedEntries.find((entry) => {
    return entry.number === entryNumber
  })?.entryItem

  if (entryItem && 'settingValues' in entryItem) {
    return entryItem.settingValues as EntrySettingValue[]
  }

  return emptyArr
}

export default useEntrySettingValues
