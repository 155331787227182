import {
  SnackbarVariant,
  UseSnackbar,
  useSnackbar
} from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction, useState } from 'react'
import DialogRequestData from '../../../controls/DialogRequestData'
import { DataFormat, OutputType } from '../../../data/model/shared'
import useSlackNotification from '../../../hooks/useSlackNotification'

interface Props {
  isDialogDataTablesOpen?: boolean
  setIsDialogDataTablesOpen?: Dispatch<SetStateAction<boolean>>
}

const RequestData: React.FC<Props> = (props: Props) => {
  const { isDialogDataTablesOpen, setIsDialogDataTablesOpen } = props

  const { enqueueSnackbar }: UseSnackbar = useSnackbar()

  const [isConfirmClickedSuccess, setIsConfirmClickedSuccess] =
    useState<boolean>(false)
  const [isConfirmClickedFail, setIsConfirmClickedFail] =
    useState<boolean>(false)
  const { handleRequestData } = useSlackNotification()

  const toggleDialogRequestData = () => {
    if (setIsDialogDataTablesOpen) {
      setIsDialogDataTablesOpen(!isDialogDataTablesOpen)
    }
  }

  const handleRequestDataLocal = async (
    fileFormat: DataFormat,
    outputType: OutputType
  ) => {
    try {
      await handleRequestData(fileFormat, outputType, true)
      setIsConfirmClickedSuccess(true)
    } catch {
      setIsConfirmClickedFail(true)
    } finally {
      toggleDialogRequestData()
    }
  }

  if (isConfirmClickedSuccess) {
    enqueueSnackbar(
      'Your request was submitted successfully. One of the Focaldata team will contact you by email shortly.',
      {
        variant: SnackbarVariant.Success
      }
    )
    setIsConfirmClickedSuccess(false)
  }

  if (isConfirmClickedFail) {
    enqueueSnackbar(
      'Something went wrong. Try again, if the error persists contact support@focaldata.com.',
      {
        variant: SnackbarVariant.Error
      }
    )
    setIsConfirmClickedFail(false)
  }

  return (
    <DialogRequestData
      isDialogDataTablesOpen={isDialogDataTablesOpen}
      toggleDialogRequestData={toggleDialogRequestData}
      handleRequestData={handleRequestDataLocal}
      loading={false}
    />
  )
}

export default RequestData
