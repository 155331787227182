import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const UPDATE_FD_CHAT_SPECIFICATION_V2 = questionnaireGql(/* GraphQL */ `
  mutation updateFdChatSpecificationV2(
    $input: UpdateFdChatSpecificationV2InputInput!
  ) {
    updateFdChatSpecificationV2(input: $input) {
      __typename
      ... on AdHocTestingSpecification {
        __typename
        fdChatSpecificationId
        maxNumberQuestions
        goal
        questions
        themes
        context
        maxLengthOfInterview
      }
    }
  }
`)
