import { useState } from 'react'
import { useInterviewLink } from '../../../../containers/Page/FdChat/FdChat.hooks'
import styles from './InterviewLinkCard.module.css'

const InterviewLinkCard = () => {
  const [copyLinkButtonText, setCopyLinkButtonText] = useState('Copy link')
  const interviewLink = useInterviewLink()

  const copyLink = () => {
    navigator.clipboard.writeText(interviewLink)
    setCopyLinkButtonText('Copied!')

    setTimeout(() => setCopyLinkButtonText('Copy link'), 1000)
  }

  return (
    <div className={styles['interview-link-card']}>
      <h2 className={styles.title}>Share your interview</h2>
      <div className={styles['interview-link-group']}>
        <input
          value={interviewLink}
          disabled
          className={styles['interview-link']}
        />
        <button className={styles['copy-link-button']} onClick={copyLink}>
          {copyLinkButtonText}
        </button>
      </div>
      <p className={styles['copy-link-warning']}>
        ⚠️ Please ensure you copy the link exactly as it is above or use the
        &apos;Copy link&apos; button to add it to your clipboard. ⚠️
      </p>
    </div>
  )
}

export default InterviewLinkCard
