import { Dispatch, ReactNode, createContext, useMemo, useReducer } from 'react'
import { DraftAudience } from '../../data/gql/questionnaire/queries/audiences'

interface AudienceState {
  newAudienceQuestionLk: string | undefined
  audienceMembers: DraftAudience['members']
  validationErrors: DraftAudience['validationErrors']
  isWeighted: boolean | undefined
}

const initialState: AudienceState = {
  newAudienceQuestionLk: undefined,
  audienceMembers: [],
  validationErrors: [],
  isWeighted: false
}

const SET_NEW_AUDIENCE_QUESTION_LK = 'SET_NEW_AUDIENCE_QUESTION_LK' as const
const SET_AUDIENCE_MEMBERS = 'SET_AUDIENCE_MEMBERS' as const
const SET_AUDIENCE_VALIDATION_ERRORS = 'SET_AUDIENCE_VALIDATION_ERRORS' as const
const SET_AUDIENCE_PRESET_WEIGHTING = 'SET_AUDIENCE_PRESET_WEIGHTING' as const

type AudienceAction =
  | {
      type: typeof SET_NEW_AUDIENCE_QUESTION_LK
      payload: AudienceState['newAudienceQuestionLk']
    }
  | {
      type: typeof SET_AUDIENCE_MEMBERS
      payload: AudienceState['audienceMembers']
    }
  | {
      type: typeof SET_AUDIENCE_VALIDATION_ERRORS
      payload: AudienceState['validationErrors']
    }
  | { type: typeof SET_AUDIENCE_PRESET_WEIGHTING; payload: boolean }

export const setAudiencePresetWheighting = (
  isWheighted: boolean
): AudienceAction => {
  return { type: SET_AUDIENCE_PRESET_WEIGHTING, payload: isWheighted }
}

export const setNewAudienceQuestionLk = (
  newAudienceQuestionLk: AudienceState['newAudienceQuestionLk']
): AudienceAction => {
  return { type: SET_NEW_AUDIENCE_QUESTION_LK, payload: newAudienceQuestionLk }
}

export const setAudienceMembers = (
  audienceMembers: AudienceState['audienceMembers']
): AudienceAction => {
  return { type: SET_AUDIENCE_MEMBERS, payload: audienceMembers }
}

export const setAudienceValidationErrors = (
  validationErrors: AudienceState['validationErrors']
): AudienceAction => {
  return { type: SET_AUDIENCE_VALIDATION_ERRORS, payload: validationErrors }
}

const audienceReducer = (
  state: AudienceState,
  { type, payload }: AudienceAction
): AudienceState => {
  const updateStateIfNeeded = (stateField: keyof AudienceState) => {
    return state[stateField] === payload
      ? state
      : {
          ...state,
          [stateField]: payload
        }
  }
  switch (type) {
    case SET_NEW_AUDIENCE_QUESTION_LK: {
      return updateStateIfNeeded('newAudienceQuestionLk')
    }
    case SET_AUDIENCE_MEMBERS: {
      return updateStateIfNeeded('audienceMembers')
    }
    case SET_AUDIENCE_VALIDATION_ERRORS: {
      return updateStateIfNeeded('validationErrors')
    }
    case SET_AUDIENCE_PRESET_WEIGHTING: {
      return updateStateIfNeeded('isWeighted')
    }
    default:
      return state
  }
}

const AudienceContext = createContext<{
  audienceState: AudienceState
  dispatch: Dispatch<AudienceAction>
}>({
  audienceState: initialState,
  dispatch: () => null
})

export const AudienceContextProvider = ({
  children
}: {
  children?: ReactNode
}) => {
  const [audienceState, dispatch] = useReducer(audienceReducer, initialState)
  const value = useMemo(() => ({ audienceState, dispatch }), [audienceState])

  return (
    <AudienceContext.Provider value={value}>
      {children}
    </AudienceContext.Provider>
  )
}

export default AudienceContext
