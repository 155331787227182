import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  iconButtonContainer: {
    width: '100%'
  },
  iconButton: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.background.default
    }
  }
})

export default useStyles
