import { theme } from '@focaldata/cin-ui-components'
import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useStyles from './Highlight.styles'

interface Props {
  text: string
  highlight?: string
  collapsed?: boolean
  characterLimit?: number
}

const Highlight: React.FC<Props> = (props: Props) => {
  const { text, highlight, collapsed, characterLimit = 50 } = props
  const [collapsedText, setCollapsedText] = useState(text)
  const [showMore, setShowMore] = useState(false)
  const { classes } = useStyles()
  const trimmedHighlight = highlight?.trim()

  useEffect(() => {
    if (collapsed) {
      if (showMore) {
        setCollapsedText(text)
      } else {
        setCollapsedText(text.substring(0, characterLimit))
      }
    }
  }, [collapsed, text, characterLimit, showMore])

  if (!trimmedHighlight) {
    return <span>{text}</span>
  }
  const parts: string[] = []
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const highlightLength = trimmedHighlight?.length

  for (let i = 0; i < collapsedText.length; i += 1) {
    const foundIdx = collapsedText // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ?.toLowerCase() // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .indexOf(trimmedHighlight?.toLowerCase(), i)

    if (foundIdx > -1) {
      const part1 = collapsedText.substring(i, foundIdx)
      const part2 = collapsedText.substring(
        foundIdx,
        foundIdx + highlightLength
      )

      i = foundIdx + highlightLength - 1

      parts.push(part1, part2)
    } else {
      parts.push(collapsedText.substring(i))
      i = collapsedText.length
    }
  }

  return (
    <span>
      {parts.map((part, index) => {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return part?.toLowerCase() === trimmedHighlight?.toLowerCase() ? (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ color: theme.palette.primary.main }}
          >
            {part}
          </span>
        ) : (
          part
        )
      })}
      {collapsed && !showMore && text.length > characterLimit && (
        <Tooltip title="Show more" placement="top">
          <span
            className={classes.highlight}
            onClick={(event) => {
              setShowMore(!showMore)
              event.preventDefault()
              event.stopPropagation()
            }}
            aria-hidden="true"
          >
            {' (...)'}
          </span>
        </Tooltip>
      )}
    </span>
  )
}

export default Highlight
