import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudePublicEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { ForgotPasswordSuccessful as ForgotPasswordSuccessfulControl } from '../../../components/Auth'
import { CreateResetPassword } from '../../../data/rest/accountUser'

const ForgotPasswordSuccessful: React.FC = () => {
  const { email = 'no-email-found' } = useParams<{ email: string }>()
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = async () => {
    if (email) {
      setLoading(true)
      await CreateResetPassword(email)
      LogAmplitudePublicEvent(EventType.RequestedResetPassword, { email })
      setLoading(false)
    }
  }

  return (
    <ForgotPasswordSuccessfulControl
      disabled={loading}
      onClickButton={handleClick}
    />
  )
}

export default ForgotPasswordSuccessful
