import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useProjectType } from '../../Project/Project.hooks'
import AudienceSummaryControl from './AudienceSummary.control'
import AudienceSummaryLoading from './AudienceSummaryLoading'

interface Props {
  showTitle?: boolean
  showQuotas?: boolean
}

const AudienceSummary = ({ showTitle, showQuotas = true }: Props) => {
  const { isFdChat } = useProjectType()

  const { draftAudience, loading, error } = useGetDraftAudience()

  if (error) return <div>Error loading audience summary</div>
  if (loading || !draftAudience) return <AudienceSummaryLoading />

  return (
    <AudienceSummaryControl
      audiences={draftAudience.members}
      validationErrors={draftAudience.validationErrors}
      showTitle={showTitle}
      showQuotas={showQuotas}
      emptySubtitle={isFdChat ? '' : undefined}
      emptyDescription={
        isFdChat
          ? 'No questions added. All qualifying questions and responses will appear here.'
          : undefined
      }
    />
  )
}

export default AudienceSummary
