import { QueryHookOptions, useQuery } from '@apollo/client'
import { FieldworkV2Query } from '../data/gql-gen/questionnaire/graphql'
import { GET_FIELDWORK } from '../data/gql/questionnaire/queries/fieldwork'
import { LoggerErrorType } from '../data/logger'
import { captureApolloError } from '../utils/HelperFunctions'
import { useSurveyId } from './useSurveyId'

export const useFieldwork = (options?: QueryHookOptions) => {
  const surveyId = useSurveyId()

  const { data, loading, error, refetch } = useQuery<FieldworkV2Query>(
    GET_FIELDWORK,
    {
      context: { clientName: 'questionnaire' },
      variables: {
        surveyId
      },
      onError: (error) => {
        captureApolloError(LoggerErrorType.ApolloQuery, 'fieldwork', error)
      },
      ...options
    }
  )

  return {
    fieldwork: data?.fieldwork,
    loading,
    error,
    refetch
  }
}
