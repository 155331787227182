import { Grid } from '@focaldata/cin-ui-components'
import { LoopBasisSelector } from '../LoopBasisSelector'
import { LoopIterationsSelector } from '../LoopIterationsSelector'
import { LoopingResponseOptions } from '../LoopingResponseOptions'
import useStyles from './DialogLooping.styles'

export const LoopingMultiSelectContent = () => {
  const { classes } = useStyles()

  return (
    <Grid className={classes.configContainer}>
      <LoopingResponseOptions />
      <Grid
        container
        alignItems="center"
        className={classes.iterationAndLoopBasis}
      >
        <p className={classes.marginRight8}>Present</p>
        <LoopIterationsSelector />
        <p className={classes.marginRight8}>of total loops based on</p>
        <LoopBasisSelector />
      </Grid>
    </Grid>
  )
}
