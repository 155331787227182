import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SurveyParams } from '../../../data/model/surveyParams'
import { DialogPublishResultsControl } from './DialogPublishResults.control'
import {
  usePublishedState,
  useSurveyPublish
} from './DialogPublishResults.hooks'

interface Props {
  isDialogOpen: boolean
  onClose: () => void
}

const PUBLIC_PATH = '/public/'

const DialogPublishResultsContainer: React.FC<Props> = ({
  isDialogOpen,
  onClose
}: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const [loading, setLoading] = useState<boolean>(false)
  const [publishError, setPublishError] = useState<boolean>(false)
  const { publishSurvey, unpublishSurvey } = useSurveyPublish({ surveyId })
  const { loading: loadingPublishedState, data: publishedStateData } =
    usePublishedState({ surveyId })
  const [isInternalPublished, setIsInternalPublished] = useState<boolean>(false)
  const [publicLink, setPublicLink] = useState<string>('')

  useEffect(() => {
    if (publishedStateData) {
      setIsInternalPublished(publishedStateData.isPublished)
      if (publishedStateData.isPublished)
        setPublicLink(
          `${process.env.REACT_APP_DASHBOARD_DOMAIN}${PUBLIC_PATH}${publishedStateData.publicSurveyId}`
        )
    }
  }, [publishedStateData])

  const handleTogglePublish = async (published: boolean): Promise<void> => {
    setLoading(true)
    if (published === true) {
      const publicSurveyId = await publishSurvey()
      setLoading(false)
      if (publicSurveyId !== undefined) {
        setPublicLink(
          `${process.env.REACT_APP_DASHBOARD_DOMAIN}${PUBLIC_PATH}${publicSurveyId}`
        )
        setIsInternalPublished(true)
      } else {
        setPublishError(true)
      }
    } else {
      const isSuccess = await unpublishSurvey()
      setLoading(false)
      if (!isSuccess) {
        setPublishError(true)
      }
      setIsInternalPublished(false)
    }
  }

  return (
    <DialogPublishResultsControl
      isDialogOpen={isDialogOpen}
      publishing={loading}
      error={publishError}
      loadingData={loadingPublishedState}
      publicLink={publicLink}
      isSurveyPublished={isInternalPublished}
      onClose={onClose}
      onTogglePublish={handleTogglePublish}
    />
  )
}

export default DialogPublishResultsContainer
