import { useContext, useMemo } from 'react'
import {
  CustomCriterionValidationError,
  CustomOptionValidationError
} from '../../data/gql-gen/fieldwork/graphql'
import {
  CriterionValidationErrors,
  CustomCriterionValidationErrors,
  StandardCriterionOptionValidationErrors,
  StandardCriterionValidationError,
  StandardCriterionValidationErrors,
  StandardOptionValidationError
} from '../../data/model/fieldwork'
import AudienceContext from '../../modules/Audience/Audience.context'
import useQuestionnaireValidation from '../questionnaire/useQuestionnaireValidation'

const standardCriterionValidationErrorMap: Map<
  StandardCriterionValidationError,
  boolean
> = new Map<StandardCriterionValidationError, boolean>([
  [StandardCriterionValidationError.InvalidQuotaSumError, true],
  [StandardCriterionValidationError.MissingQualificationError, true],
  [StandardCriterionValidationError.MissingQualifyingSettingWarning, false],
  [StandardCriterionValidationError.MissingQuotasSettingWarning, false]
])

const standardOptionValidationErrorMap: Map<
  StandardCriterionOptionValidationErrors['errors'][number],
  boolean
> = new Map<StandardOptionValidationError, boolean>([
  [StandardOptionValidationError.InvalidQualificationError, true],
  [StandardOptionValidationError.QuotaIsZeroError, true],
  [StandardOptionValidationError.AgeRangeOverlapError, true],
  [
    StandardOptionValidationError.InvalidCustomizedCriterionOptionCodeError,
    true
  ],
  [StandardOptionValidationError.MinAgeBiggerThanMaxError, true],
  [StandardOptionValidationError.AgeBandsGapError, true]
])

const customCriterionValidationErrors = new Set([
  CustomCriterionValidationError.EmptyTextError,
  CustomCriterionValidationError.InvalidCustomQuotaSumError,
  CustomCriterionValidationError.MissingCustomQualificationError,
  CustomCriterionValidationError.OptionCountLessThan2Error,
  CustomCriterionValidationError.InvalidResponseOptionsToDisplayError,
  CustomCriterionValidationError.SectionNotFoundError
])

const customOptionValidationErrors = new Set([
  CustomOptionValidationError.EmptyValueError,
  CustomOptionValidationError.ResponseOptionNotExemptOrMappedError
])

const isSpecificAudienceInvalid: (
  validationErrors: CriterionValidationErrors,
  newAudienceLk: string | undefined
) => boolean = (validationErrors, newAudienceLk) => {
  const standard = validationErrors as StandardCriterionValidationErrors
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    standard.standardCriterionErrors &&
    standard.standardCriterionErrors.some((er) =>
      standardCriterionValidationErrorMap.get(er)
    )
  ) {
    return true
  }
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    standard.standardCriterionOptionsErrors &&
    standard.standardCriterionOptionsErrors.some((er) =>
      er.errors.some(
        (er2) =>
          standardOptionValidationErrorMap.get(er2) ||
          er2.startsWith('MinAllowedAgeError') ||
          er2.startsWith('MaxAllowedAgeError')
      )
    )
  ) {
    return true
  }

  const custom = validationErrors as CustomCriterionValidationErrors
  if (custom.questionLk === newAudienceLk) {
    return false
  }
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    custom.customCriterionErrors &&
    custom.customCriterionErrors.some((er) =>
      customCriterionValidationErrors.has(er)
    )
  ) {
    return true
  }
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    custom.customCriterionOptionsErrors &&
    custom.customCriterionOptionsErrors.some((er) =>
      er.errors.some((er2) => customOptionValidationErrors.has(er2))
    )
  ) {
    return true
  }

  return false
}

interface UseAudienceValidationVars {
  validationErrors: CriterionValidationErrors[] | undefined
}

// TODO: this actually has to return a list of codes that have errors
interface UseAudienceValidationData {
  isAudienceInvalid: boolean | undefined
  surveyAudienceInvalidQuestions: string[]
}

const useAudienceValidation: (
  vars: UseAudienceValidationVars
) => UseAudienceValidationData = (vars) => {
  const { audienceState } = useContext(AudienceContext)
  const { newAudienceQuestionLk } = audienceState
  const { hasUnclearedValidationErrors } = useQuestionnaireValidation()

  const surveyAudienceInvalidQuestions = useMemo(
    () =>
      vars.validationErrors
        ? vars.validationErrors
            .filter((ve) =>
              isSpecificAudienceInvalid(ve, newAudienceQuestionLk)
            )
            .map((ve) => {
              const { code } = ve as StandardCriterionValidationErrors
              const { questionLk } = ve as CustomCriterionValidationErrors
              return code || questionLk
            })
        : [],
    [vars.validationErrors, newAudienceQuestionLk]
  )

  const isAudienceInvalid =
    surveyAudienceInvalidQuestions.length > 0 ||
    hasUnclearedValidationErrors().invalidAudience

  return {
    isAudienceInvalid,
    surveyAudienceInvalidQuestions
  }
}

export default useAudienceValidation
