import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../App.store'
import { QuestionSettingCode } from '../../../data/model/questionnaire'
import useHandleMultiSelectLimits from '../../../hooks/questionnaire/useHandleIntervalSettingsChange'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import {
  WithEntry,
  checkIfMatrixMultipleChoice,
  getQuestionSettings
} from '../../../utils/questionnaireUtils'
import MultiSelectChoiceLimit from '../BasicQuestion/QuestionCardFooter/MultiSelectChoiceLimit'
import { useCreateResponseOption } from '../BasicQuestion/ResponseOptions/ResponseOptions.hooks'
import QuestionCardFooter from '../QuestionCardFooter'
import {
  responseOptionAdded,
  selectResponseOptionsByQuestion,
  selectSettingsByQuestionId
} from '../Questionnaire.slice'
import { getEntryId } from '../Questionnaire.utils'
import { scaleConfig } from '../constants'

const MatrixQuestion: React.FC<WithEntry> = (props: WithEntry) => {
  const { entry } = props
  const matrixTitleLk = getEntryId(entry)
  const dispatch = useAppDispatch()
  const createResponseOption = useCreateResponseOption(entry.id)

  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id)

  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const responseOptions = responseOptionsByQuestion[matrixTitleLk]

  const { handleIntervalSettingsChange, handleQuestionLimitChange } =
    useHandleMultiSelectLimits(entry)

  const handleAddScale = async () => {
    await updateQuestionnaireEntry({
      replaceResponseOptions: scaleConfig.Agree1To5
    })
  }

  const handleChangeChoiceLimit: (settingValue: number) => void = useCallback(
    (settingValue) =>
      handleQuestionLimitChange(QuestionSettingCode.ChoiceLimit, settingValue),
    [handleQuestionLimitChange]
  )

  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) =>
      selectSettingsByQuestionId(state, matrixTitleLk)
    ) || []

  const isMultipleChoice = checkIfMatrixMultipleChoice(settingValues)
  const questionSettings = getQuestionSettings(settingValues)
  const choiceLimitValue = questionSettings.get(QuestionSettingCode.ChoiceLimit)
  const choiceLimit = choiceLimitValue && parseInt(choiceLimitValue, 10)

  const choiceIntervalMinValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMin
  )
  const choiceIntervalMin =
    choiceIntervalMinValue && parseInt(choiceIntervalMinValue, 10)

  const choiceIntervalMaxValue = questionSettings.get(
    QuestionSettingCode.ChoiceIntervalMax
  )
  const choiceIntervalMax =
    choiceIntervalMaxValue && parseInt(choiceIntervalMaxValue, 10)

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const matrixResponseOptionsCount = responseOptions?.length

  return (
    <QuestionCardFooter
      entry={entry}
      onClickAddResponseOption={() => {
        dispatch(
          responseOptionAdded({
            questionLk: matrixTitleLk,
            position: responseOptions.length
          })
        )
        createResponseOption(responseOptions.length)
      }}
      addButtonText="Add response"
      showAddScale
      onAddScale={handleAddScale}
      multiSelectChoiceLimit={
        isMultipleChoice ? (
          <MultiSelectChoiceLimit
            entry={entry}
            responseOptionsCount={matrixResponseOptionsCount}
            choiceLimit={choiceLimit}
            choiceIntervalMin={choiceIntervalMin}
            choiceIntervalMax={choiceIntervalMax}
            onChangeChoiceLimit={handleChangeChoiceLimit}
            onChangeIntervalRange={handleIntervalSettingsChange}
          />
        ) : undefined
      }
    />
  )
}

export default MatrixQuestion
