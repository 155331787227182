import amplitude from 'amplitude-js'
import uuid from 'uuid-random'

export const SetUserId = () => {
  // the userId should just be a random Guid unless we can set an actual email
  amplitude.getInstance().setUserId(uuid())
}

export const LogPublicAmplitudeEvent = (event: string, data?: any) => {
  try {
    if (
      process.env.REACT_APP_AMPLITUDE_API_KEY &&
      process.env.REACT_APP_AMPLITUDE_TRACK_ME
    ) {
      if (!amplitude.getInstance().options.userId) {
        SetUserId()
      }

      amplitude.getInstance().logEvent(event, data)
    }
  } catch {
    // TODO: test if this can fail for any reason
    // This is logging and shouldn't prevent anything from happening if it fails for any reason
  }
}
