import { Navigate, useLocation } from 'react-router-dom'
import { useGetProjectId } from '../../modules/Project/Project.hooks'

export const RedirectToProjectPath = () => {
  const { pathname } = useLocation()
  const projectId = useGetProjectId()

  if (!projectId) {
    return null
  }

  return <Navigate to={`/project/${projectId}${pathname}`} replace />
}
