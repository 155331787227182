import { Grid, Skeleton, textStyleUtils } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import useSubheaderSizing from '../../../../hooks/useSubheaderSizing'
import useStyles from './PublicResultsFieldworkDetails.styles'

const PublicResultsFieldworkDetailsLoading: React.FC = () => {
  const { isSubheaderCondensed, subheaderRightSideMaxWidth } =
    useSubheaderSizing({ isPublic: true })
  const { classes } = useStyles({
    styleProps: {
      width: subheaderRightSideMaxWidth
    }
  })
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.container}
    >
      <>
        <Grid item xs="auto">
          <Grid container item direction="row">
            {!isSubheaderCondensed && (
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.marginRight,
                  textClasses.displayInline
                )}
              >
                Country:{' '}
              </p>
            )}

            <Skeleton width={20} height={21} />
          </Grid>
        </Grid>
        <Grid item>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.dividerFielderwork}
          />
        </Grid>
      </>
      <Grid item xs="auto">
        <Grid container item direction="row">
          {!isSubheaderCondensed && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight,
                textClasses.alignCenter
              )}
            >
              Fieldwork:{' '}
            </p>
          )}
          <Skeleton width={160} height={21} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PublicResultsFieldworkDetailsLoading
