import { Badge, Status, Variant, theme } from '@focaldata/cin-ui-components'
import { getETADisplay } from '.'
import { DEFAULT_INCIDENCE_RATE } from '../../../constants/feasibility'

interface Props {
  wantedCompletes: number
  collectedCompletes: number
  incidenceRate: number
  nonUK: boolean
}

const ETABadgeContainer: React.FC<Props> = (props: Props) => {
  const {
    wantedCompletes,
    collectedCompletes,
    incidenceRate = DEFAULT_INCIDENCE_RATE,
    nonUK = false
  } = props

  // calculate the percentage
  const collectedCompletesPercentage =
    (collectedCompletes / wantedCompletes) * 100

  // get the remaining samples
  const remainingWantedCompletes = wantedCompletes - collectedCompletes

  const etaDaysHours = getETADisplay(
    collectedCompletesPercentage,
    remainingWantedCompletes,
    incidenceRate,
    nonUK
  )

  const etaLabel = etaDaysHours ? `ETA < ${etaDaysHours}` : ''

  return etaLabel ? (
    <Badge
      variant={Variant.filled}
      label={etaLabel}
      status={Status.LIVE}
      style={{ marginLeft: theme.spacing(2) }}
    />
  ) : null
}

export default ETABadgeContainer
