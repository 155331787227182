import { useFragment } from '@apollo/client'
import { useContext } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { DraftSectionItem } from '../../data/gql-gen/questionnaire/graphql'
import { DRAFT_SECTION_ITEM } from '../../data/gql/questionnaire/fragments/draftSectionItem'
import { QUESTIONNAIRE } from '../../data/gql/questionnaire/queries'
import { useRemoveQuestionnaireEntries } from '../../hooks/questionnaire/useRemoveQuestionnaireEntries'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import QuestionnaireContext, {
  closeDeleteSectionDialog
} from '../../modules/Questionnaire/Questionnaire.context'
import { deleteQuestionTransactionDatadog } from '../../tracking/perf/transactions'
import { updateCacheOnSectionDelete } from './Section.utils'

const useDeleteSection = (
  entryId: string
): {
  deleteSectionWithoutQuestions: () => void
  deleteSectionAndQuestions: () => void
} => {
  const removeQuestionnaireEntries = useRemoveQuestionnaireEntries()
  const { dispatch } = useContext(QuestionnaireContext)
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { data: sectionItem, complete } = useFragment<DraftSectionItem>({
    fragment: DRAFT_SECTION_ITEM,
    fragmentName: 'DraftSectionItem',
    from: { __typename: 'DraftSectionItem', sectionId: entryId },
    canonizeResults: true
  })

  const deleteSection = async (includingEntries: boolean) => {
    dispatch(closeDeleteSectionDialog())
    deleteQuestionTransactionDatadog.start()
    const sectionEntriesIds = complete
      ? sectionItem.entries.map((entry) => entry.id)
      : []

    await removeQuestionnaireEntries(
      includingEntries ? [entryId, ...sectionEntriesIds] : [entryId],
      {
        refetchQueries: [QUESTIONNAIRE],
        onCompleted: () => {
          LogAmplitudeEvent(EventType.DeletedSection, {
            projectId,
            surveyId,
            entryId,
            includingEntries
          })
        },
        update: (cache) =>
          updateCacheOnSectionDelete(cache, {
            projectId,
            surveyId,
            entryId,
            includingEntries
          })
      }
    )
  }

  return {
    deleteSectionWithoutQuestions: () => deleteSection(false),
    deleteSectionAndQuestions: () => deleteSection(true)
  }
}

export { useDeleteSection }
