import {
  IconButton,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import {
  ChangeEvent,
  ClipboardEvent,
  memo,
  useEffect,
  useRef,
  useState
} from 'react'
import { DraftCombinedResponseOption } from '../../../../../data/gql-gen/local/graphql'
import { useStyles } from './MappingResponseOption.styles'

export const MappedResponseOption = memo(function MappedResponseOption({
  responseOption,
  index,
  cannotDelete = false,
  onDelete,
  onChange,
  onPaste,
  onEnter
}: {
  responseOption: DraftCombinedResponseOption
  index: number
  cannotDelete?: boolean
  onDelete: (id: string) => void
  onChange: (value: string, id: string) => void
  onPaste: ({
    id,
    insertAt,
    updatedValue,
    newValues
  }: {
    id: string
    insertAt: number
    updatedValue: string
    newValues: string[]
  }) => void
  onEnter: () => void
}) {
  const { value, id, mappingNumber } = responseOption
  const { classes, cx: classNames } = useStyles()
  const { classes: textStyleClasses } = textStyleUtils.useTextStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
    if (!value) {
      inputRef.current?.focus()
    }
  }, [value])

  const handleResponseOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputValue(value)
    onChange(value, id)
  }

  const handleResponseOptionPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { selectionStart, selectionEnd } = inputRef.current ?? {}
    const clipboardText = e.clipboardData.getData('text').trim()
    const [firstLine, ...restLines] = clipboardText.split('\n')
    const updatedValue = inputValue
      .slice(0, selectionStart ?? 0)
      .concat(firstLine)
      .concat(inputValue.slice(selectionEnd ?? 0))
    onPaste({
      id,
      insertAt: index + 1,
      updatedValue,
      newValues: restLines
    })
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnter()
    }
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <div className={classes.root}>
      {
        <span
          className={classNames(
            textStyleClasses.default,
            textStyleClasses.sizeMs,
            classes.mappingNumber,
            classes.cursorDefault
          )}
        >
          {mappingNumber}
        </span>
      }
      <input
        ref={inputRef}
        data-testid="mapped-response-option-input"
        className={classes.responseOption}
        placeholder="Type a response option"
        value={inputValue}
        onChange={handleResponseOptionChange}
        onPaste={handleResponseOptionPaste}
        onKeyUp={handleKeyUp}
      />
      {cannotDelete ? null : (
        <IconButton
          smallHoverShadowSize
          ariaLabel="Delete response option"
          iconName={IconName.DeleteOutline}
          iconSize={IconSize.Medium}
          onClick={handleDelete}
        />
      )}
    </div>
  )
})
