import { useMutation } from '@apollo/client'
import { Dialog, DialogMaxWidth } from '@focaldata/cin-ui-components'
import { produce } from 'immer'
import React, { Dispatch, SetStateAction } from 'react'
import {
  DeleteProjectMutationVariables,
  Project,
  Survey
} from '../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../data/logger'
import { captureApolloError } from '../../../utils/HelperFunctions'
import UserSession from '../../../utils/UserSession'
import { DELETE_PROJECT } from '../Project.mutations'
import { SearchProjects } from '../Project.query'
import DialogDeleteProject from './DialogDeleteProject'

interface Props {
  isDialogDeleteOpen: boolean
  setIsDialogDeleteOpen: Dispatch<SetStateAction<boolean>>
  selectedProject: Project | undefined
}

const DeleteProject: React.FC<Props> = (props: Props) => {
  const { isDialogDeleteOpen, setIsDialogDeleteOpen, selectedProject } = props
  const projectName = selectedProject?.name

  const toggleDeleteDialog = () => {
    setIsDialogDeleteOpen(!isDialogDeleteOpen)
  }

  const [deleteProject] = useMutation<void, DeleteProjectMutationVariables>(
    DELETE_PROJECT,
    {
      context: { clientName: 'dashboard' },
      update: (cache) => {
        cache.modify({
          fields: {
            searchProjects(existing) {
              const survey: Survey | undefined = selectedProject?.surveys[0]
              if (survey) {
                const searchProjectsCache = existing as SearchProjects
                const index: number =
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  searchProjectsCache.projects.findIndex(
                    (proj: Project) =>
                      proj.projectId === selectedProject?.projectId
                  ) ?? -1
                if (index > -1) {
                  return produce(searchProjectsCache, (draft) => {
                    draft.projects.splice(index, 1)
                    draft.header.total -= 1
                  })
                }
              }

              return existing
            }
          }
        })
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'deleteProject',
          error
        )
      }
    }
  )

  const handleDeleteProject = (project: Project) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (project) {
      deleteProject({
        variables: {
          projectId: project.projectId,
          accountId: UserSession.getAccountId()
        }
      })
      setIsDialogDeleteOpen(false)
    }
  }

  return (
    <Dialog
      open={isDialogDeleteOpen}
      onClose={toggleDeleteDialog}
      primaryButtonClick={() => {
        return selectedProject && handleDeleteProject(selectedProject)
      }}
      primaryButtonText="Delete"
      title={`Delete the "${projectName}" project?`}
      maxWidth={DialogMaxWidth.Lg}
      onClickOutside={toggleDeleteDialog}
    >
      <DialogDeleteProject projectType={selectedProject?.projectType} />
    </Dialog>
  )
}

export default DeleteProject
