import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    boxSizing: 'border-box',
    border: '1px dashed',
    borderColor: theme.palette.text.disabled,
    cursor: 'pointer'
  },
  containerError: {
    border: '1px dashed',
    borderColor: theme.palette.primary.main
  },
  containerDropping: {
    border: '1px solid',
    borderColor: theme.palette.text.disabled
  },
  validImageContainer: {
    height: '80px',
    border: `1px solid ${theme.palette.text.disabled}`,
    minWidth: '560px',
    marginTop: theme.spacing(4)
  },
  statsContainer: {
    marginLeft: theme.spacing(2)
  },
  nameContainer: {
    overflowWrap: 'break-word'
  },
  errorContainer: {
    margin: theme.spacing(2, 0, -1, -1)
  },
  dropIcon: {
    marginBottom: theme.spacing(-1)
  },
  sizingContainer: {
    height: '180px',
    minWidth: '560px',
    margin: theme.spacing(-1),
    marginTop: theme.spacing(5)
  }
}))

export default useStyles
