import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  selectAudienceContainer: {
    padding: theme.spacing(6, 4, 0, 4),
    marginBottom: theme.spacing(5)
  },
  divider: {
    margin: theme.spacing(2.5, 0)
  },
  customiseAudienceContainer: {
    margin: theme.spacing(-1, 0, -0.5, 0)
  },
  leftContainer: {
    paddingRight: theme.spacing(2)
  },
  rightContainer: {
    paddingLeft: theme.spacing(2)
  },
  countryPicker: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5)
  },
  presetAudiencePicker: {
    padding: theme.spacing(1)
  }
})

export default useStyles
