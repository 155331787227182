import { useMutation } from '@apollo/client'
import {
  CreateAudienceMemberV2Mutation,
  CreateAudienceMemberV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import { CREATE_AUDIENCE_MEMBER_V2 } from '../../data/gql/questionnaire/mutations/createAudienceMemberV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { categoriesRefetchQuery } from '../../data/gql/questionnaire/queries/categories'
import { customCriteriaRefetchQuery } from '../../data/gql/questionnaire/queries/customCriteria'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useCreateAudienceMemberV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [createAudienceMemberV2] = useMutation<
    CreateAudienceMemberV2Mutation,
    CreateAudienceMemberV2MutationVariables
  >(CREATE_AUDIENCE_MEMBER_V2, {
    context: { clientName: 'questionnaire' },
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createAudienceMemberV2',
        error
      )
    },
    refetchQueries: [
      draftAudienceRefetchQuery(projectId, surveyId),
      customCriteriaRefetchQuery(projectId, surveyId),
      categoriesRefetchQuery(projectId, surveyId)
    ]
  })

  return createAudienceMemberV2
}
