import { theme } from '@focaldata/cin-ui-components'
import { Chart } from 'chart.js'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { BarChartData } from '../Chart.model'
import {
  calculateGroupedChartHeight,
  formatTooltipLabelGroupedChart,
  getBarColor,
  getDefaultOptions
} from '../Chart.utils'

interface Props extends React.HTMLAttributes<HTMLElement> {
  data: BarChartData
}

const GroupedChart: React.FC<Props> = (props: Props) => {
  const { data }: Props = props

  const height = calculateGroupedChartHeight(
    data.labels.length,
    data.datasets.length
  )

  const percentagesReponses = data.datasets
    .map((dataset) => dataset.data)
    .flatMap((percent) => percent)

  const maxBarValue = Math.max(...percentagesReponses)

  const options = getDefaultOptions(theme, maxBarValue)

  if (options.datasets.bar) {
    // this is needed to make spacing between bars and groups of bars
    options.datasets.bar.barThickness = 'flex'
    options.datasets.bar.barPercentage = 0.8
    options.datasets.bar.categoryPercentage = 0.8
  }

  options.indexAxis = 'y'
  options.plugins = {
    ...options.plugins,
    datalabels: {
      ...options.plugins.datalabels,
      color: theme.palette.text.primary,
      anchor: 'end',
      align: 'right'
    },
    tooltip: {
      ...options.plugins.tooltip,
      callbacks: {
        ...options.plugins.tooltip?.callbacks,
        label: formatTooltipLabelGroupedChart
      },
      axis: 'y',
      mode: 'index'
    }
  }

  const dataWithColor = {
    ...data,
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    datasets: data?.datasets.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: getBarColor(index),
        hoverBackgroundColor: getBarColor(index),
        totalSamples: data.totalSamples,
        completesPerRow: data.completesPerRow
      }
    })
  }

  Chart.register(ChartDataLabels)

  return (
    <Bar
      height={height}
      data={dataWithColor}
      options={options}
      updateMode="resize"
    />
  )
}

export default GroupedChart
