import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const DEFAULT_RETRY_DELAY = 500
const DEFAULT_RETRY_COUNT = 5
const DELAY_EXPONENT = 1.05

const requestWithRetry = ({
  retryCount = DEFAULT_RETRY_COUNT,
  payload,
  getConfig,
  method,
  url,
  retryDelay = DEFAULT_RETRY_DELAY
}: {
  retryCount?: number
  payload?: any
  getConfig?: () => AxiosRequestConfig
  method: 'get' | 'post'
  url: string
  retryDelay?: number
}): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const getParams: [string, AxiosRequestConfig | undefined] = [
      url,
      getConfig?.()
    ]
    const postParams: [string, any, AxiosRequestConfig | undefined] = [
      url,
      payload,
      getConfig?.()
    ]
    const request =
      method === 'get'
        ? axios[method](...getParams)
        : axios[method](...postParams)

    return request.then(resolve).catch((e) => {
      if (retryCount === 0) {
        reject(e)
      } else {
        window.setTimeout(() => {
          requestWithRetry({
            retryCount: retryCount - 1,
            payload,
            getConfig,
            method,
            url,
            retryDelay: retryDelay ** DELAY_EXPONENT
          })
            .then(resolve)
            .catch(reject)
        }, retryDelay)
      }
    })
  })
}

export default requestWithRetry
