import { Grid } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'

import useStyles from './styles'

interface Props {
  leftChild?: JSX.Element
  middleChild?: JSX.Element
  rightChild?: JSX.Element
  maxWidth?: number | 'max-content'
  maxWidthPercent?: string
  height?: number
  justifyContent?: 'flex-start' | 'flex-end'
  showDividers?: boolean
  gap?: string
}

const ThreeChildLayout: React.FC<Props> = (props: Props) => {
  const {
    leftChild,
    rightChild,
    middleChild,
    maxWidth,
    justifyContent,
    showDividers = false,
    maxWidthPercent,
    height,
    gap
  } = props
  const { classes } = useStyles({
    styleProps: { maxWidth, maxWidthPercent, height }
  })

  return (
    <Grid
      container
      direction="row"
      justifyContent={justifyContent}
      alignItems="center"
      className={classes.layoutStyle}
      gap={gap}
    >
      <Grid item xs="auto">
        {leftChild}
      </Grid>
      {showDividers && (
        <Divider orientation="vertical" className={classes.divider} />
      )}
      <Grid item xs="auto">
        {middleChild}
      </Grid>
      {showDividers && (
        <Divider orientation="vertical" className={classes.divider} />
      )}
      <Grid item xs="auto">
        {rightChild}
      </Grid>
    </Grid>
  )
}

export default ThreeChildLayout
