import { Paragraph } from 'docx'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { getQuestionSettings } from '../questionnaireUtils'
import { addMediaIfNeeded } from './addMediaUrlIfNeeded'

export const createEntryContentFreeText = async (
  entry: DraftQuestionnaireEntry
) => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const paragraphs: Paragraph[] = []
  const questionSettings = getQuestionSettings(entryItem.settingValues)
  let inputType = 'free text'

  switch (questionSettings.get(QuestionSettingCode.ContentStructure)) {
    case SettingValue.Numeric1:
    case SettingValue.Numeric2:
    case SettingValue.Numeric3:
      inputType = 'number'
      break
    case SettingValue.PostCode:
      inputType = 'postal code'
      break
    case SettingValue.Email:
      inputType = 'email'
      break
    default:
      break
  }

  paragraphs.push(new Paragraph(`Input type: ${inputType}`))

  if (inputType === 'free text') {
    const characterLimit = questionSettings.get(
      QuestionSettingCode.CharacterLimit
    )

    if (characterLimit) {
      paragraphs.push(new Paragraph(`Character limit: ${characterLimit}`))
    }
  }

  await addMediaIfNeeded(entryItem, paragraphs)

  return paragraphs
}
