import { useFragment, useReactiveVar } from '@apollo/client'
import debounce from 'lodash/debounce'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import { MATRIX_TITLE } from '../../../../data/gql/questionnaire/fragments/draftMatrixItem'
import { LoggerErrorType } from '../../../../data/logger'
import { questionCardEmptiedVar } from '../../../../hooks/questionnaire/useEmptyQuestionCard'
import { useUpdateQuestionnaireEntry } from '../../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { CardTitleControl } from '../../CardTitle'
import { getTextFromRawTitleStyling } from '../../CardTitle/CardTitle.utils'
import { pipingMarkerAddedVar } from '../../PipingDialog/PipingDialog.hooks'

interface Props {
  matrixTitleLk: string
  ariaLabel: string
  shouldTitleInputFocus?: boolean
  hasError?: boolean
  placeholder?: string
  helperText?: string
}

const MatrixCardTitleContainer = ({
  matrixTitleLk,
  ariaLabel = 'Question header input',
  shouldTitleInputFocus,
  hasError,
  placeholder = 'Type your question',
  helperText = 'Please add a question title'
}: Props) => {
  const { updateQuestionnaireEntry } =
    useUpdateQuestionnaireEntry(matrixTitleLk)

  const { data: matrixTitleData } = useFragment({
    fragment: MATRIX_TITLE,
    fragmentName: 'MatrixTitle',
    from: { __typename: 'MatrixTitle', matrixTitleId: matrixTitleLk },
    canonizeResults: true
  })

  const [titleStyling, setTitleStyling] = useState(matrixTitleData.titleStyling)

  const pipingMarkerAdded = useReactiveVar(pipingMarkerAddedVar)
  const questionCardEmptied = useReactiveVar(questionCardEmptiedVar)

  useEffect(() => {
    if (pipingMarkerAdded[matrixTitleLk]) {
      pipingMarkerAddedVar({ [matrixTitleLk]: false })
      setTitleStyling(matrixTitleData.titleStyling)
    } else if (questionCardEmptied[matrixTitleLk]) {
      questionCardEmptiedVar({ [matrixTitleLk]: false })
      setTitleStyling(matrixTitleData.titleStyling)
    }
  }, [
    matrixTitleData.titleStyling,
    matrixTitleLk,
    pipingMarkerAdded,
    questionCardEmptied
  ])

  const handleUpdateMatrixTitle = useCallback(
    (rawTitleStyling: string): void => {
      updateQuestionnaireEntry(
        {
          updateMatrixQuestion: {
            title: getTextFromRawTitleStyling(rawTitleStyling),
            titleStyling: rawTitleStyling
          }
        },
        {
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'updateMatrixQuestion',
              error
            )
          }
        }
      )
    },
    [updateQuestionnaireEntry]
  )

  const handleOnFocus = () => {
    questionBeingEditedId(matrixTitleLk)
  }

  const handleOnEditorChangeDebounced = useMemo(
    () =>
      debounce((newRawTitleStyling: string) => {
        handleUpdateMatrixTitle(newRawTitleStyling)
      }, 1000),
    [handleUpdateMatrixTitle]
  )

  const handleOnEditorChange = (newRawTitleStyling: string) => {
    setTitleStyling(newRawTitleStyling)
    handleOnEditorChangeDebounced(newRawTitleStyling)
  }

  return (
    <CardTitleControl
      hasEditor
      shouldHandlePasteManually
      titleValue={matrixTitleData.title}
      titleStyling={titleStyling ?? undefined}
      ariaLabel={ariaLabel}
      shouldTitleInputFocus={shouldTitleInputFocus}
      hasError={hasError}
      placeholder={placeholder}
      helperText={helperText}
      onFocus={handleOnFocus}
      onEditorChange={handleOnEditorChange}
    />
  )
}

export default memo(MatrixCardTitleContainer)
