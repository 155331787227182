import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'

import useStyles from './styles'

interface Props {
  header: JSX.Element | null
  content: JSX.Element | null
  footer: JSX.Element | null
}

const SectionEntryLayout: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()

  const { header, content, footer } = props

  return (
    <Grid container className={classNames([classes.sectionLayoutContainer])}>
      <Grid item xs={12}>
        {header}
      </Grid>
      <Grid item xs={12} className={classNames([classes.sectionLayoutContent])}>
        {content}
      </Grid>
      {footer && (
        <Grid item xs={12}>
          {footer}
        </Grid>
      )}
    </Grid>
  )
}

export default SectionEntryLayout
