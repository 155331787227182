import { datadogLogs } from '@datadog/browser-logs'
import { Grid } from '@focaldata/cin-ui-components'
import React, { useEffect, useRef, useState } from 'react'
import { DialogRawData } from '../../../controls/DialogRawData'
import SpinnerDialog from '../../../controls/SpinnerDialog/SpinnerDialog'
import { LogDownloadDataEvent } from '../../../data/amplitude'
import { DataFormat, OutputType } from '../../../data/model/shared'
import useRawDataDownload from '../../../hooks/useRawDataDownload'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { downloadFile, getNextText } from '../../../utils/projectUtils'

interface Props {
  isDialogOpen: boolean
  setIsDialogOpen: (value: boolean) => void
}

const RequestRawData: React.FC<Props> = (props: Props) => {
  const { isDialogOpen, setIsDialogOpen }: Props = props
  const surveyId = useSurveyId()
  const { getRawData } = useRawDataDownload()
  const [gettingRawData, setGettingRawData] = useState<boolean>(false)
  const [spinnerText, setSpinnerText] = useState<string>('Building columns')

  const startSpinner = () => {
    setGettingRawData(true)
    const interval = setInterval(() => {
      setSpinnerText(getNextText())
      if (!isDialogOpen) {
        clearInterval(interval)
      }
    }, 2000)
    return interval
  }
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleDownload: (fileFormat: DataFormat) => void = async (
    fileFormat
  ) => {
    const interval = startSpinner()
    try {
      const url = await getRawData(surveyId, fileFormat)
      downloadFile({ dataUrl: url })
      setGettingRawData(false)
      setIsDialogOpen(false)
    } catch (error) {
      setSpinnerText(
        'The file failed to download with ${error}, please retry - If the download continues to fail, please contact support@focaldata.com'
      )
      datadogLogs.logger.error(
        'The file failed to download for survey: ${surveyId}',
        { error }
      )

      clearInterval(interval)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        setGettingRawData(false)
        setIsDialogOpen(false)
      }, 3000)
    }
  }

  const handleRequestDataLocal: (
    outputType: OutputType,
    fileFormat: DataFormat
  ) => void = async (_, fileFormat) => {
    handleDownload(fileFormat)
    LogDownloadDataEvent(fileFormat, surveyId)
  }

  return (
    <>
      <DialogRawData
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        onRequestData={handleRequestDataLocal}
        loading={false}
      />
      <SpinnerDialog
        text={spinnerText}
        subtext={
          <Grid container direction="column" alignItems="center">
            <Grid item>DO NOT REFRESH THIS PAGE.</Grid>
            <Grid item textAlign="center">
              Exporting your file will take 3-4 minutes on average and up to
              15-20 minutes for large sample size.
            </Grid>
          </Grid>
        }
        isVisible={gettingRawData}
      />
    </>
  )
}

export default RequestRawData
