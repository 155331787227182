import { useContext } from 'react'
import { QuestionsDialog } from '../../../components/QuestionsDialog'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { isSettingEnabled } from '../../../utils/questionnaireUtils'
import QuestionnaireContext, {
  closeQuestionsDialogPiping
} from '../Questionnaire.context'
import { flattenEntries } from '../Questionnaire.utils'
import { useUpdateTitleWithPipingMarker } from './PipingDialog.hooks'
import {
  createLoopFieldDialogItem,
  getPipingMarker,
  getQuestionsDialogItem,
  isFreeTextItem,
  isSingleSelectItem
} from './PipingDialog.utils'

export const PipingDialogContainer = () => {
  const updateTitleWithPipingMarker = useUpdateTitleWithPipingMarker()
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const { currentPipingTargetNumber } = questionnaireState
  const {
    draftAudience = {
      members: []
    }
  } = useGetDraftAudience()
  const hasEntryPipingTargetNumber = (entry: DraftQuestionnaireEntry) =>
    entry.number === currentPipingTargetNumber
  const audienceMembers = draftAudience.members
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const draftQuestionnaireEntries = draftQuestionnaire?.entries ?? []
  const entries = flattenEntries(draftQuestionnaireEntries)
  const currentPipingTargetIndex = entries.findIndex(hasEntryPipingTargetNumber)
  const currentPipingTarget = entries.find(hasEntryPipingTargetNumber)
  const currentTargetParentSection = draftQuestionnaireEntries.find(
    (entry) =>
      entry.entryType === EntryType.SectionEntryType &&
      (entry.entryItem as DraftSectionItem).sectionId ===
        currentPipingTarget?.sectionId
  )
  const preivousToTargetEntries = entries.slice(0, currentPipingTargetIndex)

  const audienceDialogItems = audienceMembers
    .filter(isSingleSelectItem)
    .map(getQuestionsDialogItem)

  const questionnaireDialogItems = preivousToTargetEntries
    .filter((entry) => isSingleSelectItem(entry) || isFreeTextItem(entry))
    .map(getQuestionsDialogItem)

  if (currentTargetParentSection) {
    const parentSectionItem =
      currentTargetParentSection.entryItem as DraftSectionItem
    const isLoopingEnabled = isSettingEnabled(
      parentSectionItem.settingValues,
      QuestionSettingCode.Looping
    )

    if (isLoopingEnabled) {
      // if looping was set to the section then place loop field at the position of the source question instead of section position
      const loopingSourceEntryNumber =
        parentSectionItem.loopingConfig?.sourceEntryNumber
      const loopingSourceEntry = preivousToTargetEntries.find(
        (entry) => entry.number === loopingSourceEntryNumber
      )
      if (loopingSourceEntry) {
        const loopFieldDialogItem = createLoopFieldDialogItem(
          {
            ...currentTargetParentSection,
            position: loopingSourceEntry.position
          },
          loopingSourceEntry.contextPosition
        )

        questionnaireDialogItems.push(loopFieldDialogItem)
        questionnaireDialogItems.sort((a, b) => a.position - b.position)
      }
    }
  }

  const questionDialogItems = [
    ...audienceDialogItems,
    ...questionnaireDialogItems
  ]

  return (
    <QuestionsDialog
      title="Piping"
      dialogHeader="Pick which question to recall"
      primaryButtonText="Insert"
      isQuestionDialogOpen={questionnaireState.isQuestionsDialogPipingOpened}
      onQuestionDialogClose={() => dispatch(closeQuestionsDialogPiping())}
      questionDialogItems={questionDialogItems}
      onSelectQuestionDialogItem={(_, index) => {
        // index can be null only in case showEndOfSurvey property passed to QuestionsDialog
        const selectedItem = questionDialogItems[index as number]
        updateTitleWithPipingMarker(getPipingMarker(selectedItem))
      }}
    />
  )
}
