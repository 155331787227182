import { useReactiveVar } from '@apollo/client'
import { DropResult, List, RoutingMenuItem } from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../App.store'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import { DraftEntryResponseOption } from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry
} from '../../../../data/model/questionnaire'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import {
  checkIfMultipleChoice,
  getRoutingBadgeDisplayString,
  isLastEntry
} from '../../../../utils/questionnaireUtils'
import {
  responseOptionAdded,
  responseOptionsReoredered
} from '../../Questionnaire.slice'
import ResponseOption from './ResponseOption'
import {
  useCreateResponseOption,
  useFilteredRoutingMenuItems,
  useResponseOptions,
  useUpdateResponseOption
} from './ResponseOptions.hooks'
import { ROUTE_TO_END_SURVEY_NUMBER } from './ResponseOptions.utils'

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  currentEntry: DraftQuestionnaireEntry
}

const ResponseOptionsList: React.FC<Props> = (props: Props) => {
  const { currentEntry, entries: flatEntries } = props
  const dispatch = useAppDispatch()
  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)

  const entryItem = currentEntry.entryItem as DraftQuestionItem

  const { validateBasicResponseOption } = useQuestionnaireValidation()

  const updateResponseOption = useUpdateResponseOption(currentEntry.id)

  // masking should be enabled if this is not the first entry
  const maskingEnabled =
    (flatEntries &&
      flatEntries.length > 0 &&
      currentEntry.number !== flatEntries[0].number) ||
    false

  const createResponseOption = useCreateResponseOption(currentEntry.id)

  const filteredRoutingMenuItems: RoutingMenuItem[] =
    useFilteredRoutingMenuItems(flatEntries, currentEntry)

  const newlyAddedResponseOptionLk = useReactiveVar(responseOptionLkNewlyAdded)

  const responseOptions = useResponseOptions(currentEntry.id)
  const responseOptionCount = responseOptions.length

  useEffect(() => {
    setPendingDeletionPosition(undefined)
  }, [responseOptionCount])

  const handleAddResponseOption = useCallback(
    (newResponseOptionPosition: number): void => {
      dispatch(
        responseOptionAdded({
          questionLk: (currentEntry.entryItem as DraftQuestionItem).questionLk,
          position: newResponseOptionPosition
        })
      )
      createResponseOption(newResponseOptionPosition)
    },
    [createResponseOption, currentEntry.entryItem, dispatch]
  )

  const handleFocusResponseOption = useCallback(() => {
    questionBeingEditedId(currentEntry.id)
  }, [currentEntry.id])

  const handleReorderedItems = (event: DropResult): void => {
    if (event.destination) {
      const sourceIndex = event.source.index
      const destinationIndex = event.destination.index
      dispatch(
        responseOptionsReoredered({
          questionLk: entryItem.questionLk,
          destinationIndex,
          sourceIndex
        })
      )
      updateResponseOption({
        responseOptionId: responseOptions[sourceIndex].responseOptionLk,
        position: destinationIndex
      })
    }
  }

  const isMultipleChoice = checkIfMultipleChoice(entryItem.settingValues)
  const routeThatIsSet = responseOptions.find((ro) => ro.route)

  const shouldShowEndOfSurveyForMultipleChoice = !routeThatIsSet
    ? undefined
    : routeThatIsSet.route?.targetNumber === ROUTE_TO_END_SURVEY_NUMBER

  const showEndOfSurveyRouting = !isMultipleChoice
    ? true
    : shouldShowEndOfSurveyForMultipleChoice

  const getResponseOptionsComponent = (
    responseOptions: DraftEntryResponseOption[]
  ) => {
    return responseOptions.map((responseOption) => {
      if (responseOption.position === pendingDeletionPosition) {
        return null
      }
      const shouldAutoFocus =
        newlyAddedResponseOptionLk === responseOption.responseOptionLk

      const { errorMessage } = validateBasicResponseOption(
        entryItem.questionLk,
        responseOption.responseOptionLk
      )

      const routingBadgeDisplayString = getRoutingBadgeDisplayString(
        responseOption,
        flatEntries,
        ROUTE_TO_END_SURVEY_NUMBER
      )

      // TODO: this should also be able to render a lilght version of the response option control
      return (
        <ResponseOption
          key={responseOption.responseOptionLk + responseOption.position}
          ariaLabel="Basic question response option"
          errorText={errorMessage ?? ''}
          maskingEnabled={maskingEnabled}
          responseOption={responseOption}
          entryId={currentEntry.id}
          routingDisabled={isLastEntry(flatEntries, currentEntry.id)}
          showEndOfSurveyRouting={showEndOfSurveyRouting}
          shouldAutoFocus={shouldAutoFocus}
          cannotDelete={responseOptions.length <= 2}
          entries={flatEntries}
          routingText={routingBadgeDisplayString}
          filteredRoutingMenuItems={filteredRoutingMenuItems}
          onEnter={handleAddResponseOption}
          onFocus={handleFocusResponseOption}
        />
      )
    })
  }

  const responseOptionsComponent = getResponseOptionsComponent(responseOptions)

  return (
    <List
      canReorderItems
      droppableId="drop-basicQuestion-responseOptions"
      onReorderedItems={handleReorderedItems}
    >
      {responseOptionsComponent}
    </List>
  )
}

export default memo(ResponseOptionsList)
