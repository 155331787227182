import { Paper } from '@focaldata/cin-ui-components'
import { ReactNode, memo, useContext } from 'react'
import QuestionnaireContext from '../Questionnaire.context'

interface Props {
  children: ReactNode
  entryId: string
}

const CardBackgroundContainer = ({ children, entryId }: Props) => {
  const { questionnaireState } = useContext(QuestionnaireContext)
  const isHighlighted = questionnaireState.selectedCards.has(entryId)

  return (
    <Paper style={{ backgroundColor: isHighlighted ? '#F7F7F7' : 'initial' }}>
      {children}
    </Paper>
  )
}

export default memo(CardBackgroundContainer)
