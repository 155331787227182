import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ImportQuestionSearchProjectsQueryVariables } from '../../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { SurveyParams } from '../../../../data/model/surveyParams'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import UserSession from '../../../../utils/UserSession'
import ImportQuestionsSurveyPickerControl from './ImportQuestionsSurveyPicker.control'
import ImportQuestionsSurveyPickerLoading from './ImportQuestionsSurveyPicker.loading'
import {
  IMPORT_QUESTIONS_SEARCH_PROJECTS,
  ImportQuestionProject,
  SearchProjectsData
} from './ImportQuestionsSurveyPicker.query'

interface Props {
  onSurveypicked: (projectId: string, surveyId: string) => void
}

const ImportQuestionsSurveyPicker: React.FC<Props> = ({
  onSurveypicked
}: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { data, loading } = useQuery<
    SearchProjectsData,
    ImportQuestionSearchProjectsQueryVariables
  >(IMPORT_QUESTIONS_SEARCH_PROJECTS, {
    context: { clientName: 'dashboard' },
    fetchPolicy: 'cache-and-network',
    variables: {
      accountId: UserSession.getAccountId()
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'importQuestionsProjectQuery',
        error
      )
    }
  })

  const handleProjectSelected = (project: ImportQuestionProject): void => {
    const {
      projectId,
      surveys: [firstSurvey]
    } = project

    onSurveypicked(projectId, firstSurvey.surveyId)
  }

  if (loading || !data) {
    return <ImportQuestionsSurveyPickerLoading />
  }

  const { projects } = data.searchProjects

  const allProjectsExceptCurrent = projects.filter(
    (project) =>
      project.surveys.length > 0 && project.surveys[0].surveyId !== surveyId
  )

  if (allProjectsExceptCurrent.length === 0) {
    return <>There are no other projects in this account</>
  }

  return (
    <ImportQuestionsSurveyPickerControl
      onProjectSelected={handleProjectSelected}
      projects={allProjectsExceptCurrent}
    />
  )
}

export default ImportQuestionsSurveyPicker
