import {
  StandardCriterionOptionValidationErrors,
  StandardOptionValidationError
} from '../../../data/model/fieldwork'

export const AGE_RANGES_CRITERION_CODE = 'age_16_to_99__7_bands'

export const getErrorMessage = (
  errors: StandardCriterionOptionValidationErrors['errors']
): string => {
  let errorMessage = ''

  if (errors.length === 0) {
    return errorMessage
  }

  const minAllowedAgeError = errors.find((error) =>
    error.startsWith('MinAllowedAgeError')
  )
  const maxAllowedAgeError = errors.find((error) =>
    error.startsWith('MaxAllowedAgeError')
  )

  if (errors.includes(StandardOptionValidationError.MinAgeBiggerThanMaxError)) {
    errorMessage = 'Min age cannot be bigger than max age'
  } else if (
    errors.includes(StandardOptionValidationError.AgeRangeOverlapError)
  ) {
    errorMessage =
      'Please correct the below errors. You have 2 or more ranges that overlap.'
  } else if (minAllowedAgeError) {
    errorMessage = `Min age cannot be less than ${minAllowedAgeError.replace(
      'MinAllowedAgeError_',
      ''
    )}`
  } else if (maxAllowedAgeError) {
    errorMessage = `Max age cannot be more than ${maxAllowedAgeError.replace(
      'MaxAllowedAgeError_',
      ''
    )}`
  } else if (errors.includes(StandardOptionValidationError.QuotaIsZeroError)) {
    errorMessage =
      'Sorry, you cannot set a quota to 0%. Please change the quota % or disqualify the option.'
  } else if (errors.includes(StandardOptionValidationError.AgeBandsGapError)) {
    errorMessage =
      'You cannot have gaps between age buckets unless you add quotas.'
  }

  return errorMessage
}
