import { validate } from 'email-validator'
import { useState } from 'react'

export interface CustomError extends Error {
  response?: {
    status: number
  }
}

interface Props {
  email?: string
  organisation?: string
  error?: CustomError
}

const checkIfPersonalEmailAddress: (email: string) => boolean = (email) => {
  const personalEmailDomains = [
    // 'gmail.com',
    // 'hotmail.com',
    'yahoo.com',
    'aol.com',
    'outlook.com'
  ]
  const isPersonalEmail = personalEmailDomains.some((domain) =>
    email.includes(domain)
  )

  return isPersonalEmail
}

const useSignupForm = (props: Props) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>(props.email || '')
  const [organisation, setOrganisation] = useState<string>(
    props.organisation || ''
  )
  const [error, setError] = useState<CustomError | undefined>(props.error)
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >()

  const validateEmail: (email: string) => boolean = (email) => {
    const isEmailFormatValid = validate(email)
    const isPersonalEmail = checkIfPersonalEmailAddress(email)

    const isValid = isEmailFormatValid && !isPersonalEmail
    const errorMessage = isPersonalEmail
      ? 'You have entered a personal email address. Please use your work email address instead.'
      : 'You have entered an invalid email address.'

    if (!isValid) setEmailErrorMessage(errorMessage)

    return isValid
  }

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErrorMessage(undefined)
    setEmail(event.target.value)
  }

  const handleOrganisationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganisation(event.target.value)
  }

  const getEmailInputErrorMessage: (
    error?: CustomError
  ) => string | undefined = (error) => {
    if (error?.response?.status === 409) {
      return 'This email is already in use.'
    }
    if (emailErrorMessage) {
      return emailErrorMessage
    }

    return undefined
  }

  const isFormValid = () => {
    return (
      emailErrorMessage === undefined &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      organisation.length > 0 &&
      validateEmail(email)
    )
  }

  return {
    firstName,
    lastName,
    email,
    organisation,
    error,
    emailErrorMessage,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    setEmail,
    handleOrganisationChange,
    setOrganisation,
    setError,
    validateEmail,
    getEmailInputErrorMessage,
    isFormValid
  }
}

export default useSignupForm
