import ListItemSidebar from '../../../../components/ListItemSidebar'
import useQuestionnaireValidation from '../../../../hooks/questionnaire/useQuestionnaireValidation'

interface Props {
  entryId: string
  questionNumber: string
  questionTitle: string
  isSelected: boolean
  onItemClick: () => void
}

const DraggableSidebarItem: React.FC<Props> = ({
  entryId,
  questionTitle,
  questionNumber,
  isSelected,
  onItemClick
}: Props) => {
  const { entryHasValidationErrors } = useQuestionnaireValidation()
  return (
    <ListItemSidebar
      isError={entryHasValidationErrors(entryId)}
      questionTitle={questionTitle}
      questionNumber={questionNumber}
      isSelected={isSelected}
      onClickItem={onItemClick}
    />
  )
}

export default DraggableSidebarItem
