import React, { createContext, memo, useContext } from 'react'
import { EntryType } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftMatrixItem,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import { useDuplicateQuestionnaireEntry } from '../../../hooks/questionnaire/useDuplicateQuestionnaireEntry'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import MatrixQuestionLayout from '../../../layouts/MatrixQuestionLayout'
import { WithEntryOfType } from '../../../utils/questionnaireUtils'
import DefaultOptions from '../QuestionCardDefaultOptions/DefaultOptions'
import QuestionCardSwitches from '../QuestionCardSwitches'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import { MatrixCardTitleContainer } from './MatrixCardTitle'
import MatrixQuestionFooter from './MatrixQuestionFooter'
import MatrixQuestionRows from './MatrixQuestionRows/MatrixQuestionRowsList.container'
import { MatrixQuestionRowsSwitches } from './MatrixQuestionRowsSwitches'
import { MatrixResponseOptionsList } from './MatrixResponseOptions'

type Props = {
  shouldTitleInputFocus: boolean
} & WithEntryOfType<EntryType.MatrixEntryType>

export const MatrixEntryContext = createContext<DraftMatrixItem | undefined>(
  undefined
)

export const useMatrixEntryContext = () => {
  const entryItem = useContext(MatrixEntryContext)

  if (!entryItem) {
    throw new Error('Missing MatrixEntryContext.Provider')
  }

  return entryItem
}

const MatrixQuestionContainer: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()

  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const { validateMatrixQuestionTitle } = useQuestionnaireValidation()

  const entryItem = entry.entryItem
  const { duplicateQuestionnaireEntry } = useDuplicateQuestionnaireEntry(
    entry.id
  )

  const isMultipleChoice =
    entryItem.settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.MatrixChoice
    )?.value === SettingValue.MultipleChoice

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const emptyMatrixTitle = entryItem.matrixTitle === null
  const isBasicQuestionTextEmpty = validateMatrixQuestionTitle(
    entryItem.matrixTitleLk
  )

  return (
    <MatrixEntryContext.Provider value={entryItem}>
      <MatrixQuestionLayout
        entryId={entry.id}
        questionCardHeader={
          <SurveyQuestionCardHeaderContainer
            titleContent={
              <MatrixCardTitleContainer
                ariaLabel="Matrix question header"
                hasError={isBasicQuestionTextEmpty}
                matrixTitleLk={entryItem.matrixTitleLk}
                shouldTitleInputFocus={shouldTitleInputFocus}
              />
            }
            entry={entry}
            hasError={isBasicQuestionTextEmpty}
            onClickDuplicateIcon={async () => {
              await duplicateQuestionnaireEntry({
                position: entry.position + 1
              })
            }}
            isMultipleChoice={isMultipleChoice}
            disabledDuplicate={emptyMatrixTitle}
            entries={flatDraftQuestionnaireEntries}
          />
        }
        matrixQuestionRows={<MatrixQuestionRows entry={entry} />}
        matrixQuestionRowsSwitches={
          <MatrixQuestionRowsSwitches entry={entry} />
        }
        matrixResponseOptionSwitches={
          <QuestionCardSwitches
            entry={entry}
            randomiseQuestionSettingCode={
              QuestionSettingCode.MatrixRandomiseResponseOptions
            }
            flipOrderQuestionSettingCode={QuestionSettingCode.MatrixFlipOptions}
            title="COLUMNS"
            showMatrixChoiceOptions
          />
        }
        matrixResponseOptions={<MatrixResponseOptionsList entry={entry} />}
        questionCardFooter={<MatrixQuestionFooter entry={entry} />}
        defaultOptions={<DefaultOptions entry={entry} />}
      />
    </MatrixEntryContext.Provider>
  )
}

export default memo(MatrixQuestionContainer)
