import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorPage as ErrorPageComponent } from '../../components/ErrorPage'
import errorText from './errorText'
import useStyles from './styles'

export enum ErrorType {
  NotFound404,
  ServerError503,
  NotFound404Public
}

interface Props {
  errorType: ErrorType
}

const ErrorPage: React.FC<Props> = (props: Props) => {
  const { errorType = ErrorType.NotFound404 }: Props = props
  const { classes } = useStyles()
  const navigate = useNavigate()

  const redirectToDashboard = () => {
    navigate('/projects')
  }
  const redirectToWebsite = () => {
    window.location.href = process.env.REACT_APP_WEBSITE_URL || ''
  }

  const errorCodeType = new Map<ErrorType, string>([
    [ErrorType.NotFound404, errorText.notFound404.errorCode],
    [ErrorType.ServerError503, errorText.serverError503.errorCode],
    [ErrorType.NotFound404Public, errorText.notFound404Public.errorCode]
  ])

  const titleType = new Map<ErrorType, string>([
    [ErrorType.NotFound404, errorText.notFound404.title],
    [ErrorType.ServerError503, errorText.serverError503.title],
    [ErrorType.NotFound404Public, errorText.notFound404Public.title]
  ])

  const subtitleType = new Map<ErrorType, string>([
    [ErrorType.NotFound404, errorText.notFound404.subtitle],
    [ErrorType.ServerError503, errorText.serverError503.subtitle],
    [ErrorType.NotFound404Public, errorText.notFound404Public.subtitle]
  ])

  const contactInfoType = new Map<ErrorType, string>([
    [ErrorType.NotFound404, errorText.notFound404.contactInfo],
    [ErrorType.ServerError503, errorText.serverError503.contactInfo],
    [ErrorType.NotFound404Public, errorText.notFound404Public.contactInfo]
  ])

  const buttonTextType = new Map<ErrorType, string>([
    [ErrorType.NotFound404, errorText.notFound404.buttonText],
    [ErrorType.NotFound404Public, errorText.notFound404Public.buttonText]
  ])

  const onClickType = new Map<ErrorType, () => void>([
    [ErrorType.NotFound404, redirectToDashboard],
    [ErrorType.NotFound404Public, redirectToWebsite]
  ])

  const errorCode = errorCodeType.get(errorType)
  const title = titleType.get(errorType)
  const subtitle = subtitleType.get(errorType)
  const contactInfo = contactInfoType.get(errorType)
  const buttonText = buttonTextType.get(errorType)
  const onClickButton = onClickType.get(errorType)

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.containerGrid}
    >
      <Grid item xs={3} />
      <Grid item xs={9}>
        <ErrorPageComponent
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          errorCode={errorCode!}
          title={title}
          subtitle={subtitle}
          contactInfo={contactInfo}
          buttonText={buttonText}
          onClick={onClickButton}
        />
      </Grid>
    </Grid>
  )
}

export default ErrorPage
