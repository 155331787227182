import {
  Grid,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Icon from '@mui/material/Icon'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { truncateText } from '../../../utils/questionnaireUtils'

interface Props {
  value?: string
  position?: number
  isChecked?: boolean
  isDisabled?: boolean
  showActions?: boolean
  showMoveUp?: boolean
  showMoveDown?: boolean
  onCheckedChange?: (isChecked: boolean) => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`
}))

const OptionPreview: React.FC<Props> = ({
  value,
  position,
  isChecked,
  isDisabled,
  showActions,
  showMoveUp,
  showMoveDown,
  onCheckedChange,
  onMoveUp,
  onMoveDown
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  return (
    <Grid container item sm>
      <Item style={{ width: '100%', boxSizing: 'border-box' }}>
        <Grid container item direction="row">
          <Grid container item sm={10}>
            {value && (
              <input
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                onChange={(e) => onCheckedChange?.(e.target.checked)}
              />
            )}
            <Tooltip
              disabled={value === undefined}
              tipPlacement={TipPlacement.BottomStart}
              title={value || ''}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90%'
              }}
            >
              <p className={classNames(textClasses.default, textClasses.sizeM)}>
                {value ? truncateText(45, value) : '-'} - {position}
              </p>
            </Tooltip>
          </Grid>
          {showActions && (
            <Grid
              container
              item
              sm={2}
              style={{ paddingTop: '0' }}
              justifyContent="flex-end"
            >
              <Grid container item sm="auto" direction="row">
                <Grid
                  item
                  alignItems="center"
                  style={{
                    maxHeight: '20px'
                  }}
                >
                  <Icon
                    onClick={onMoveUp}
                    style={{
                      display: showMoveUp ? 'unset' : 'none'
                    }}
                  >
                    <KeyboardArrowUp />
                  </Icon>
                </Grid>
                <Grid
                  item
                  alignItems="center"
                  style={{
                    maxHeight: '20px'
                  }}
                >
                  <Icon
                    onClick={onMoveDown}
                    style={{
                      display: showMoveDown ? 'unset' : 'none'
                    }}
                  >
                    <KeyboardArrowDown />
                  </Icon>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Item>
    </Grid>
  )
}

export default OptionPreview
