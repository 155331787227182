import { makeStyles, theme } from '@focaldata/cin-ui-components'

const infoStyles = {
  position: 'fixed' as any,
  zIndex: 10_000,
  padding: '5px 0',
  backgroundColor: theme.palette.common.black,
  opacity: 0.7,
  color: theme.palette.common.white
}

const infoButton = {
  ...infoStyles,
  width: '130px',
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '0 0 25px 25px',
  border: 'none',
  cursor: 'pointer'
}

const useStyles = makeStyles()({
  infoButton,
  infoLabel: {
    ...infoStyles,
    width: '100%',
    display: 'flex'
  },
  infoLabelTextWrapper: {
    display: 'inline-block',
    flex: 1,
    textAlign: 'center'
  },
  copyText: {
    cursor: 'default',
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: 'monospace',
    fontWeight: 'bold',
    '&:active': {
      cursor: 'copy',
      transform: 'scale(1.01)'
    }
  },
  closeButton: {
    border: 'none',
    backgroundColor: theme.palette.grey['900'],
    color: 'inherit',
    cursor: 'pointer'
  }
})

export default useStyles
