import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'
import { useLatest } from 'react-use'

/**
 * Wrapper for setState that allows you to call a notifying function when the
 * value has changed – debounced
 */
export function useNotifyingState<T>(
  defaultValue: T,
  notify: (nextValue: T) => void,
  wait: number | undefined = undefined
) {
  const [value, setValue] = useState(defaultValue)
  const latestNotify = useLatest(notify)
  const [isNotifying, setIsNotifying] = useState(false)

  const notifyChange = useMemo(
    () =>
      debounce((nextTitle: T) => {
        setIsNotifying(false)
        latestNotify.current(nextTitle)
      }, wait),
    [latestNotify, wait]
  )

  const handleChange = useCallback(
    (nextValue: T) => {
      setValue(nextValue)
      setIsNotifying(true)
      notifyChange(nextValue)
    },
    [notifyChange]
  )

  return [
    value,
    handleChange,
    { setValueWithoutNotifying: setValue, isNotifying }
  ] as const
}

/**
 * Wrapper for useState which resets back to defaultValue whenever it changes –
 * makes having state with default values based on props more ergonomic. It will
 * also call a notifying function when the value has changed – debounced. Has
 * protection against the value resetting when a notification is still in
 * progress.
 *
 * @warning defaultValue must be stable or this will infinite re-render
 */
export function useNotifyingDefaultState<T>(
  defaultValue: T,
  notify: (nextValue: T) => void,
  wait: number | undefined = undefined
) {
  const [prevValue, setPrevValue] = useState(defaultValue)
  const [
    currentValue,
    handleChange,
    { setValueWithoutNotifying, isNotifying }
  ] = useNotifyingState(defaultValue, notify, wait)

  if (prevValue !== defaultValue) {
    setPrevValue(defaultValue)

    if (!isNotifying) {
      setValueWithoutNotifying(defaultValue)
    }
  }

  return [currentValue, handleChange] as const
}
