import { PureQueryOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { DraftQuestionnaire } from '../../../model/questionnaire'
import DRAFT_QUESTIONNAIRE_ENTRY from '../fragments/draftQuestionnaireEntry'

export interface QuestionnaireData {
  draftQuestionnaire: DraftQuestionnaire
}

/**
 * @deprecated Write more specific queries for the data your component actually needs
 */
export const QUESTIONNAIRE = gql`
  query draftQuestionnaireV2($projectId: ID!, $surveyId: ID) {
    draftQuestionnaire: draftQuestionnaireV2(
      projectId: $projectId
      surveyId: $surveyId
    ) {
      questionnaireId
      entries {
        ...DraftQuestionnaireEntry
      }
      audienceEntries {
        ...DraftQuestionnaireEntry
      }
      __typename
    }
  }
  ${DRAFT_QUESTIONNAIRE_ENTRY}
`

export const draftQuestionnaireRefetchQuery: (
  projectId: string,
  surveyId: string
) => PureQueryOptions = (projectId, surveyId) => {
  return {
    query: QUESTIONNAIRE,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId }
  }
}
