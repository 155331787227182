import { EntryType } from '../../../../data/model/questionnaire'
import { truncateText } from '../../../../utils/questionnaireUtils'
import { ResponseOptionsByQuestion } from '../../Questionnaire.slice'

export const getResponseOptionValue: (options: {
  entryType: Exclude<EntryType, EntryType.MatrixEntryType> | undefined
  entryItemLk: string | undefined
  maskedItemLk: string | undefined
  responseOptionsByQuestion: ResponseOptionsByQuestion
}) => string | undefined = ({
  entryType,
  entryItemLk,
  maskedItemLk,
  responseOptionsByQuestion
}) => {
  let text = ''
  if (entryType === EntryType.QuestionEntryType) {
    const responseOption = entryItemLk
      ? // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        responseOptionsByQuestion[entryItemLk]?.find(
          (ro) => ro.responseOptionLk === maskedItemLk
        )?.responseOption
      : null
    text = responseOption?.value ?? ''
  }

  return truncateText(70, text)
}
