import {
  Chip,
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './styles'

interface Props {
  resultText: string
  emails: string[]
  isError?: boolean
}
const InviteResult = (props: Props) => {
  const { resultText, emails, isError } = props
  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  const errorMessage =
    emails.length === 0
      ? 'Invite could not be sent. Please try again!'
      : // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        `${emails && emails.length === 1 ? 'User' : 'Users'} already exists.`

  return (
    <>
      <Grid
        container
        item
        justifyContent="start"
        alignItems="center"
        display="flex"
      >
        <Icon
          name={isError ? IconName.CancelOutlined : IconName.SingleChoice}
          size={IconSize.Large}
          iconColor={isError ? IconColor.Error : IconColor.Enabled}
        />
        <p
          className={classNames(
            {
              [classes.sentText]: !isError,
              [classes.failedText]: isError
            },
            classes.text
          )}
        >
          {resultText}
        </p>
      </Grid>
      <Grid className={classes.emailContainer}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {emails &&
          emails.map((email) => (
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={email}
              className={classNames({
                [classes.email]: !isError,
                [classes.emailError]: isError
              })}
            />
          ))}
      </Grid>
      {isError && (
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeS,
            textClasses.highlightError
          )}
        >
          {errorMessage}
        </p>
      )}
    </>
  )
}

export default InviteResult
