import {
  TextHighlight,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import MiuButton from '@mui/material/Button'
import React from 'react'
import useStyles from './Breadcrumbs.styles'

export enum BreadcrumbLinkVariant {
  Selected,
  Selectable,
  Disabled
}

interface Props extends React.HTMLProps<HTMLButtonElement> {
  variant: BreadcrumbLinkVariant
}

const BreadcrumbLink: React.FC<Props> = ({
  variant,
  children,
  onClick
}: Props) => {
  const { classes } = useStyles()
  const { useTextStyles, classNameByWeight, classNameByHighlight } =
    textStyleUtils
  const { classes: textClasses, cx: classNames } = useTextStyles()

  const linkButtonClass = new Map<BreadcrumbLinkVariant, string>([
    [BreadcrumbLinkVariant.Disabled, classes.buttonNotClickable],
    [BreadcrumbLinkVariant.Selectable, classes.buttonClickable],
    [BreadcrumbLinkVariant.Selected, classes.buttonNotClickable]
  ])

  const linkHighlight = new Map<BreadcrumbLinkVariant, TextHighlight>([
    [BreadcrumbLinkVariant.Disabled, TextHighlight.Background],
    [BreadcrumbLinkVariant.Selectable, TextHighlight.Emphasis],
    [BreadcrumbLinkVariant.Selected, TextHighlight.Standard]
  ])

  const linkWeigth = new Map<BreadcrumbLinkVariant, TextWeight>([
    [BreadcrumbLinkVariant.Disabled, TextWeight.Regular],
    [BreadcrumbLinkVariant.Selectable, TextWeight.Regular],
    [BreadcrumbLinkVariant.Selected, TextWeight.SemiBold]
  ])

  const selectedButtonClass = linkButtonClass.get(variant)
  const selectedLinkTextWeight = linkWeigth.get(variant)
  const selectedLinkTextHighlight = linkHighlight.get(variant)

  return (
    <MiuButton disableRipple onClick={onClick} className={selectedButtonClass}>
      <p
        className={classNames(
          textClasses.default,
          textClasses[
            classNameByHighlight.get(
              selectedLinkTextHighlight ?? TextHighlight.Background
            ) ?? 'highlightBackground'
          ],
          textClasses.sizeM,
          textClasses[
            classNameByWeight.get(
              selectedLinkTextWeight ?? TextWeight.Regular
            ) ?? 'weightRegular'
          ]
        )}
      >
        {children}
      </p>
    </MiuButton>
  )
}

export default BreadcrumbLink
