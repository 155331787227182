import { QuestionKind, QuestionType } from '../../data/model/chart'
import { SearchableFilterOption } from '../../data/model/results'

export interface SearchResultEntry {
  isMatrix?: boolean
  matrixRow?: number
  chartEntry: SearchableFilterOption
}

const searchByQuestionPosition: (
  chartEntry: SearchableFilterOption,
  text: string
) => boolean = (chartEntry, text) => {
  // Do a regex to see if it's Axxx or Qxxx or Qxxx.xxx
  if (text.match('^[aq][0-9]+[.]*[0-9]*$')) {
    const qType = text[0]
    const shards = text.split('.')
    const qNumber = parseInt(shards[0].slice(1), 10)
    const isQType =
      (chartEntry.questionKind === QuestionKind.Audience && qType === 'a') ||
      (chartEntry.questionKind === QuestionKind.Questionnaire && qType === 'q')
    if (isQType) {
      const isQNumber = qNumber === chartEntry.contextPosition
      return isQNumber
    }
  }
  return false
}

export const getSearchResultUIKey: (
  resultEntry: SearchResultEntry
) => string = (resultEntry) =>
  resultEntry.isMatrix
    ? `${resultEntry.chartEntry.id}${
        resultEntry.chartEntry.rows[resultEntry.matrixRow || 0].id
      }`
    : resultEntry.chartEntry.id

export const getSearchResultUIPosition: (
  resultEntry: SearchResultEntry
) => string | number = (resultEntry) =>
  resultEntry.isMatrix
    ? `${resultEntry.chartEntry.contextPosition}.${
        resultEntry.chartEntry.rows[resultEntry.matrixRow || 0].position + 1
      }`
    : resultEntry.chartEntry.contextPosition

export const getSearchResultUIText: (
  resultEntry: SearchResultEntry
) => string = (resultEntry) =>
  resultEntry.isMatrix
    ? `${resultEntry.chartEntry.text} ${
        resultEntry.chartEntry.rows[resultEntry.matrixRow || 0].text
      }`
    : resultEntry.chartEntry.text

export const searchForEntries: (
  entries: SearchableFilterOption[],
  value: string
) => SearchResultEntry[] = (entries, value) => {
  const lowercaseValue = value.toLocaleLowerCase()

  const results: SearchResultEntry[] = entries.reduce<SearchResultEntry[]>(
    (filtered, entry) => {
      if (entry.questionType === QuestionType.matrix) {
        if (
          entry.text.toLocaleLowerCase().includes(lowercaseValue) ||
          searchByQuestionPosition(entry, lowercaseValue)
        ) {
          const temp: SearchResultEntry[] = entry.rows.map((_, index) => {
            return { isMatrix: true, matrixRow: index, chartEntry: entry }
          })
          filtered.push(...temp)
        } else {
          entry.rows.forEach((row, index) => {
            if (
              row.text.toLocaleLowerCase().includes(lowercaseValue) ||
              searchByQuestionPosition(entry, lowercaseValue)
            ) {
              filtered.push({
                isMatrix: true,
                matrixRow: index,
                chartEntry: entry
              })
            }
          })
        }
      } else if (
        ((entry.questionType === QuestionType.basic ||
          entry.questionKind === QuestionKind.Audience ||
          entry.questionKind === QuestionKind.None) &&
          entry.text.toLocaleLowerCase().includes(lowercaseValue)) ||
        searchByQuestionPosition(entry, lowercaseValue)
      ) {
        filtered.push({ chartEntry: entry })
      }

      return filtered
    },
    []
  )

  return results
}

export const getSearchPrefixByFilterEntryType = (
  questionKind: QuestionKind
): 'A' | 'Q' | 'F' => {
  switch (questionKind) {
    case QuestionKind.Audience:
      return 'A'
    case QuestionKind.Questionnaire:
      return 'Q'
    case QuestionKind.None:
      return 'F'
    default:
      return 'Q'
  }
}
