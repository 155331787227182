import axios from 'axios'
import { AccessToken } from '../../../utils/authUtils'
import {
  Account,
  AutoSignUpData,
  InviteDetails,
  LoginData,
  RefreshData,
  RequestAccessData,
  SignUpData,
  User
} from '../../model/accountUser'

const dashboardApi = `${
  process.env.REACT_APP_URL_DASHBOARD || '/v1/dashboard'
}/api/v1.0`
const DASHBOARD_API_URL = dashboardApi.includes('http')
  ? dashboardApi
  : process.env.REACT_APP_REST_DOMAIN + dashboardApi

// Enpoint to retrieve the accounts a user has access to by email
const accountsByEmailEndpoint = `${DASHBOARD_API_URL}/account`
// Endpoint for requesting access to our platform
const login = `${DASHBOARD_API_URL}/login`
// Endpoint for refreshing accessToken
const signupAccess = `${DASHBOARD_API_URL}/signup/access`
// Endpoint to complete the signup process once you have been approved
const refresh = `${DASHBOARD_API_URL}/refresh_token`
// Endpoint for loging in
const requestAccess = `${DASHBOARD_API_URL}/request/access`
// Enpoint to retrieve the users details
const userByEmailEndpoint = `${DASHBOARD_API_URL}/user`
// Endpoint for loging in
const createInvite = `${DASHBOARD_API_URL}/invite/create`
// Enpoint to retrieve the users details
const inviteSignup = `${DASHBOARD_API_URL}/signup/invite`
// Enpoint to retrieve the invite details
const inviteDetails = `${DASHBOARD_API_URL}/signup/invite-details`
// Enpoint to create a reset password
const createResetPassword = `${DASHBOARD_API_URL}/request-reset-password`
// Enpoint to reset password
const resetPassword = `${DASHBOARD_API_URL}/reset-password`
// Enpoint to check if reset password link is valid
const isResetPasswordValid = `${DASHBOARD_API_URL}/valid-reset-password`

const endpointForAutoSignup = `${DASHBOARD_API_URL}/auto-signup`

export default class AccountUserService {
  accountsByEmailEndpoint: string

  loginEndpoint: string

  signUpEndpoint: string

  autoSignUpEndpoint: string

  refreshEndpoint: string

  requestAccessEndpoint: string

  userByEmailEndpoint: string

  createInviteEndpoint: string

  inviteDetailsByInviteId: string

  inviteSignupEndpoint: string

  createResetPasswordEndpoint: string

  resetPasswordEndpoint: string

  isResetPasswordValidEndpoint: string

  constructor() {
    this.accountsByEmailEndpoint = accountsByEmailEndpoint

    this.loginEndpoint = login

    this.refreshEndpoint = refresh

    this.requestAccessEndpoint = requestAccess

    this.signUpEndpoint = signupAccess

    this.autoSignUpEndpoint = endpointForAutoSignup

    this.userByEmailEndpoint = userByEmailEndpoint

    this.createInviteEndpoint = createInvite

    this.inviteSignupEndpoint = inviteSignup

    this.inviteDetailsByInviteId = inviteDetails

    this.createResetPasswordEndpoint = createResetPassword

    this.resetPasswordEndpoint = resetPassword

    this.isResetPasswordValidEndpoint = isResetPasswordValid
  }

  requestAccess(
    firstName: string,
    lastName: string,
    emailAddress: string,
    accountName: string
  ) {
    return axios.post<RequestAccessData>(this.requestAccessEndpoint, {
      firstName,
      lastName,
      emailAddress,
      accountName
    })
  }

  autoSignup(
    firstName: string,
    lastName: string,
    emailAddress: string,
    accountName: string,
    password: string,
    autoSignupId: string
  ) {
    return axios.post<AutoSignUpData>(
      this.autoSignUpEndpoint,
      {
        firstName,
        lastName,
        emailAddress,
        accountName,
        password,
        autoSignupId
      },
      {
        withCredentials: true
      }
    )
  }

  signUp(requestId: string, password: string) {
    return axios.post<SignUpData>(this.signUpEndpoint, {
      requestId,
      password
    })
  }

  login(emailAddress: string, password: string) {
    return axios.post<LoginData>(
      this.loginEndpoint,
      {
        emailAddress,
        password
      },
      {
        withCredentials: true
      }
    )
  }

  refresh() {
    return axios.get<RefreshData>(this.refreshEndpoint, {
      withCredentials: true
    })
  }

  getAccountsByEmail(accessToken: string, emailAddress: string) {
    return axios.post<Account[]>(
      this.accountsByEmailEndpoint,
      {
        emailAddress
      },
      {
        headers: {
          ...AccessToken.requestHeadersWithToken(accessToken)
        }
      }
    )
  }

  getAccountsByAccountId(accessToken: string, accountId: string) {
    return axios.get<Account>(`${this.accountsByEmailEndpoint}/${accountId}`, {
      headers: {
        ...AccessToken.requestHeadersWithToken(accessToken)
      }
    })
  }

  getInviteDetails(inviteId: string) {
    return axios.get<InviteDetails>(
      `${this.inviteDetailsByInviteId}/${inviteId}`
    )
  }

  verifyAutoSignup(autoSignupId: string) {
    return axios.get<boolean>(
      `${this.autoSignUpEndpoint}/verify/${autoSignupId}`
    )
  }

  getUserByEmail(accessToken: string, emailAddress: string) {
    return axios.get<User>(this.userByEmailEndpoint, {
      params: {
        emailAddress
      },
      headers: {
        ...AccessToken.requestHeadersWithToken(accessToken)
      }
    })
  }

  createInvite(
    accessToken: string,
    accountId: string,
    creatorEmailAddress: string,
    inviteeEmailAddresses: string[]
  ) {
    return axios.post<any>(
      this.createInviteEndpoint,
      {
        accountId,
        creatorEmailAddress,
        inviteeEmailAddresses
      },
      {
        headers: {
          ...AccessToken.requestHeadersWithToken(accessToken)
        }
      }
    )
  }

  inviteSignup(
    inviteId: string,
    firstName: string,
    lastName: string,
    password: string
  ) {
    return axios.post<SignUpData>(
      this.inviteSignupEndpoint,
      {
        inviteId,
        firstName,
        lastName,
        password
      },
      {
        withCredentials: true
      }
    )
  }

  createResetPassword(email: string) {
    return axios.post<string>(this.createResetPasswordEndpoint, {
      emailAddress: email
    })
  }

  resetPassword(resetPasswordId: string, password: string) {
    return axios.post<SignUpData>(this.resetPasswordEndpoint, {
      resetPasswordId,
      password
    })
  }

  isResetPasswordValid(resetPasswordId: string) {
    return axios.post(this.isResetPasswordValidEndpoint, {
      resetPasswordId
    })
  }
}
