import gql from 'graphql-tag'
import { EntryType } from '../../../../data/model/questionnaire'

export interface SidebarQuestionItem {
  questionLk: string
  question: {
    text: string
  }
}

export interface SidebarMatrixItem {
  matrixTitleLk: string
  matrixTitle: {
    title: string
    matrixTitleId: string
  }
}

export interface SidebarTextCardItem {
  textCardLk: string
  textCard: {
    title: string
  }
}

export interface SidebarForkItem {
  fork: {
    forkId: string
    name: string
  }
}

export interface SidebarSectionItem {
  sectionId: string
  title: string
  entries: SidebarEntry[]
}

type SidebarEntryTypes = {
  [EntryType.QuestionEntryType]: SidebarQuestionItem
  [EntryType.TextCardEntryType]: SidebarTextCardItem
  [EntryType.MatrixEntryType]: SidebarMatrixItem
  [EntryType.ForkEntryType]: SidebarForkItem
  [EntryType.SectionEntryType]: SidebarSectionItem
}

export type SidebarEntry<
  Type extends keyof SidebarEntryTypes = keyof SidebarEntryTypes
> = {
  id: string
  number: number
  position: number
  contextPosition: number
  sectionId: string
} & {
  [K in keyof SidebarEntryTypes]: {
    entryType: K
    entryItem: SidebarEntryTypes[K]
  }
}[Type]

export interface FieldworkAudienceData {
  draftQuestionnaire: {
    questionnaireId: string
    entries: SidebarEntry[]
  }
}

export const SIDEBAR_QUESTIONS = gql`
  query sidebarQuestions($projectId: ID!, $surveyId: ID) {
    draftQuestionnaire: draftQuestionnaireV2(
      projectId: $projectId
      surveyId: $surveyId
    ) {
      questionnaireId
      entries {
        id
        number
        position
        contextPosition
        entryType
        entryItem {
          ... on DraftQuestionItem {
            questionLk
            question {
              ...Question
            }
            __typename
          }
          ... on DraftMatrixItem {
            matrixTitleLk
            matrixTitle {
              matrixTitleId
              title
            }
            __typename
          }
          ... on DraftTextCardItem {
            textCardLk
            textCard {
              textCardId
              title
              subtitle
              body
            }
            __typename
          }
          ... on DraftForkItem {
            fork {
              forkId
              name
            }
            __typename
          }
          ... on DraftSectionItem {
            sectionId
            title
            entries {
              id
              number
              position
              contextPosition
              entryType
              sectionId
              entryItem {
                ... on DraftQuestionItem {
                  questionLk
                  question {
                    __typename
                    questionId
                    text
                    createdDate
                  }
                  __typename
                }
                ... on DraftMatrixItem {
                  matrixTitleLk
                  matrixTitle {
                    matrixTitleId
                    title
                  }
                  __typename
                }
                ... on DraftTextCardItem {
                  textCardLk
                  textCard {
                    textCardId
                    title
                    subtitle
                    body
                  }
                  __typename
                }
                ... on DraftForkItem {
                  fork {
                    forkId
                    name
                  }
                  __typename
                }
              }
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`
