import { DataFormat } from '../data/model/shared'
import UserSession from '../utils/UserSession'
import axios from '../utils/axiosWithRetry'

const RAW_DATA_CLOUD_FUNCTION_URL = `${process.env.REACT_APP_RAW_DATA_DOWNLOAD_URL}/fd-core`

interface Data {
  getRawData: (surveyId: string, dataFormat: DataFormat) => Promise<string>
}

const getDimensionFromDataFormat: (dataFormat: DataFormat) => string = (
  dataFormat
) => {
  switch (dataFormat) {
    case DataFormat.WIDE_ENCODED:
    case DataFormat.WIDE_UNENCODED:
    case DataFormat.SPSS:
    case DataFormat.XLSX_UNENCODED:
      return 'WIDE'
    case DataFormat.LONG_ENCODED:
    case DataFormat.LONG_UNENCODED:
    default:
      return 'LONG'
  }
}

const getFormatFromDataFormat: (dataFormat: DataFormat) => string = (
  dataFormat
) => {
  switch (dataFormat) {
    case DataFormat.SPSS:
      return 'SPSS'
    case DataFormat.WIDE_ENCODED:
    case DataFormat.LONG_ENCODED:
      return 'CSV_ENCODED'
    case DataFormat.XLSX_UNENCODED:
      return 'XLSX_UNENCODED'
    case DataFormat.WIDE_UNENCODED:
    case DataFormat.LONG_UNENCODED:
    default:
      return 'CSV_UNENCODED'
  }
}

const useRawDataDownload: () => Data = () => {
  const getRawData: (
    surveyId: string,
    dataFormat: DataFormat
  ) => Promise<string> = async (surveyId, dataFormat) => {
    const dimension = getDimensionFromDataFormat(dataFormat)
    const format = getFormatFromDataFormat(dataFormat)
    const rawDataRequestObject = {
      survey_id: surveyId,
      dimension,
      fileformat: format
    }
    const userEmail = UserSession.getEmail() ?? ''

    const res = await axios.post(
      RAW_DATA_CLOUD_FUNCTION_URL,
      JSON.stringify(rawDataRequestObject),
      {
        withCredentials: false,
        transformRequest: [
          (data) => {
            return data
          }
        ],
        headers: {
          'X-Client-Identifier': userEmail
        }
      }
    )

    if (res.status === 200) {
      return res.data
    }

    return Promise.reject()
  }

  return { getRawData }
}

export default useRawDataDownload
