import {
  TablePagination as MuiTablePagination,
  TablePaginationProps
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'

export const TablePagination = (props: TablePaginationProps) => {
  return (
    <ThemeProvider theme={createTheme()}>
      <MuiTablePagination component="div" {...props} />
    </ThemeProvider>
  )
}
