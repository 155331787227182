import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SurveyParams } from '../data/model/surveyParams'
import { isURL } from '../utils/HelperFunctions'
import UserSession from '../utils/UserSession'
import { AccessToken } from '../utils/authUtils'

const RETARGETING_ENDPOINT = `${
  process.env.REACT_APP_URL_DASHBOARD || '/v1/dashboard'
}/api/v1.0/retargeting`

interface RetargetingData {
  setRespondentIds: (respondentIds: string[]) => void
  isSuccess: boolean
  error: boolean
  loading: boolean
}

interface RetargetingApiData {
  isSuccess: boolean
}

const useRetargeting: () => RetargetingData = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const accountId = UserSession.maybeGetAccountId()

  const setRespondentIds: (respondentIds: string[]) => void = (
    respondentIds
  ) => {
    setLoading(true)
    const postPayload = {
      surveyId,
      accountId,
      focaldataRespondentIds: respondentIds
    }

    const axiosConfig = {
      headers: {
        ...AccessToken.requestHeaders(),
        'content-type': 'application/json'
      }
    }

    const requestUrl = `${
      isURL(RETARGETING_ENDPOINT) ? '' : process.env.REACT_APP_REST_DOMAIN
    }${RETARGETING_ENDPOINT}`

    axios
      .post<RetargetingApiData>(requestUrl, postPayload, axiosConfig)
      .then((data: AxiosResponse<RetargetingApiData>) => {
        if (data.status >= 200 && data.status < 400) {
          setError(false)
          setIsSuccess(true)
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (error) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { setRespondentIds, isSuccess, error, loading }
}

export default useRetargeting
