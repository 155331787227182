import { ApolloError } from '@apollo/client'
import {
  FieldworkAudienceType,
  MemberSettingCode
} from '../../data/model/audience'
import { getIsEnabledInSettings } from '../../modules/Audience/utils'
import useGetDraftAudience from './useGetDraftAudience'

export interface CountAudienceQuestion {
  totalNumberAudienceQuestions: number
  numberStandardAudienceQuestions: number
  numberCustomAudienceQuestions: number
  loadingCountAudienceQuestions: boolean
  totalNumberAudienceQuestionsAddedToSurvey: number
  errorCountAudienceQuestions: ApolloError | undefined
}

const useAudienceQuestionsCounter = (): CountAudienceQuestion => {
  const {
    draftAudience,
    loading: loadingCountAudienceQuestions,
    error: errorCountAudienceQuestions
  } = useGetDraftAudience()

  const totalNumberAudienceQuestionsAddedToSurvey =
    draftAudience?.members.filter(
      (dra) =>
        !getIsEnabledInSettings(
          dra.memberItem.settingValues,
          MemberSettingCode.HideSurveyQuestion
        )
    ).length || 0

  const totalNumberAudienceQuestions = draftAudience?.members.length || 0

  const numberCustomAudienceQuestions =
    draftAudience?.members.filter(
      (audienceQuestion) =>
        audienceQuestion.memberType === FieldworkAudienceType.Custom
    ).length || 0

  const numberStandardAudienceQuestions =
    draftAudience?.members.filter(
      (audienceQuestion) =>
        audienceQuestion.memberType === FieldworkAudienceType.Standard
    ).length || 0

  return {
    totalNumberAudienceQuestions,
    numberStandardAudienceQuestions,
    numberCustomAudienceQuestions,
    loadingCountAudienceQuestions,
    totalNumberAudienceQuestionsAddedToSurvey,
    errorCountAudienceQuestions
  }
}

export default useAudienceQuestionsCounter
