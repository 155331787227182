import { Grid, PaperGroupItem } from '@focaldata/cin-ui-components'
import React from 'react'
import { CardBackgroundContainer } from '../../modules/Questionnaire/CardBackground'

interface Props {
  questionCardHeader: JSX.Element
  mediaUploader?: JSX.Element
  freeTextInput: JSX.Element
  entryId: string
  hasMedia: boolean
}

const FreeTextLayout: React.FC<Props> = (props: Props) => {
  const {
    questionCardHeader,
    mediaUploader,
    freeTextInput,
    entryId,
    hasMedia
  } = props

  return (
    <Grid container>
      <CardBackgroundContainer entryId={entryId}>
        <PaperGroupItem>{questionCardHeader}</PaperGroupItem>
        <PaperGroupItem
          collapsed={!hasMedia}
          paddingTopBottom={hasMedia ? 2 : 0}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ minWidth: '359px' }}
          >
            {mediaUploader}
          </Grid>
        </PaperGroupItem>
        {freeTextInput}
      </CardBackgroundContainer>
    </Grid>
  )
}

export default FreeTextLayout
