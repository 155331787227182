import { Grid } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'

import useStyles from './styles'

interface Props {
  leftChild?: JSX.Element
  rightChild?: JSX.Element
  maxWidth: string
  height?: number
  spaceBetweenChildren?: boolean
  justifyContent?: 'flex-start' | 'flex-end'
  showDivider?: boolean
}

const TwoChildLayout: React.FC<Props> = (props: Props) => {
  const {
    leftChild,
    rightChild,
    spaceBetweenChildren = false,
    maxWidth,
    height,
    justifyContent,
    showDivider = false
  } = props
  const { classes } = useStyles({ styleProps: { maxWidth, height } })

  return (
    <Grid
      container
      direction="row"
      justifyContent={justifyContent}
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs="auto" className={classes.leftChild}>
        {leftChild}
      </Grid>
      <Grid item>
        {showDivider && (
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
        )}
      </Grid>
      <Grid
        className={
          spaceBetweenChildren
            ? classes.spaceBetweenChildren
            : classes.rightChild
        }
        item
        xs="auto"
      >
        {rightChild}
      </Grid>
    </Grid>
  )
}

export default TwoChildLayout
