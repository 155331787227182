import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  projectLoading: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(1.875, 0, 1.875, 3),
    marginBottom: theme.spacing(2)
  },

  project: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.25),
    cursor: 'pointer',
    padding: theme.spacing(1.75, 0, 1.75, 3),
    marginBottom: theme.spacing(2),
    '&:active': {
      borderColor: theme.palette.secondary.main
    }
  },
  projectText: {
    paddingTop: 2,
    '&:hover': {
      color: additionalPalette.grey.secondary
    }
  }
})

export default useStyles
