import {
  Grid,
  Menu,
  MenuOption,
  MenuVariant,
  Switch,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { EventType } from '../../../../amplitude/eventType'
import { LogPublicAmplitudeEvent } from '../../../../amplitude/public'
import useStyles from './PublicResultsOptions.styles'

interface Props {
  isMultiMarket: boolean
  disableShowQuotas: boolean
  onShowQuotasChange: (show: boolean) => void
  onClickChartData: () => void
  onClickExportToPptx: () => void
}

const PublicResultsOptionsControl: React.FC<Props> = ({
  isMultiMarket,
  disableShowQuotas,
  onShowQuotasChange,
  onClickChartData,
  onClickExportToPptx
}: Props) => {
  const { classes } = useStyles()

  const onChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    onShowQuotasChange(event.target.checked)
    if (event.target.checked) {
      LogPublicAmplitudeEvent(EventType.ShowQuotasFromPublicResults)
    }
  }

  const options = [
    {
      text: 'Download chart data',
      onClick: onClickChartData
    },
    {
      text: 'Export as presentation',
      onClick: onClickExportToPptx
    }
  ]

  const requestDataMenuOptions: MenuOption[] = [
    {
      id: 0,
      options
    }
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs="auto">
        <Grid container direction="row" alignItems="flex-end">
          <Grid item xs="auto" className={classes.resultsText}>
            <Text
              weight={TextWeight.SemiBold}
              highlight={TextHighlight.Standard}
              size={TextSize.lxl}
              marginRight
            >
              Results
            </Text>
          </Grid>
          {!isMultiMarket && (
            <>
              <Grid item xs="auto">
                <Text
                  weight={TextWeight.Regular}
                  highlight={TextHighlight.Background}
                  size={TextSize.m}
                >
                  Show quotas
                </Text>
              </Grid>
              <Switch first onChange={onChange} disabled={disableShowQuotas} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs="auto" className={classes.rightContainer1}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs="auto" className={classes.requestData}>
            <Menu
              variant={MenuVariant.Body}
              menuOptions={requestDataMenuOptions}
              title="Export data"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PublicResultsOptionsControl
