import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  questionLayoutContainerNotOver: {
    width: '100%',
    paddingBottom: theme.spacing(4)
  },
  questionLayoutContainerOver: {
    width: '100%',
    paddingBottom: theme.spacing(1.5)
  },
  bottomActionsLayout: {
    marginTop: `-${theme.spacing(2.5)}`
  }
})

export default useStyles
