import { QueryHookOptions, useQuery } from '@apollo/client'
import { GetFeasibilityEstimateV2Query } from '../data/gql-gen/questionnaire/graphql'
import { GET_FEASIBILITY_ESTIMATE } from '../data/gql/questionnaire/queries/getFeasibilityEstimate'
import { LoggerErrorType } from '../data/logger'
import { captureApolloError } from '../utils/HelperFunctions'
import { useProjectId } from './useProjectId'
import { useSurveyId } from './useSurveyId'

export const useGetFeasibilityEstimate = (options?: QueryHookOptions) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const { variables, ...restOptions } = options ?? {}

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useQuery<GetFeasibilityEstimateV2Query>(GET_FEASIBILITY_ESTIMATE, {
      context: { clientName: 'questionnaire' },
      variables: {
        projectId,
        surveyId,
        ...variables
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloQuery,
          'getFeasibilityEstimate',
          error
        )
      },
      ...restOptions
    })

  return {
    feasibilityEstimate: data?.getFeasibilityEstimate,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling
  }
}
