import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  Paper
} from '@focaldata/cin-ui-components'
import React from 'react'
import DragAndDrop from '../../../components/DragAndDrop-react-dnd/DragAndDrop'
import { AddQuestionMenu } from '../AddQuestionMenu'
import { useDragAndDrop } from '../useDragAndDrop.hooks'
import useStyles from './EmptySection.styles'

interface Props {
  sectionId: string
  nextPosition: number
}

const EmptySection: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()
  const { sectionId, nextPosition } = props
  const moveCard = useDragAndDrop()

  return (
    <DragAndDrop
      position={nextPosition}
      type="section"
      moveCard={moveCard}
      path={`${sectionId}/${nextPosition}`}
      accept={['basic']}
    >
      <Paper className={classes.paper}>
        <div
          className={classNames(
            'fd-grid',
            'fd-container',
            'fd-column',
            'fd-align-items-center',
            'fd-justify-items-center',
            classes.root
          )}
        >
          <Icon
            name={IconName.AddQuestionEmptyState}
            iconColor={IconColor.Background}
            size={IconSize.Large}
            className={classes.emptyIcon}
          />
          <AddQuestionMenu
            showAddButton
            showSectionMenu={false}
            position={nextPosition}
            sectionId={sectionId}
          />
        </div>
      </Paper>
    </DragAndDrop>
  )
}

export default EmptySection
