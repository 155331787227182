import { Theme, makeStyles } from '@focaldata/cin-ui-components'

export interface StyleProps {
  position: 'fixed' | 'relative'
  width: string
}

const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps }) => ({
    grid: {
      zIndex: 1,
      position: styleProps.position,
      bottom: 0,
      width: styleProps.width || '100%',
      height: theme.spacing(7),
      borderRadius: theme.spacing(0),
      boxShadow: `0 -${theme.spacing(0.125)} ${theme.spacing(
        0.75
      )} 0 rgba(0,0,0,0.1)`,
      padding: theme.spacing(0, 4),
      backgroundColor: theme.palette.background.default
    },
    button: {
      height: theme.spacing(5)
    },
    link: {
      paddingLeft: theme.spacing(2)
    },
    linkText: {
      fontSize: 16,
      color: theme.palette.text.secondary, // This is for the underline color
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline',
      margin: 0,
      padding: 0
    },
    iconButton: {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark
      }
    }
  })
)

export default useStyles
