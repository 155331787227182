import { Select, SelectVariant } from '@focaldata/cin-ui-components'
import { ChangeEvent, memo } from 'react'
import { useStyles } from './MappingResponseOption.styles'

export const MarketLevelResponseOption = memo(
  function MarketLevelResponseOption({
    value,
    mappingId,
    mappingOptions,
    onMapResponseOption
  }: {
    value: string
    mappingId: string
    mappingOptions: { mappingId: string; mappingNumber: number }[]
    onMapResponseOption: (mappingId: string) => void
  }) {
    const { classes, cx: classNames } = useStyles()
    const handleSelectMappingOption = (e: ChangeEvent<HTMLInputElement>) => {
      onMapResponseOption(e.target.value)
    }

    return (
      <div className={classes.root}>
        <Select
          variant={SelectVariant.Filled}
          optionValue={mappingId}
          width={60}
          noWhiteSpaceAfterSelection
          onChange={handleSelectMappingOption}
          options={mappingOptions.map(({ mappingId, mappingNumber }) => ({
            id: mappingId,
            name: `${mappingNumber}`,
            value: mappingId
          }))}
        />
        <span
          className={classNames(classes.responseOption, classes.cursorDefault)}
        >
          {value}
        </span>
      </div>
    )
  }
)
