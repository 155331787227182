import {
  Select,
  SelectOption,
  SelectVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import {
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import {
  ResponseOrderingOptions,
  WithEntry
} from '../../../utils/questionnaireUtils'
import EntryMediaUploader from '../BasicQuestion/MediaUploader/MediaUploader'
import { entryHasStaticLooping } from '../Looping/Looping.utils'
import { MobileViewOnlySwitch } from './MobileViewOnlySwitch'
import useQuestionCardSwitches from './QuestionCardSwitches.hooks'
import useStyles from './styles'

export interface BoolSettingToggler {
  (settingValue: SettingValue.Enabled | SettingValue.Disabled): void
}

interface Props extends WithEntry {
  showMatrixChoiceOptions?: boolean
  showFileUpload?: boolean
  title?: string
  randomiseQuestionSettingCode: QuestionSettingCode
  flipOrderQuestionSettingCode?: QuestionSettingCode
  hasMobileViewOnlySwitch?: boolean
}

export const RESPONSE_ORDERING_SELECTOR = 'Response ordering'
export const MATRIX_CHOICE_SELECTOR = 'Matrix choice'

const QuestionCardSwitches = ({
  entry,
  showMatrixChoiceOptions,
  showFileUpload,
  title,
  randomiseQuestionSettingCode,
  flipOrderQuestionSettingCode,
  hasMobileViewOnlySwitch
}: Props) => {
  const {
    ordering,
    matrixChoiceDefaultSettingValue = SettingValue.SingleChoice,
    showFlipOrder,
    onOrderingChange,
    onChangeMatrixChoice
  } = useQuestionCardSwitches({
    entry,
    randomiseQuestionSettingCode,
    flipOrderQuestionSettingCode
  })

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const matrixChoiceOptions: SelectOption[] = [
    {
      id: '0',
      value: SettingValue.SingleChoice,
      name: 'Single select'
    },
    {
      id: '1',
      value: SettingValue.MultipleChoice,
      name: 'Multi select'
    }
  ]

  const responseOrderingOptions: SelectOption[] = [
    {
      id: '0',
      value: ResponseOrderingOptions.Regular,
      name: 'Fixed order'
    },
    {
      id: '1',
      value: ResponseOrderingOptions.Random,
      name: 'Randomised'
    }
  ]

  if (showFlipOrder) {
    responseOrderingOptions.push({
      id: '2',
      value: ResponseOrderingOptions.FlipOrder,
      name: 'Flip order'
    })
  }

  return (
    <div
      className="fd-grid fd-container fd-row fd-justify-content-between fd-align-items-center"
      style={{ minWidth: '359px' }}
    >
      {entryHasStaticLooping(entry) ? (
        <p>
          <strong>WARNING:</strong> This question is used as the source for a
          static loop and will not be shown to users.
        </p>
      ) : (
        <>
          <div className="fd-grid fd-container fd-xs-auto fd-row fd-justify-content-center fd-align-items-center">
            {title && (
              <div
                className={classNames(
                  'fd-grid fd-item fd-xs-auto',
                  classes.title
                )}
              >
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeMs,
                    textClasses.highlightBackground,
                    textClasses.weightSemiBold
                  )}
                >
                  {title}
                </p>
              </div>
            )}
            <div className="fd-grid fd-item fd-xs-auto fd-justify-content-flex-start">
              <Select
                ariaLabel={RESPONSE_ORDERING_SELECTOR}
                minWidth={130}
                noWhiteSpaceAfterSelection
                optionValue={ordering}
                variant={SelectVariant.Underlined}
                options={responseOrderingOptions}
                onChange={(event) => {
                  const option = event.target.value as ResponseOrderingOptions
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (onOrderingChange) {
                    onOrderingChange(option)
                  }
                }}
              />
            </div>
          </div>
          {showMatrixChoiceOptions && (
            <div className="fd-grid fd-item fd-xs-auto">
              <Select
                ariaLabel={MATRIX_CHOICE_SELECTOR}
                variant={SelectVariant.Underlined}
                minWidth={120}
                optionValue={matrixChoiceDefaultSettingValue}
                options={matrixChoiceOptions}
                onChange={(event) => {
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (onChangeMatrixChoice) {
                    onChangeMatrixChoice(event.target.value as SettingValue)
                  }
                }}
              />
            </div>
          )}
          {showFileUpload && <EntryMediaUploader entry={entry} />}
          {hasMobileViewOnlySwitch && <MobileViewOnlySwitch entry={entry} />}
        </>
      )}
    </div>
  )
}

export default QuestionCardSwitches
