/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  SurveyResultChartEntry: { input: any; output: any; }
  Unit: { input: any; output: any; }
};

export type DraftCombinedQuestion = {
  __typename?: 'DraftCombinedQuestion';
  id: Scalars['ID']['output'];
  mappedResponseOptions: Array<DraftCombinedResponseOption>;
  marketQuestions: Array<DraftMarketQuestion>;
  position: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type DraftCombinedQuestionInput = {
  id: Scalars['ID']['input'];
  mappedResponseOptions: Array<DraftCombinedResponseOptionInput>;
  marketQuestions: Array<DraftMarketQuestionInput>;
  position: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type DraftCombinedResponseOption = {
  __typename?: 'DraftCombinedResponseOption';
  id: Scalars['String']['output'];
  mappingNumber: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type DraftCombinedResponseOptionInput = {
  id: Scalars['String']['input'];
  mappingNumber: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type DraftMarketQuestion = {
  __typename?: 'DraftMarketQuestion';
  id: Scalars['ID']['output'];
  market: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  responseOptions: Array<DraftMarketResponseOption>;
};

export type DraftMarketQuestionInput = {
  id: Scalars['ID']['input'];
  market: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  responseOptions: Array<DraftMarketResponseOptionInput>;
};

export type DraftMarketResponseOption = {
  __typename?: 'DraftMarketResponseOption';
  id: Scalars['String']['output'];
  mappingId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type DraftMarketResponseOptionInput = {
  id: Scalars['String']['input'];
  mappingId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDraftCombinedResponseOption?: Maybe<Scalars['Unit']['output']>;
  bulkAddDraftCombinedResponseOptions: Array<Maybe<DraftCombinedResponseOption>>;
  deleteDraftCombinedResponseOption?: Maybe<Scalars['Unit']['output']>;
  deleteMappingMarketQuestion?: Maybe<Scalars['Unit']['output']>;
  mapResponseOption?: Maybe<Scalars['Unit']['output']>;
  setDraftCombinedQuestion?: Maybe<Scalars['Unit']['output']>;
  setDraftCombinedQuestionTitle: Scalars['String']['output'];
  updateDraftCombinedResponseOption?: Maybe<Scalars['Unit']['output']>;
};


export type MutationBulkAddDraftCombinedResponseOptionsArgs = {
  insertAtIndex: Scalars['Int']['input'];
  values: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationDeleteDraftCombinedResponseOptionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMappingMarketQuestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationMapResponseOptionArgs = {
  id: Scalars['String']['input'];
  mappingId: Scalars['String']['input'];
  marketQuestionId: Scalars['String']['input'];
};


export type MutationSetDraftCombinedQuestionArgs = {
  draftCombinedQuestion: DraftCombinedQuestionInput;
};


export type MutationSetDraftCombinedQuestionTitleArgs = {
  title: Scalars['String']['input'];
};


export type MutationUpdateDraftCombinedResponseOptionArgs = {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  audienceChangedSinceLastIRSNotification: Scalars['Boolean']['output'];
  draftCombinedQuestion: DraftCombinedQuestion;
  draftCombinedQuestionTitle: Scalars['String']['output'];
  surveyResult?: Maybe<SurveyResult>;
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  data?: Maybe<Array<Scalars['SurveyResultChartEntry']['output']>>;
  id: Scalars['ID']['output'];
};

export type AudienceChangedSinceLastSlackIrsNotificationQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type AudienceChangedSinceLastSlackIrsNotificationQueryQuery = { __typename: 'Query', audienceChangedSinceLastIRSNotification: boolean };

export type WriteSurveyResultQueryVariables = Exact<{ [key: string]: never; }>;


export type WriteSurveyResultQuery = { __typename: 'Query', surveyResult?: { __typename: 'SurveyResult', id: string, data?: Array<any> | null } | null };

export type ReadSurveyResultQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadSurveyResultQuery = { __typename: 'Query', surveyResult?: { __typename: 'SurveyResult', data?: Array<any> | null } | null };

export type SetDraftCombinedQuestionMutationVariables = Exact<{
  draftCombinedQuestion: DraftCombinedQuestionInput;
}>;


export type SetDraftCombinedQuestionMutation = { __typename: 'Mutation', setDraftCombinedQuestion?: any | null };

export type SetDraftCombinedQuestionTitleMutationVariables = Exact<{
  title: Scalars['String']['input'];
}>;


export type SetDraftCombinedQuestionTitleMutation = { __typename: 'Mutation', setDraftCombinedQuestionTitle: string };

export type GetDraftCombinedQuestionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDraftCombinedQuestionQuery = { __typename: 'Query', draftCombinedQuestion: { __typename: 'DraftCombinedQuestion', id: string, title: string, position: number, mappedResponseOptions: Array<{ __typename: 'DraftCombinedResponseOption', id: string, mappingNumber: number, value: string }>, marketQuestions: Array<{ __typename: 'DraftMarketQuestion', id: string, market: string, position: number, responseOptions: Array<{ __typename: 'DraftMarketResponseOption', id: string, mappingId?: string | null, value: string }> }> } };

export type AddDraftCombinedResponseOptionMutationVariables = Exact<{ [key: string]: never; }>;


export type AddDraftCombinedResponseOptionMutation = { __typename: 'Mutation', addDraftCombinedResponseOption?: any | null };

export type DeleteDraftCombinedResponseOptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDraftCombinedResponseOptionMutation = { __typename: 'Mutation', deleteDraftCombinedResponseOption?: any | null };

export type BulkAddDraftCombinedResponseOptionsMutationVariables = Exact<{
  values: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  insertAtIndex: Scalars['Int']['input'];
}>;


export type BulkAddDraftCombinedResponseOptionsMutation = { __typename: 'Mutation', bulkAddDraftCombinedResponseOptions: Array<{ __typename: 'DraftCombinedResponseOption', id: string, mappingNumber: number, value: string } | null> };

export type UpdateDraftCombinedResponseOptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;


export type UpdateDraftCombinedResponseOptionMutation = { __typename: 'Mutation', updateDraftCombinedResponseOption?: any | null };

export type DeleteMappingMarketQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteMappingMarketQuestionMutation = { __typename: 'Mutation', deleteMappingMarketQuestion?: any | null };

export type MapResponseOptionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  marketQuestionId: Scalars['String']['input'];
  mappingId: Scalars['String']['input'];
}>;


export type MapResponseOptionMutation = { __typename: 'Mutation', mapResponseOption?: any | null };


export const AudienceChangedSinceLastSlackIrsNotificationQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"audienceChangedSinceLastSlackIRSNotificationQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"audienceChangedSinceLastIRSNotification"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<AudienceChangedSinceLastSlackIrsNotificationQueryQuery, AudienceChangedSinceLastSlackIrsNotificationQueryQueryVariables>;
export const WriteSurveyResultDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WriteSurveyResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"surveyResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]} as unknown as DocumentNode<WriteSurveyResultQuery, WriteSurveyResultQueryVariables>;
export const ReadSurveyResultDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ReadSurveyResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"surveyResult"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"data"}}]}}]}}]} as unknown as DocumentNode<ReadSurveyResultQuery, ReadSurveyResultQueryVariables>;
export const SetDraftCombinedQuestionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetDraftCombinedQuestion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draftCombinedQuestion"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DraftCombinedQuestionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"setDraftCombinedQuestion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draftCombinedQuestion"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draftCombinedQuestion"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<SetDraftCombinedQuestionMutation, SetDraftCombinedQuestionMutationVariables>;
export const SetDraftCombinedQuestionTitleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetDraftCombinedQuestionTitle"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"setDraftCombinedQuestionTitle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<SetDraftCombinedQuestionTitleMutation, SetDraftCombinedQuestionTitleMutationVariables>;
export const GetDraftCombinedQuestionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDraftCombinedQuestion"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"draftCombinedQuestion"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"mappedResponseOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mappingNumber"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"Field","name":{"kind":"Name","value":"marketQuestions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"market"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"responseOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mappingId"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetDraftCombinedQuestionQuery, GetDraftCombinedQuestionQueryVariables>;
export const AddDraftCombinedResponseOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddDraftCombinedResponseOption"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"addDraftCombinedResponseOption"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<AddDraftCombinedResponseOptionMutation, AddDraftCombinedResponseOptionMutationVariables>;
export const DeleteDraftCombinedResponseOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDraftCombinedResponseOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"deleteDraftCombinedResponseOption"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<DeleteDraftCombinedResponseOptionMutation, DeleteDraftCombinedResponseOptionMutationVariables>;
export const BulkAddDraftCombinedResponseOptionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BulkAddDraftCombinedResponseOptions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"values"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"insertAtIndex"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"bulkAddDraftCombinedResponseOptions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"values"},"value":{"kind":"Variable","name":{"kind":"Name","value":"values"}}},{"kind":"Argument","name":{"kind":"Name","value":"insertAtIndex"},"value":{"kind":"Variable","name":{"kind":"Name","value":"insertAtIndex"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mappingNumber"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<BulkAddDraftCombinedResponseOptionsMutation, BulkAddDraftCombinedResponseOptionsMutationVariables>;
export const UpdateDraftCombinedResponseOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDraftCombinedResponseOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"value"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"updateDraftCombinedResponseOption"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"value"},"value":{"kind":"Variable","name":{"kind":"Name","value":"value"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<UpdateDraftCombinedResponseOptionMutation, UpdateDraftCombinedResponseOptionMutationVariables>;
export const DeleteMappingMarketQuestionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteMappingMarketQuestion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"deleteMappingMarketQuestion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<DeleteMappingMarketQuestionMutation, DeleteMappingMarketQuestionMutationVariables>;
export const MapResponseOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MapResponseOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"marketQuestionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mappingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"mapResponseOption"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"marketQuestionId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"marketQuestionId"}}},{"kind":"Argument","name":{"kind":"Name","value":"mappingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mappingId"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}]}]}}]} as unknown as DocumentNode<MapResponseOptionMutation, MapResponseOptionMutationVariables>;