import {
  Text,
  TextAlign,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import InsightsImg from '../../../../../assets/fdchat/results/insights.svg'
import { useSurveyId } from '../../../../../hooks/useSurveyId'
import { useFdChatProgress } from '../../../../../modules/FdChat/FdChatProgressProvider.context'
import styles from './AskAi.module.css'
import AskAiBar from './AskAiBar'
import ExampleQuestionChip from './ExampleQuestionChip'

const starterQuestions: {
  label: string
  icon: string
  question?: string
}[] = [
  {
    label: 'Explore results summary',
    icon: '🚀',
    question:
      'Please provide an overview of the main findings, including key insights and trends from the interviews'
  },
  {
    label: 'See main interview themes',
    icon: '📖',
    question:
      'Could you identify recurring topics and primary themes emerging from the interviews, along with any specific issues or concerns mentioned by the interviewees?'
  },
  {
    label: 'See key quotes',
    icon: '💭',
    question:
      'Please highlight standout quotes from the interviews that capture essential themes or insights, providing context and relevance to the research objectives'
  }
]

export const ASK_AI_ENABLED_THRESHOLD = process.env
  .REACT_APP_FD_CHAT_ASK_AI_ENABLED_THRESHOLD
  ? Number(process.env.REACT_APP_FD_CHAT_ASK_AI_ENABLED_THRESHOLD)
  : 5

const AskAi = () => {
  const surveyId = useSurveyId()
  const [question, setQuestion] = useState('')
  const navigate = useNavigate()
  const { dataAvailable } = useFdChatProgress()

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedFdChatAskAiPage, { surveyId })
  }, [surveyId])

  const handleAsk = async ({
    question,
    amplitudeEvent,
    label
  }: {
    question: string
    amplitudeEvent: EventType
    label?: string
  }) => {
    LogAmplitudeEvent(amplitudeEvent, {
      surveyId,
      question: label ?? question
    })

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          goToSearch({ question, label })
        })
      })
      return
    }

    goToSearch({ question, label })
  }

  const goToSearch = ({
    question,
    label
  }: {
    question: string
    label?: string
  }) => {
    navigate('search', {
      state: {
        question,
        label
      }
    })
  }

  return (
    <Container
      className={styles['ask-ai']}
      sx={{ paddingY: '100px' }}
      maxWidth="md"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        gap="10px"
        marginBottom="48px"
      >
        <img src={InsightsImg} alt="" />
        <Text
          size={TextSize.xl}
          weight={TextWeight.Bold}
          align={TextAlign.Center}
        >
          Ask questions to uncover insights
        </Text>
      </Grid>
      <AskAiBar
        inputValue={question}
        onInputChange={(_, value) => {
          setQuestion(value)
        }}
        onSelectOption={(_, value) => {
          handleAsk({
            question: value,
            amplitudeEvent: EventType.ClickedFdChatAskAiSuggestedQuestion
          })
        }}
        placeholder={'Ask a question to explore the data collected so far'}
        onAskAi={() => {
          handleAsk({
            question,
            amplitudeEvent: EventType.ClickedFdChatAskAiButton
          })
        }}
        hideOptions={!dataAvailable}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          flexWrap: 'wrap'
        }}
      >
        <p style={{ color: '#565D6D' }}>Start here:</p>
        {starterQuestions.map((question) => (
          <ExampleQuestionChip
            key={question.label}
            icon={question.icon}
            label={question.label}
            onClick={() => {
              handleAsk({
                question: question.question ?? question.label,
                amplitudeEvent: EventType.ClickedFdChatAskAiExampleQuestion,
                label: question.label
              })
            }}
            disabled={!dataAvailable}
          />
        ))}
      </div>
    </Container>
  )
}

export default AskAi
