import { Grid, Switch } from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { useCustomCriterionValidationErrors } from '../AudienceQuestionCard/useCustomCriterionValidationErrors'
import { CARD_FOOTER_ROW_HEIGHT } from './AudienceQuestionCardFooter'
import { CardFooterErrorRow } from './CardFooterErrorRow'
import { useCustomCriterionOptionLogicActions } from './CustomCriterionOptionLogic.hooks'
import { MaxDisplayedOptionsSettings } from './MaxDisplayedOptionsSettings'
import { useResponseOptionsLogic } from './useResponseOptionsLogic'

const DEFAULT_MAX_DISPLAYED_OPTIONS = 2

export const MaxDisplayedOptions = ({ questionLk }: { questionLk: string }) => {
  const responseOptionsLogicCache = useResponseOptionsLogic(questionLk)
  const [responseOptionsLogic, setResponseOptionsLogic] = useState(
    responseOptionsLogicCache
  )

  const maxDisplayedOptionsEnabled = !!responseOptionsLogic

  const setCustomCriterionOptionsLogic = useCustomCriterionOptionLogicActions()

  const { errorMessage } = useCustomCriterionValidationErrors(questionLk)

  const handleChangeMaxDisplayedOptionsEnabled = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked
      ? {
          maxResponseOptionsToDisplay: DEFAULT_MAX_DISPLAYED_OPTIONS,
          setAsRandomised: true
        }
      : null
    const result = await setCustomCriterionOptionsLogic(questionLk, value)
    setResponseOptionsLogic(result)
  }

  return (
    <>
      <Grid display="flex" alignItems="center" height={CARD_FOOTER_ROW_HEIGHT}>
        <Switch
          first
          label="Max displayed options"
          checked={maxDisplayedOptionsEnabled}
          onChange={handleChangeMaxDisplayedOptionsEnabled}
        />
        {maxDisplayedOptionsEnabled ? (
          <MaxDisplayedOptionsSettings
            questionLk={questionLk}
            responseOptionsLogic={responseOptionsLogic}
          />
        ) : null}
      </Grid>
      {errorMessage ? <CardFooterErrorRow errorMessage={errorMessage} /> : null}
    </>
  )
}
