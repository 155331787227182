import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { AddQuestionMenu } from '../AddQuestionMenu'
import useStyles from './EmptyQuestionnaire.styles'

const EmptyQuestionnaire: React.FC = () => {
  const { classes } = useStyles()

  return (
    <div
      className="fd-grid fd-container fd-column fd-align-items-center fd-justify-content-center"
      data-testid="empty-questions-list-wrapper"
    >
      <Icon
        name={IconName.AddQuestionEmptyState}
        iconColor={IconColor.Background}
        size={IconSize.Large}
      />
      <Text
        className={classes.text}
        weight={TextWeight.SemiBold}
        size={TextSize.ml}
        highlight={TextHighlight.Background}
      >
        Add your first survey question
      </Text>
      <AddQuestionMenu showAddButton showSectionMenu position={0} />
    </div>
  )
}

export default EmptyQuestionnaire
