import axios, { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import requestWithRetry from '../../../utils/requestWithRetry'

interface Vars {
  surveyId: string
}

interface Data {
  publishSurvey: () => Promise<string | undefined>
  unpublishSurvey: () => Promise<boolean>
}

const PUBLISH_SURVEY_URL = process.env.REACT_APP_PUBLISH_RESULTS_DOMAIN

export const useSurveyPublish = ({ surveyId }: Vars): Data => {
  const publishSurvey = useCallback(async (): Promise<string | undefined> => {
    try {
      const data = await axios({
        method: 'get',
        url: `${PUBLISH_SURVEY_URL}/?surveyId=${surveyId}`
      })

      if (data.status >= 200 && data.status < 400) {
        return data.data as string
      }
    } catch (_) {
      return undefined
    }

    return undefined
  }, [surveyId])

  const unpublishSurvey = useCallback(async (): Promise<boolean> => {
    try {
      const data = await axios({
        method: 'get',
        url: `${PUBLISH_SURVEY_URL}/?surveyId=${surveyId}&unpublish=true`
      })

      if (data.status >= 200 && data.status < 400) {
        return true
      }
    } catch (_) {
      return false
    }

    return false
  }, [surveyId])

  return { publishSurvey, unpublishSurvey }
}

const PUBLISHED_STATE_ENDPOINT = '/v2/survey-results/api/v1.0/publishedState'

interface PublishedState {
  isPublished: boolean
  publicSurveyId: string
}

interface PublishedStateData {
  loading: boolean
  error: boolean
  data: PublishedState | undefined
}

export const usePublishedState = ({
  surveyId
}: {
  surveyId: string
}): PublishedStateData => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [data, setData] = useState<PublishedState>()

  useEffect(() => {
    setLoading(true)

    requestWithRetry({
      method: 'get',
      url: `${process.env.REACT_APP_REST_DOMAIN}${PUBLISHED_STATE_ENDPOINT}?surveyId=${surveyId}`
    })
      .then((data: AxiosResponse<PublishedState>) => {
        if (data.status >= 200 && data.status < 400) {
          setError(false)
          setData(data.data)
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [surveyId])

  return { loading, error, data }
}
