import { useAppDispatch } from '../../App.store'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import {
  removeQuestionSetting,
  setQuestionSetting
} from '../../modules/Questionnaire/Questionnaire.slice'
import { getEntryId } from '../../modules/Questionnaire/Questionnaire.utils'
import { useSurveyId } from '../useSurveyId'
import useRemoveQuestionnaireSetting from './useRemoveQuestionnaireSetting'
import useSetQuestionnaireSetting from './useSetQuestionnaireSetting'

const useHandleMultiSelectLimits = (entry: DraftQuestionnaireEntry) => {
  const surveyId = useSurveyId()
  const handleSettingChange = useSetQuestionnaireSetting(entry)
  const removeQuestionnaireSetting = useRemoveQuestionnaireSetting(entry)
  const dispatch = useAppDispatch()
  const entryId = getEntryId(entry)

  const changeSettingValues = async (
    settingValues: {
      settingCode: QuestionSettingCode
      settingValue: string
    }[]
  ) => {
    settingValues.forEach(({ settingCode, settingValue }) => {
      dispatch(
        setQuestionSetting({
          questionLk: entryId,
          code: settingCode,
          value: settingValue
        })
      )
    })

    await handleSettingChange(settingValues, 'no-cache')
  }

  const handleIntervalSettingsChange = async (min: number, max: number) => {
    if (min === 0 && max === 0) {
      dispatch(
        removeQuestionSetting({
          questionLk: entryId,
          settingCode: QuestionSettingCode.ChoiceIntervalMin
        })
      )
      dispatch(
        removeQuestionSetting({
          questionLk: entryId,
          settingCode: QuestionSettingCode.ChoiceIntervalMax
        })
      )
      await removeQuestionnaireSetting([
        QuestionSettingCode.ChoiceIntervalMin,
        QuestionSettingCode.ChoiceIntervalMax
      ])
    } else {
      await changeSettingValues([
        {
          settingCode: QuestionSettingCode.ChoiceIntervalMin,
          settingValue: min.toString()
        },
        {
          settingCode: QuestionSettingCode.ChoiceIntervalMax,
          settingValue: max.toString()
        }
      ])
      LogAmplitudeEvent(EventType.AddChoiceResponseRange, {
        surveyId,
        entryId,
        range: `${min}-${max}`
      })
    }
  }

  const handleQuestionLimitChange = async (
    settingCode: QuestionSettingCode,
    settingValue: number
  ) => {
    if (settingValue === 0) {
      await removeQuestionnaireSetting([settingCode])
      dispatch(removeQuestionSetting({ questionLk: entryId, settingCode }))
    } else {
      await changeSettingValues([
        { settingCode, settingValue: settingValue.toString() }
      ])
    }
  }
  return { handleIntervalSettingsChange, handleQuestionLimitChange }
}

export default useHandleMultiSelectLimits
