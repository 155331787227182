import React from 'react'
import { useParams } from 'react-router-dom'
import { SignupSuccessful as SignupSuccessfulControl } from '../../../components/Auth'

const SignupSuccessful: React.FC = () => {
  const { email = 'no-email-found' } = useParams<{ email: string }>()

  const handleClick: () => void = () => {
    window.open('https://www.focaldata.com', '_blank')
  }

  return <SignupSuccessfulControl email={email} onClickButton={handleClick} />
}

export default SignupSuccessful
