import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  containerGrid: {
    paddingTop: theme.spacing(15),
    width: 'calc(100% - 56px)'
  }
})

export default useStyles
