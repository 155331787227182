import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  selectedEntries: {
    margin: theme.spacing(1, 4, 2, 4)
  },
  audienceCriteria: {
    margin: theme.spacing(1, 5, 2, 4)
  },
  searchBarContainer: {
    padding: theme.spacing(1, 4, 0, 4)
  },
  filterTitleContainer: {
    padding: theme.spacing(4, 4, 0, 4)
  },
  filterBlockContainer: {
    marginBottom: theme.spacing(2)
  },
  resultsContainer: {
    zIndex: 2
  },
  checkbox: {
    width: theme.spacing(5)
  },
  checkboxContainer: {
    width: theme.spacing(11)
  },
  prefixContainer: {
    paddingRight: theme.spacing(0.75),
    paddingTop: theme.spacing(1)
  },
  resultsText: {
    paddingTop: theme.spacing(1)
  }
}))

export default useStyles
