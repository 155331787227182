import { makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    background: 'black',
    width: '56px',
    height: '100vh'
  },
  imageHome: {
    paddingTop: '0 !important',
    width: '56px'
  },
  userAvatarContainer: {
    marginBottom: '16px'
  }
})

export default useStyles
