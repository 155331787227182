import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  emptyInnerDiv: {
    minHeight: theme.spacing(18)
  },
  emptyOuterDiv: {
    minHeight: theme.spacing(30)
  },
  emptyPaperStyle: {
    width: '100%',
    minHeight: theme.spacing(30)
  }
}))

export default useStyles
