import { Grid } from '@focaldata/cin-ui-components'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { DraftQuestionnaireEntry } from '../../../data/model/questionnaire'
import { RawQuestionnaireEntry } from '../../../hooks/import/models'
import useImportListPreview from './ImportListPreview.hooks'
import QuestionPreview from './QuestionPreview'
import { Pair } from './import.utils'

interface Props {
  questionnaireQuestions: DraftQuestionnaireEntry[]
  importedQuestions: RawQuestionnaireEntry[]
  onMappingChanged?: (pairs: Pair[]) => void
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  border: '1px solid grey'
}))

const ImportListPreview: React.FC<Props> = ({
  questionnaireQuestions,
  importedQuestions,
  onMappingChanged
}: Props) => {
  const {
    pairs,
    isQuestionnaireEmpty,
    handleMoveUp,
    handleMoveDown,
    handleMoveResponseUp,
    handleMoveResponseDown,
    handleMoveRowUp,
    handleMoveRowDown,
    handleOnSelectionChanged,
    handleOnResponseSelectionChanged,
    handleOnRowSelectionChanged
  } = useImportListPreview({
    questionnaireQuestions,
    importedQuestions,
    onMappingChanged
  })

  return (
    <Grid container rowSpacing={2} style={{ marginTop: '20px' }}>
      {pairs.map((pair, index) => (
        <Grid
          container
          item
          columnSpacing={1}
          key={`${pair.item1?.entryId}${pair.item2?.id}`}
        >
          {!isQuestionnaireEmpty && (
            <Grid container item sm={6}>
              <Item style={{ width: '100%', boxSizing: 'border-box' }}>
                <QuestionPreview
                  entry={pair.item1}
                  responsePairs={pair.responsePairs}
                  rowPairs={pair.rowPairs}
                  selected
                  disabled
                />
              </Item>
            </Grid>
          )}
          <Grid container item sm={isQuestionnaireEmpty ? 12 : 6}>
            <Item style={{ width: '100%', boxSizing: 'border-box' }}>
              <QuestionPreview
                rawEntry={pair.item2}
                responsePairs={pair.responsePairs}
                rowPairs={pair.rowPairs}
                handleMoveUp={handleMoveUp}
                handleMoveDown={handleMoveDown}
                handleMoveResponseUp={handleMoveResponseUp}
                handleMoveResponseDown={handleMoveResponseDown}
                handleMoveRowUp={handleMoveRowUp}
                handleMoveRowDown={handleMoveRowDown}
                selectionChanged={(id, selected) =>
                  handleOnSelectionChanged(id, selected)
                }
                responseSelectionChanged={handleOnResponseSelectionChanged}
                rowSelectionChanged={handleOnRowSelectionChanged}
                isQuestionnaireEmpty={isQuestionnaireEmpty}
                showUpArrow={
                  pair.item1 !== undefined ||
                  (pairs[index - 1]?.item1 !== undefined &&
                    pairs[index - 1]?.item2 === undefined)
                }
                showDownArrow={
                  pair.item1 !== undefined ||
                  // @todo Legacy eslint violation – fix this when editing
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  pairs.slice(index + 1)?.some((r) => r.item1 !== undefined)
                }
              />
            </Item>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImportListPreview
