import {
  Accordion,
  AccordionVariant,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LinkButton,
  ListItemAudienceResponseOption,
  Paper,
  PaperGroupItem,
  ScrollElement,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FieldworkAudienceV2Query } from '../../../data/gql-gen/questionnaire/graphql'
import { SurveyParams } from '../../../data/model/surveyParams'
import { CUSTOM_QUESTION_KEY, SCROLL_ID_PREFIX } from '../constants'
import useStyles from './AudienceQuestion.styles'

type CustomAudienceQuestionItem = NonNullable<
  FieldworkAudienceV2Query['fieldworkAudienceV2']
>['entries'][number]['entryItem'] & {
  __typename: 'QuestionItem'
}
interface Props {
  scrollKey: string
  customAudienceEntryItem: CustomAudienceQuestionItem
  audienceQuestionIndex: number
}

const getResponseOptions: (
  customAudienceEntryItem: CustomAudienceQuestionItem | undefined
) => (JSX.Element | null)[] | undefined = (customAudienceEntryItem) => {
  if (customAudienceEntryItem) {
    return customAudienceEntryItem.responseOptions.map(
      (entryResponseOption) => {
        return (
          <ListItemAudienceResponseOption
            responseOptionId={
              entryResponseOption.responseOption.responseOptionId
            }
            key={entryResponseOption.responseOption.responseOptionId}
            disableGutters
            index={0}
            draggableId={`dr${entryResponseOption.responseOption.responseOptionId}`}
            value={entryResponseOption.responseOption.value}
          />
        )
      }
    )
  }
  return undefined
}

const AudienceQuestionContainer: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const { customAudienceEntryItem, audienceQuestionIndex, scrollKey } = props
  const { surveyId } = useParams<keyof SurveyParams>()

  const onClickEdit: () => void = () => {
    navigate(`/survey/${surveyId}/audience`)
  }

  const { classes, cx: classNames } = useStyles()
  const responseOptions = getResponseOptions(customAudienceEntryItem)
  const title = customAudienceEntryItem.question.text
  const identifier = `A${audienceQuestionIndex + 1}`

  return (
    <ScrollElement
      elementId={`${SCROLL_ID_PREFIX}${scrollKey}${CUSTOM_QUESTION_KEY}`}
    >
      <div
        className={classNames('fd-grid', 'fd-container', classes.paddingBottom)}
      >
        <Paper>
          <Accordion
            title={title}
            variant={AccordionVariant.QuestionnaireAudienceQuestion}
            titleDivider
            identifier={identifier}
            name="Audience"
          >
            <PaperGroupItem paddingTopBottom={1}>
              <LinkButton
                size={TextSize.m}
                weight={TextWeight.SemiBold}
                highlight={TextHighlight.Emphasis}
                underlined={false}
                onClick={onClickEdit}
              >
                <div
                  className={classNames(
                    'fd-grid',
                    'fd-container',
                    'fd-align-items-center',
                    'fd-justify-content-center',
                    classes.editInfo
                  )}
                >
                  <div
                    className={classNames(
                      'fd-grid',
                      'fd-item',
                      'fd-xs-auto',
                      classes.icon
                    )}
                  >
                    <Icon
                      iconColor={IconColor.Emphasis}
                      name={IconName.ChevronLeft}
                      size={IconSize.Large}
                    />
                  </div>{' '}
                  <div className="fd-grid fd-item">Edit audience criteria</div>
                </div>
              </LinkButton>
            </PaperGroupItem>
            <PaperGroupItem>{responseOptions}</PaperGroupItem>
          </Accordion>
        </Paper>
      </div>
    </ScrollElement>
  )
}

export default AudienceQuestionContainer
