import { useReactiveVar } from '@apollo/client'
import { memo, useCallback } from 'react'
import { useAppDispatch } from '../../../../App.store'
import { EntryType } from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import {
  addMatrixQuestionRowTransactionDatadog,
  deleteMatrixQuestionRowTransactionDatadog
} from '../../../../tracking/perf/transactions'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import {
  WithEntryOfType,
  propsAreEqual
} from '../../../../utils/questionnaireUtils'
import { matrixRowReordered } from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import {
  useCreateMatrixRow,
  useRemoveMatrixRow,
  useUpdateMatrixRow
} from './MatrixQuestionRows.hooks'
import MatrixQuestionRowsControl from './MatrixQuestionRowsList.control'

const MatrixQuestionRows = (
  props: WithEntryOfType<EntryType.MatrixEntryType>
) => {
  const { entry } = props
  const { matrixTitleLk } = entry.entryItem

  const matrixRows = entry.entryItem.matrixRows
  const dispatch = useAppDispatch()

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()

  const flatEntries = flattenEntries(draftQuestionnaireEntries)

  const maskingEnabled =
    flatEntries.length > 0 && entry.number !== flatEntries[0].number

  const createMatrixRow = useCreateMatrixRow(matrixTitleLk)
  const updateMatrixRow = useUpdateMatrixRow(entry.id)
  const removeMatrixRow = useRemoveMatrixRow(entry.id)

  const handleReorderedQuestionRows: (
    destinationIndex: number | undefined,
    sourceIndex: number | undefined
  ) => void = (destinationIndex, sourceIndex) => {
    if (destinationIndex !== undefined && sourceIndex !== undefined) {
      dispatch(
        matrixRowReordered({
          matrixTitleLk,
          destinationIndex,
          sourceIndex
        })
      )
      updateMatrixRow(
        {
          questionId: matrixRows[sourceIndex].questionLk,
          position: destinationIndex
        },
        {
          fetchPolicy: 'no-cache',
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'updateMatrixQuestionRow',
              error
            )
          }
        }
      )
    }
  }

  const handleAddMatrixQuestionRow: () => void = useCallback(() => {
    addMatrixQuestionRowTransactionDatadog.start()
    createMatrixRow(matrixRows.length)
  }, [createMatrixRow, matrixRows.length])

  const handleAddMatrixQuestionRowOnEnter: (position: number) => void =
    useCallback(
      (position) => {
        const newRowPosition = position + 1
        createMatrixRow(newRowPosition)
      },
      [createMatrixRow]
    )

  const handleDeleteMatrixQuestionRow: (questionLk: string) => void =
    useCallback(
      (questionLk) => {
        deleteMatrixQuestionRowTransactionDatadog.start()
        removeMatrixRow(questionLk)
      },
      [removeMatrixRow]
    )

  const handleChangeMatrixQuestionRow: (
    inputValue: string,
    questionLk: string
  ) => void = useCallback(
    (inputValue, questionLk) => {
      updateMatrixRow(
        {
          questionId: questionLk,
          text: inputValue
        },
        {
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'updateMatrixQuestionRow',
              error
            )
          }
        }
      )
    },
    [updateMatrixRow]
  )

  const newlyAddedRowLk = useReactiveVar(responseOptionLkNewlyAdded)

  return (
    <MatrixQuestionRowsControl
      entry={entry}
      matrixRows={matrixRows}
      maskingEnabled={maskingEnabled}
      onEnter={handleAddMatrixQuestionRowOnEnter}
      newlyAddedRowLk={newlyAddedRowLk}
      onClickAddMatrixQuestionRow={handleAddMatrixQuestionRow}
      onChangeMatrixQuestionRow={handleChangeMatrixQuestionRow}
      onDeleteMatrixQuestionRow={handleDeleteMatrixQuestionRow}
      onReorderedQuestionRows={handleReorderedQuestionRows}
    />
  )
}

export default memo(MatrixQuestionRows, propsAreEqual)
