import {
  InfoIcon,
  InfoIconColor,
  Input,
  InputFontSize,
  LinkButton,
  TextHighlight,
  TextSize,
  TextWeight,
  TooltipPlacement,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './ForkFooter.styles'

interface Props {
  totalForkPercentage: number
  makeEqualBranches: () => void
  onAddGroup?: () => void
}

const ForkFooter: React.FC<Props> = (props: Props) => {
  const { totalForkPercentage, makeEqualBranches, onAddGroup } = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const invalidTotalPercentage = totalForkPercentage !== 100

  return (
    <div className="fd-grid fd-container fd-row fd-align-items-center">
      <div className="fd-grid fd-container fd-item fd-xs-1">
        <LinkButton
          highlight={TextHighlight.Emphasis}
          size={TextSize.m}
          weight={TextWeight.SemiBold}
          onClick={onAddGroup}
          noPadding
        >
          Add group
        </LinkButton>
      </div>
      <div className={classNames('fd-grid fd-item', classes.input)}>
        <div className="fd-grid fd-item fd-xs-10">
          <Input
            value={totalForkPercentage}
            isError={invalidTotalPercentage}
            disabled
            supressHelperText
            inputFontSize={InputFontSize.m}
          />
        </div>
      </div>
      {invalidTotalPercentage ? (
        <InfoIcon
          className={classes.errorIcon}
          iconColor={InfoIconColor.Error}
          text="The total must sum to 100%"
          tooltipPlacement={TooltipPlacement.Right}
        />
      ) : (
        <p
          className={classNames(
            textClasses.default,
            textClasses.highlightBackground,
            textClasses.weightSemiBold,
            classes.percentText
          )}
        >
          %
        </p>
      )}
      <div className="fd-grid fd-container fd-item fd-xs-3">
        <LinkButton
          highlight={TextHighlight.Emphasis}
          size={TextSize.m}
          weight={TextWeight.SemiBold}
          onClick={makeEqualBranches}
          noPadding
        >
          Equalise groups
        </LinkButton>
      </div>
    </div>
  )
}

export default ForkFooter
