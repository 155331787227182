import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  dividerGrid: {
    margin: theme.spacing(4, 0, 4, 0),
    width: '100%'
  }
})

export default useStyles
