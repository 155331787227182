import { ReactNode, createContext, useContext, useMemo } from 'react'
import { useFdChatFieldwork } from '../../containers/Page/FdChat/FdChat.hooks'
import { ASK_AI_ENABLED_THRESHOLD } from '../../containers/Page/FdChat/ResultsPage/AskAi/AskAi'
import useGetSurveysProgress from '../../hooks/useGetSurveysProgress'
import { useSurveyId } from '../../hooks/useSurveyId'

interface FdChatProgressContextValue {
  interviewsCollected: number
  interviewsWanted: number
  loading: boolean
  error: Error | undefined
  dataAvailable: boolean
}

const FdChatProgressContext = createContext<FdChatProgressContextValue>({
  interviewsCollected: 0,
  interviewsWanted: 0,
  loading: true,
  error: undefined,
  dataAvailable: false
})

export const useFdChatProgress = () => {
  return useContext(FdChatProgressContext)
}

export const FdChatProgressProvider = ({
  children
}: {
  children: ReactNode
}) => {
  const surveyId = useSurveyId()
  const { surveyProgress, loading, surveyProgressError } =
    useGetSurveysProgress([surveyId])
  const { fieldwork, fieldworkLoading } = useFdChatFieldwork()

  const samplesCollected = surveyProgress?.[0].progress?.samplesCollected ?? 0
  const samplesWanted =
    surveyProgress?.[0].progress?.samplesWanted ??
    fieldwork?.samplesNeeded.unadjusted ??
    0
  const dataAvailable = samplesCollected >= ASK_AI_ENABLED_THRESHOLD

  const value = useMemo(
    () => ({
      interviewsCollected: samplesCollected,
      interviewsWanted: samplesWanted,
      loading: loading || fieldworkLoading,
      error: surveyProgressError,
      dataAvailable
    }),
    [
      samplesCollected,
      samplesWanted,
      loading,
      fieldworkLoading,
      surveyProgressError,
      dataAvailable
    ]
  )

  return (
    <FdChatProgressContext.Provider value={value}>
      {children}
    </FdChatProgressContext.Provider>
  )
}
