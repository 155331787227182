import { gql, useApolloClient } from '@apollo/client'
import { ChartEntry } from '../../data/model/chart'

const WRITE_SURVEY_RESULT_QUERY = gql`
  query WriteSurveyResult {
    surveyResult {
      id
      data
    }
  }
`
const READ_SURVEY_RESULT_QUERY = gql`
  query ReadSurveyResult {
    surveyResult {
      data
    }
  }
`

const useSurveyResult = () => {
  const client = useApolloClient()

  const writeSurveyResult = (data: ChartEntry[]): void => {
    client.writeQuery({
      query: WRITE_SURVEY_RESULT_QUERY,
      data: {
        surveyResult: {
          __typename: 'SurveyResult',
          id: 'latest',
          data
        }
      }
    })
  }

  const readSurveyResult = (): ChartEntry[] => {
    const queryResult = client.readQuery({
      query: READ_SURVEY_RESULT_QUERY
    })
    return queryResult?.surveyResult?.data ?? []
  }

  return { writeSurveyResult, readSurveyResult }
}

export default useSurveyResult
