import { AskAIAnswerResponseInput } from '@focaldata/askai-client'
import Container from '@mui/material/Container'
import { useRef, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSurveyId } from '../../../../../../hooks/useSurveyId'
import { useAskAiDrawer } from '../../../../../../modules/FdChat/AskAi/AskAi.hooks'
import { useFdChatContainerContent } from '../../../FdChat.hooks'
import Answer from './Answer/Answer'
import AskAiBarContainer from './AskAiBarContainer/AskAiBarContainer'
import AskAiDrawer from './AskAiDrawer/AskAiDrawer'
import Drawer from './Drawer/Drawer'
import FeedbackForm from './FeedbackForm/FeedbackForm'
import Modal from './Modal/Modal'
import styles from './Search.module.css'

export type LocationState = {
  question: string
  label?: string
  answer?: AskAIAnswerResponseInput
} | null

const Search = () => {
  const surveyId = useSurveyId()
  const location = useLocation()
  const locationState = location.state as LocationState
  const [questions, setQuestions] = useState<
    {
      question: string
      label?: string
    }[]
  >(
    locationState?.label || locationState?.question
      ? [{ question: locationState.question, label: locationState.label }]
      : []
  )
  const { drawerOpen } = useAskAiDrawer()
  const { contentRef } = useFdChatContainerContent()
  const modalRef = useRef<HTMLDialogElement | null>(null)
  const [answerForFeedback, setAnswerForFeedback] =
    useState<AskAIAnswerResponseInput>()

  const onAskAi = async ({ question }: { question: string }) => {
    setQuestions((prev) => [...prev, { question }])

    // wait for the next render cycle to ensure the element is rendered
    await new Promise((resolve) => {
      setTimeout(() => {
        // scroll to the bottom of the content
        const content = contentRef.current
        content?.scrollTo({
          top: content.scrollHeight,
          behavior: 'smooth'
        })
        resolve(null)
      }, 0)
    })
  }

  const openFeedbackModal = ({
    answer
  }: {
    answer: AskAIAnswerResponseInput
  }) => {
    // set the answer id for the feedback form and open the modal
    setAnswerForFeedback(answer)
    modalRef.current?.showModal()
  }

  const closeModal = () => {
    // close the modal and reset the answer id
    if (modalRef.current !== null) {
      modalRef.current.close()
      setAnswerForFeedback(undefined)
    }
  }

  // if there are no questions go back to the ask ai page
  if (questions.length === 0)
    return <Navigate to={`/fdchat/${surveyId}/results/ask-ai`} replace />

  return (
    <div
      className={styles.search}
      style={{
        width: drawerOpen ? 'calc(100% - var(--drawer-max-width))' : '100%'
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingY: '100px',
          maxWidth: '800px'
        }}
      >
        <div className={styles.answers}>
          {questions.map((question, i) => (
            // TODO: change key to not use index once we have an appropriate id
            <Answer
              question={question.question}
              label={question.label}
              onFeedbackClick={openFeedbackModal}
              key={i}
            />
          ))}
        </div>
        <div
          className={styles['ask-ai-bar-container']}
          style={{
            width: drawerOpen
              ? 'calc(100% - var(--sidebar-width) - var(--drawer-max-width))'
              : 'calc(100% - var(--sidebar-width))'
          }}
        >
          <AskAiBarContainer onAskAi={onAskAi} />
        </div>
        <Modal ref={modalRef} onCloseIconClick={closeModal}>
          {answerForFeedback && (
            <FeedbackForm
              answer={answerForFeedback}
              onSubmitted={closeModal}
              onCancelClick={closeModal}
            />
          )}
        </Modal>
      </Container>
      <Drawer open={drawerOpen}>{drawerOpen && <AskAiDrawer />}</Drawer>
    </div>
  )
}

export default Search
