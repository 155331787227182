import React from 'react'
import { Project } from '../../../data/gql-gen/dashboard/graphql'
import useGetSurveysProgress from '../../../hooks/useGetSurveysProgress'
import ProjectListControl from './ProjectList'

interface Props {
  projects?: Project[]
  onClickDuplicate: (project: Project) => void
  onClickDelete: (project: Project) => void
  onClickPause: (project: Project) => void
  onClickUnpause: (project: Project) => void
  onLoadMore?: () => void
  loadingMore?: boolean
  showLoadMore?: boolean
}

const ProjectList: React.FC<Props> = (props: Props) => {
  const {
    projects,
    onClickDuplicate,
    onClickDelete,
    onClickPause,
    onClickUnpause,
    onLoadMore,
    loadingMore,
    showLoadMore
  } = props

  const surveyIds = projects?.map((project) => project.surveys[0].surveyId)

  const { surveyProgress, loading: loadingProgress } =
    useGetSurveysProgress(surveyIds)

  return (
    <ProjectListControl
      projects={projects}
      surveysProgress={surveyProgress}
      onClickDuplicate={onClickDuplicate}
      onClickDelete={onClickDelete}
      onClickPause={onClickPause}
      onClickUnpause={onClickUnpause}
      onLoadMore={onLoadMore}
      loadingMore={loadingMore}
      loadingProgress={loadingProgress}
      showLoadMore={showLoadMore}
    />
  )
}

export default ProjectList
