import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import {
  DraftCustomAudienceCriterionOption,
  DraftCustomAudienceItem,
  DraftFieldworkAudience,
  MemberType
} from '../../../data/gql-gen/questionnaire/graphql'

export const addCustomResponseOptionCacheUpdate: (
  cache: ApolloCache<any>,
  newResponseOption: DraftCustomAudienceCriterionOption,
  newAudienceQuestionLk: string | undefined
) => void = (cache, newResponseOption, newAudienceQuestionLk) => {
  cache.modify({
    fields: {
      draftAudience(existing) {
        const draftAudienceCache = existing as DraftFieldworkAudience
        const criterionIndex = draftAudienceCache.members.findIndex(
          (audience) =>
            audience.memberType === MemberType.Custom &&
            (audience.memberItem as DraftCustomAudienceItem).questionLk ===
              newAudienceQuestionLk
        )

        if (criterionIndex === -1) {
          return draftAudienceCache
        }

        return produce(draftAudienceCache, (draft) => {
          const member = draft.members[criterionIndex]
            .memberItem as DraftCustomAudienceItem
          member.criterionOptions.push(newResponseOption)
        })
      }
    }
  })
}
