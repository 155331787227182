import React from 'react'
import { ProjectStatus } from '../../data/gql-gen/dashboard/graphql'
import { SurveyProgress } from '../../data/model/surveyResults'
import {
  getFormattedProgress,
  getProgressPercent
} from './ProjectListItem.utils'
import ProjectListItemDetails from './ProjectListItemDetails'

interface Props {
  surveyId: string
  projectStatus: ProjectStatus
  surveyProgressList?: SurveyProgress[]
  loadingProgress?: boolean
}

const ProjectListItemProgress: React.FC<Props> = ({
  surveyProgressList,
  surveyId,
  projectStatus,
  loadingProgress
}: Props) => {
  // if project is draft or scheduled don't show progress
  const showProgress = !(
    projectStatus === ProjectStatus.Draft ||
    projectStatus === ProjectStatus.Scheduled
  )
  const isComplete = projectStatus === ProjectStatus.Completed

  const hasProgressData = surveyProgressList?.find(
    (progress) => progress.surveyId === surveyId
  )

  let surveyProgressString = ''
  let percent = 0

  if (showProgress) {
    // if no data from progress and is loading, show spinner

    if (hasProgressData) {
      const progress = getFormattedProgress(
        surveyId,
        projectStatus,
        surveyProgressList
      )

      const localPercent = getProgressPercent(
        surveyId,
        projectStatus,
        surveyProgressList
      )

      surveyProgressString = `${progress} (${localPercent}%)`
      percent = localPercent || 0
    }
  }

  const progressControl = isComplete ? (
    <ProjectListItemDetails
      label="Completes"
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      value={hasProgressData?.progress?.samplesCollected.toString()}
      loadingValue={loadingProgress && !hasProgressData}
      showProgress
      percent={100}
    />
  ) : (
    <ProjectListItemDetails
      label="Progress"
      value={surveyProgressString}
      loadingValue={loadingProgress && !hasProgressData}
      showProgress
      percent={percent}
    />
  )

  return showProgress ? progressControl : null
}

export default ProjectListItemProgress
