import { HeadingLevel, Paragraph } from 'docx'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  TextEntryState
} from '../../data/model/questionnaire'
import { hasDisplayLogic } from '../questionLogic'
import {
  checkIfMatrixMultipleChoice,
  checkIfMultipleChoice,
  getQuestionEnabledSettings,
  getQuestionEntryPrefix,
  getQuestionSettings
} from '../questionnaireUtils'
import { QuestionPrefix } from './model'
import { paragraphTopBorderOptions } from './paragraphTopBorderOptions'

const getQuestionLogicEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  return hasDisplayLogic(entry) ? getQuestionEntryPrefix(entry) : ''
}

const hasMaskingRule = (entry: DraftQuestionnaireEntry): boolean => {
  let entryItem

  switch (entry.entryType) {
    case EntryType.MatrixEntryType:
      entryItem = entry.entryItem
      return (
        entryItem.matrixRows.some(
          (matrixRow) => matrixRow.maskingRules.length > 0
        ) || entryItem.responseOptions.some((ro) => ro.maskingRules.length > 0)
      )
    case EntryType.QuestionEntryType:
      entryItem = entry.entryItem
      return entryItem.responseOptions.some((ro) => ro.maskingRules.length > 0)
    default:
      return false
  }
}

const getQuestionTimerEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  const { settingValues = [] } = entry.entryItem as DraftQuestionItem
  return getQuestionSettings(settingValues).get(
    QuestionSettingCode.QuestionTimer
  )
    ? getQuestionEntryPrefix(entry)
    : ''
}

const getResponseLogicEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  return hasMaskingRule(entry) ? getQuestionEntryPrefix(entry) : ''
}

const isMultipleChoice = (entry: DraftQuestionnaireEntry) => {
  let entryItem
  switch (entry.entryType) {
    case EntryType.MatrixEntryType:
      entryItem = entry.entryItem
      return checkIfMatrixMultipleChoice(entryItem.settingValues)
    case EntryType.QuestionEntryType:
      entryItem = entry.entryItem
      return checkIfMultipleChoice(entryItem.settingValues)
    default:
      return false
  }
}

const getExclusiveResponseEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  const entryItem = entry.entryItem as DraftQuestionItem | DraftMatrixItem
  const enabledSettings = getQuestionEnabledSettings(entryItem.settingValues)
  const hasExclusiveResponse =
    [
      QuestionSettingCode.NoneOfThese,
      QuestionSettingCode.PreferNotToSay,
      QuestionSettingCode.DontKnow
    ].some((setting) => enabledSettings.has(setting)) ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    entryItem.responseOptions?.some((option) => option.exclusive)

  if (hasExclusiveResponse && isMultipleChoice(entry)) {
    return getQuestionEntryPrefix(entry)
  }

  return ''
}

const getPinnedResponseEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  let entryItem
  let hasPinnedResponse = false
  const questionPrefix = getQuestionEntryPrefix(entry)

  switch (entry.entryType) {
    case EntryType.MatrixEntryType:
      entryItem = entry.entryItem
      hasPinnedResponse = entryItem.matrixRows.some(
        (matrixRow) => matrixRow.pinned
      )
      break
    case EntryType.QuestionEntryType:
      entryItem = entry.entryItem
      hasPinnedResponse = entryItem.responseOptions.some((ro) => ro.pinned)
      break
    default:
      return ''
  }

  return hasPinnedResponse ? questionPrefix : ''
}

const getTextEntryResponseEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix | '' => {
  let entryItem
  let hasTextEntryEnabled = false
  if (entry.entryType === EntryType.QuestionEntryType) {
    entryItem = entry.entryItem
    hasTextEntryEnabled = entryItem.responseOptions.some(
      (ro) => ro.textEntryState === TextEntryState.TextEntryEnabled
    )
  } else return ''

  return hasTextEntryEnabled ? getQuestionEntryPrefix(entry) : ''
}

type BehaviourSummaryEntity =
  | 'displayLogicQuestions'
  | 'timerQuestions'
  | 'displayLogicResponses'
  | 'exclusiveResponses'
  | 'pinnedResponses'
  | 'textEntryResponses'

export const createQuestionsBehaviorSummary = (
  entries: DraftQuestionnaireEntry[]
) => {
  const behaviorSummaryData: Record<BehaviourSummaryEntity, QuestionPrefix[]> =
    {
      timerQuestions: [],
      displayLogicQuestions: [],
      displayLogicResponses: [],
      exclusiveResponses: [],
      pinnedResponses: [],
      textEntryResponses: []
    }

  entries.forEach((entry) => {
    const questionLogicEntryPrefix = getQuestionLogicEntryPrefix(entry)
    const questionTimerEntryPrefix = getQuestionTimerEntryPrefix(entry)
    const responseLogicEntryPrefix = getResponseLogicEntryPrefix(entry)
    const exclusiveResponseEntryPrefix = getExclusiveResponseEntryPrefix(entry)
    const pinnedResponseEntryPrefix = getPinnedResponseEntryPrefix(entry)
    const textEntryResponseEntryPrefix = getTextEntryResponseEntryPrefix(entry)

    if (questionTimerEntryPrefix) {
      behaviorSummaryData.timerQuestions.push(questionTimerEntryPrefix)
    }

    if (questionLogicEntryPrefix) {
      behaviorSummaryData.displayLogicQuestions.push(questionLogicEntryPrefix)
    }

    if (responseLogicEntryPrefix) {
      behaviorSummaryData.displayLogicResponses.push(responseLogicEntryPrefix)
    }

    if (exclusiveResponseEntryPrefix) {
      behaviorSummaryData.exclusiveResponses.push(exclusiveResponseEntryPrefix)
    }

    if (pinnedResponseEntryPrefix) {
      behaviorSummaryData.pinnedResponses.push(pinnedResponseEntryPrefix)
    }

    if (textEntryResponseEntryPrefix) {
      behaviorSummaryData.textEntryResponses.push(textEntryResponseEntryPrefix)
    }
  })

  const hasDataForSummary = Object.values(behaviorSummaryData).some(
    (data) => data.length > 0
  )

  if (!hasDataForSummary) {
    return []
  }

  const behaviorSummaryHeader = new Paragraph({
    text: 'Question Behaviour Summary:',
    heading: HeadingLevel.HEADING_2,
    ...paragraphTopBorderOptions
  })

  const behaviorSummaryBody = []

  const {
    timerQuestions,
    displayLogicQuestions,
    displayLogicResponses,
    exclusiveResponses,
    pinnedResponses,
    textEntryResponses
  } = behaviorSummaryData

  if (timerQuestions.length) {
    behaviorSummaryBody.push(
      new Paragraph(`Questions with timer: ${timerQuestions.join(', ')}`)
    )
  }

  if (displayLogicQuestions.length) {
    behaviorSummaryBody.push(
      new Paragraph(
        `Questions with display logic: ${displayLogicQuestions.join(', ')}`
      )
    )
  }

  if (displayLogicResponses.length) {
    behaviorSummaryBody.push(
      new Paragraph(
        `Responses with display logic/masking: ${displayLogicResponses.join(
          ', '
        )}`
      )
    )
  }

  if (exclusiveResponses.length) {
    behaviorSummaryBody.push(
      new Paragraph(`Exclusive responses: ${exclusiveResponses.join(', ')}`)
    )
  }

  if (pinnedResponses.length) {
    behaviorSummaryBody.push(
      new Paragraph(`Pinned responses: ${pinnedResponses.join(', ')}`)
    )
  }

  if (textEntryResponses.length) {
    behaviorSummaryBody.push(
      new Paragraph(
        `Responses with text entry enabled: ${textEntryResponses.join(', ')}`
      )
    )
  }

  return [behaviorSummaryHeader, ...behaviorSummaryBody]
}
