import { useMutation, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import {
  BulkAddDraftCombinedResponseOptionsMutation,
  BulkAddDraftCombinedResponseOptionsMutationVariables,
  DraftCombinedQuestion,
  DraftCombinedResponseOption,
  SetDraftCombinedQuestionTitleMutation,
  SetDraftCombinedQuestionTitleMutationVariables
} from '../../../../../data/gql-gen/local/graphql'
import {
  ADD_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION,
  BULK_ADD_DRAFT_COMBINED_RESPONSE_OPTIONS_MUTATION,
  DELETE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION,
  DELETE_MAPPING_MARKET_QUESTION_MUTATION,
  GET_DRAFT_COMBINED_QUESTION,
  MAP_RESPONSE_OPTION_MUTATION,
  SET_DRAFT_COMBINED_QUESTION_MUTATION,
  SET_DRAFT_COMBINED_QUESTION_TITLE_MUTATION,
  UPDATE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION
} from './DraftCombinedQuestion.localState'

export const useGetDraftCombinedQuestion = () => {
  const { data, loading, error } = useQuery(GET_DRAFT_COMBINED_QUESTION, {
    context: { clientName: 'local' }
  })

  return { draftCombinedQuestion: data?.draftCombinedQuestion, loading, error }
}

export const useGetDraftCombinedQuestionTitle = () => {
  const { draftCombinedQuestion } = useGetDraftCombinedQuestion()

  return draftCombinedQuestion?.title ?? ''
}

export const useSetDraftCombinedQuestionTitle = () => {
  const [setDraftCombinedQuestionTitleMutation] = useMutation<
    SetDraftCombinedQuestionTitleMutation,
    SetDraftCombinedQuestionTitleMutationVariables
  >(SET_DRAFT_COMBINED_QUESTION_TITLE_MUTATION)
  const setDraftCombinedQuestionTitle = useCallback(
    (title: string) => {
      return setDraftCombinedQuestionTitleMutation({
        variables: { title }
      }).then(({ data }) => {
        if (typeof data?.setDraftCombinedQuestionTitle !== 'string') {
          throw new Error(
            'Resolver `setDraftCombinedQuestionTitle` for client only mutation  must return title data'
          )
        }
        return data.setDraftCombinedQuestionTitle
      })
    },
    [setDraftCombinedQuestionTitleMutation]
  )
  return setDraftCombinedQuestionTitle
}

export const useSetDraftCombinedQuestion = () => {
  const [setDraftCombinedQuestionMutation] = useMutation<{
    setDraftCombinedQuestion: DraftCombinedQuestion
  }>(SET_DRAFT_COMBINED_QUESTION_MUTATION)
  const [addDraftCombinedResponseOptionMutation] = useMutation(
    ADD_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION
  )
  const setDraftCombinedQuestion = (
    draftCombinedQuestion: DraftCombinedQuestion
  ) => {
    return setDraftCombinedQuestionMutation({
      variables: { draftCombinedQuestion }
    }).then(async ({ data }) => {
      if (!data) {
        throw new Error(
          'Resolver `setDraftCombinedQuestion` for client only mutation  must return combinedQuestion data'
        )
      }
      // when new combined question is created, at least 1 response option should be present on the screen
      if (data.setDraftCombinedQuestion.mappedResponseOptions.length === 0) {
        await addDraftCombinedResponseOptionMutation()
      }
      return data.setDraftCombinedQuestion
    })
  }
  return setDraftCombinedQuestion
}

export const useAddDraftCombinedResponseOption = () => {
  const [addDraftCombinedResponseOptionMutation] = useMutation<{
    addDraftCombinedResponseOption: DraftCombinedResponseOption
  }>(ADD_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION)
  const addDraftCombinedResponseOption = useCallback(() => {
    return addDraftCombinedResponseOptionMutation().then(({ data }) => {
      if (!data) {
        throw new Error(
          'Resolver `addDraftCombinedResponseOption` for client only mutation  must return DraftCombinedResponseOption data'
        )
      }
      return data.addDraftCombinedResponseOption
    })
  }, [addDraftCombinedResponseOptionMutation])
  return addDraftCombinedResponseOption
}

export const useDeleteDraftCombinedResponseOption = () => {
  const [deleteDraftCombinedResponseOptionMutation] = useMutation(
    DELETE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION
  )
  const deleteDraftCombinedResponseOption = useCallback(
    (id: string) => {
      return deleteDraftCombinedResponseOptionMutation({
        variables: { id }
      }).then(({ data }) => {
        if (!data) {
          throw new Error(
            'Resolver `deleteDraftCombinedResponseOption` for client only mutation  must return DraftCombinedResponseOption data'
          )
        }
        return data.deleteDraftCombinedResponseOption
      })
    },
    [deleteDraftCombinedResponseOptionMutation]
  )
  return deleteDraftCombinedResponseOption
}

export const useBulkAddDraftCombinedResponseOptions = () => {
  const [bulkAddDraftCombinedResponseOptionsMutation] = useMutation<
    BulkAddDraftCombinedResponseOptionsMutation,
    BulkAddDraftCombinedResponseOptionsMutationVariables
  >(BULK_ADD_DRAFT_COMBINED_RESPONSE_OPTIONS_MUTATION)
  const bulkAddDraftCombinedResponseOptions = useCallback(
    (values: string[], insertAtIndex: number) => {
      return bulkAddDraftCombinedResponseOptionsMutation({
        variables: { values, insertAtIndex }
      }).then(({ data }) => {
        if (!data) {
          throw new Error(
            'Resolver `bulkAddDraftCombinedResponseOptions` for client only mutation  must return added response options'
          )
        }
        return data.bulkAddDraftCombinedResponseOptions
      })
    },
    [bulkAddDraftCombinedResponseOptionsMutation]
  )
  return bulkAddDraftCombinedResponseOptions
}

export const useUpdateDraftCombinedResponseOption = () => {
  const [updateDraftCombinedResponseOptionMutation] = useMutation(
    UPDATE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION
  )
  const updateDraftCombinedResponseOption = useCallback(
    (value: string, id: string) => {
      return updateDraftCombinedResponseOptionMutation({
        variables: { id, value }
      }).then(({ data }) => {
        if (!data) {
          throw new Error(
            'Resolver `updateDraftCombinedResponseOption` for client only mutation  must return DraftCombinedResponseOption data'
          )
        }
        return data.updateDraftCombinedResponseOption
      })
    },
    [updateDraftCombinedResponseOptionMutation]
  )
  return updateDraftCombinedResponseOption
}

export const useDeleteMappingMarketQuestion = () => {
  const [deleteMappingMarketQuestionMutation] = useMutation(
    DELETE_MAPPING_MARKET_QUESTION_MUTATION
  )
  const deleteMappingMarketQuestion = useCallback(
    (id: string) => {
      return deleteMappingMarketQuestionMutation({
        variables: { id }
      }).then(({ data }) => {
        if (!data) {
          throw new Error(
            'Resolver `deleteMappingMarketQuestion` for client only mutation  must return market response option data'
          )
        }
        return data.deleteMappingMarketQuestion
      })
    },
    [deleteMappingMarketQuestionMutation]
  )
  return deleteMappingMarketQuestion
}

export const useMapResponseOption = () => {
  const [mapResponseOptionMutation] = useMutation(MAP_RESPONSE_OPTION_MUTATION)
  const mapResponseOption = useCallback(
    (id: string, marketQuestionId: string, mappingId: string) => {
      return mapResponseOptionMutation({
        variables: { id, marketQuestionId, mappingId }
      }).then(({ data }) => {
        if (!data) {
          throw new Error(
            'Resolver `mapResponseOption` for client only mutation  must return response option data'
          )
        }
        return data.mapResponseOption
      })
    },
    [mapResponseOptionMutation]
  )
  return mapResponseOption
}
