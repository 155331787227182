import { TextRun } from 'docx'
import {
  DraftEntryResponseOption,
  DraftMaskingRule,
  MaskingRuleClause
} from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionnaireEntry,
  EntryType
} from '../../data/model/questionnaire'
import { getQuestionEntryPrefix } from '../questionnaireUtils'

const findResponseTextById = (
  responseOptions: DraftEntryResponseOption[],
  responseOptionLk: string | null
): string => {
  if (!responseOptionLk) {
    return ''
  }

  return (
    responseOptions.find((ro) => responseOptionLk === ro.responseOptionLk)
      ?.responseOption?.value ?? ''
  )
}

const createClauseTextRuns = (
  entries: DraftQuestionnaireEntry[],
  searchParams: {
    questionLk: string
    matrixTitleLk: string | null | undefined
    responseOptionLk: string | null
  },
  isNegated: boolean | null | undefined
): TextRun[] => {
  let entryItem
  const responseLogicTextRuns: TextRun[] = []
  const negationText = isNegated ? 'is NOT ' : 'is '

  entries.forEach((entry) => {
    let responseOptionText: string
    switch (entry.entryType) {
      case EntryType.QuestionEntryType:
        entryItem = entry.entryItem
        responseOptionText = findResponseTextById(
          entryItem.responseOptions,
          searchParams.responseOptionLk
        )

        if (entryItem.questionLk === searchParams.questionLk) {
          const questionEntryPrefix = getQuestionEntryPrefix(entry)

          responseLogicTextRuns.push(
            new TextRun({
              text: `${questionEntryPrefix} `,
              bold: true
            }),
            new TextRun({
              text: negationText
            }),
            new TextRun({
              text: responseOptionText,
              bold: true
            })
          )
        }

        break
      case EntryType.MatrixEntryType:
        entryItem = entry.entryItem

        if (entryItem.matrixTitleLk === searchParams.matrixTitleLk) {
          const rowPosition = entryItem.matrixRows.find(
            ({ questionLk }) => questionLk === searchParams.questionLk
          )?.position
          responseOptionText = findResponseTextById(
            entryItem.responseOptions,
            searchParams.responseOptionLk
          )
          const rowPositionPostfix =
            rowPosition !== undefined ? `.${rowPosition + 1}` : ''
          const questionEntryPrefix = getQuestionEntryPrefix(entry)

          responseLogicTextRuns.push(
            new TextRun({
              text: `${questionEntryPrefix}${rowPositionPostfix} `,
              bold: true
            }),
            new TextRun({
              text: negationText
            }),
            new TextRun({
              text: responseOptionText,
              bold: true
            })
          )
        }

        break
      default:
        break
    }
  })

  return responseLogicTextRuns
}

export const createResponseLogicText = (
  maskingRules: DraftMaskingRule[],
  entries: DraftQuestionnaireEntry[]
): TextRun[] => {
  if (!maskingRules.length) {
    return []
  }

  const operator =
    maskingRules[0].clause === MaskingRuleClause.And ? ' AND ' : ' OR '

  const responseLogicTextRuns: TextRun[] = []
  maskingRules.forEach(({ maskingKeys, negated }, j) => {
    if (j !== 0) {
      responseLogicTextRuns.push(
        new TextRun({
          text: operator
        })
      )
    }

    maskingKeys.forEach(
      ({ questionLk, matrixTitleLk, responseOptionLk }, i) => {
        const clauseTextRuns = createClauseTextRuns(
          entries,
          {
            questionLk,
            matrixTitleLk,
            responseOptionLk
          },
          negated
        )
        if (i !== 0) {
          clauseTextRuns.unshift(
            new TextRun({
              text: ' OR '
            })
          )
        }
        responseLogicTextRuns.push(...clauseTextRuns)
      }
    )
  })
  return [
    new TextRun({
      text: ' [Display if '
    }),
    ...responseLogicTextRuns,
    new TextRun({
      text: ']'
    })
  ]
}
