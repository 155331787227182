import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  text: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(2)
  }
})

export default useStyles
