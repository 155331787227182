import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  questionType: {
    paddingRight: theme.spacing(2)
  },
  middleIcon: {
    paddingRight: theme.spacing(0.5)
  }
})

export default useStyles
