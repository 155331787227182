import {
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React from 'react'
import {
  InfoPanel,
  InfoPanelIconVariant,
  InfoPanelItem
} from '../../../components/InfoPanel'
import useStyles from './SurveyStats.styles'

interface Props {
  disableActions: boolean
  isPaused: boolean
  onEndLiveProject?: () => void
  onPauseLiveProject?: () => void
}

const LiveActions: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()

  const { disableActions, isPaused, onPauseLiveProject, onEndLiveProject } =
    props

  const startOrPauseText = isPaused ? 'Unpause project' : 'Pause project'
  const startOrPauseIcon = isPaused
    ? IconName.PlayCircleOutline
    : IconName.PauseCircleOutline

  const iconColor = disableActions ? IconColor.Background : IconColor.Emphasis
  const textHoverClass = disableActions ? undefined : classes.textHoverClass
  const textHighlight = disableActions
    ? TextHighlight.Background
    : TextHighlight.Emphasis
  const actionItemClass = disableActions
    ? classes.actionItemDisabled
    : classes.actionItem

  return (
    <Grid className={classes.liveActions}>
      <InfoPanel
        title="Actions"
        iconName={InfoPanelIconVariant.Kebab}
        closeMenuOnItemClick
        titleTextColor={TextHighlight.Emphasis}
      >
        <InfoPanelItem>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            onClick={disableActions ? undefined : onPauseLiveProject}
            className={classNames(actionItemClass, classes.pauseItem)}
          >
            <Text
              size={TextSize.ms}
              highlight={textHighlight}
              className={textHoverClass}
            >
              {startOrPauseText}
            </Text>
            <Grid item xs="auto" className={classes.pauseIcon}>
              <Icon
                name={startOrPauseIcon}
                iconColor={iconColor}
                size={IconSize.Medium}
              />
            </Grid>
          </Grid>
        </InfoPanelItem>
        {
          // Note: You will update the order status as paused and the project status as closed
          // cint will transistion the project to closed after 2 weeks
        }
        <InfoPanelItem>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            onClick={disableActions ? undefined : onEndLiveProject}
            className={classNames(actionItemClass, classes.endSurveyItem)}
          >
            <Text
              size={TextSize.ms}
              highlight={textHighlight}
              className={textHoverClass}
            >
              End project
            </Text>
            <Icon
              name={IconName.FlagOutlined}
              iconColor={iconColor}
              size={IconSize.Medium}
            />
          </Grid>
        </InfoPanelItem>
      </InfoPanel>
    </Grid>
  )
}

export default LiveActions
