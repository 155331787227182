import { Grid, Skeleton } from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './AudienceSummary.styles'

const AudienceSummaryLoading: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Grid container direction="column" className={classes.container}>
      <Skeleton height={24} width={80} />
      <Skeleton height={24} width={400} className={classes.skeletonMargin} />
      <Skeleton height={24} width={80} />
      <Skeleton height={24} width={400} />
    </Grid>
  )
}

export default AudienceSummaryLoading
