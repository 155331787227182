import React, { useContext } from 'react'
import { useAppSelector } from '../../../../App.store'
import { pluralise } from '../../../../utils/chartUtils'
import QuestionnaireContext, {
  openRandomiseSectionsDialog
} from '../../Questionnaire.context'
import { useSetSectionsRandomisation } from '../SectionsRandomisation.hooks'
import { selectRandomisedSections } from '../SectionsRandomisation.slice'
import { RandomiseSectionsHeaderComponent } from './RandomiseSectionsHeader.component'
import useStyles from './RandomiseSectionsHeader.styles'

const RandomiseSectionsHeader: React.FC = () => {
  const { classes, cx: classNames } = useStyles()
  const { dispatch } = useContext(QuestionnaireContext)
  const setSectionsRandomisation = useSetSectionsRandomisation()

  const randomisedSections = useAppSelector(selectRandomisedSections)
  const countRandomisedSections = randomisedSections.length

  const outputText = `${countRandomisedSections} ${pluralise(
    countRandomisedSections,
    'section'
  )} randomised`

  const tooltipText = `${randomisedSections
    .map(({ title }) => title)
    .join(', ')
    .replace(/,(?!.*,)/, ' and')} are randomised`

  const handleOnClickRandomiseSections = () => {
    dispatch(openRandomiseSectionsDialog())
  }

  const removeSectionsRandomisation = () => {
    setSectionsRandomisation([])
  }

  return (
    <div
      className={classNames(
        'fd-grid fd-container fd-column',
        classes.randomiseSectionsHeader
      )}
    >
      <RandomiseSectionsHeaderComponent
        hasRandomisedSections={countRandomisedSections > 1}
        outputText={outputText}
        tooltipText={tooltipText}
        onClickRandomiseSections={handleOnClickRandomiseSections}
        onClickRemoveRandomisation={removeSectionsRandomisation}
      />
    </div>
  )
}

export default React.memo(RandomiseSectionsHeader)
