import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  content: {
    padding: theme.spacing(4)
  },
  purchaseGrid: {
    width: '100%'
  },
  purchaseButton: {
    width: '100%'
  },
  costBreakdownOuterGrid: {
    width: '100%',
    border: `1px solid ${additionalPalette.lightGrey.main}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  costBreakdownInnerGrid: {
    padding: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  infoCost: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3)
  }
})

export default useStyles
