import {
  Text,
  TextDisplay,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import CostDropdownPanel from '../../../controls/CostDropdownPanel'
import {
  SurveyOrderStatus,
  SurveyState
} from '../../../data/gql-gen/dashboard/graphql'
import { getLangFromLangName } from '../../../data/seed/languages'
import useGetSurveyState from '../../../hooks/useGetSurveyState'
import ThreeChildLayout from '../../../layouts/ThreeChildRowLayout'
import LiveActions from './LiveActions'
import { SurveyErrorPanel } from './SurveyErrorPanel'
import useStyles from './SurveyStats.styles'

interface Props {
  totalCost?: string
  isCostPreview?: boolean
  lang?: string
  cpi?: number
  completes: number
  showLiveActions?: boolean
  orderState?: SurveyOrderStatus
  incidenceRate?: string
  displayIncrement?: boolean
  displayedLOI?: number
  displayedLOIRange?: string
  noOfQuestions?: number
  onEndLiveProject?: () => void
  onPauseLiveProject?: () => void
  onClickIncrementAdd?: () => void
  onClickIncrementSubstract?: () => void
}

const SurveyStatsControl: React.FC<Props> = (props: Props) => {
  const {
    totalCost,
    isCostPreview,
    cpi,
    lang,
    completes,
    showLiveActions = false,
    displayIncrement = false,
    orderState,
    incidenceRate,
    displayedLOI,
    displayedLOIRange,
    noOfQuestions,
    onEndLiveProject,
    onPauseLiveProject,
    onClickIncrementAdd,
    onClickIncrementSubstract
  } = props
  const { classes } = useStyles()

  const priceTitle = `£${totalCost || '...'}`

  const cpiString = `£${cpi ? cpi.toFixed(2) : '...'}`

  const subtitle = 'Quote:'

  const { surveyState } = useGetSurveyState()
  const isDraft = surveyState === SurveyState.Draft

  const disableActions =
    orderState === SurveyOrderStatus.Pending ||
    orderState === SurveyOrderStatus.Scheduled ||
    orderState === SurveyOrderStatus.Completed
  const isPaused = orderState === SurveyOrderStatus.Paused

  const rightChild = showLiveActions ? (
    <LiveActions
      disableActions={disableActions}
      isPaused={isPaused}
      onEndLiveProject={onEndLiveProject}
      onPauseLiveProject={onPauseLiveProject}
    />
  ) : (
    <SurveyErrorPanel />
  )
  const languageString = getLangFromLangName(lang)

  return (
    <ThreeChildLayout
      justifyContent="flex-end"
      maxWidthPercent="35%"
      height={48}
      showDividers
      leftChild={
        <>
          <Text display={TextDisplay.Inline} size={TextSize.ms}>
            Language:
          </Text>
          <Text
            display={TextDisplay.Inline}
            size={TextSize.ms}
            weight={TextWeight.SemiBold}
            className={classes.language}
          >{` ${languageString}`}</Text>
        </>
      }
      middleChild={
        <CostDropdownPanel
          isCostPreview={isCostPreview}
          priceTitle={priceTitle}
          subtitle={subtitle}
          incidenceRate={incidenceRate}
          cpi={cpiString}
          completes={completes}
          isDraft={isDraft}
          displayIncrement={displayIncrement}
          displayedLOI={displayedLOI}
          displayedLOIRange={displayedLOIRange}
          noOfQuestions={noOfQuestions}
          onClickIncrementAdd={onClickIncrementAdd}
          onClickIncrementSubstract={onClickIncrementSubstract}
        />
      }
      rightChild={rightChild}
    />
  )
}

export default memo(SurveyStatsControl)
