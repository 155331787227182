import { Dialog, DialogMaxWidth } from '@focaldata/cin-ui-components'
import DialogPurchaseContent from '../../../../controls/DialogPurchaseContent'

interface Props {
  startDate: string
  totalCost?: number
  isOpen: boolean
  primaryButtonLoading?: boolean
  closeDialog: () => void
  launchSurvey: () => Promise<void>
}

export const FdChatLaunchDialog = ({
  startDate,
  totalCost,
  isOpen,
  primaryButtonLoading,
  closeDialog,
  launchSurvey
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      primaryButtonClick={launchSurvey}
      primaryButtonText="Purchase"
      primaryButtonLoading={primaryButtonLoading}
      title="Purchase"
      maxWidth={DialogMaxWidth.Md}
      onClickOutside={closeDialog}
    >
      <DialogPurchaseContent
        surveyLaunchDate={startDate}
        totalCost={totalCost}
      />
    </Dialog>
  )
}
