import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { memo, useState } from 'react'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import { EntryType, ForkBranch } from '../../../data/model/questionnaire'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import BasicQuestionLayout from '../../../layouts/BasicQuestionLayout'
import { sumBy } from '../../../utils/array/sumBy'
import {
  WithEntry,
  getBranchBatchUpdateData,
  getTotalForkPercentage,
  propsAreEqual
} from '../../../utils/questionnaireUtils'
import { CardTitleControl } from '../CardTitle'
import { SurveyQuestionCardHeaderControl } from '../SurveyQuestionCardHeader'
import Branch from './Branch'
import ForkFooter from './ForkFooter'

const Fork = ({ entry }: WithEntry) => {
  if (entry.entryType !== EntryType.ForkEntryType) {
    throw new Error('Cannot render non-fork as fork')
  }

  const { fork } = entry.entryItem

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(fork.forkId)

  const [quotaBeingEdited, setQuotaBeingEdited] = useState<{
    value: number
    branch: ForkBranch
  }>()

  const triggerOnQuotaChange = async () => {
    questionBeingEditedId(entry.id)
    if (quotaBeingEdited) {
      await updateQuestionnaireEntry({
        updateFork: {
          name: fork.name,
          branches: fork.branches.map((branch) => ({
            ...branch,
            percentage:
              branch.branchNumber === quotaBeingEdited.branch.branchNumber
                ? quotaBeingEdited.value
                : branch.percentage
          }))
        }
      })
    }
  }

  useDebounceEffect(
    () => {
      triggerOnQuotaChange()
    },
    quotaBeingEdited?.value,
    { delay: 500 }
  )

  const makeEqualBranches = async () => {
    await updateQuestionnaireEntry({
      updateFork: {
        name: fork.name,
        branches: getBranchBatchUpdateData(fork.branches)
      }
    })
  }

  const handleDeleteBranch = async (branchNumber: number) => {
    questionBeingEditedId(entry.id)
    await updateQuestionnaireEntry({
      updateFork: {
        name: fork.name,
        branches: fork.branches
          .filter((branch) => branch.branchNumber !== branchNumber)
          .map((branch, index) => ({
            ...branch,
            branchNumber: index
          }))
      }
    })
  }

  const handleAddGroup = async () => {
    questionBeingEditedId(entry.id)
    const totalPercentages =
      Math.round(sumBy(fork.branches, (b) => b.percentage) * 1000) / 1000
    const percentage =
      totalPercentages < 1
        ? Math.round((1 - totalPercentages) * 1000) / 1000
        : 0.1
    await updateQuestionnaireEntry({
      updateFork: {
        name: fork.name,
        branches: [
          ...fork.branches,
          {
            branchNumber: fork.branches.length,
            percentage
          }
        ]
      }
    })
  }

  const hideDeleteButtons = fork.branches.length < 3

  const totalForkPercentage = getTotalForkPercentage(fork)

  return (
    <BasicQuestionLayout
      entryId={entry.id}
      questionCardHeader={
        <SurveyQuestionCardHeaderControl
          entry={entry}
          titleContent={
            <CardTitleControl
              titleValue={fork.name}
              readOnly
              ariaLabel="Fork header input"
            />
          }
          idContent={
            <p
              className={classNames(
                textClasses.default,
                textClasses.weightSemiBold,
                textClasses.highlightBackground
              )}
            >
              A/B
            </p>
          }
        />
      }
      responseOptions={
        <div className="fd-grid fd-container fd-column fd-align-items-flex-start">
          <p
            className={classNames(
              textClasses.default,
              textClasses.highlightBackground
            )}
          >
            Randomly divide your audience into the following groups:
          </p>
          {fork.branches.map((branch, index) => (
            <Branch
              isLast={index === fork.branches.length - 1}
              defaultPercent={branch.percentage}
              key={branch.branchNumber}
              label={branch.label}
              hideDelete={hideDeleteButtons}
              onDeleteBranch={() => handleDeleteBranch(branch.branchNumber)}
              onPercentChange={(newPercent) =>
                setQuotaBeingEdited({ value: newPercent, branch })
              }
            />
          ))}
          <ForkFooter
            totalForkPercentage={totalForkPercentage}
            onAddGroup={handleAddGroup}
            makeEqualBranches={makeEqualBranches}
          />
        </div>
      }
      defaultOptions={
        <div className="fd-grid fd-container fd-row fd-align-items-center">
          <Icon
            name={IconName.InfoOutlinedIcon}
            iconColor={IconColor.Background}
            size={IconSize.Medium}
          />
          <p
            className={classNames(
              textClasses.default,
              textClasses.marginLeft,
              textClasses.highlightBackground
            )}
          >
            Set up display logic on later questions to show/hide questions for
            each group
          </p>
        </div>
      }
    />
  )
}

export default memo(Fork, propsAreEqual)
