import { EntryType } from '../../../data/gql-gen/questionnaire/graphql'
import { CUSTOM_QUESTION_KEY, SCROLL_ID_PREFIX } from '../constants'

export const getPrevNextEntrySiblings = (
  currentEntryIndex: number
): {
  prevEntryNode?: Element
  nextEntryNode?: Element
} => {
  const allEntryNodes = document.querySelectorAll(
    `[name*=${SCROLL_ID_PREFIX}]:not([name*=${CUSTOM_QUESTION_KEY}]):not([name$=${EntryType.SectionEntryType}])`
  )
  const prevEntryNode = allEntryNodes[currentEntryIndex - 1]
  const nextEntryNode = allEntryNodes[currentEntryIndex + 1]

  return {
    prevEntryNode,
    nextEntryNode
  }
}

export const isEntryAboveViewportTop = (node: Element): boolean => {
  return node.getBoundingClientRect().bottom < 0
}

export const isEntryBelowViewportBottom = (node: Element): boolean => {
  return window.innerHeight - node.getBoundingClientRect().top < 0
}

export const getLastVisibleEntryIndexUpdated = ({
  lastVisibleEntryIndex,
  scrolledEntryIndex,
  isVisible
}: {
  lastVisibleEntryIndex: number
  scrolledEntryIndex: number
  isVisible: boolean
}) => {
  if (isVisible) {
    return scrolledEntryIndex
  }

  // if entry is not in viewport and this entry was last visible before
  // then we need to update lastVisibleEntryIndexRef choosing the one which is visible
  if (scrolledEntryIndex === lastVisibleEntryIndex) {
    const { prevEntryNode } = getPrevNextEntrySiblings(scrolledEntryIndex)
    const prevEntryIndex = scrolledEntryIndex - 1
    const nextEntryIndex = scrolledEntryIndex + 1

    if (!prevEntryNode) {
      return nextEntryIndex
    }

    const isPrevEntryOutsideView = isEntryAboveViewportTop(prevEntryNode)

    return isPrevEntryOutsideView ? nextEntryIndex : prevEntryIndex
  }

  return lastVisibleEntryIndex
}
