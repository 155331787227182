import inRange from 'lodash/inRange'
import { DEFAULT_INCIDENCE_RATE } from '../../../constants/feasibility'

export interface ETARange {
  beginPercentageGroup: number
  endPercentageGroup: number
  beginCompletes: number
  endCompletes: number
  estimatedHours: number
}

export const etaRanges: ETARange[] = [
  {
    beginPercentageGroup: 90,
    endPercentageGroup: 95,
    beginCompletes: 0,
    endCompletes: 50,
    estimatedHours: 6
  },
  {
    beginPercentageGroup: 90,
    endPercentageGroup: 95,
    beginCompletes: 51,
    endCompletes: 100,
    estimatedHours: 8
  },
  {
    beginPercentageGroup: 90,
    endPercentageGroup: 95,
    beginCompletes: 101,
    endCompletes: 250,
    estimatedHours: 12
  },
  {
    beginPercentageGroup: 90,
    endPercentageGroup: 95,
    beginCompletes: 251,
    endCompletes: 500,
    estimatedHours: 18
  },
  {
    beginPercentageGroup: 90,
    endPercentageGroup: 95,
    beginCompletes: 501,
    endCompletes: 0,
    estimatedHours: 36
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 0,
    endCompletes: 10,
    estimatedHours: 2
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 11,
    endCompletes: 25,
    estimatedHours: 3
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 26,
    endCompletes: 50,
    estimatedHours: 4
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 51,
    endCompletes: 100,
    estimatedHours: 6
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 101,
    endCompletes: 250,
    estimatedHours: 8
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 251,
    endCompletes: 500,
    estimatedHours: 12
  },
  {
    beginPercentageGroup: 95,
    endPercentageGroup: 98,
    beginCompletes: 501,
    endCompletes: 0,
    estimatedHours: 24
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 0,
    endCompletes: 10,
    estimatedHours: 1
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 11,
    endCompletes: 25,
    estimatedHours: 2
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 26,
    endCompletes: 50,
    estimatedHours: 3
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 51,
    endCompletes: 100,
    estimatedHours: 5
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 101,
    endCompletes: 250,
    estimatedHours: 7
  },
  {
    beginPercentageGroup: 98,
    endPercentageGroup: 100,
    beginCompletes: 251,
    endCompletes: 0,
    estimatedHours: 10
  }
]

export const getETARange: (
  percentage: number,
  remainingSamples: number
) => ETARange | undefined = (percentage, remainingSamples) =>
  etaRanges.find(
    (etaRange) =>
      percentage >= etaRange.beginPercentageGroup &&
      percentage < etaRange.endPercentageGroup &&
      remainingSamples >= etaRange.beginCompletes &&
      (remainingSamples <= etaRange.endCompletes || etaRange.endCompletes === 0)
  )

export const getIncidenceRateMultiplier: (incidenceRate: number) => number = (
  incidenceRate
) => {
  const isIncidentRateInRange = (start: number, end: number) =>
    inRange(incidenceRate, start, end)

  if (isIncidentRateInRange(0, 10)) return 10

  if (isIncidentRateInRange(10, 15)) return 7

  if (isIncidentRateInRange(15, 20)) return 5

  if (isIncidentRateInRange(20, 30)) return 3

  if (isIncidentRateInRange(30, 80)) return 2

  return 1
}

export const hoursToDays: (hours: number) => string | undefined = (hours) => {
  const d = Math.floor(hours / 24)
  const h = Math.floor(hours % 24) / 24
  const dh = d + Math.floor(h / 0.5) * 0.5

  if (dh > 1) return dh + (dh === 1 ? ' day' : ' days')
  if (h > 0) return hours + (hours === 1 ? ' hour' : ' hours')

  return ''
}

export const getETADisplay: (
  percentage: number,
  remainingSamples: number,
  incidenceRate?: number,
  nonUK?: boolean
) => string | undefined = (
  percentage,
  remainingSamples,
  incidenceRate = DEFAULT_INCIDENCE_RATE,
  nonUK = false
) => {
  const etaRange = getETARange(percentage, remainingSamples)
  const multiplier = nonUK ? 2 : 1
  const incidenceRateMultiplier = getIncidenceRateMultiplier(incidenceRate)
  const etaRangeHours = (etaRange && etaRange.estimatedHours) || 0
  const estimatedHours = etaRangeHours * multiplier * incidenceRateMultiplier

  return (estimatedHours && hoursToDays(estimatedHours)) || ''
}
