import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  inputContainer: {
    paddingRight: theme.spacing(4),
    '&:nth-child(3)': {
      paddingRight: 0
    }
  },
  inputText: {
    paddingBottom: theme.spacing(1)
  },
  errorContainer: {
    paddingLeft: theme.spacing(2)
  }
})

export default useStyles
