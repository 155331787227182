import { Dialog, Radio } from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { FieldworkAudienceType } from '../../data/model/audience'
import { EntryType } from '../../data/model/questionnaire'
import useStyles from './QuestionsDialog.styles'
import { truncateText } from './QuestionsDialog.utils'

export interface QuestionDialogItem {
  id: string
  text: string
  number: number
  entryType: EntryType | FieldworkAudienceType
  position: number
  contextPosition: number
}

interface Props {
  title: string
  dialogHeader?: string
  primaryButtonText: string
  endOfSurveyNumber?: number
  showEndOfSurvey?: boolean
  isQuestionDialogOpen: boolean
  onQuestionDialogClose: () => void
  questionDialogItems?: QuestionDialogItem[]
  onSelectQuestionDialogItem?: (
    entryNumber: number | null,
    index: number | null
  ) => void
}

interface QuestionItemSelection {
  selectedMenuItemNumber: number
  index: number | null
}

const QuestionsDialogDialog: React.FC<Props> = (props: Props) => {
  const {
    title,
    dialogHeader,
    primaryButtonText,
    endOfSurveyNumber,
    showEndOfSurvey,
    isQuestionDialogOpen,
    questionDialogItems,
    onSelectQuestionDialogItem,
    onQuestionDialogClose
  }: Props = props
  const { classes, cx: classNames } = useStyles()
  // TODO rename
  const [questionItemSelection, setQuestionItemSelection] =
    useState<QuestionItemSelection | null>(null)

  const handlePrimaryButtonClick = () => {
    if (onSelectQuestionDialogItem && questionItemSelection) {
      onSelectQuestionDialogItem(
        questionItemSelection.selectedMenuItemNumber,
        questionItemSelection.index
      )
      onQuestionDialogClose()
    }
  }
  const getEntryPrefix: (
    entryType: EntryType | FieldworkAudienceType
  ) => string = (entryType) => {
    switch (entryType) {
      case EntryType.TextCardEntryType:
        return 'T'
      case FieldworkAudienceType.Standard:
      case FieldworkAudienceType.Custom:
        return 'A'
      case EntryType.SectionEntryType:
        return 'S'
      default:
        return 'Q'
    }
  }

  const getDialogItems: (
    questionDialogItems: QuestionDialogItem[] | undefined,
    isRoutingMenuEmpty: boolean
  ) => (JSX.Element | null)[] | undefined = (
    questionDialogItems,
    isRoutingMenuEmpty
  ) => {
    return (
      questionDialogItems &&
      questionDialogItems.map((menuItem: QuestionDialogItem, index) => {
        return (
          <Radio
            key={menuItem.id}
            condensed
            onChange={() => {
              setQuestionItemSelection({
                selectedMenuItemNumber: menuItem.number,
                index
              })
            }}
            checked={
              questionItemSelection?.selectedMenuItemNumber ===
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                menuItem.number && questionItemSelection?.index === index
            }
            label={
              <Grid
                key={`menu-item-${menuItem.id}`}
                container
                direction="row"
                justifyContent="flex-start"
                alignContent="flex-start"
                className={classes.menuItem}
              >
                {!isRoutingMenuEmpty && (
                  <Grid item xs="auto" className={classes.menuItemNumber}>
                    <p className={classes.menuItemTextSemiBold}>
                      {getEntryPrefix(menuItem.entryType)}
                      {menuItem.contextPosition + 1}
                    </p>
                  </Grid>
                )}
                <Grid item xs="auto" className={classes.menuItemText}>
                  <p className={classes.menuItemTextRegular}>
                    {truncateText(menuItem.text)}
                  </p>
                </Grid>
              </Grid>
            }
          />
        )
      })
    )
  }

  const isRoutingMenuEmpty = questionDialogItems?.length === 0
  const menuItems =
    isQuestionDialogOpen &&
    getDialogItems(questionDialogItems, isRoutingMenuEmpty)

  return (
    <Dialog
      title={title}
      primaryButtonText={primaryButtonText}
      fullWidth
      open={isQuestionDialogOpen}
      onClose={onQuestionDialogClose}
      primaryButtonClick={handlePrimaryButtonClick}
    >
      {dialogHeader && <p className={classes.dialogHeader}>{dialogHeader}</p>}
      <div className={classes.menu}>{menuItems}</div>
      {endOfSurveyNumber !== undefined &&
        (showEndOfSurvey ||
          (!isRoutingMenuEmpty && showEndOfSurvey !== false)) && (
          <div className={classes.endOfSurveyContainer}>
            <Radio
              condensed
              onChange={() => {
                setQuestionItemSelection({
                  selectedMenuItemNumber: endOfSurveyNumber,
                  index: null
                })
              }}
              checked={
                questionItemSelection?.selectedMenuItemNumber ===
                endOfSurveyNumber
              }
              label={
                <Grid
                  container
                  direction="column"
                  className={classNames(
                    classes.menuItem,
                    classes.endOfSurveyOption
                  )}
                >
                  <p className={classes.menuItemTextSemiBold}>End of survey</p>
                  <p className={classes.menuItemTextRegular}>
                    Route to end of survey
                  </p>
                </Grid>
              }
            />
          </div>
        )}
    </Dialog>
  )
}

export default QuestionsDialogDialog
