import { DropResult, List } from '@focaldata/cin-ui-components'
import { ComponentProps, memo, useCallback, useContext } from 'react'
import {
  DraftCustomAudience,
  DraftCustomAudienceCriterionOption,
  FieldworkAudienceType,
  MemberSettingCode,
  SettingValue
} from '../../../data/model/audience'
import { useDefaultState } from '../../../hooks/useDefaultState'
import AudienceContext from '../Audience.context'
import { useLoopSourceMatches } from '../AudienceQuestionCardFooter/useLoopSourceMatches'
import { getIsEnabledInSettings } from '../utils'
import CustomAudienceResponseOption from './CustomAudienceResponseOption'

type CustomAudienceResponseOptionsListControlProps = {
  questionLk: string
  responseOptions: DraftCustomAudienceCriterionOption[]
  onMoveOption: (responseOptionLk: string, toPosition: number) => void
} & Pick<
  ComponentProps<typeof CustomAudienceResponseOption>,
  | 'isAudienceInvalid'
  | 'onSetCustomQuota'
  | 'onToggleQualification'
  | 'onUpdateOptionText'
  | 'onUpdateQuotaValues'
  | 'qualifyingEnabled'
  | 'quotasEnabled'
  | 'validationErrors'
  | 'onDeleteOption'
>

const CustomAudienceResponseOptionsListControl = ({
  questionLk,
  responseOptions,
  onMoveOption,
  ...responseOptionProps
}: CustomAudienceResponseOptionsListControlProps) => {
  const { audienceState } = useContext(AudienceContext)
  const audience = audienceState.audienceMembers.find(
    (m) =>
      m.memberType === FieldworkAudienceType.Custom &&
      (m.memberItem as DraftCustomAudience).questionLk === questionLk
  )

  const customAudience = audience?.memberItem as DraftCustomAudience
  const loopSourceMatches = useLoopSourceMatches(questionLk)

  const [options, setOptions] = useDefaultState(responseOptions)

  const handleMoveCustomOption = useCallback(
    (dropResult: DropResult) => {
      if (dropResult.destination) {
        const destinationIndex: number = dropResult.destination.index
        const sourceIndex = dropResult.source.index
        const result = [...options]
        const [removed] = result.splice(sourceIndex, 1)
        result.splice(destinationIndex, 0, removed)

        const { responseOptionLk } = options[dropResult.source.index]
        onMoveOption(responseOptionLk, destinationIndex)
        setOptions(result)
      }
    },
    [onMoveOption, options, setOptions]
  )

  const isRandomiseOn = getIsEnabledInSettings(
    customAudience.settingValues,
    MemberSettingCode.RandomiseOptions
  )

  const isMultipleChoice = customAudience.settingValues.some(
    (settingValue) => settingValue.value === SettingValue.MultipleChoice
  )

  return (
    <List
      canReorderItems
      droppableId={`drop-${questionLk}`}
      onReorderedItems={handleMoveCustomOption}
    >
      {options.map((option) => (
        <CustomAudienceResponseOption
          {...responseOptionProps}
          key={option.responseOptionLk}
          questionLk={customAudience.questionLk}
          ariaLabel="Custom audience criteria option"
          responseOption={option}
          cannotDelete={options.length <= 2}
          isRandomiseOn={isRandomiseOn}
          isMultiSelect={isMultipleChoice}
          matchedWithLoopSource={loopSourceMatches.has(option.responseOptionLk)}
        />
      ))}
    </List>
  )
}

export default memo(CustomAudienceResponseOptionsListControl)
