import { gql } from '@apollo/client/core'
import RESPONSE_OPTION from './responseOption'

const DRAFT_CUSTOM_CRITERION_OPTION = gql`
  fragment DraftCustomCriterionOption on DraftCustomAudienceCriterionOption {
    responseOptionLk
    responseOption {
      ...ResponseOption
    }
    position
    qualification {
      createdDate
      sinceDate
    }
    quota {
      percent
      createdDate
      sinceDate
    }
    pinned
    exclusive
    createdDate
    sinceDate
    media {
      mediaName
      mediaUrl
      mediaType
      renderedMediaUrl
    }
  }
  ${RESPONSE_OPTION}
`

export default DRAFT_CUSTOM_CRITERION_OPTION
