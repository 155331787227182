import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const addDraftCombinedResponseOption: Resolver = (
  _,
  __,
  { cache }: { cache: InMemoryCache }
) => {
  const data = cache.readQuery({
    query: GET_DRAFT_COMBINED_QUESTION
  })

  if (!data) {
    throw new Error('No draft combined question found in cache')
  }

  const { mappedResponseOptions } = data.draftCombinedQuestion

  const emptyResponseOption = {
    __typename: 'DraftCombinedResponseOption' as const,
    id: uuid(),
    mappingNumber: mappedResponseOptions.length + 1,
    value: ''
  }

  cache.writeQuery({
    query: GET_DRAFT_COMBINED_QUESTION,
    data: produce(data, (draft) => {
      draft.draftCombinedQuestion.mappedResponseOptions.push(
        emptyResponseOption
      )
    })
  })

  return emptyResponseOption
}
