export const minimumLengthCheck: (password: string) => boolean = (password) => {
  const minPasswordLen = 8
  const isValid = password.length >= minPasswordLen

  return isValid
}
export const usingNumbersCheck: (password: string) => boolean = (password) => {
  const isValid = /\d/.test(password)

  return isValid
}
export const usingCapitalsCheck: (password: string) => boolean = (password) => {
  const isValid = password.toLowerCase() !== password

  return isValid
}

export const usingLowercaseCheck: (password: string) => boolean = (
  password
) => {
  const isValid = password.toUpperCase() !== password

  return isValid
}

export const isPasswordStrong: (password: string) => boolean = (password) => {
  return (
    minimumLengthCheck(password) &&
    usingNumbersCheck(password) &&
    usingLowercaseCheck(password) &&
    usingCapitalsCheck(password)
  )
}
