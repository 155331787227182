import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  Text,
  TextHighlight,
  TextSize,
  theme
} from '@focaldata/cin-ui-components'
import { TranscriptType } from '../../../FdChat.model'

const TranscriptDialog = ({
  open,
  closeDialog,
  transcript,
  quoteToHighlight
}: {
  open: boolean
  closeDialog: () => void
  transcript?: TranscriptType
  quoteToHighlight?: string
}) => {
  return (
    <Dialog
      title="Interview transcript"
      open={open}
      primaryButtonText="Close"
      primaryButtonClick={closeDialog}
      onClickOutside={closeDialog}
      fullWidth
      transparentBackground
      variant={DialogVariant.SingleButton}
      maxWidth={DialogMaxWidth.Md}
    >
      <Grid container direction={'column'} marginBottom={'32px'}>
        <Text size={TextSize.ms}>Date: {transcript?.date}</Text>
        <Text size={TextSize.ms}>
          Participant: {transcript?.respondent_info}
        </Text>
      </Grid>
      <Grid
        container
        direction={'column'}
        gap={'20px'}
        maxHeight={'300px'}
        overflow={'auto'}
        wrap="nowrap"
      >
        {transcript?.messages.map((message) => (
          <div key={message.id}>
            <Grid
              container
              alignItems={'center'}
              gap={'10px'}
              marginBottom={'10px'}
            >
              <Grid
                container
                justifyContent={'center'}
                alignContent={'center'}
                style={{
                  borderRadius: '50%',
                  backgroundColor:
                    message.name === 'Interviewer'
                      ? theme.palette.grey[500]
                      : theme.palette.primary.main,
                  height: '20px',
                  width: '20px'
                }}
              >
                <Text highlight={TextHighlight.White} size={TextSize.s}>
                  {message.name === 'Interviewer' ? 'FD' : 'J'}
                </Text>
              </Grid>
              <Text highlight={TextHighlight.Background} size={TextSize.s}>
                {message.name}
              </Text>
              <Text highlight={TextHighlight.Background} size={TextSize.s}>
                {message.time}
              </Text>
            </Grid>
            <div
              style={{
                backgroundColor:
                  message.message === quoteToHighlight ? '#F6E620' : undefined
              }}
            >
              <Text size={TextSize.ms}>{message.message}</Text>
            </div>
          </div>
        ))}
      </Grid>
    </Dialog>
  )
}

export default TranscriptDialog
