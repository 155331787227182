import { Grid, InfoIcon, InfoIconColor } from '@focaldata/cin-ui-components'
import { TextHighlight, TextWeight } from '../../../constants/text'
import {
  DraftStandardAudience,
  FieldworkAudienceCriterionOption,
  MemberSettingCode
} from '../../../data/model/audience'
import { proportionToPercent } from '../../../utils/HelperFunctions'
import { getIsEnabledInSettings } from '../utils'
import useStyles from './StandardAudienceSummaryItem.styles'

interface Props {
  audience: DraftStandardAudience
  hasError?: boolean
  showQuotas?: boolean
}

const StandardAudienceSummaryItem = ({
  audience,
  hasError,
  showQuotas
}: Props) => {
  const {
    settingValues,
    criterion,
    standardCriterionOptions,
    customizedCriterionOptions = []
  } = audience
  const { classes } = useStyles()
  const customizedCriterionOptionsEnabled = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.CustomizedCriterionOptions
  )
  const areQuotasOn = getIsEnabledInSettings(
    settingValues,
    MemberSettingCode.Quotas
  )

  const qualifyingOptions: FieldworkAudienceCriterionOption[] = (
    customizedCriterionOptionsEnabled
      ? customizedCriterionOptions
      : standardCriterionOptions
  ).filter((option) => option.qualification)

  const lastIndex: number = qualifyingOptions.length - 1

  return (
    <Grid className={classes.item}>
      <Grid container direction="row" alignItems="center">
        <p style={{ fontWeight: TextWeight.SemiBold }}>{criterion.name}</p>
        {hasError && (
          <InfoIcon
            iconColor={InfoIconColor.Error}
            text="There are errors in your targeting and sampling. You’ll need to resolve them before launch."
          />
        )}
      </Grid>

      <Grid container direction="row">
        {qualifyingOptions.map(
          (option: FieldworkAudienceCriterionOption, index: number) => {
            return (
              <div
                className={classes.optionContainer}
                key={option.criterionOption.code}
              >
                <p
                  style={{
                    fontWeight: TextWeight.SemiBold,
                    color: TextHighlight.Background
                  }}
                >
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                  {option.criterionOption.responseOption?.value}
                  {showQuotas && areQuotasOn && option.quota && (
                    <span style={{ fontWeight: 400 }}>
                      {`\xa0(${proportionToPercent(option.quota.percent)}%)`}
                    </span>
                  )}
                  {index < lastIndex && ',\xa0'}
                </p>
              </div>
            )
          }
        )}
      </Grid>
    </Grid>
  )
}

export default StandardAudienceSummaryItem
