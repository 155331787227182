import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  border: {
    borderRight: '1px solid',
    height: 10
  },
  infoPanelHeader: {
    padding: theme.spacing(1.75, 3, 1.75, 3)
  },
  infoPanelFooter: {
    padding: theme.spacing(1.75, 3, 1.75, 3)
  },
  itemPanelGroup: {
    padding: theme.spacing(1.75, 3, 1.75, 3)
  },
  itemPanel: {
    paddingBottom: theme.spacing(1.75)
  },
  items: {
    minWidth: theme.spacing(48),
    outline: 'none'
  },
  rightChild: {
    paddingLeft: theme.spacing(1)
  },
  buttonSubheader: {
    height: theme.spacing(6.5)
  },
  loader: {
    height: '100%'
  },
  increment: {
    marginRight: `-${theme.spacing(0.75)}`,
    paddingLeft: theme.spacing(0.5)
  },
  incidenceRateText: {
    paddingLeft: theme.spacing(1)
  }
})

export default useStyles
