import { Dialog, DialogMaxWidth } from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { DataFormat, OutputType } from '../../data/model/shared'
import DialogDataTables from '../DialogDataTables'

interface Props {
  isDialogDataTablesOpen?: boolean
  toggleDialogRequestData: () => void
  handleRequestData: (dataFormat: DataFormat, outputType: OutputType) => void
  loading: boolean
}

const DialogRequestData: React.FC<Props> = (props: Props) => {
  const {
    isDialogDataTablesOpen,
    handleRequestData,
    toggleDialogRequestData,
    loading
  } = props

  const [outputType, setOutputType] = useState<OutputType>(OutputType.Weighted)
  const [dataFormat, setDataFormat] = useState<DataFormat>(
    DataFormat.WIDE_UNENCODED
  )

  return (
    <Dialog
      open={!!isDialogDataTablesOpen}
      onClose={() => toggleDialogRequestData()}
      primaryButtonClick={() => {
        handleRequestData(dataFormat, outputType)
      }}
      title="Request data tables"
      primaryButtonText="Request"
      maxWidth={DialogMaxWidth.Sm}
      onClickOutside={() => toggleDialogRequestData()}
      primaryButtonDisabled={loading}
    >
      <DialogDataTables
        dataFormat={dataFormat}
        setDataFormat={setDataFormat}
        outputType={outputType}
        setOutputType={setOutputType}
      />
    </Dialog>
  )
}

export default DialogRequestData
