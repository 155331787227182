import {
  Button,
  ButtonVariant,
  IconButton,
  IconColor,
  IconName,
  IconSize
} from '@focaldata/cin-ui-components'
import Box from '@mui/material/Box'
import React from 'react'

import useStyles, { StyleProps } from './BottomNav.styles'
import RightButtons from './RightButtons'

export enum PositionType {
  Fixed,
  Relative
}

interface OwnProps {
  middleButtonText?: string
  middleButtonDisabled?: boolean
  middleButtonControl?: React.ReactNode
  onClickRightButton?: () => void
  onClickMiddleButton?: () => void
  position?: PositionType
  width?: string
  rightButtonDisabled?: boolean
  rightButtonText?: string
  secondaryRightButtonText?: string
  onClickSecondaryRightButton?: () => void
  secondaryRightButtonDisabled?: boolean
}

export type Props = OwnProps & React.HTMLAttributes<HTMLElement>

const BottomNav: React.FC<Props> = (props: Props) => {
  const {
    middleButtonText,
    middleButtonDisabled,
    middleButtonControl,
    onClickRightButton,
    onClickMiddleButton,
    position = PositionType.Fixed,
    width = '100%',
    rightButtonDisabled = false,
    rightButtonText,
    secondaryRightButtonText,
    secondaryRightButtonDisabled = false,
    onClickSecondaryRightButton
  }: Props = props

  const positionTypes = new Map<PositionType, 'fixed' | 'relative'>([
    [PositionType.Fixed, 'fixed'],
    [PositionType.Relative, 'relative']
  ])

  const positionString = positionTypes.get(position)

  const styleProps: StyleProps = { position: positionString || 'fixed', width }

  const { classes, cx: classNames } = useStyles({ styleProps })

  const getMiddleButton = (): React.ReactNode => {
    if (middleButtonControl) {
      return middleButtonControl
    }
    if (middleButtonText && onClickMiddleButton) {
      return (
        <>
          <Box display={{ xs: 'block', md: 'none' }}>
            <IconButton
              disabled={middleButtonDisabled}
              className={classes.iconButton}
              onClick={onClickMiddleButton}
              iconName={IconName.Add}
              iconSize={IconSize.Large}
              iconColor={IconColor.White}
              ariaLabel="Add question"
              hasShadow
            >
              {middleButtonText}
            </IconButton>
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Button
              disabled={middleButtonDisabled}
              className={classes.button}
              onClick={onClickMiddleButton}
              variant={ButtonVariant.SecondaryFilled}
            >
              {middleButtonText}
            </Button>
          </Box>
        </>
      )
    }

    return null
  }

  return (
    <div
      className={classNames(
        'fd-grid fd-container fd-justify-content-between fd-align-items-center',
        classes.grid
      )}
    >
      <div className="fd-grid fd-container fd-xs-5">
        {/* TODO: create the ui for the error tracking */}
      </div>
      <div className="fd-grid fd-container fd-xs-1 fd-sm-2 fd-row fd-justify-content-center fd-align-items-center">
        {getMiddleButton()}
      </div>
      <div className="fd-grid fd-container fd-xs-6 fd-sm-5 fd-justify-content-flex-end">
        <RightButtons
          onClickRightButton={onClickRightButton}
          rightButtonDisabled={rightButtonDisabled}
          rightButtonText={rightButtonText}
          secondaryRightButtonText={secondaryRightButtonText}
          secondaryRightButtonDisabled={secondaryRightButtonDisabled}
          onClickSecondaryRightButton={onClickSecondaryRightButton}
        />
      </div>
    </div>
  )
}

export default BottomNav
