import { Switch } from '@focaldata/cin-ui-components'
import React from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { QuestionSettingCode } from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { getSettingValue } from '../../../utils/HelperFunctions'
import { WithEntry } from '../../../utils/questionnaireUtils'
import { BoolSettingToggler } from '../QuestionCardSwitches'
import { getEntryId } from '../Questionnaire.utils'

interface Props extends WithEntry {
  isSliderSwitchOn?: boolean
  onShowSliderLabels?: () => void
}

const SliderSwitches: React.FC<Props> = (props: Props) => {
  const { entry, isSliderSwitchOn, onShowSliderLabels } = props
  const surveyId = useSurveyId()
  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const handleShowSliderLabels: BoolSettingToggler = (settingValue) => {
    if (onShowSliderLabels) {
      onShowSliderLabels()
    }
    handleSettingChange(
      [{ settingCode: QuestionSettingCode.SliderLabels, settingValue }],
      'no-cache'
    )
  }

  return (
    <div className="fd-grid fd-container">
      <Switch
        first
        checked={isSliderSwitchOn}
        onChange={(event) => {
          const settingValue = getSettingValue(event.target.checked)

          handleShowSliderLabels(settingValue)

          if (event.target.checked) {
            LogAmplitudeEvent(EventType.ShowedLabelsOnSlider, {
              surveyId,
              questionId: getEntryId(entry)
            })
          }
        }}
        label="Show labels"
      />
    </div>
  )
}

export default SliderSwitches
