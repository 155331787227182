import {
  Dialog,
  Text,
  TextHighlight,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'

interface Props {
  isOpen: boolean
  toggleOpenDialog: (open: boolean) => void
  onConfirm: () => void
  loading?: boolean
}

const mainText = 'Full launch?'
const buttonText = 'Confirm'
const generalExplanation = 'By clicking confirm, you will re-start the project.'

const additionalExplanation =
  "You'll see all results together in the dashboard. This will combine answers collected as part of the soft launch with those collected following the full launch."

const FullLaunchDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, toggleOpenDialog, onConfirm, loading }: Props = props
  return (
    <Dialog
      open={isOpen}
      onClickOutside={() => toggleOpenDialog(false)}
      onClose={() => toggleOpenDialog(false)}
      title={mainText}
      primaryButtonText={buttonText}
      primaryButtonClick={onConfirm}
      primaryButtonLoading={loading}
      primaryButtonDisabled={loading}
    >
      <Text weight={TextWeight.SemiBold} gutterBottom>
        {generalExplanation}
      </Text>
      <Text highlight={TextHighlight.Background}>{additionalExplanation}</Text>
    </Dialog>
  )
}

export default FullLaunchDialog
