/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum'

export const TransactionConsoleHandler = {
  start(name: string) {
    window.performance.mark(name)
  },
  end(name: string) {
    const { duration } = window.performance.measure(
      '_',
      name
    ) as unknown as PerformanceMeasure
    console.log(`${name}: ${duration} ms`)
  }
}

export const TransactionPerformanceHandler = {
  DEMO_NODE_ID: 'test-performance',
  start(name: string) {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const body = document.querySelector('body')!
    let p = document.getElementById(this.DEMO_NODE_ID)
    const initialTextContent = `${name}:`

    if (p) {
      p.textContent = initialTextContent
    } else {
      p = document.createElement('p')
      p.textContent = initialTextContent
      p.id = this.DEMO_NODE_ID
      Object.assign(p.style, {
        position: 'fixed',
        top: 0,
        left: '25%',
        color: 'tomato',
        fontSize: '1.5rem',
        background: '#eee',
        padding: '10px 25px'
      })

      body.append(p)
    }

    window.performance.mark(name)
  },
  end(name: string) {
    const { duration } = window.performance.measure(
      '_',
      name
    ) as unknown as PerformanceMeasure
    const p = document.getElementById(this.DEMO_NODE_ID)
    if (p) {
      p.textContent = `${name}: ${(duration / 1000).toFixed(3)}s`
    }
    performance.clearMarks()
    performance.clearMeasures()
  }
}

export const TransactionDatadogHandler = {
  start(name: string) {
    window.performance.mark(name)
  },
  end(name: string) {
    const { duration } = window.performance.measure(
      '_',
      name
    ) as unknown as PerformanceMeasure
    datadogRum.addAction(name, {
      duration
    })
  }
}

export const NoopTransactionHandler = {
  start: () => undefined,
  end: () => undefined
}
