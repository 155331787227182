import { datadogRum } from '@datadog/browser-rum'
import isEmpty from 'lodash/isEmpty'
import packageJson from '../../../package.json'
import { getEnvironment } from '../../utils/HelperFunctions'
import UserSession from '../../utils/UserSession'
import { isFocaldataUser } from '../../utils/authUtils'
import { pickBy } from '../../utils/object/pickBy'

export const DATADOG_VERSION =
  process.env.REACT_APP_GIT_VERSION ?? `fallback-${packageJson.version}`

/**
 * Datadog
 * @method init Initialises Datadog monitoring for focaldata users only
 * @method updateUserSession Updates user session info, e.g. when user logs off and then logs in with another credentials
 */
export const Datadog = {
  init: () => {
    const env = getEnvironment()
    const email = UserSession.getEmail()
    const accountId = UserSession.maybeGetAccountId()
    const accountName = UserSession.getAccountName()

    datadogRum.init({
      applicationId: 'cc82d0f4-c9b1-4de6-b1ce-a0747d8f1d6d',
      clientToken: 'pubfbeff228b80ed86710490dfce6cf23f0',
      site: 'datadoghq.eu',
      service: packageJson.name,
      env,
      version: DATADOG_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })

    const userInfo = pickBy(
      {
        id: email ?? undefined,
        email: email ?? undefined,
        accountId,
        accountName,
        focaldataUser: isFocaldataUser()
      },
      Boolean
    )

    if (!isEmpty(userInfo)) {
      datadogRum.setUser(userInfo)
    }

    datadogRum.startSessionReplayRecording()
  },

  /**
   * Updates Datadog user session info. Merges provided properties resetting the values by provided keys; doesn't modify the rest properties which exist. Setting property to falsy value will remove it from Datadog session.
   * @example
   * // having the following data set in Datadog session
   * datadogRum.setUser({
   *  id: '123',
   *  email: 'email@example.com',
   *  name: 'John Doe'
   * })
   *
   * // will update name from 'John Doe' to 'Jane Doe' keeping id and email the same
   * Datadog.updateUserSession({ name: 'Jane Doe' }) // -> { id: '123', email: 'email@example.com', name: 'Jane Doe' }
   *
   * // will remove name but keep id and email in Datadog session
   * Datadog.updateUserSession({ name: undefined }) // -> { id: '123', email: 'email@example.com' }
   */
  updateUserSession: (
    sessionInfo: {
      id?: string
      email?: string
      name?: string
      accountId?: string
      accountName?: string
      surveyId?: string
    } & {
      [key: string]: string
    }
  ) => {
    const currentSessionInfo = datadogRum.getUser()

    Object.entries(sessionInfo).forEach(([key, value]) => {
      // do nothing if the value is the same
      if (currentSessionInfo[key] === value) {
        return
      }

      // in case new value is different either update property or remove it
      if (value) {
        datadogRum.setUserProperty(key, value)
      } else {
        datadogRum.removeUserProperty(key)
      }
    })
  }
}
