import { isFocaldataUser } from '../../utils/authUtils'
import {
  NoopTransactionHandler,
  TransactionConsoleHandler,
  TransactionDatadogHandler,
  TransactionPerformanceHandler
} from './transactionHandlers'

interface TransactionOptions {
  skipShorterThan: number
}

class Transaction {
  public isRunning: boolean

  private name: string

  private handler: typeof TransactionConsoleHandler

  private startTimestamp: number

  private options?: TransactionOptions

  constructor({
    name,
    handler,
    options
  }: {
    name: string
    handler: typeof TransactionConsoleHandler
    options?: TransactionOptions
  }) {
    this.name = name
    this.handler = handler
    this.startTimestamp = -1
    this.isRunning = false
    this.options = options
    this.invalidate()
  }

  invalidate() {
    this.isRunning = false
    return this
  }

  start() {
    if (this.isRunning) {
      return this
    }
    this.startTimestamp = Date.now()
    this.handler.start(this.name)
    this.isRunning = true
    return this
  }

  end() {
    if (!this.isRunning) {
      return this
    }

    if (
      typeof this.options?.skipShorterThan === 'number' &&
      this.options.skipShorterThan > Date.now() - this.startTimestamp
    ) {
      return this.invalidate()
    }

    this.handler.end(this.name)

    return this.invalidate()
  }
}

export class TransactionConsole extends Transaction {
  constructor(name: string, options?: TransactionOptions) {
    super({
      name,
      handler: TransactionConsoleHandler,
      options
    })
  }
}

export class TransactionPerformance extends Transaction {
  constructor(name: string, options?: TransactionOptions) {
    super({
      name,
      handler: TransactionPerformanceHandler,
      options
    })
  }
}

export class TransactionDatadog extends Transaction {
  constructor(name: string, options?: TransactionOptions) {
    super({
      name,
      handler: isFocaldataUser()
        ? TransactionDatadogHandler
        : NoopTransactionHandler,
      options
    })
  }
}
