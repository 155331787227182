import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  AudienceData,
  GET_AUDIENCES
} from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

/**
 * @deprecated You should use useQuery/useFragment with the *specific* query you want to use instead of this hook.
 */
const useGetDraftAudience = (options?: QueryHookOptions) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const {
    data,
    loading,
    error,
    refetch: refetchAudience
  } = useQuery<AudienceData>(GET_AUDIENCES, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'draftAudience', error)
    },
    ...options
  })

  return { draftAudience: data?.draftAudience, loading, error, refetchAudience }
}

export default useGetDraftAudience
