import { Dialog, DialogMaxWidth } from '@focaldata/cin-ui-components'
import React, { useCallback, useRef, useState } from 'react'
import { EntryType, QuestionKind } from '../../../data/model/questionnaire'
import {
  RawQuestionnaireEntry,
  RawQuestionnaireEntryInput
} from '../../../hooks/import/models'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { createRawValue } from '../CardTitle/CardTitle.utils'
import { useDialogHooks } from './Dialog.hooks'
import FilePicker from './DocxFileInput/FilePicker'
import useDocxProcessor from './DocxProcessor/DocxProcessor.hooks'
import ImportListPreview from './ImportListPreview'
import {
  Pair,
  getQuestionTypeText,
  getQuestionTypeTextFromRaw
} from './import.utils'

const ImportFromFileDialog: React.FC = () => {
  const getRawQuestionnaireFromDocx = useDocxProcessor()
  const dataToImport = useRef<RawQuestionnaireEntryInput[]>([])
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true)

  const [importedQuestions, setImportedQuestions] =
    React.useState<RawQuestionnaireEntry[]>()

  const handleResetPreview = () => {
    setImportedQuestions([])
    dataToImport.current = []
    setIsImportButtonDisabled(true)
  }

  const { flattenedEntries } = useGetDraftQuestionnaire()
  const filteredQuestionnaireEntries = flattenedEntries.filter(
    (q) =>
      ![EntryType.ForkEntryType, EntryType.SectionEntryType].includes(
        q.entryType
      ) && q.questionKind === QuestionKind.QuestionnaireKind
  )

  const { isDialogOpen, isLoading, handleDialogClose, handleDialogSave } =
    useDialogHooks(handleResetPreview)

  const handleFileSelected = useCallback(
    async (file: File) => {
      const rawQuestions = await getRawQuestionnaireFromDocx(file)
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const importedQuestionnaireQuestions = rawQuestions?.filter(
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (q) => q?.kind === QuestionKind.QuestionnaireKind
      )
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (importedQuestionnaireQuestions?.length > 0) {
        setImportedQuestions(importedQuestionnaireQuestions)
      }
    },
    [getRawQuestionnaireFromDocx]
  )

  const validateMapping = (pairs: Pair[]) => {
    if (
      pairs.some(
        (p) =>
          p.item1 &&
          p.item2 &&
          getQuestionTypeText(p.item1) !== getQuestionTypeTextFromRaw(p.item2)
      )
    ) {
      setIsImportButtonDisabled(true)
    }
  }

  const handleMappingChanged = (newPairs: Pair[]) => {
    const entriesToImport = newPairs.reduce(
      (acc: RawQuestionnaireEntryInput[], pair: Pair) => {
        if (!pair.item2?.isChecked) return acc
        const { item2 } = pair

        acc.push({
          position: item2.position,
          kind: item2.kind,
          entryType: item2.entryType,
          questionType: item2.questionType,
          title: item2.title,
          styling: JSON.stringify(createRawValue(item2.title)),
          subtitle: item2.subtitle,
          options: item2.options.filter((o) => o.isChecked),
          setting: item2.setting,
          rows: item2.rows.filter((o) => o.isChecked),
          isNew: item2.isNew
        })

        return acc
      },
      []
    )
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    setIsImportButtonDisabled(entriesToImport?.length === 0)
    validateMapping(newPairs)
    dataToImport.current = entriesToImport
  }

  return (
    <Dialog
      title="Import Survey (.docx)"
      primaryButtonText="Import"
      open={isDialogOpen}
      fullWidth
      primaryButtonLoading={isLoading}
      primaryButtonDisabled={isImportButtonDisabled}
      maxWidth={
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        filteredQuestionnaireEntries?.length
          ? DialogMaxWidth.Lg
          : DialogMaxWidth.Md
      }
      primaryButtonClick={() => handleDialogSave(dataToImport.current)}
      onClose={handleDialogClose}
    >
      <FilePicker
        onFileSelected={handleFileSelected}
        onFileRemoved={handleResetPreview}
      />
      {!!importedQuestions?.length && (
        <ImportListPreview
          questionnaireQuestions={filteredQuestionnaireEntries}
          importedQuestions={importedQuestions}
          onMappingChanged={handleMappingChanged}
        />
      )}
    </Dialog>
  )
}

export default ImportFromFileDialog
