import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_COST_QUOTE = questionnaireGql(/* GraphQL */ `
  query getCostQuoteV2(
    $projectId: ID!
    $surveyId: ID!
    $lengthOfInterview: Int
  ) {
    getCostQuote: getCostQuoteV2(
      projectId: $projectId
      surveyId: $surveyId
      loi: $lengthOfInterview
    ) {
      cpi {
        amount
        baseAmount
        currency
      }
    }
  }
`)
