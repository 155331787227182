import { Checkbox, Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import { produce } from 'immer'
import React from 'react'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  EntryType,
  QuestionKind
} from '../../../../data/model/questionnaire'
import {
  checkIfFreeText,
  checkIfMatrixMultipleChoice,
  checkIfMultipleChoice,
  checkIfRankedChoice,
  checkIfScale,
  truncateText
} from '../../../../utils/questionnaireUtils'
import {
  ImportQuestionsEntry,
  ImportQuestionsMatrixItem,
  ImportQuestionsQuestionItem,
  ImportQuestionsTextItem
} from './ImportQuestionsPicker.query'
import useStyles from './ImportQuestionsPicker.styles'

interface Props {
  entries: ImportQuestionsEntry[]
  questionSelection: string[]
  setQuestionSelection: (newSelection: string[]) => void
}

const ImportQuestionsPickerControl: React.FC<Props> = ({
  entries,
  questionSelection,
  setQuestionSelection
}: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  // no forks, audience questions
  const filteredQuestions = entries.filter(
    (entry) =>
      entry.questionKind === QuestionKind.QuestionnaireKind &&
      entry.entryType !== EntryType.ForkEntryType
  )

  const getQuestionNumberingText = (entry: ImportQuestionsEntry): string =>
    `${entry.entryType === EntryType.TextCardEntryType ? 'T' : 'Q'}${
      entry.contextPosition + 1
    }`

  const getQuestionText = (entry: ImportQuestionsEntry): string => {
    switch (entry.entryType) {
      case EntryType.MatrixEntryType:
        return truncateText(
          120,
          (entry.entryItem as ImportQuestionsMatrixItem).matrixTitle.title,
          true
        )
      case EntryType.TextCardEntryType:
        return truncateText(
          120,
          (entry.entryItem as ImportQuestionsTextItem).textCard.title,
          true
        )
      case EntryType.QuestionEntryType:
      default:
        return truncateText(
          120,
          (entry.entryItem as ImportQuestionsQuestionItem).question.text,
          true
        )
    }
  }

  const getQuestionTypeText = (entry: ImportQuestionsEntry): string => {
    switch (entry.entryType) {
      case EntryType.MatrixEntryType: {
        const matrix = entry.entryItem as ImportQuestionsMatrixItem
        const isMultipleChoice = checkIfMatrixMultipleChoice(
          matrix.settingValues as DraftMatrixItem['settingValues']
        )
        return isMultipleChoice ? 'Matrix multi select' : 'Matrix single select'
      }
      case EntryType.QuestionEntryType: {
        const question = entry.entryItem as DraftQuestionItem
        if (checkIfMultipleChoice(question.settingValues)) {
          return 'Multi select'
        }
        if (checkIfFreeText(question)) {
          return 'Text input'
        }
        if (checkIfScale(question)) {
          return 'Slider'
        }
        if (checkIfRankedChoice(question)) {
          return 'Ranked'
        }
        return 'Single select'
      }
      case EntryType.TextCardEntryType:
      default:
        return 'Text instruction'
    }
  }

  const handleCheckboxChanged = (entryId: string): void => {
    const indexAt = questionSelection.findIndex((id) => id === entryId)
    const newQuestionSelection = produce(questionSelection, (draft) => {
      if (indexAt > -1) {
        draft.splice(indexAt, 1)
      } else {
        draft.push(entryId)
        // TODO: this can probably be a cheaper computation if we keep position as well
        draft.sort(
          (a, b) =>
            entries.findIndex((entry) => entry.id === a) -
            entries.findIndex((entry) => entry.id === b)
        )
      }
    })

    setQuestionSelection(newQuestionSelection)
  }

  return (
    <Grid container className={classes.questionContainer}>
      {filteredQuestions.map((entry) => (
        <Grid
          key={entry.number}
          container
          direction="column"
          className={classes.questionItem}
        >
          <Grid container direction="row" className={classes.questionSubItem1}>
            <Grid item sm={1}>
              <Checkbox
                noPadding
                checked={questionSelection.includes(entry.id)}
                onChange={() => handleCheckboxChanged(entry.id)}
              />
            </Grid>
            <Grid item sm={11} className={classes.questionTextContainer}>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.displayInline,
                  textClasses.weightSemiBold
                )}
              >
                {getQuestionNumberingText(entry)}{' '}
              </p>
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.displayInline
                )}
              >
                {getQuestionText(entry)}
              </p>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.questionSubItem}>
            <p className={classNames(textClasses.default, textClasses.sizeMs)}>
              {getQuestionTypeText(entry)}
            </p>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImportQuestionsPickerControl
