import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  MultiEmaiInput,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'

import { DuplicateInviteRequestError } from './DuplicateInviteRequestError'
import { InviteRequestGenericError } from './InviteRequestGenericError'
import InviteResult from './InviteResult'
import useStyles from './styles'

interface Props {
  dialogOpen: boolean
  onSendInvites: (emails: string[]) => Promise<boolean | Error>
  onCloseDialog?: () => void
}

const InviteTeamDialogControl: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { dialogOpen, onSendInvites, onCloseDialog } = props
  const [isError, setIsError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [emails, setEmails] = useState<string[]>([])
  const [emailsWithError, setEmailsWithError] = useState<string[]>([])
  const [dialogVariant, setDialogVariant] = useState<DialogVariant>(
    DialogVariant.SingleButton
  )
  const [inviteSent, setInviteSent] = useState<boolean>(false)
  const resetDialog = () => {
    setDialogVariant(DialogVariant.SingleButton)
    setInviteSent(false)
    setEmails([])
    setEmailsWithError([])
    setLoading(false)
    setIsError(false)
  }

  const handleSendInvites = async () => {
    if (inviteSent) {
      onCloseDialog?.()
      resetDialog()
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (emails) {
      setLoading(true)
      const result = await onSendInvites(emails)
      setLoading(false)

      if (result instanceof DuplicateInviteRequestError) {
        const failedEmails = emails.filter((email) =>
          result.message.includes(email)
        )
        setEmailsWithError(failedEmails)
        setEmails(emails.filter((email) => !failedEmails.includes(email)))
      }

      if (result instanceof InviteRequestGenericError) {
        setIsError(true)
      }
      setInviteSent(true)
      setDialogVariant(DialogVariant.DoubleButton)
    }
  }

  const handleInviteMorePeople = () => {
    resetDialog()
  }

  const handleCloseDialog = () => {
    onCloseDialog?.()
    resetDialog()
  }

  const handleChangeEmails = (newEmails: string[]) => {
    setEmails(newEmails)
  }

  return (
    <Dialog
      title="Invite team members"
      primaryButtonText={inviteSent ? 'Done' : 'Send invite'}
      primaryButtonDisabled={
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        emails?.length === 0 && emailsWithError?.length === 0
      }
      secondaryButtonText="Invite more people"
      secondaryButtonClick={handleInviteMorePeople}
      open={dialogOpen}
      fullWidth
      primaryButtonLoading={loading}
      maxWidth={DialogMaxWidth.Sm}
      primaryButtonClick={handleSendInvites}
      onClose={handleCloseDialog}
      variant={dialogVariant}
    >
      <Grid>
        {inviteSent ? (
          <>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {emails && emails.length > 0 && !isError && (
              <InviteResult
                resultText="Invite successfully sent"
                emails={emails}
              />
            )}
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {((emailsWithError && emailsWithError.length > 0) || isError) && (
              <InviteResult
                resultText="Invite failed"
                emails={emailsWithError}
                // @todo Legacy eslint violation – fix this when editing
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                isError={emailsWithError?.length > 0 || isError}
              />
            )}
          </>
        ) : (
          <>
            <Text
              size={TextSize.m}
              className={classes.subtitleContainer}
              weight={TextWeight.Bold}
            >
              To:
            </Text>
            <Grid>
              <MultiEmaiInput
                placeholder="name@company.com"
                onEmailsChange={handleChangeEmails}
              />
              <Text
                noWrap
                multiline
                size={TextSize.ms}
                highlight={TextHighlight.Emphasis}
                className={classes.infoText}
              >
                Invited team members will be able to view all the projects in
                the account.
              </Text>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

export default InviteTeamDialogControl
