import { Paragraph, TextRun } from 'docx'
import groupBy from 'lodash/groupBy'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry
} from '../../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../../modules/Questionnaire/Questionnaire.slice'
import {
  Operator,
  getDefaultOperator,
  getForkLogicTextData,
  hasQuestionLogic
} from '../questionLogic'
import { createPropositionsTextRuns } from './createPropositionsTextRuns'
import { paragraphTopBorderOptions } from './paragraphTopBorderOptions'

export const createLogicHeader = (
  entry: DraftQuestionnaireEntry,
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): Paragraph | undefined => {
  const defaultOperator = getDefaultOperator(entry)

  const isEntryWithQuestionLogic = hasQuestionLogic(entry)
  const hasForks = entry.forks.length > 0
  let forksTextRuns: TextRun[] = []
  let displayLogicTextRuns: TextRun[] = []
  if (hasForks) {
    forksTextRuns = entry.forks.reduce((textRuns, fork, i, forks) => {
      const forkLogictextData = getForkLogicTextData(fork, entries)
      if (forkLogictextData) {
        textRuns.push(
          new TextRun({
            text: 'IF '
          }),
          new TextRun({
            text: forkLogictextData.forkName,
            bold: true
          }),
          new TextRun({
            text: ' is Group '
          }),
          new TextRun({
            text: forkLogictextData.branchLabel,
            bold: true
          })
        )
        const isLastFork = i === forks.length - 1
        if (!isLastFork) {
          textRuns.push(
            new TextRun({
              text: ' OR '
            })
          )
        } else if (isEntryWithQuestionLogic) {
          textRuns.push(
            new TextRun({
              text: ' AND '
            })
          )
        }
      }

      return textRuns
    }, forksTextRuns)
  }

  if (isEntryWithQuestionLogic) {
    const entryItem = entry.entryItem as DraftQuestionItem | DraftMatrixItem
    if (defaultOperator === Operator.And) {
      displayLogicTextRuns = entryItem.questionLogic.reduce(
        (textRuns, propositions, i) => {
          const isFirstClause = i === 0

          textRuns.push(
            ...createPropositionsTextRuns({
              operator: Operator.And,
              entries,
              propositions,
              isFirstClause,
              responseOptionsByQuestion
            })
          )

          return textRuns
        },
        displayLogicTextRuns
      )
    } else if (defaultOperator === Operator.Or) {
      displayLogicTextRuns = entryItem.questionLogic.flatMap((propositions) => {
        const propositionsByQuestionLk = groupBy(
          propositions,
          'proposition.questionLk'
        )

        return Object.values(propositionsByQuestionLk).flatMap(
          (propositions, i) => {
            const isFirstClause = i === 0
            return createPropositionsTextRuns({
              operator: Operator.Or,
              entries,
              propositions,
              isFirstClause,
              responseOptionsByQuestion
            })
          }
        )
      })
    }
  }

  const paragraphChildren = [...forksTextRuns, ...displayLogicTextRuns]

  return paragraphChildren.length > 0
    ? new Paragraph({
        children: [
          new TextRun({
            text: 'Display this question '
          }),
          ...paragraphChildren
        ],
        ...paragraphTopBorderOptions
      })
    : undefined
}
