import { TextRun } from 'docx'
import {
  DraftLogicClauseProposition,
  DraftQuestionnaireEntry,
  LogicPropositionType
} from '../../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../../modules/Questionnaire/Questionnaire.slice'
import {
  Operator,
  getMatrixLogicTextData,
  getQuestionLogicTextData
} from '../questionLogic'
import { truncateText } from '../questionnaireUtils'

interface CreatePropositionsTextRunsArgs {
  operator: Operator
  entries: DraftQuestionnaireEntry[]
  propositions: DraftLogicClauseProposition[]
  isFirstClause: boolean
  responseOptionsByQuestion: ResponseOptionsByQuestion
}
export const createPropositionsTextRuns = ({
  operator,
  entries,
  propositions,
  isFirstClause,
  responseOptionsByQuestion
}: CreatePropositionsTextRunsArgs): TextRun[] => {
  const { propositionType, negated } = propositions[0]
  const textRuns: TextRun[] = []
  const textDataByPropositionType = new Map([
    [LogicPropositionType.QuestionResponseOptionType, getQuestionLogicTextData],
    [LogicPropositionType.MatrixResponseOptionType, getMatrixLogicTextData]
  ])

  const logicTextData = textDataByPropositionType.get(propositionType)?.(
    propositions,
    entries,
    responseOptionsByQuestion
  )

  if (!logicTextData) {
    return []
  }

  if (!isFirstClause) {
    textRuns.push(
      new TextRun({
        // adds line break before the text
        break: 1,
        text: `${operator.toUpperCase()}`
      }),
      new TextRun({
        break: 1
      })
    )
  }

  textRuns.push(
    new TextRun({
      text: 'IF the answer to '
    }),
    new TextRun({
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      text: `${truncateText(70, logicTextData!.questionText)}`,
      bold: true
    }),
    new TextRun({
      text: negated ? ' is NOT ' : ' is '
    }),
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...logicTextData!.roTexts.flatMap((roText, i, roTexts) => {
      const isLastRo = i === roTexts.length - 1
      const roTextRun = new TextRun({
        text: truncateText(70, roText),
        bold: true
      })
      return isLastRo
        ? roTextRun
        : [
            roTextRun,
            new TextRun({
              text: ' OR ',
              bold: true
            })
          ]
    })
  )
  return textRuns
}
