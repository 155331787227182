import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  infoDuplicate: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3)
  }
})

export default useStyles
