import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { QuestionEventMetadata } from '../../data/amplitude'
import { MoveQuestionnaireEntryV2MutationVariables } from '../../data/gql-gen/questionnaire/graphql'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { useProjectId } from '../../hooks/useProjectId'
import {
  questionNewlyCreatedNumber,
  responseOptionLkNewlyAdded
} from '../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../hooks/useSurveyId'
import { captureApolloError } from '../../utils/HelperFunctions'
import { newEntryId } from '../../utils/questionnaireUtils'
import { MOVE_QUESTIONNAIRE_ENTRY } from './Sidebar/SidebarQuestionnaireItemList/SidebarQuestionnaireItemList.mutations'

interface MoveQuestionEventMetadata extends QuestionEventMetadata {
  sourceContext: string
  destContext: string
  entryId: string
  toPosition: number
}

interface DragAndDropItem {
  path: string
  position: number
}

export type Source = DragAndDropItem
export type Destination = DragAndDropItem

export const useDragAndDrop = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [moveQuestionnaireEntry] = useMutation(MOVE_QUESTIONNAIRE_ENTRY, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'moveQuestionniareEntry',
        error
      )
    }
  })

  const moveCard = useCallback(
    (entryId: string, source: Source, dest: Destination) => {
      if (source.path === dest.path || !entryId) return

      const contextFromPath = (path: string) =>
        path.includes('/') ? 'section' : 'sidebar'
      const sourceContext = contextFromPath(source.path)
      const destContext = contextFromPath(dest.path)

      const variables: MoveQuestionnaireEntryV2MutationVariables = {
        input: {
          projectId,
          surveyId,
          entryId,
          position: dest.position
        }
      }

      const eventMetadata: MoveQuestionEventMetadata = {
        surveyId,
        sourceContext,
        destContext,
        entryId,
        toPosition: dest.position
      }

      const setSectionId = (sectionId?: string | null) => {
        variables.input.sectionId = { value: sectionId }
      }

      const getSectionId = (dragAndDropItem: DragAndDropItem) =>
        dragAndDropItem.path.split('/')[0]

      if (sourceContext === 'sidebar' && destContext === 'section') {
        // move from sidebar to section
        const destSectionId = getSectionId(dest)
        setSectionId(destSectionId)
      } else if (sourceContext === 'section' && destContext === 'sidebar') {
        // move from section to sidebar
        setSectionId(null)
      } else if (sourceContext === 'section' && destContext === 'section') {
        // move within section or to another section
        const sourceSectionId = getSectionId(source)
        const destSectionId = getSectionId(dest)
        // if moving into another section, update the sectionId
        if (sourceSectionId !== destSectionId) {
          setSectionId(destSectionId)
        }
      }

      moveQuestionnaireEntry({ variables })

      questionNewlyCreatedNumber(undefined)
      newEntryId(undefined)
      responseOptionLkNewlyAdded(undefined)

      LogAmplitudeEvent(EventType.MovedCard, eventMetadata)
    },
    [moveQuestionnaireEntry, projectId, surveyId]
  )

  return moveCard
}
