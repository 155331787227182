import {
  LogAmplitudeEvent,
  LogAmplitudePublicEvent,
  SetUserId,
  SetUserProperties
} from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  EntryType,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../model/questionnaire'
import { DataFormat } from '../model/shared'

export interface QuestionEventMetadata {
  surveyId: string
  sectionId?: string
}

export const LogDownloadDataEvent = (
  fileFormat: DataFormat,
  surveyId: string
) => {
  switch (fileFormat) {
    case DataFormat.LONG_ENCODED:
    case DataFormat.LONG_UNENCODED:
      LogAmplitudeEvent(EventType.DownloadedLongData, { surveyId })
      break
    case DataFormat.WIDE_ENCODED:
    case DataFormat.WIDE_UNENCODED:
      LogAmplitudeEvent(EventType.DownloadedWideData, { surveyId })
      break
    case DataFormat.SPSS:
      LogAmplitudeEvent(EventType.DownloadedSPSS, { surveyId })
      break
    default:
      break
  }
}

export const LogCreateQuestionEvent = (
  surveyId: string,
  questionTypeCode: QuestionTypeCode,
  settings:
    | {
        questionSettingCode: QuestionSettingCode
        settingValue: SettingValue
      }
    | undefined,
  sectionId?: string
) => {
  const questionEventMetadata: QuestionEventMetadata = {
    surveyId
  }

  if (sectionId) {
    questionEventMetadata.sectionId = sectionId
  }

  switch (questionTypeCode) {
    case QuestionTypeCode.Basic:
      if (
        settings &&
        settings.questionSettingCode === QuestionSettingCode.BasicChoice &&
        settings.settingValue === SettingValue.SingleChoice
      )
        LogAmplitudeEvent(
          EventType.AddedSingleSelectQuestion,
          questionEventMetadata
        )
      else if (
        settings &&
        settings.questionSettingCode === QuestionSettingCode.BasicChoice &&
        settings.settingValue === SettingValue.MultipleChoice
      )
        LogAmplitudeEvent(
          EventType.AddedMultiSelectQuestion,
          questionEventMetadata
        )
      break
    case QuestionTypeCode.FreeText:
      LogAmplitudeEvent(EventType.AddedTextInputQuestion, questionEventMetadata)
      break
    case QuestionTypeCode.Ranked:
      LogAmplitudeEvent(EventType.AddedRankedQuestion, questionEventMetadata)
      break
    case QuestionTypeCode.TextCard:
      LogAmplitudeEvent(EventType.AddedTextInstruction, questionEventMetadata)
      break
    case QuestionTypeCode.Scale:
      LogAmplitudeEvent(EventType.AddedSliderQuestion, questionEventMetadata)
      break
    case QuestionTypeCode.Matrix:
      LogAmplitudeEvent(EventType.AddedMatrixQuestion, questionEventMetadata)
      break
    case QuestionTypeCode.MaxDiff:
      LogAmplitudeEvent(EventType.AddedMaxDiffQuestion, questionEventMetadata)
      break
    default:
      break
  }
}

export const LogCreateEntryTypeEvent = (
  surveyId: string,
  entryType: EntryType
) => {
  switch (entryType) {
    case EntryType.ForkEntryType:
      LogAmplitudeEvent(EventType.AddedABLogicFork, { surveyId })
      break
    case EntryType.TextCardEntryType:
      LogAmplitudeEvent(EventType.AddedTextInstruction, { surveyId })
      break
    case EntryType.SectionEntryType:
      LogAmplitudeEvent(EventType.AddedSection, { surveyId })
      break
    default:
      break
  }
}

export const LogSignedUpEvent = (
  email: string,
  organisation: string,
  data?: any
) => {
  SetUserId(email)
  SetUserProperties('', organisation)
  LogAmplitudePublicEvent(EventType.SignedUp, data)
}
