import {
  Dialog,
  Text,
  TextHighlight,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'

interface Props {
  isOpen: boolean
  isLive: boolean
  toggleOpenDialog: (open: boolean) => void
  onPauseProject: () => void
}

const getMainText: (isLive: boolean) => string = (isLive) =>
  isLive ? 'Pause project' : 'Unpause project'
const getGeneralExplanation: (isLive: boolean) => string = (isLive) =>
  isLive
    ? 'This will pause the project and all ongoing data collection.'
    : 'This will unpause the project. Data collection will continue from where it was paused.'

const getAdditionalExplanation: (isLive: boolean) => string = (isLive) =>
  isLive
    ? 'If you leave the project paused for longer than 10 business days, then the project will automatically end.'
    : 'You can pause the project again at any point.'

const DialogPauseProject: React.FC<Props> = (props: Props) => {
  const { isOpen, isLive, toggleOpenDialog, onPauseProject }: Props = props
  return (
    <Dialog
      open={isOpen}
      onClickOutside={() => toggleOpenDialog(false)}
      onClose={() => toggleOpenDialog(false)}
      title={getMainText(isLive)}
      primaryButtonText={getMainText(isLive)}
      primaryButtonClick={onPauseProject}
    >
      <Text weight={TextWeight.SemiBold} gutterBottom>
        {getGeneralExplanation(isLive)}
      </Text>
      <Text highlight={TextHighlight.Background}>
        {getAdditionalExplanation(isLive)}
      </Text>
    </Dialog>
  )
}

export default DialogPauseProject
