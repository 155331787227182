import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const IMPORT_QUESTIONNAIRE_ENTRIES = questionnaireGql(/* GraphQL */ `
  mutation importQuestionnaireEntriesV2(
    $input: ImportQuestionnaireEntriesV2Input!
  ) {
    importQuestionnaireEntriesV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)
