import { makeVar } from '@apollo/client'
import { useContext } from 'react'
import { EntryType } from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import QuestionnaireContext from '../Questionnaire.context'
import { flattenEntries } from '../Questionnaire.utils'
import { getTitleStyling } from './PipingDialog.utils'

export const pipingMarkerAddedVar = makeVar<Record<string, boolean>>({})

export const useUpdateTitleWithPipingMarker = () => {
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const { questionnaireState } = useContext(QuestionnaireContext)
  const { currentPipingTargetNumber } = questionnaireState
  const entries = flattenEntries(draftQuestionnaire?.entries ?? [])

  const currentPipingTargetIndex = entries.findIndex(
    (entry) => entry.number === currentPipingTargetNumber
  )
  const currentEntry = entries[currentPipingTargetIndex] ?? {}
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(
    currentEntry.id
  )

  return async (
    marker: `{A${number}}` | `{Q${number}}` | `{S${number}}`
  ): Promise<void> => {
    if (!draftQuestionnaire || entries.length === 0) {
      return
    }

    if (currentEntry.entryType === EntryType.QuestionEntryType) {
      const { question, questionLk } = currentEntry.entryItem
      const text = `${question?.text}${marker}`
      const textStyling = getTitleStyling(marker, question?.textStyling)
      await updateQuestionnaireEntry({
        updateBasicQuestion: {
          text,
          textStyling
        }
      })

      pipingMarkerAddedVar({ [questionLk]: true })
    }

    if (currentEntry.entryType === EntryType.TextCardEntryType) {
      const { textCard, textCardLk } = currentEntry.entryItem
      const title = `${textCard.title}${marker}`
      const titleStyling = getTitleStyling(marker, textCard.titleStyling)
      await updateQuestionnaireEntry({
        updateTextCard: {
          title,
          titleStyling,
          subtitle: '',
          body: textCard.body
        }
      })

      pipingMarkerAddedVar({ [textCardLk]: true })
    }

    if (currentEntry.entryType === EntryType.MatrixEntryType) {
      const { matrixTitle, matrixTitleLk } = currentEntry.entryItem
      const title = `${matrixTitle?.title}${marker}`
      const titleStyling = getTitleStyling(marker, matrixTitle?.titleStyling)
      await updateQuestionnaireEntry({
        updateMatrixQuestion: {
          title,
          titleStyling
        }
      })

      pipingMarkerAddedVar({ [matrixTitleLk]: true })
    }
  }
}
