import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  addButton: {
    marginTop: theme.spacing(0.75)
  },
  audienceCard: {
    '&.MuiGrid-item': {
      margin: theme.spacing(4)
    }
  },
  root: {
    paddingBottom: theme.spacing(4)
  },
  titleContainer: {
    display: 'inherit'
  }
})

export default useStyles
