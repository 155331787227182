import {
  Button,
  ButtonVariant,
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import React, { useContext } from 'react'
import Subheader from '../../../components/Subheader'
import { SurveyStatsContainer } from '../../Survey/SurveyStats'
import AudienceContext from '../Audience.context'
import { AudienceCountContainer } from '../AudienceCount'
import { AudienceCriteriaConfigurator } from '../AudienceCriteriaConfigurator'
import { AudienceSearchContainer } from '../AudienceSearch'
import { CompletesInputContainer } from '../CompletesInput'

export interface Props {
  isOpen: boolean
  toggleIsOpen: () => void
  showButton?: boolean
}

const CustomizeAudienceCriteria: React.FC<Props> = (props: Props) => {
  const { isOpen, toggleIsOpen, showButton = false }: Props = props
  const { audienceState } = useContext(AudienceContext)

  return (
    <>
      {showButton && (
        <Tooltip
          title="Weighted audience specifications cannot be altered. Please choose an unweighted one or `Custom` from the dropdown on the main audience page."
          tipPlacement={TipPlacement.Top}
          disabled={!audienceState.isWeighted}
        >
          <Button
            variant={ButtonVariant.PrimaryOutlined}
            onClick={toggleIsOpen}
            disabled={audienceState.isWeighted}
          >
            Edit targeting and screening
          </Button>
        </Tooltip>
      )}
      <Dialog
        variant={DialogVariant.SingleButtonStatus}
        statusComponent={<AudienceCountContainer />}
        title="Edit targeting and screening"
        fullScreen
        open={isOpen}
        primaryButtonText="Save audience"
        primaryButtonClick={toggleIsOpen}
        onClose={toggleIsOpen}
        maxWidth={DialogMaxWidth.Lg}
        onClickOutside={toggleIsOpen}
      >
        <Subheader
          leftRightPadding={false}
          leftChild={
            <Grid container style={{ maxWidth: '600px' }}>
              <AudienceSearchContainer />
              <CompletesInputContainer />
            </Grid>
          }
          rightChild={<SurveyStatsContainer />}
        />
        <AudienceCriteriaConfigurator />
      </Dialog>
    </>
  )
}

export default CustomizeAudienceCriteria
