import { SurveyOrder } from '../../data/model/order'

export interface SoftLaunch {
  samplesNeeded: number
}

export enum SoftLaunchState {
  Active = 'active',
  Disabled = 'disabled',
  Completed = 'completed'
}

export interface SoftLaunchSurveyOrder extends SurveyOrder {
  isSoftLaunch: boolean
}
