import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  Text,
  TextWeight
} from '@focaldata/cin-ui-components'
import { useEffect } from 'react'
import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import { useSurveyId } from '../../../../../hooks/useSurveyId'

import BarChartImg from '../../../../../assets/fdchat/bar-chart.svg'

const RequestFindingsDialog = ({
  open,
  onClose
}: {
  open: boolean
  onClose: () => void
}) => {
  const surveyId = useSurveyId()

  useEffect(() => {
    if (open) {
      LogAmplitudeEvent(EventType.OpenedRequestFdChatFindingsDialog, {
        surveyId
      })
    }
  }, [open, surveyId])

  return (
    <Dialog
      title={'In-depth findings report available for £10,000'}
      open={open}
      transparentBackground={true}
      variant={DialogVariant.SingleButton}
      onClose={onClose}
      onClickOutside={onClose}
      maxWidth={DialogMaxWidth.Lg}
      className="findings-available-dialog"
      primaryButtonText="See sample report"
      primaryButtonClick={() => {
        LogAmplitudeEvent(EventType.ClickedFdChatSeeSampleReport, {
          surveyId
        })
        window.open('/fdchat/sample-findings-report', '_blank')
      }}
    >
      <Grid container spacing={'40px'} alignItems={'center'}>
        <Grid item xs={12} md={4}>
          <img
            src={BarChartImg}
            alt=""
            style={{ maxWidth: '100%', height: '210px', objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Text>
            Get access to a report of the main insights with the ability to dive
            deep into each research question:
          </Text>
          <ul>
            <li>
              <Text>Summary answer to your research goal</Text>
            </li>
            <li>
              <Text>Direct answers to your research questions</Text>
            </li>
            <li>
              <Text>Insights quantified for your research questions</Text>
            </li>
            <li>
              <Text>
                Quant statistics and chart illustrating the main finding for
                each research question
              </Text>
            </li>
            <li>
              <Text>Summaries of the main themes with illustrative quotes</Text>
            </li>
            <li>
              <Text weight={TextWeight.SemiBold}>
                All insights reviewed by a Focaldata expert researcher
              </Text>
            </li>
          </ul>
          <Text>
            Email{' '}
            <a href="mailto:justin@focaldata.com" style={{ color: 'black' }}>
              justin@focaldata.com
            </a>{' '}
            to request your report.
          </Text>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default RequestFindingsDialog
