/**
 * Represents a Least Recently Used (LRU) Cache.
 */
export default class LRUCache {
  private capacity: number
  private cache: Map<any, any>
  private keys: Set<any>

  /**
   * Creates a new LRUCache instance with the specified capacity.
   * @param capacity The maximum number of items the cache can hold.
   */
  constructor(capacity: number) {
    this.capacity = capacity
    this.cache = new Map()
    this.keys = new Set()
  }

  /**
   * Retrieves the value associated with the specified key from the cache.
   * If the key is not found, returns null.
   * @param key The key to retrieve the value for.
   * @returns The value associated with the key, or null if not found.
   */
  get(key: any): any {
    if (!this.cache.has(key)) {
      return null
    }

    // Remove and add the key to update its position
    this.keys.delete(key)
    this.keys.add(key)

    return this.cache.get(key)
  }

  /**
   * Adds or updates the value associated with the specified key in the cache.
   * If the cache is full, removes the least recently used item before adding the new item.
   * @param key The key to add or update the value for.
   * @param value The value to associate with the key.
   */
  put(key: any, value: any): void {
    if (this.cache.size >= this.capacity) {
      // Remove the least recently used key
      const firstKey = this.keys.values().next().value
      this.keys.delete(firstKey)
      this.cache.delete(firstKey)
    }

    this.cache.set(key, value)
    this.keys.add(key)
  }
}
