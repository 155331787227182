import { textStyleUtils } from '@focaldata/cin-ui-components'
import useStyles from './StagingInfoBanner.styles'

interface Props {
  onClick: () => void
}

const StagingInfoButton = ({ onClick }: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()

  return (
    <button
      type="button"
      className={classNames(
        textClasses.default,
        textClasses.highlightInherit,
        classes.infoButton
      )}
      onClick={onClick}
    >
      Staging
    </button>
  )
}

export default StagingInfoButton
