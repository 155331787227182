import {
  Button,
  ButtonVariant,
  Grid,
  Input,
  InputVariant,
  Paper,
  SpinnerButton,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { Divider } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SurveyLaunchedPage from '../../../../controls/SurveyLaunchedPage'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { useFdChatFieldwork, useInterviewLink } from '../FdChat.hooks'

const FDChatLaunch = () => {
  const surveyId = useSurveyId()
  const navigate = useNavigate()
  const [linkCopyState, setLinkCopyState] = useState<
    'initial' | 'copying' | 'copied'
  >('initial')
  const { isCustomAudience } = useFdChatFieldwork()
  const link = useInterviewLink()

  const copyLink = async () => {
    setLinkCopyState('copying')
    await navigator.clipboard.writeText(link)
    setLinkCopyState('copied')
  }

  if (!isCustomAudience)
    return (
      <SurveyLaunchedPage
        onButtonClick={() => navigate(`/fdchat/${surveyId}`)}
      />
    )

  return (
    <Grid container padding={'24px 32px'} className="fd-chat-launch">
      <Grid item component={Paper} xs={12} lg={6}>
        <div style={{ padding: '24px' }}>
          <Text size={TextSize.lxl} weight={TextWeight.Bold}>
            Project Launched Successfully
          </Text>
        </div>
        <Divider />
        <div style={{ padding: '24px' }}>
          <Text>
            You can now share your interview link with your audience and start
            collecting data.
          </Text>
          <div style={{ marginTop: '16px' }}>
            <Text weight={TextWeight.SemiBold}>
              ⚠️ Please ensure you copy the link exactly as it is below or use
              the &apos;Copy Interview Link&apos; button to add it to your
              clipboard. ⚠️
            </Text>
          </div>
          <div style={{ margin: '20px 0px' }}>
            <Text>Interview link:</Text>
            <Input
              value={link}
              variant={InputVariant.Filled}
              fullWidth
              disabled
              style={{ marginTop: '5px' }}
            />
          </div>
          <Grid container gap={'10px'}>
            <Button
              variant={ButtonVariant.PrimaryOutlined}
              onClick={() => navigate(`/fdchat/${surveyId}`)}
            >
              Go to project page
            </Button>
            <SpinnerButton
              onClick={copyLink}
              loading={linkCopyState === 'copying'}
            >
              {linkCopyState === 'initial'
                ? 'Copy Interview Link'
                : 'Interview Link Copied'}
            </SpinnerButton>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default FDChatLaunch
