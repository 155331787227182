import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  root: {
    paddingTop: theme.spacing(1)
  },
  addButton: {
    paddingLeft: theme.spacing(0.25)
  },
  addScale: {
    paddingLeft: theme.spacing(3),
    '& p': {
      width: 'auto'
    }
  },
  errorText: {
    marginTop: theme.spacing(0.4),
    color: theme.palette.error.main,
    maxWidth: theme.spacing(42)
  }
})

export default useStyles
