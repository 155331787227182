import {
  Badge,
  Grid,
  Status,
  Text,
  TextWeight,
  TipPlacement,
  Tooltip,
  theme
} from '@focaldata/cin-ui-components'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import {
  checkIsConfirmationPage,
  checkIsFDChatResultsPage,
  checkIsResultsPage
} from '../../../utils/HelperFunctions'
import {
  isMultiMarketProjectType,
  projectTypeMap,
  statusMap,
  stringStatusMap
} from '../../../utils/projectUtils'
import EditProjectName from './EditProjectName'
import useStyles from './ProjectNameAndStatus.styles'

interface Props {
  projectName: string | undefined
  projectStatus: string
  projectType: ProjectType
  noTooltip?: boolean
  onClickProjectName?: Dispatch<SetStateAction<boolean>>
}

const ProjectTitleAndStatus: React.FC<Props> = (props: Props) => {
  const {
    projectName,
    projectStatus,
    projectType,
    noTooltip = false,
    onClickProjectName
  } = props

  const isConfirmationPage = checkIsConfirmationPage()
  const isResultsPage = checkIsResultsPage()
  const isMultiMarket = isMultiMarketProjectType(projectType)
  const isFDChatResultsPage = checkIsFDChatResultsPage()

  const { classes } = useStyles({
    styleProps: { isConfirmationPage, isResultsPage, isFDChatResultsPage }
  })

  const badgeStatus =
    (isMultiMarket ? Status.LIVE : statusMap.get(projectStatus)) ?? Status.DRAFT

  const badgeLabel =
    (isMultiMarket
      ? projectTypeMap.get(projectType)
      : stringStatusMap.get(badgeStatus)) || 'Unknown'

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.rootFullWidth}
    >
      <Grid
        item
        className={classes.projectName}
        onClick={() => onClickProjectName && onClickProjectName(true)}
      >
        {noTooltip ? (
          <Text weight={TextWeight.SemiBold} noWrap dataCy="project-title">
            {projectName}
          </Text>
        ) : (
          <Tooltip
            title="Edit project title"
            tipPlacement={TipPlacement.TopStart}
          >
            <Text
              className={classes.projectNameText}
              weight={TextWeight.SemiBold}
              noWrap
              dataCy="project-title"
            >
              {projectName}
            </Text>
          </Tooltip>
        )}
      </Grid>
      <Badge
        label={badgeLabel}
        status={badgeStatus}
        style={{ marginRight: theme.spacing(1) }}
      />
    </Grid>
  )
}

export const ProjectNameAndStatusEditable = ({
  name,
  status,
  type,
  projectId
}: {
  name: string
  status: string
  type: ProjectType
  projectId?: string
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <ProjectTitleAndStatus
        projectName={name}
        projectStatus={status}
        projectType={type}
        onClickProjectName={setIsDialogOpen}
      />
      {isDialogOpen && (
        <EditProjectName
          selectedProjectId={projectId}
          selectedProjectName={name}
          setIsDialogOpen={setIsDialogOpen}
          isDialogOpen={isDialogOpen}
        />
      )}
    </>
  )
}

export default ProjectTitleAndStatus
