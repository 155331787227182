import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  body: {
    padding: theme.spacing(0, 4, 2, 2)
  },
  title: {
    paddingTop: theme.spacing(1)
  },
  icon: {
    padding: theme.spacing(1.5, 1, 1.5, 1.5)
  }
})

export default useStyles
