import { makeVar } from '@apollo/client'
import { ResponseOption } from '@focaldata/cin-ui-components'
import isEqual from 'lodash/isEqual'
import uuid from 'uuid-random'
import { questionBeingEditedId } from '../apollo/apolloClient'
import {
  DraftEntryResponseOption,
  DraftSectionItem,
  MatrixRowQualifyingOperator
} from '../data/gql-gen/questionnaire/graphql'
import {
  DraftForkItem,
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaire,
  DraftQuestionnaireEntry,
  DraftTextCardItem,
  EntryPrefix,
  EntrySettingValue,
  EntryType,
  Fork,
  ForkBranch,
  QuestionKind,
  QuestionSettingCode,
  QuestionTypeCode,
  QuestionValidationErrors,
  SettingValue,
  TextEntryState
} from '../data/model/questionnaire'
import { proportionToPercent } from './HelperFunctions'
import { sumBy } from './array/sumBy'
import { countNumberQuestionsByType } from './countNumberQuestionsByType'
import { QuestionPrefix } from './surveyDocBuilder/model'

export type WithEntryOfType<T extends keyof EntryTypes> = {
  entry: DraftQuestionnaireEntry<T>
}

export interface WithEntry {
  entry: DraftQuestionnaireEntry
}

export const isLogicEqual: (
  entry1: DraftQuestionnaireEntry,
  entry2: DraftQuestionnaireEntry
) => boolean = (entry1, entry2) => {
  if (
    entry1.entryType === EntryType.QuestionEntryType &&
    entry2.entryType === EntryType.QuestionEntryType
  ) {
    const entryItem1 = entry1.entryItem
    const entryItem2 = entry2.entryItem
    return isEqual(entryItem1.questionLogic, entryItem2.questionLogic)
  }
  if (
    entry1.entryType === EntryType.MatrixEntryType &&
    entry2.entryType === EntryType.MatrixEntryType
  ) {
    const entryItem1 = entry1.entryItem
    const entryItem2 = entry2.entryItem
    return isEqual(entryItem1.questionLogic, entryItem2.questionLogic)
  }
  if (
    entry1.entryType === EntryType.TextCardEntryType &&
    entry2.entryType === EntryType.TextCardEntryType
  ) {
    return true
  }
  if (
    entry1.entryType === EntryType.ForkEntryType &&
    entry2.entryType === EntryType.ForkEntryType
  ) {
    return true
  }
  return false
}

export const propsAreEqual: (
  prevProps: WithEntry,
  nextProps: WithEntry
) => boolean = (prevProps, nextProps) => {
  if (
    questionBeingEditedId() !== undefined &&
    !isLogicEqual(prevProps.entry, nextProps.entry) &&
    nextProps.entry.id !== questionBeingEditedId()
  ) {
    return true
  }
  return false
}

export const newEntryId = makeVar<string | undefined>(undefined)

export const getRoutingBadgeDisplayString: (
  responseOption: DraftEntryResponseOption,
  entries: DraftQuestionnaireEntry[] | undefined,
  routeToEndSurveyNumber: number
) => string | undefined = (responseOption, entries, routeToEndSurveyNumber) => {
  const routingTargetNumber = responseOption.route
    ? responseOption.route.targetNumber
    : undefined

  const routingTargetEntry = entries?.find(
    (entry) => entry.number === routingTargetNumber
  )

  const routingBadgeDisplayNumber =
    routingTargetEntry?.contextPosition !== undefined
      ? // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        routingTargetEntry?.contextPosition + 1
      : undefined

  const isTargetNumberToEndSurvey =
    routingTargetNumber === routeToEndSurveyNumber

  const prefix =
    routingTargetEntry?.entryType === EntryType.TextCardEntryType ? 'T' : 'Q'

  const routingBadgeDisplayString = isTargetNumberToEndSurvey
    ? 'End'
    : `${prefix}${routingBadgeDisplayNumber}`

  return routingBadgeDisplayNumber || isTargetNumberToEndSurvey
    ? routingBadgeDisplayString
    : undefined
}

export const getQuestionEnabledSettings = (
  settingValues?: EntrySettingValue[]
): Set<QuestionSettingCode> => {
  const enabledSettings = new Set() as Set<QuestionSettingCode>

  if (!settingValues) {
    return enabledSettings
  }

  return settingValues.reduce((set, { code, value }) => {
    if (value === SettingValue.Enabled) {
      set.add(code)
    }
    return set
  }, enabledSettings)
}

export const isSettingEnabled: (
  settingValues: EntrySettingValue[] | undefined,
  code: QuestionSettingCode
) => boolean = (settingValues, code) => {
  const enabledSettings = getQuestionEnabledSettings(settingValues || [])

  return enabledSettings.has(code)
}

export const getQuestionSettings = (
  settingValues: EntrySettingValue[]
): Map<QuestionSettingCode, SettingValue> => {
  return settingValues.reduce((map, { code, value }) => {
    map.set(code, value)
    return map
  }, new Map())
}

export const checkIfSingleChoice: (entryItem: DraftQuestionItem) => boolean = (
  entryItem
) => {
  return (
    entryItem.settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.BasicChoice
    )?.value === SettingValue.SingleChoice
  )
}

export const checkIfMultipleChoice: (
  settingValues: EntrySettingValue[]
) => boolean = (settingValues) => {
  return (
    settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.BasicChoice
    )?.value === SettingValue.MultipleChoice
  )
}

export const checkIfMatrixMultipleChoice: (
  settingValues: EntrySettingValue[]
) => boolean = (settingValues) => {
  return (
    settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.MatrixChoice
    )?.value === SettingValue.MultipleChoice
  )
}

export const checkIfRankedChoice: (entryItem: DraftQuestionItem) => boolean = (
  entryItem
) => {
  return entryItem.questionTypeCode === QuestionTypeCode.Ranked
}

export const checkIfFreeText: (entryItem: DraftQuestionItem) => boolean = (
  entryItem
) => {
  return entryItem.questionTypeCode === QuestionTypeCode.FreeText
}

export const checkIfScale: (entryItem: DraftQuestionItem) => boolean = (
  entryItem
) => {
  return entryItem.questionTypeCode === QuestionTypeCode.Scale
}

export const getChoiceLimit: (
  entryItem: DraftQuestionItem | DraftMatrixItem
) => SettingValue | undefined = (entryItem) => {
  return getQuestionSettings(entryItem.settingValues).get(
    QuestionSettingCode.ChoiceLimit
  )
}

export const getChoiceIntervalMin: (
  entryItem: DraftQuestionItem | DraftMatrixItem
) => number | undefined = (entryItem) => {
  const choiceIntervalMinValue = getQuestionSettings(
    entryItem.settingValues
  ).get(QuestionSettingCode.ChoiceIntervalMin)

  return choiceIntervalMinValue && parseInt(choiceIntervalMinValue, 10)
}

export const getChoiceIntervalMax: (
  entryItem: DraftQuestionItem | DraftMatrixItem
) => number | undefined = (entryItem) => {
  const choiceIntervalMaxValue = getQuestionSettings(
    entryItem.settingValues
  ).get(QuestionSettingCode.ChoiceIntervalMax)
  return choiceIntervalMaxValue && parseInt(choiceIntervalMaxValue, 10)
}

export const getRankChoiceRequisite: (
  entryItem: DraftQuestionItem | DraftMatrixItem
) => SettingValue | undefined = (entryItem) => {
  return getQuestionSettings(entryItem.settingValues).get(
    QuestionSettingCode.RankChoiceRequisite
  )
}

export const getForkCount: (
  entries: DraftQuestionnaireEntry[] | undefined
) => number = (entries) => {
  return entries
    ? entries.filter((entry) => entry.entryType === EntryType.ForkEntryType)
        .length + 1
    : 1
}

export const getFreeTextMinCharacters: (
  settingValues: EntrySettingValue[]
) => SettingValue | undefined = (settingValues) => {
  return getQuestionSettings(settingValues).get(
    QuestionSettingCode.CharacterMinimum
  )
}

export const getFreeTextMaxCharacters: (
  settingValues: EntrySettingValue[]
) => SettingValue | undefined = (settingValues) => {
  return getQuestionSettings(settingValues).get(
    QuestionSettingCode.CharacterLimit
  )
}

export const isLastEntry = (
  entries: DraftQuestionnaireEntry[] | undefined,
  entryId: string
) => {
  if (entries) {
    const lastEntryIndex = entries.length - 1
    const isLastEntry = entries[lastEntryIndex]?.id === entryId
    return isLastEntry
  }
  return false
}

// TODO: A Free Text Card gets a validation error due to a backend problem
// It shows the error: ["ResponseOptionCountLessThan2Error"]
// This is a momentaneous fix to avoid the error to disable the "To confirmation" BottomNav button
export const checkIsQuestionnaireValid: (
  draftQuestionnaire: DraftQuestionnaire | undefined
) => boolean = (draftQuestionnaire) => {
  const questionnaireValidationErrors =
    draftQuestionnaire?.validationErrors as QuestionValidationErrors[]

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const countQuestionnaireErrors = questionnaireValidationErrors?.flatMap(
    (validationError) => validationError.errors
  ).length

  const questionnaireEntries = draftQuestionnaire?.entries

  const countNumberFreeText = countNumberQuestionsByType(
    questionnaireEntries,
    QuestionTypeCode.FreeText
  )

  const countNumberScaleQuestions = countNumberQuestionsByType(
    questionnaireEntries,
    QuestionTypeCode.Scale
  )

  const areThereErrors =
    countQuestionnaireErrors - countNumberFreeText - countNumberScaleQuestions >
    0

  return !areThereErrors
}

enum RoType {
  RoDouble,
  RoInt,
  RoLink,
  RoString
}

const getDefaultResponseOption = (
  position: number,
  value: string
): ResponseOption => {
  return {
    position,
    option: {
      responseOptionId: uuid(),
      value,
      isQualifying: false,
      isDefaultOption: true,
      isExclusiveOption: false,
      isTextEntry: false,
      roType: RoType.RoString
    },
    renderedPosition: position
  }
}

export const getResponseOptions = (
  entryItem: DraftQuestionItem | DraftMatrixItem,
  entryResponseOptions: DraftEntryResponseOption[],
  options?: { showInitialValues: boolean }
): ResponseOption[] => {
  const addPinnedOrTextEntryPostfixIfNeeded = (
    draftResponseOption: DraftEntryResponseOption
  ): string => {
    const isRandomisedOptionsEnabled = isSettingEnabled(
      entryItem.settingValues,
      QuestionSettingCode.RandomiseOptions
    )
    const isTextEntryEnabled =
      draftResponseOption.textEntryState === TextEntryState.TextEntryEnabled
    let { value = '' } = draftResponseOption.responseOption ?? {}

    if (isTextEntryEnabled) {
      value = `${value} [text entry enabled]`
    }

    if (draftResponseOption.pinned && isRandomisedOptionsEnabled) {
      value = `${value} [pinned]`
    }

    if (draftResponseOption.exclusive) {
      value = `${value} [exclusive]`
    }

    return value
  }

  const shouldShowInitialValues = options && options.showInitialValues

  const responseOptions = entryResponseOptions.map((draftResponseOption) => {
    return {
      position: draftResponseOption.position,
      option: {
        responseOptionId: draftResponseOption.responseOptionLk,
        value: shouldShowInitialValues
          ? draftResponseOption.responseOption?.value ?? ''
          : addPinnedOrTextEntryPostfixIfNeeded(draftResponseOption),
        isQualifying: false,
        isTextEntry:
          draftResponseOption.textEntryState ===
          TextEntryState.TextEntryEnabled,
        isDefaultOption: false,
        isExclusiveOption: draftResponseOption.exclusive ?? false,
        roType: RoType.RoString
      },
      renderedPosition: draftResponseOption.position,
      route: draftResponseOption.route ?? undefined
    }
  })

  const responseValueBySettingCode = {
    [QuestionSettingCode.DontKnow]: "Don't know",
    [QuestionSettingCode.PreferNotToSay]: 'Prefer not to say',
    [QuestionSettingCode.NoneOfThese]: 'None of these'
  }

  const enabledSettings = getQuestionEnabledSettings(entryItem.settingValues)

  const addExclusivePostfixIfNeeded = (value: string): string => {
    if (
      !shouldShowInitialValues &&
      checkIfMultipleChoice((entryItem as DraftQuestionItem).settingValues)
    ) {
      return `${value} [exclusive]`
    }
    return value
  }

  const defaultResponseOptions = Object.entries(
    responseValueBySettingCode
  ).reduce((acc, [settingCode, responseValue]) => {
    if (enabledSettings.has(settingCode as QuestionSettingCode)) {
      acc.push(
        getDefaultResponseOption(
          responseOptions.length + acc.length,
          addExclusivePostfixIfNeeded(responseValue)
        )
      )
    }
    return acc
  }, [] as ResponseOption[])

  return [...responseOptions, ...defaultResponseOptions]
}

export const getTotalForkPercentage = (fork: Fork) => {
  return proportionToPercent(sumBy(fork.branches, (b) => b.percentage))
}

interface BranchBatchUpdateData {
  branchNumber: number
  percentage: number
}

export const getBranchBatchUpdateData = (
  branches: ForkBranch[]
): BranchBatchUpdateData[] => {
  const branchesCount = branches.length
  // makes rounded decimal with 3 digits after dot, e.g. 0.333, 0.167
  const percentagePerBranch = Math.round((1 / branchesCount) * 1000) / 1000
  let percentageCorrection = 0

  // handles situation when we have not 100% sum, e.g. instead of 0.333, 0.333, 0.333 (that is 0.999 instead of 1) it will make 0.333, 0.333, 0.334
  if (percentagePerBranch * branchesCount !== 1) {
    percentageCorrection =
      (1000 - Math.round(branchesCount * percentagePerBranch * 1000)) / 1000
  }

  return branches.map(({ branchNumber }, i) => {
    const isLastBranch = i === branches.length - 1
    return {
      branchNumber,
      percentage: isLastBranch
        ? percentagePerBranch + percentageCorrection
        : percentagePerBranch
    }
  })
}

export enum ResponseOrderingOptions {
  Regular = 'Regular',
  Random = 'Random',
  FlipOrder = 'FlipOrder'
}

export const getOrdering: (
  isRandom: boolean,
  isFlipped?: boolean
) => ResponseOrderingOptions = (isRandom, isFlipped) => {
  if (isRandom) {
    return ResponseOrderingOptions.Random
  }
  if (isFlipped) {
    return ResponseOrderingOptions.FlipOrder
  }

  return ResponseOrderingOptions.Regular
}

export const getQuestionType = (
  entry: DraftQuestionnaireEntry
): QuestionTypeCode => {
  switch (entry.entryType) {
    case EntryType.QuestionEntryType: {
      const entryItem = entry.entryItem as DraftQuestionItem
      const { questionTypeCode } = entryItem
      return questionTypeCode
    }
    case EntryType.TextCardEntryType:
      return QuestionTypeCode.TextCard
    case EntryType.MatrixEntryType:
      return QuestionTypeCode.Matrix
    case EntryType.ForkEntryType:
      return QuestionTypeCode.Fork
    case EntryType.SectionEntryType:
      return QuestionTypeCode.Section
    default:
      return QuestionTypeCode.Basic
  }
}

export const truncateText = (
  lengthOfText: number,
  responseOptionText: string | undefined = '',
  truncateMiddle: boolean | undefined = true
) => {
  if (responseOptionText.length <= lengthOfText) {
    return responseOptionText
  }

  if (truncateMiddle) {
    const half = Math.floor(lengthOfText / 2)
    const newText = `${responseOptionText.substring(
      0,
      half - 1
    )}...${responseOptionText.substring(responseOptionText.length - half)}`
    return newText
  }

  return `${responseOptionText.substring(0, lengthOfText - 1)}…`
}

export const isAudienceKind = (entry: DraftQuestionnaireEntry): boolean => {
  return entry.questionKind === QuestionKind.AudienceKind
}

export const getContextPositionPlusOne = (contextPosition: number): number => {
  return contextPosition + 1
}

export const getQuestionEntryPrefix = (
  entry: DraftQuestionnaireEntry
): QuestionPrefix => {
  const contextPositionPlusOne = getContextPositionPlusOne(
    entry.contextPosition
  )

  if (isAudienceKind(entry)) {
    return `${EntryPrefix.AudienceKind}${contextPositionPlusOne}` as const
  }

  let entryPrefixLetter: EntryPrefix

  switch (entry.entryType) {
    case EntryType.TextCardEntryType:
      entryPrefixLetter = EntryPrefix.TextCardEntryType
      break
    case EntryType.SectionEntryType:
      entryPrefixLetter = EntryPrefix.SectionEntryType
      break
    case EntryType.QuestionEntryType:
    default:
      entryPrefixLetter = EntryPrefix.QuestionnaireKind
  }

  return `${entryPrefixLetter}${contextPositionPlusOne}` as const
}

export const getLetterPrefix: (entryType: EntryType) => string = (
  entryType
) => {
  switch (entryType) {
    case EntryType.TextCardEntryType:
      return 'T'
    case EntryType.ForkEntryType:
      return 'A/B'
    case EntryType.SectionEntryType:
      return 'S'
    default:
      return 'Q'
  }
}

export const getQuestionTimerLabel = (
  entry: DraftQuestionnaireEntry
): string => {
  const { settingValues } = entry.entryItem as DraftQuestionItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (settingValues) {
    const timerSettingValue = getQuestionSettings(settingValues).get(
      QuestionSettingCode.QuestionTimer
    )
    return timerSettingValue ? `[timer: ${timerSettingValue} seconds]` : ''
  }

  return ''
}

export const defaultMatrixQualification = {
  numberOfQualifyingRows: 1,
  qualifyingOptions: (responseOptions?: { responseOptionLk: string }[]) =>
    responseOptions?.map((responseOption) => responseOption.responseOptionLk) ??
    [],
  operator: MatrixRowQualifyingOperator.GreaterThanOrEquals
}

export const getQuestionPrefix = (
  entryType: EntryType,
  contextPosition: number
): string => {
  const questionPosition = contextPosition + 1
  switch (entryType) {
    case EntryType.ForkEntryType:
      return `Fork ${questionPosition}`
    case EntryType.TextCardEntryType:
      return `T${questionPosition}`
    case EntryType.QuestionEntryType:
    case EntryType.MatrixEntryType:
    default:
      return `Q${questionPosition}`
  }
}

type EntryTypes = {
  [EntryType.QuestionEntryType]: DraftQuestionItem
  [EntryType.MatrixEntryType]: DraftMatrixItem
  [EntryType.SectionEntryType]: DraftSectionItem
  [EntryType.TextCardEntryType]: DraftTextCardItem
  [EntryType.ForkEntryType]: DraftForkItem
}

/**
 * The DraftQuestionnaireEntry type is not set up as a discriminated union,
 * which can make working with it quite difficult (forcing us to cast when
 * we've already checked the type). This converts it into one so presuming you
 * check entryType, entryItem is properly typed
 */
export const entryAsUnion = (
  entry: DraftQuestionnaireEntry
): Omit<DraftQuestionnaireEntry, 'entryType' | 'entryItem'> &
  {
    [Type in keyof EntryTypes]: {
      entryType: Type
      entryItem: EntryTypes[Type]
    }
  }[keyof EntryTypes] => entry as any
