import { gql } from '@apollo/client'

const DRAFT_RESPONSE_OPTION = gql`
  fragment DraftEntryResponseOption on DraftEntryResponseOption {
    position
    createdDate
    sinceDate
    responseOptionLk
    responseOption {
      responseOptionId
      rotype
      value
      createdDate
    }
    qualification {
      createdDate
      sinceDate
    }
    route {
      sinceDate
      targetNumber
    }
    maskingRules {
      ...DraftMaskingRule
    }
    pinned
    textEntryState
    exclusive
    media {
      mediaName
      mediaUrl
      mediaType
      renderedMediaUrl
    }
    __typename
  }
`

export default DRAFT_RESPONSE_OPTION
