import React, { useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import {
  LogCreateEntryTypeEvent,
  LogCreateQuestionEvent
} from '../../../data/amplitude'
import { EntryType, QuestionTypeCode } from '../../../data/model/questionnaire'
import { useCreateQuestionnaireEntryV2 } from '../../../hooks/questionnaire/useCreateQuestionnaireEntryV2'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { addQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import { getForkCount } from '../../../utils/questionnaireUtils'
import { ImportQuestions } from '../ImportQuestions'
import AddQuestionControl from './AddQuestionMenu.control'
import {
  AddQuestionOption,
  AddQuestionSecondaryOption
} from './AddQuestionMenu.model'

enum DEFAULT_OPTIONS_COUNT {
  ZERO = 0,
  AT_LEAST_TWO = 2
}

interface Props {
  showAddButton?: boolean
  showSectionMenu?: boolean
  position: number
  sectionId?: string
}

const AddQuestionMenu: React.FC<Props> = ({
  position,
  showAddButton = false,
  showSectionMenu = false,
  sectionId
}: Props) => {
  const surveyId = useSurveyId()
  const { draftQuestionnaire, refetchQuestionnaire } =
    useGetDraftQuestionnaire()
  const createQuestionnaireEntryV2 = useCreateQuestionnaireEntryV2()
  const [importQuestionsDialogOpen, setImportQuestionsDialogOpen] =
    useState<boolean>(false)

  const handleCreateNewQuestion: (
    addQuestionOption: AddQuestionOption
  ) => void = async (addQuestionOption) => {
    const defaultOptionsCount =
      addQuestionOption.questionTypeCode === QuestionTypeCode.FreeText
        ? DEFAULT_OPTIONS_COUNT.ZERO
        : DEFAULT_OPTIONS_COUNT.AT_LEAST_TWO
    addQuestionTransactionDatadog.start()
    switch (addQuestionOption.questionTypeCode) {
      case QuestionTypeCode.Basic:
      case QuestionTypeCode.Ranked:
      case QuestionTypeCode.MaxDiff:
      case QuestionTypeCode.FreeText: {
        createQuestionnaireEntryV2({
          sectionId,
          position,
          createQuestionItem: {
            text: '',
            questionTypeCode: addQuestionOption.questionTypeCode,
            defaultResponseOptionsCount: defaultOptionsCount,
            settingValues: addQuestionOption.settings
              ? [
                  {
                    questionSettingCode:
                      addQuestionOption.settings.questionSettingCode,
                    settingValue: addQuestionOption.settings.settingValue
                  }
                ]
              : []
          }
        })
        //TODO: Do we still need the optimistic response?
        break
      }
      case QuestionTypeCode.Scale: {
        createQuestionnaireEntryV2({
          sectionId,
          position,
          createQuestionItem: {
            text: '',
            questionTypeCode: QuestionTypeCode.Scale,
            scaleRange: {
              min: 0,
              max: 100,
              step: 1,
              startingPosition: 0
            }
          }
        })
        break
      }
      case QuestionTypeCode.Matrix: {
        createQuestionnaireEntryV2({
          sectionId,
          position,
          createMatrixItem: {
            title: '',
            settingValues: addQuestionOption.settings
              ? [
                  {
                    questionSettingCode:
                      addQuestionOption.settings.questionSettingCode,
                    settingValue: addQuestionOption.settings.settingValue
                  }
                ]
              : [],
            defaultMatrixRowsCount: defaultOptionsCount,
            defaultResponseOptionsCount: defaultOptionsCount
          }
        })

        break
      }
      default:
    }
    LogCreateQuestionEvent(
      surveyId,
      addQuestionOption.questionTypeCode,
      addQuestionOption.settings,
      sectionId
    )
  }

  const handleCreateSecondaryCard: (
    addQuestionSecondaryOption: AddQuestionSecondaryOption
  ) => void = async (addQuestionSecondaryOption) => {
    switch (addQuestionSecondaryOption.entryType) {
      case EntryType.ForkEntryType:
        createQuestionnaireEntryV2({
          position,
          createForkItem: {
            name: `Fork ${getForkCount(draftQuestionnaire?.entries)}`,
            defaultBranchNumber: 2
          }
        })
        break
      case EntryType.TextCardEntryType:
        createQuestionnaireEntryV2({
          sectionId,
          position,
          createTextCardItem: {
            title: ''
          }
        })

        break
      case EntryType.SectionEntryType: {
        createQuestionnaireEntryV2({
          position,
          createSectionItem: {
            title: 'Section'
          }
        })
        break
      }
      default:
    }
    LogCreateEntryTypeEvent(surveyId, addQuestionSecondaryOption.entryType)
  }

  const handleImportFromOtherSurvey = (): void => {
    setImportQuestionsDialogOpen(true)
    LogAmplitudeEvent(EventType.OpenedImportQuestions, { surveyId })
  }

  return (
    <>
      <AddQuestionControl
        showAddButton={showAddButton}
        showSectionMenu={showSectionMenu}
        onCreateNewQuestion={handleCreateNewQuestion}
        onCreateSecondaryEntry={handleCreateSecondaryCard}
        onImportFromOtherSurveyClick={handleImportFromOtherSurvey}
      />
      {importQuestionsDialogOpen && (
        <ImportQuestions
          position={position}
          refetchQuestionnaire={refetchQuestionnaire}
          onClose={() => setImportQuestionsDialogOpen(false)}
        />
      )}
    </>
  )
}

export default React.memo(AddQuestionMenu)
