import {
  Grid,
  LinkButton,
  Paper,
  PaperGroupItem,
  Skeleton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import useStyles from './styles'

const ConfirmationProjectOverviewLoader: React.FC = () => {
  const navigate = useNavigate()
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const { classes } = useStyles()

  const goToAudienceCriteria = () => {
    navigate(`/project/${projectId}/survey/${surveyId}/audience`)
  }

  const goToQuestionnaire = () => {
    navigate(`/project/${projectId}/survey/${surveyId}/questionnaire`)
  }

  return (
    <Paper>
      <PaperGroupItem>
        <Grid className={classes.content}>
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Project overview
          </Text>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column" className={classes.content}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.countryContainer}
          >
            <Grid container item direction="row" xs={6}>
              <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                Country: &nbsp;
              </Text>
              <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                <Skeleton width={40} height={21} />
              </Text>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <LinkButton
                highlight={TextHighlight.Emphasis}
                weight={TextWeight.SemiBold}
                size={TextSize.ms}
                onClick={goToAudienceCriteria}
              >
                Edit audience
              </LinkButton>
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Number of completes wanted: &nbsp;
            </Text>
            <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
              <Skeleton width={40} height={21} />
            </Text>
          </Grid>
        </Grid>
        <Grid item className={classes.dividerGrid}>
          <Divider />
        </Grid>
        <Grid container direction="column" className={classes.content}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item direction="row" xs={8}>
              <Text size={TextSize.ms} highlight={TextHighlight.Background}>
                Number of questions: &nbsp;
              </Text>
              <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
                <Skeleton width={40} height={21} />
              </Text>
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end">
              <LinkButton
                highlight={TextHighlight.Emphasis}
                weight={TextWeight.SemiBold}
                size={TextSize.ms}
                onClick={goToQuestionnaire}
              >
                Edit survey
              </LinkButton>
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              Length of survey: &nbsp;
            </Text>
            <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
              <Skeleton width={40} height={21} />
            </Text>
          </Grid>
        </Grid>
        <Grid item className={classes.dividerGrid}>
          <Divider />
        </Grid>
        <Grid container direction="row" className={classes.content}>
          <Text size={TextSize.ms} highlight={TextHighlight.Background}>
            Output options: &nbsp;
          </Text>
          <Text size={TextSize.ms} weight={TextWeight.SemiBold}>
            Data visuals, raw data, data tables
          </Text>
        </Grid>
      </PaperGroupItem>
    </Paper>
  )
}

export default ConfirmationProjectOverviewLoader
