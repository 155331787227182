import { Skeleton } from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './Sidebar.styles'

const SidebarLoader: React.FC = () => {
  const { classes } = useStyles()

  return (
    <>
      <div className={classes.skeleton}>
        <Skeleton variant="rectangular" width={310} height={35} />
      </div>
      <div className={classes.skeleton}>
        <Skeleton variant="rectangular" width={310} height={35} />
      </div>
      <div className={classes.skeleton}>
        <Skeleton variant="rectangular" width={310} height={35} />
      </div>
    </>
  )
}

export default SidebarLoader
