import {
  Dialog,
  DialogMaxWidth,
  Grid,
  LinkButton,
  Select,
  SelectIconColor,
  SelectOption,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { ForkTag } from '../../../data/gql-gen/questionnaire/graphql'
import { Operator } from '../../../data/model/common'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  DraftTextCardItem,
  EntryType
} from '../../../data/model/questionnaire'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { chain } from '../../../utils/lodashChain'
import {
  ClauseBlock,
  ClauseBlockContainer,
  getClauseBlockContainers,
  getDefaultClause,
  getDefaultOperator
} from '../../../utils/questionLogic'
import { flattenEntries } from '../Questionnaire.utils'
import ClauseBlockControl, { DisplayLogicForkTag } from './ClauseBlock'
import useStyles from './DialogAddDisplayLogic.styles'

interface Props {
  entryPosition: number | undefined
  contextPosition: number | undefined
  open: boolean
  isSaving: boolean
  onClose: () => void
  onSave: (
    entry: DraftQuestionnaireEntry | undefined,
    clauseBlockContainers: ClauseBlockContainer[],
    operator: Operator,
    forkTags: ForkTag[]
  ) => void
}
const DialogAddDisplayLogic: React.FC<Props> = (props: Props) => {
  const { entryPosition, contextPosition, open, isSaving, onClose, onSave } =
    props
  const { draftQuestionnaireEntries, flattenedEntries } =
    useGetDraftQuestionnaire()

  // TODO no need for flattening audience questions, should be done in useGetDraftQuestionnaire after backend fixes for sections are done
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const [saveDisabled, setSaveDisabled] = useState(false)
  const entry = flattenedEntries.find(
    (entry) => entry.position === entryPosition
  )
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const checkIfQuestionLogic: (
    entry: DraftQuestionnaireEntry | undefined
  ) => boolean = (entry) => {
    if (
      entry &&
      [
        EntryType.QuestionEntryType,
        EntryType.MatrixEntryType,
        EntryType.TextCardEntryType
      ].includes(entry.entryType)
    ) {
      const entryItem = entry.entryItem as
        | DraftQuestionItem
        | DraftMatrixItem
        | DraftTextCardItem

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!entryItem.questionLogic) {
        return false
      }

      return entryItem.questionLogic.length !== 0
    }

    return false
  }

  // contains the clauses that are in the backend. Question logic only
  const defaultClauseBlockContainers = getClauseBlockContainers(
    draftQuestionnaireEntries,
    entry
  )

  // this has to go through all of the forktags and group by same id and create a list of number
  const displayLogicForks: DisplayLogicForkTag[] = chain(entry?.forks)
    .groupBy((fork) => fork.forkId)
    .map((value, key) => {
      return {
        forkId: key,
        branchNumbers: value.map((tag) => tag.branchNumber)
      }
    })
    .value()

  const [displayForkTags, setDisplayForkTags] =
    useState<DisplayLogicForkTag[]>(displayLogicForks)

  const hasFork = displayForkTags.length > 0
  const defaultOperator = hasFork
    ? Operator.And
    : getDefaultOperator(entry) ?? Operator.And

  const [operator, setOperator] = useState<Operator>(defaultOperator)

  // contains the clauses that need to be added
  // if there was a question previously this will load up as empty
  // so if that is replaced by a fork, it will require 2 clicks
  // if there's only one fork, it will also show 2 things
  const [newClauseBlockContainer] = useState([
    {
      clauseNumber: undefined,
      clauseBlocks:
        checkIfQuestionLogic(entry) || displayLogicForks.length > 0
          ? []
          : [getDefaultClause()]
    }
  ])

  // combines defaultClauseBlockContainers && newClauseBlockContainer
  const [clauseBlockContainers, setClauseBlockContainers] = useState<
    ClauseBlockContainer[]
  >(defaultClauseBlockContainers.concat(newClauseBlockContainer))

  const options: SelectOption[] = [
    {
      id: '0',
      name: 'AND',
      value: Operator.And
    },
    {
      id: '1',
      name: 'OR',
      value: Operator.Or
    }
  ]

  const noDisplayLogic =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !clauseBlockContainers?.[0]?.clauseBlocks?.[0]?.questionLk ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    clauseBlockContainers?.[0]?.clauseBlocks?.[0]?.propositionResponseOptionLks
      .length === 0 || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    clauseBlockContainers?.[0]?.clauseBlocks.length === 0
  const noForks =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !displayForkTags?.[0]?.forkId || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    displayForkTags?.[0]?.branchNumbers.length === 0

  const handleDelete = (clauseId: string): void => {
    const newClauseBlockContainers = clauseBlockContainers.map(
      (clauseBlockContainer) => {
        return {
          clauseNumber: clauseBlockContainer.clauseNumber,
          clauseBlocks: clauseBlockContainer.clauseBlocks.filter(
            (clause) => clause.clauseId !== clauseId
          )
        } as ClauseBlockContainer
      }
    )
    setClauseBlockContainers(newClauseBlockContainers)
  }

  const handleAddCondition: () => void = () => {
    const defaultClause = getDefaultClause()
    // if there is nothing in the clause block containers it means a fork has been added previously
    if (clauseBlockContainers.length === 0) {
      setClauseBlockContainers([
        {
          clauseNumber: undefined,
          clauseBlocks: [defaultClause]
        }
      ])
    } else {
      const newClauseBlockContainers = clauseBlockContainers.reduce(
        (acc, clauseBlockContainer) => {
          const newClauseBlockContainer = clauseBlockContainer
          if (newClauseBlockContainer.clauseNumber === undefined) {
            newClauseBlockContainer.clauseBlocks.push(defaultClause)
            acc.push(newClauseBlockContainer)
            return acc
          }
          acc.push(clauseBlockContainer)
          return acc
        },
        [] as ClauseBlockContainer[]
      )
      setClauseBlockContainers(newClauseBlockContainers)
    }
  }

  const handleChangeResponseOptions: (
    clauseId: string,
    responseOptionLks: string[],
    questionLk: string | undefined,
    matrixTitleLk: string | undefined
  ) => void = (clauseId, responseOptionLks, questionLk, matrixTitleLk) => {
    const newClauseBlockContainers = clauseBlockContainers.reduce(
      (acc, clauseBlockContainer) => {
        const newClauseBlockContainer: ClauseBlockContainer = {
          clauseNumber: clauseBlockContainer.clauseNumber,
          clauseBlocks: clauseBlockContainer.clauseBlocks.map((clauseBlock) => {
            const newClauseBlock = { ...clauseBlock }
            if (clauseBlock.clauseId === clauseId) {
              newClauseBlock.propositionResponseOptionLks = responseOptionLks
              newClauseBlock.questionLk = questionLk
              newClauseBlock.matrixTitleLk = matrixTitleLk
              return newClauseBlock
            }
            return newClauseBlock
          })
        }

        acc.push(newClauseBlockContainer)
        return acc
      },
      [] as ClauseBlockContainer[]
    )
    setClauseBlockContainers(newClauseBlockContainers)
  }

  const handleSelectedQuestionChange: (
    clauseId: string,
    entry: DraftQuestionnaireEntry | undefined,
    selectedQuestionLk: string,
    matrixTitleLk: string | undefined
  ) => void = (clauseId, entry, selectedQuestionLk, matrixTitleLk) => {
    const newClauseBlockContainers = (
      clauseBlockContainers.length === 0
        ? newClauseBlockContainer
        : clauseBlockContainers
    ).reduce((acc, clauseBlockContainer) => {
      const newClauseBlockContainer: ClauseBlockContainer = {
        clauseNumber: clauseBlockContainer.clauseNumber,
        clauseBlocks: clauseBlockContainer.clauseBlocks.map((clauseBlock) => {
          const newClauseBlock = { ...clauseBlock }
          if (clauseBlock.clauseId === clauseId) {
            newClauseBlock.propositionEntry = entry
            newClauseBlock.questionLk = selectedQuestionLk
            newClauseBlock.matrixTitleLk = matrixTitleLk
            newClauseBlock.propositionResponseOptionLks = []
            return newClauseBlock
          }
          return newClauseBlock
        })
      }

      acc.push(newClauseBlockContainer)
      return acc
    }, [] as ClauseBlockContainer[])

    setClauseBlockContainers(newClauseBlockContainers)
  }

  const handleOnChangeSelect: (Operator: Operator) => void = (operator) => {
    setOperator(operator)
  }

  const handleOnChangeNegation: (clauseId: string, negated: boolean) => void = (
    clauseId,
    negated
  ) => {
    const newClauseBlockContainers = clauseBlockContainers.reduce(
      (acc, clauseBlockContainer) => {
        const newClauseBlockContainer: ClauseBlockContainer = {
          clauseNumber: clauseBlockContainer.clauseNumber,
          clauseBlocks: clauseBlockContainer.clauseBlocks.map((clauseBlock) => {
            const newClauseBlock = { ...clauseBlock }
            if (clauseBlock.clauseId === clauseId) {
              newClauseBlock.negated = negated
              return newClauseBlock
            }
            return newClauseBlock
          })
        }

        acc.push(newClauseBlockContainer)
        return acc
      },
      [] as ClauseBlockContainer[]
    )
    setClauseBlockContainers(newClauseBlockContainers)
  }

  const getOperatorText: (operator: Operator) => string = (operator) => {
    switch (operator) {
      case Operator.Or:
        return 'OR'
      default:
        return 'AND'
    }
  }

  const clauseBlocks = clauseBlockContainers.flatMap(
    (container) => container.clauseBlocks
  )

  const handleChangeForkBranches: (
    forkId: string,
    checkedBranches: number[]
  ) => void = (forkId, checkedBranches) => {
    // here we just persist the new branch list inside the displayforktag with the correct forkId
    setDisplayForkTags([{ forkId, branchNumbers: checkedBranches }])
  }

  const handleSelectedForkChanged: (forkId: string) => void = (forkId) => {
    setDisplayForkTags([{ forkId, branchNumbers: [] }])
    setClauseBlockContainers([])
    // Setting AND if there's a fork
    setOperator(Operator.And)
  }

  const handleDeleteFork: () => void = () => {
    setDisplayForkTags([])
  }

  const handleSave: () => void = () => {
    if (saveDisabled) {
      return
    }
    setSaveDisabled(true)
    onSave(
      entry,
      clauseBlockContainers,
      operator,
      displayForkTags.flatMap((dft) =>
        dft.branchNumbers.map((bn) => {
          return { forkId: dft.forkId, branchNumber: bn, __typename: 'ForkTag' }
        })
      )
    )
  }
  const shouldDisplayAndBetweenForkAndQuestions =
    displayForkTags.length > 0 && clauseBlocks.length > 0

  const forkClauseBlock =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (newClauseBlockContainer &&
      newClauseBlockContainer.length > 0 &&
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      newClauseBlockContainer[0].clauseBlocks &&
      newClauseBlockContainer[0].clauseBlocks.length > 0 &&
      newClauseBlockContainer[0].clauseBlocks[0]) ||
    undefined

  const questionPrefix =
    entry?.entryType === EntryType.TextCardEntryType ? 'T' : 'Q'

  const hasMutualExclusiveRules = (clauseBlocks: ClauseBlock[]) => {
    return clauseBlocks.some((currentRule, index) =>
      clauseBlocks.slice(index + 1).some((rule) => {
        const isRuleDuplicate =
          rule.questionLk === currentRule.questionLk &&
          rule.propositionResponseOptionLks.some((s) =>
            currentRule.propositionResponseOptionLks.includes(s)
          )

        return isRuleDuplicate && rule.negated !== currentRule.negated
      })
    )
  }

  const isLogicInvalid = hasMutualExclusiveRules(clauseBlocks)
  const incompleteRule = clauseBlocks.some(
    (cb) => cb.propositionResponseOptionLks.length === 0
  )
  const disableSaveButton =
    (noDisplayLogic && noForks) || incompleteRule || isLogicInvalid

  const validationError = isLogicInvalid
    ? 'You selected 2 or more mutually exclusive rules'
    : ''

  return (
    <Dialog
      title="Display logic"
      open={open}
      onClose={() => {
        onClose()
      }}
      fullWidth
      maxWidth={DialogMaxWidth.Md}
      primaryButtonDisabled={disableSaveButton}
      primaryButtonText="Save"
      primaryButtonLoading={isSaving}
      primaryButtonClick={handleSave}
    >
      <Grid container>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.highlightBackground,
            classes.subtitleContainer
          )}
        >
          Only show{' '}
          <strong>{`${questionPrefix}${
            contextPosition ? contextPosition + 1 : 1
          }
          `}</strong>{' '}
          if the following conditions are met:
        </p>
      </Grid>
      {displayForkTags.map((fork) => {
        return (
          <ClauseBlockControl
            key={fork.forkId}
            showForks
            displayLogicForkTags={fork}
            clauseId={forkClauseBlock?.clauseId}
            clauseBlock={forkClauseBlock}
            entries={flatDraftQuestionnaireEntries}
            entry={entry}
            onChangeForkBranches={handleChangeForkBranches}
            onSelectedForkChange={handleSelectedForkChanged}
            onDelete={handleDeleteFork}
            onSelectedQuestionChange={(
              clauseId,
              entry: DraftQuestionnaireEntry | undefined,
              selectedQuestionLk,
              matrixTitleLk
            ) => {
              if (clauseBlockContainers.length === 0) {
                setDisplayForkTags([])
              }
              handleSelectedQuestionChange(
                clauseId,
                entry,
                selectedQuestionLk,
                matrixTitleLk
              )
            }}
            onChangeResponseOptions={handleChangeResponseOptions}
            negated={false}
            onChangeNegation={handleOnChangeNegation}
          />
        )
      })}
      {shouldDisplayAndBetweenForkAndQuestions && (
        <Grid className={classes.operatorText}>
          <p
            className={classNames(
              textClasses.default,
              textClasses.highlightStandard,
              textClasses.sizeMs,
              textClasses.weightSemiBold
            )}
          >
            AND
          </p>
        </Grid>
      )}
      {clauseBlocks.map((clauseBlock, index) => {
        if (clauseBlock.shouldDelete) {
          return null
        }
        const containerLength = clauseBlocks.length
        const isFirstOperatorSelect = index === 0 && containerLength > 1
        const isSubsequentQuestionOperator =
          index > 0 && index !== containerLength - 1
        const showSelect = isFirstOperatorSelect && !hasFork
        const showText =
          isSubsequentQuestionOperator || (isFirstOperatorSelect && hasFork)
        const showForks = index === 0 && !hasFork
        const { negated } = clauseBlock
        return (
          <>
            <ClauseBlockControl
              key={clauseBlock.clauseId}
              showForks={showForks}
              clauseId={clauseBlock.clauseId}
              entries={flatDraftQuestionnaireEntries}
              onChangeForkBranches={handleChangeForkBranches}
              onSelectedForkChange={handleSelectedForkChanged}
              clauseBlock={clauseBlock}
              entry={entry}
              onSelectedQuestionChange={handleSelectedQuestionChange}
              onChangeResponseOptions={handleChangeResponseOptions}
              onDelete={handleDelete}
              negated={negated}
              onChangeNegation={handleOnChangeNegation}
            />
            {showSelect && (
              <Grid className={classes.selectContainer}>
                <Select
                  defaultOptionValue={operator}
                  options={options}
                  variant={SelectVariant.Underlined}
                  iconColor={SelectIconColor.Secondary}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleOnChangeSelect(event.target.value as Operator)
                  }}
                  width={70}
                  textSize={TextSize.ms}
                  selectedTextHighlight={TextHighlight.Standard}
                  selectedTextWeight={TextWeight.SemiBold}
                />
              </Grid>
            )}
            {showText && (
              <Grid className={classes.operatorText}>
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.highlightStandard,
                    textClasses.sizeMs,
                    textClasses.weightSemiBold
                  )}
                >
                  {getOperatorText(operator)}
                </p>
              </Grid>
            )}
          </>
        )
      })}
      <div className="fd-grid fd-container fd-item fd-justify-content-start">
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightRegular,
            classes.errorText
          )}
        >
          {validationError}
        </p>
      </div>
      <Grid container className={classes.linkButton}>
        <LinkButton
          highlight={TextHighlight.Emphasis}
          size={TextSize.m}
          weight={TextWeight.SemiBold}
          onClick={() => {
            handleAddCondition()
          }}
          noPadding
        >
          Add condition
        </LinkButton>
      </Grid>
    </Dialog>
  )
}

export default DialogAddDisplayLogic
