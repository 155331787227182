/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query audienceChangedSinceLastSlackIRSNotificationQuery {\n      audienceChangedSinceLastIRSNotification @client\n    }\n  ": types.AudienceChangedSinceLastSlackIrsNotificationQueryDocument,
    "\n  query WriteSurveyResult {\n    surveyResult {\n      id\n      data\n    }\n  }\n": types.WriteSurveyResultDocument,
    "\n  query ReadSurveyResult {\n    surveyResult {\n      data\n    }\n  }\n": types.ReadSurveyResultDocument,
    "\n  mutation SetDraftCombinedQuestion(\n    $draftCombinedQuestion: DraftCombinedQuestionInput!\n  ) {\n    setDraftCombinedQuestion(draftCombinedQuestion: $draftCombinedQuestion)\n      @client\n  }\n": types.SetDraftCombinedQuestionDocument,
    "\n  mutation SetDraftCombinedQuestionTitle($title: String!) {\n    setDraftCombinedQuestionTitle(title: $title) @client\n  }\n": types.SetDraftCombinedQuestionTitleDocument,
    "\n  query GetDraftCombinedQuestion {\n    draftCombinedQuestion @client {\n      id\n      title\n      position\n      mappedResponseOptions {\n        id\n        mappingNumber\n        value\n      }\n      marketQuestions {\n        id\n        market\n        position\n        responseOptions {\n          id\n          mappingId\n          value\n        }\n      }\n    }\n  }\n": types.GetDraftCombinedQuestionDocument,
    "\n  mutation AddDraftCombinedResponseOption {\n    addDraftCombinedResponseOption @client\n  }\n": types.AddDraftCombinedResponseOptionDocument,
    "\n  mutation DeleteDraftCombinedResponseOption($id: String!) {\n    deleteDraftCombinedResponseOption(id: $id) @client\n  }\n": types.DeleteDraftCombinedResponseOptionDocument,
    "\n  mutation BulkAddDraftCombinedResponseOptions(\n    $values: [String]!\n    $insertAtIndex: Int!\n  ) {\n    bulkAddDraftCombinedResponseOptions(\n      values: $values\n      insertAtIndex: $insertAtIndex\n    ) @client {\n      id\n      mappingNumber\n      value\n    }\n  }\n": types.BulkAddDraftCombinedResponseOptionsDocument,
    "\n  mutation UpdateDraftCombinedResponseOption($id: String!, $value: String!) {\n    updateDraftCombinedResponseOption(id: $id, value: $value) @client\n  }\n": types.UpdateDraftCombinedResponseOptionDocument,
    "\n  mutation DeleteMappingMarketQuestion($id: String!) {\n    deleteMappingMarketQuestion(id: $id) @client\n  }\n": types.DeleteMappingMarketQuestionDocument,
    "\n  mutation MapResponseOption(\n    $id: String!\n    $marketQuestionId: String!\n    $mappingId: String!\n  ) {\n    mapResponseOption(\n      id: $id\n      marketQuestionId: $marketQuestionId\n      mappingId: $mappingId\n    ) @client\n  }\n": types.MapResponseOptionDocument,
};

/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = localGql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function localGql(source: string): unknown;

/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n    query audienceChangedSinceLastSlackIRSNotificationQuery {\n      audienceChangedSinceLastIRSNotification @client\n    }\n  "): (typeof documents)["\n    query audienceChangedSinceLastSlackIRSNotificationQuery {\n      audienceChangedSinceLastIRSNotification @client\n    }\n  "];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  query WriteSurveyResult {\n    surveyResult {\n      id\n      data\n    }\n  }\n"): (typeof documents)["\n  query WriteSurveyResult {\n    surveyResult {\n      id\n      data\n    }\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  query ReadSurveyResult {\n    surveyResult {\n      data\n    }\n  }\n"): (typeof documents)["\n  query ReadSurveyResult {\n    surveyResult {\n      data\n    }\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation SetDraftCombinedQuestion(\n    $draftCombinedQuestion: DraftCombinedQuestionInput!\n  ) {\n    setDraftCombinedQuestion(draftCombinedQuestion: $draftCombinedQuestion)\n      @client\n  }\n"): (typeof documents)["\n  mutation SetDraftCombinedQuestion(\n    $draftCombinedQuestion: DraftCombinedQuestionInput!\n  ) {\n    setDraftCombinedQuestion(draftCombinedQuestion: $draftCombinedQuestion)\n      @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation SetDraftCombinedQuestionTitle($title: String!) {\n    setDraftCombinedQuestionTitle(title: $title) @client\n  }\n"): (typeof documents)["\n  mutation SetDraftCombinedQuestionTitle($title: String!) {\n    setDraftCombinedQuestionTitle(title: $title) @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  query GetDraftCombinedQuestion {\n    draftCombinedQuestion @client {\n      id\n      title\n      position\n      mappedResponseOptions {\n        id\n        mappingNumber\n        value\n      }\n      marketQuestions {\n        id\n        market\n        position\n        responseOptions {\n          id\n          mappingId\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDraftCombinedQuestion {\n    draftCombinedQuestion @client {\n      id\n      title\n      position\n      mappedResponseOptions {\n        id\n        mappingNumber\n        value\n      }\n      marketQuestions {\n        id\n        market\n        position\n        responseOptions {\n          id\n          mappingId\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation AddDraftCombinedResponseOption {\n    addDraftCombinedResponseOption @client\n  }\n"): (typeof documents)["\n  mutation AddDraftCombinedResponseOption {\n    addDraftCombinedResponseOption @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation DeleteDraftCombinedResponseOption($id: String!) {\n    deleteDraftCombinedResponseOption(id: $id) @client\n  }\n"): (typeof documents)["\n  mutation DeleteDraftCombinedResponseOption($id: String!) {\n    deleteDraftCombinedResponseOption(id: $id) @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation BulkAddDraftCombinedResponseOptions(\n    $values: [String]!\n    $insertAtIndex: Int!\n  ) {\n    bulkAddDraftCombinedResponseOptions(\n      values: $values\n      insertAtIndex: $insertAtIndex\n    ) @client {\n      id\n      mappingNumber\n      value\n    }\n  }\n"): (typeof documents)["\n  mutation BulkAddDraftCombinedResponseOptions(\n    $values: [String]!\n    $insertAtIndex: Int!\n  ) {\n    bulkAddDraftCombinedResponseOptions(\n      values: $values\n      insertAtIndex: $insertAtIndex\n    ) @client {\n      id\n      mappingNumber\n      value\n    }\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation UpdateDraftCombinedResponseOption($id: String!, $value: String!) {\n    updateDraftCombinedResponseOption(id: $id, value: $value) @client\n  }\n"): (typeof documents)["\n  mutation UpdateDraftCombinedResponseOption($id: String!, $value: String!) {\n    updateDraftCombinedResponseOption(id: $id, value: $value) @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation DeleteMappingMarketQuestion($id: String!) {\n    deleteMappingMarketQuestion(id: $id) @client\n  }\n"): (typeof documents)["\n  mutation DeleteMappingMarketQuestion($id: String!) {\n    deleteMappingMarketQuestion(id: $id) @client\n  }\n"];
/**
 * The localGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function localGql(source: "\n  mutation MapResponseOption(\n    $id: String!\n    $marketQuestionId: String!\n    $mappingId: String!\n  ) {\n    mapResponseOption(\n      id: $id\n      marketQuestionId: $marketQuestionId\n      mappingId: $mappingId\n    ) @client\n  }\n"): (typeof documents)["\n  mutation MapResponseOption(\n    $id: String!\n    $marketQuestionId: String!\n    $mappingId: String!\n  ) {\n    mapResponseOption(\n      id: $id\n      marketQuestionId: $marketQuestionId\n      mappingId: $mappingId\n    ) @client\n  }\n"];

export function localGql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;