import {
  Grid,
  Spinner,
  SpinnerColor,
  SpinnerSize
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudePublicEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { SideMenuComponent } from '../../../containers/Page/Survey/SideMenu/SideMenu'
import ErrorPage, { ErrorType } from '../../../controls/ErrorPage'
import { SelectedFilter } from '../../../data/model/results'
import { PublicResultsParams } from '../../../data/model/surveyParams'
import SurveyLayout from '../../../layouts/SurveyLayout'
import { Datadog } from '../../../tracking/datadog'
import { APP_MIN_WIDTH_PX } from '../../../utils/appConstants'
import { WeightingData } from '../../../utils/resultsUtils'
import HubspotContactForm from '../HubspotContactForm/HubspotContactForm.container'
import { PublicResultsContent } from '../PublicResultsContent'
import { usePublicSummary } from '../PublicResultsContent/PublicResultsContent.hooks'
import { PublicResultsSubheader } from '../PublicResultsSubheader'
import { useSurveyMetadata } from '../PublicResultsSubheader/PublicResultsSubheader.hooks'

const PublicResultsPage: React.FC = () => {
  const params = useParams<keyof PublicResultsParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const { from } = params
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([])
  const {
    data,
    error,
    loading: loadingMetadata
  } = useSurveyMetadata({ surveyId })
  const {
    data: summaryData,
    weightingScheme,
    loading: summaryLoading
  } = usePublicSummary({
    surveyId,
    filters: selectedFilters
  })

  const showWeightedData = !!weightingScheme

  const weightingQuestions =
    weightingScheme &&
    (weightingScheme.questions
      .map((questionId) => {
        const question = summaryData.find(
          (question) => question.id === questionId
        )
        if (question) {
          return {
            number: question.contextPosition,
            text: question.text
          }
        }

        return undefined
      })
      .filter((wq) => wq !== undefined) as WeightingData[])

  useEffect(() => {
    LogAmplitudePublicEvent(EventType.ViewedPublicResults, { surveyId, from })
    Datadog.updateUserSession({ surveyId })
  }, [surveyId, from])
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (error && error.isError) {
    LogAmplitudePublicEvent(EventType.WrongIdOrUnpublishedSurvey, { surveyId })
    return (
      <Grid container>
        <SideMenuComponent isPublicPage />
        <ErrorPage errorType={ErrorType.NotFound404Public} />
      </Grid>
    )
  }

  if (loadingMetadata || !data) {
    return (
      <Grid
        container
        style={{ height: '100vh' }}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size={SpinnerSize.l} color={SpinnerColor.Primary} />
      </Grid>
    )
  }

  return (
    <>
      <HubspotContactForm projectTitle={data.projectName} />
      <Grid
        display="flex"
        justifyContent="center"
        direction="row"
        minWidth={APP_MIN_WIDTH_PX}
      >
        <SideMenuComponent isPublicPage />
        <SurveyLayout
          headers={
            <PublicResultsSubheader
              projectMetadata={data}
              loadingMetadata={loadingMetadata}
              weightingQuestions={weightingQuestions}
            />
          }
          content={
            <PublicResultsContent
              projectMetadata={data}
              loadingMetadata={loadingMetadata}
              summaryData={summaryData}
              summaryLoading={summaryLoading}
              showWeightedData={showWeightedData}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          }
          hasOneSubheader={false}
          hasOneSubheaderNoFooter
        />
      </Grid>
    </>
  )
}

export default PublicResultsPage
