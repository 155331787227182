import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  positioning: {
    position: 'absolute',
    height: '100vh',
    marginTop: 0,
    marginLeft: 56,
    width: 'calc(100vw - 56px)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 4
  },
  container: {
    height: '100%',
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(6, 24, 24, 24)
  },
  largePadding: {
    padding: theme.spacing(2)
  },
  smallPadding: {
    padding: theme.spacing(0.5)
  },
  buttonPadding: {
    padding: theme.spacing(4, 0.5, 0.5, 0.5)
  },
  image: {
    height: '100%',
    width: '100%',
    maxHeight: 414,
    maxWidth: 414,
    borderRadius: '100%'
  },
  button: {
    width: 140
  }
}))

export default useStyles
