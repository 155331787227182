import { gql, useApolloClient } from '@apollo/client'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const DRAFT_QUESTIONNAIRE_ID = gql`
  query draftQuestionnaireId($projectId: ID!, $surveyId: ID) {
    draftQuestionnaire: draftQuestionnaireV2(
      projectId: $projectId
      surveyId: $surveyId
    ) {
      questionnaireId
      __typename
    }
  }
`
// TODO: update all the places where QUESTIONNAIRE query is used only for reading questionnaireId
const useDraftQuestionnaireIdCache = (): string => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const client = useApolloClient()

  const queryResult = client.readQuery({
    query: DRAFT_QUESTIONNAIRE_ID,
    variables: {
      projectId,
      surveyId
    }
  })

  return queryResult?.draftQuestionnaire?.questionnaireId ?? ''
}

export default useDraftQuestionnaireIdCache
