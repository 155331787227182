import { Respondent } from '@focaldata/askai-client'
import Skeleton from '@mui/material/Skeleton'
import { LogAmplitudeEvent } from '../../../../../../../amplitude'
import { EventType } from '../../../../../../../amplitude/eventType'
import { useSurveyId } from '../../../../../../../hooks/useSurveyId'
import { useAskAiDrawer } from '../../../../../../../modules/FdChat/AskAi/AskAi.hooks'
import { useFdChatProgress } from '../../../../../../../modules/FdChat/FdChatProgressProvider.context'
import { getUserImage } from '../../../../FdChat.utils'
import RespondentCard from '../RespondentCard/RespondentCard'
import styles from './SourcesViewer.module.css'

const SourcesViewer = ({ sources }: { sources?: Respondent[] }) => {
  const surveyId = useSurveyId()
  const { loading, error, interviewsCollected } = useFdChatProgress()
  const { goToTranscript } = useAskAiDrawer()

  const handleRespondentClick = ({
    respondentId
  }: {
    respondentId: string
  }) => {
    LogAmplitudeEvent(EventType.ClickedFdChatAskAiRespondent, {
      surveyId
    })
    goToTranscript({ respondentId })
  }

  return (
    <div>
      <h2 className={styles.title}>Sources</h2>
      <p className={styles.description}>
        {loading || error ? (
          <Skeleton
            sx={{ fontSize: '14px' }}
            animation={error ? false : 'pulse'}
          />
        ) : (
          sources &&
          `We analysed ${interviewsCollected} interviews and found ${sources.length} that were relevant to your question.`
        )}
      </p>
      <div className={styles.respondents}>
        {sources?.map((respondent, index) => (
          <RespondentCard
            {...respondent}
            key={respondent.focaldata_respondent_id}
            number={index + 1}
            onClick={() =>
              handleRespondentClick({
                respondentId: respondent.focaldata_respondent_id
              })
            }
            src={getUserImage({
              userId: respondent.focaldata_respondent_id,
              gender: respondent.gender
            })}
          />
        ))}
      </div>
    </div>
  )
}

export default SourcesViewer
