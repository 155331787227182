import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const CLEAR_QUESTIONNAIRE_ENTRY_V2 = questionnaireGql(/* GraphQL */ `
  mutation clearQuestionnaireEntryV2(
    $input: ClearDraftQuestionnaireEntryV2ArgsInput!
  ) {
    clearQuestionnaireEntryV2(input: $input) {
      id
      number
      position
      entryType
      entryItem {
        __typename
        ... on DraftMatrixItem {
          matrixTitleLk
          matrixTitle {
            ...MatrixTitle
          }
        }
        ... on DraftQuestionItem {
          question {
            ...Question
          }
          questionLk
        }
      }
      __typename
    }
  }
`)
