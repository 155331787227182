import {
  Badge,
  Grid,
  Status,
  TipPlacement,
  Tooltip,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import dayjs from 'dayjs'
import React from 'react'
import Subheader from '../../../components/Subheader'
import {
  ProjectType,
  SurveyState
} from '../../../data/gql-gen/dashboard/graphql'
import { ProjectMetadata } from '../../../data/model/results'
import useSubheaderSizing from '../../../hooks/useSubheaderSizing'
import TwoChildRowLayout from '../../../layouts/TwoChildRowLayout'
import {
  WeightingData,
  transformWeightedQuestionsToTooltipMarkup
} from '../../../utils/resultsUtils'
import ProjectNameAndStatusComponent from '../../Project/ProjectNameAndStatus/ProjectNameAndStatus.component'
import ProjectTitleAndStatusLoading from '../../Project/ProjectNameAndStatus/ProjectNameAndStatus.loading'
import PublicResultsFieldworkDetails from './PublicResultsFieldworkDetails/PublicResultsFieldworkDetails.control'
import PublicResultsFieldworkDetailsLoading from './PublicResultsFieldworkDetails/PublicResultsFieldworkDetails.loading'

interface Props {
  loadingMetadata: boolean
  projectMetadata: ProjectMetadata | undefined
  weightingQuestions?: WeightingData[]
}

const PublicResultsHeaderContainer: React.FC<Props> = ({
  loadingMetadata,
  projectMetadata,
  weightingQuestions
}: Props) => {
  const { subheaderLeftSideMaxWidth } = useSubheaderSizing({ isPublic: true })
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  if (loadingMetadata || !projectMetadata) {
    return (
      <Subheader
        shadow
        topBottomPadding={false}
        leftRightPadding={false}
        leftChild={<ProjectTitleAndStatusLoading />}
        rightChild={<PublicResultsFieldworkDetailsLoading />}
      />
    )
  }

  const projectStatus = SurveyState.Completed
  const { projectType, completes, projectName, country } = projectMetadata
  const isMultiMarket = projectMetadata.projectType === ProjectType.MultiMarket

  const { startDate, endDate } = projectMetadata.fieldwork
  const formattedFieldworkStartDate = startDate
    ? dayjs(startDate).format('D MMM YYYY')
    : 'N/A'
  const formattedFieldworkEndDate = endDate
    ? dayjs(endDate).format('D MMM YYYY')
    : 'N/A'
  const fieldworkPeriod = `${formattedFieldworkStartDate} - ${formattedFieldworkEndDate}`
  const showWeightingBadge = weightingQuestions && weightingQuestions.length > 0

  return (
    <Subheader
      shadow
      topBottomPadding={false}
      leftRightPadding={false}
      leftChild={
        <TwoChildRowLayout
          maxWidth={subheaderLeftSideMaxWidth}
          spaceBetweenChildren
          leftChild={
            <ProjectNameAndStatusComponent
              projectName={projectName}
              projectStatus={projectStatus}
              projectType={projectType}
              noTooltip
            />
          }
          rightChild={
            <Grid direction="row" container alignItems="center" item>
              {showWeightingBadge && (
                <Tooltip
                  tipPlacement={TipPlacement.BottomStart}
                  title={transformWeightedQuestionsToTooltipMarkup(
                    weightingQuestions
                  )}
                >
                  <Badge
                    status={Status.DRAFT}
                    label="Weighted data"
                    style={{ marginRight: 16, marginLeft: -8 }}
                  />
                </Tooltip>
              )}
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.sizeMs,
                  textClasses.weightSemiBold,
                  textClasses.marginRight
                )}
              >
                Completes: {completes}
              </p>
            </Grid>
          }
        />
      }
      rightChild={
        <PublicResultsFieldworkDetails
          fieldworkPeriod={fieldworkPeriod}
          countryCode={country}
          isMultiMarket={isMultiMarket}
        />
      }
    />
  )
}

export default PublicResultsHeaderContainer
