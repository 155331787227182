import { InMemoryCache, Resolver } from '@apollo/client'

export const updateDraftCombinedResponseOption: Resolver = (
  _,
  { id, value }: { id: string; value: string },
  { cache }: { cache: InMemoryCache }
) => {
  const cacheId = cache.identify({
    __typename: 'DraftCombinedResponseOption',
    id
  })

  const successfullyModified = cache.modify({
    id: cacheId,
    fields: {
      value() {
        return value
      }
    }
  })

  if (!successfullyModified) {
    throw new Error(
      `Failed to update value for DraftCombinedResponseOption with id: ${id}`
    )
  }

  return value
}
