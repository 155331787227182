import {
  Dialog,
  DialogMaxWidth,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React from 'react'

interface Props {
  isDialogOpen: boolean
  onExportToPptx: () => void
  onClose: () => void
}

const DialogExportToPowerPoint: React.FC<Props> = (props: Props) => {
  const { isDialogOpen, onExportToPptx, onClose }: Props = props

  return (
    <Dialog
      open={isDialogOpen}
      primaryButtonClick={onExportToPptx}
      onClose={onClose}
      onClickOutside={onClose}
      title="Export to PowerPoint"
      primaryButtonText="Export"
      maxWidth={DialogMaxWidth.Sm}
    >
      <Text size={TextSize.ml} highlight={TextHighlight.Background}>
        Download a .pptx file containing all of the charts, fully editable in
        Microsoft PowerPoint.
      </Text>
      <br />
      <Text size={TextSize.ml} highlight={TextHighlight.Background}>
        All applied filters will be reflected in the exported file.
      </Text>
    </Dialog>
  )
}

export default DialogExportToPowerPoint
