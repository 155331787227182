import { ApolloError, WatchQueryFetchPolicy, useQuery } from '@apollo/client'
import {
  Project,
  ProjectByIdQueryVariables,
  ProjectBySurveyIdQueryVariables,
  ProjectIdBySurveyIdQuery,
  ProjectIdBySurveyIdQueryVariables,
  ProjectType
} from '../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../data/logger'
import { useProjectId } from '../../hooks/useProjectId'
import { useSurveyId } from '../../hooks/useSurveyId'
import { captureApolloError } from '../../utils/HelperFunctions'
import {
  PROJECT_BY_ID,
  PROJECT_BY_SURVEY_ID,
  PROJECT_ID_BY_SURVEY_ID,
  ProjectData
} from './Project.query'

export const useProjectData = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
): {
  project?: Project
  error?: ApolloError
  loading: boolean
} => {
  const surveyId = useSurveyId()
  const {
    data: projectData,
    error,
    loading
  } = useQuery<ProjectData, ProjectBySurveyIdQueryVariables>(
    PROJECT_BY_SURVEY_ID,
    {
      context: { clientName: 'dashboard' },
      variables: {
        surveyId
      },
      fetchPolicy
    }
  )

  const project = projectData?.project

  return {
    project,
    error,
    loading
  }
}

export const useGetProjectByProjectId = (): {
  project?: Project
  error?: ApolloError
  loading: boolean
} => {
  const projectId = useProjectId()
  const {
    data: projectData,
    error,
    loading
  } = useQuery<ProjectData, ProjectByIdQueryVariables>(PROJECT_BY_ID, {
    context: { clientName: 'dashboard' },
    variables: {
      projectId
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloQuery, 'project', error)
    }
  })

  const project = projectData?.project

  return {
    project,
    error,
    loading
  }
}

export const useGetProjectId = () => {
  const surveyId = useSurveyId()
  const { data } = useQuery<
    ProjectIdBySurveyIdQuery,
    ProjectIdBySurveyIdQueryVariables
  >(PROJECT_ID_BY_SURVEY_ID, {
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    }
  })

  return data?.projectBySurveyId?.projectId
}

export const useProjectType = () => {
  const { project, loading, error } = useProjectData()

  const projectTypes = {
    isAdHoc: project?.projectType === ProjectType.AdHoc,
    isFdChat: project?.projectType === ProjectType.FdChat,
    isMrp: project?.projectType === ProjectType.Mrp,
    isMultiMarket: project?.projectType === ProjectType.MultiMarket,
    isMultiMarketV2: project?.projectType === ProjectType.MultiMarketV2
  }

  return { ...projectTypes, loading, error }
}
