import { Grid, Skeleton } from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './ImportQuestionsPicker.styles'

const ImportQuestionsPickerLoading: React.FC = () => {
  const { classes } = useStyles()
  return (
    <Grid container>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
        <Grid
          key={val}
          container
          direction="column"
          className={classes.questionItem}
        >
          <Grid container direction="row" className={classes.questionSubItem1}>
            <Grid item sm={1}>
              <Skeleton height={24} width={24} />
            </Grid>
            <Grid item sm={11}>
              <Skeleton height={24} width={200} />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.questionSubItem}>
            <Skeleton height={20} width={120} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ImportQuestionsPickerLoading
