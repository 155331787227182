/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query softLaunchSurveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      panelSupplierOrders {\n        orderId\n        panelSupplierOrderId {\n          value\n        }\n        status\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      isSoftLaunch\n      customerCPI\n    }\n  }\n": types.SoftLaunchSurveyOrderDocument,
    "\n  mutation createSurveyOrder(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrder(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n": types.CreateSurveyOrderDocument,
    "\n  mutation createSurveyOrderV2(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrderV2(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n": types.CreateSurveyOrderV2Document,
    "\n  mutation updateSurveyOrder($surveyId: ID!, $orderStatus: SurveyOrderStatus!) {\n    updateSurveyOrder(surveyId: $surveyId, orderStatus: $orderStatus)\n  }\n": types.UpdateSurveyOrderDocument,
    "\n  query surveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n": types.SurveyOrderDocument,
    "\n  fragment Project on Project {\n    projectId\n    nickname\n    name\n    description\n    projectType\n    projectMetadata {\n      mrpUrl\n    }\n    accountId\n    creator\n    solution {\n      solutionId\n      name\n      createdDate\n      sinceDate\n    }\n    surveys {\n      surveyId\n      nickname\n      name\n      state\n      fieldworkDates {\n        startDate\n        endDate\n      }\n      sinceDate\n    }\n    createdDate\n    sinceDate\n    status\n  }\n": types.ProjectFragmentDoc,
    "\n  fragment Survey on Survey {\n    surveyId\n    nickname\n    name\n    state\n    createdDate\n    sinceDate\n  }\n": types.SurveyFragmentDoc,
    "\n  mutation project(\n    $name: String!\n    $description: String!\n    $accountId: ID!\n    $solutionId: ID! # $hash: String!\n    $creator: String!\n    $projectType: ProjectType\n  ) {\n    createProject(\n      name: $name\n      description: $description\n      accountId: $accountId\n      solutionId: $solutionId # hash: $hash\n      creator: $creator\n      projectType: $projectType\n    ) {\n      projectId\n      projectType\n      accountId\n      name\n      nickname\n      createdDate\n      sinceDate\n      creator\n      description\n      projectMetadata {\n        mrpUrl\n      }\n      solution {\n        solutionId\n        name\n        createdDate\n        sinceDate\n      }\n      surveys {\n        surveyId\n        createdDate\n        sinceDate\n        fieldworkDates {\n          startDate\n          endDate\n        }\n        name\n        nickname\n        state\n      }\n      status\n    }\n  }\n": types.ProjectDocument,
    "\n  mutation deleteProject($projectId: ID!, $accountId: ID!) {\n    deleteProject(projectId: $projectId, accountId: $accountId)\n  }\n": types.DeleteProjectDocument,
    "\n  mutation updateProject($projectId: ID!, $name: String!) {\n    updateProject(projectId: $projectId, name: $name) {\n      projectId\n    }\n  }\n": types.UpdateProjectDocument,
    "\n  mutation updateSurvey($surveyId: ID!, $state: SurveyState!) {\n    updateSurvey(surveyId: $surveyId, state: $state) {\n      ...Survey\n    }\n  }\n  \n": types.UpdateSurveyDocument,
    "\n  query searchProjects(\n    $accountId: ID!\n    $offset: Int!\n    $limit: Int!\n    $text: String\n    $sorting: [ProjectSortingInput!]!\n    $state: SurveyState\n    $projectTypes: [ProjectType!]\n    $sources: [Source!]\n  ) {\n    searchProjects(\n      accountId: $accountId\n      offset: $offset\n      limit: $limit\n      text: $text\n      sorting: $sorting\n      state: $state\n      projectTypes: $projectTypes\n      sources: $sources\n    ) {\n      header {\n        total\n      }\n      projects {\n        ...Project\n      }\n    }\n  }\n  \n": types.SearchProjectsDocument,
    "\n  query projectById($projectId: ID!) {\n    project: project(projectId: $projectId) {\n      ...Project\n    }\n  }\n  \n": types.ProjectByIdDocument,
    "\n  query projectBySurveyId($surveyId: ID!) {\n    project: projectBySurveyId(surveyId: $surveyId) {\n      ...Project\n    }\n  }\n  \n": types.ProjectBySurveyIdDocument,
    "\n  query projectIdBySurveyId($surveyId: ID!) {\n    projectBySurveyId(surveyId: $surveyId) {\n      projectId\n    }\n  }\n": types.ProjectIdBySurveyIdDocument,
    "\n  query survey($surveyId: ID!) {\n    survey: survey(surveyId: $surveyId) {\n      surveyId\n      name\n      state\n      createdDate\n      sinceDate\n      fieldworkDates {\n        startDate\n        endDate\n      }\n    }\n  }\n": types.SurveyDocument,
    "\n  query importQuestionSearchProjects($accountId: ID!) {\n    searchProjects(\n      accountId: $accountId\n      offset: 0\n      limit: 100\n      sorting: { sortBy: SinceDate, order: Desc }\n    ) {\n      projects {\n        name\n        projectId\n        surveys {\n          surveyId\n        }\n      }\n    }\n  }\n": types.ImportQuestionSearchProjectsDocument,
    "\n  mutation addPanelSupplierOrder(\n    $surveyId: ID!\n    $orderDescription: String!\n    $samplesNeeded: Int!\n  ) {\n    addPanelSupplierOrder(\n      panelSupplierCode: \"cint\"\n      surveyId: $surveyId\n      orderDescription: $orderDescription\n      samplesNeeded: $samplesNeeded\n    ) {\n      orderId\n      panelSupplierCode\n      panelSupplierOrderId {\n        value\n      }\n      status\n      cpi\n      orderNumber\n      createdDate\n      sinceDate\n    }\n  }\n": types.AddPanelSupplierOrderDocument,
};

/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = dashboardGql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function dashboardGql(source: string): unknown;

/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query softLaunchSurveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      panelSupplierOrders {\n        orderId\n        panelSupplierOrderId {\n          value\n        }\n        status\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      isSoftLaunch\n      customerCPI\n    }\n  }\n"): (typeof documents)["\n  query softLaunchSurveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      panelSupplierOrders {\n        orderId\n        panelSupplierOrderId {\n          value\n        }\n        status\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      isSoftLaunch\n      customerCPI\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation createSurveyOrder(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrder(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"): (typeof documents)["\n  mutation createSurveyOrder(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrder(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation createSurveyOrderV2(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrderV2(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"): (typeof documents)["\n  mutation createSurveyOrderV2(\n    $surveyId: ID!\n    $feasibility: Float!\n    $customerCPI: Float!\n    $panelSupplierCode: String!\n    $baseCPI: Float\n  ) {\n    createSurveyOrderV2(\n      panelSupplierCode: $panelSupplierCode\n      surveyId: $surveyId\n      feasibility: $feasibility\n      customerCPI: $customerCPI\n      baseCPI: $baseCPI\n    ) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      panelSupplierOrders {\n        orderId\n        panelSupplierCode\n        panelSupplierOrderId {\n          value\n          idType\n          panelSupplierCode\n        }\n        status\n        cpi\n        orderNumber\n        createdDate\n        sinceDate\n      }\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation updateSurveyOrder($surveyId: ID!, $orderStatus: SurveyOrderStatus!) {\n    updateSurveyOrder(surveyId: $surveyId, orderStatus: $orderStatus)\n  }\n"): (typeof documents)["\n  mutation updateSurveyOrder($surveyId: ID!, $orderStatus: SurveyOrderStatus!) {\n    updateSurveyOrder(surveyId: $surveyId, orderStatus: $orderStatus)\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query surveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"): (typeof documents)["\n  query surveyOrder($surveyId: ID!) {\n    surveyOrder(surveyId: $surveyId) {\n      surveyId\n      customerCPI\n      status\n      feasibility\n      orderType\n      createdDate\n      sinceDate\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  fragment Project on Project {\n    projectId\n    nickname\n    name\n    description\n    projectType\n    projectMetadata {\n      mrpUrl\n    }\n    accountId\n    creator\n    solution {\n      solutionId\n      name\n      createdDate\n      sinceDate\n    }\n    surveys {\n      surveyId\n      nickname\n      name\n      state\n      fieldworkDates {\n        startDate\n        endDate\n      }\n      sinceDate\n    }\n    createdDate\n    sinceDate\n    status\n  }\n"): (typeof documents)["\n  fragment Project on Project {\n    projectId\n    nickname\n    name\n    description\n    projectType\n    projectMetadata {\n      mrpUrl\n    }\n    accountId\n    creator\n    solution {\n      solutionId\n      name\n      createdDate\n      sinceDate\n    }\n    surveys {\n      surveyId\n      nickname\n      name\n      state\n      fieldworkDates {\n        startDate\n        endDate\n      }\n      sinceDate\n    }\n    createdDate\n    sinceDate\n    status\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  fragment Survey on Survey {\n    surveyId\n    nickname\n    name\n    state\n    createdDate\n    sinceDate\n  }\n"): (typeof documents)["\n  fragment Survey on Survey {\n    surveyId\n    nickname\n    name\n    state\n    createdDate\n    sinceDate\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation project(\n    $name: String!\n    $description: String!\n    $accountId: ID!\n    $solutionId: ID! # $hash: String!\n    $creator: String!\n    $projectType: ProjectType\n  ) {\n    createProject(\n      name: $name\n      description: $description\n      accountId: $accountId\n      solutionId: $solutionId # hash: $hash\n      creator: $creator\n      projectType: $projectType\n    ) {\n      projectId\n      projectType\n      accountId\n      name\n      nickname\n      createdDate\n      sinceDate\n      creator\n      description\n      projectMetadata {\n        mrpUrl\n      }\n      solution {\n        solutionId\n        name\n        createdDate\n        sinceDate\n      }\n      surveys {\n        surveyId\n        createdDate\n        sinceDate\n        fieldworkDates {\n          startDate\n          endDate\n        }\n        name\n        nickname\n        state\n      }\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation project(\n    $name: String!\n    $description: String!\n    $accountId: ID!\n    $solutionId: ID! # $hash: String!\n    $creator: String!\n    $projectType: ProjectType\n  ) {\n    createProject(\n      name: $name\n      description: $description\n      accountId: $accountId\n      solutionId: $solutionId # hash: $hash\n      creator: $creator\n      projectType: $projectType\n    ) {\n      projectId\n      projectType\n      accountId\n      name\n      nickname\n      createdDate\n      sinceDate\n      creator\n      description\n      projectMetadata {\n        mrpUrl\n      }\n      solution {\n        solutionId\n        name\n        createdDate\n        sinceDate\n      }\n      surveys {\n        surveyId\n        createdDate\n        sinceDate\n        fieldworkDates {\n          startDate\n          endDate\n        }\n        name\n        nickname\n        state\n      }\n      status\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation deleteProject($projectId: ID!, $accountId: ID!) {\n    deleteProject(projectId: $projectId, accountId: $accountId)\n  }\n"): (typeof documents)["\n  mutation deleteProject($projectId: ID!, $accountId: ID!) {\n    deleteProject(projectId: $projectId, accountId: $accountId)\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation updateProject($projectId: ID!, $name: String!) {\n    updateProject(projectId: $projectId, name: $name) {\n      projectId\n    }\n  }\n"): (typeof documents)["\n  mutation updateProject($projectId: ID!, $name: String!) {\n    updateProject(projectId: $projectId, name: $name) {\n      projectId\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation updateSurvey($surveyId: ID!, $state: SurveyState!) {\n    updateSurvey(surveyId: $surveyId, state: $state) {\n      ...Survey\n    }\n  }\n  \n"): (typeof documents)["\n  mutation updateSurvey($surveyId: ID!, $state: SurveyState!) {\n    updateSurvey(surveyId: $surveyId, state: $state) {\n      ...Survey\n    }\n  }\n  \n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query searchProjects(\n    $accountId: ID!\n    $offset: Int!\n    $limit: Int!\n    $text: String\n    $sorting: [ProjectSortingInput!]!\n    $state: SurveyState\n    $projectTypes: [ProjectType!]\n    $sources: [Source!]\n  ) {\n    searchProjects(\n      accountId: $accountId\n      offset: $offset\n      limit: $limit\n      text: $text\n      sorting: $sorting\n      state: $state\n      projectTypes: $projectTypes\n      sources: $sources\n    ) {\n      header {\n        total\n      }\n      projects {\n        ...Project\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query searchProjects(\n    $accountId: ID!\n    $offset: Int!\n    $limit: Int!\n    $text: String\n    $sorting: [ProjectSortingInput!]!\n    $state: SurveyState\n    $projectTypes: [ProjectType!]\n    $sources: [Source!]\n  ) {\n    searchProjects(\n      accountId: $accountId\n      offset: $offset\n      limit: $limit\n      text: $text\n      sorting: $sorting\n      state: $state\n      projectTypes: $projectTypes\n      sources: $sources\n    ) {\n      header {\n        total\n      }\n      projects {\n        ...Project\n      }\n    }\n  }\n  \n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query projectById($projectId: ID!) {\n    project: project(projectId: $projectId) {\n      ...Project\n    }\n  }\n  \n"): (typeof documents)["\n  query projectById($projectId: ID!) {\n    project: project(projectId: $projectId) {\n      ...Project\n    }\n  }\n  \n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query projectBySurveyId($surveyId: ID!) {\n    project: projectBySurveyId(surveyId: $surveyId) {\n      ...Project\n    }\n  }\n  \n"): (typeof documents)["\n  query projectBySurveyId($surveyId: ID!) {\n    project: projectBySurveyId(surveyId: $surveyId) {\n      ...Project\n    }\n  }\n  \n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query projectIdBySurveyId($surveyId: ID!) {\n    projectBySurveyId(surveyId: $surveyId) {\n      projectId\n    }\n  }\n"): (typeof documents)["\n  query projectIdBySurveyId($surveyId: ID!) {\n    projectBySurveyId(surveyId: $surveyId) {\n      projectId\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query survey($surveyId: ID!) {\n    survey: survey(surveyId: $surveyId) {\n      surveyId\n      name\n      state\n      createdDate\n      sinceDate\n      fieldworkDates {\n        startDate\n        endDate\n      }\n    }\n  }\n"): (typeof documents)["\n  query survey($surveyId: ID!) {\n    survey: survey(surveyId: $surveyId) {\n      surveyId\n      name\n      state\n      createdDate\n      sinceDate\n      fieldworkDates {\n        startDate\n        endDate\n      }\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  query importQuestionSearchProjects($accountId: ID!) {\n    searchProjects(\n      accountId: $accountId\n      offset: 0\n      limit: 100\n      sorting: { sortBy: SinceDate, order: Desc }\n    ) {\n      projects {\n        name\n        projectId\n        surveys {\n          surveyId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query importQuestionSearchProjects($accountId: ID!) {\n    searchProjects(\n      accountId: $accountId\n      offset: 0\n      limit: 100\n      sorting: { sortBy: SinceDate, order: Desc }\n    ) {\n      projects {\n        name\n        projectId\n        surveys {\n          surveyId\n        }\n      }\n    }\n  }\n"];
/**
 * The dashboardGql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function dashboardGql(source: "\n  mutation addPanelSupplierOrder(\n    $surveyId: ID!\n    $orderDescription: String!\n    $samplesNeeded: Int!\n  ) {\n    addPanelSupplierOrder(\n      panelSupplierCode: \"cint\"\n      surveyId: $surveyId\n      orderDescription: $orderDescription\n      samplesNeeded: $samplesNeeded\n    ) {\n      orderId\n      panelSupplierCode\n      panelSupplierOrderId {\n        value\n      }\n      status\n      cpi\n      orderNumber\n      createdDate\n      sinceDate\n    }\n  }\n"): (typeof documents)["\n  mutation addPanelSupplierOrder(\n    $surveyId: ID!\n    $orderDescription: String!\n    $samplesNeeded: Int!\n  ) {\n    addPanelSupplierOrder(\n      panelSupplierCode: \"cint\"\n      surveyId: $surveyId\n      orderDescription: $orderDescription\n      samplesNeeded: $samplesNeeded\n    ) {\n      orderId\n      panelSupplierCode\n      panelSupplierOrderId {\n        value\n      }\n      status\n      cpi\n      orderNumber\n      createdDate\n      sinceDate\n    }\n  }\n"];

export function dashboardGql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;