const results = {
  ask_ai: {
    assistant_id: 'asst_aiOrkpBhSe408FhOjjV2fBFp',
    number_of_completes: 500,
    datetime_created: ''
  },
  research_goal: {
    headline:
      'How can Waitrose encourage more customers to try meat-free products?',
    description:
      "We conducted 500 interviews and explored consumer purchase habits, motivations and barriers to increasing consumption of meat-free products.\n\nRecommendations for Waitrose:\n\n- Focus communications on the 'naturalness' of ingredients to avoid consumer perceptions of ultra-processed foods\n- Emphasise the quality and integrity of sourcing\n- Address misconceptions around the high price of meat-free products\n- Expand marketing of meatless products for special occasions\n\nExplore the data below to uncover more detail around consumer attitudes and sentiments towards a meat-free diet.",
    chart_data: {
      title: 'Perceptions of meat-free products',
      xLabel: 'Theme',
      yLabel: 'Percentage (%)',
      stacked: true,
      labels: [
        'Taste and Texture',
        'Cost of Products',
        'Availability and Quality',
        'Marketing and Packaging',
        'Other'
      ],
      datasets: [
        {
          label: 'Negative',
          data: [20, 16, 8, 2, 2]
        },
        {
          label: 'Neutral',
          data: [12, 5, 2, 2, 6]
        },
        {
          label: 'Positive',
          data: [8, 5, 3, 5, 4]
        }
      ]
    }
  },
  research_questions: [
    {
      id: 'research-question-1',
      text: 'When and on what occasions do consumers buy and eat meat-free products?',
      headline:
        'Meat-free products can bring the whole household together during special occasions',
      short_description:
        'There is a large proportion of responders who rarely or never buy or eat meat-free (55%). The biggest identified opportunity for Waitrose in this space is related to ‘special occasions’ which was linked to 12% of purchases. During these special occasions, respondents previously felt left out and unwelcome due to their different diet but many spoke of how meat-free products had transformed these occasions to something more positive. This was especially prevalent in older (30%) and younger veggies (20%).\n\nBy re-positioning meat-free products as a key part of special occasions, Waitrose could see an increase in adoption of meat-free products.',
      long_description:
        'There is a large proportion of responders who rarely or never buy or eat meat-free (55%). The biggest identified opportunity for Waitrose in this space is related to ‘special occasions’ which was linked to 12% of purchases. During these special occasions, respondents previously felt left out and unwelcome due to their different diet but many spoke of how meat-free products had transformed these occasions to something more positive. This was especially prevalent in older (30%) and younger veggies (20%).\n\nBy re-positioning meat-free products as a key part of special occasions, Waitrose could see an increase in adoption of meat-free products.',
      chart_data: {
        title: 'Meat-free purchase habits',
        xLabel: 'Frequency of purchase',
        yLabel: 'Percentage (%)',
        labels: [
          'Daily Purchase',
          'At Least Once a Week',
          'When On Offer',
          'Special Occasions',
          'Rarely or Never'
        ],
        datasets: [
          {
            label: 'Percent',
            data: [21, 23, 29, 12, 55]
          }
        ]
      }
    },
    {
      id: 'research-question-2',
      text: 'What is motivating consumers to buy and eat meat-free products?',
      headline:
        "Consumers look to meat-free for health reasons but many avoid it as they think it's 'ultra-processed'",
      short_description:
        'Different Waitrose customers had different motivations for buying and eating meat-free products. The most common motivation was health and weight loss goals (37%). A significant proportion of customers buy for ethical and environmental reasons (22%; 15%).\n\n20% of consumers are also motivated by the need to satisfy mixed preferences in their households.This group expressed a range of negative experiences and challenges when trying to accommodate the needs of others (53%). Special recipes aimed at combo households and meals could satisfy all preferences. Waitrose could focus communications on “having the best of both worlds”.',
      long_description:
        'Different Waitrose customers had different motivations for buying and eating meat-free products. The most common motivation was health and weight loss goals (37%). A significant proportion of customers buy for ethical and environmental reasons (22%; 15%).\n\n20% of consumers are also motivated by the need to satisfy mixed preferences in their households.This group expressed a range of negative experiences and challenges when trying to accommodate the needs of others (53%). Special recipes aimed at combo households and meals could satisfy all preferences. Waitrose could focus communications on “having the best of both worlds”.',
      chart_data: {
        title: 'Main motivations for buying meat-free',
        xLabel: 'Motivations',
        yLabel: 'Percentage (%)',
        stacked: true,
        labels: [
          'Health and Weight Loss Goals',
          'Ethical Concerns',
          'Environmental Factors',
          'Experimenting with Choices',
          'Accommodating for Others'
        ],
        datasets: [
          {
            label: 'Positive',
            data: [42, 30, 23, 11, 15]
          },
          {
            label: 'Neutral',
            data: [14, 5, 4, 0, 2]
          },
          {
            label: 'Negative',
            data: [10, 4, 0, 0, 19]
          }
        ]
      }
    },
    {
      id: 'research-question-3',
      text: 'What is preventing consumers from purchasing more meat-free products?',
      headline:
        'Taste of meat-free food is biggest barrier for young non-veggies',
      short_description:
        'We identified 3 main barriers to consuming more meat-free products. 53% of consumers perceive taste and texture of meat-free products negatively. Younger non-veggies (71%) had the most negative perceptions.\n\nCost of meat substitutes were seen as a barrier by all groups (43%). 30% of consumers overestimated the cost of these products, suggesting that there is a big gap between cost perception and actual cost.\n\nPerceptions to do with product quality is another significant barrier to purchase (45%). Customers frequently viewed meat-free food as over processed and unhealthy.',
      long_description:
        'We identified 3 main barriers to consuming more meat-free products. 53% of consumers perceive taste and texture of meat-free products negatively. Younger non-veggies (71%) had the most negative perceptions.\n\nCost of meat substitutes were seen as a barrier by all groups (43%). 30% of consumers overestimated the cost of these products, suggesting that there is a big gap between cost perception and actual cost.\n\nPerceptions to do with product quality is another significant barrier to purchase (45%). Customers frequently viewed meat-free food as over processed and unhealthy.\n\nRecommendations:\n\n1. Focus communications on the ‘naturalness’ of ingredients to avoid perceptions of ultra-processed foods\n2. Emphasise the quality and integrity of sourcing\n3. Address misconceptions around the high price of meat-free products',
      chart_data: {
        title:
          'Perceptions of meat-free taste and texture according to segment',
        xLabel: 'Audience segment (age and diet)',
        yLabel: 'Percentage (%)',
        stacked: true,
        labels: [
          'Younger Non Veggies',
          'Younger Veggies',
          'Older Non Veggies',
          'Older Veggies'
        ],
        datasets: [
          {
            label: 'Positive',
            data: [21.4, 71.4, 25.9, 44.4]
          },
          {
            label: 'Neutral',
            data: [7.1, 14.3, 29.6, 22.2]
          },
          {
            label: 'Negative',
            data: [71.4, 14.3, 44.4, 33.3]
          }
        ]
      },
      themes: [
        {
          name: 'Taste and Texture',
          description:
            'Concerns around taste and texture of overly proccessed food',
          verbatims: [
            {
              respondent_id: 'user-1',
              respondent_info: 'Male, 19, Younger non-Veggie',
              quote:
                'Ive had quorn, and meat free sausages. I like both of these and would eat again, but they are more expensive, and I worry about eating too much processed food.',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-2',
              respondent_info: 'Female, 42, Older non-Veggie',
              quote:
                'the taste was often bitter and tasted over processed and unnatural. The textures were bitty and rubbery',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-3',
              respondent_info: 'Male, 34, non-adopters',
              quote:
                'think it has to be the Beyond Meat burger - it was recommended, It was horrible. It felt rubbery in the mouth and was very mushroomy. It felt hyper processed. It was expensive. I think I have two lurking in the freezer waiting to be thrown out.',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-4',
              respondent_info: 'Male, 58, Older non veggie',
              quote:
                'I think I tried the Impossible burger once at a restaurant. I think the texture was okay but it looked quite obviously processed. It was visually a good impression of a meat burger but was lacking a little in flavour.',
              message_id: 'message-10'
            },
            {
              respondent_id: 'user-5',
              respondent_info: 'Male, 23, Younger non veggie',
              quote:
                "It tends to have a very artificial taste that doesnt really appeal to me. The texture isn't the same as real bacon either. I'm not sure i would really want to eat a good imitation of real bacon as its quite chewy and really makes me feel like I'm eating flesh.",
              message_id: 'message-1'
            }
          ]
        },
        {
          name: 'Cost',
          description:
            'High perceived cost is putting consumers off purchasing',
          verbatims: [
            {
              respondent_id: 'user-6',
              respondent_info: 'Female, 18-25, Younger non-Veggie',
              quote:
                "I don't know why the cost is so high for meat substututes. It often feels double like double the cost of similar priced meat produts. I don't get it.",
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-7',
              respondent_info: 'Female, 36, older,non-adopter',
              quote:
                "I found some meat-free sausages recently in the reduced section of the supermarket. I hadn't seem them before and probably wouldn't have paid full price, but as they were reduced I bought two packets and they were liked by the whole family. I would definitely buy them again if they were on offer.",
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-8',
              respondent_info: 'Female, 39, Older, non-veggie',
              quote:
                'There is definitely a price difference, some plant based are more expensive than say your standard pack of chicken breast, where as they may be cheaper in others. I also would like to be able to see a bigger variety of meat free or plant based fresh foods. I go into a supermarket and go to a meat aisle and generally you will struggle to find anything that is plant based/meat free.  As a family we do tend to eat more chicken and mince and that is a struggle to find anything cost wise to match.',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-9',
              respondent_info: 'female, 35, Older non veggie',
              quote:
                'the cost - it annoys me that its either the same price or more than the meat counterpart - even though it surely costs more the breed, raise, feed & kill the animal. never mind the cost to the planet. the plastic packaging also annoys me. or some shops not having a big enough stock of items.',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-10',
              respondent_info: 'Female, 33, older veggie',
              quote:
                'My husband and my parents also buy plant based products. They feel that packaging can sometimes be confusing too and also that plant based products are so much more expensive than meat products.',
              message_id: 'message-1'
            }
          ]
        },
        {
          name: 'Product Quality',
          description: 'Meat substitutes viewed as unhealhty ',
          verbatims: [
            {
              respondent_id: 'user-11',
              respondent_info: 'Male, 21, Younger non-Veggie',
              quote:
                'Lots of plant based products are overly processed and therefore the overall nutrition if the food available can be negatively impacted. I like to go back to whole foods where possible for health and weight reasons and don’t want to undo the health benefits of a plant based diet by eating lots of processed/chemical based foods..',
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-12',
              respondent_info: 'Male, 55, Older Veggie',
              quote:
                "I find it really annoying that a lot of plant based food tries to replicate meat. It is less healthy as it is really processed, and it doesn't taste as good as things like beans and lentils.",
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-13',
              respondent_info: 'Male, 43, Older Veggie',
              quote:
                "I really like them but try not to eat too many ready meals these days as they are quite processed. I think tofu is a healthier option but sometimes it's nice to have vegan sausages for breakfast or a burger for lunch.",
              message_id: 'message-1'
            },
            {
              respondent_id: 'user-14',
              respondent_info: 'Male, 43, Older Veggie',
              quote:
                "I really like them but try not to eat too many ready meals these days as they are quite processed. I think tofu is a healthier option but sometimes it's nice to have vegan sausages for breakfast or a burger for lunch.",
              message_id: 'message-1'
            }
          ]
        }
      ]
    }
  ],
  transcripts: [
    {
      respondent_id: 'user-4',
      respondent_info: 'Male, 58, Older non veggie',
      date: '5 August 2023',
      messages: [
        {
          id: 'message-1',
          name: 'Interviewer',
          time: '16:28:07',
          message:
            "Great, let's get started! 😊\n\nI’d like to talk to you about meat-free products. Can you tell me about the last time you had a plant-based or meat-free meal?"
        },
        {
          id: 'message-2',
          name: '33, Male, London England',
          time: '16:28:42',
          message:
            'I had a halloumi burger on Wednesday that i made at home with gochujang and honey. I also used mayonaisse, so meat-free but not plant based.'
        },
        {
          id: 'message-3',
          name: 'Interviewer',
          time: '16:28:47',
          message:
            'That sounds delicious! 😋 What made you decide to prepare a halloumi burger instead of a traditional meat burger?'
        },
        {
          id: 'message-4',
          name: '33, Male, London England',
          time: '16:29:29',
          message:
            'The texture is different and the flavour is interesting. We eat a lot of meat so it was good to mix it up a bit.'
        },
        {
          id: 'message-5',
          name: 'Interviewer',
          time: '16:29:35',
          message:
            'Interesting! Can you tell me more about how the texture and flavour of the halloumi burger compared to a traditional meat burger? Did it satisfy your craving in the same way?'
        },
        {
          id: 'message-6',
          name: '33, Male, London England',
          time: '16:30:23',
          message:
            'Halloumi is as satisfying as meat in that they have a similar density. I think given that the mix of sweet-salty-spicy between the honey/gochujang/halloumi meant i didnt miss having meat on this occasion.'
        },
        {
          id: 'message-7',
          name: 'Interviewer',
          time: '16:30:28',
          message:
            "That's a great insight! 😊 So, it seems like the combination of flavours played a big role in your satisfaction. Have you ever tried a meat-free alternative that's specifically designed to mimic the taste and texture of meat?"
        },
        {
          id: 'message-8',
          name: '33, Male, London England',
          time: '16:31:06',
          message: 'Yes i have'
        },
        {
          id: 'message-9',
          name: 'Interviewer',
          time: '16:31:11',
          message:
            'Great! Can you share your experience with that? How did you find the taste or texture of the meat-free alternative? What did you enjoy or not enjoy about it?'
        },
        {
          id: 'message-10',
          name: '33, Male, London England',
          time: '16:32:28',
          message:
            'I think I tried the Impossible burger once at a restaurant. I think the texture was okay but it looked quite obviously processed. It was visually a good impression of a meat burger but was lacking a little in flavour.'
        },
        {
          id: 'message-11',
          name: 'Interviewer',
          time: '16:32:35',
          message:
            'Interesting! 😊 When you say it was lacking in flavour, could you elaborate a bit more? Was it missing a certain taste you associate with meat or was it just bland overall?'
        },
        {
          id: 'message-12',
          name: '33, Male, London England',
          time: '16:33:37',
          message:
            "I would say just a little bland and maybe artificial-tasting? A bit like how bacon flavour crisps don't really taste like bacon."
        },
        {
          id: 'message-13',
          name: 'Interviewer',
          time: '16:33:41',
          message:
            "I see, so the taste didn't quite hit the mark for you. 🤔 Have you had any other bad experiences with plant-based products in the past? Or was this the only one?"
        },
        {
          id: 'message-14',
          name: '33, Male, London England',
          time: '16:34:11',
          message:
            'I prefer having a vegetarian meal to having a meat substitute. I can do great things with Tofu and halloumi and paneer that make things more interesting and less disappointing than a meat alternative.'
        },
        {
          id: 'message-15',
          name: 'Interviewer',
          time: '16:34:17',
          message:
            "That's a great perspective! 😊 So, it seems like you prefer using ingredients that are naturally vegetarian and can stand on their own, rather than substitutes trying to mimic meat. Is the nutrition content for your own health an important factor in which plant-based product you choose?"
        },
        {
          id: 'message-16',
          name: '33, Male, London England',
          time: '16:34:58',
          message:
            'Not really. I have a very unhealthy diet and lifestyle so i dont pay much attention apart from calories.'
        },
        {
          id: 'message-17',
          name: 'Interviewer',
          time: '16:35:03',
          message:
            "Got it! 😊 So, it's more about the taste and texture for you rather than the nutritional content. Have you ever bought a meat-free alternative for a special occasion, like a barbecue or family dinner?"
        },
        {
          id: 'message-18',
          name: '33, Male, London England',
          time: '16:35:38',
          message:
            "I bought vegan sausages (i think impossible again) for a friend at our barbecue but they cancelled so i ended up eating them. I found them flavourless, texture was weird and they didn't cook in the same way, so even though they had grill marks they still looked a bit insipid and pale."
        },
        {
          id: 'message-19',
          name: 'Interviewer',
          time: '16:35:44',
          message:
            'Ah, I see. 😕 So, the visual aspect and the cooking process also play a role in your overall experience with meat-free alternatives. Do you know other people who regularly buy plant-based products? And if so, what are their experiences?'
        },
        {
          id: 'message-10',
          name: '33, Male, London England',
          time: '16:36:33',
          message: 'No one in particular'
        },
        {
          id: 'message-11',
          name: 'Interviewer',
          time: '16:36:38',
          message:
            'Alright, no worries! 😊 Just one last question: Is having meat-free products an important consideration for your family’s health?'
        },
        {
          id: 'message-12',
          name: '33, Male, London England',
          time: '16:37:07',
          message:
            'Not particularly. I raise my daughter with a varied diet but considering meat and fish as an essential component of her diet nonetheless.'
        },
        {
          id: 'message-13',
          name: 'Interviewer',
          time: '16:37:11',
          message:
            "That's fair! 😊 It's all about balance and variety, isn't it? Thank you so much for sharing your experiences and thoughts on this topic. It's been really insightful!"
        },
        {
          id: 'message-14',
          name: '33, Male, London England',
          time: '16:37:45',
          message: 'Thanks'
        }
      ]
    }
  ]
}

export default results
