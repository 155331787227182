import { useApolloClient } from '@apollo/client'
import { Document, Packer } from 'docx'
import { saveAs } from 'file-saver'
import { LogAmplitudeEvent } from '../amplitude'
import { EventType } from '../amplitude/eventType'
import {
  QUESTIONNAIRE,
  QuestionnaireData
} from '../data/gql/questionnaire/queries'
import { GET_FIELDWORK } from '../data/gql/questionnaire/queries/fieldwork'
import { GET_COUNTRIES_V2 } from '../data/gql/questionnaire/queries/getCountries'
import { DraftQuestionnaireEntry } from '../data/model/questionnaire'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData
} from '../modules/Project/Project.query'
import { ResponseOptionsByQuestion } from '../modules/Questionnaire/Questionnaire.slice'
import {
  flattenAllEntries,
  flattenEntries,
  getResponseOptionsByQuestion
} from '../modules/Questionnaire/Questionnaire.utils'
import { createPreviewUrl } from '../utils/HelperFunctions'
import { sortBy } from '../utils/array/sortBy'
import {
  createQuestions,
  createQuestionsBehaviorSummary,
  createSummary
} from '../utils/surveyDocBuilder'
import { useProjectId } from './useProjectId'
import { useSurveyId } from './useSurveyId'

const DEFULT_COUNTRY_NAME = 'UK'

interface ExportSurveyParams {
  projectName: string
  surveyId: string
  countryName: string
  previewUrl: string
  questionnaireEntries: DraftQuestionnaireEntry[]
  questionnaireAudienceEntries: DraftQuestionnaireEntry[]
  responseOptionsByQuestion: ResponseOptionsByQuestion
}
const exportSurvey = async ({
  projectName,
  surveyId,
  countryName,
  previewUrl,
  questionnaireEntries,
  questionnaireAudienceEntries,
  responseOptionsByQuestion
}: ExportSurveyParams) => {
  const pageContent = [
    ...(await createSummary({ projectName, surveyId, countryName, previewUrl }))
  ]

  const sortedEntries: DraftQuestionnaireEntry[] = sortBy(
    questionnaireEntries,
    { key: 'position' }
  )

  const sortedAudienceEntries: DraftQuestionnaireEntry[] = sortBy(
    questionnaireAudienceEntries,
    { key: 'position' }
  )

  const audienceQuestionContent = await createQuestions({
    audienceEntries: sortedAudienceEntries,
    responseOptionsByQuestion
  })
  pageContent.push(...audienceQuestionContent)

  const questionContent = await createQuestions({
    entries: sortedEntries,
    audienceEntries: sortedAudienceEntries,
    responseOptionsByQuestion
  })
  pageContent.push(...questionContent)

  pageContent.push(
    ...createQuestionsBehaviorSummary(flattenEntries(sortedEntries))
  )

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Heading2',
          name: 'Heading 2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 22,
            font: 'Arial',
            bold: true
          }
        }
      ],
      default: {
        document: {
          run: {
            font: 'Arial',
            size: 22
          }
        }
      }
    },
    sections: [
      {
        children: pageContent
      }
    ]
  })
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${projectName}.docx`)
  })
}

const useDownloadSurveyDoc = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const client = useApolloClient()

  return {
    exportSurvey: async () => {
      LogAmplitudeEvent(EventType.ExportedSurvey, { surveyId })

      const { data: dataProject } = await client.query<ProjectData>({
        context: { clientName: 'dashboard' },
        query: PROJECT_BY_SURVEY_ID,
        variables: {
          surveyId
        }
      })

      const projectName = dataProject.project.name
      let questionnaireEntries = [] as DraftQuestionnaireEntry[]
      let questionnaireAudienceEntries = [] as DraftQuestionnaireEntry[]
      let countryName = DEFULT_COUNTRY_NAME

      // try to get country name
      try {
        const { data: fieldworkData } = await client.query({
          context: { clientName: 'questionnaire' },
          query: GET_FIELDWORK,
          variables: {
            surveyId
          }
        })

        const fieldwork = fieldworkData.fieldwork

        if (fieldwork) {
          const { data: dataCountries } = await client.query({
            context: { clientName: 'questionnaire' },
            query: GET_COUNTRIES_V2
          })

          countryName =
            dataCountries.getCountriesV2?.find((country) => {
              return country.countryCode === fieldwork.locale.country
            })?.name ?? DEFULT_COUNTRY_NAME
        }
      } catch (e) {
        // leave countryName unchanged
      }

      let responseOptionsByQuestion: ResponseOptionsByQuestion = {}

      // try to get questionnaire entries
      try {
        const { data: questionnaireData } =
          await client.query<QuestionnaireData>({
            context: { clientName: 'questionnaire' },
            query: QUESTIONNAIRE,
            variables: {
              projectId,
              surveyId
            },
            fetchPolicy: 'no-cache'
          })

        const { draftQuestionnaire } = questionnaireData

        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (draftQuestionnaire) {
          responseOptionsByQuestion = getResponseOptionsByQuestion(
            flattenAllEntries(draftQuestionnaire)
          )
          questionnaireEntries = draftQuestionnaire.entries
          questionnaireAudienceEntries = draftQuestionnaire.audienceEntries
        }
      } catch (e) {
        // leave questionnaireEntries unchanged
      }

      exportSurvey({
        projectName,
        surveyId,
        countryName,
        previewUrl: createPreviewUrl(surveyId),
        questionnaireEntries,
        questionnaireAudienceEntries,
        responseOptionsByQuestion
      })
    }
  }
}

export default useDownloadSurveyDoc
