import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LoopBasis } from '../../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../../data/model/questionnaire'
import { useLoopingSelectedEntry } from '../Looping.hooks'
import { LoopBasisSelectorOption } from '../Looping.model'
import {
  loopBasisSet,
  selectLoopBasis,
  selectStaticLoopingEnabled
} from '../Looping.slice'
import LoopBasisSelectorComponent from './LoopBasisSelector.component'

const loopBasisSelectorOptions: LoopBasisSelectorOption[] = [
  {
    id: '1',
    name: 'Random selection',
    value: LoopBasis.Random
  },
  {
    id: '2',
    name: 'Least filled',
    value: LoopBasis.LeastFilled
  }
]

const LoopBasisSelector = () => {
  const dispatch = useAppDispatch()
  const loopBasis = useAppSelector(selectLoopBasis)
  const selectedLoopingSourceType = useLoopingSelectedEntry()?.entryType
  const staticLoopingEnabled = useAppSelector(selectStaticLoopingEnabled)

  const filteredLoopBasicSelectorOptions = staticLoopingEnabled
    ? loopBasisSelectorOptions.filter(
        (option) => option.value !== 'LeastFilled'
      )
    : loopBasisSelectorOptions

  const handleChangeLoopBasis = (newLoopBasis: LoopBasis) => {
    dispatch(loopBasisSet(newLoopBasis))
  }

  const isValid =
    selectedLoopingSourceType !== EntryType.QuestionEntryType ||
    loopBasis !== 'QuotaTarget'

  return (
    <LoopBasisSelectorComponent
      isValid={isValid}
      loopBasis={loopBasis}
      loopBasisSelectorOptions={filteredLoopBasicSelectorOptions}
      onChangeLoopBasis={handleChangeLoopBasis}
    />
  )
}

export default LoopBasisSelector
