import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const UPDATE_QUESTIONNAIRE_ENTRY = questionnaireGql(/* GraphQL */ `
  mutation updateQuestionnaireEntryV2(
    $input: UpdateQuestionnaireEntryV2Input!
  ) {
    updateQuestionnaireEntryV2(input: $input) {
      ...DraftQuestionnaireEntry
    }
  }
`)
