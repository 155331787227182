import { DraftMaskingRule } from '../data/gql-gen/questionnaire/graphql'
import {
  DraftLogicClauseProposition,
  DraftQuestionnaireEntry,
  EntryType
} from '../data/model/questionnaire'
import { ResponseOptionsByQuestion } from '../modules/Questionnaire/Questionnaire.slice'

const hasMaskingRulesBasedOnId = (
  maskingRules: DraftMaskingRule[],
  id: string
) =>
  maskingRules
    .flatMap(({ maskingKeys }) => maskingKeys)
    .some(
      ({ questionLk, responseOptionLk, matrixTitleLk }) =>
        questionLk === id || responseOptionLk === id || matrixTitleLk === id
    )

const hasQuestionLogicBasedOnId = (
  questionLogic: DraftLogicClauseProposition[][],
  id: string
) => {
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return questionLogic?.flat()?.some((rule) => {
    const { questionLk, responseOptionLk, matrixTitleLk } =
      rule.proposition as {
        questionLk: string
        responseOptionLk: string
        matrixTitleLk?: string
      }
    return questionLk === id || responseOptionLk === id || matrixTitleLk === id
  })
}

export const getEntriesWithRuleBasedOnId = (
  id: string,
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): string[] => {
  return entries
    .filter((entry) => {
      if (
        entry.entryType === EntryType.ForkEntryType ||
        entry.entryType === EntryType.SectionEntryType
      ) {
        return false
      }
      if (hasQuestionLogicBasedOnId(entry.entryItem.questionLogic, id)) {
        return true
      }

      const hasResponsesWithDisplayLogicBasedOn = responseOptionsByQuestion[
        entry.entryId
      ] // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ?.some(({ maskingRules }) => hasMaskingRulesBasedOnId(maskingRules, id))

      if (hasResponsesWithDisplayLogicBasedOn) {
        return true
      }

      if (entry.entryType === EntryType.MatrixEntryType) {
        return entry.entryItem.matrixRows.some(({ maskingRules }) =>
          hasMaskingRulesBasedOnId(maskingRules, id)
        )
      }
    })
    .map(({ entryId }) => entryId)
}
