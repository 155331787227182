export const MULTI_SELECT_MAXIMUM_SELECTED_OPTION = 1
export const MULTI_SELECT_RANGE_OPTION = 2
export const RESPONSE_OPTIONS_LIMIT_RESET_OPTION = 0
export const MULTI_SELECT_OPTION = 'Multi-select option'
export const MULTI_SELECT_MAXIMUM_SELECTED = 'Maximum selected'
export const MAX_GREATHER_THAT_RO_COUNT_ERROR =
  'Maximum cannot exceed the total number of response options.'
export const MAX_CANNOT_BE_ZERO_ERROR = 'Maximum cannot be 0'
export const INVALID_RANGE_ERROR = 'Minimum cannot exceed maximum'
export const RANK_CHOICE_REQUISITE_SELECTOR = 'Rank Choice Requisite'

export const multiSelectRangeOptions = [
  {
    id: '1',
    name: 'Maximum selected',
    value: MULTI_SELECT_MAXIMUM_SELECTED_OPTION
  },
  {
    id: '2',
    name: 'Response range',
    value: MULTI_SELECT_RANGE_OPTION
  }
]

export const defaultOption = {
  id: '0',
  name: 'No limit',
  value: 0
}

export const defaultRankChoiceRequisiteOption = {
  id: '0',
  name: 'All ranked',
  value: 0
}
