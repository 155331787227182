import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  root: {
    marginLeft: `-${theme.spacing(1.375)}`
  },
  buttonPaddingRight: {
    paddingRight: theme.spacing(2)
  }
})

export default useStyles
