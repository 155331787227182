import Skeleton from '@mui/material/Skeleton'
import dayjs from 'dayjs'
import { useGetSurveyOrder } from '../../../../components/SoftLaunch'
import { useFdChatFieldwork } from '../../../../containers/Page/FdChat/FdChat.hooks'
import { USER_MAX_LENGTH_OF_INTERVIEW } from '../../../../containers/Page/FdChat/FdChat.model'
import { useFdChatProgress } from '../../FdChatProgressProvider.context'
import styles from './ProjectCost.module.css'

const ProjectCost = () => {
  const { interviewsWanted, loading: loadingProgress } = useFdChatProgress()
  const { fieldwork, fieldworkLoading } = useFdChatFieldwork()
  const { surveyOrderData, surveyOrderLoading } = useGetSurveyOrder()

  return (
    <div>
      <h2 className={styles.title}>Project cost</h2>
      <ul className={styles.list}>
        <li className={styles['list-item']}>
          <span>Completes</span>
          <span>
            {loadingProgress ? (
              <Skeleton variant="text" width={50} />
            ) : (
              interviewsWanted
            )}
          </span>
        </li>
        <li className={styles['list-item']}>
          <span>Cost per interview</span>
          <span>
            {surveyOrderLoading ? (
              <Skeleton variant="text" width={25} />
            ) : (
              `£${surveyOrderData?.surveyOrder.customerCPI.toLocaleString(
                'en-GB'
              )}`
            )}
          </span>
        </li>
        <li className={styles['list-item']}>
          <span>Length of interview</span>
          <span>{USER_MAX_LENGTH_OF_INTERVIEW} mins</span>
        </li>
        <li className={styles['list-item']}>
          <span>Fieldwork</span>
          <span>
            {fieldworkLoading ? (
              <Skeleton variant="text" width={50} />
            ) : (
              `${dayjs(fieldwork?.duration.endDate).diff(
                fieldwork?.duration.startDate,
                'days'
              )} days`
            )}
          </span>
        </li>
        <li className={styles['list-item']}>
          <span>Total Cost</span>
          <span>
            {loadingProgress || surveyOrderLoading ? (
              <Skeleton variant="text" width={60} />
            ) : (
              `£${(
                (surveyOrderData?.surveyOrder.customerCPI ?? 0) *
                interviewsWanted
              ).toLocaleString('en-GB')}`
            )}
          </span>
        </li>
      </ul>
    </div>
  )
}

export default ProjectCost
