import { useSnackbar } from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CreatePassword as CreatePasswordControl } from '../../../components/Auth'
import { SignUp } from '../../../data/rest/accountUser'
import { useLoginRedirect } from '../../../hooks/useLoginRedirect'
import UserSession from '../../../utils/UserSession'
import { AccessToken, setAccountId } from '../../../utils/authUtils'

const CreatePassword: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [signUpError, setSignupError] = useState<Error | undefined>(undefined)
  const { requestId = '' } = useParams<{ requestId: string }>()
  const { loginRedirect } = useLoginRedirect()

  const signUpErrorMessage =
    'Whoops, there was an issue completing the signup process. Please refresh and try again.'

  const handleCreatePassword: (
    password: string
  ) => Promise<boolean | Error> = async (password) => {
    try {
      const response = await SignUp(requestId, password)
      UserSession.setEmail(response.data.emailAddress)
      AccessToken.set(response.data.accessToken)
      const gotAccountId: boolean = await setAccountId(
        response.data.emailAddress,
        response.data.accessToken
      )
      loginRedirect()
      return gotAccountId
    } catch (err: any) {
      setSignupError(new Error(err))
      return new Error(err)
    }
  }

  useEffect(() => {
    if (signUpError) {
      setTimeout(() => {
        setSignupError(undefined)
      }, 3000)
    }
  }, [signUpError])

  if (signUpError) {
    enqueueSnackbar(signUpErrorMessage, {
      variant: 'error'
    })
  }

  return <CreatePasswordControl onCreatePassword={handleCreatePassword} />
}

export default CreatePassword
