import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import {
  ForkFilter,
  SearchableFilterOption
} from '../../../../data/model/results'
import { isURL } from '../../../../utils/HelperFunctions'
import requestWithRetry from '../../../../utils/requestWithRetry'
import { transformForkDataIntoSearchableFilterOption } from '../../../../utils/resultsUtils'

interface Vars {
  surveyId: string
}

interface ResponseData {
  filters: SearchableFilterOption[]
  forkFilters: ForkFilter[]
}

interface FilterData {
  error: boolean
  loading: boolean
  data: SearchableFilterOption[]
}

const FILTERS_ENDPOINT = `${
  process.env.REACT_APP_URL_SURVEY_RESULTS || '/v2/survey-results'
}/api/v1.0/filters`

export const useFilters = ({ surveyId }: Vars): FilterData => {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<SearchableFilterOption[]>([])

  useEffect(() => {
    setLoading(true)

    requestWithRetry({
      method: 'get',
      url: `${
        isURL(FILTERS_ENDPOINT) ? '' : process.env.REACT_APP_REST_DOMAIN
      }${FILTERS_ENDPOINT}?surveyId=${surveyId}`
    })
      .then((data: AxiosResponse<ResponseData>) => {
        if (data.status >= 200 && data.status < 400) {
          setError(false)
          setData([
            ...transformForkDataIntoSearchableFilterOption(
              data.data.forkFilters
            ),
            ...data.data.filters
          ])
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [surveyId])

  return { data, error, loading }
}
