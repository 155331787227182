import { LinkButton, TextHighlight } from '@focaldata/cin-ui-components'
import ListInput from '../../../../components/ListInput'
import { useAutoKeyedArray } from '../../../../hooks/useAutoKeyedArray'

interface FdChatSpecificationQuestionListProps {
  questions: string[]
  onChange: (questions: string[]) => void
  canAdd?: boolean
  canRemove?: boolean
  shouldAutofocusKeyQuestion?: boolean
}

export const FdChatSpecificationQuestionList = ({
  questions,
  onChange,
  canAdd,
  canRemove,
  shouldAutofocusKeyQuestion
}: FdChatSpecificationQuestionListProps) => {
  const {
    keyedValues: keyedQuestions,
    updateAt: updateQuestion,
    removeAt: removeQuestion,
    insertEnd: newQuestion
  } = useAutoKeyedArray(questions, onChange)

  return (
    <>
      {keyedQuestions.map(({ key, value: question }, position) => (
        <ListInput
          key={String(key)}
          value={question}
          placeholder={
            position === 0
              ? 'Type your first interview question here'
              : undefined
          }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={shouldAutofocusKeyQuestion}
          onChange={(text) => updateQuestion(position, text)}
          onRemove={() => removeQuestion(position)}
          canRemove={canRemove}
        />
      ))}
      {canAdd && (
        <LinkButton
          highlight={TextHighlight.Primary}
          onClick={() => {
            newQuestion('')
          }}
        >
          <b>+</b> Add new question
        </LinkButton>
      )}
    </>
  )
}
