import {
  Button,
  ButtonVariant,
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LinkButton,
  TextHighlight,
  TextSize,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import { useNavigate } from 'react-router-dom'
import ErrorPage, { ErrorType } from '../../../../controls/ErrorPage'
import { useProjectId } from '../../../../hooks/useProjectId'
import { useCombinedQuestionId } from '../MultiMarketPage.hooks'
import { CombinedQuestionTitleInput } from './CombinedQuestionTitleInput'
import { useGetDraftCombinedQuestion } from './DraftCombinedQuestionState/DraftCombinedQuestion.hooks'
import { useResponseOptionsMappingPageValidation } from './ResponseOptionsMappingPage.hooks'
import { useStyles } from './ResponseOptionsMappingPage.styles'
import { ResponseOptionsSourceView } from './ResponseOptionsSourceView'

export const ResponseOptionsMappingPage = () => {
  const { draftCombinedQuestion } = useGetDraftCombinedQuestion()

  const navigate = useNavigate()
  const projectId = useProjectId()
  const combinedQuestionId = useCombinedQuestionId()
  const { isValid, validationErrors } =
    useResponseOptionsMappingPageValidation()
  const { classes } = useStyles()

  if (draftCombinedQuestion?.id !== combinedQuestionId) {
    return <ErrorPage errorType={ErrorType.NotFound404} />
  }

  const saveMappingButton = (
    <Button variant={ButtonVariant.PrimaryFilled} disabled={!isValid}>
      Save mapping
    </Button>
  )

  return (
    <Grid container className={classes.root}>
      <Grid marginTop={1} marginBottom={2} className={classes.backLinkWrapper}>
        <LinkButton
          noPadding
          onClick={() => navigate(`/project/${projectId}/mapping`)}
          size={TextSize.m}
          highlight={TextHighlight.Primary}
        >
          <Icon
            name={IconName.Arrow}
            size={IconSize.Medium}
            iconColor={IconColor.Primary}
          />
          Back to Mapping
        </LinkButton>
      </Grid>
      <Grid display="flex">
        <Grid item xs={6}>
          <CombinedQuestionTitleInput />
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          gap={2}
        >
          <Button variant={ButtonVariant.PrimaryOutlined}>
            Disband mapping
          </Button>
          {isValid ? (
            saveMappingButton
          ) : (
            <Tooltip
              title={
                <ul style={{ paddingLeft: 10 }}>
                  {validationErrors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              }
              tipPlacement={TipPlacement.Top}
            >
              {saveMappingButton}
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <ResponseOptionsSourceView />
    </Grid>
  )
}
