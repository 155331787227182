import { Paragraph, convertMillimetersToTwip } from 'docx'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry
} from '../../data/model/questionnaire'
import { chain } from '../lodashChain'
import {
  getQuestionEntryPrefix,
  getResponseOptions
} from '../questionnaireUtils'
import { createResponseLogicText } from './createResponseLogicText'

export const createResponseOptions = (
  entryItem: DraftQuestionItem | DraftMatrixItem,
  entries: DraftQuestionnaireEntry[]
): Paragraph[] => {
  return chain(getResponseOptions(entryItem, entryItem.responseOptions))
    .filter('option.value')
    .sortBy('position')
    .map(({ option, route }, i) => {
      let skipToQuestionText = ''
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (entries && route?.targetNumber !== undefined) {
        const routeToEntry = entries.find(
          ({ number }) => number === route.targetNumber
        )
        if (routeToEntry) {
          const routeToQuestionPrefixAndNumber =
            getQuestionEntryPrefix(routeToEntry)

          skipToQuestionText = ` (skip to ${routeToQuestionPrefixAndNumber})`
        }
      }

      const maskingRules =
        entryItem.responseOptions.find(
          ({ responseOptionLk }) => responseOptionLk === option.responseOptionId
        )?.maskingRules || []

      return new Paragraph({
        text: `${i + 1}. ${option.value}${skipToQuestionText}`,
        children: createResponseLogicText(maskingRules, entries),
        indent: {
          start: convertMillimetersToTwip(6.3)
        }
      })
    })
    .value()
}
