import {
  Icon,
  IconColor,
  IconName,
  IconSize
} from '@focaldata/cin-ui-components'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import { AdHocTestingSpecification } from '../../../../../data/gql-gen/questionnaire/graphql'
import { useFdChatProgress } from '../../../../../modules/FdChat/FdChatProgressProvider.context'
import { useGetFdChatSpecification } from '../../FdChat.hooks'

const AskAiBar = ({
  inputValue,
  onInputChange,
  placeholder,
  onAskAi,
  onSelectOption,
  hideOptions
}: {
  inputValue: string
  onInputChange: (_: React.SyntheticEvent, value: string) => void
  placeholder: string
  onAskAi: () => void
  onSelectOption?: (_: React.SyntheticEvent, value: string) => void
  hideOptions?: boolean
}) => {
  const { fdChatSpecification } = useGetFdChatSpecification()
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const { dataAvailable } = useFdChatProgress()

  const adHocTestingSpecification =
    fdChatSpecification?.__typename === 'AdHocTestingSpecification'
      ? (fdChatSpecification as AdHocTestingSpecification)
      : null

  const researchQuestions = adHocTestingSpecification?.questions || []
  const askAiEnabled = dataAvailable && inputValue.length > 0

  return (
    <Autocomplete
      freeSolo
      sx={{
        marginBottom: '16px',
        viewTransitionName: 'ask-ai-bar'
      }}
      options={hideOptions ? [] : researchQuestions}
      groupBy={() => 'Suggested questions'}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onFocus={() => setShowPlaceholder(false)}
      onBlur={() => setShowPlaceholder(true)}
      disableClearable
      onChange={(_, value, reason) => {
        if (reason === 'selectOption') {
          onSelectOption?.(_, value)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={showPlaceholder ? placeholder : undefined}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && askAiEnabled) {
              onAskAi()
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  marginLeft: '8px',
                  marginRight: '3px'
                }}
              >
                <Icon
                  name={IconName.Search}
                  size={IconSize.Large}
                  iconColor={IconColor.Background}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={
                    'No data available. You will be able to ask questions of the data as soon as more interviews are being conducted.'
                  }
                  placement="right"
                  disableHoverListener={dataAvailable}
                  disableFocusListener={dataAvailable}
                  disableTouchListener={dataAvailable}
                >
                  {/* span wrapper needed for tooltip to hover over disabled element */}
                  <span>
                    <Button
                      variant="contained"
                      disabled={!askAiEnabled}
                      disableElevation
                      sx={{
                        textTransform: 'unset',
                        borderRadius: '16px',
                        fontWeight: 500,
                        padding: '8px 16px',
                        fontSize: '16px'
                      }}
                      onClick={onAskAi}
                    >
                      Ask AI
                    </Button>
                  </span>
                </Tooltip>
              </InputAdornment>
            )
          }}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '100px',
              backgroundColor: '#ffffff',
              boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
              padding: '23px 20px',
              fontSize: '18px',
              '& fieldset': {
                border: '1px solid #D0D5DD'
              },
              '&:hover fieldset': {
                border: '1px solid #D0D5DD'
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #D0D5DD'
              }
            }
          }}
        />
      )}
      componentsProps={{
        popper: {
          sx: {
            marginTop: '8px !important',
            '& .MuiAutocomplete-paper': {
              borderRadius: '30px',
              boxShadow: 'none'
            },
            '& .MuiAutocomplete-listbox': {
              padding: '16px 20px'
            },
            '& .MuiAutocomplete-option.Mui-hover': {
              backgroundColor: '#EDF5FD'
            },
            '& .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: '#EDF5FD'
            }
          }
        }
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <p
            style={{
              marginBottom: '10px',
              marginLeft: '8px',
              color: '#565D6D',
              fontWeight: 500,
              fontSize: '12px'
            }}
          >
            {params.group}
          </p>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            padding: '8px',
            color: '#72758D',
            borderRadius: '8px'
          }}
        >
          {option}
        </li>
      )}
    />
  )
}

export default AskAiBar
