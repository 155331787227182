import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { SaveMarketData } from './AddMarketsDialog'
import { ADD_MARKET } from './MarketsPage.mutations'

export const useAddMarket = () => {
  const { projectId } = useParams()
  const [addMarketMutation] = useMutation(ADD_MARKET)
  return ({
    marketTitle,
    countryCode,
    language,
    completes
  }: SaveMarketData) => {
    addMarketMutation({
      variables: {
        projectId,
        name: marketTitle,
        countryCode,
        language,
        completes
      }
    })
  }
}
