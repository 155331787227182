import { EntryType } from '../../../../data/gql-gen/questionnaire/graphql'
import { DraftQuestionnaireEntry } from '../../../../data/model/questionnaire'

// This number is used by the respondent app to route the user to the end of the survey
export const ROUTE_TO_END_SURVEY_NUMBER = 100000

export const getRoutingMenuItemText: (
  entry: DraftQuestionnaireEntry
) => string = (entry) => {
  switch (entry.entryType) {
    case EntryType.QuestionEntryType:
      return entry.entryItem.question?.text ?? ''
    case EntryType.MatrixEntryType:
      return entry.entryItem.matrixTitle?.title ?? ''
    case EntryType.TextCardEntryType:
      return entry.entryItem.textCard.title
    default:
      return ''
  }
}
