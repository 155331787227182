import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { GetDraftCombinedQuestionQuery } from '../../../../../../data/gql-gen/local/graphql'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const mapResponseOption: Resolver = (
  _,
  {
    id,
    marketQuestionId,
    mappingId
  }: { id: string; marketQuestionId: string; mappingId: string },
  { cache }: { cache: InMemoryCache }
) => {
  return cache.updateQuery(
    {
      query: GET_DRAFT_COMBINED_QUESTION
    },
    (
      data: GetDraftCombinedQuestionQuery | null
    ): GetDraftCombinedQuestionQuery | void => {
      if (!data) {
        throw new Error('No draft combined question found in cache')
      }
      return produce(data, ({ draftCombinedQuestion }) => {
        const { marketQuestions } = draftCombinedQuestion
        const marketQuestionToMap = marketQuestions.find(
          (marketQuestion) => marketQuestion.id === marketQuestionId
        )
        const responseOptionToMap = marketQuestionToMap?.responseOptions.find(
          (responseOption) => responseOption.id === id
        )

        if (responseOptionToMap) {
          responseOptionToMap.mappingId = mappingId
        }
      })
    }
  )
}
