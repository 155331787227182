import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  copyButton: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 2,
    padding: 6
  },
  loadingContainer: {
    margin: theme.spacing(2, 0, 0, 0)
  },
  loadingBar: {
    height: theme.spacing(2.5),
    width: 450,
    borderRadius: 2,
    backgroundColor: '#FF450040'
  },
  loadingProgress: {
    borderRadius: 2
  },
  urlContainer: {
    margin: theme.spacing(1, 0)
  },
  switchContainer: {
    margin: theme.spacing(1, 0, 1, -1.5)
  }
}))

export default useStyles
