import { useMutation } from '@apollo/client'
import { useAppDispatch } from '../../../App.store'
import { LoggerErrorType } from '../../../data/logger'
import { useProjectId } from '../../../hooks/useProjectId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { UPDATE_SECTIONS_RANDOMISATION } from './SectionsRandomisation.mutations'
import {
  randomisedSectionsSet,
  sectionsRandomisationRemoved
} from './SectionsRandomisation.slice'

export const useSetSectionsRandomisation = () => {
  const projectId = useProjectId()
  const dispatch = useAppDispatch()

  const [setSectionsRandomisationMutation] = useMutation(
    UPDATE_SECTIONS_RANDOMISATION,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateSectionsRandomisationV2',
          error
        )
      }
    }
  )

  const setSectionsRandomisation = (sectionIds: string[]) => {
    return setSectionsRandomisationMutation({
      variables: {
        input: {
          projectId,
          sectionIds
        }
      },
      onCompleted() {
        sectionIds.length
          ? dispatch(randomisedSectionsSet(sectionIds))
          : dispatch(sectionsRandomisationRemoved())
      }
    })
  }

  return setSectionsRandomisation
}
