import {
  AudienceType,
  BadgeAudience,
  EditorInput,
  Grid,
  IconName,
  Paper,
  PaperGroupItem,
  Progress,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { EmptyState } from '../../components/EmptyState'
import { QuestionType } from '../../data/model/chart'
import { QuotaResults } from '../../data/model/quotaResults'
import {
  getPercentageFromFloat,
  numberToFixedString
} from '../../utils/HelperFunctions'
import { sortBy } from '../../utils/array/sortBy'
import useStyles from './styles'

interface QuotaResultsWithType extends QuotaResults {
  type: QuestionType | undefined
}

interface Props {
  areQuotasApplied: boolean
  quotaResults: QuotaResultsWithType[] | undefined
  quotaResultsLoading: boolean
}

const QuotaCards: React.FC<Props> = (props: Props) => {
  const { areQuotasApplied, quotaResults, quotaResultsLoading } = props
  const { classes } = useStyles()

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  if (!areQuotasApplied) {
    return (
      <Grid className={classes.quotaEmptyState}>
        <EmptyState
          iconName={IconName.InfoOutlinedIcon}
          title="No quotas have been added for this project."
          hasElevation
        />
      </Grid>
    )
  }

  if (quotaResults?.length === 0) {
    return (
      <Grid className={classes.quotaEmptyState}>
        <EmptyState
          iconName={IconName.BarChart}
          title="Your quotas will appear here after the first person completes the survey."
          hasElevation
        />
      </Grid>
    )
  }

  return (
    <Grid container>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {quotaResults && quotaResults?.length === 0 && (
        <EmptyState
          iconName={IconName.Search}
          title="No quotas are available for this survey."
          hasElevation
        />
      )}
      {quotaResults && (
        <>
          {quotaResults.map((quota) => {
            const quotaOptions = sortBy(quota.options, { key: 'position' })
            return (
              <Grid
                container
                className={classes.quotaCardContainer}
                key={quota.id}
              >
                <Paper>
                  <PaperGroupItem>
                    <Grid container item xs={6} direction="row">
                      <Grid item xs="auto" className={classes.identifier}>
                        <p
                          className={classNames(
                            textClasses.default,
                            textClasses.sizeMs,
                            textClasses.highlightBackground,
                            textClasses.weightBold
                          )}
                        >
                          A{quota.position}
                        </p>
                      </Grid>

                      <Grid item xs="auto" className={classes.questionType}>
                        {quota.type === QuestionType.standard_audience ? (
                          <BadgeAudience badgeType={AudienceType.Targeting} />
                        ) : (
                          <BadgeAudience badgeType={AudienceType.Screening} />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} direction="row">
                      <Grid item xs={12} className={classes.questionType}>
                        <EditorInput readOnly value={quota.text || '...'} />
                      </Grid>
                    </Grid>
                  </PaperGroupItem>
                  <PaperGroupItem>
                    <Grid container className={classes.subtitle}>
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item xs={10} />
                          <Grid container item xs={2} justifyContent="center">
                            <p
                              className={classNames(
                                textClasses.default,
                                textClasses.sizeMs,
                                textClasses.highlightBackground,
                                textClasses.weightBold
                              )}
                            >
                              QUOTA %
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} justifyContent="flex-start">
                        <p
                          className={classNames(
                            textClasses.default,
                            textClasses.sizeMs,
                            textClasses.highlightBackground,
                            textClasses.weightBold
                          )}
                        >
                          CURRENT PROGRESS
                        </p>
                      </Grid>
                    </Grid>
                    {quotaOptions.map((option) => {
                      const quotaPercentage = numberToFixedString(
                        option.quotaPercent * 100
                      )
                      const progressPercentage = getPercentageFromFloat(
                        option.progress.progress
                      )
                      const { samplesCollected, samplesWanted } =
                        option.progress

                      return (
                        <Grid container key={option.id}>
                          <Grid
                            container
                            item
                            xs={8}
                            justifyContent="space-between"
                            alignItems="flex-end"
                            className={classes.quotaRow}
                          >
                            <Grid
                              container
                              item
                              xs={10}
                              className={classes.optionText}
                              alignItems="center"
                            >
                              <p
                                className={classNames(
                                  textClasses.default,
                                  textClasses.sizeM,
                                  textClasses.highlightBackground,
                                  textClasses.weightRegular
                                )}
                              >
                                {option.text}
                              </p>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={2}
                              className={classes.quotaPercentage}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid
                                container
                                item
                                justifyContent="center"
                                alignItems="center"
                                className={classes.textQuotaPercentage}
                              >
                                <p
                                  className={classNames(
                                    textClasses.default,
                                    textClasses.sizeM,
                                    textClasses.highlightBackground,
                                    textClasses.weightRegular,
                                    textClasses.alignCenter
                                  )}
                                >
                                  {quotaPercentage}%
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={4}
                            alignItems="center"
                            className={classes.progressBar}
                          >
                            <Progress
                              value={
                                samplesWanted !== 0 ? progressPercentage : 0
                              }
                              progressPercentage={progressPercentage}
                              wanted={samplesWanted}
                              collected={samplesCollected}
                              loading={quotaResultsLoading}
                              isProgressNA={samplesWanted === 0}
                            />
                          </Grid>
                        </Grid>
                      )
                    })}
                  </PaperGroupItem>
                </Paper>
              </Grid>
            )
          })}
          <Divider className={classes.divider} />
        </>
      )}
    </Grid>
  )
}

export default QuotaCards
