import { TextHighlight, TextSize, TextWeight } from '../../../constants/text'
import {
  ForkTag,
  MaskingRuleClause
} from '../../../data/gql-gen/questionnaire/graphql'
import { DraftQuestionnaireEntry } from '../../../data/model/questionnaire'
import { getForkLogicTextData } from '../../../utils/questionLogic'
import { truncateText } from '../../../utils/questionnaireUtils'

type QuestionTextTo = {
  questionText: string
  roTexts: (string | undefined)[]
  highlight?: (typeof TextHighlight)[keyof typeof TextHighlight]
  size?: (typeof TextSize)[keyof typeof TextSize]
  operator?: MaskingRuleClause
  negationText: string
}

export const questionTextTo: (qtt: QuestionTextTo) => JSX.Element = ({
  questionText,
  roTexts,
  highlight = TextHighlight.Background,
  size = TextSize.m,
  operator,
  negationText
}: QuestionTextTo) => (
  <>
    <p
      style={{
        color: highlight,
        display: 'inline',
        fontSize: size
      }}
    >
      {operator ? ` ${operator}` : 'IF'} the answer to{' '}
    </p>
    <p
      style={{
        color: highlight,
        display: 'inline',
        fontSize: size,
        fontWeight: TextWeight.SemiBold
      }}
    >
      {truncateText(70, questionText)}
    </p>
    <p
      style={{
        color: highlight,
        display: 'inline',
        fontSize: size
      }}
    >
      {' '}
      {negationText}{' '}
    </p>
    {roTexts.map((rot, index) => (
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line react/jsx-key
      <p
        style={{
          color: highlight,
          display: 'inline',
          fontWeight: TextWeight.SemiBold,
          fontSize: size
        }}
      >
        {index !== roTexts.length - 1
          ? `${truncateText(70, rot)} OR `
          : truncateText(70, rot)}
      </p>
    ))}
  </>
)

export const getForkItem: (
  fork: ForkTag,
  entries: DraftQuestionnaireEntry[],
  fontSize?: (typeof TextSize)[keyof typeof TextSize],
  textColor?: (typeof TextHighlight)[keyof typeof TextHighlight]
) => JSX.Element | null = (
  fork,
  entries,
  fontSize,
  textColor = TextHighlight.Background
) => {
  const forkLogicTextData = getForkLogicTextData(fork, entries)

  if (forkLogicTextData) {
    return (
      <>
        <p
          style={{
            color: textColor,
            display: 'inline',
            fontSize
          }}
        >
          If{' '}
        </p>
        <p
          style={{
            color: textColor,
            display: 'inline',
            fontWeight: TextWeight.SemiBold,
            fontSize
          }}
        >
          {forkLogicTextData.forkName}
        </p>
        <p
          style={{
            color: textColor,
            display: 'inline',
            fontSize
          }}
        >
          {' '}
          is{' '}
        </p>
        <p
          style={{
            color: textColor,
            display: 'inline',
            fontWeight: TextWeight.SemiBold,
            fontSize
          }}
        >
          Group {forkLogicTextData.branchLabel}
        </p>
      </>
    )
  }
  return null
}
