import { gql } from '@apollo/client/core'
import DRAFT_AUDIENCE_MEMBER from '../fragments/draftAudienceMember'

export const PASTE_AUDIENCE_RESPONSE_OPTION_VALUES_V2 = gql`
  mutation pasteAudienceMemberResponseOptionValuesV2(
    $input: PasteAudienceMemberResponseOptionsValuesArgsInput!
  ) {
    pasteAudienceMemberResponseOptionValuesV2(input: $input) {
      ...DraftAudienceMember
    }
  }
  ${DRAFT_AUDIENCE_MEMBER}
`
