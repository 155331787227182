import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  TextHighlight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import MuiMenu from '@mui/material/Menu'
/* eslint-disable complexity */
import React from 'react'
import useStyles from './InfoPanel.styles'

interface OwnProps {
  title: string
  leftSubtitle?: string
  disabled?: boolean
  disabledWithColor?: boolean
  iconName?: InfoPanelIconVariant
  closeMenuOnItemClick?: boolean
  titleTextColor?: TextHighlight
  variant?: InfoPanelVariant
}

export enum InfoPanelIconVariant {
  Chevron,
  Kebab
}

export enum InfoPanelVariant {
  Error,
  Standard
}

export type Props = OwnProps & React.InputHTMLAttributes<HTMLInputElement>

const menuIconMap = new Map<InfoPanelIconVariant, IconName>([
  [InfoPanelIconVariant.Chevron, IconName.KeyboardArrowDown],
  [InfoPanelIconVariant.Kebab, IconName.MoreVert]
])

const InfoPanel: React.FC<Props> = (props: Props) => {
  const {
    title,
    leftSubtitle,
    children,
    disabled = false,
    disabledWithColor = false,
    iconName = InfoPanelIconVariant.Chevron,
    closeMenuOnItemClick = false,
    titleTextColor = TextHighlight.Standard,
    variant = InfoPanelVariant.Standard
  }: Props = props
  const { useTextStyles, classNameByHighlight } = textStyleUtils

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } = useTextStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose: () => void = () => {
    setAnchorEl(null)
  }

  const onMenuItemClick: () => void = () => {
    if (closeMenuOnItemClick) {
      handleClose()
    }
  }

  const menuIcon = menuIconMap.get(iconName) || IconName.KeyboardArrowDown
  const iconColor =
    disabled && disabledWithColor ? IconColor.Disabled : IconColor.Emphasis

  return (
    <Button
      disableTouchRipple
      className={classes.menuButton}
      classes={{
        root:
          variant === InfoPanelVariant.Error
            ? classes.buttonRootTransparent
            : classes.buttonRoot
      }}
      color="secondary"
      onClick={handleClick}
      variant="outlined"
      disabled={disabled}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignContent="center"
      >
        {variant === InfoPanelVariant.Standard && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {leftSubtitle && (
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.alignLeft,
                  textClasses.sizeMs,
                  textClasses.noWrap,
                  classes.leftSubtitle
                )}
              >
                {leftSubtitle}
              </p>
            )}
            <p
              className={classNames(
                textClasses.default,
                textClasses.alignLeft,
                textClasses.sizeMs,
                textClasses.weightSemiBold,
                textClasses.noWrap,
                disabledWithColor
                  ? textClasses.highlightBackground
                  : textClasses[
                      classNameByHighlight.get(titleTextColor) ??
                        'highlightStandard'
                    ],
                classes.title
              )}
            >
              {title}
            </p>
            <Icon name={menuIcon} size={IconSize.Large} iconColor={iconColor} />
          </Grid>
        )}
        {variant === InfoPanelVariant.Error && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.error}
          >
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.weightSemiBold,
                classes.errorText
              )}
            >
              {title}
            </p>
            <Icon
              iconColor={IconColor.White}
              name={IconName.KeyboardArrowDown}
              size={IconSize.Medium}
              className={classes.errorText}
            />
          </Grid>
        )}
      </Grid>
      <MuiMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        color="secondary"
        classes={{ paper: classes.menu, list: classes.list }}
        elevation={1}
        // getContentAnchorEl={null}
        onClick={onMenuItemClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {children}
      </MuiMenu>
    </Button>
  )
}

export default InfoPanel
