import { Grid, Text, TextSize, TextWeight } from '@focaldata/cin-ui-components'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import ErrorPage, { ErrorType } from '../../../../../controls/ErrorPage'
import { ResearchQuestionType, TranscriptType } from '../../FdChat.model'
import ResearchQuestionCard from '../components/ResearchQuestionCard/ResearchQuestionCard'
import TranscriptDialog from '../components/TranscriptDialog/TranscriptDialog'
import VerbatimsCard from '../components/VerbatimsCard/VerbatimsCard'
import { questionImg } from './researchQuestionIcons'
import results from './results'

const FdChatQuestionInsight = () => {
  const navigate = useNavigate()
  const [transcriptDialogOpen, setTranscriptDialogOpen] = useState(false)
  const [activeTranscript, setActiveTranscript] = useState<TranscriptType>()
  const [quoteToHighlight, setQuoteToHighlight] = useState<string>()
  const { questionId } = useParams()

  const questionIndex = results.research_questions.findIndex(
    (question) => question.id === questionId
  )

  const question: ResearchQuestionType | undefined =
    questionIndex >= 0 ? results.research_questions[questionIndex] : undefined
  const nextQuestion =
    questionIndex >= 0
      ? results.research_questions[questionIndex + 1]
      : undefined

  useEffect(() => {
    if (question) {
      LogAmplitudeEvent(EventType.ViewedFdChatResearchQuestionPage, {
        researchQuestionId: question.id,
        researchQuestionText: question.text,
        researchQuestionHeadline: question.headline
      })
    }
  }, [question])

  useEffect(() => {
    if (activeTranscript) {
      setTranscriptDialogOpen(true)
    }
  }, [activeTranscript])

  useEffect(() => {
    if (!transcriptDialogOpen) {
      setActiveTranscript(undefined)
      setQuoteToHighlight(undefined)
    }
  }, [transcriptDialogOpen])

  if (!question) return <ErrorPage errorType={ErrorType.NotFound404} />

  return (
    <Grid
      container
      gap={'48px'}
      padding={'24px 24px 48px 24px'}
      display={'grid'}
      maxHeight={'100dvh'}
      className="fd-chat-question-insight"
      style={{
        overflowY: 'auto'
      }}
    >
      <ResearchQuestionCard
        captionIcon={questionImg[questionIndex + 1]}
        caption={question.text}
        title={question.headline}
        description={question.long_description}
        chartData={question.chart_data}
        linkText="Back to Summary"
        handleLinkClick={() => navigate('/fdchat/sample-findings-report')}
        primaryButtonText={nextQuestion && 'Explore Next Question'}
        handlePrimaryButtonClick={
          nextQuestion
            ? () =>
                navigate(`/fdchat/sample-findings-report/${nextQuestion.id}`)
            : undefined
        }
      />
      {question.themes && (
        <section style={{ width: '100%' }}>
          <Grid
            container
            justifyContent={'space-between'}
            marginBottom={'24px'}
          >
            <Text size={TextSize.lxl} weight={TextWeight.Bold}>
              Themes & Verbatims
            </Text>
          </Grid>
          <Grid container spacing={'24px'}>
            {question.themes.map((theme) => (
              <Grid item xs={12} lg={4} key={theme.name}>
                <VerbatimsCard
                  {...theme}
                  handleOpenTranscript={({
                    respondentId,
                    quoteToHighlight
                  }) => {
                    LogAmplitudeEvent(EventType.ClickedViewTranscript, {
                      themeName: theme.name,
                      themeDescription: theme.description,
                      respondentId,
                      quote: quoteToHighlight
                    })
                    const transcript = results.transcripts.find(
                      (transcript) => transcript.respondent_id === respondentId
                    )
                    if (transcript) {
                      setActiveTranscript(transcript)
                      setQuoteToHighlight(quoteToHighlight)
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <TranscriptDialog
            open={transcriptDialogOpen}
            closeDialog={() => {
              setTranscriptDialogOpen(false)
            }}
            transcript={activeTranscript}
            quoteToHighlight={quoteToHighlight}
          />
        </section>
      )}
    </Grid>
  )
}

export default FdChatQuestionInsight
