import { useNavigate } from 'react-router-dom'
import { getMultiMarketPath } from '../../controls/ProjectListItem/ProjectListItem.utils'
import { Project, ProjectType } from '../../data/gql-gen/dashboard/graphql'

export const useNavigateToInitialProjectPage = () => {
  const navigate = useNavigate()
  return ({ projectType, projectId, surveys }: Project) => {
    const surveyId = surveys[0].surveyId
    switch (projectType) {
      case ProjectType.MultiMarketV2:
        navigate(getMultiMarketPath(projectId))
        break
      case ProjectType.FdChat:
        navigate(`/fdchat/${surveyId}`)
        break
      default:
        navigate(`/survey/${surveyId}/audience`)
        break
    }
  }
}
