import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  content: {
    padding: theme.spacing(8, 40)
  },
  styleText: {
    fontFamily: 'Proxima Nova',
    color: additionalPalette.darkGrey.main
  },
  noList: {
    listStyleType: 'none',
    textAlign: 'justify'
  }
}))

export default useStyles
