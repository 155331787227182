import { Theme, makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme: Theme) => ({
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  result: {
    width: '450px',
    padding: theme.spacing(1, 0, 1, 1)
  },
  resultText: {
    padding: theme.spacing(0, 1, 1, 1)
  },
  resultCategory: {
    padding: theme.spacing(0, 1)
  },
  container: {
    width: `calc(300px - ${theme.spacing(4).slice(0, -2)})px)`,
    margin: theme.spacing(1, 2, 1, 0)
  }
}))

export default useStyles
