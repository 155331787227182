// we don't want to have to take care that every callback that is passed to this is
// wrapped in a useCallback
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react'

export default function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export function useDebounceEffect<T>(
  callback: () => void,
  value: T,
  {
    delay,
    leading = false
  }: {
    delay: number
    leading?: boolean
  }
) {
  const isLeading = useRef(leading)
  useEffect(
    () => {
      if (isLeading.current) {
        callback()
        isLeading.current = false
        return undefined
      }
      // Update debounced value after delay
      const handler = setTimeout(() => {
        callback()
        isLeading.current = leading
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
}
