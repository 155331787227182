import { useFragment } from '@apollo/client'
import { DraftMatrixRow } from '../../data/gql-gen/questionnaire/graphql'
import DRAFT_MATRIX_ROW from '../../data/gql/questionnaire/fragments/draftMatrixRow'

export const useDraftMatrixRow = (
  questionLk?: string
): DraftMatrixRow | null => {
  const matrixRowFragment = useFragment<DraftMatrixRow>({
    fragment: DRAFT_MATRIX_ROW,
    fragmentName: 'DraftMatrixRow',
    from: { __typename: 'DraftMatrixRow', questionLk },
    canonizeResults: true
  })

  return matrixRowFragment.complete ? matrixRowFragment.data : null
}
