// eslint-disable-next-line no-restricted-imports
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import {
  ChartEntry,
  EntryType,
  QuestionType,
  Row,
  RowItem
} from '../data/model/chart'

export const isSingleChoiceQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.basic &&
    !entry.isMultipleChoice
  )
}

export const isMultipleChoiceQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.basic &&
    entry.isMultipleChoice
  )
}

export const isMatrixSingleChoiceQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.MatrixEntryType && !entry.isMultipleChoice
  )
}

export const isMatrixMultipleChoiceQuestion = (entry: ChartEntry): boolean => {
  return entry.entryType === EntryType.MatrixEntryType && entry.isMultipleChoice
}

export const isRankedQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.ranked
  )
}

export const isScaleQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.scale
  )
}

export const isFreeTextQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.free_text
  )
}

export const isAudienceQuestion = (entry: ChartEntry): boolean => {
  return (
    entry.entryType === EntryType.QuestionEntryType &&
    (entry.questionType === QuestionType.custom_audience ||
      entry.questionType === QuestionType.standard_audience)
  )
}

export const getDisplayNameFromQuestionType = ({
  isMultipleChoice,
  questionType
}: ChartEntry): string => {
  const questionDisplayNameByType: Map<QuestionType, string> = new Map([
    [QuestionType.basic, isMultipleChoice ? 'Multi select' : 'Single select'],
    [QuestionType.ranked, 'Ranked'],
    [QuestionType.matrix, 'Matrix'],
    [QuestionType.custom_audience, 'Custom audience'],
    [QuestionType.standard_audience, 'Standard audience']
  ])

  return questionDisplayNameByType.get(questionType) ?? questionType
}

const rankedNonStandardStrings: string[] = [
  'Ranked 1st',
  'Ranked 2nd',
  'Ranked 3rd'
]

export const getRankLabel: (index: number) => string = (index) => {
  if (index < 3) {
    return rankedNonStandardStrings[index]
  }
  return `Ranked ${index + 1}th`
}

export const safeDivision = (
  nominator: number | undefined,
  denominator: number | undefined
): number => {
  if (!nominator || !denominator) {
    return 0
  }

  try {
    return nominator / denominator
  } catch (error) {
    return 0
  }
}

export const formatRankedDataset = (entry: ChartEntry) => {
  // filter out regular rows, non-default options
  const nonDefaultOptionsRows = entry.rows.filter((row) => {
    return row.rowItems.some((ri) => {
      return parseInt(ri.text, 10) > -1
    })
  })
  const allRanks = nonDefaultOptionsRows.flatMap((r) =>
    r.rowItems.map((ri) => ri.text)
  )
  const allDistinctRanks = uniq(allRanks)
  const allSortedDistinctRanks = sortBy(allDistinctRanks, (label) =>
    Number(label)
  )

  const formattedDataset = allSortedDistinctRanks.map((rank, index) => {
    return {
      label: getRankLabel(index),
      data: entry.rows.map((row: Row) => {
        return safeDivision(
          row.rowItems.find((ri) => ri.text === rank)?.completes,
          entry.samplesCollectedFiltered
        )
      })
    }
  })

  return { formattedDataset, nonDefaultOptionsRows }
}

export const formatMatrixMultiSelectDataset = (entry: ChartEntry) => {
  const hasWeights = !!entry.weightedSamplesCollectedFiltered
  const samplesCollectedPerRow = hasWeights
    ? entry.rows.map((row) => row.weightedSamplesCollectedFiltered)
    : entry.rows.map((row) => row.samplesCollectedFiltered)

  const firstRow = entry.rows[0]
  const formattedDataset = firstRow.rowItems.map(
    (rowItem: RowItem, index: number) => {
      return {
        label: rowItem.text,
        data: entry.rows.map((row: Row) =>
          safeDivision(
            hasWeights
              ? row.rowItems[index]?.weightedCompletes
              : row.rowItems[index]?.completes,
            hasWeights
              ? row.weightedSamplesCollectedFiltered
              : row.samplesCollectedFiltered
          )
        )
      }
    }
  )

  return { formattedDataset, samplesCollectedPerRow }
}

export const formatMatrixDataset = (entry: ChartEntry) => {
  const hasWeights = !!entry.weightedSamplesCollectedFiltered
  const entrySamples = hasWeights
    ? entry.weightedSamplesCollectedFiltered
    : entry.samplesCollectedFiltered
  const samplesCollectedPerRow = entry.rows.map((row) =>
    row.rowItems.reduce((sum, item) => {
      const completes = hasWeights
        ? item.weightedCompletes || 0
        : item.completes
      return sum + completes
    }, 0)
  )

  const formattedDataset = entry.rows[0].rowItems.map((_, index) => {
    return {
      label: entry.rows[0].rowItems[index].text,
      data: entry.rows.map((row: Row, idx) => {
        let ratio = 1
        // if the numbers of samples for this row is not the same as the total number of filtered samples
        // it means there's masking on the row so we need to display the percentages to 100%
        const isRowMasked =
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          samplesCollectedPerRow && samplesCollectedPerRow[idx] !== entrySamples

        if (isRowMasked)
          ratio = safeDivision(entrySamples, samplesCollectedPerRow[idx])
        return (
          safeDivision(
            hasWeights
              ? row.rowItems[index]?.weightedCompletes
              : row.rowItems[index]?.completes,
            entrySamples
          ) * ratio
        )
      })
    }
  })

  return { formattedDataset, samplesCollectedPerRow }
}
