import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { isFocaldataUser } from '../../../utils/authUtils'
import {
  checkIfMultipleChoice,
  isSettingEnabled
} from '../../../utils/questionnaireUtils'

export const isPossibleSourceEntry = ({
  entryItem,
  entryType
}: DraftQuestionnaireEntry) => {
  const isMultiSelect =
    entryType === EntryType.QuestionEntryType &&
    checkIfMultipleChoice(entryItem.settingValues)
  const isMatrix = entryType === EntryType.MatrixEntryType

  return isMultiSelect || (isFocaldataUser() && isMatrix)
}

export const entryHasStaticLooping = (sourceEntry: DraftQuestionnaireEntry) => {
  if (!('settingValues' in sourceEntry.entryItem)) {
    throw new Error(
      'Cannot check source entry for static looping without settings'
    )
  }

  return isSettingEnabled(
    sourceEntry.entryItem.settingValues,
    QuestionSettingCode.AutoAnswerAll
  )
}
