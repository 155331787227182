import { datadogLogs } from '@datadog/browser-logs'
import { RawDraftContentState } from '@focaldata/cin-ui-components'

const getTextFromRawContent = (rawContent: RawDraftContentState): string => {
  return rawContent.blocks.map((block) => block.text).join('\n')
}

export const getTextFromRawTitleStyling = (rawTitleStyling: string) => {
  if (!rawTitleStyling) {
    return ''
  }

  try {
    return getTextFromRawContent(JSON.parse(rawTitleStyling))
  } catch (e) {
    return ''
  }
}

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | JSONValue[]

// replace key fields which have dynamic values for proper comparison of strings
const rawToStringReplacer = (key: string, value: JSONValue) => {
  if (key === 'key') {
    return undefined
  }

  return value
}

export const areRawTitlesSame = (
  rawTitle1: RawDraftContentState,
  rawTitle2: RawDraftContentState
): boolean => {
  return (
    JSON.stringify(rawTitle1, rawToStringReplacer) ===
    JSON.stringify(rawTitle2, rawToStringReplacer)
  )
}

export const createRawValue = (text = '') => {
  return {
    blocks: [
      {
        key: '1',
        text,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  }
}

export const getRawValueFromJsonString = (
  rawStylingState = '""',
  fallback = ''
): RawDraftContentState => {
  try {
    const rawTitle = JSON.parse(rawStylingState || '""')
    if (rawTitle) {
      return rawTitle as RawDraftContentState
    }
    return createRawValue(fallback)
  } catch (e: unknown) {
    if (e instanceof Error) {
      datadogLogs.logger.error(`[getRawValueFromJsonString] ${e.message}`, e)
    }
    return createRawValue(fallback)
  }
}
