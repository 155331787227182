import { Grid } from '@focaldata/cin-ui-components'
import { Outlet } from 'react-router-dom'
import { FdChatBreadcrumbs } from './FdChatBreadcrumbs'
import { FdChatPageFooter } from './FdChatPageFooter'

export const FdChatSetupContainer = () => {
  return (
    <div
      className="setup-container"
      style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
    >
      <Grid paddingX={'32px'} paddingTop={'24px'}>
        <FdChatBreadcrumbs />
      </Grid>
      <div style={{ flex: '1' }}>
        <Outlet />
      </div>
      <FdChatPageFooter />
    </div>
  )
}

export default FdChatSetupContainer
