import { isURL } from '../../../utils/HelperFunctions'
import { AccessToken } from '../../../utils/authUtils'
import axios from '../../../utils/axiosWithRetry'
import { SurveyProgress } from '../../model/surveyResults'

// Enpoint to retrieve details about a surveys progress
const surveyProgressEndpoint = `${
  process.env.REACT_APP_URL_SURVEY_RESULTS || '/v2/survey-results'
}/api/v1.0/progress`

export default class AccountUserService {
  surveyProgressEndpoint: string

  constructor() {
    this.surveyProgressEndpoint = `${
      isURL(surveyProgressEndpoint) ? '' : process.env.REACT_APP_REST_DOMAIN
    }${surveyProgressEndpoint}`
  }

  surveysProgress(surveyIds: string[]) {
    return axios.post<SurveyProgress[]>(
      this.surveyProgressEndpoint,
      {
        surveyIds
      },
      {
        headers: {
          ...AccessToken.requestHeaders()
        }
      }
    )
  }
}
