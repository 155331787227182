import { makeVar, useReactiveVar } from '@apollo/client'
import { DEFAULT_LOI } from '../../modules/Survey/SurveyStats/SurveyStats.utils'

export const sharedDisplayedLOI = makeVar(DEFAULT_LOI)

const useDisplayedLOI = () => {
  return {
    displayedLOI: useReactiveVar(sharedDisplayedLOI)
  }
}

export default useDisplayedLOI
