import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  meanSection: {
    background: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center'
  },
  meanLabel: {
    width: '15%',
    textAlign: 'center',
    justifyContent: 'center'
  },
  meanLabelMin: {
    paddingRight: theme.spacing(3)
  },
  meanLabelMax: {
    paddingLeft: theme.spacing(3)
  },
  meanText: {
    fontSize: theme.spacing(8),
    padding: theme.spacing(0, 4)
  },
  meanContainer: {
    position: 'relative',
    width: '100%'
  },
  meanLine: {
    margin: 0,
    flex: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider
  }
}))

export default useStyles
