import { Grid } from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import { useCallback, useMemo } from 'react'
import {
  useAddDraftCombinedResponseOption,
  useBulkAddDraftCombinedResponseOptions,
  useDeleteDraftCombinedResponseOption,
  useDeleteMappingMarketQuestion,
  useGetDraftCombinedQuestion,
  useMapResponseOption,
  useUpdateDraftCombinedResponseOption
} from '../DraftCombinedQuestionState/DraftCombinedQuestion.hooks'
import {
  MappedResponseOption,
  MarketLevelResponseOption
} from '../MappingResponseOption'
import { ResponseOptionsGroup } from '../ResponseOptionsGroup'
import { ResponseOptionsSourceColumnHeader } from './ResponseOptionsSourceColumnHeader'
import { useStyles } from './ResponseOptionsSourceView.styles'

export const ResponseOptionsSourceView = () => {
  const { draftCombinedQuestion } = useGetDraftCombinedQuestion()
  const mappedResponseOptions = useMemo(
    () => draftCombinedQuestion?.mappedResponseOptions ?? [],
    [draftCombinedQuestion?.mappedResponseOptions]
  )
  const mappingOptions = useMemo(
    () =>
      mappedResponseOptions.map(({ id, mappingNumber }) => ({
        mappingId: id,
        mappingNumber
      })),
    [mappedResponseOptions]
  )
  const marketQuestions = draftCombinedQuestion?.marketQuestions ?? []
  const addDraftCombinedResponseOption = useAddDraftCombinedResponseOption()
  const deleteDraftCombinedResponseOption =
    useDeleteDraftCombinedResponseOption()
  const bulkAddDraftCombinedResponseOptions =
    useBulkAddDraftCombinedResponseOptions()
  const updateDraftCombinedResponseOption =
    useUpdateDraftCombinedResponseOption()
  const deleteMappingMarketQuestion = useDeleteMappingMarketQuestion()
  const mapResponseOption = useMapResponseOption()

  const { classes } = useStyles()

  const debouncedCombinedResponseOptionChange = useMemo(() => {
    return debounce((value: string, id: string) => {
      updateDraftCombinedResponseOption(value, id)
    }, 500)
  }, [updateDraftCombinedResponseOption])

  const handleCombinedResponseOptionChange = useCallback(
    (value: string, id: string) => {
      debouncedCombinedResponseOptionChange(value, id)
    },
    [debouncedCombinedResponseOptionChange]
  )

  const handleCombinedResponseOptionPaste = useCallback(
    async ({
      id,
      insertAt,
      updatedValue,
      newValues
    }: {
      id: string
      insertAt: number
      updatedValue: string
      newValues: string[]
    }) => {
      handleCombinedResponseOptionChange(updatedValue, id)
      await bulkAddDraftCombinedResponseOptions(newValues, insertAt)
    },
    [bulkAddDraftCombinedResponseOptions, handleCombinedResponseOptionChange]
  )

  const handleDeleteMappedResponseOption = useCallback(
    (id: string) => {
      deleteDraftCombinedResponseOption(id)
    },
    [deleteDraftCombinedResponseOption]
  )

  const handleDeleteMarketQuestion = useCallback(
    (id: string) => () => {
      deleteMappingMarketQuestion(id)
    },
    [deleteMappingMarketQuestion]
  )

  const getDeleteMarketQuestionIfAllowed = (id: string) =>
    marketQuestions.length > 1 ? handleDeleteMarketQuestion(id) : undefined

  return (
    <Grid
      gap={4}
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      marginTop={4}
    >
      <Grid container display="flex" direction="column">
        <ResponseOptionsSourceColumnHeader
          header="Mapped Response Options"
          subheader="Type your response options below."
        />
        <Grid className={classes.responseOptionsGroupsWrapper}>
          <ResponseOptionsGroup
            title={`A${draftCombinedQuestion?.position} Mapped`}
            onAdd={addDraftCombinedResponseOption}
          >
            {mappedResponseOptions.map((responseOption, index) => {
              return (
                <MappedResponseOption
                  key={responseOption.id}
                  index={index}
                  responseOption={responseOption}
                  cannotDelete={mappedResponseOptions.length === 1}
                  onDelete={handleDeleteMappedResponseOption}
                  onChange={handleCombinedResponseOptionChange}
                  onPaste={handleCombinedResponseOptionPaste}
                  onEnter={addDraftCombinedResponseOption}
                />
              )
            })}
          </ResponseOptionsGroup>
        </Grid>
      </Grid>

      <Grid container display="flex" direction="column">
        <ResponseOptionsSourceColumnHeader
          header="Market-Level Response Options"
          subheader="Use the dropdown numbers below to amend the response positions."
        />
        <Grid className={classes.responseOptionsGroupsWrapper}>
          {marketQuestions.map(
            ({ id: marketQuestionId, market, position, responseOptions }) => {
              return (
                <Grid key={marketQuestionId} marginBottom={3}>
                  <ResponseOptionsGroup
                    title={`A${position} ${market}`}
                    onDelete={getDeleteMarketQuestionIfAllowed(
                      marketQuestionId
                    )}
                  >
                    {responseOptions.map(({ id, mappingId, value = '' }) => {
                      return (
                        <MarketLevelResponseOption
                          key={id}
                          value={value}
                          mappingId={mappingId || ''}
                          mappingOptions={mappingOptions}
                          onMapResponseOption={(mappingId) =>
                            mapResponseOption(id, marketQuestionId, mappingId)
                          }
                        />
                      )
                    })}
                  </ResponseOptionsGroup>
                </Grid>
              )
            }
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
