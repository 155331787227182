import { useCallback, useMemo } from 'react'
import { useAppSelector } from '../App.store'
import { DraftQuestionnaireEntry } from '../data/model/questionnaire'
import { selectResponseOptionsByQuestion } from '../modules/Questionnaire/Questionnaire.slice'
import { flattenEntries } from '../modules/Questionnaire/Questionnaire.utils'
import { getQuestionPrefix } from '../utils/questionnaireUtils'
import { getEntriesWithRuleBasedOnId } from './displayLogic.utils'
import useGetDraftQuestionnaire from './questionnaire/useGetDraftQuestionnaire'

export const useDisplayLogicDetector = () => {
  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()

  const flatDraftQuestionnaireEntries = useMemo(
    () => flattenEntries(draftQuestionnaireEntries),
    [draftQuestionnaireEntries]
  )

  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const getQPrefixForQsWithDisplayLogicBasedOnId = useCallback(
    (id: string) => {
      const questionsWithLogicBasedOnThisQuestion = getEntriesWithRuleBasedOnId(
        id,
        flatDraftQuestionnaireEntries,
        responseOptionsByQuestion
      )
      return questionsWithLogicBasedOnThisQuestion.map((id) => {
        const { entryType, contextPosition } =
          flatDraftQuestionnaireEntries.find(
            ({ entryId }) => id === entryId
          ) as DraftQuestionnaireEntry
        return getQuestionPrefix(entryType, contextPosition)
      })
    },
    [flatDraftQuestionnaireEntries, responseOptionsByQuestion]
  )

  return getQPrefixForQsWithDisplayLogicBasedOnId
}
