import {
  Checkbox,
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { SectionsRandomisationState } from '../SectionsRandomisation.model'
import { SectionsRandomisationHint } from '../SectionsRandomisationHint'

interface Props {
  isOpened: boolean
  sections: SectionsRandomisationState['sections']
  hasValidSelection: boolean
  onClose: () => void
  onSaveRandomiseSections: () => void
  onCheckSection: (id: string, checked: boolean) => void
}

export const DialogRandomiseSectionsComponent = ({
  isOpened,
  sections,
  hasValidSelection,
  onClose,
  onSaveRandomiseSections,
  onCheckSection
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Dialog
      fullWidth
      title="Randomise sections"
      open={isOpened}
      statusComponent={!hasValidSelection && <SectionsRandomisationHint />}
      onClose={onClose}
      maxWidth={DialogMaxWidth.Sm}
      variant={
        hasValidSelection
          ? DialogVariant.SingleButton
          : DialogVariant.SingleButtonStatus
      }
      primaryButtonText="Save"
      primaryButtonLoading={false}
      primaryButtonDisabled={!hasValidSelection}
      primaryButtonClick={onSaveRandomiseSections}
    >
      <Grid container direction="column">
        <Grid item>
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeM,
              textClasses.highlightBackground,
              textClasses.weightSemiBold
            )}
          >
            Randomise the order of:
          </p>
        </Grid>
        <Grid container direction="column">
          {sections.map(({ id, title, checked }) => (
            <Checkbox
              key={id}
              label={title}
              checked={checked}
              onChange={(e) => onCheckSection(id, e.target.checked)}
            />
          ))}
        </Grid>
      </Grid>
    </Dialog>
  )
}
