import FiveImg from '../../../../../assets/fdchat/results/five.svg'
import FourImg from '../../../../../assets/fdchat/results/four.svg'
import OneImg from '../../../../../assets/fdchat/results/one.svg'
import ThreeImg from '../../../../../assets/fdchat/results/three.svg'
import TwoImg from '../../../../../assets/fdchat/results/two.svg'

export const questionImg: { [key: number]: string } = {
  1: OneImg,
  2: TwoImg,
  3: ThreeImg,
  4: FourImg,
  5: FiveImg
}
