import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  inputContainer: {
    paddingRight: theme.spacing(4)
  },
  inputText: {
    paddingBottom: theme.spacing(1)
  }
})

export default useStyles
