import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './CustomAgeRanges.styles'

interface Props {
  errorMessage?: string
}

export const CustomAgeRangesError = ({ errorMessage = '' }: Props) => {
  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  return (
    <div
      className={classNames(classes.errorMessage, {
        [classes.displayNone]: !errorMessage
      })}
    >
      <Icon
        name={IconName.Cancel}
        size={IconSize.Small}
        iconColor={IconColor.Error}
      />
      <p
        className={classNames(
          textClasses.displayInline,
          textClasses.highlightError,
          textClasses.marginLeft
        )}
      >
        {errorMessage}
      </p>
    </div>
  )
}
