import { useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import {
  FocaldataPreferredApp,
  LoginLocationState,
  appTargetSchema,
  isFocaldataUser,
  projectSetupHost,
  targetDefaults
} from '../utils/authUtils'

const searchSchema = z
  .object({
    app: appTargetSchema.nullable().optional().catch(undefined),
    target: z.string().nullable().optional()
  })
  .transform((data) => ({
    ...data,
    target: data.target ?? targetDefaults[data.app ?? 'dashboard']
  }))

export function useLoginRedirect() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const loginRedirect = ({ firstLogin }: LoginLocationState = {}) => {
    // giving the app specified in the URL priority over the cookie
    const lookup = {
      app: searchParams.get('app') ?? FocaldataPreferredApp.get(),
      target: searchParams.get('target')
    }
    const { app, target } = searchSchema.parse(lookup)

    switch (app) {
      case 'dashboard':
        FocaldataPreferredApp.set('dashboard')
        navigate(target, { state: { firstLogin } })
        break
      case 'coworker':
      case 'project_setup':
        FocaldataPreferredApp.set('coworker')
        window.location.href =
          projectSetupHost + '/' + target.replace(/^\/+/g, '')
        break
      case 'audience_picker':
        const isInsideIframe = window.self !== window.parent
        if (isInsideIframe) {
          window.parent.postMessage('reload', {
            targetOrigin:
              process.env.NODE_ENV === 'development'
                ? '*'
                : window.location.origin
          })
        } else {
          window.location.assign(target)
        }
        break
      default:
        // Only showing the choose app screen to focaldata users until we release the coworker app
        if (isFocaldataUser()) {
          navigate('/choose-app?target=' + target, { state: { firstLogin } })
        } else {
          navigate(target, { state: { firstLogin } })
        }
    }
  }

  return { loginRedirect }
}
