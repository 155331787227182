import { Paragraph } from 'docx'
import {
  DraftQuestionnaireEntry,
  DraftTextCardItem
} from '../../data/model/questionnaire'
import { addMediaIfNeeded } from './addMediaUrlIfNeeded'
import { createMultilinedTextRuns } from './createMultilinedTextRuns'

export const createEntryContentTextCard = async (
  entry: DraftQuestionnaireEntry
): Promise<Paragraph[]> => {
  const entryItem = entry.entryItem as DraftTextCardItem
  const { subtitle } = entryItem.textCard

  const paragraphs = subtitle
    ? [
        new Paragraph({
          children: createMultilinedTextRuns(subtitle)
        })
      ]
    : []

  await addMediaIfNeeded(entryItem, paragraphs)

  return paragraphs
}
