import * as tus from 'tus-js-client'
import { AccessToken } from '../../../utils/authUtils'

const uploadFile: (file: File) => Promise<string | null> = async (file) => {
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      endpoint: process.env.REACT_APP_TUSD_DOMAIN,
      metadata: {
        filename: file.name,
        filetype: file.type
      },
      headers: {
        ...AccessToken.requestHeaders()
      },
      onError: (error) => {
        reject(error)
      },
      onSuccess: async () => {
        resolve(upload.url)
      }
    })

    upload.start()
  })
}

export default uploadFile
