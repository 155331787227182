import { Button, Grid } from '@focaldata/cin-ui-components'
import { useNavigate } from 'react-router-dom'
import { safeObject } from '../../../utils/HelperFunctions'
import { useFdChatValidation } from './FdChat.hooks'
import { FdChatPagePath } from './FdChat.model'
import useStyles from './FdChat.styles'
import { steps } from './FdChatBreadcrumbs'
import { FDChatLaunchButton } from './FdChatLaunch'

export const FdChatPageFooter = () => {
  const { classes } = useStyles()
  const { objectivesValid, audienceValid, questionnaireValid } =
    useFdChatValidation()
  const navigate = useNavigate()
  const currentPage = window.location.pathname
    .split('/')
    .pop() as FdChatPagePath

  const nextButtonEnabledByRoute = safeObject(
    {
      audience: audienceValid && questionnaireValid,
      objectives: objectivesValid,
      review: audienceValid && objectivesValid && questionnaireValid
    },
    false
  )

  const nextButtonEnabled = nextButtonEnabledByRoute[currentPage]
  const shouldShowLaunchButton = currentPage === 'review'
  const nextStep =
    steps[steps.findIndex((step) => step.path === currentPage) + 1]

  return (
    <Grid
      display="flex"
      justifyContent="end"
      alignItems="center"
      className={classes.footerContainer}
    >
      <Grid item>
        {shouldShowLaunchButton ? (
          <FDChatLaunchButton disabled={!nextButtonEnabled} />
        ) : (
          <Button
            disabled={!nextButtonEnabled}
            onClick={() => navigate(nextStep.path)}
          >
            {nextStep.name}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
