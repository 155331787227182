import { textStyleUtils } from '@focaldata/cin-ui-components'
import React, { memo } from 'react'

import useStyles from './SectionEntry.styles'

interface Props {
  title: string
}

const SectionEntryFooter: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const { title } = props

  return (
    <div className={classes.sectionLayoutFooter}>
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeM,
          textClasses.highlightBackground,
          textClasses.displayBlock
        )}
      >
        End of {title}
      </p>
    </div>
  )
}

export default memo(SectionEntryFooter)
