export const asyncMap = <T, O>(
  arr: T[],
  asyncFn: (t: T, index: number) => Promise<O>
): Promise<O[]> => {
  return Promise.all(arr.map(asyncFn))
}

export const asyncFlatMap = async <T, O>(
  arr: T[],
  asyncFn: (t: T) => Promise<O[]>
): Promise<O[]> => {
  return Promise.all((await asyncMap(arr, asyncFn)).flat())
}
