import {
  CircularProgress,
  Grid,
  Skeleton,
  Text
} from '@focaldata/cin-ui-components'
import { useFdChatProgress } from '../../../modules/FdChat/FdChatProgressProvider.context'

const FdChatProgress = () => {
  const { interviewsCollected, interviewsWanted, loading } = useFdChatProgress()

  if (loading) {
    return <Skeleton variant="rectangular" width={130} height={30} />
  }

  return (
    <Grid container gap={'10px'} alignItems={'center'}>
      <Text>
        Completes: {interviewsCollected}/{interviewsWanted}
      </Text>
      <CircularProgress
        current={interviewsCollected}
        total={interviewsWanted}
        size={24}
      />
    </Grid>
  )
}

export default FdChatProgress
