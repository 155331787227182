import isEmpty from 'lodash/isEmpty'
import { EntryType } from '../../../../data/model/questionnaire'
import {
  ImportQuestionsEntry,
  ImportQuestionsSectionItem
} from './ImportQuestionsPicker.query'

const isEmptySection = (entry: ImportQuestionsEntry) => {
  if (entry.entryType === EntryType.SectionEntryType) {
    return isEmpty((entry.entryItem as ImportQuestionsSectionItem).entries)
  }
  return false
}

export const flatImportedQuestions = (entries: ImportQuestionsEntry[]) => {
  return entries
    .filter((entry) => !isEmptySection(entry))
    .flatMap((entry) => {
      return entry.entryType === EntryType.SectionEntryType
        ? (entry.entryItem as ImportQuestionsSectionItem).entries
        : entry
    })
}
