import {
  Theme,
  additionalPalette,
  makeStyles
} from '@focaldata/cin-ui-components'
import { isMasterSurveyPage } from '../../utils/HelperFunctions'

interface StyleProps {
  drawerWidth: number
  topPosition?: number
  showFooter: boolean
}

const sideBarWidthInSpacingUnits = 42
const footerHeight = 56

const useStyles = makeStyles<{ styleProps: StyleProps }>()((
  theme: Theme,
  { styleProps: props }
) => {
  const headerHeightInSpacingUnits = isMasterSurveyPage() ? 24 : 13.25
  const drawerHeightShowFooter = `calc(100% - ${theme.spacing(
    headerHeightInSpacingUnits
  )} - ${footerHeight}px)`
  const drawerHeight = `calc(100% - ${theme.spacing(
    headerHeightInSpacingUnits
  )})`

  return {
    root: {
      display: 'flex'
    },
    drawer: {
      width: props.drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      top: theme.spacing(headerHeightInSpacingUnits),
      padding: theme.spacing(4.5, 3),
      borderLeft: `1px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.default,
      maxWidth: props.drawerWidth,
      height: props.showFooter ? drawerHeightShowFooter : drawerHeight,
      borderBottom: props.showFooter
        ? `1px solid ${additionalPalette.lightGrey.main}`
        : 'inherit',
      overflowY: 'auto'
    },
    contentComplete: {
      flexGrow: 1,
      width: '100%',
      padding: theme.spacing(5.25, 4),
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shorter
      }),
      marginRight: 0
    },
    contentCompleteShift: {
      flexGrow: 1,
      width: `calc(100% - ${theme.spacing(sideBarWidthInSpacingUnits)})`,
      padding: theme.spacing(5.25, 4),
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginRight: props.drawerWidth - parseInt(theme.spacing(2), 10),
      flexShrink: 1
    }
  }
})

export default useStyles
