import { gql } from '@apollo/client/core'

export interface SearchProjectsData {
  searchProjects: SearchProjects
}

export interface ImportQuestionProject {
  name: string
  projectId: string
  surveys: {
    surveyId: string
  }[]
}

export interface SearchProjects {
  projects: ImportQuestionProject[]
}

export const IMPORT_QUESTIONS_SEARCH_PROJECTS = gql`
  query importQuestionSearchProjects($accountId: ID!) {
    searchProjects(
      accountId: $accountId
      offset: 0
      limit: 100
      sorting: { sortBy: SinceDate, order: Desc }
    ) {
      projects {
        name
        projectId
        surveys {
          surveyId
        }
      }
    }
  }
`
