import { Navigate, Route, Routes } from 'react-router-dom'
import ErrorPage, { ErrorType } from '../../../controls/ErrorPage'
import { AudienceMappingPage } from './AudienceMappingPage'
import { MarketsPage } from './MarketsPage'
import { MasterSurveyPage } from './MasterSurveyPage'
import { MultiMarketPage } from './MultiMarketPage'
import { ResponseOptionsMappingPage } from './ResponseOptionsMappingPage'

export const MultiMarketRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MultiMarketPage />}>
        <Route index element={<Navigate to="markets" replace />} />
        <Route path="markets">
          <Route index element={<MarketsPage />} />
          <Route path=":marketId/audience" element={<h1>Market audience</h1>} />
          <Route path=":marketId/results" element={<h1>Market results</h1>} />
        </Route>
        <Route path="mapping">
          <Route index element={<AudienceMappingPage />} />
          <Route
            path="question/:questionId"
            element={<ResponseOptionsMappingPage />}
          />
        </Route>
        <Route path="master/:surveyId" element={<MasterSurveyPage />} />
        <Route path="results" element={<h1>Dashboard</h1>} />
      </Route>
      <Route
        path="*"
        element={<ErrorPage errorType={ErrorType.NotFound404} />}
      />
    </Routes>
  )
}
