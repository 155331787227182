import { useMutation } from '@apollo/client'
import {
  Dialog,
  DialogVariant,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'
import React, { useState } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { LoggerErrorType } from '../../../data/logger'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { IMPORT_QUESTIONNAIRE_ENTRIES } from './ImportQuestions.mutations'
import ImportQuestionsPicker from './ImportQuestionsPicker/ImportQuestionsPicker.container'
import ImportQuestionsSurveyPicker from './ImportQuestionsSurveyPicker/ImportQuestionsSurveyPicker.container'

interface Props {
  position: number
  refetchQuestionnaire: () => void
  onClose: () => void
}

const ImportQuestions: React.FC<Props> = ({
  onClose,
  refetchQuestionnaire,
  position
}: Props) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [selectedSurveyIds, setSelectedSurveyIds] = useState<
    [typeof projectId, typeof surveyId] | undefined
  >()
  const [questionSelection, setQuestionSelection] = useState<string[]>([])
  const [importing, setImporting] = useState<boolean>(false)

  const [importQuestionnaireEntries] = useMutation(
    IMPORT_QUESTIONNAIRE_ENTRIES,
    {
      context: { clientName: 'questionnaire' },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'importQuestionnaireEntriesMutation',
          error
        )
      }
    }
  )

  const [selectedProjectId, selectedSurveyId] = selectedSurveyIds || ['', '']

  const handleImportQuestions = async (): Promise<void> => {
    if (selectedSurveyIds) {
      setImporting(true)
      await importQuestionnaireEntries({
        variables: {
          input: {
            projectId,
            sourceProjectId: selectedProjectId,
            entryIds: questionSelection,
            position
          }
        }
      })
      setImporting(false)
      // this doesn't seem to be quite right
      // TODO: importQuestionnaireEntriesV2 should return the list of target entries to get rid of refetching; currently, it returns the list of source entries
      refetchQuestionnaire()
      onClose()
      LogAmplitudeEvent(EventType.ImportedFromSurvey, {
        source: { projectId: selectedProjectId, surveyId: selectedSurveyId },
        destination: { projectId, surveyId },
        nrOfQuestions: questionSelection.length,
        position
      })
    }
  }

  const handleBackButton = (): void => {
    setSelectedSurveyIds(undefined)
    setQuestionSelection([])
  }

  const isImportButtonDisabled =
    selectedSurveyIds === undefined || questionSelection.length === 0

  return (
    <Dialog
      onClose={onClose}
      open
      statusOnRight
      fullWidth
      variant={DialogVariant.SingleButtonStatus}
      statusComponent={
        <Text size={TextSize.ms} highlight={TextHighlight.Background}>
          {isImportButtonDisabled
            ? undefined
            : `${questionSelection.length} selected`}
        </Text>
      }
      primaryButtonLoading={importing}
      primaryButtonDisabled={isImportButtonDisabled}
      title="Import from existing survey"
      primaryButtonText="Import"
      primaryButtonClick={handleImportQuestions}
    >
      {!selectedSurveyIds && (
        <ImportQuestionsSurveyPicker
          onSurveypicked={(projectId, surveyId) => {
            setSelectedSurveyIds([projectId, surveyId])
          }}
        />
      )}
      {selectedSurveyIds && (
        <ImportQuestionsPicker
          projectId={selectedProjectId}
          surveyId={selectedSurveyId}
          onBackButton={handleBackButton}
          questionSelection={questionSelection}
          setQuestionSelection={setQuestionSelection}
        />
      )}
    </Dialog>
  )
}

export default React.memo(ImportQuestions)
