import {
  DraftEntryResponseOption,
  DraftMaskingRule,
  DraftMatrixRow,
  Route
} from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftForkItem,
  DraftLogicClauseProposition,
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  DraftTextCardItem,
  ForkValidationError,
  MatrixRowValidationError,
  MatrixRowValidationErrors,
  QuestionKind,
  QuestionSettingCode,
  QuestionTypeCode,
  QuestionValidationError,
  ResponseOptionValidationError,
  ResponseOptionsValidationErrors,
  SliderNumbers,
  SliderValidationError,
  ValidationError
} from '../../data/model/questionnaire'
import { getTotalForkPercentage } from '../../utils/questionnaireUtils'
import { QuestionnaireState } from './Questionnaire.slice'

export interface KeyValue {
  key: string
  value: string
}

export const hasErrors = (error: ValidationError | undefined) => {
  return !!(
    error?.errors?.length ||
    error?.responseOptionErrors?.length ||
    error?.rowErrors?.length ||
    error?.sliderErrors?.length
  )
}

const validateQuestionTitle = (text: string) => {
  if (text === '') {
    return [QuestionValidationError.EmptyQuestionTextError]
  }
  return []
}

export const getDuplicateKeys = (keyValues: KeyValue[]) => {
  const duplicates = keyValues.reduce((acc, curr, currentIndex, array) => {
    const duplicates = array.filter(
      (item, index) => currentIndex !== index && item.value === curr.value
    )
    if (duplicates.length) {
      acc.push(curr.key)
    }
    return acc
  }, [] as string[])
  return duplicates
}

export const validateSliderNumbers = (sliderNumbers: SliderNumbers) => {
  const errors: SliderValidationError[] = []
  if (sliderNumbers.min >= sliderNumbers.max) {
    errors.push(SliderValidationError.SliderInvalidRangeError)
  }
  if (
    sliderNumbers.step > Math.ceil((sliderNumbers.max - sliderNumbers.min) / 2)
  ) {
    errors.push(SliderValidationError.SliderInvalidStepError)
  }
  return errors
}

export const validateResponseOptionText: (
  responseOptionLk: string,
  text: string,
  responseOptions: KeyValue[]
) => ResponseOptionValidationError[] = (
  responseOptionLk: string,
  text: string,
  responseOptions: KeyValue[]
) => {
  const responseErrors: ResponseOptionValidationError[] = []
  if (text === '') {
    responseErrors.push(
      ResponseOptionValidationError.EmptyResponseOptionValueError
    )
  }

  if (getDuplicateKeys(responseOptions).includes(responseOptionLk)) {
    responseErrors.push(
      ResponseOptionValidationError.DuplicateResponseOptionValueError
    )
  }

  return responseErrors
}

const validateRouting = (
  currentEntryPosition: number,
  route: Route,
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const routingErrors: ResponseOptionValidationError[] = []
  const targetQuestion = flatAllEntries.find(
    ({ number }) => number === route.targetNumber
  )
  if (targetQuestion && targetQuestion.position < currentEntryPosition) {
    routingErrors.push(ResponseOptionValidationError.InvalidRoute)
  }
  return routingErrors
}

const hasDisplayLogicError = (
  targetEntryPosition: number,
  maskingRules: DraftMaskingRule[],
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  return maskingRules.some((maskingRule) => {
    const sourceQuestion = flatAllEntries.find(
      ({ entryId }) =>
        entryId ===
        (maskingRule.maskingKeys[0].matrixTitleLk ||
          maskingRule.maskingKeys[0].questionLk)
    )

    const missingSourceQuestion = !sourceQuestion
    const sourceQuestionIsAfterTargetQuestion =
      sourceQuestion &&
      sourceQuestion.questionKind === QuestionKind.QuestionnaireKind &&
      targetEntryPosition < sourceQuestion.position

    if (missingSourceQuestion || sourceQuestionIsAfterTargetQuestion) {
      return true
    }
  })
}

export const validateResponseOptions: (
  responseOptions: DraftEntryResponseOption[],
  currentEntryPosition?: number,
  flatAllEntries?: DraftQuestionnaireEntry[]
) => ResponseOptionsValidationErrors[] = (
  responseOptions: DraftEntryResponseOption[],
  currentEntryPosition?: number,
  flatAllEntries?: DraftQuestionnaireEntry[]
) => {
  const responseOptionsValidationErrors: ResponseOptionsValidationErrors[] = []
  responseOptions.forEach((responseOption) => {
    const currentResponseErrors: ResponseOptionsValidationErrors = {
      responseOptionLk: responseOption.responseOptionLk,
      errors: []
    }
    const textValidationErrors = validateResponseOptionText(
      responseOption.responseOptionLk,
      responseOption.responseOption?.value ?? '',
      responseOptions.map((r) => ({
        key: r.responseOptionLk,
        value: r.responseOption?.value ?? ''
      }))
    )

    if (textValidationErrors.length)
      currentResponseErrors.errors.push(...textValidationErrors)

    if (
      currentEntryPosition !== undefined &&
      responseOption.route &&
      flatAllEntries
    ) {
      const hasRoutingError = validateRouting(
        currentEntryPosition,
        responseOption.route,
        flatAllEntries
      )
      if (hasRoutingError.length)
        currentResponseErrors.errors.push(...hasRoutingError)
    }

    if (
      currentEntryPosition !== undefined &&
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      responseOption.maskingRules &&
      flatAllEntries
    ) {
      const displayLogicError = hasDisplayLogicError(
        currentEntryPosition,
        responseOption.maskingRules,
        flatAllEntries
      )
      if (displayLogicError)
        currentResponseErrors.errors.push(
          ResponseOptionValidationError.InvalidDisplayLogic
        )
    }
    if (currentResponseErrors.errors.length)
      responseOptionsValidationErrors.push(currentResponseErrors)
  })
  return responseOptionsValidationErrors
}

export const validateFreeTextResponseRange = (min: number, max: number) => {
  if (min > max) {
    return [QuestionValidationError.ResponseRangeMinCharactersExceedError]
  }
  return []
}

export const validateFreeTextEntry = (entryItem: DraftQuestionItem) => {
  if (entryItem.questionTypeCode === QuestionTypeCode.FreeText) {
    const characterLimit = entryItem.settingValues.find(
      (settingValue) => settingValue.code === QuestionSettingCode.CharacterLimit
    )?.value
    const characterMin = entryItem.settingValues.find(
      (settingValue) =>
        settingValue.code === QuestionSettingCode.CharacterMinimum
    )?.value

    if (characterMin !== undefined && characterLimit !== undefined) {
      return validateFreeTextResponseRange(
        parseInt(characterMin, 10),
        parseInt(characterLimit, 10)
      )
    }
  }
  return []
}

export const validateForkEntry = (entryItem: DraftForkItem) => {
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entryItem.fork) {
    const totalPercentage = getTotalForkPercentage(entryItem.fork)
    if (totalPercentage !== 100) {
      return {
        forkErrors: [ForkValidationError.ForkBranchesInvalidTotalError]
      } as ValidationError
    }
  }
  return undefined
}

export const validateQuestionLogic = (
  targetEntryPosition: number,
  questionLogic: DraftLogicClauseProposition[][],
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const hasInvalidlogic = questionLogic.some((logicClause) =>
    logicClause.some((logicProposition) => {
      const { matrixTitleLk, questionLk } = logicProposition.proposition as {
        matrixTitleLk?: string
        questionLk?: string
      }

      const sourceQuestionIdentifier = matrixTitleLk || questionLk

      const sourceQuestion = flatAllEntries.find(
        ({ entryId }) => entryId === sourceQuestionIdentifier
      )

      const missingSourceQuestion = !sourceQuestion
      const sourceQuestionIsAfterTargetQuestion =
        sourceQuestion &&
        sourceQuestion.questionKind === QuestionKind.QuestionnaireKind &&
        targetEntryPosition < sourceQuestion.position

      if (missingSourceQuestion || sourceQuestionIsAfterTargetQuestion) {
        return true
      }
    })
  )
  if (hasInvalidlogic) {
    return QuestionValidationError.QuestionLogicInvalidQuestionError
  }

  return undefined
}

export const validateQuestionEntry = (
  currentEntryPosition: number,
  entryItem: DraftQuestionItem,
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const validationError: ValidationError = {
    errors: [],
    responseOptionErrors: [],
    sliderErrors: []
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entryItem.questionLogic?.length) {
    const questionLogicError = validateQuestionLogic(
      currentEntryPosition,
      entryItem.questionLogic,
      flatAllEntries
    )
    if (questionLogicError) validationError.errors?.push(questionLogicError)
  }
  if (entryItem.question?.text === '') {
    validationError.errors?.push(QuestionValidationError.EmptyQuestionTextError)
  }
  if (entryItem.questionTypeCode === QuestionTypeCode.Scale) {
    if (entryItem.questionScale?.range) {
      validationError.sliderErrors?.push(
        ...validateSliderNumbers(entryItem.questionScale.range)
      )
    }
  }
  if (entryItem.questionTypeCode === QuestionTypeCode.FreeText) {
    validationError.errors?.push(...validateFreeTextEntry(entryItem))
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  validationError?.responseOptionErrors?.push(
    ...validateResponseOptions(
      entryItem.responseOptions,
      currentEntryPosition,
      flatAllEntries
    )
  )
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.errors?.length ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.responseOptionErrors?.length || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.sliderErrors?.length
  ) {
    return validationError
  }
  return undefined
}

export const validateTextCardEntry = (
  currentEntryPosition: number,
  entryItem: DraftTextCardItem,
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const validationError: ValidationError = {
    errors: [],
    responseOptionErrors: [],
    sliderErrors: []
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entryItem.questionLogic?.length) {
    const questionLogicError = validateQuestionLogic(
      currentEntryPosition,
      entryItem.questionLogic,
      flatAllEntries
    )
    if (questionLogicError) validationError.errors?.push(questionLogicError)
  }
  if (entryItem.textCard.title === '') {
    validationError.errors?.push(QuestionValidationError.EmptyQuestionTextError)
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (validationError?.errors?.length) {
    return validationError
  }
  return undefined
}

export const validateMatrixRowText: (
  questionLk: string,
  text: string,
  rows: KeyValue[]
) => MatrixRowValidationError[] = (
  questionLk: string,
  text: string,
  rows: KeyValue[]
) => {
  const rowErrors: MatrixRowValidationError[] = []

  if (text === '') {
    rowErrors.push(MatrixRowValidationError.EmptyMatrixRowError)
  }

  if (getDuplicateKeys(rows).includes(questionLk)) {
    rowErrors.push(MatrixRowValidationError.DuplicateMatrixRowError)
  }

  return rowErrors
}

export const validateMatrixRows: (
  matrixRows: DraftMatrixRow[],
  currentEntryPosition?: number,
  flatAllEntries?: DraftQuestionnaireEntry[]
) => MatrixRowValidationErrors[] = (
  matrixRows: DraftMatrixRow[],
  currentEntryPosition,
  flatAllEntries
) => {
  const rowValidationErrors: MatrixRowValidationErrors[] = []
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  matrixRows?.forEach((matrixRow) => {
    const currentRowErrors: MatrixRowValidationErrors = {
      questionLk: matrixRow.questionLk,
      errors: []
    }
    const textErrors = validateMatrixRowText(
      matrixRow.questionLk,
      matrixRow.question?.text ?? '',
      matrixRows.map((r) => ({
        key: r.questionLk,
        value: r.question?.text ?? ''
      }))
    )
    if (textErrors.length) currentRowErrors.errors.push(...textErrors)

    if (
      currentEntryPosition !== undefined &&
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      matrixRow.maskingRules &&
      flatAllEntries
    ) {
      const displayLogicError = hasDisplayLogicError(
        currentEntryPosition,
        matrixRow.maskingRules,
        flatAllEntries
      )
      if (displayLogicError) {
        currentRowErrors.errors.push(
          MatrixRowValidationError.InvalidDisplayLogic
        )
      }
    }
    if (currentRowErrors.errors.length)
      rowValidationErrors.push(currentRowErrors)
  })
  return rowValidationErrors
}

export const validateMatrixEntry = (
  currentEntryPosition: number,
  entryItem: DraftMatrixItem,
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const validationError: ValidationError = {
    errors: [],
    responseOptionErrors: [],
    rowErrors: []
  }
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entryItem.questionLogic?.length) {
    const questionLogicError = validateQuestionLogic(
      currentEntryPosition,
      entryItem.questionLogic,
      flatAllEntries
    )
    if (questionLogicError) validationError.errors?.push(questionLogicError)
  }
  validationError.errors = validateQuestionTitle(
    entryItem.matrixTitle?.title ?? ''
  )
  validationError.responseOptionErrors?.push(
    ...validateResponseOptions(
      entryItem.responseOptions,
      currentEntryPosition,
      flatAllEntries
    )
  )

  validationError.rowErrors?.push(
    ...validateMatrixRows(
      entryItem.matrixRows,
      currentEntryPosition,
      flatAllEntries
    )
  )
  if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.errors?.length ||
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.responseOptionErrors?.length || // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationError?.rowErrors?.length
  )
    return validationError
  return undefined
}

export const updateResponsesValidationErrorsState = (
  questionLk: string,
  state: QuestionnaireState,
  flatAllEntries?: DraftQuestionnaireEntry[]
) => {
  const currentError = state.validationErrorsByQuestion[questionLk]
  const responseErrors = validateResponseOptions(
    state.responseOptionsByQuestion[questionLk],
    flatAllEntries?.find(({ entryId }) => entryId === questionLk)?.position,
    flatAllEntries
  )
  const newError = {
    ...currentError,
    responseOptionErrors: responseErrors
  }
  state.validationErrorsByQuestion[questionLk] = newError
  if (!hasErrors(newError)) {
    delete state.validationErrorsByQuestion[questionLk]
  }
}

export const updateRowsValidationErrorsState = (
  matrixTitleLk: string,
  state: QuestionnaireState,
  flatAllEntries?: DraftQuestionnaireEntry[]
) => {
  const currentError = state.validationErrorsByQuestion[matrixTitleLk]
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!state.matrixRowsByQuestion[matrixTitleLk]) {
    return
  }
  const rowErrors = validateMatrixRows(
    state.matrixRowsByQuestion[matrixTitleLk],
    flatAllEntries?.find(({ entryId }) => entryId === matrixTitleLk)?.position,
    flatAllEntries
  )
  const newError = { ...currentError, rowErrors }

  state.validationErrorsByQuestion[matrixTitleLk] = newError
  if (!hasErrors(newError)) {
    delete state.validationErrorsByQuestion[matrixTitleLk]
  }
}
