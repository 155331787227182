import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  editInfo: {
    '&:hover svg': {
      color: theme.palette.primary.main
    }
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3)
  },
  icon: {
    marginTop: theme.spacing(-0.25),
    marginLeft: theme.spacing(-2)
  }
})

export default useStyles
