import { questionnaireGql } from '../../../gql-gen/questionnaire'

const DRAFT_MASKING_RULE = questionnaireGql(/* GraphQL */ `
  fragment DraftMaskingRule on DraftMaskingRule {
    clause
    maskingKeys {
      questionLk
      responseOptionLk
      matrixTitleLk
    }
    negated
    __typename
  }
`)

export default DRAFT_MASKING_RULE
