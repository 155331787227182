import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    border: `1px solid ${additionalPalette.lightGrey.main}`,
    padding: theme.spacing(0.5, 0.5, 2, 3)
  },
  linkContainer: {
    padding: theme.spacing(0, 3)
  }
})

export default useStyles
