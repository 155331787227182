import {
  Grid,
  LinkButton,
  Text,
  TextDisplay,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { MAILTO_LINK } from '../../modules/Questionnaire/constants'
import { formatThousandsWithCommas } from '../../utils/HelperFunctions'
import dayjs from '../../utils/dayjs'
import useStyles from './styles'

interface Props {
  surveyLaunchDate?: string
  totalCost?: number
  showErrorMessage?: boolean
}

const DialogPurchaseContent: React.FC<Props> = (props: Props) => {
  const { surveyLaunchDate, totalCost, showErrorMessage } = props
  const { classes } = useStyles()

  const goToTermsPage = () => {
    const domain = process.env.PUBLIC_URL
    const fdSurveyUrl = `${domain}/terms`
    window.open(fdSurveyUrl, '_blank')
  }

  const todayDate = new Date()
  const isSurveyLaunchToday = dayjs(surveyLaunchDate).isSame(todayDate, 'day')

  const displayDateSurveyText = (isSurveyLaunchToday: boolean) => {
    if (isSurveyLaunchToday) {
      return (
        <Text
          size={TextSize.m}
          highlight={TextHighlight.Background}
          weight={TextWeight.SemiBold}
          display={TextDisplay.Inline}
        >
          today.{' '}
        </Text>
      )
    }
    return (
      <>
        <Text
          size={TextSize.m}
          display={TextDisplay.Inline}
          highlight={TextHighlight.Background}
        >
          on{' '}
        </Text>
        <Text
          size={TextSize.m}
          highlight={TextHighlight.Background}
          weight={TextWeight.SemiBold}
          display={TextDisplay.Inline}
        >
          {surveyLaunchDate}.{' '}
        </Text>
      </>
    )
  }

  // TODO: Update terms and conditions once available
  return (
    <Grid container direction="column">
      <Grid item>
        <Text size={TextSize.m} weight={TextWeight.SemiBold}>
          You&apos;re about to finalise your project.
        </Text>
      </Grid>
      <Grid item className={classes.infoCost}>
        <Text
          size={TextSize.m}
          display={TextDisplay.Inline}
          highlight={TextHighlight.Background}
        >
          Your project will go live{' '}
        </Text>
        {displayDateSurveyText(isSurveyLaunchToday)}
        <Text
          size={TextSize.m}
          display={TextDisplay.Inline}
          highlight={TextHighlight.Background}
        >
          The total estimated cost is{' '}
        </Text>
        <Text
          size={TextSize.m}
          highlight={TextHighlight.Background}
          display={TextDisplay.Inline}
          weight={TextWeight.SemiBold}
        >
          £{totalCost && formatThousandsWithCommas(totalCost)} (GBP)
        </Text>
        <Text
          size={TextSize.m}
          display={TextDisplay.Inline}
          highlight={TextHighlight.Background}
        >
          .
        </Text>
        <Text size={TextSize.m} highlight={TextHighlight.Background}>
          You will start incurring costs once the project is live.
        </Text>
      </Grid>
      <Grid item>
        <Text size={TextSize.m} highlight={TextHighlight.Background}>
          By clicking purchase, you agree to our
          <LinkButton
            size={TextSize.m}
            onClick={goToTermsPage}
            highlight={TextHighlight.Background}
            underlined
          >
            Terms and Conditions
          </LinkButton>
        </Text>
      </Grid>
      <Grid item>
        <Text size={TextSize.m} highlight={TextHighlight.Background}>
          If you need help before confirming, please contact{' '}
          <a className={classes.mailtoLink} href={`mailto:${MAILTO_LINK}`}>
            {MAILTO_LINK}
          </a>
        </Text>
      </Grid>
      {showErrorMessage && (
        <>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item>
            <Text size={TextSize.m} highlight={TextHighlight.Error}>
              Your survey has failed to launch.{' '}
            </Text>
            <Text size={TextSize.m} highlight={TextHighlight.Error}>
              Please try clicking Purchase again to retry launching your survey.
            </Text>
            <Text size={TextSize.m} highlight={TextHighlight.Error}>
              If your survey still fails to launch, please contact{' '}
              <a className={classes.mailtoLink} href={`mailto:${MAILTO_LINK}`}>
                {MAILTO_LINK}
              </a>
            </Text>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default DialogPurchaseContent
