import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  root: {
    '&.MuiChip-root': {
      marginLeft: theme.spacing(2),
      borderRadius: theme.spacing(0.25)
    }
  }
})

export default useStyles
