import { useCallback } from 'react'
import { ChartEntry, QuestionType } from '../data/model/chart'

const markerStoredRegex = /\{([AQ])-(\d+)\}/g

const getIsSourceEntry = (
  sourceNumberInTitle: number,
  pipingPrefixInTitle: 'A' | 'Q'
): ((chartEntry: ChartEntry) => boolean) => {
  return ({ questionType, isMultipleChoice, entryNumber }) => {
    const hasSameAsSourceEntryNumber = entryNumber === sourceNumberInTitle

    if (!hasSameAsSourceEntryNumber) {
      return false
    }

    if (isMultipleChoice) {
      return false
    }

    const hasTitleAudienceSource = pipingPrefixInTitle === 'A'

    return hasTitleAudienceSource
      ? questionType === QuestionType.custom_audience ||
          questionType === QuestionType.standard_audience
      : questionType === QuestionType.basic ||
          questionType === QuestionType.free_text
  }
}

export const usePipingMarkerTransformer = (chartEntries: ChartEntry[]) => {
  const getCardTitleDisplayView = useCallback(
    (cardTitle: string): string => {
      if (!cardTitle) {
        return cardTitle
      }

      let title = cardTitle

      title.match(markerStoredRegex)?.forEach((marker) => {
        // in case of match marker will have the following format: `{A-${number}}` | `{Q-${number}}`
        const prefix = marker[1] as 'A' | 'Q'
        const sourceNumber = Number(marker.slice(3, -1))
        const isSourceEntry = getIsSourceEntry(sourceNumber, prefix)

        const sourceEntry = chartEntries.find(isSourceEntry)

        let markerToShow = ''

        if (!sourceEntry) {
          // if source wasn't found, it means it was deleted
          // in this case we'll show empty curly braces showing to the user this place was used for piping
          markerToShow = '{}'
        } else {
          // we'll show marker even if source goes after the target
          markerToShow = `{${prefix}${sourceEntry.contextPosition}}`
        }
        title = title.replace(marker, markerToShow)
      })

      return title
    },
    [chartEntries]
  )

  return {
    getCardTitleDisplayView
  }
}
