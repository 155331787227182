import { useCallback } from 'react'
import { draftQuestionnaireRefetchQuery } from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import {
  QuestionSettingCode,
  SettingValue
} from '../../data/model/questionnaire'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import { useDraftQuestionnaireEntry } from './useDraftQuestionnaireEntry'
import { useUpdateQuestionnaireEntry } from './useUpdateQuestionnaireEntry'

export const useSetSectionSettingValue = (sectionId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const section = useDraftQuestionnaireEntry(sectionId)
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(sectionId, {
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'setSectionSettingValue',
        error
      )
    }
  })

  const handleSetSectionSettingValue = useCallback(
    async (
      questionSettingCode: QuestionSettingCode,
      settingValue: SettingValue | string
    ): Promise<void> => {
      if (!section) {
        return
      }
      if (!('settingValues' in section.entryItem)) {
        throw new Error('Cannot change settings as missing settingValues')
      }
      const { settingValues } = section.entryItem
      const isExistingSetting = settingValues.some(
        ({ code }) => code === questionSettingCode
      )
      await updateQuestionnaireEntry({
        settingValues: isExistingSetting
          ? settingValues.map(({ code, value }) => {
              return {
                questionSettingCode: code,
                settingValue:
                  code === questionSettingCode ? settingValue : value
              }
            })
          : settingValues
              .map((s) => {
                return {
                  questionSettingCode: s.code,
                  settingValue: s.value
                }
              })
              .concat({
                questionSettingCode,
                settingValue
              })
      })
    },
    [section, updateQuestionnaireEntry]
  )

  return handleSetSectionSettingValue
}
