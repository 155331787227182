export const truncateText: (text: string | undefined) => string = (text) => {
  if (text && text.length > 66) {
    const start = text.slice(0, 32)
    const end = text.slice(text.length - 31, text.length)

    return `${start}...${end}`
  }

  return text || ''
}
