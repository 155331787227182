import { Grid, IconName } from '@focaldata/cin-ui-components'
import { Outlet, useMatch } from 'react-router-dom'
import Subheader from '../../../components/Subheader'
import ErrorPage, { ErrorType } from '../../../controls/ErrorPage'
import TwoChildRowLayout from '../../../layouts/TwoChildRowLayout'
import { useGetProjectByProjectId } from '../../../modules/Project/Project.hooks'
import { ProjectNameAndStatusEditable } from '../../../modules/Project/ProjectNameAndStatus/ProjectNameAndStatus.component'
import { MultiMarketNavigationButton } from './MultiMarketNavigationButton'

const LoadingContent = () => {
  return (
    <div data-testid="loading-screen" className="cover">
      <div className="spinner">
        <div className="static"></div>
      </div>
    </div>
  )
}

export const PROJECT_TITLE_HEADER_HEIGHT = 49
const NAVIGATION_BUTTONS_HEADER_HEIGHT = 62
export const MULTI_MARKET_HEADER_HEIGHT =
  PROJECT_TITLE_HEADER_HEIGHT + NAVIGATION_BUTTONS_HEADER_HEIGHT

export const MultiMarketPage = () => {
  const { project, loading, error } = useGetProjectByProjectId()
  const responseOptionsMappingPathMatch = useMatch(
    'project/:projectId/mapping/question/:questionId'
  )

  if (loading) {
    return <LoadingContent />
  }

  if (error) {
    return <ErrorPage errorType={ErrorType.ServerError503} />
  }

  if (!project) {
    return <ErrorPage errorType={ErrorType.NotFound404} />
  }

  const { name, projectType, projectId, surveys } = project
  const masterSurveyId = surveys[0].surveyId

  const subHeader = (
    <Subheader
      leftRightPadding={false}
      topBottomPadding
      leftChild={
        <TwoChildRowLayout
          maxWidth="65%"
          spaceBetweenChildren
          leftChild={
            <ProjectNameAndStatusEditable
              name={name}
              status={project.status}
              type={projectType}
              projectId={projectId}
            />
          }
        />
      }
    />
  )

  if (responseOptionsMappingPathMatch) {
    return (
      <>
        {subHeader}
        <Outlet />
      </>
    )
  }

  return (
    <>
      <Grid height={MULTI_MARKET_HEADER_HEIGHT}>
        {subHeader}
        <Grid
          container
          sx={{
            background: '#F1F5FD'
          }}
          display="grid"
          gap={2}
          paddingTop={0.5}
          paddingBottom={0.5}
          gridTemplateColumns="repeat(4, 1fr)"
        >
          <MultiMarketNavigationButton
            to={`/project/${projectId}/markets`}
            iconName={IconName.Language}
            label="Manage markets"
          />
          <MultiMarketNavigationButton
            to={`/project/${projectId}/mapping`}
            iconName={IconName.CalendarViewWeek}
            label="Map audience questions"
          />
          <MultiMarketNavigationButton
            to={`/project/${projectId}/master/${masterSurveyId}`}
            iconName={IconName.ChromeReaderModeOutlined}
            label="Build Master Survey"
          />
          <MultiMarketNavigationButton
            to={`/project/${projectId}/results`}
            iconName={IconName.InsertChartOutlined}
            label="View Multi-Market Dashboard"
          />
        </Grid>
      </Grid>
      <Outlet />
    </>
  )
}
