import { Theme, makeStyles } from '@focaldata/cin-ui-components'
import {
  ACCORDION_QUESTION_TYPES_WIDTH,
  ACCORDION_SUMMARY_WIDTH,
  EDIT_TARGETING_FIXED_CONTENT_HEIGHT
} from '../constants'

interface StyleProps {
  isWindowResized?: boolean
  height?: string
}

const useStyles = makeStyles<{ styleProps?: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    cardListOuter: {
      width: props?.isWindowResized
        ? `calc(100% - ${ACCORDION_QUESTION_TYPES_WIDTH}px)`
        : `calc(100% - ${ACCORDION_QUESTION_TYPES_WIDTH}px - ${ACCORDION_SUMMARY_WIDTH}px)`,
      overflowY: 'auto',
      height:
        props?.height ??
        `calc(100vh - ${EDIT_TARGETING_FIXED_CONTENT_HEIGHT}px)`
    },
    cardList: {
      width: '100%',
      minWidth: '660px',
      position: 'relative',
      height: '100%',
      overflow: 'auto'
    },
    emptyStateContainer: {
      paddingTop: theme.spacing(8)
    },
    emptyStateIcon: {
      paddingBottom: theme.spacing(1)
    },
    emptayStateText: {
      width: 520
    }
  })
)

export default useStyles
