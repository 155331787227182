import { Grid } from '@focaldata/cin-ui-components'
import Drawer from '@mui/material/Drawer'
import React from 'react'
import { CONTAINER_ID_COMPLETE_CONTENT } from '../../containers/Page/Survey/Complete/constants'
import useStyles from './styles'

interface Props {
  content?: JSX.Element
  drawerWidth?: number
  sideBar?: JSX.Element
  isDrawerOpen?: boolean
  showFooter?: boolean
}

const RightDrawerContentLayout: React.FC<Props> = (props: Props) => {
  const {
    content,
    sideBar,
    isDrawerOpen = false,
    drawerWidth = 384,
    showFooter = false
  }: Props = props
  const { classes, cx: classNames } = useStyles({
    styleProps: { drawerWidth, showFooter }
  })

  return (
    <Grid container className={classes.root}>
      <Grid
        id={CONTAINER_ID_COMPLETE_CONTENT}
        item
        xs="auto"
        className={classNames({
          [classes.contentCompleteShift]: isDrawerOpen,
          [classes.contentComplete]: !isDrawerOpen
        })}
      >
        {content}
      </Grid>
      <Grid item xs="auto">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isDrawerOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          transitionDuration={{ enter: 200, exit: 300 }}
        >
          {sideBar}
        </Drawer>
      </Grid>
    </Grid>
  )
}

export default RightDrawerContentLayout
