import { gql } from '@apollo/client/core'

const QUESTION_LOGIC = gql`
  fragment DraftLogicClauseProposition on DraftLogicClauseProposition {
    propositionType
    propositionRef {
      clauseNumber
      propositionId
    }
    propositionType
    proposition {
      ... on DraftQuestionResponseOption {
        questionLk
        responseOptionLk
      }
      ... on DraftMatrixResponseOption {
        matrixTitleLk
        questionLk
        responseOptionLk
      }
      ... on DraftMatrixResponseOptionCount {
        matrixTitleLk
        questionLk
        count
      }
      ... on DraftMatrixRowCount {
        matrixTitleLk
        responseOptionLk
        count
      }
      ... on DraftQuestionResponseOption {
        questionLk
        responseOptionLk
      }
      ... on DraftQuestionResponseOptionCount {
        questionLk
        count
      }
      ... on DraftQuestionResponseOptionRank {
        questionLk
        responseOptionLk
        ranked
      }
    }
    negated
  }
`

export default QUESTION_LOGIC
