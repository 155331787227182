import { Respondent } from '@focaldata/askai-client'
import Skeleton from '@mui/material/Skeleton'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import DateIcon from '../../../../../../../assets/fdchat/results/date.svg'
import DurationIcon from '../../../../../../../assets/fdchat/results/duration.svg'
import ProfileIcon from '../../../../../../../assets/fdchat/results/profile.svg'
import { useTranscript } from '../../../../../../../modules/FdChat/Transcripts/Transcripts.hooks'
import { getUserImage } from '../../../../FdChat.utils'
import Avatar from '../Avatar/Avatar'
import styles from './TranscriptViewer.module.css'

const TranscriptViewer = ({ sources }: { sources?: Respondent[] }) => {
  const [searchParams] = useSearchParams()
  const respondentId = searchParams.get('respondentId')!
  const { transcript, loading, error } = useTranscript({ respondentId })
  const interactionRef = useRef<HTMLLIElement>(null)

  const respondentIndex = sources?.findIndex(
    (source) => source.focaldata_respondent_id === respondentId
  )
  const respondentNumber =
    typeof respondentIndex === 'number' ? respondentIndex + 1 : undefined
  const respondentName = respondentNumber
    ? `Participant #${respondentNumber}`
    : 'Participant'
  const respondentSrc = getUserImage({
    userId: respondentId,
    gender: transcript?.respondent.gender
  })
  const respondentInitials = respondentNumber ? `P${respondentNumber}` : 'P'

  const getProfileInfo = ({
    gender,
    age,
    location
  }: {
    gender?: string | null
    age?: number | null
    location?: string | null
  }) => {
    if (gender || age || location) {
      return (
        <ul className={styles.value}>
          {gender && <li className={styles.chip}>{gender}</li>}
          {age && <li className={styles.chip}>{age}</li>}
          {location && <li className={styles.chip}>{location}</li>}
        </ul>
      )
    }
    return (
      <span className={styles.value}>
        <i>Profile information unavailable</i>
      </span>
    )
  }

  const attributes = [
    {
      propertyName: 'Profile',
      propertyIcon: ProfileIcon,
      value: getProfileInfo({
        gender: transcript?.respondent.gender,
        age: transcript?.respondent.age,
        location: transcript?.respondent.location
      })
    },
    {
      propertyName: 'Date',
      propertyIcon: DateIcon,
      value: (
        <span className={styles.value}>
          {dayjs(transcript?.transcript.interviewStartedAt).format(
            'DD/MM/YYYY'
          )}
        </span>
      )
    },
    {
      propertyName: 'Duration',
      propertyIcon: DurationIcon,
      value: (
        <span className={styles.value}>
          {dayjs(transcript?.transcript.interviewEndedAt).diff(
            dayjs(transcript?.transcript.interviewStartedAt),
            'minute'
          )}{' '}
          mins
        </span>
      )
    }
  ]

  // scroll to interactionRef on load
  useEffect(() => {
    if (interactionRef.current) {
      interactionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [transcript])

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>
          {loading || error ? (
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              animation={error ? false : 'pulse'}
            />
          ) : (
            <Avatar src={respondentSrc} initials={respondentInitials} />
          )}
          <h2 className={styles.name}>{respondentName}</h2>
        </div>
        <ul className={styles.attributes}>
          {attributes.map((attribute) => (
            <li className={styles.attribute} key={attribute.propertyName}>
              <div className={styles.property}>
                <img
                  src={attribute.propertyIcon}
                  alt=""
                  className={styles['property-icon']}
                />
                <span className={styles['property-name']}>
                  {attribute.propertyName}
                </span>
              </div>
              {loading || error ? (
                <Skeleton
                  width="125px"
                  sx={{ fontSize: '12px' }}
                  animation={error ? false : 'pulse'}
                />
              ) : (
                attribute.value
              )}
            </li>
          ))}
        </ul>
      </div>
      <hr className={styles.divider} />
      <ul className={styles.interactions}>
        {loading || error ? (
          <InteractionsLoading hasError={!!error} />
        ) : (
          transcript?.transcript.responses.map((interaction) => {
            const highlightInteraction =
              searchParams.get('interactionId') ===
                // @ts-expect-error can remove this comment once askai-client has been updated to return Output types instead of Input types
                interaction.interaction_id && interaction.role === 'respondent'
            return (
              <li
                // @ts-expect-error can remove this comment once askai-client has been updated to return Output types instead of Input types
                key={interaction.interaction_id}
                className={highlightInteraction ? styles.highlight : undefined}
                ref={highlightInteraction ? interactionRef : null}
              >
                <div className={styles['interaction-info']}>
                  {interaction.role === 'interviewer' ? (
                    <Avatar initials="S" height={24} width={24} />
                  ) : (
                    <Avatar
                      src={respondentSrc}
                      initials={respondentInitials}
                      height={24}
                      width={24}
                    />
                  )}
                  <span>
                    {interaction.role === 'interviewer'
                      ? 'Sage AI'
                      : respondentName}
                  </span>
                  <span>{dayjs(interaction.created_at).format('HH:mm')}</span>
                </div>
                <p className={styles.message}>{interaction.content}</p>
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}

export default TranscriptViewer

const InteractionsLoading = ({ hasError }: { hasError?: boolean }) => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, i) => (
        <div key={i}>
          <div className={styles['interaction-loading-info']}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              animation={hasError ? false : 'pulse'}
            />
            <Skeleton
              width="100px"
              sx={{ fontSize: '14px' }}
              animation={hasError ? false : 'pulse'}
            />
          </div>
          <Skeleton
            sx={{ fontSize: '14px' }}
            animation={hasError ? false : 'pulse'}
          />
        </div>
      ))}
    </>
  )
}
