import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { CSSProperties, ReactNode } from 'react'

const Accordion = ({
  summaryContent,
  detailsContent,
  padding,
  id
}: {
  summaryContent: ReactNode
  detailsContent: ReactNode
  padding?: CSSProperties['padding']
  id: string
}) => {
  return (
    <MuiAccordion
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: padding,
        '&.Mui-expanded': {
          margin: '0px',
          '&:before': {
            opacity: 1
          }
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id}
        id={id}
        sx={{
          padding: '0px',
          minHeight: 'auto',
          '& .MuiAccordionSummary-content': {
            margin: '0px'
          },
          '&.Mui-expanded': {
            minHeight: 'auto'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px'
          }
        }}
      >
        {summaryContent}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0px'
        }}
      >
        {detailsContent}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
