import { GenericPair } from './import.utils'

export const shiftMappingDown = <T, R>(pairs: GenericPair<T, R>[]) => {
  const newPairs = pairs.map((newPair) => ({ ...newPair }))

  for (let i = 0; i < newPairs.length; i += 1) {
    if (i === 0) {
      newPairs[0] = { item1: pairs[0]?.item1 }
    } else if (i > 0 && i < newPairs.length) {
      newPairs[i].item2 = pairs[i - 1]?.item2
    }
  }
  delete newPairs[0].item2
  newPairs.push({ item2: pairs[pairs.length - 1]?.item2 })
  const removeUndefined = newPairs.filter(
    (pair) => !(pair.item2 === undefined && pair.item1 === undefined)
  )
  return removeUndefined
}

export const shiftMappingUp = <T, R>(pairs: GenericPair<T, R>[]) => {
  const newPairs = pairs.map((newPair) => ({ ...newPair }))

  for (let i = 0; i < newPairs.length; i += 1) {
    if (i < newPairs.length) {
      newPairs[i].item2 = pairs[i + 1]?.item2
    }
  }
  newPairs.unshift({ item2: pairs[0].item2 })
  const removeUndefined = newPairs.filter(
    (pair) => !(pair.item2 === undefined && pair.item1 === undefined)
  )
  return removeUndefined
}
