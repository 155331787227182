import {
  AlignInputText,
  Checkbox,
  Grid,
  Input,
  InputType,
  InputVariant,
  Switch,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LoopIterationsSelector } from '../LoopIterationsSelector'
import {
  useLoopingSourceMatrixRows,
  useLoopingSourceResponseOptions
} from '../Looping.hooks'
import {
  loopRandomisationEnabledSet,
  qualifyingResponseOptionIdAdded,
  qualifyingResponseOptionIdRemoved,
  selectLoopRandomisationEnabled,
  selectQualifyingResponseOptionIdSet,
  selectSourceMatrixQuotaByRow,
  sourceMatrixQuotaTargetSet
} from '../Looping.slice'
import useStyles from './DialogLooping.styles'

export const LoopingMatrixContent = () => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()
  const loopingSourceResponseOptions = useLoopingSourceResponseOptions()
  const loopingSourceMatrixRows = useLoopingSourceMatrixRows()
  const qualifyingResponseOptionIds = useAppSelector(
    selectQualifyingResponseOptionIdSet
  )
  const sourceMatrixQuotaByRow = useAppSelector(selectSourceMatrixQuotaByRow)
  const loopRandomisationEnabled = useAppSelector(
    selectLoopRandomisationEnabled
  )
  const dispatch = useAppDispatch()

  const handleSelectResponseOption =
    (responseOptionLk: string) => (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        event.target.checked
          ? qualifyingResponseOptionIdAdded(responseOptionLk)
          : qualifyingResponseOptionIdRemoved(responseOptionLk)
      )
    }

  const handleQuotaChange =
    (questionId: string) =>
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      dispatch(
        sourceMatrixQuotaTargetSet({
          questionId,
          quotaTarget: target.value ? Number(target.value) : undefined
        })
      )

  const handleLoopRandomisationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(loopRandomisationEnabledSet(event.target.checked))
  }

  return (
    <Grid
      className={classes.configContainer}
      style={{ marginBottom: -16 }}
      display="flex"
      flexDirection="column"
    >
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeM,
          textClasses.highlightBackground,
          textClasses.gutterBottom
        )}
      >
        Loop a row if the following columns are picked:
      </p>

      {loopingSourceResponseOptions.map(
        ({ responseOptionLk, responseOption }) => {
          return (
            <Checkbox
              key={responseOptionLk}
              label={responseOption?.value}
              checked={qualifyingResponseOptionIds.has(responseOptionLk)}
              color="#72758D"
              onChange={handleSelectResponseOption(responseOptionLk)}
            />
          )
        }
      )}
      <Grid
        container
        alignItems="center"
        className={classes.iterationAndLoopBasis}
      >
        <p className={classes.marginRight8}>Present</p>
        <LoopIterationsSelector />
        <p>of total loops based on Quota target</p>
      </Grid>
      <div className={classes.topDelimiter}>
        <p
          className={classNames(textClasses.weightSemiBold)}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          Quota target
        </p>
        <>
          {loopingSourceMatrixRows.map(({ question, questionLk }) => (
            <div
              key={questionLk}
              style={{ display: 'flex', padding: '6px 0px' }}
            >
              <div
                style={{
                  border: '1px solid #E5EAF5',
                  padding: '4px 8px',
                  flex: 1
                }}
              >
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeM,
                    textClasses.highlightBackground
                  )}
                >
                  {question?.text}
                </p>
              </div>
              <div
                style={{
                  width: 90,
                  marginLeft: 40,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Input
                  alignInputText={AlignInputText.Center}
                  value={sourceMatrixQuotaByRow.get(questionLk)}
                  variant={InputVariant.Filled}
                  inputType={InputType.Number}
                  onChange={handleQuotaChange(questionLk)}
                />
              </div>
            </div>
          ))}
        </>
      </div>
      <div className={classes.topDelimiter}>
        <Switch
          first
          checked={loopRandomisationEnabled}
          label="Randomise order that loops are shown"
          onChange={handleLoopRandomisationChange}
        />
      </div>
    </Grid>
  )
}
