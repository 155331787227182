import { useEffect, useState } from 'react'
import { DraftQuestionnaireEntry } from '../../../data/model/questionnaire'
import { RawQuestionnaireEntry } from '../../../hooks/import/models'
import {
  Pair,
  findResponsePairPosition,
  findRowPairPosition,
  getMergedPairs,
  getOptionsFromEntry,
  getRowsFromEntry,
  setCheckedAndPositions,
  setOptionsCheckAndPosition
} from './import.utils'
import { shiftMappingDown, shiftMappingUp } from './mapping.utils'

interface Props {
  questionnaireQuestions: DraftQuestionnaireEntry[]
  importedQuestions: RawQuestionnaireEntry[]
  onMappingChanged?: (pairs: Pair[]) => void
}

const useImportListPreview = ({
  questionnaireQuestions,
  importedQuestions,
  onMappingChanged
}: Props) => {
  const [pairs, setPairs] = useState<Pair[]>(() =>
    getMergedPairs(questionnaireQuestions, importedQuestions)
  )

  useEffect(() => {
    if (pairs.length > 0) {
      onMappingChanged?.(pairs)
    }
  }, [onMappingChanged, pairs])

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isQuestionnaireEmpty = !questionnaireQuestions?.length

  const handleMoveUp = (id: string) => {
    const pair = pairs.find((p) => p.item2?.id === id)
    if (pair) {
      let shiftStartIndex = pairs.indexOf(pair)
      if (
        shiftStartIndex > 0 &&
        pairs[shiftStartIndex - 1]?.item2 === undefined
      ) {
        shiftStartIndex -= 1
      }
      const newShiftedPairs = shiftMappingUp(pairs.slice(shiftStartIndex))

      const newPairs = [...pairs.slice(0, shiftStartIndex), ...newShiftedPairs]
      setPairs(setCheckedAndPositions(newPairs, shiftStartIndex))
    }
  }

  const handleMoveDown = (id: string) => {
    const pair = pairs.find((p) => p.item2?.id === id)
    if (pair) {
      const index = pairs.indexOf(pair)
      const newShiftedPairs = shiftMappingDown(pairs.slice(index))
      const newPairs = [...pairs.slice(0, index), ...newShiftedPairs]
      setPairs(setCheckedAndPositions(newPairs, index))
    }
  }

  const shiftResponses = (
    questionId: string,
    responseId: string,
    direction: 'up' | 'down'
  ) => {
    const questionPair = pairs.find((p) => p.item2?.id === questionId)
    if (questionPair) {
      const pairOptions = getOptionsFromEntry(questionPair.item1)

      const questionIndex = pairs.indexOf(questionPair)
      const { responsePairs } = questionPair
      if (responsePairs) {
        const responsePair = responsePairs.find((p) => p.item2 === responseId)
        if (responsePair) {
          const responsePairIndex = responsePairs.indexOf(responsePair)

          let shiftStartIndex = responsePairIndex
          if (
            direction === 'up' &&
            responsePairIndex > 0 &&
            responsePairs[responsePairIndex - 1]?.item2 === undefined
          ) {
            shiftStartIndex = responsePairIndex - 1
          }

          const shiftMapping =
            direction === 'up' ? shiftMappingUp : shiftMappingDown

          const newShiftedRPairs = shiftMapping(
            responsePairs.slice(shiftStartIndex)
          )
          const newRPairs = [
            ...responsePairs.slice(0, shiftStartIndex),
            ...newShiftedRPairs
          ]

          const newResponses = setOptionsCheckAndPosition(
            pairOptions,
            questionPair.item2?.options || [],
            newRPairs,
            findResponsePairPosition,
            false
          )

          const newItem2 = {
            ...questionPair.item2,
            options: newResponses
          } as RawQuestionnaireEntry

          const newPairs = [...pairs]
          newPairs.splice(questionIndex, 1, {
            ...questionPair,
            item2: newItem2,
            responsePairs: newRPairs
          })

          setPairs(newPairs)
        }
      }
    }
  }

  const shiftRows = (
    questionId: string,
    row: string,
    direction: 'up' | 'down'
  ) => {
    const questionPair = pairs.find((p) => p.item2?.id === questionId)
    if (questionPair) {
      const pairOptions = getRowsFromEntry(questionPair.item1)

      const questionIndex = pairs.indexOf(questionPair)
      const { rowPairs } = questionPair
      if (rowPairs) {
        const rowPair = rowPairs.find((p) => p.item2 === row)
        if (rowPair) {
          const rowPairIndex = rowPairs.indexOf(rowPair)

          let shiftStartIndex = rowPairIndex
          if (
            direction === 'up' &&
            rowPairIndex > 0 &&
            rowPairs[rowPairIndex - 1]?.item2 === undefined
          ) {
            shiftStartIndex = rowPairIndex - 1
          }

          const shiftMapping =
            direction === 'up' ? shiftMappingUp : shiftMappingDown

          const newShiftedRPairs = shiftMapping(rowPairs.slice(shiftStartIndex))
          const newRPairs = [
            ...rowPairs.slice(0, shiftStartIndex),
            ...newShiftedRPairs
          ]

          const newRows = setOptionsCheckAndPosition(
            pairOptions,
            questionPair.item2?.rows || [],
            newRPairs,
            findRowPairPosition,
            false
          )

          const newItem2 = {
            ...questionPair.item2,
            rows: newRows
          } as RawQuestionnaireEntry

          const newPairs = [...pairs]
          newPairs.splice(questionIndex, 1, {
            ...questionPair,
            item2: newItem2,
            rowPairs: newRPairs
          })

          setPairs(newPairs)
        }
      }
    }
  }

  const handleMoveResponseUp = (id: string, responseId: string) => {
    shiftResponses(id, responseId, 'up')
  }

  const handleMoveResponseDown = (id: string, responseId: string) => {
    shiftResponses(id, responseId, 'down')
  }

  const handleMoveRowUp = (id: string, rowId: string) => {
    shiftRows(id, rowId, 'up')
  }

  const handleMoveRowDown = (id: string, rowId: string) => {
    shiftRows(id, rowId, 'down')
  }

  const handleOnSelectionChanged = (id: string, isChecked: boolean) => {
    const item = pairs.find((p) => p.item2?.id === id)
    if (item) {
      const newPairs = [...pairs]
      const newItem = {
        ...item,
        item2: {
          ...item.item2,
          isChecked,
          isNew: !item.item1 && isChecked,
          options:
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            !isChecked || (isChecked && !item.item1)
              ? item.item2?.options.map((o) => ({ ...o, isChecked }))
              : setOptionsCheckAndPosition(
                  getOptionsFromEntry(item.item1),
                  item.item2?.options || [],
                  item.responsePairs || [],
                  findResponsePairPosition,
                  false
                ),
          rows:
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            !isChecked || (isChecked && !item.item1)
              ? item.item2?.rows.map((o) => ({ ...o, isChecked }))
              : setOptionsCheckAndPosition(
                  getRowsFromEntry(item.item1),
                  item.item2?.rows || [],
                  item.rowPairs || [],
                  findRowPairPosition,
                  false
                )
        } as RawQuestionnaireEntry
      }
      newPairs.splice(pairs.indexOf(item), 1, newItem)
      setPairs(newPairs)
    }
  }

  const handleOnResponseSelectionChanged = (
    id: string,
    responseId: string,
    isChecked: boolean
  ) => {
    const newPairs = pairs.map((pair) => {
      if (pair.item2?.id === id) {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const options = pair.item2?.options?.map((option) => {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (option?.id === responseId) {
            const pairId = pair.responsePairs?.find(
              (p) => p.item2 === responseId
            )?.item1
            return {
              ...option,
              isChecked,
              isNew: !pairId && isChecked
            }
          }
          return option
        })
        return { ...pair, item2: { ...pair.item2, options } }
      }
      return pair
    })
    setPairs(newPairs)
  }

  const handleOnRowSelectionChanged = (
    id: string,
    rowId: string,
    isChecked: boolean
  ) => {
    const newPairs = pairs.map((pair) => {
      if (pair.item2?.id === id) {
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const rows = pair.item2?.rows?.map((row) => {
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (row?.id === rowId) {
            const pairId = pair.rowPairs?.find((p) => p.item2 === rowId)?.item1
            return {
              ...row,
              isChecked,
              isNew: !pairId && isChecked
            }
          }
          return row
        })
        return { ...pair, item2: { ...pair.item2, rows } }
      }
      return pair
    })
    setPairs(newPairs)
  }

  return {
    pairs,
    isQuestionnaireEmpty,
    handleOnSelectionChanged,
    handleOnResponseSelectionChanged,
    handleOnRowSelectionChanged,
    handleMoveUp,
    handleMoveDown,
    handleMoveResponseUp,
    handleMoveResponseDown,
    handleMoveRowUp,
    handleMoveRowDown
  }
}

export default useImportListPreview
