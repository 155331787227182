import {
  Grid,
  Select,
  SelectOption,
  SelectVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import { suggestedCountryOption } from '../../../utils/HelperFunctions'

interface Props {
  defaultCountryCode?: string
  countries?: SelectOption[]
  onChangeSelectCountry?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CountryPicker: React.FC<Props> = (props: Props) => {
  const { defaultCountryCode, countries, onChangeSelectCountry }: Props = props

  // TODO: Set it to "suggestedCountries(countries)" when we have all countries
  const suggestedCountries = suggestedCountryOption(undefined)

  return (
    <>
      <Grid item>
        <Text size={TextSize.m} weight={TextWeight.SemiBold}>
          Country
        </Text>
      </Grid>
      <Grid item>
        <Select
          ariaLabel="Country select"
          variant={SelectVariant.Filled}
          maxMenuHeight={300}
          defaultOptionValue={defaultCountryCode}
          suggestedOptions={suggestedCountries}
          options={countries}
          onChange={onChangeSelectCountry}
          fullWidth
        />
      </Grid>
    </>
  )
}

export default CountryPicker
