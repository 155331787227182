import {
  Checkbox,
  Grid,
  IconButton,
  IconName,
  Text,
  TextHighlight,
  TextSize,
  TextWeight,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import React from 'react'
import { QuestionKind } from '../../../data/model/chart'
import { FilterOption, ResponseOption } from '../../../data/model/results'
import { addNumberSeperators } from '../../../utils/chartUtils'
import { truncateText } from '../../../utils/questionnaireUtils'
import useStyles from './FilterBlock.styles'

export interface ResponseOptionSelection {
  isFork?: boolean
  responseOptionId: string
  questionId: string
  matrixTitleId?: string
}

interface Props {
  filterOption: FilterOption
  isDisabled?: boolean
  onDelete?: (filterOption: FilterOption) => void
  isResponseOptionSelected: (
    responseOptionSelection: ResponseOptionSelection
  ) => boolean
  onToggleFilter: (responseOptionSelection: ResponseOptionSelection) => void
}

const FilterBlock: React.FC<Props> = (props: Props) => {
  const {
    filterOption,
    isDisabled,
    onDelete,
    isResponseOptionSelected,
    onToggleFilter
  } = props
  const { classes } = useStyles()

  const onClickDelete: (filterOption: FilterOption) => void = (
    filterOption
  ) => {
    if (onDelete) {
      onDelete(filterOption)
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        key={`${filterOption.id}${filterOption.matrixTitleId}`}
      >
        <Grid item container xs={11}>
          <Grid
            item
            xs="auto"
            alignItems="flex-start"
            justifyContent="flex-start"
          />
          <Grid item xs={11}>
            <Text
              className={classes.title}
              size={TextSize.ms}
              weight={TextWeight.SemiBold}
              highlight={TextHighlight.Background}
            >
              {filterOption.text}
            </Text>
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          container
          className={classes.deleteIcon}
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          <IconButton
            iconName={IconName.DeleteOutline}
            onClick={() => {
              onClickDelete(filterOption)
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {filterOption.responseOptions?.map((responseOption: ResponseOption) => {
          const formattedTotal = addNumberSeperators(responseOption.completes)

          const isChecked = isResponseOptionSelected({
            responseOptionId: responseOption.id,
            questionId: filterOption.id,
            matrixTitleId: filterOption.matrixTitleId
          })

          const MAX_CHARACTERS_CHEKBOX_LABEL = 45
          const isFork = filterOption.questionKind === QuestionKind.None

          return (
            <Grid item container xs={12} wrap="nowrap" key={responseOption.id}>
              <Grid container className={classes.root}>
                <Grid item xs className={classes.checkBox}>
                  <Tooltip
                    title={responseOption.text}
                    tipPlacement={TipPlacement.Top}
                  >
                    <Checkbox
                      isGrouped
                      label={truncateText(
                        MAX_CHARACTERS_CHEKBOX_LABEL,
                        responseOption.text
                      )}
                      disabled={isDisabled}
                      className={classes.fullWidth}
                      defaultChecked={isChecked}
                      onChange={() => {
                        onToggleFilter({
                          isFork,
                          responseOptionId: responseOption.id,
                          questionId: filterOption.id,
                          matrixTitleId: filterOption.matrixTitleId
                        })
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  alignItems="center"
                  className={classes.numbers}
                >
                  {!isFork && (
                    <Text
                      size={TextSize.ms}
                      highlight={TextHighlight.Background}
                    >
                      ({formattedTotal})
                    </Text>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default React.memo(FilterBlock)
