import { Theme, makeStyles } from '@focaldata/cin-ui-components'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      boxSizing: 'border-box',
      border: '1px solid #F1F4F5',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingRight: 12,
      borderRadius: 2
    },
    mappingNumber: {
      backgroundColor: '#F1F5FD',
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.secondary
    },
    responseOption: {
      fontFamily: theme.typography.fontFamily,
      padding: '10px 16px',
      fontSize: '0.875rem',
      flexGrow: 1,
      border: 'none',
      outline: 'none',
      background: 'inherit',
      color: theme.palette.text.secondary,
      '&::placeholder': {
        color: '#ccc'
      },
      '&:disabled': {
        color: theme.palette.text.disabled
      }
    },
    cursorDefault: {
      cursor: 'default'
    }
  }
})
