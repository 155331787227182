import { Paper, PaperGroupItem, Skeleton } from '@focaldata/cin-ui-components'
import React from 'react'

import useStyles from './styles'

const AddEntryLoader: React.FC = () => {
  const { classes, cx: classNames } = useStyles()

  return (
    <div
      className={classNames('fd-grid', 'fd-container', classes.paddingBottom)}
      data-testid="question-card-loading"
    >
      <Paper>
        <PaperGroupItem>
          <div
            className={classNames(
              'fd-grid',
              'fd-container',
              classes.marginBottom
            )}
          >
            <Skeleton height={20} width={60} />
          </div>
          <div className="fd-grid fd-item fd-xs-12">
            <Skeleton height={30} />
          </div>
        </PaperGroupItem>
        <PaperGroupItem paddingTopBottom={2}>
          <div className={classNames('fd-grid', classes.switchesPlaceholder)}>
            <div className="fd-grid fd-container fd-justify-content-between">
              <Skeleton height={20} width={60} />
              <Skeleton height={20} width={60} />
              <Skeleton height={20} width={60} />
            </div>
          </div>
        </PaperGroupItem>
        <PaperGroupItem>
          <div
            className={classNames(
              'fd-grid',
              'fd-container',
              classes.marginBottom
            )}
          >
            <div className="fd-grid fd-item fd-xs-12">
              <Skeleton height={30} />
            </div>
          </div>
          <div
            className={classNames(
              'fd-grid',
              'fd-container',
              classes.marginBottom
            )}
          >
            <div className="fd-grid fd-item fd-xs-12">
              <Skeleton height={30} />
            </div>
          </div>
          <div
            className={classNames(
              'fd-grid',
              'fd-container',
              classes.footerPlaceholder
            )}
          >
            <Skeleton height={20} width={60} />
          </div>
        </PaperGroupItem>
      </Paper>
    </div>
  )
}

export default AddEntryLoader
