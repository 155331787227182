class UserSession {
  static setEmail(email: string) {
    localStorage.setItem('email', email)
  }

  static getEmail(): string | null {
    const email = localStorage.getItem('email')
    return email
  }

  static removeEmail(): void {
    localStorage.removeItem('email')
  }

  static setAccountId(accountId: string) {
    localStorage.setItem('accountId', accountId)
  }

  static removeAccountId(): void {
    localStorage.removeItem('accountId')
  }

  static getAccountId(): string {
    const id = this.maybeGetAccountId()

    if (!id) {
      throw new Error('Account ID missing when expected to be present')
    }

    return id
  }

  static maybeGetAccountId(): string | null {
    /*
      Note: Some users, such as researchers, may manually update the 'accountId' stored in localStorage.

      There is an issue in Chrome where if a newline character ('\n') is accidentally added to the end of localStorage's value ('accountId' in this case), it cannot be replaced manually later. This can result in failed HTTP calls, as 'some-account-id' and 'some-account-id\n' are treated as distinct values.

      For more details, refer to: https://github.com/focaldata/cin-fd-surveys/pull/1353#issue-2038113838
    */
    const accountId = localStorage.getItem('accountId')?.trim() ?? null
    return accountId
  }

  static setAccountName(accountName: string) {
    localStorage.setItem('accountName', accountName)
  }

  static removeAccountName(): void {
    localStorage.removeItem('accountName')
  }

  static getAccountName(): string | null {
    const accountName = localStorage.getItem('accountName')
    return accountName
  }

  static getSolutionId(): string {
    return 'b6987a9b-1e51-44c6-8406-ae5c766186ec'
  }
}

export default UserSession
