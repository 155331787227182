import {
  Grid,
  Input,
  InputFontSize,
  InputVariant,
  Text,
  TextSize
} from '@focaldata/cin-ui-components'
import debounce from 'lodash/debounce'
import { ChangeEvent, useMemo, useState } from 'react'
import {
  useGetDraftCombinedQuestionTitle,
  useSetDraftCombinedQuestionTitle
} from '../DraftCombinedQuestionState/DraftCombinedQuestion.hooks'

export const CombinedQuestionTitleInput = () => {
  const draftCombinedQuestionTitle = useGetDraftCombinedQuestionTitle()
  const [titleValue, setTitleValue] = useState(draftCombinedQuestionTitle)
  const setCombinedQuestionTitle = useSetDraftCombinedQuestionTitle()

  const debouncedTitleChange = useMemo(() => {
    return debounce((value: string) => {
      setCombinedQuestionTitle(value)
    }, 500)
  }, [setCombinedQuestionTitle])

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value
    setTitleValue(newTitle)
    debouncedTitleChange(newTitle)
  }

  return (
    <>
      <Grid marginY={1}>
        <Text size={TextSize.ms}>Combined question title</Text>
      </Grid>
      <Input
        ariaLabel="Combined question title"
        value={titleValue}
        onChange={handleTitleChange}
        fullWidth
        multiline
        variant={InputVariant.Filled}
        inputFontSize={InputFontSize.lxl}
        placeholder="Enter combined question title"
      />
    </>
  )
}
