import {
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './SectionsRandomisationHint.styles'

export const SectionsRandomisationHint = () => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()

  return (
    <div>
      <Icon
        name={IconName.InfoOutlinedIcon}
        iconColor={IconColor.Emphasis}
        size={IconSize.Medium}
      />

      <p
        className={classNames(
          textClasses.default,
          textClasses.displayInline,
          textClasses.sizeMs,
          textClasses.highlightEmphasis,
          classes.hintText
        )}
      >
        At least 2 sections should be selected to enable randomisation
      </p>
    </div>
  )
}
