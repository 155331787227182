import {
  Button,
  ButtonVariant,
  Icon,
  IconColor,
  IconName,
  IconSize,
  KebabMenu,
  KebabMenuIconPosition,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import useStyles from './SectionEntry.styles'

interface Props {
  onRemoveQuestionOrderRandomised: () => void
}

const RandomisationActionMenuControl = ({
  onRemoveQuestionOrderRandomised
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()
  return (
    <KebabMenu
      kebabMenuOptions={[
        {
          id: 0,
          textItem: 'Remove question order randomisation',
          onClickItem: onRemoveQuestionOrderRandomised,
          iconName: IconName.DeleteOutline
        }
      ]}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      iconPosition={KebabMenuIconPosition.Left}
      menuButton={
        <Button
          className={classes.randomiseQuestionsIndicator}
          variant={ButtonVariant.SecondaryOutlined}
          startIcon={
            <Icon
              name={IconName.Shuffle}
              iconColor={IconColor.Emphasis}
              size={IconSize.Large}
            />
          }
        >
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.highlightEmphasis
            )}
          >
            Question order randomised
          </p>
        </Button>
      }
    />
  )
}

export default RandomisationActionMenuControl
