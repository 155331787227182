import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  DraftCombinedQuestion,
  DraftMarketQuestion
} from '../../../../data/gql-gen/local/graphql'
import { MappingOption } from './MapAudienceDialog/MapAudienceDialog'

const createMappingStub = (
  existingCombinedQuestionId?: string
): Promise<DraftCombinedQuestion> => {
  return new Promise((resolve) =>
    setTimeout(() => {
      const createMarketQuestionsForMapping = (
        markets: string[],
        responseOptionsCount: number
      ): DraftMarketQuestion[] => {
        return markets.map((market) => {
          return {
            id: uuid(),
            market,
            position: 1,
            responseOptions: Array.from(
              { length: responseOptionsCount },
              () => {
                return {
                  id: uuid(),
                  mappingId: null,
                  value: `response option for ${market}`
                }
              }
            )
          }
        })
      }

      const createMarketQuestionsForExistingMapping = (
        markets: string[],
        responseOptionsCount: number
      ): DraftMarketQuestion[] => {
        const marketQuestions = createMarketQuestionsForMapping(
          markets,
          responseOptionsCount
        )
        marketQuestions.forEach(({ responseOptions }) => {
          responseOptions.forEach((responseOption, index) => {
            responseOption.mappingId = `${index + 1}`
          })
        })

        return marketQuestions
      }

      const combinedQuestion = existingCombinedQuestionId
        ? {
            id: existingCombinedQuestionId,
            title: 'Existing combined question title',
            position: 1,
            mappedResponseOptions: [1, 2, 3].map((n) => ({
              id: `${n}`,
              mappingNumber: n,
              value: `Option ${n}`
            })),
            marketQuestions: createMarketQuestionsForExistingMapping(
              ['Spain', 'Germany', 'France'],
              3
            )
          }
        : {
            id: uuid(),
            title: '',
            position: 1,
            mappedResponseOptions: [],
            marketQuestions: createMarketQuestionsForMapping(
              ['Spain', 'Germany', 'France'],
              3
            )
          }
      resolve(combinedQuestion)
    }, 1000)
  )
}

const getRedirectUrl = (questionId: string) => {
  return `question/${questionId}`
}

export const useConfirmMapping = () => {
  const [mappingInProgress, setMappingInProgress] = useState(false)
  const navigate = useNavigate()

  const proceedToCombinedQuestion = (questionId: string) => {
    setMappingInProgress(false)
    navigate(getRedirectUrl(questionId))
  }

  const confirmMapping = async (
    mappingOption: MappingOption,
    existingQuestionId?: string
  ): Promise<DraftCombinedQuestion> => {
    setMappingInProgress(true)

    switch (mappingOption) {
      case MappingOption.ExistingCombinedQuestion:
        if (!existingQuestionId) {
          throw new Error(
            'Combined question id is missing while mapping to existing combined question'
          )
        }
        const existingCombinedQuestion =
          await createMappingStub(existingQuestionId)
        proceedToCombinedQuestion(existingQuestionId)
        return existingCombinedQuestion
      case MappingOption.NewCombinedQuestion:
        const newCombinedQuestion = await createMappingStub()
        proceedToCombinedQuestion(newCombinedQuestion.id)
        return newCombinedQuestion
      default:
        // exhaustive guard to ensure all cases are handled
        return mappingOption satisfies never
    }
  }

  return { confirmMapping, mappingInProgress }
}
