import { FieldFunctionOptions } from '@apollo/client'
import { SearchProjects } from './Project.query'

export interface SearchProjectsCacheSettings {
  keyArgs: string[]
  merge: (
    existing: SearchProjects,
    incoming: SearchProjects,
    { args }: FieldFunctionOptions
  ) => SearchProjects
}

export const cacheSettings: SearchProjectsCacheSettings = {
  keyArgs: ['accountId'],
  merge(
    existing: SearchProjects = { projects: [], header: { total: 0 } },
    incoming: SearchProjects,
    { args }: FieldFunctionOptions
  ) {
    // if the first page is requested, don't concatenate
    if (args?.offset === 0) {
      return incoming
    }
    return {
      projects: [...existing.projects, ...incoming.projects],
      header: incoming.header
    }
  }
}
