import { IParagraphOptions, Paragraph, TextRun } from 'docx'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode,
  QuestionTypeCode,
  SettingValue
} from '../../data/model/questionnaire'
import {
  getQuestionEntryPrefix,
  getQuestionSettings,
  getQuestionTimerLabel,
  getQuestionType
} from '../questionnaireUtils'
import { paragraphTopBorderOptions } from './paragraphTopBorderOptions'

const getQuestionTypeText = (
  entry: DraftQuestionnaireEntry,
  questionType: QuestionTypeCode
): string => {
  if (entry.entryType === EntryType.SectionEntryType) {
    return '(Section - start)'
  }

  switch (questionType) {
    case QuestionTypeCode.CustomAudience:
    case QuestionTypeCode.StandardAudience:
    case QuestionTypeCode.Basic: {
      const entryItem = entry.entryItem as DraftQuestionItem
      const { settingValues } = entryItem
      const questionSettings = getQuestionSettings(settingValues)

      const questionTypeText = `(${
        questionSettings.get(
          questionType === QuestionTypeCode.CustomAudience
            ? QuestionSettingCode.CustomAudienceChoice
            : QuestionSettingCode.BasicChoice
        ) === SettingValue.MultipleChoice
          ? 'Multi'
          : 'Single'
      } select)`
      return questionTypeText
    }
    case QuestionTypeCode.TextCard:
      return '(Text instruction)'
    case QuestionTypeCode.Matrix: {
      const entryItem = entry.entryItem as DraftMatrixItem
      const { settingValues } = entryItem
      const questionSettings = getQuestionSettings(settingValues)

      return `(Matrix - ${
        questionSettings.get(QuestionSettingCode.MatrixChoice) ===
        SettingValue.MultipleChoice
          ? 'multi'
          : 'single'
      } select)`
    }
    case QuestionTypeCode.Scale:
      return '(Slider)'
    case QuestionTypeCode.Ranked: {
      const entryItem = entry.entryItem as DraftQuestionItem
      const { settingValues } = entryItem
      const questionSettings = getQuestionSettings(settingValues)

      const choiceRequisite = questionSettings.get(
        QuestionSettingCode.RankChoiceRequisite
      )

      const rankedQuestionText = `(Ranked)${
        choiceRequisite ? ` [Rank top ${choiceRequisite}]` : ' [All ranked]'
      }`
      return rankedQuestionText
    }
    case QuestionTypeCode.FreeText:
      return '(Text input)'
    default:
      return ''
  }
}

const createQuestionParagraphOptions = (
  entry: DraftQuestionnaireEntry
): IParagraphOptions => {
  const questionType = getQuestionType(entry)
  const questionPrefixAndNumber = getQuestionEntryPrefix(entry)
  const questionTimerLabel = getQuestionTimerLabel(entry)

  return {
    children: [
      new TextRun({
        text: `${questionPrefixAndNumber} `,
        bold: true
      }),
      new TextRun({
        text: `${getQuestionTypeText(
          entry,
          questionType
        )} ${questionTimerLabel}`
      })
    ]
  }
}

export const createQuestionTitle = ({
  entry,
  shouldHaveTopBorder
}: {
  entry: DraftQuestionnaireEntry
  shouldHaveTopBorder: boolean
}): Paragraph => {
  const questionParagraphOptions = createQuestionParagraphOptions(entry)

  return new Paragraph(
    shouldHaveTopBorder
      ? { ...questionParagraphOptions, ...paragraphTopBorderOptions }
      : questionParagraphOptions
  )
}
