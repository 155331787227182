import { ApolloCache, Reference } from '@apollo/client'
import { produce } from 'immer'
import { Project, SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import { UpdateSurveyOrderData } from '../../../data/gql/order/mutations/updateSurveyOrder'
import { SearchProjects } from '../../../modules/Project/Project.query'

export const CompleteProjectCacheUpdate: (
  cache: ApolloCache<UpdateSurveyOrderData>,

  selectedProject: Project | undefined
) => void = (cache, selectedProject) => {
  cache.modify({
    fields: {
      searchProjects(
        existing: Reference | SearchProjects = {
          projects: [],
          header: { total: 0 }
        }
      ) {
        const searchProjectsCache = existing as SearchProjects
        const index: number = searchProjectsCache.projects.findIndex(
          (proj: Project) => proj.projectId === selectedProject?.projectId
        )

        return produce(searchProjectsCache, (draft) => {
          draft.projects[index].surveys[0].state = SurveyState.Completed
        })
      },
      projectBySurveyId(existing: Project | Reference) {
        const projectCache = existing as Project
        return produce(projectCache, (draft) => {
          draft.surveys[0].state = SurveyState.Completed
        })
      }
    }
  })
}
