import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    border: `1px solid ${additionalPalette.lightGrey.main}`,
    padding: theme.spacing(0.5, 0.5, 2, 3)
  },
  subtitleContainer: {
    paddingBottom: theme.spacing(2.5)
  },
  logicContainer: {
    padding: theme.spacing(0, 3)
  },
  logicText: {
    paddingTop: theme.spacing(0.5)
  },
  responseOptionsContainer: {
    marginLeft: `-${theme.spacing(1)}`,
    '& .MuiFormControlLabel-label': {
      paddingTop: theme.spacing(1.125)
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start'
    }
  },
  andOrContainer: {
    padding: theme.spacing(1, 0)
  },
  linkButton: {
    padding: theme.spacing(2, 0, 0, 0)
  },
  responseLogicText: {
    marginTop: theme.spacing(0.5)
  },
  operatorText: {
    padding: theme.spacing(1.25, 0)
  },
  selectContainer: {
    padding: theme.spacing(0.5, 0),
    marginLeft: `-${theme.spacing(1)}`
  },
  errorText: {
    marginTop: theme.spacing(0.4),
    color: theme.palette.error.main,
    maxWidth: theme.spacing(42)
  }
})

export default useStyles
