import {
  Grid,
  Paper,
  PaperGroupItem,
  Skeleton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './styles'

const ConfirmationStartDateLoader: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Paper>
      <PaperGroupItem>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              1
            </Text>
          </Grid>
          <Grid item>
            <Text size={TextSize.l} weight={TextWeight.SemiBold}>
              Choose the start date
            </Text>
          </Grid>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Text size={TextSize.m} weight={TextWeight.SemiBold}>
              Go live date
            </Text>
          </Grid>
          <Grid item className={classes.datePicker}>
            <Skeleton width={233} height={54} variant="rectangular" />
          </Grid>
          <Grid container item direction="row">
            <Grid container item direction="row" xs={5}>
              <Text highlight={TextHighlight.Background} size={TextSize.ms}>
                Estimated time to complete:
              </Text>
              <Text
                size={TextSize.ms}
                weight={TextWeight.SemiBold}
                className={classes.estimateLengthStyle}
              >
                <Skeleton width={60} height={21} />
              </Text>
            </Grid>
            <Grid container item direction="row" xs={7}>
              <Text highlight={TextHighlight.Background} size={TextSize.ms}>
                Completion date:
              </Text>
              <Text
                size={TextSize.ms}
                weight={TextWeight.SemiBold}
                className={classes.estimateLengthStyle}
              >
                <Skeleton width={121} height={21} />
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </PaperGroupItem>
      <PaperGroupItem>
        <Grid container direction="column">
          <Grid container item direction="row">
            <Grid container item direction="row" xs={5}>
              <Text highlight={TextHighlight.Background} size={TextSize.ms}>
                Soft launch
              </Text>
              <Text
                size={TextSize.ms}
                weight={TextWeight.SemiBold}
                className={classes.estimateLengthStyle}
              >
                <Skeleton width={60} height={21} />
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </PaperGroupItem>
    </Paper>
  )
}

export default ConfirmationStartDateLoader
