import { QuestionTypeCode } from '../../data/model/questionnaire'
import { useFieldworkAudience } from '../../hooks/audience/useFieldworkAudience'
import { AudienceQuestionContainer } from './AudienceQuestion'

export const CustomCriteriaQuestionsListContainer = (): JSX.Element | null => {
  const { fieldworkAudience } = useFieldworkAudience()

  const customCriteriaQuestions = fieldworkAudience?.entries.map(
    (entry, index) => {
      if (entry.entryItem.__typename !== 'QuestionItem') {
        return null
      }
      const entryItem = entry.entryItem
      if (
        entryItem.questionTypeCode === QuestionTypeCode.CustomAudience ||
        entryItem.questionTypeCode === QuestionTypeCode.StandardAudience
      ) {
        const scrollKey = entryItem.question.questionId
        return (
          <AudienceQuestionContainer
            key={entry.number}
            audienceQuestionIndex={index}
            scrollKey={scrollKey}
            customAudienceEntryItem={entryItem}
          />
        )
      }
      return null
    }
  )

  return <>{customCriteriaQuestions}</>
}
