import { Paragraph } from 'docx'
import {
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../data/model/questionnaire'
import { isSettingEnabled } from '../questionnaireUtils'

export const createEntryContentSlider = (
  entry: DraftQuestionnaireEntry
): Paragraph[] => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const paragraphs: Paragraph[] = []

  const { range, labels } = entryItem.questionScale ?? {}
  const { min, max } = range ?? {}

  paragraphs.push(
    new Paragraph({
      text: `Min: ${min}`,
      spacing: {
        before: 200
      }
    }),
    new Paragraph({
      text: `Max: ${max}`,
      spacing: {
        after: 200
      }
    })
  )

  if (
    isSettingEnabled(entryItem.settingValues, QuestionSettingCode.NotApplicable)
  ) {
    paragraphs.push(
      new Paragraph({ text: 'Default response options:' }),
      new Paragraph({
        text: '1. Not applicable',
        spacing: {
          after: 200
        }
      })
    )
  }

  if (labels?.length) {
    const leftLabelText = labels.find((label) => label.position === 0)?.label
      .text
    const centralLabelText = labels.find((label) => label.position === 1)?.label
      .text
    const rightLabelText = labels.find((label) => label.position === 2)?.label
      .text

    if (leftLabelText) {
      paragraphs.push(new Paragraph(`Left-hand label: ${leftLabelText}`))
    }

    if (centralLabelText) {
      paragraphs.push(new Paragraph(`Central label: ${centralLabelText}`))
    }

    if (rightLabelText) {
      paragraphs.push(new Paragraph(`Right-hand label: ${rightLabelText}`))
    }
  }

  return paragraphs
}
