import Chip from '@mui/material/Chip'

const ExampleQuestionChip = ({
  icon,
  label,
  onClick,
  disabled
}: {
  icon: string
  label: string
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <Chip
      icon={<span style={{ fontSize: '12px' }}>{icon}</span>}
      label={label}
      onClick={onClick}
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #D0D5DD',
        borderRadius: '100px',
        color: '#72758D',
        height: 'unset',
        padding: '6px 13px',
        gap: '4px',
        fontSize: '14px',
        '&:hover, &:focus': {
          backgroundColor: '#ffffff',
          color: '#6BABF2'
        },
        '& .MuiChip-icon, & .MuiChip-label': {
          padding: 0,
          margin: 0
        }
      }}
      disabled={disabled}
    />
  )
}

export default ExampleQuestionChip
