/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import useStyles from './FileInput.styles'

const isAcceptedFileType = (
  file: File,
  acceptedExtensions: string[]
): boolean =>
  acceptedExtensions.some((acceptedExtension) =>
    file.name.endsWith(`.${acceptedExtension}`)
  )

const isAcceptedFileSize = (file: File, maxSizeMb: number): boolean =>
  file.size < 1024 * 1024 * maxSizeMb

interface Props {
  extensions: string[]
  inputRef: React.RefObject<HTMLInputElement>
  errorMessage?: string
  maxSizeMb: number
  loading: boolean
  setErrorMessage: (newMessage: string | undefined) => void
  setIsValidFile: (isValid: boolean) => void
  setSelectedFile: (file: File) => void
}

export const FileDropZone: React.FC<Props> = (props: Props) => {
  const {
    extensions,
    inputRef,
    errorMessage,
    maxSizeMb,
    loading,
    setErrorMessage,
    setIsValidFile,
    setSelectedFile
  }: Props = props
  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  const setFile = async (file: File) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!file) {
      setErrorMessage('The selected file is invalid')
      setIsValidFile(false)

      return
    }

    if (!isAcceptedFileType(file, extensions)) {
      setErrorMessage('File type is not supported')
      setIsValidFile(false)

      return
    }
    if (!isAcceptedFileSize(file, maxSizeMb)) {
      setErrorMessage(`File size must be less than ${maxSizeMb}MB`)
      setIsValidFile(false)

      return
    }

    setErrorMessage(undefined)
    setIsValidFile(true)
    setSelectedFile(file)
  }

  const handleFileUploaded = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0]

      setFile(file)
    } else {
      setIsValidFile(false)
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (acceptedFiles) {
      const file = acceptedFiles[0]

      setFile(file)
    } else {
      setIsValidFile(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        []
    }
  })

  return (
    <>
      <div data-testid="file-dropzone">
        <input
          {...getInputProps()}
          ref={inputRef}
          disabled={loading}
          accept=".docx"
          style={{ display: 'none' }}
          id="icon-button-file"
          type="file"
          multiple={false}
          data-testid="file-input"
          onChange={handleFileUploaded}
        />
        <Grid
          {...getRootProps()}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classNames(classes.container, classes.sizingContainer, {
            [classes.containerDropping]: isDragActive,
            [classes.containerError]: errorMessage !== undefined
          })}
        >
          <Grid item>
            <Icon
              name={IconName.CloudUploadOutlined}
              size={IconSize.Large}
              iconColor={IconColor.Background}
              className={classes.dropIcon}
            />
          </Grid>
          <Grid item marginTop="10px">
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeL,
                textClasses.highlightPrimary,
                textClasses.displayInline,
                textClasses.weightSemiBold
              )}
            >
              Upload
            </p>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeL,
                textClasses.highlightBackground,
                textClasses.displayInline,
                textClasses.weightSemiBold
              )}
            >
              {' '}
              or drop a file here
            </p>
          </Grid>
          <Grid>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeM,
                textClasses.highlightBackground
              )}
            >
              Only .DOCX files can be imported
            </p>
          </Grid>
        </Grid>
      </div>
      {errorMessage !== undefined && (
        <Grid container className={classes.errorContainer} direction="row">
          <Icon
            name={IconName.InfoOutlinedIcon}
            iconColor={IconColor.Error}
            size={IconSize.Medium}
          />
          <p
            className={classNames(
              textClasses.default,
              textClasses.marginLeft,
              textClasses.weightSemiBold,
              textClasses.highlightError,
              textClasses.sizeMs
            )}
          >
            {errorMessage}
          </p>
        </Grid>
      )}
    </>
  )
}
