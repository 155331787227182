import React, { memo, useContext } from 'react'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import QuestionnaireContext, {
  closePreviewPanel
} from '../Questionnaire.context'
import { flattenEntries } from '../Questionnaire.utils'
import PreviewPanelControl from './PreviewPanel.control'

const PreviewPanel: React.FC = () => {
  const { draftQuestionnaire, refetchQuestionnaire } =
    useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaire?.entries ?? []
  )
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)

  if (!questionnaireState.isPreviewPanelOpened) {
    return null
  }

  return (
    <PreviewPanelControl
      entries={flatDraftQuestionnaireEntries}
      onClose={() => dispatch(closePreviewPanel())}
      onRefresh={() => {
        refetchQuestionnaire()
      }}
    />
  )
}

export default memo(PreviewPanel)
