import { Respondent } from '@focaldata/askai-client'
import Tooltip from '@mui/material/Tooltip'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import ArrowDownIcon from '../../../../../../../assets/fdchat/results/arrow-down.svg'
import CloseIcon from '../../../../../../../assets/fdchat/results/chevron-right-double.svg'
import ChevronRight from '../../../../../../../assets/fdchat/results/chevron-right.svg'
import { useAskAiDrawer } from '../../../../../../../modules/FdChat/AskAi/AskAi.hooks'
import styles from './AskAiDrawerMenuBar.module.css'

export const AskAiDrawerMenuBar = ({ sources }: { sources?: Respondent[] }) => {
  const [searchParams] = useSearchParams()
  const { goToTranscript, closeDrawer, viewer, goToSources } = useAskAiDrawer()

  const respondentId = searchParams.get('respondentId')

  const respondentIndex = sources?.findIndex(
    (respondent) => respondent.focaldata_respondent_id === respondentId
  )
  const respondentNumber =
    typeof respondentIndex === 'number' ? respondentIndex + 1 : undefined
  const isFirstRespondent = respondentIndex === 0
  const isLastRespondent = sources
    ? respondentIndex === sources.length - 1
    : null
  const respondentName = respondentNumber
    ? `Participant #${respondentNumber}`
    : 'Participant'

  useEffect(() => {
    // if esc key is pressed, close the drawer
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDrawer()
      }
    }
    window.addEventListener('keydown', handleKeyDown)

    // cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToPreviousRespondent = () => {
    if (
      !sources ||
      typeof respondentIndex !== 'number' ||
      respondentIndex === -1
    )
      return

    const prevRespondent = sources[respondentIndex - 1]
    goToTranscript({ respondentId: prevRespondent.focaldata_respondent_id })
  }

  const goToNextRespondent = () => {
    if (
      !sources ||
      typeof respondentIndex !== 'number' ||
      respondentIndex === -1
    )
      return

    const nextRespondent = sources[respondentIndex + 1]
    goToTranscript({ respondentId: nextRespondent.focaldata_respondent_id })
  }

  return (
    <div className={styles['menu-bar']}>
      <Tooltip title="Close">
        <button className={styles.close} onClick={closeDrawer}>
          <img src={CloseIcon} alt="close drawer" />
        </button>
      </Tooltip>
      {viewer === 'transcript' && (
        <div className={styles['navigation-arrows']}>
          <Tooltip title="Previous source">
            <button
              onClick={goToPreviousRespondent}
              disabled={isFirstRespondent}
              className={styles.arrow}
            >
              <img
                src={ArrowDownIcon}
                alt="go to previous source"
                className={styles['arrow-up']}
              />
            </button>
          </Tooltip>
          <Tooltip title="Next source">
            <button
              onClick={goToNextRespondent}
              disabled={isLastRespondent ?? false}
              className={styles.arrow}
            >
              <img src={ArrowDownIcon} alt="go to next source" />
            </button>
          </Tooltip>
        </div>
      )}
      <div className={styles.breadcrumbs}>
        <button
          className={styles['breadcrumb-button']}
          onClick={() => goToSources()}
          style={{
            pointerEvents: viewer === 'sources' ? 'none' : 'auto'
          }}
          data-dd-action-name="Sources (breadcrumb)"
        >
          Sources
        </button>
        {viewer === 'transcript' && (
          <>
            <img src={ChevronRight} alt="chevron right" />
            <span className={styles.breadcrumb}>{respondentName}</span>
          </>
        )}
      </div>
    </div>
  )
}

export default AskAiDrawerMenuBar
