import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { GetDraftCombinedQuestionQuery } from '../../../../../../data/gql-gen/local/graphql'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const setDraftCombinedQuestionTitle: Resolver = (
  _,
  { title }: { title: string },
  { cache }: { cache: InMemoryCache }
) => {
  cache.updateQuery(
    {
      query: GET_DRAFT_COMBINED_QUESTION
    },
    (
      data: GetDraftCombinedQuestionQuery | null
    ): GetDraftCombinedQuestionQuery | void => {
      if (!data) {
        throw new Error('No draft combined question found in cache')
      }

      return produce(data, (draft) => {
        draft.draftCombinedQuestion.title = title
      })
    }
  )

  return title
}
