import {
  MediaUploader as MediaUploaderControl,
  VideoService,
  getImageContentSource
} from '@focaldata/cin-ui-components'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import {
  MediaEnabledEntry,
  MediaType
} from '../../../../data/model/questionnaire'
import { SurveyParams } from '../../../../data/model/surveyParams'
import useMediaUrlAndThumbnailUrl, {
  getImageUrl
} from '../../../../data/rest/media/media'
import uploadFile from '../../../../data/rest/media/uploadMedia'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { WithEntry } from '../../../../utils/questionnaireUtils'
import QuestionnaireContext, {
  closeAddMediaDialog
} from '../../Questionnaire.context'
import { useSetQuestionMedia } from './MediaUploader.mutations'

interface Props extends WithEntry {
  onMediaUploadStart?: () => void
  onMediaUploadEnd?: () => void
}

const EntryMediaUploader: React.FC<Props> = ({
  entry,
  onMediaUploadStart,
  onMediaUploadEnd
}: Props) => {
  const mediaEntry = entry.entryItem as MediaEnabledEntry
  const { questionMedia } = mediaEntry

  const { dispatch, questionnaireState } = useContext(QuestionnaireContext)
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const [tempUrl, setTempUrl] = useState<string | undefined>()
  const [isMediaPresent, setIsMediaPresent] = useState<boolean>(
    !!questionMedia?.mediaUrl
  )
  const [errorOnUpload, setErrorOnUpload] = useState<boolean>(false)
  const { resizedMediaUrl, resizedThumbnailUrl } = useMediaUrlAndThumbnailUrl(
    questionMedia?.mediaUrl,
    questionMedia?.mediaType,
    questionMedia?.renderedMediaUrl
  )
  const { draftQuestionnaire } = useGetDraftQuestionnaire()

  const setQuestionMedia = useSetQuestionMedia(entry.id)

  useEffect(() => {
    if (questionMedia) {
      setIsMediaPresent(true)
    }
  }, [questionMedia])

  const isDialogOpen =
    questionnaireState.openedAddMediaDialogNumber === entry.number

  if (!isDialogOpen && !isMediaPresent) {
    return null
  }

  const questionnaireId = draftQuestionnaire?.questionnaireId || ''

  const removeFile: () => Promise<void> = async () => {
    setIsMediaPresent(false)
    await setQuestionMedia(null)
  }

  const handleFileUpload: (file: File) => Promise<void> = async (file) => {
    try {
      setErrorOnUpload(false)
      if (onMediaUploadStart) onMediaUploadStart()
      const mediaUrl = await uploadFile(file)
      if (mediaUrl) {
        const renderedMediaUrl = await getImageUrl(mediaUrl)
        setQuestionMedia({
          mediaName: file.name,
          mediaType: MediaType.Image,
          mediaUrl,
          renderedMediaUrl
        })
      }
    } catch (error) {
      setErrorOnUpload(true)
    } finally {
      if (onMediaUploadEnd) onMediaUploadEnd()
    }

    LogAmplitudeEvent(EventType.SavedImageMedia, {
      surveyId,
      questionnaireId
    })
  }

  const handleRemoveFile: () => void = async () => {
    setIsMediaPresent(false)
    setTempUrl(undefined)
    await removeFile()
  }

  const handleSetMedia: (mediaUrl: string) => void = async (mediaUrl) => {
    setQuestionMedia({
      mediaName: mediaUrl,
      mediaType: MediaType.Video,
      mediaUrl
    })

    dispatch(closeAddMediaDialog())

    LogAmplitudeEvent(EventType.SavedVideoMedia, {
      surveyId,
      questionnaireId
    })
  }

  const supportedVideoService = [VideoService.YOUTUBE, VideoService.VIMEO]

  return (
    <MediaUploaderControl
      isDialogOpened={isDialogOpen}
      shouldHideAddMediaButton
      shouldHideRemoveMediaButton={!questionMedia?.mediaUrl}
      mediaType={questionMedia?.mediaType}
      thumbnailUrl={tempUrl || resizedThumbnailUrl}
      errorOnUpload={errorOnUpload}
      isMediaPresent={isMediaPresent}
      mediaUrl={resizedMediaUrl}
      supportedVideoService={supportedVideoService}
      onFileUpload={async (file) => {
        const tempThumb = await getImageContentSource(file)
        setTempUrl(tempThumb)
        setIsMediaPresent(true)
        await handleFileUpload(file)
      }}
      onRemoveMedia={handleRemoveFile}
      onMediaUrlSet={handleSetMedia}
      onCloseDialog={() => dispatch(closeAddMediaDialog())}
      maxSizeMb={5}
    />
  )
}

export default EntryMediaUploader
