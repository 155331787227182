import { questionnaireGql } from '../../../../data/gql-gen/questionnaire'
import {
  EntryType,
  QuestionKind,
  QuestionTypeCode
} from '../../../../data/model/questionnaire'

export interface ImportQuestionsQuestionItem {
  questionTypeCode: QuestionTypeCode
  questionLk: string
  question: {
    text: string
  }
  responseOptions: {
    responseOptionLk: string
    responseOption: {
      value: string
    }
  }[]
  settingValues: {
    code: string
    value: string
  }[]
}

export interface ImportQuestionsMatrixItem {
  matrixTitleLk: string
  matrixTitle: {
    matrixTitleId: string
    title: string
  }
  matrixRows: {
    questionLk: string
    question: {
      text: string
    }
  }
  responseOptions: {
    responseOptionLk: string
    responseOption: {
      value: string
    }
  }
  settingValues: {
    code: string
    value: string
  }[]
}

export interface ImportQuestionsTextItem {
  textCardLk: string
  textCard: {
    title: string
  }
}

export interface ImportQuestionsSectionItem {
  title: string
  sectionId: string
  entries: ImportQuestionsEntry[]
}

export interface ImportQuestionsEntry {
  id: string
  number: number
  contextPosition: number
  entryType: EntryType
  questionKind: QuestionKind
  entryItem:
    | ImportQuestionsQuestionItem
    | ImportQuestionsMatrixItem
    | ImportQuestionsTextItem
    | ImportQuestionsSectionItem
}

export interface ImportQuestions {
  questionnaireId: string
  entries: ImportQuestionsEntry[]
}

export interface ImportQuestionsData {
  draftQuestionnaire: ImportQuestions
}

export const IMPORT_QUESTIONS_DRAFT_QUESTIONNAIRE = questionnaireGql(
  /* GraphQL */ `
    query importQuestionsDraftQuestionnaire($projectId: ID!, $surveyId: ID) {
      draftQuestionnaire: draftQuestionnaireV2(
        projectId: $projectId
        surveyId: $surveyId
      ) {
        questionnaireId
        entries {
          id
          number
          contextPosition
          entryType
          questionKind
          entryItem {
            ... on DraftQuestionItem {
              questionTypeCode
              questionLk
              question {
                text
              }
              responseOptions {
                createdDate
                responseOptionLk
                responseOption {
                  value
                }
              }
              settingValues {
                code
                value
              }
            }
            ... on DraftMatrixItem {
              matrixTitleLk
              matrixTitle {
                matrixTitleId
                title
              }
              matrixRows {
                questionLk
                question {
                  text
                }
              }
              responseOptions {
                createdDate
                responseOptionLk
                responseOption {
                  value
                }
              }
              settingValues {
                code
                value
              }
            }
            ... on DraftTextCardItem {
              textCardLk
              textCard {
                title
              }
            }
            ... on DraftSectionItem {
              __typename
              title
              sectionId
              isLoopingEnabled @client
              entries {
                id
                number
                contextPosition
                entryType
                questionKind
                entryItem {
                  ... on DraftQuestionItem {
                    questionTypeCode
                    questionLk
                    question {
                      text
                    }
                    responseOptions {
                      createdDate
                      responseOptionLk
                      responseOption {
                        value
                      }
                    }
                    settingValues {
                      code
                      value
                    }
                  }
                  ... on DraftMatrixItem {
                    matrixTitleLk
                    matrixTitle {
                      matrixTitleId
                      title
                    }
                    matrixRows {
                      questionLk
                      question {
                        text
                      }
                    }
                    responseOptions {
                      createdDate
                      responseOptionLk
                      responseOption {
                        value
                      }
                    }
                    settingValues {
                      code
                      value
                    }
                  }
                  ... on DraftTextCardItem {
                    textCardLk
                    textCard {
                      title
                    }
                  }
                }
              }
            }
          }
        }
        __typename
      }
    }
  `
)
