import { useSnackbar } from '@focaldata/cin-ui-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudePublicEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { ForgotPassword } from '../../../components/Auth'
import { CreateResetPassword } from '../../../data/rest/accountUser'

const ForgotPasswordComponent: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleSendEmail = async (email: string) => {
    try {
      await CreateResetPassword(email)
      LogAmplitudePublicEvent(EventType.RequestedResetPassword, { email })
      navigate(`/forgot-password-successful/${email}`)
      return Promise.resolve(true)
    } catch (ex: any) {
      if (ex.response.data.message.includes('not found')) {
        enqueueSnackbar(
          'There is no user with that email address. Do you want to sign up instead?',
          {
            variant: 'error'
          }
        )
      }
      return Promise.resolve(true)
    }
  }

  const handleGoToRegister: () => void = () => {
    navigate('/signup')
  }

  return (
    <ForgotPassword
      onSendEmail={handleSendEmail}
      onGoToRegister={handleGoToRegister}
    />
  )
}

export default ForgotPasswordComponent
