import {
  Button,
  ButtonVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import React from 'react'
import useStyles from './ErrorPage.styles'

interface Props extends React.HTMLAttributes<HTMLElement> {
  errorCode: string
  title?: string
  subtitle?: string
  contactInfo?: string
  buttonText?: string
  onClick?: () => void
}

const ErrorPage: React.FC<Props> = (props: Props) => {
  const {
    onClick,
    style,
    errorCode,
    title,
    subtitle,
    contactInfo,
    buttonText
  }: Props = props

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Paper style={style} elevation={0} className={classes.emptyPaperStyle}>
      <Grid container direction="column" spacing={5}>
        <Grid container item xs={12} direction="column" spacing={2}>
          <Grid item xs={5}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeXxl,
                textClasses.highlightPrimary,
                textClasses.weightRegular
              )}
            >
              {errorCode}
            </p>
          </Grid>
          <Grid item xs={5}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeXl,
                textClasses.highlightStandard,
                textClasses.weightRegular
              )}
            >
              {title}
            </p>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid container item xs={12} direction="column" spacing={2}>
          <Grid item xs={9}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeL,
                textClasses.highlightStandard,
                textClasses.weightRegular
              )}
            >
              {subtitle}
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeL,
                textClasses.highlightStandard,
                textClasses.weightRegular
              )}
            >
              {contactInfo}
            </p>
          </Grid>
        </Grid>
        {buttonText && (
          <Grid container item>
            <Button variant={ButtonVariant.PrimaryFilled} onClick={onClick}>
              {buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default ErrorPage
