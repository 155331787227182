import { makeVar, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useAppDispatch } from '../../App.store'
import { CLEAR_QUESTIONNAIRE_ENTRY_V2 } from '../../data/gql/questionnaire/mutations/clearQuestionnaireEntryV2'
import { questionCardEmptied } from '../../modules/Questionnaire/Questionnaire.slice'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const questionCardEmptiedVar = makeVar<Record<string, boolean>>({})

export const useEmptyQuestionCardMutation = () => {
  const surveyId = useSurveyId()
  const projectId = useProjectId()
  const dispatch = useAppDispatch()

  const [emptyQuestionnaireEntry] = useMutation(CLEAR_QUESTIONNAIRE_ENTRY_V2, {
    context: { clientName: 'questionnaire' },
    onCompleted: (response) => {
      const questionId = response.clearQuestionnaireEntryV2?.id || ''
      questionCardEmptiedVar({ [questionId]: true })
      dispatch(
        questionCardEmptied({
          questionId
        })
      )
    }
  })

  const emptyQuestionCardMutation = useCallback(
    (questionId: string) => {
      return emptyQuestionnaireEntry({
        variables: {
          input: {
            projectId,
            entryId: questionId,
            surveyId
          }
        }
      })
    },
    [projectId, surveyId, emptyQuestionnaireEntry]
  )

  const emptyMatrixQuestionMutation = useCallback(
    (questionId: string) => {
      return emptyQuestionnaireEntry({
        variables: {
          input: {
            projectId,
            entryId: questionId,
            surveyId
          }
        }
      })
    },
    [projectId, surveyId, emptyQuestionnaireEntry]
  )

  return {
    emptyQuestionCardMutation,
    emptyMatrixQuestionMutation
  }
}
