import { questionnaireGql } from '../../../gql-gen/questionnaire'

const DRAFT_MATRIX_ROW = questionnaireGql(/* GraphQL */ `
  fragment DraftMatrixRow on DraftMatrixRow {
    position
    createdDate
    sinceDate
    questionLk
    question {
      __typename
      questionId
      text
      createdDate
    }
    pinned
    maskingRules {
      ...DraftMaskingRule
    }
    questionMedia {
      ...QuestionMedia
    }
    forks {
      forkId
      branchNumber
    }
    __typename
  }
`)

export default DRAFT_MATRIX_ROW
