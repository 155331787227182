import { SearchBarInput } from '@focaldata/cin-ui-components'
import React, { useState } from 'react'

interface Props {
  showClearIcon?: boolean
  onClickClear?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onSearchBarChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ProjectListSearch: React.FC<Props> = (props: Props) => {
  const { showClearIcon, onClickClear, onSearchBarChange } = props
  const [value, setValue] = useState('')
  return (
    <SearchBarInput
      value={value}
      showClearIcon={showClearIcon}
      onClickClear={(event) => {
        setValue('')
        return onClickClear && onClickClear(event)
      }}
      onChange={(event) => {
        setValue(event.target.value)
        return onSearchBarChange && onSearchBarChange(event)
      }}
    />
  )
}
export default ProjectListSearch
