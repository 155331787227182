import {
  Checkbox,
  Grid,
  LinkButton,
  SearchBarInput,
  SearchBarVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { produce } from 'immer'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { LogAmplitudeEvent, LogAmplitudePublicEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import {
  FilterOption,
  SearchableFilterOption,
  SelectedFilter
} from '../../data/model/results'
import { useSurveyId } from '../../hooks/useSurveyId'
import {
  FilterBlock,
  ResponseOptionSelection
} from '../ResultsFilterSiderbar/FilterBlock'
import LoadingFilters from './Filters.loading'
import useStyles from './Filters.styles'
import {
  SearchResultEntry,
  getSearchPrefixByFilterEntryType,
  getSearchResultUIKey,
  getSearchResultUIPosition,
  getSearchResultUIText,
  searchForEntries
} from './Filters.utils'

interface Props {
  isLoading: boolean
  isPublic?: boolean
  filterCount: number
  searchableChartEntries: SearchableFilterOption[]
  entryFilterOptions: FilterOption[]
  selectedFilters: SelectedFilter[]
  selectedFilterOptions: SearchResultEntry[]
  setSelectedFilterOptions: Dispatch<SetStateAction<SearchResultEntry[]>>
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void
}

const logFilterByVariableEvent = (isPublic: boolean, surveyId: string) => {
  if (isPublic) {
    LogAmplitudePublicEvent(EventType.FilteredByVariableFromPublicResults, {
      surveyId
    })
  } else {
    LogAmplitudeEvent(EventType.FilteredByVariable, { surveyId })
  }
}

const ITEMS_PER_PAGE = 20

const Filters: React.FC<Props> = (props: Props) => {
  const {
    isLoading,
    isPublic = false,
    filterCount,
    searchableChartEntries,
    selectedFilters,
    entryFilterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
    setSelectedFilters
  } = props
  const [value, setValue] = useState<string>('')
  const [filterBlockKey, setFilterBlockKey] = useState<number>(0)
  const { classes, cx: classnames } = useStyles()
  const surveyId = useSurveyId()
  const [currentPage, setCurrentPage] = useState(1)

  const toggleForkFilter = (
    newSelectedFilters: SelectedFilter[],
    responseOptionId: string,
    questionId: string
  ) => {
    const selectedFilterQuestion = newSelectedFilters.find(
      (filter) => filter.forkId === questionId
    )

    const roIdIndex = selectedFilterQuestion?.branchNumbers?.findIndex(
      (brNr) => brNr === responseOptionId
    )

    if (roIdIndex !== undefined && roIdIndex > -1) {
      selectedFilterQuestion?.branchNumbers?.splice(roIdIndex, 1)
    } else if (selectedFilterQuestion) {
      selectedFilterQuestion.branchNumbers?.push(responseOptionId)
      logFilterByVariableEvent(isPublic, surveyId)
    } else {
      newSelectedFilters.push({
        forkId: questionId,
        branchNumbers: [responseOptionId]
      })
    }
  }

  const toggleQuestionFilter = (
    newSelectedFilters: SelectedFilter[],
    responseOptionId: string,
    questionId: string,
    matrixTitleId?: string
  ) => {
    const selectedFilterQuestion = newSelectedFilters.find(
      (filter) =>
        filter.questionId === questionId &&
        (!matrixTitleId || matrixTitleId === filter.matrixTitleId)
    )

    const roIdIndex = selectedFilterQuestion?.responseOptionIds?.findIndex(
      (roId) => roId === responseOptionId
    )

    if (roIdIndex !== undefined && roIdIndex > -1) {
      selectedFilterQuestion?.responseOptionIds?.splice(roIdIndex, 1)
    } else if (selectedFilterQuestion) {
      selectedFilterQuestion.responseOptionIds?.push(responseOptionId)
      logFilterByVariableEvent(isPublic, surveyId)
    } else {
      newSelectedFilters.push({
        matrixTitleId,
        questionId,
        responseOptionIds: [responseOptionId]
      })
      logFilterByVariableEvent(isPublic, surveyId)
    }
  }

  const onToggleFilter = (
    responseOptionSelection: ResponseOptionSelection
  ): void => {
    const { isFork, responseOptionId, questionId, matrixTitleId } =
      responseOptionSelection
    const newSelectedFilters = produce(selectedFilters, (draft) => {
      if (isFork) {
        toggleForkFilter(draft, responseOptionId, questionId)
      } else {
        toggleQuestionFilter(draft, responseOptionId, questionId, matrixTitleId)
      }
    })

    setSelectedFilters(newSelectedFilters)
  }

  const handleSearchResultToggle: (
    event: React.ChangeEvent<HTMLInputElement>,
    searchResultEntry: SearchResultEntry
  ) => void = (event, searchResult) => {
    if (event.target.checked) {
      setSelectedFilterOptions([...selectedFilterOptions, searchResult])
      if (isPublic) {
        LogAmplitudePublicEvent(
          EventType.AddedQuestionToFiltersFromPublicResults,
          { surveyId }
        )
      } else {
        LogAmplitudeEvent(EventType.AddedQuestionToFilters, { surveyId })
      }
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (!event.target.checked) {
      const newSelectedFilterOptions = selectedFilterOptions.filter(
        (filterOption) =>
          ((!searchResult.isMatrix || !filterOption.isMatrix) &&
            searchResult.chartEntry.id !== filterOption.chartEntry.id) ||
          (searchResult.isMatrix &&
            filterOption.isMatrix &&
            (searchResult.chartEntry.id !== filterOption.chartEntry.id ||
              searchResult.chartEntry.rows[searchResult.matrixRow || 0].id !==
                filterOption.chartEntry.rows[filterOption.matrixRow || 0].id))
      )
      setSelectedFilterOptions(newSelectedFilterOptions)
    }
  }

  const getSearchResultMarkup: (
    resultEntry: SearchResultEntry,
    checked: boolean,
    prefix: 'Q' | 'A' | 'F'
  ) => JSX.Element = (resultEntry, checked, prefix) => (
    <div
      className={classnames(
        'fd-grid fd-container fd-align-items-flex-start fd-nowrap',
        classes.resultsContainer
      )}
      key={getSearchResultUIKey(resultEntry)}
    >
      <div
        className={classnames(
          'fd-grid fd-item fd-container fd-xs-auto fd-direction-row',
          classes.checkboxContainer
        )}
      >
        <div
          className={classnames(
            'fd-grid fd-container fd-align-items-flex-start fd-justify-content-flex-start'
          )}
        >
          <div
            className={classnames(
              'fd-grid fd-item  fd-xs-auto',
              classes.checkbox
            )}
          >
            <Checkbox
              checked={checked}
              onChange={(event) => {
                handleSearchResultToggle(event, resultEntry)
              }}
            />
          </div>
          <div
            className={classnames(
              'fd-grid fd-item fd-xs-auto ',
              classes.prefixContainer
            )}
          >
            <Text weight={TextWeight.SemiBold} size={TextSize.ms}>
              {prefix}
              {getSearchResultUIPosition(resultEntry)}
            </Text>
          </div>
        </div>
      </div>
      <div
        className={classnames('fd-grid fd-item fd-xs-9', classes.resultsText)}
      >
        <Text size={TextSize.ms} noWrap={false}>
          {getSearchResultUIText(resultEntry)}
        </Text>
      </div>
    </div>
  )

  const getResult: (
    searchableChartEntries: SearchableFilterOption[],
    value: string
  ) => JSX.Element[] = (searchableChartEntries, value) => {
    // this just gets the ones that contain what we need
    const filteredSearchableChartEntries = searchForEntries(
      searchableChartEntries,
      value
    )
    const sliceEnd = Math.min(
      currentPage * ITEMS_PER_PAGE,
      filteredSearchableChartEntries.length
    )

    // this maps them into actual stuff for search results
    const searchItems = filteredSearchableChartEntries
      .slice(0, sliceEnd)
      .flatMap((entry) => {
        const prefix = getSearchPrefixByFilterEntryType(
          entry.chartEntry.questionKind
        )
        // if it's matrix has to have both titleId and normal id match
        const checked = selectedFilterOptions.some(
          (filterOption) =>
            entry.chartEntry.id === filterOption.chartEntry.id &&
            ((!entry.isMatrix && !filterOption.isMatrix) ||
              (entry.isMatrix &&
                filterOption.isMatrix &&
                entry.chartEntry.rows[entry.matrixRow || 0].id ===
                  filterOption.chartEntry.rows[filterOption.matrixRow || 0].id))
        )
        return getSearchResultMarkup(entry, checked, prefix)
      })
    if (searchItems.length < filteredSearchableChartEntries.length) {
      searchItems.push(
        <div
          key="load-more"
          className="fd-grid fd-container fd-item fd-justify-content-center"
        >
          <LinkButton
            underlined
            onClick={() => setCurrentPage(currentPage + 1)}
            noPadding
            alignTextToEnd
          >
            Load more
          </LinkButton>
        </div>
      )
    }
    return searchItems
  }

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    setValue(event.target.value)
  }

  const handleClickClear: () => void = () => {
    setValue('')
  }

  const isResponseOptionSelected = (
    responseOptionSelection: ResponseOptionSelection
  ): boolean => {
    const { matrixTitleId, questionId, responseOptionId } =
      responseOptionSelection
    const selectedFilter = selectedFilters.find((selectedFilter) =>
      responseOptionSelection.isFork
        ? selectedFilter.forkId === questionId &&
          selectedFilter.branchNumbers?.some(
            (brNr) => brNr === responseOptionId
          )
        : selectedFilter.matrixTitleId === matrixTitleId &&
          selectedFilter.questionId === questionId &&
          selectedFilter.responseOptionIds?.some(
            (roId) => roId === responseOptionId
          )
    )

    return !!selectedFilter
  }

  const handleDelete: (filterOption: FilterOption) => void = (filterOption) => {
    const newSelectedFilterOptions = selectedFilterOptions.filter((option) => {
      const stuff = option.isMatrix
        ? option.chartEntry.id !== filterOption.matrixTitleId ||
          option.chartEntry.rows[option.matrixRow || 0].id !== filterOption.id
        : option.chartEntry.id !== filterOption.id
      return stuff
    })
    setSelectedFilterOptions(newSelectedFilterOptions)
    const newSelectedFilters = selectedFilters.filter(
      (selectedFilter) =>
        selectedFilter.matrixTitleId !== filterOption.matrixTitleId ||
        selectedFilter.questionId !== filterOption.id
    )

    setSelectedFilters(newSelectedFilters)
  }

  const getFilterBlock: (
    filterOptions: FilterOption[],
    filterBlockKey: number
  ) => JSX.Element[] = (filterOptions, filterBlockKey) => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return filterOptions?.map((filterOption: FilterOption) => (
      <Grid
        item
        xs={12}
        key={`${filterOption.id}${filterOption.matrixTitleId}`}
        className={classes.filterBlockContainer}
      >
        <FilterBlock
          key={`filter-block${filterBlockKey}`}
          onDelete={handleDelete}
          filterOption={filterOption}
          onToggleFilter={onToggleFilter}
          isResponseOptionSelected={isResponseOptionSelected}
        />
      </Grid>
    ))
  }

  const handleClearAll: () => void = () => {
    setSelectedFilters([])
    setValue('')
    setFilterBlockKey(filterBlockKey + 1)
  }

  const results = getResult(searchableChartEntries, value)
  const showClearIcon = value.length > 0

  const selectedEntries = isLoading ? (
    <LoadingFilters />
  ) : (
    getFilterBlock(entryFilterOptions, filterBlockKey)
  )

  return (
    <>
      <Grid container className={classes.filterTitleContainer}>
        <Grid container item xs={6}>
          <Text weight={TextWeight.Bold} size={TextSize.ml} marginRight>
            Filters
          </Text>
          <Text weight={TextWeight.Regular} size={TextSize.ml}>
            ({filterCount})
          </Text>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <LinkButton
            underlined
            onClick={handleClearAll}
            noPadding
            alignTextToEnd
          >
            Clear all
          </LinkButton>
        </Grid>
      </Grid>
      <div className={classes.searchBarContainer}>
        <SearchBarInput
          value={value}
          onChange={handleChange}
          onClickClear={handleClickClear}
          showClearIcon={showClearIcon}
          showResultsPopup
          searchResults={results}
          variant={SearchBarVariant.Filter}
          placeholder="Add filters..."
          menuWidthSameAsSearchBar
          maxHeightResultsMenu={30}
          minValueLengthForPopUp={0}
        />
      </div>
      <div className={classes.selectedEntries}>{selectedEntries}</div>
    </>
  )
}

export default Filters
