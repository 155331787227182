import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  deleteButton: {
    margin: theme.spacing(-0.25, 1.5, 0, 0.75)
  },
  actionContainer: {
    margin: theme.spacing(0, -1.5, 0, 0)
  },
  cardSelectorContainer: {
    marginTop: theme.spacing(0.25)
  }
})

export default useStyles
