export interface GqlService {
  name: string
  uri: string
}

const URL_FIELDWORK = process.env.REACT_APP_URL_QUESTIONNAIRE
  ? `${process.env.REACT_APP_URL_QUESTIONNAIRE}/fw`
  : '/v3/fieldwork'

const services: GqlService[] = [
  {
    name: 'dashboard',
    uri: `${process.env.REACT_APP_URL_DASHBOARD || '/v1/dashboard'}/graphql`
  },
  {
    name: 'questionnaire',
    uri: `${
      process.env.REACT_APP_URL_QUESTIONNAIRE || '/v3/questionnaire'
    }/graphql`
  },
  {
    name: 'fieldwork',
    uri: `${URL_FIELDWORK}/graphql`
  }
]
export default services
