import { Grid, Skeleton, Text, TextSize } from '@focaldata/cin-ui-components'
import { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Subheader from '../../../components/Subheader'
import { SurveyState } from '../../../data/gql-gen/dashboard/graphql'
import useGetSurveyState from '../../../hooks/useGetSurveyState'
import ThreeChildLayout from '../../../layouts/ThreeChildRowLayout'
import TwoChildRowLayout from '../../../layouts/TwoChildRowLayout'
import { useFieldworkData } from '../../../modules/Audience/Audience.hooks'
import { ProjectNameAndStatus } from '../../../modules/Project/ProjectNameAndStatus'
import { useFdChatPrice } from './FdChat.hooks'
import { USER_MAX_LENGTH_OF_INTERVIEW } from './FdChat.model'
import FdChatProgress from './FdChatProgress'

export type ContextType = { contentRef: React.RefObject<HTMLDivElement> }

const FdChatContainer = () => {
  const { pathname } = useLocation()
  const { fieldworkData } = useFieldworkData()
  const { total } = useFdChatPrice()
  const { surveyState, loading: surveyStateLoading } = useGetSurveyState()
  const contentRef = useRef<HTMLDivElement>(null)

  const isDraft = surveyState === SurveyState.Draft
  const isScheduled = surveyState === SurveyState.Scheduled

  useEffect(() => {
    // scroll to top when navigating to a new page
    contentRef.current?.scrollTo(0, 0)
  }, [pathname])

  return (
    <Grid
      display="flex"
      flexDirection="column"
      height="100dvh"
      className="fd-chat-container"
    >
      <Subheader
        leftRightPadding={false}
        topBottomPadding
        leftChild={
          <TwoChildRowLayout
            maxWidth="65%"
            spaceBetweenChildren
            leftChild={<ProjectNameAndStatus />}
            rightChild={
              surveyStateLoading ? (
                <Skeleton width={200} height={35} />
              ) : isDraft || isScheduled ? undefined : (
                <FdChatProgress />
              )
            }
          />
        }
        rightChild={
          surveyStateLoading ? (
            <Skeleton width={200} height={35} />
          ) : isDraft ? (
            <ThreeChildLayout
              leftChild={
                <Text size={TextSize.ms}>
                  <b>Interview length:</b> {USER_MAX_LENGTH_OF_INTERVIEW}{' '}
                  minutes
                </Text>
              }
              middleChild={
                <Text size={TextSize.ms}>
                  <b>Number of interviews:</b>{' '}
                  {fieldworkData?.samplesNeeded.unadjusted}
                </Text>
              }
              rightChild={
                <Text size={TextSize.ms}>
                  <b>Quote:</b> £{total.toLocaleString()}
                </Text>
              }
              justifyContent="flex-end"
              maxWidth={'max-content'}
              showDividers
              gap={'10px'}
            />
          ) : undefined
        }
      />
      <div
        className="fd-chat-container__content"
        style={{ flex: 1, overflowY: 'auto' }}
        ref={contentRef}
      >
        <Outlet context={{ contentRef } satisfies ContextType} />
      </div>
    </Grid>
  )
}

export default FdChatContainer
