import { EntryPrefix } from '../../data/model/questionnaire'

export enum Order {
  Randomise = 'Randomise order',
  Reverse = 'Reverse order'
}

export type QuestionPrefix =
  | `${EntryPrefix.QuestionnaireKind}${number}`
  | `${EntryPrefix.AudienceKind}${number}`
  | `${EntryPrefix.TextCardEntryType}${number}`
  | `${EntryPrefix.SectionEntryType}${number}`
