import dayjs from 'dayjs'

import { TranscriptsFileFormatType } from './FdChat.model'

const DATE_FORMAT = 'D MMMM YYYY'

export function getFdChatDatesFormatted(startDate = new Date()) {
  const daysToComplete = 7
  return {
    formattedStartDate: dayjs(startDate).format(DATE_FORMAT),
    formattedEndDate: dayjs(startDate)
      .add(daysToComplete, 'day')
      .format(DATE_FORMAT)
  }
}

export const generateTranscriptsFilename = (
  projectName: string,
  fileFormat: TranscriptsFileFormatType
) => {
  const currentTimestamp = dayjs().format('YYYY-MM-DD_HH-mm')
  const filename = `${projectName}-${currentTimestamp}.${fileFormat}`
  return filename
}

function stringToNumber(s: string) {
  let hash = 0
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function getRandomNumber(s: string) {
  const number = Math.abs(stringToNumber(s))
  // capping number between 1 and 99 as that is the range of images available from https://randomuser.me/
  return (number % 99) + 1
}

/**
 * Generates a random gender based on the given string.
 * @param s - The input string.
 * @returns A randomly generated gender ('Male' or 'Female').
 */
function getRandomGender(s: string) {
  const number = Math.abs(stringToNumber(s))
  return number % 2 === 0 ? 'Male' : 'Female'
}

export const getUserImage = ({
  userId,
  gender
}: {
  userId: string
  gender?: string | null
}) => {
  let userGender = gender

  // pick a random value between 'Male' and 'Female' if no gender is provided
  if (!userGender) {
    userGender = getRandomGender(userId)
  }

  return `https://randomuser.me/api/portraits/med/${
    userGender === 'Male' ? 'men' : 'women'
  }/${getRandomNumber(userId)}.jpg`
}
