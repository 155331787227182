import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  iconButton: {
    '&.MuiButtonBase-root': {
      padding: theme.spacing(0.5),
      margin: `-${theme.spacing(0.5)}`
    }
  }
})

export default useStyles
