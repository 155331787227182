import {
  Button,
  ButtonVariant,
  Grid,
  Icon,
  IconColor,
  IconName,
  IconSize,
  KebabMenu,
  Text,
  TextAlign,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import {
  DraftCustomAudience,
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceType
} from '../../../data/model/audience'
import useWindowSize from '../../../hooks/useWindowSize'
import { AudienceQuestionCardContainer } from '../AudienceQuestionCard'
import { CustomAudienceQuestionCardContainer } from '../CustomAudienceQuestionCard'
import {
  AUDIENCE_SCROLL_CONTAINER_ID,
  MIN_WIDTH_BEFORE_RESIZING
} from '../constants'
import useStyles from './SurveyAudienceListLoading.styles'

interface Props {
  audiences: FieldworkAudience[]
  height?: string
  emptyTitle?: string
  emptyDescription?: string
  onAddCustomAudience?: (isMulti: boolean) => void
  disableAddCustomAudience?: boolean
}

const SurveyAudienceList: React.FC<Props> = (props: Props) => {
  const {
    audiences,
    height,
    emptyTitle = 'Add audience criteria',
    emptyDescription = 'Pick age, gender or other criteria from the list to target your survey at the right people. Then add any quotas or screening. Any chosen criteria will be added as audience questions at the start of your survey.',
    onAddCustomAudience,
    disableAddCustomAudience
  }: Props = props
  const showEmptyStateControl = audiences.length === 0

  const { windowWidth } = useWindowSize()
  const isWindowResized = windowWidth < MIN_WIDTH_BEFORE_RESIZING
  const { classes } = useStyles({
    styleProps: { isWindowResized, height }
  })

  return (
    <div className={classes.cardListOuter}>
      <Grid item id={AUDIENCE_SCROLL_CONTAINER_ID} className={classes.cardList}>
        {showEmptyStateControl && (
          <Grid
            container
            className={classes.emptyStateContainer}
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              className={classes.emptyStateIcon}
            >
              <Icon
                name={IconName.PersonPlus}
                size={IconSize.Medium}
                iconColor={IconColor.Background}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Text
                size={TextSize.l}
                weight={TextWeight.SemiBold}
                highlight={TextHighlight.Background}
                gutterBottom
              >
                {emptyTitle}
              </Text>
            </Grid>
            <Grid
              item
              xs="auto"
              container
              justifyContent="center"
              className={classes.emptayStateText}
            >
              <Text
                size={TextSize.m}
                weight={TextWeight.Regular}
                highlight={TextHighlight.Background}
                align={TextAlign.Center}
              >
                {emptyDescription}
              </Text>
            </Grid>
          </Grid>
        )}
        {audiences.map((audience: FieldworkAudience) => {
          if (audience.memberType === FieldworkAudienceType.Standard) {
            const standardAudience =
              audience.memberItem as DraftStandardAudience
            return (
              <AudienceQuestionCardContainer
                key={standardAudience.criterion.code}
                audience={audience}
              />
            )
          }
          const customAudience = audience.memberItem as DraftCustomAudience
          return (
            <CustomAudienceQuestionCardContainer
              key={customAudience.questionLk}
              questionLk={customAudience.questionLk}
            />
          )
        })}
        {onAddCustomAudience && (
          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            marginTop={'20px'}
            marginBottom={'32px'}
          >
            <KebabMenu
              kebabMenuOptions={[
                {
                  id: 0,
                  textItem: 'Single select',
                  onClickItem: () => onAddCustomAudience(false),
                  disabled: disableAddCustomAudience
                },
                {
                  id: 1,
                  textItem: 'Multi select',
                  onClickItem: () => onAddCustomAudience(true),
                  disabled: disableAddCustomAudience
                }
              ]}
              menuButton={
                <Button
                  variant={ButtonVariant.SecondaryFilled}
                  startIcon={
                    <Icon
                      name={IconName.Add}
                      iconColor={IconColor.White}
                      size={IconSize.Large}
                    />
                  }
                >
                  Add
                </Button>
              }
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default memo(SurveyAudienceList)
