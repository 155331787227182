import {
  AlignInputText,
  Input,
  InputVariant,
  PaperGroupItem,
  Switch
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React, { memo, useEffect, useState } from 'react'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import { DraftTextCardItem } from '../../../data/model/questionnaire'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import EntryMediaUploader from '../BasicQuestion/MediaUploader/MediaUploader'
import { CardBackgroundContainer } from '../CardBackground'
import useStyles from './TextCard.styles'

interface Props extends WithEntry {
  defaultBody: string | undefined
  questionCardHeader: JSX.Element
  onChangeBody: (inputValue: string) => void
}

const TextCard: React.FC<Props> = (props: Props) => {
  const { defaultBody, entry, questionCardHeader, onChangeBody } = props
  const { classes } = useStyles()
  const [descriptionExpanded, setDescriptionExpanded] =
    useState<boolean>(!!defaultBody)

  useEffect(() => {
    setDescriptionExpanded(!!defaultBody)
  }, [defaultBody])

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasMedia = (entry.entryItem as DraftTextCardItem).questionMedia !== null

  return (
    <CardBackgroundContainer entryId={entry.id}>
      <PaperGroupItem>{questionCardHeader}</PaperGroupItem>

      <PaperGroupItem paddingTopBottom={2}>
        <div className="fd-grid fd-container fd-row">
          <Switch
            checked={descriptionExpanded}
            onChange={() => {
              if (descriptionExpanded) {
                onChangeBody('')
              }
              setDescriptionExpanded(!descriptionExpanded)
            }}
            label="Add subtitle"
            first
            last
          />
          {hasMedia && (
            <Divider orientation="vertical" className={classes.divider} />
          )}
          <div className={classes.mediaUploader}>
            <EntryMediaUploader entry={entry} />
          </div>
        </div>
      </PaperGroupItem>
      {descriptionExpanded && (
        <PaperGroupItem>
          <Input
            ariaLabel="Text card description"
            onFocus={() => {
              questionBeingEditedId(entry.id)
            }}
            alignInputText={AlignInputText.Left}
            placeholder="Add further details here"
            fullWidth
            multiline
            variant={InputVariant.Outlined}
            defaultValue={defaultBody}
            onBlur={(event) => {
              onChangeBody(event.target.value)
            }}
          />
        </PaperGroupItem>
      )}
    </CardBackgroundContainer>
  )
}

export default memo(TextCard, propsAreEqual)
