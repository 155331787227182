import {
  LoopQuotaPriority,
  Randomised
} from '../../../data/gql-gen/fieldwork/graphql'
import { useResponseOptionsLogic } from './useResponseOptionsLogic'

type ResponseOptionLk = string

export const isLoopQuotaPriorityCondition = (
  condition?: LoopQuotaPriority | Randomised
): condition is LoopQuotaPriority => {
  return condition?.__typename === 'LoopQuotaPriority'
}

export const useLoopSourceMatches = (
  questionLk: string
): Set<ResponseOptionLk> => {
  const responseOptionsLogic = useResponseOptionsLogic(questionLk)
  const logicCondition = responseOptionsLogic?.condition

  if (isLoopQuotaPriorityCondition(logicCondition)) {
    return new Set(
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      logicCondition?.responseOptionLoopSourcePairs?.map(
        ({ responseOptionId }) => responseOptionId
      )
    )
  }

  return new Set()
}
