import ListItem from '@mui/material/ListItem'
import React from 'react'

import useStyles from './InfoPanel.styles'

type Props = React.HTMLAttributes<HTMLElement>

// @todo Legacy eslint violation – fix this when editing
// eslint-disable-next-line react/display-name
export const InfoPanelItem = React.forwardRef(
  (props: Props, ref?: React.Ref<HTMLLIElement>) => {
    const { children } = props
    const { classes } = useStyles()

    return (
      <ListItem ref={ref} className={classes.listItem}>
        {children}
      </ListItem>
    )
  }
)
