import { InMemoryCache, Resolver } from '@apollo/client'
import {
  DraftCombinedQuestion,
  GetDraftCombinedQuestionQuery,
  GetDraftCombinedQuestionQueryVariables
} from '../../../../../../data/gql-gen/local/graphql'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const setDraftCombinedQuestion: Resolver = (
  _,
  data: { draftCombinedQuestion: DraftCombinedQuestion },
  { cache }: { cache: InMemoryCache }
) => {
  cache.writeQuery<
    GetDraftCombinedQuestionQuery,
    GetDraftCombinedQuestionQueryVariables
  >({
    query: GET_DRAFT_COMBINED_QUESTION,
    data: {
      __typename: 'Query',
      draftCombinedQuestion: {
        __typename: 'DraftCombinedQuestion',
        ...data.draftCombinedQuestion,
        mappedResponseOptions:
          data.draftCombinedQuestion.mappedResponseOptions.map(
            (mappedResponseOption) => {
              return {
                __typename: 'DraftCombinedResponseOption',
                ...mappedResponseOption
              }
            }
          ),
        marketQuestions: data.draftCombinedQuestion.marketQuestions.map(
          (marketQuestion) => {
            return {
              __typename: 'DraftMarketQuestion',
              ...marketQuestion,
              responseOptions: marketQuestion.responseOptions.map(
                (marketResponseOption) => {
                  return {
                    __typename: 'DraftMarketResponseOption',
                    ...marketResponseOption
                  }
                }
              )
            }
          }
        )
      }
    }
  })

  return data.draftCombinedQuestion
}
