import { useMutation } from '@apollo/client'
import {
  CreateCustomizedCriterionOptionV2Mutation,
  CreateCustomizedCriterionOptionV2MutationVariables,
  CustomizedCriterionOptionInput
} from '../../data/gql-gen/questionnaire/graphql'
import {
  CREATE_CUSTOMIZED_CRITERION_OPTION,
  REMOVE_CUSTOMIZED_CRITERION_OPTION,
  UPDATE_CUSTOMIZED_CRITERION_OPTION
} from '../../data/gql/questionnaire/mutations/CustomAgeRanges.mutations'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import {
  StandardCriterionOptionValidationErrors,
  StandardCriterionValidationErrors
} from '../../data/model/fieldwork'
import {
  AGE_RANGES_CRITERION_CODE,
  getErrorMessage
} from '../../modules/Audience/CustomAgeRanges/CustomAgeRanges.utils'
import useGetDraftAudience from '../audience/useGetDraftAudience'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

type ErrorsByOptionCode = Record<
  StandardCriterionOptionValidationErrors['code'],
  {
    hasError: boolean
    errorCodes: string[]
  }
>

export const useAddCustomAgeRange = (questionId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [addCustomizedCriterionOption] = useMutation<
    CreateCustomizedCriterionOptionV2Mutation,
    CreateCustomizedCriterionOptionV2MutationVariables
  >(CREATE_CUSTOMIZED_CRITERION_OPTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
  })

  return () => {
    addCustomizedCriterionOption({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId,
          criterionOptions: [
            {
              criterionOptionCode: '_-_'
            }
          ]
        }
      }
    })
  }
}

export const useDeleteCustomAgeRange = (questionId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [removeCustomizedCriterionOption] = useMutation(
    REMOVE_CUSTOMIZED_CRITERION_OPTION,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
    }
  )

  return (responseOptionId: string) => {
    removeCustomizedCriterionOption({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId,
          responseOptionId
        }
      }
    })
  }
}

export const useUpdateCustomizedCriterionOption = (questionId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [updateCustomizedCriterionOption] = useMutation(
    UPDATE_CUSTOMIZED_CRITERION_OPTION,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
    }
  )

  return (
    responseOptionId: string,
    {
      min,
      max,
      qualified,
      quota
    }: {
      min: string
      max: string
      qualified: boolean
      quota?: number
    }
  ) => {
    const criterionOption: CustomizedCriterionOptionInput = {
      criterionOptionCode: `${min}_-_${max}`,
      qualification: qualified
        ? {
            createdDate: new Date().toISOString(),
            sinceDate: new Date().toISOString()
          }
        : null,
      quota:
        typeof quota === 'number'
          ? {
              createdDate: new Date().toISOString(),
              sinceDate: new Date().toISOString(),
              percent: quota
            }
          : null
    }

    return updateCustomizedCriterionOption({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId,
          responseOptionId,
          criterionOption
        }
      }
    })
  }
}

export const useCustomAgeRangesValidation = (): {
  errorsByOptionCode: ErrorsByOptionCode
  errorMessage: string
} => {
  const { draftAudience } = useGetDraftAudience()

  const standardCriterionValidationErrors = draftAudience?.validationErrors as
    | StandardCriterionValidationErrors[]
    | undefined

  const standardCriterionOptionsErrors =
    standardCriterionValidationErrors?.find(
      ({ code }: StandardCriterionValidationErrors) =>
        code === AGE_RANGES_CRITERION_CODE
    )?.standardCriterionOptionsErrors ?? []

  const errorsByOptionCode: ErrorsByOptionCode = {}
  const allErrors: StandardCriterionOptionValidationErrors['errors'] = []

  standardCriterionOptionsErrors.forEach(
    ({ code, errors }: StandardCriterionOptionValidationErrors) => {
      allErrors.push(...errors)
      errorsByOptionCode[code] = {
        hasError: true,
        errorCodes: errors
      }
    }
  )

  return { errorsByOptionCode, errorMessage: getErrorMessage(allErrors) }
}
