import {
  IconButton,
  IconColor,
  IconName,
  IconSize,
  KebabMenu
} from '@focaldata/cin-ui-components'
import React from 'react'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode,
  SettingValue
} from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { isSettingEnabled } from '../../../utils/questionnaireUtils'
import useEntrySettingValues from './useEntrySettingValues'
import useLoopSourceOptions from './useLoopSourceOptions'
import useLoopingConfigData from './useLoopingConfigData'

interface MediaDisabledButtonProps {
  entry: DraftQuestionnaireEntry
}

const SourceMediaButton: React.FC<MediaDisabledButtonProps> = ({ entry }) => {
  const handleSettingChange = useSetQuestionnaireSetting(entry)

  const { isLoopingEnabled, loopingConfig } =
    useLoopingConfigData(entry.sectionId) ?? {}
  const loopSourceOptions = useLoopSourceOptions(
    loopingConfig?.sourceEntryNumber
  )

  const anySourceOptionHasMedia = loopSourceOptions.some((s) => s.media)
  const settingValues = useEntrySettingValues(entry.number)

  const sourceMediaDisabled = isSettingEnabled(
    settingValues,
    QuestionSettingCode.DisableSourceMedia
  )

  const handleSourceMediaState = async () => {
    await handleSettingChange(
      [
        {
          settingCode: QuestionSettingCode.DisableSourceMedia,
          settingValue: sourceMediaDisabled
            ? SettingValue.Disabled
            : SettingValue.Enabled
        }
      ],
      'no-cache'
    )
  }
  const buttonActionText = sourceMediaDisabled
    ? 'Enable source media'
    : 'Disable source media'
  const buttonTooltipText = sourceMediaDisabled
    ? 'Source media disabled'
    : 'Source media enabled'

  if (!(isLoopingEnabled && anySourceOptionHasMedia)) {
    return null
  }

  return (
    <div style={{ marginRight: 10, marginBottom: -2 }}>
      <KebabMenu
        kebabMenuOptions={[
          {
            id: 1,
            textItem: buttonActionText,
            onClickItem: () => handleSourceMediaState()
          }
        ]}
        menuButton={
          <IconButton
            ariaLabel="Source media button"
            smallHoverShadowSize
            iconName={
              sourceMediaDisabled
                ? IconName.HideImageOutlined
                : IconName.ImageOutlined
            }
            iconSize={IconSize.Large}
            iconColor={sourceMediaDisabled ? IconColor.Green : undefined}
            tooltipText={buttonTooltipText}
          />
        }
      />
    </div>
  )
}

export default SourceMediaButton
