import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  floatingFilterButton: {
    zIndex: theme.zIndex.drawer - 1,
    top: theme.spacing(18.5),
    right: theme.spacing(3.75),
    position: 'absolute'
  },
  emptyState: {
    paddingBottom: theme.spacing(4)
  }
})

export default useStyles
