import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  iconButton: {
    margin: theme.spacing(-1.375, 0)
  },
  infoIcon: {
    display: 'inherit',
    alignItems: 'center'
  },
  limitInputLabel: {
    marginRight: theme.spacing(2)
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2)
  }
})

export default useStyles
