import { Grid, Skeleton, Text, TextSize } from '@focaldata/cin-ui-components'
import React from 'react'

import useStyles from './CompletesInput.styles'

const CompletesInputLoading: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Text size={TextSize.ms} marginRight>
        Completes:
      </Text>
      <Grid item className={classes.input}>
        <Skeleton height={40} width={74} />
      </Grid>
    </Grid>
  )
}

export default CompletesInputLoading
