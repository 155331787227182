import { useReactiveVar } from '@apollo/client'
import React, { useCallback } from 'react'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import { DraftQuestionItem } from '../../../data/model/questionnaire'
import useCopyPasteComplete from '../../../hooks/copyPaste/useCopyPasteComplete'
import { useDuplicateQuestionnaireEntry } from '../../../hooks/questionnaire/useDuplicateQuestionnaireEntry'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import BasicQuestionLayout from '../../../layouts/BasicQuestionLayout'
import { duplicateQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import { WithEntry, newEntryId } from '../../../utils/questionnaireUtils'
import { BasicEntryContext } from '../BasicQuestion/BasicQuestion.container'
import Footer from '../BasicQuestion/QuestionCardFooter/QuestionCardFooter.container'
import { ResponseOptionsList } from '../BasicQuestion/ResponseOptions'
import { CardTitleContainer } from '../CardTitle'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import MaxDiffSpecs from './MaxDiffSpecs'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const MaxDiffQuestionContainer: React.FC<Props> = ({
  entry,
  shouldTitleInputFocus
}: Props) => {
  const entryItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem
  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const { pasteToTitle } = useCopyPasteComplete(questionLk)
  const { validateBasicQuestionText } = useQuestionnaireValidation()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )
  const isBasicQuestionTextEmpty = validateBasicQuestionText(
    entryItem.questionLk
  )
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const isNewlyCreatedEntry =
    !!newlyCreatedEntryId && newlyCreatedEntryId === entryItem.questionLk
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasEmptyQuestionTitle = entryItem.question === null

  const { duplicateQuestionnaireEntry } = useDuplicateQuestionnaireEntry(
    entry.id
  )

  const handleDuplicateQuestion: () => void = useCallback(async () => {
    duplicateQuestionTransactionDatadog.start()
    await duplicateQuestionnaireEntry({ position: entry.position + 1 })
  }, [duplicateQuestionnaireEntry, entry.position])

  const handlePasteInTitle = async (
    text: string,
    styledText: string,
    responseOptions: string[]
  ) => {
    questionBeingEditedId(entry.id)
    await pasteToTitle(text, styledText, responseOptions)
  }

  return (
    <BasicEntryContext.Provider value={entryItem}>
      <BasicQuestionLayout
        entryId={entry.id}
        questionCardHeader={
          <SurveyQuestionCardHeaderContainer
            entry={entry}
            hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
            titleContent={
              <CardTitleContainer
                // for max-diff question when multilined pasting occur only first line will be pasted, the rest will be handled by "smart pasting"
                shouldPasteOneLine
                ariaLabel="MaxDiff question header"
                questionLk={entryItem.questionLk}
                hasError={!isNewlyCreatedEntry && isBasicQuestionTextEmpty}
                shouldTitleInputFocus={shouldTitleInputFocus}
                onPasteInTitle={handlePasteInTitle}
              />
            }
            onClickDuplicateIcon={handleDuplicateQuestion}
            disabledDuplicate={hasEmptyQuestionTitle}
            entries={flatDraftQuestionnaireEntries}
          />
        }
        responseOptions={
          <ResponseOptionsList
            entries={flatDraftQuestionnaireEntries}
            currentEntry={entry}
          />
        }
        questionCardFooter={<Footer entry={entry} />}
        defaultOptions={<MaxDiffSpecs entry={entry} />}
      />
    </BasicEntryContext.Provider>
  )
}

export default MaxDiffQuestionContainer
