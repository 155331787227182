import dayjs from 'dayjs'
import Pptxgen from 'pptxgenjs'
import logoSrc from '../assets/fd-logo.svg'
import { ChartEntry, QuestionKind, Row } from '../data/model/chart'
import { MediaType } from '../data/model/questionnaire'
import { asyncMap } from './asyncMaps'
import {
  formatMatrixDataset,
  formatMatrixMultiSelectDataset,
  formatRankedDataset,
  getDisplayNameFromQuestionType,
  isAudienceQuestion,
  isFreeTextQuestion,
  isMatrixMultipleChoiceQuestion,
  isMatrixSingleChoiceQuestion,
  isMultipleChoiceQuestion,
  isRankedQuestion,
  isScaleQuestion,
  isSingleChoiceQuestion,
  safeDivision
} from './chartTypeUtils'
import {
  getImageDimensionsAsync,
  getScaledImageDimensions
} from './imageDimensions'

interface PptxChartData {
  name: string
  labels: string[]
  values: (number | undefined)[]
}

interface SurveyFieldworkDates {
  startDate: string | null
  endDate: string | null
}

interface DatasetFormatter {
  (entry: ChartEntry): {
    formattedDataset: { label: string; data: number[] }[]
    samplesCollectedPerRow: number[]
  }
}

const COLORS_CHART = [
  '#14465A',
  '#FF6E5A',
  '#59A14F',
  '#EDC948',
  '#B9C8CE',
  '#F28E2B',
  '#B07AA1',
  '#4E79A7',
  '#FFD4CE',
  '#9C755F',
  '#58B5DA',
  '#F8E9B5',
  '#A5D09F',
  '#F3F6F7',
  '#F8C28C',
  '#DEE2E6',
  '#88AAAA',
  '#F88DF6',
  '#52F4AE',
  '#E9FF70'
]

const getSingleMultipleColor: (index: number) => string = (index) => {
  const indexModulo = index % 10 // 10 are the primary colors for the charts
  const selectedColor = COLORS_CHART[indexModulo]

  return selectedColor
}

const roundToOneDecimal = (value: number): number => {
  return Math.round(value * 10) / 10
}

const formatDataForPptxBarChart = (chartEntry: ChartEntry): PptxChartData[] => {
  const reversedRows = [...chartEntry.rows].reverse()

  const hasWeights = chartEntry.weightedSamplesCollected ? true : false

  const totalSamplesFiltered = hasWeights
    ? chartEntry.weightedSamplesCollectedFiltered
    : chartEntry.samplesCollectedFiltered

  const dataChartAreaLine = [
    {
      name: chartEntry.text,
      labels: reversedRows.map((row) => row.text),
      values: reversedRows.map((row) => {
        const selectedRowItem = row.rowItems.find(
          (ri) => ri.text === 'Selected'
        )
        const completes = hasWeights
          ? selectedRowItem?.weightedCompletes
          : selectedRowItem?.completes

        return roundToOneDecimal(
          safeDivision(completes, totalSamplesFiltered) * 100
        )
      })
    }
  ]

  return dataChartAreaLine
}
const formatDataForPptxRankedStackedChart = (
  chartEntry: ChartEntry
): PptxChartData[] => {
  const { formattedDataset } = formatRankedDataset(chartEntry)
  const labels = chartEntry.rows.map((row: Row) => row.text).reverse()
  const dataChartAreaLine = formattedDataset.map((row) => {
    const values = row.data
      .reverse()
      .map((data) => roundToOneDecimal(data * 100))
    return { name: row.label, labels, values }
  })

  return dataChartAreaLine
}

const formatDataForPptxMatrixChart = (
  chartEntry: ChartEntry,
  datasetFormatter: DatasetFormatter = formatMatrixDataset
): PptxChartData[] => {
  const { formattedDataset } = datasetFormatter(chartEntry)

  const labels = chartEntry.rows.map((row: Row) => row.text).reverse()
  const dataChartAreaLine = formattedDataset.map((row) => {
    const values = row.data
      .reverse()
      .map((data) => roundToOneDecimal(data * 100))
    return { name: row.label, labels, values }
  })

  return dataChartAreaLine
}
const addQuestionTextToSlide = async (
  slide: Pptxgen.Slide,
  chartEntry: ChartEntry,
  projectName: string
) => {
  slide.addText(projectName, {
    x: 0.25,
    y: 0.25,
    w: 9,
    h: 0.25,
    bold: true,
    color: '989CBE',
    fontFace: 'Proxima Nova Semibold',
    isTextBox: true,
    fontSize: 10
  })
  slide.addText('Add your key insight or takeaway', {
    x: 0.25,
    y: 0.5,
    w: 9,
    h: 1,
    bold: true,
    isTextBox: true,
    color: '72758D',
    fontFace: 'Proxima Nova Semibold',
    fontSize: 14
  })
  // add question title
  slide.addText(
    `${chartEntry.questionKind === QuestionKind.Audience ? 'A' : 'Q'}${
      chartEntry.contextPosition
    } ${chartEntry.text}`,
    {
      x: 6.25,
      y: 1.5,
      w: 3,
      h: 2,
      valign: 'top',
      isTextBox: true,
      fontFace: 'Proxima Nova Semibold',
      fontSize: chartEntry.text.length > 180 ? 10 : 12
    }
  )
  if (chartEntry.mediaType === MediaType.Image) {
    const { height, width } = await getImageDimensionsAsync(chartEntry.mediaUrl)
    const { height: scaledHeight, width: scaledWidth } =
      getScaledImageDimensions(width, height, 3, 1.5)
    slide.addImage({
      path: chartEntry.mediaUrl,
      x: 6.25,
      y: 2,
      w: scaledWidth,
      h: scaledHeight
    })
  }
  slide.addText('Add your commentary', {
    x: 6.25,
    y: 3.5,
    w: 3,
    h: 1.5,
    color: '989CBE',
    valign: 'top',
    fontFace: 'Proxima Nova Semibold',
    fontSize: 12,
    isTextBox: true
  })
  const filterApplied =
    chartEntry.samplesCollectedFiltered !== chartEntry.samplesCollected
  const respondentsTotalText = filterApplied
    ? 'respondents total'
    : 'respondents'
  const respondentsFilteredText = filterApplied
    ? `, ${chartEntry.samplesCollectedFiltered} respondents filtered`
    : ''

  // add question type and nr of respondents
  slide.addText(
    `${getDisplayNameFromQuestionType(chartEntry)} | ${
      chartEntry.samplesCollected
    } ${respondentsTotalText}${respondentsFilteredText}`,
    {
      x: 0.25,
      y: '95%',
      w: '100%',
      align: 'left',
      color: '989CBE',
      fontFace: 'Proxima Nova Semibold',
      fontSize: 10
    }
  )
}

export const downloadPptxFile = async (
  data: ChartEntry[],
  fieldworkDates: SurveyFieldworkDates,
  projectName: string | undefined = ''
) => {
  // create the file, add a first page with the FD logo and stuff
  const pptx = new Pptxgen()

  const coverSlide = pptx.addSlide()
  coverSlide.addImage({ path: logoSrc, x: 0.6, y: 0.5, h: 0.3, w: 2 })
  coverSlide.addText(projectName, {
    x: 0.5,
    y: 2,
    h: 0.75,
    w: 9,
    color: '72758D',
    fontFace: 'Proxima Nova Semibold',
    fontSize: 32
  })
  const { startDate, endDate } = fieldworkDates
  const fieldworkStartDate = dayjs(startDate).format('D MMM YYYY')
  const fieldworkEndDate = dayjs(endDate).format('D MMM YYYY')
  const sampleAndFieldwork = `Sample size: ${data[0].samplesCollected}\nFieldwork: ${fieldworkStartDate} - ${fieldworkEndDate}`
  coverSlide.addText(sampleAndFieldwork, {
    x: 0.5,
    y: 2.75,
    h: 0.625,
    w: 9,
    valign: 'top',
    fontFace: 'Proxima Nova Semibold',
    fontSize: 16
  })
  const datePptxGenerated = dayjs(new Date()).format('D MMM YYYY')
  coverSlide.addText(datePptxGenerated, {
    x: 0.5,
    y: 5,
    h: 0.5,
    color: '72758D',
    fontFace: 'Proxima Nova Semibold'
  })

  // for each chart entry, create a new slide
  await asyncMap(data, async (chartEntry, index) => {
    if (isFreeTextQuestion(chartEntry)) {
      return
    }

    const commonChartProps = {
      x: 0.25,
      y: 1.5,
      w: 6,
      h: 3.5,
      valAxisLabelColor: 'FFFFFF',
      dataLabelFontFace: 'Proxima Nova Semibold',
      valAxisMinVal: 0,
      dataLabelFontSize: 8,
      showValue: true,
      showPercent: true,
      valGridLine: { style: 'none' } as unknown as Pptxgen.OptsChartGridLine,
      valGridLineColor: 'FFFFFF',
      valAxisHidden: true,
      catAxisLabelFontSize: 8,
      catAxisLineShow: false,
      catAxisLabelFontFace: 'Proxima Nova Semibold',
      catAxisLineColor: 'FFFFFF',
      barDir: 'bar'
    }

    const slide = pptx.addSlide()
    // add the q number, text and nr of respondents
    await addQuestionTextToSlide(slide, chartEntry, projectName)

    // if there are no samples on this question just don't show the chart
    if (chartEntry.samplesCollectedFiltered === 0) {
      return
    }
    // based on the type of the question, create a chart
    if (
      isSingleChoiceQuestion(chartEntry) ||
      isMultipleChoiceQuestion(chartEntry) ||
      isAudienceQuestion(chartEntry)
    ) {
      slide.addChart(
        pptx.ChartType.bar,
        formatDataForPptxBarChart(chartEntry),
        {
          chartColors: [getSingleMultipleColor(index)],
          dataLabelColor: '000000',
          dataLabelFormatCode: '0.0\\%',
          ...commonChartProps
        }
      )
    }

    if (isRankedQuestion(chartEntry)) {
      slide.addChart(
        pptx.ChartType.bar,
        formatDataForPptxRankedStackedChart(chartEntry),
        {
          barGrouping: 'stacked',
          dataLabelColor: 'FFFFFF',
          valAxisMaxVal: 100,
          dataLabelFormatCode: '0\\%;;;',
          showLegend: true,
          chartColors: COLORS_CHART,
          ...commonChartProps
        }
      )
    }

    if (isMatrixSingleChoiceQuestion(chartEntry)) {
      slide.addChart(
        pptx.ChartType.bar,
        formatDataForPptxMatrixChart(chartEntry, formatMatrixDataset),
        {
          barGrouping: 'stacked',
          dataLabelColor: 'FFFFFF',
          valAxisMaxVal: 100,
          dataLabelFormatCode: '0\\%;;;',
          showLegend: true,
          chartColors: COLORS_CHART,
          ...commonChartProps
        }
      )
    }

    if (isMatrixMultipleChoiceQuestion(chartEntry)) {
      slide.addChart(
        pptx.ChartType.bar,
        formatDataForPptxMatrixChart(
          chartEntry,
          formatMatrixMultiSelectDataset
        ),
        {
          showLegend: true,
          chartColors: COLORS_CHART,
          dataLabelFormatCode: '0\\%;;;',
          dataLabelColor: '000000',
          ...commonChartProps
        }
      )
    }

    if (isScaleQuestion(chartEntry)) {
      slide.addText('Mean', {
        x: '0%',
        y: '40%',
        w: '60%',
        align: 'center'
      })
      slide.addText(
        (
          Math.round(parseFloat(chartEntry.rows[0].text) * 100) / 100
        ).toString(),
        {
          x: '0%',
          y: '50%',
          w: '60%',
          align: 'center',
          fontSize: 64
        }
      )
    }
  })

  pptx.title = projectName
  pptx.writeFile({ fileName: `${projectName}` })
}
