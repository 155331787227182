export interface SplitTextResult {
  title: string
  responseOptions: string[]
}

export const cleanText: (text: string) => string = (text) => {
  return text.replace(/^\s*([○•-]+|[\da-zA-Z]*[).]+)\s+/g, '').trim()
}

const isEmptyLine: (shard: string) => boolean = (shard) => {
  return shard.trim() === ''
}

export const splitTextIntoLinesAndCleanup: (
  text: string,
  skipFirstLine?: boolean
) => string[] = (text, skipFirstLine = false) => {
  return text
    .split('\n')
    .filter((ro) => !isEmptyLine(ro))
    .map((ro, index) => (index === 0 && skipFirstLine ? ro : cleanText(ro)))
}

export const splitText: (text: string) => SplitTextResult | undefined = (
  text
) => {
  const shards = text.split('\n').filter((s) => !isEmptyLine(s))
  if (shards.length === 0) {
    return undefined
  }
  if (shards.length === 1) {
    // should this be a failure or?
    return { title: shards[0], responseOptions: [] }
  }

  const title = cleanText(shards[0])
  const rawResponseOptions = shards.slice(1)
  const responseOptions = rawResponseOptions.map((ro) => cleanText(ro))

  return { title, responseOptions }
}

export const replacingTextAt = (
  currentText: string,
  replacingText: string,
  startSelectedText: number,
  endSelectedText: number
) => {
  const newText =
    currentText.slice(0, startSelectedText) +
    replacingText +
    currentText.slice(endSelectedText)

  return newText
}
