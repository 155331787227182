import { useParams } from 'react-router-dom'

export const useProjectId = () => {
  const { projectId } = useParams()

  if (!projectId) {
    throw new Error('Missing project ID required for route')
  }

  return projectId
}
