import {
  Alert,
  AlertType,
  Dialog,
  DialogMaxWidth,
  Grid,
  IconButton,
  IconName,
  Input,
  InputVariant,
  Switch,
  Text,
  TextHighlight,
  TextSize,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import { LoadingBar } from '../../../components/LoadingBar'
import useStyles from './DialogPublishResults.styles'

interface Props {
  isDialogOpen: boolean
  publishing: boolean
  error: boolean
  loadingData: boolean
  onClose: () => void
  isSurveyPublished: boolean
  publicLink: string
  onTogglePublish: (published: boolean) => void
}

const SECONDS_TO_DOWNLOAD = 18

export const DialogPublishResultsControl: React.FC<Props> = (props: Props) => {
  const {
    isDialogOpen,
    publishing,
    loadingData,
    error,
    isSurveyPublished,
    publicLink,
    onClose,
    onTogglePublish
  }: Props = props

  const [isCopiedTooltipOpen, setIsCopiedTooltipOpen] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const { classes } = useStyles()

  useEffect(() => {
    if (publishing === true) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = 100 / SECONDS_TO_DOWNLOAD
          return Math.min(oldProgress + diff, 100)
        })
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }

    return () => {
      // No Op
    }
  }, [publishing])

  const handleCopyClick = (): void => {
    navigator.clipboard.writeText(publicLink)
    setIsCopiedTooltipOpen(true)
    setTimeout(() => setIsCopiedTooltipOpen(false), 2000)
  }

  return (
    <Dialog
      open={isDialogOpen}
      primaryButtonClick={onClose}
      primaryButtonDisabled={publishing}
      onClose={onClose}
      title="Publish results"
      primaryButtonText="Ok"
      maxWidth={DialogMaxWidth.Sm}
    >
      <Grid direction="column">
        <Text size={TextSize.m}>
          Make these results viewable by anyone by publishing to the web.
        </Text>
        {publishing && (
          <>
            <Grid
              className={classes.loadingContainer}
              container
              direction="row"
            >
              <LoadingBar
                className={classes.loadingBar}
                variant="determinate"
                value={progress}
                classes={{ bar: classes.loadingProgress }}
              />
            </Grid>
            <Text size={TextSize.s}>
              Publishing your results can take up to 20 seconds
            </Text>
          </>
        )}
        {isSurveyPublished && !publishing && (
          <Grid className={classes.urlContainer} container direction="row">
            <Grid container xs={11}>
              <Input
                fullWidth
                variant={InputVariant.Filled}
                readOnly
                value={publicLink}
              />
            </Grid>
            <Grid container xs={1}>
              <Tooltip
                open={isCopiedTooltipOpen}
                tipPlacement={TipPlacement.Top}
                title="Copied link"
              >
                <IconButton
                  className={classes.copyButton}
                  iconName={IconName.FileCopyOutlined}
                  onClick={handleCopyClick}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" className={classes.switchContainer}>
          <Switch
            first
            defaultChecked={isSurveyPublished}
            disabled={loadingData || publishing}
            onChange={() => onTogglePublish(!isSurveyPublished)}
          />
          <Text size={TextSize.m} highlight={TextHighlight.Background}>
            Published
          </Text>
        </Grid>
        {isSurveyPublished && (
          <Text size={TextSize.ms} highlight={TextHighlight.Background}>
            These results are published on the web. Use the link above to share
            them.
          </Text>
        )}
        {!isSurveyPublished && (
          <Text size={TextSize.ms}>
            Once the survey is published you will be given a link that you can
            use to <br /> share the results.
          </Text>
        )}
        {error && (
          <Alert
            severity={AlertType.ERROR}
            style={{ marginTop: 16, maxWidth: 420 }}
          >
            Something went wrong. Please refresh the page and try again.
          </Alert>
        )}
      </Grid>
    </Dialog>
  )
}
