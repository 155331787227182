/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Icon,
  IconColor,
  IconName,
  IconSize
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles, { StyleProps } from './ListItemSidebar.styles'

export interface Props extends React.LiHTMLAttributes<HTMLLIElement> {
  isSelected?: boolean
  questionTitle?: string
  questionNumber?: string
  isError?: boolean
  onClickItem?: () => void
}

const ListItemSidebar: React.FC<Props> = (props: Props) => {
  const {
    questionNumber = '',
    questionTitle = '',
    isSelected,
    isError = false,
    onClickItem
  }: Props = props
  const styleProps: StyleProps = { isSelected }
  const { classes, cx: classNames } = useStyles({ styleProps })

  return (
    <div
      onClick={onClickItem}
      className={classNames(
        'fd-grid fd-container',
        classes.listItem,
        classes.draggableDiv
      )}
    >
      <div
        className={classNames(
          'fd-grid fd-container fd-item fd-xs-10 fd-align-items-center fd-row',
          classes.infoContainer
        )}
      >
        <div className="fd-grid fd-container">
          <p
            className={classNames(
              'fd-grid fd-item fd-xs-2',
              classes.questionNumber,
              {
                [classes.questionItemSelected]: !isError && isSelected,
                [classes.questionItemError]: isError
              }
            )}
          >
            {questionNumber}
          </p>
          <p
            className={classNames(
              'fd-grid fd-item fd-xs-10',
              classes.questionTitle,
              {
                [classes.questionItemSelected]: isSelected
              }
            )}
          >
            {questionTitle}
          </p>
        </div>
      </div>
      <div
        className={classNames(
          'fd-grid fd-container fd-xs-1 fd-align-items-center',
          classes.iconContainer
        )}
      >
        {isError && (
          <Icon
            className={classes.icon}
            name={IconName.InfoOutlinedIcon}
            iconColor={IconColor.Error}
            size={IconSize.Medium}
          />
        )}
      </div>
      <div
        className={classNames(
          'fd-grid fd-container fd-xs-1 fd-align-items-center',
          classes.dragHandleContainer
        )}
      >
        <Icon
          iconColor={IconColor.Disabled}
          name={IconName.DragIndicator}
          size={IconSize.Large}
          className={classes.dragHandle}
        />
      </div>
    </div>
  )
}

export default ListItemSidebar
