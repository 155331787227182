import {
  DraftCustomAudience,
  FieldworkAudience,
  FieldworkAudienceType,
  MemberSettingCode
} from '../data/model/audience'
import {
  CustomAudience,
  DraftCustomAudienceOption
} from '../data/model/irNotification'
import { getIsEnabledInSettings } from '../modules/Audience/utils'
import { proportionToPercent } from './HelperFunctions'

export const getIRNotificationCustomAudience = (
  members: FieldworkAudience[] | undefined
) => {
  return (
    members
      ?.filter((m) => m.memberType !== FieldworkAudienceType.Standard)
      .map((m) => {
        const f = m.memberItem as DraftCustomAudience
        const newMember: CustomAudience = {
          contextPosition: m.contextPosition,
          position: m.position,
          memberItem: {
            questionText: f.question.text,
            criterionOptions: f.criterionOptions.map((ro) => {
              const newRo: DraftCustomAudienceOption = {
                position: ro.position,
                responseOptionText: ro.responseOption.value,
                quota: proportionToPercent(ro.quota?.percent ?? 0).toString(),
                qualification: ro.qualification ? 'Qualified' : 'Disqualified'
              }
              return newRo
            }),
            qualifying: getIsEnabledInSettings(
              f.settingValues,
              MemberSettingCode.Qualifying
            ),
            quotas: getIsEnabledInSettings(
              f.settingValues,
              MemberSettingCode.Quotas
            )
              ? 'Enabled'
              : 'Disabled'
          }
        }
        return newMember
      }) ?? []
  )
}
