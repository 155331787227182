import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  separatorStyle: {
    color: '#B9C8CE'
  },
  buttonNotClickable: {
    'button&': {
      textDecoration: 'none',
      textTransform: 'none',
      background: 'none',
      pointerEvents: 'none',
      padding: 0,
      '&:hover': {
        textDecoration: 'none',
        textTransform: 'none',
        background: 'none',
        pointerEvents: 'none'
      }
    }
  },
  buttonClickable: {
    'button&': {
      textDecoration: 'none',
      textTransform: 'none',
      background: 'none',
      cursor: 'pointer',
      padding: 0,
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'underline',
        textTransform: 'none',
        background: 'none',
        cursor: 'pointer',
        color: theme.palette.secondary.main
      }
    }
  }
}))

export default useStyles
