import { PureQueryOptions } from '@apollo/client'
import { questionnaireGql } from '../../../gql-gen/questionnaire'
import { Fieldwork } from '../../../model/fieldwork'

export interface GetFieldworkData {
  fieldwork: Fieldwork
}

export const GET_FIELDWORK = questionnaireGql(/* GraphQL */ `
  query fieldworkV2($surveyId: ID!) {
    fieldwork: fieldworkV2(
      surveyId: $surveyId
      skipCintQuotaGroupJsonRefresh: true
    ) {
      surveyId
      locale {
        language
        country
      }
      samplesNeeded {
        unadjusted
        adjusted
      }
      lengthOfInterview {
        unadjusted
        adjusted
      }
      incidenceRate
      duration {
        startDate
        endDate
      }
      audience {
        __typename
        ... on CintAudience {
          countryId
        }
        ... on CustomAudience {
          countryId
          description
        }
        ... on ProlificAudience {
          countryId
          description
        }
      }
      activePresetAudience
      presetAudience {
        presetAudienceId
        name
      }
      createdDate
      sinceDate
      samplesNeededIncreaseFrequency
    }
  }
`)

export const fieldworkRefetchQuery: (surveyId: string) => PureQueryOptions = (
  surveyId
) => {
  return {
    query: GET_FIELDWORK,
    context: { clientName: 'questionnaire' },
    variables: { surveyId }
  }
}
