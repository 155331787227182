import { gql } from '@apollo/client'
import { localGql } from '../../../../../data/gql-gen/local'

export const SET_DRAFT_COMBINED_QUESTION_MUTATION = gql`
  mutation SetDraftCombinedQuestion(
    $draftCombinedQuestion: DraftCombinedQuestionInput!
  ) {
    setDraftCombinedQuestion(draftCombinedQuestion: $draftCombinedQuestion)
      @client
  }
`

export const SET_DRAFT_COMBINED_QUESTION_TITLE_MUTATION = gql`
  mutation SetDraftCombinedQuestionTitle($title: String!) {
    setDraftCombinedQuestionTitle(title: $title) @client
  }
`

export const GET_DRAFT_COMBINED_QUESTION = localGql(/* GraphQL */ `
  query GetDraftCombinedQuestion {
    draftCombinedQuestion @client {
      id
      title
      position
      mappedResponseOptions {
        id
        mappingNumber
        value
      }
      marketQuestions {
        id
        market
        position
        responseOptions {
          id
          mappingId
          value
        }
      }
    }
  }
`)

export const ADD_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION = gql`
  mutation AddDraftCombinedResponseOption {
    addDraftCombinedResponseOption @client
  }
`

export const DELETE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION = gql`
  mutation DeleteDraftCombinedResponseOption($id: String!) {
    deleteDraftCombinedResponseOption(id: $id) @client
  }
`

export const BULK_ADD_DRAFT_COMBINED_RESPONSE_OPTIONS_MUTATION = gql`
  mutation BulkAddDraftCombinedResponseOptions(
    $values: [String]!
    $insertAtIndex: Int!
  ) {
    bulkAddDraftCombinedResponseOptions(
      values: $values
      insertAtIndex: $insertAtIndex
    ) @client {
      id
      mappingNumber
      value
    }
  }
`

export const UPDATE_DRAFT_COMBINED_RESPONSE_OPTION_MUTATION = gql`
  mutation UpdateDraftCombinedResponseOption($id: String!, $value: String!) {
    updateDraftCombinedResponseOption(id: $id, value: $value) @client
  }
`

export const DELETE_MAPPING_MARKET_QUESTION_MUTATION = gql`
  mutation DeleteMappingMarketQuestion($id: String!) {
    deleteMappingMarketQuestion(id: $id) @client
  }
`

export const MAP_RESPONSE_OPTION_MUTATION = gql`
  mutation MapResponseOption(
    $id: String!
    $marketQuestionId: String!
    $mappingId: String!
  ) {
    mapResponseOption(
      id: $id
      marketQuestionId: $marketQuestionId
      mappingId: $mappingId
    ) @client
  }
`
