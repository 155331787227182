export const USER_MAX_LENGTH_OF_INTERVIEW = 30

export const defaultFdChatSpecification = {
  maxLengthOfInterview: 60,
  goal: '',
  questions: [''],
  themes: '',
  context: '',
  maxNumberQuestions: 9999
}

export interface FdChatSpecification {
  fdChatSpecificationId: string
  maxLengthOfInterview: number
  goal: string
  questions: string[]
  themes?: string
  context?: string
}

export type FdChatPagePath = 'audience' | 'objectives' | 'review' | 'results'

export type ChartDataType = {
  title: string
  xLabel: string
  yLabel: string
  stacked?: boolean
  labels: string[]
  datasets: {
    label: string
    data: number[]
  }[]
}

export type TranscriptType = {
  respondent_id: string
  date: string
  respondent_info: string
  messages: {
    id: string
    name: string
    time: string
    message: string
  }[]
}

export type ThemeType = {
  name: string
  description: string
  verbatims: {
    respondent_id: string
    respondent_info: string
    quote: string
    message_id: string
  }[]
}

type AskAiType = {
  datetime_created: string
  assistant_id: string
  number_of_completes: number
}

type ResearchGoalType = {
  headline: string
  description: string
  chart_data: ChartDataType
}

export type ResearchQuestionType = {
  id: string
  text: string
  headline: string
  short_description: string
  long_description: string
  chart_data: ChartDataType
  themes?: ThemeType[]
}

export type ResultsType = {
  ask_ai: AskAiType
  research_goal?: ResearchGoalType
  research_questions?: ResearchQuestionType[]
  transcripts?: TranscriptType[]
}

export type TranscriptsFileFormatType = 'txt' | 'csv'
