import {
  Spinner,
  SpinnerColor,
  SpinnerSize,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { Dialog, Grid } from '@mui/material'
import React from 'react'
import useStyles from './SpinnerDialog.styles'

interface Props {
  isVisible: boolean
  text: string
  subtext?: string | JSX.Element
}

const SpinnerDialog: React.FC<Props> = (props: Props) => {
  const { isVisible, text, subtext }: Props = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Dialog
      open={isVisible}
      fullWidth
      classes={{
        container: classes.containerBackground
      }}
    >
      <Grid
        className={classes.container}
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Spinner
          className={classes.spinner}
          color={SpinnerColor.Primary}
          size={SpinnerSize.m}
        />
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            textClasses.sizeL,
            classes.text
          )}
        >
          {text}
        </p>
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold,
            textClasses.sizeM,
            textClasses.highlightBackground
          )}
        >
          {subtext}
        </p>
      </Grid>
    </Dialog>
  )
}

export default SpinnerDialog
