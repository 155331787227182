import { Grid, KebabMenu, Logo, theme } from '@focaldata/cin-ui-components'
import { Avatar, IconButton } from '@mui/material'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useGetUserAndAccount from '../../../../hooks/useGetUserAndAccount'
import UserSession from '../../../../utils/UserSession'
import { AccessToken } from '../../../../utils/authUtils'
import useStyles from './SideMenu.styles'

interface Props {
  isPublicPage?: boolean
  onClickLogout?: () => void
  userName?: string
}

export const SideMenuComponent: FC<Props> = ({
  isPublicPage,
  onClickLogout,
  userName
}: Props) => {
  const { classes } = useStyles()

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid
        item
        display="flex"
        justifyContent="center"
        height={56}
        alignItems={'center'}
      >
        <Link to="/projects">
          <Logo width={24} type="icon" colour="white" />
        </Link>
      </Grid>
      {!isPublicPage && (
        <Grid
          item
          display="flex"
          justifyContent="center"
          className={classes.userAvatarContainer}
        >
          <KebabMenu
            kebabMenuOptions={[
              {
                id: 0,
                textItem: 'Logout',
                onClickItem: onClickLogout
              }
            ]}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            menuButton={
              <IconButton
                size="small"
                sx={{ ml: 2, marginLeft: 0 }}
                aria-haspopup="true"
              >
                <Avatar
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: '28px',
                    width: '28px',
                    fontSize: '12px',
                    lineHeight: '14px'
                  }}
                >
                  {userName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1).toUpperCase())}
                </Avatar>
              </IconButton>
            }
          />
        </Grid>
      )}
    </Grid>
  )
}

const SideMenu: FC = () => {
  const { userName } = useGetUserAndAccount()
  const navigate = useNavigate()

  const handleLogoutClick: () => void = () => {
    navigate('/login')
    AccessToken.clear()
    UserSession.removeEmail()
    UserSession.removeAccountId()
    UserSession.removeAccountName()
  }

  return (
    <SideMenuComponent onClickLogout={handleLogoutClick} userName={userName} />
  )
}

export default SideMenu
