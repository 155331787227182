import {
  Button,
  ButtonVariant,
  Grid,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { EventType } from '../../../../amplitude/eventType'
import { LogPublicAmplitudeEvent } from '../../../../amplitude/public'
import { SurveyParams } from '../../../../data/model/surveyParams'
import useSubheaderSizing from '../../../../hooks/useSubheaderSizing'
import useStyles from './PublicResultsFieldworkDetails.styles'

interface Props {
  fieldworkPeriod?: string
  countryCode?: string
  isMultiMarket?: boolean
}

const contactFormPage = '/contact-form'
const websiteUrl = 'https://focaldata.com'

const PublicResultsFieldworkDetails: React.FC<Props> = ({
  fieldworkPeriod,
  countryCode,
  isMultiMarket
}: Props) => {
  const { subheaderRightSideMaxWidth, isSubheaderCondensed } =
    useSubheaderSizing({ isPublic: true })
  const { classes } = useStyles({
    styleProps: { width: subheaderRightSideMaxWidth }
  })
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const handleContactSalesClick = (): void => {
    LogPublicAmplitudeEvent(EventType.ClickedContactSalesFromPublicResults, {
      surveyId
    })
  }
  const handleRegister = (): void => {
    LogPublicAmplitudeEvent(EventType.ClickedRegisterFromPublicResults, {
      surveyId
    })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.container}
    >
      {!isMultiMarket && (
        <>
          <Grid item xs="auto">
            <Grid container item direction="row">
              {!isSubheaderCondensed && (
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeMs,
                    textClasses.marginRight,
                    textClasses.displayInline
                  )}
                >
                  Country:{' '}
                </p>
              )}
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.displayInline,
                  textClasses.sizeMs,
                  textClasses.weightSemiBold
                )}
              >
                {countryCode}
              </p>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.dividerFielderwork}
            />
          </Grid>
        </>
      )}
      <Grid item xs="auto">
        <Grid container item direction="row">
          {!isSubheaderCondensed && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight,
                textClasses.alignCenter
              )}
            >
              Fieldwork:{' '}
            </p>
          )}
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.alignCenter,
              textClasses.weightSemiBold
            )}
          >
            {fieldworkPeriod}
          </p>
        </Grid>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
          flexItem
          className={classes.dividerFielderwork}
        />
      </Grid>
      <Grid item>
        <Button
          variant={ButtonVariant.PrimaryNoOutline}
          onClick={handleContactSalesClick}
          className={classes.leftButton}
        >
          <a
            style={{ textDecoration: 'none', color: 'inherit' }}
            href={`${websiteUrl}${contactFormPage}`}
            rel="noreferrer"
            target="_blank"
          >
            Contact
          </a>
        </Button>
        <Button
          variant={ButtonVariant.PrimaryNoOutline}
          onClick={handleRegister}
          className={classes.rightButton}
        >
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to="/signup"
            rel="noreferrer"
            target="_blank"
          >
            Register
          </Link>
        </Button>
      </Grid>
    </Grid>
  )
}

export default PublicResultsFieldworkDetails
