import { useLayoutEffect, useState } from 'react'

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeigth, setWindowHeigth] = useState(0)

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeigth(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { windowWidth, windowHeigth }
}

export default useWindowSize
