import {
  Grid,
  LinkButton,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import InsightsImg from '../../../../../../assets/fdchat/results/insights.svg'
import { ThemeType } from '../../../FdChat.model'
import InfoCard from '../InfoCard'

const VerbatimsCard = ({
  name,
  description,
  verbatims,
  handleOpenTranscript
}: ThemeType & {
  handleOpenTranscript: ({
    respondentId,
    quoteToHighlight
  }: {
    respondentId: string
    quoteToHighlight: string
  }) => void
}) => {
  return (
    <InfoCard>
      <Grid container alignItems={'center'} gap={'14px'} marginBottom={'14px'}>
        <img src={InsightsImg} width={'32px'} height={'32px'} alt="" />
        <Text size={TextSize.ms}>{name.toUpperCase()}</Text>
      </Grid>
      <Text size={TextSize.lxl} weight={TextWeight.Bold}>
        {description}
      </Text>
      <Divider style={{ marginTop: '18px' }} />
      <div
        style={{
          height: '400px',
          overflowY: 'auto'
        }}
      >
        {verbatims.map((verbatim, index) => (
          <>
            <Grid
              container
              direction={'column'}
              paddingY={'16px'}
              gap={'16px'}
              key={index}
            >
              <Text size={TextSize.l}>&quot;{verbatim.quote}&quot;</Text>
              <Text size={TextSize.ms}>{verbatim.respondent_info}</Text>
              <div>
                <LinkButton
                  noPadding
                  onClick={() =>
                    handleOpenTranscript({
                      respondentId: verbatim.respondent_id,
                      quoteToHighlight: verbatim.quote
                    })
                  }
                  highlight={TextHighlight.Primary}
                >
                  View transcript
                </LinkButton>
              </div>
            </Grid>
            <Divider />
          </>
        ))}
      </div>
    </InfoCard>
  )
}

export default VerbatimsCard
