import { Logo } from '@focaldata/cin-ui-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../amplitude'
import { IconArrowRight } from '../../components/Icons/IconArrowRight'
import {
  FocaldataPreferredApp,
  LoginLocationState,
  projectSetupHost,
  targetDefaults
} from '../../utils/authUtils'
import styles from './ChooseApp.module.css'

export const ChooseApp = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { firstLogin } = (location.state as LoginLocationState) || {
    firstLogin: false
  }

  const goToFocaldataAi = () => {
    LogAmplitudeEvent('Clicked on Go to Focaldata AI button')
    FocaldataPreferredApp.set('coworker')
    // going to the default coworker target rather than the one specified in the URL as it doesn't
    // necessarily match up to the routes in the coworker app
    const target = targetDefaults.coworker
    window.location.href = projectSetupHost + '/' + target.replace(/^\/+/g, '')
    return
  }

  const goToFocaldataCore = () => {
    LogAmplitudeEvent('Clicked on Go to Focaldata Core button')
    FocaldataPreferredApp.set('dashboard')
    const target = searchParams.get('target') ?? targetDefaults.dashboard
    navigate(target, { state: { firstLogin } })
    return
  }

  return (
    <div className={styles['choose-app']}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo type={'full'} width={173} />
          <h1 className={styles.title}>
            Welcome back. What would you like to do today?
          </h1>
        </div>
        <div>
          <div className={styles.options}>
            <div className={styles.option}>
              <div className={styles['ai-logo-bg']}>
                <Logo type={'icon'} width={16} colour="white" />
              </div>
              <h2 className={styles['option-title']}>Focaldata AI</h2>
              <p className={styles['option-description']}>
                Get expert qual research done with an end-to-end AI co-worker.
              </p>
              <button className={styles['ai-button']} onClick={goToFocaldataAi}>
                Go to Focaldata AI <IconArrowRight />
              </button>
            </div>
            <div className={styles.option}>
              <div className={styles['core-logo-bg']}>
                <Logo type={'icon'} width={16} colour="white" />
              </div>
              <h2 className={styles['option-title']}>Focaldata Core</h2>
              <p className={styles['option-description']}>
                Get robust quant survey data at exceptional quality and speed.
              </p>
              <button
                className={styles['core-button']}
                onClick={goToFocaldataCore}
              >
                Go to Focaldata Core <IconArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
