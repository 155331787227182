import { makeStyles, theme } from '@focaldata/cin-ui-components'

const { palette } = theme

const useStyles = makeStyles()({
  sectionLayoutContainer: {
    borderOffset: theme.spacing(5),
    borderWidth: theme.spacing(0.25),
    borderColor: palette.divider,
    borderStyle: 'dashed',
    padding: theme.spacing(4, 4, 0, 4),
    width: 'auto',
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`,
    marginBottom: theme.spacing(4)
  },
  sectionLayoutContent: {
    marginTop: theme.spacing(2)
  }
})

export default useStyles
