/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Icon,
  IconColor,
  IconName,
  IconSize
} from '@focaldata/cin-ui-components'
import React, { PropsWithChildren } from 'react'
import useStyles, { StyleProps } from './ListItemSectionSidebar.styles'

export interface Props {
  isSelected?: boolean
  questionTitle?: string
  isError?: boolean
  onClickItem?: () => void
}

const ListItemSectionSidebar: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    questionTitle = '',
    isSelected,
    isError = false,
    onClickItem
  } = props
  const styleProps: StyleProps = { isSelected }
  const { classes, cx: classNames } = useStyles({ styleProps })

  return (
    <div
      className={classNames(
        'fd-grid fd-container',
        classes.sectionContainer,
        classes.listItemSection,
        {
          [classes.sectionItemSelected]: isSelected
        }
      )}
    >
      <div
        onClick={onClickItem}
        className={classNames(
          'fd-grid fd-container fd-item fd-xs-12 fd-align-items-right',
          classes.draggableDiv
        )}
      >
        <div
          className={classNames(
            'fd-grid fd-container fd-item fd-xs-12',
            classes.sectionHeaderStyle
          )}
        >
          <div
            className={classNames(
              'fd-grid fd-container fd-item fd-xs-10 fd-align-items-center fd-row',
              classes.infoContainer,
              classes.sectionHeader
            )}
          >
            <p
              className={classNames(classes.sectionTitle, {
                [classes.sectionItemSelected]: isSelected
              })}
            >
              {questionTitle}
            </p>
          </div>
          <div className="fd-grid fd-container fd-xs-1 fd-align-items-center">
            {isError && (
              <Icon
                className={classes.icon}
                name={IconName.InfoOutlinedIcon}
                iconColor={IconColor.Error}
                size={IconSize.Medium}
              />
            )}
          </div>
          <div className="fd-grid fd-container fd-xs-1 fd-align-items-center">
            <Icon
              iconColor={IconColor.Disabled}
              name={IconName.DragIndicator}
              size={IconSize.Large}
              className={classes.dragHandle}
            />
          </div>
        </div>
      </div>
      {children}
      <div
        className={classNames(
          'fd-grid fd-container',
          classes.sectionTitle,
          classes.sectionFooter
        )}
      >
        <div
          className={classNames(
            'fd-grid fd-container fd-align-items-center fd-row',
            classes.sectionFooterStyle
          )}
        >
          <p
            className={classNames(classes.sectionTitle, {
              [classes.sectionItemSelected]: isSelected
            })}
          >
            END OF {questionTitle}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ListItemSectionSidebar
