import { BorderStyle } from 'docx'

export const paragraphTopBorderOptions = {
  border: {
    top: {
      color: '#a0a0a0',
      space: 5,
      style: BorderStyle.SINGLE,
      size: 15
    }
  },
  spacing: {
    before: 200
  }
}
