import { InMemoryCache, Resolver } from '@apollo/client'
import { produce } from 'immer'
import { GetDraftCombinedQuestionQuery } from '../../../../../../data/gql-gen/local/graphql'
import { GET_DRAFT_COMBINED_QUESTION } from '../DraftCombinedQuestion.localState'

export const deleteDraftCombinedResponseOption: Resolver = (
  _,
  { id }: { id: string },
  { cache }: { cache: InMemoryCache }
) => {
  cache.updateQuery(
    {
      query: GET_DRAFT_COMBINED_QUESTION
    },
    (
      data: GetDraftCombinedQuestionQuery | null
    ): GetDraftCombinedQuestionQuery | void => {
      if (!data) {
        throw new Error('No draft combined question found in cache')
      }
      return produce(data, ({ draftCombinedQuestion }) => {
        const { mappedResponseOptions, marketQuestions } = draftCombinedQuestion
        draftCombinedQuestion.mappedResponseOptions = mappedResponseOptions
          .filter((responseOption) => responseOption.id !== id)
          .map((responseOption, index) => ({
            ...responseOption,
            mappingNumber: index + 1
          }))

        // clean dead links from market questions to deleted combined response option
        marketQuestions.forEach((marketQuestion) => {
          marketQuestion.responseOptions.forEach((responseOption) => {
            if (responseOption.mappingId === id) {
              responseOption.mappingId = null
            }
          })
        })
      })
    }
  )

  return id
}
