import { useLocation } from 'react-router-dom'
import { useAskAiDrawer } from '../../../../../../../modules/FdChat/AskAi/AskAi.hooks'
import AskAiDrawerMenuBar from '../AskAiDrawerMenuBar/AskAiDrawerMenuBar'
import { LocationState } from '../Search'
import SourcesViewer from '../SourcesViewer/SourcesViewer'
import TranscriptViewer from '../TranscriptViewer/TranscriptViewer'
import styles from './AskAiDrawer.module.css'

const AskAiDrawer = () => {
  const location = useLocation()
  const locationState = location.state as LocationState
  const { viewer } = useAskAiDrawer()

  return (
    <>
      <AskAiDrawerMenuBar
        sources={locationState?.answer?.answer.source_references}
      />
      <div className={styles.content}>
        {viewer === 'sources' && (
          <SourcesViewer
            sources={locationState?.answer?.answer.source_references}
          />
        )}
        {viewer === 'transcript' && (
          <TranscriptViewer
            sources={locationState?.answer?.answer.source_references}
          />
        )}
      </div>
    </>
  )
}

export default AskAiDrawer
