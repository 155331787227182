import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  container: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(1.5, 0)
  },
  edit: {
    marginRight: `-${theme.spacing(1.5)}`
  },
  error: {
    borderColor: theme.palette.error.main
  }
})

export default useStyles
