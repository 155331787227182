import { useEffect, useState } from 'react'

import { getFeasibilityEstimateRefetchQuery } from '../../../data/gql/questionnaire/queries/getFeasibilityEstimate'
import {
  DraftStandardAudience,
  FieldworkAudience,
  MemberSettingCode
} from '../../../data/model/audience'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import useGetDraftAudience from '../../../hooks/audience/useGetDraftAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { DEFAULT_LOI } from '../../Survey/SurveyStats/SurveyStats.utils'
import { getIsEnabledInSettings } from '../utils'

export const useSurveyLengthData = (): {
  totalCompletes: number
  loi: number
} => {
  const [totalCompletes, setTotalCompletes] = useState(0)
  const [loi, setLoi] = useState(DEFAULT_LOI)

  const { loading, error, fieldwork } = useFieldwork()

  useEffect(() => {
    if (!loading && !error && fieldwork) {
      if (fieldwork.samplesNeeded.unadjusted !== totalCompletes) {
        setTotalCompletes(fieldwork.samplesNeeded.unadjusted || 0)
      }
      if (fieldwork.lengthOfInterview.adjusted !== loi) {
        setLoi(fieldwork.lengthOfInterview.adjusted || DEFAULT_LOI)
      }
    }
  }, [totalCompletes, loi, loading, error, fieldwork])

  return { totalCompletes, loi }
}

export const useSumQuotas = (audience: FieldworkAudience, loi: number) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  // const updateCustomizedCriterionOption = useUpdateCustomizedCriterionOption()
  const memberItem: DraftStandardAudience =
    audience.memberItem as DraftStandardAudience
  // const { batchUpdateQuota } = useAudienceQuestionCardMutations(loi)
  const { updateAudienceMemberV2 } = useUpdateAudienceMemberV2()
  const { refetchAudience } = useGetDraftAudience()
  const customizedCriterionOptionsEnabled: boolean = getIsEnabledInSettings(
    memberItem.settingValues,
    MemberSettingCode.CustomizedCriterionOptions
  )
  const criterionOptions = customizedCriterionOptionsEnabled
    ? memberItem.customizedCriterionOptions ?? []
    : memberItem.standardCriterionOptions
  const quotaSum = criterionOptions.reduce<number>(
    (sum, { qualification, quota }) =>
      sum + (qualification && quota ? quota.percent : 0),
    0
  )

  const toDistribute = 1 - quotaSum
  // get all options that are qualifying
  const qualifyingOptions = criterionOptions.filter((co) => co.qualification)
  const valueToAdd = toDistribute / qualifyingOptions.length

  const customQuotaInput = qualifyingOptions.map((qualifyingOption) => ({
    responseOptionId:
      qualifyingOption.criterionOption.responseOption.responseOptionId,
    quota:
      toDistribute < 0
        ? (qualifyingOption.quota?.percent ?? 0) / quotaSum
        : (qualifyingOption.quota?.percent ?? 0) + valueToAdd
  }))

  return () =>
    updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId: memberItem.criterion.question.questionId,
          updateStandardMember: {
            standardQuotas: customQuotaInput
          }
        }
      }
    }).then(async () => {
      await refetchAudience()
      getFeasibilityEstimateRefetchQuery(projectId, surveyId, loi)
    })
}
