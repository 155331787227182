import { Grid } from '@focaldata/cin-ui-components'
import React, { CSSProperties } from 'react'
import LoadingbarHorizontal from '../../controls/LoadingBarHorizontal'
import { CONTAINER_ID_QUESTIONNAIRE_CONTENT } from '../../modules/Questionnaire/constants'
import { useStyles } from './styles'

interface Props {
  sideBar?: JSX.Element
  content?: JSX.Element
  maxWidth?: number
  hasContentPadding?: boolean
  isLoading?: boolean
  containerIdForScoll: string
  sideBarHeader?: JSX.Element
  height?: CSSProperties['height']
  sidebarHeight?: CSSProperties['height']
}

const SidebarLayout: React.FC<Props> = (props: Props) => {
  const {
    content,
    sideBar,
    maxWidth = 336,
    hasContentPadding = true,
    isLoading = false,
    containerIdForScoll = CONTAINER_ID_QUESTIONNAIRE_CONTENT,
    sideBarHeader,
    height,
    sidebarHeight
  }: Props = props

  const { classes, cx: classNames } = useStyles({
    styleProps: { maxWidth, height, sidebarHeight }
  })

  return (
    <>
      <LoadingbarHorizontal isLoading={isLoading} />
      <Grid container className={classes.root}>
        <Grid container direction="column" className={classes.sidebarWrapper}>
          {sideBarHeader}
          <div
            className={classNames('fd-grid fd-container fd-column', {
              [classes.sidebar]: !sideBarHeader,
              [classes.sidebarWithHeader]: !!sideBarHeader
            })}
          >
            {sideBar}
          </div>
        </Grid>
        <Grid
          id={containerIdForScoll}
          item
          xs="auto"
          className={classNames(
            {
              [classes.content]: !sideBarHeader,
              [classes.contentWithHeader]: !!sideBarHeader
            },
            {
              [classes.contentNoPadding]: !hasContentPadding
            }
          )}
        >
          {content}
        </Grid>
      </Grid>
    </>
  )
}

export default SidebarLayout
