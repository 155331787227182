import { useQuery } from '@apollo/client'
import { GET_AUDIENCE_CATEGORIES } from '../../data/gql/questionnaire/queries/categories'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'

const useStandardAudienceListGqlOperations = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { data: audienceCategories } = useQuery(GET_AUDIENCE_CATEGORIES, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const { draftAudience: audienceQuestions } = useGetDraftAudience({
    notifyOnNetworkStatusChange: true
  })

  return {
    audienceCategories: audienceCategories?.listCategoriesV2,
    audienceQuestions
  }
}

export default useStandardAudienceListGqlOperations
