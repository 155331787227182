import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import CompleteInfoHeader from '../../../../controls/CompleteInfoHeader'
import CompleteInfoHeaderLoader from '../../../../controls/CompleteInfoHeader/loading'
import CostDropdownPanel from '../../../../controls/CostDropdownPanel'
import {
  ProjectBySurveyIdQueryVariables,
  SurveyQueryVariables,
  SurveyState
} from '../../../../data/gql-gen/dashboard/graphql'
import {
  GET_SURVEY_ORDER,
  SuveryOrderData
} from '../../../../data/gql/order/queries/surveyOrder'
import { SurveyParams } from '../../../../data/model/surveyParams'
import { useFieldwork } from '../../../../hooks/useFieldwork'
import useGetSurveyState from '../../../../hooks/useGetSurveyState'
import useGetSurveysProgress from '../../../../hooks/useGetSurveysProgress'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData,
  SURVEY,
  SurveyData
} from '../../../../modules/Project/Project.query'
import { formatThousandsWithCommas } from '../../../../utils/HelperFunctions'
import dayjs from '../../../../utils/dayjs'
import { isMultiMarketProjectType } from '../../../../utils/projectUtils'

const CompleteHeader: React.FC = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!

  const { data: surveyOrderData, loading: loadingSurveyOrder } =
    useQuery<SuveryOrderData>(GET_SURVEY_ORDER, {
      context: { clientName: 'dashboard' },
      variables: {
        surveyId
      }
    })

  // What the user agreed on the confirmation page
  const surveyOrderCpi = surveyOrderData?.surveyOrder.customerCPI

  // How many respondent answered the question survey. This might not be the same number as the completes chosen by the user
  const { surveyProgress } = useGetSurveysProgress([surveyId])

  const samplesCollected = surveyProgress?.find(
    (progress) => progress.surveyId === surveyId
  )?.progress?.samplesCollected

  const { fieldwork, loading: loadingFieldwork } = useFieldwork()

  const countryCode = fieldwork?.locale.country

  const { data: surveyData, loading: loadingSurveyData } = useQuery<
    SurveyData,
    SurveyQueryVariables
  >(SURVEY, {
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    },
    skip: !surveyId
  })

  const { data: projectData } = useQuery<
    ProjectData,
    ProjectBySurveyIdQueryVariables
  >(PROJECT_BY_SURVEY_ID, {
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    },
    fetchPolicy: 'cache-and-network'
  })

  const fieldworkStartDate = surveyData?.survey.fieldworkDates.startDate
  const fieldworkEndDate = surveyData?.survey.fieldworkDates.endDate

  const formattedFieldworkStartDate = fieldworkStartDate
    ? dayjs(fieldworkStartDate).format('D MMM YYYY')
    : 'N/A'

  const formattedFieldworkEndDate = fieldworkEndDate
    ? dayjs(fieldworkEndDate).format('D MMM YYYY')
    : 'N/A'

  const liveCPI = surveyOrderData?.surveyOrder.customerCPI || 0

  const calculateCost: (customerCPI: number, completes: number) => number = (
    customerCPI,
    completes
  ) => {
    const roundedCustomerCPI = parseFloat(customerCPI.toFixed(2))
    const cost = roundedCustomerCPI * completes
    return Math.round(cost)
  }

  const completes: number = fieldwork?.samplesNeeded.adjusted || 0

  const { surveyState } = useGetSurveyState()
  const isDraft = surveyState === SurveyState.Draft
  const isCompletePage =
    surveyState === SurveyState.Completed || surveyState === SurveyState.Halted
  // Completes we show in the cost DropDown - The completes we charge the user for
  const totalCostLive = formatThousandsWithCommas(
    calculateCost(liveCPI, isCompletePage ? samplesCollected || 0 : completes)
  )

  const fieldworkPeriod = `${formattedFieldworkStartDate} - ${formattedFieldworkEndDate}`
  const cpi = `£${surveyOrderCpi?.toFixed(2) || '...'}`
  const totalCostDisplayed = `£${totalCostLive || '...'}`

  const completeLOI = fieldwork?.lengthOfInterview.unadjusted || 5 // 5 minutes is the min default length of interview used for the intial estimation

  const isLoading = loadingSurveyOrder || loadingFieldwork || loadingSurveyData

  const isMultiMarket =
    projectData && isMultiMarketProjectType(projectData.project.projectType)

  const incidenceRateString = fieldwork?.incidenceRate.toString()

  if (isLoading) {
    return <CompleteInfoHeaderLoader />
  }

  return (
    <CompleteInfoHeader
      fieldworkPeriod={fieldworkPeriod}
      countryCode={countryCode}
      costMenuDropdown={
        <CostDropdownPanel
          // If we are the complete page, it means the cost are NEVER a preview, thus I set it to false
          isCostPreview={false}
          // If we are the complete page, it means the user cannot play with the increments on the cost menu
          displayIncrement={false}
          isDraft={isDraft}
          priceTitle={totalCostDisplayed}
          isCompletePage={isCompletePage}
          incidenceRate={incidenceRateString}
          cpi={cpi}
          displayedLOI={completeLOI}
          completes={completes}
          samplesCollected={samplesCollected}
        />
      }
      isMultiMarket={isMultiMarket}
    />
  )
}

export default CompleteHeader
