import { makeStyles, theme } from '@focaldata/cin-ui-components'

const { palette, typography } = theme

const menuItemTextRegular = {
  fontFamily: typography.fontFamily,
  fontSize: typography.body1.fontSize,
  fontWeight: typography.fontWeightRegular as any,
  color: palette.text.secondary,
  textAlign: typography.body1.textAlign as any,
  margin: 0,
  lineHeight: 1.5
}

const useStyles = makeStyles()(() => ({
  endOfSurveyOption: {
    paddingRight: theme.spacing(1.5)
  },
  menu: {
    maxHeight: 200,
    padding: theme.spacing(1, 1, 1, 3),
    border: `1px solid ${palette.divider}`,
    overflow: 'auto'
  },
  endOfSurveyContainer: {
    padding: theme.spacing(1, 1, 1, 3),
    borderLeft: `1px solid ${palette.divider}`,
    borderBottom: `1px solid ${palette.divider}`,
    borderRight: `1px solid ${palette.divider}`
  },
  menuItem: {
    cursor: 'pointer',
    minWidth: 300,
    overflow: 'hidden'
  },
  menuItemText: {
    maxWidth: '438px'
  },
  menuItemTextRegular,
  menuItemNumber: {
    paddingRight: theme.spacing(3.5),
    width: theme.spacing(2.5)
  },
  menuItemTextSemiBold: {
    ...menuItemTextRegular,
    fontWeight: 'bolder'
  },
  dialogHeader: {
    ...menuItemTextRegular,
    marginBottom: theme.spacing(2.5)
  }
}))

export default useStyles
