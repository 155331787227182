import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const LOOPING_DATA = questionnaireGql(/* GraphQL */ `
  query loopingData($projectId: ID!, $surveyId: ID) {
    draftQuestionnaire: draftQuestionnaireV2(
      projectId: $projectId
      surveyId: $surveyId
    ) {
      entries {
        number
        contextPosition
        entryType
        entryItem {
          ... on DraftSectionItem {
            sectionId
            entries {
              number
              contextPosition
              entryType
            }
            settingValues {
              code
              value
            }
            isLoopingEnabled @client
            loopingConfig {
              sourceEntryNumber
            }
          }
        }
      }
    }
  }
`)
