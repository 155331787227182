import { PureQueryOptions } from '@apollo/client'
import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const FD_CHAT_SPECIFICATION_V2 = questionnaireGql(/* GraphQL */ `
  query draftQuestionnaireFDChatV2($projectId: ID!, $surveyId: ID!) {
    draftQuestionnaireV2(projectId: $projectId, surveyId: $surveyId) {
      __typename
      questionnaireId
      fdChatSpecification {
        ... on AdHocTestingSpecification {
          fdChatSpecificationId
          maxNumberQuestions
          goal
          questions
          themes
          context
          maxLengthOfInterview
        }
      }
    }
  }
`)

export const draftFDChatSpecificationRefetchQuery: (
  projectId: string,
  surveyId: string
) => PureQueryOptions = (projectId, surveyId) => {
  return {
    query: FD_CHAT_SPECIFICATION_V2,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId }
  }
}
