import {
  ButtonType,
  Icon,
  IconColor,
  IconName,
  SpinnerButton,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import styles from '../Auth.module.css'
import useStyles from '../Auth.styles'
import PasswordChecklist from '../CreatePassword/PasswordChecklist'
import {
  isPasswordStrong,
  minimumLengthCheck,
  usingCapitalsCheck,
  usingLowercaseCheck,
  usingNumbersCheck
} from '../CreatePassword/passwordUtils'
import useSignupForm, { CustomError } from './useSignupForm'

interface Props {
  predefinedOrganisation?: string
  predefinedEmail?: string
  showPasswordInput?: boolean
  disableEmailInput?: boolean
  disableOrganisationInput?: boolean
  onSignup: (
    firstName: string,
    lastName: string,
    email: string,
    organisation: string,
    password?: string
  ) => Promise<boolean | Error>
  onSignupError: (error: CustomError | undefined) => void
}

const SignupForm: React.FC<Props> = (props: Props) => {
  const {
    predefinedOrganisation,
    predefinedEmail,
    showPasswordInput = false,
    disableEmailInput = false,
    disableOrganisationInput = false,
    onSignup,
    onSignupError
  } = props
  const [loading, onLoading] = useState<boolean>(false)
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const {
    firstName,
    lastName,
    email,
    organisation,
    error,
    emailErrorMessage,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handleOrganisationChange,
    setError,
    setEmail,
    setOrganisation,
    validateEmail,
    getEmailInputErrorMessage,
    isFormValid
  } = useSignupForm({
    email: predefinedEmail,
    organisation: predefinedOrganisation
  })

  useEffect(() => {
    setEmail(predefinedEmail || '')
    if (predefinedEmail) validateEmail(predefinedEmail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedEmail])

  useEffect(() => {
    setOrganisation(predefinedOrganisation || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedOrganisation])

  const [password, setPassword] = useState<string>('')

  const handleSignup: (event: React.SyntheticEvent) => void = async (event) => {
    event.preventDefault()
    if (validateEmail(email)) {
      onLoading(true)

      const signupResponse = await onSignup(
        firstName,
        lastName,
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        email?.toLowerCase(),
        organisation,
        password
      )

      if (signupResponse) {
        onLoading(false)
      }
      if (signupResponse instanceof Error) {
        setError(signupResponse)
        onSignupError(signupResponse)
      }
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const isEmailAddressError =
    error?.response?.status === 409 || !!emailErrorMessage

  const emailInputErrorMessage = getEmailInputErrorMessage(error)

  const isDisabled =
    !isFormValid() || (!isPasswordStrong(password) && showPasswordInput)

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={classes.gridItem}
      >
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.firstName}
        >
          <p className={styles['input-label']}>First name</p>
          <input
            value={firstName}
            onChange={handleFirstNameChange}
            className={styles.input}
            placeholder="Enter your first name"
          />
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.lastName}
        >
          <p className={styles['input-label']}>Last name</p>
          <input
            value={lastName}
            onChange={handleLastNameChange}
            className={styles.input}
            placeholder="Enter your last name"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="flex-start"
        className={classes.gridItem}
      >
        <p className={styles['input-label']}>Work email</p>
        <input
          disabled={disableEmailInput}
          value={email}
          onChange={handleEmailChange}
          className={styles.input}
          placeholder="Enter your work email"
        />
        {isEmailAddressError && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.gridItem}
          >
            <Icon
              name={IconName.InfoOutlinedIcon}
              iconColor={IconColor.Error}
            />
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeS,
                textClasses.highlightError
              )}
            >
              {emailInputErrorMessage}
            </p>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container className={classes.gridItem}>
        <p className={styles['input-label']}>Organisation</p>
        <input
          disabled={disableOrganisationInput}
          value={organisation}
          onChange={handleOrganisationChange}
          className={styles.input}
          placeholder="Enter your organisation name"
        />
      </Grid>
      {showPasswordInput && (
        <>
          <Grid item container className={classes.gridItem}>
            <p className={styles['input-label']}>Password</p>
            <input
              type="password"
              className={styles.input}
              value={password}
              onChange={handlePasswordChange}
            />
          </Grid>
          <PasswordChecklist
            isLengthValid={minimumLengthCheck(password)}
            isUsingNumbers={usingNumbersCheck(password)}
            isUsingCapitals={
              usingCapitalsCheck(password) && usingLowercaseCheck(password)
            }
          />
        </>
      )}
      <Grid item xs={12} container className={classes.gridItem}>
        <SpinnerButton
          onClick={handleSignup}
          className={classes.button}
          loading={loading}
          fullWidth
          buttonType={ButtonType.Submit}
          disabled={!!isDisabled}
        >
          Sign up
        </SpinnerButton>
      </Grid>
    </>
  )
}

export default SignupForm
