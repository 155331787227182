import {
  AskAIAnswerResponseInput,
  FeedbackCategory
} from '@focaldata/askai-client'
import CircularProgress from '@mui/material/CircularProgress'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useProvideAnswerFeedback } from '../../../../../../../modules/FdChat/AnswersFeedback/AnswersFeedback.hooks'
import styles from './FeedbackForm.module.css'

type Inputs = {
  categories: FeedbackCategory[]
  feedbackMessage: string
}

const FeedbackForm = ({
  onSubmitted,
  onCancelClick,
  answer
}: {
  onSubmitted: () => void
  onCancelClick: () => void
  answer: AskAIAnswerResponseInput
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm<Inputs>()
  const { provideFeedback, loading } = useProvideAnswerFeedback()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await provideFeedback({
        answer,
        categories: data.categories,
        feedbackMessage: data.feedbackMessage
      })
      reset()
      onSubmitted()
    } catch {
      // swallow error as giveFeedback handles it
    }
  }

  const handleCancelClick = () => {
    reset()
    onCancelClick()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className={styles.title}>Help us improve</h1>
      <p className={styles.text}>Provide additional feedback on this answer.</p>
      <p className={styles.text}>Select all that apply.</p>
      <div className={styles.categories}>
        {Object.values(FeedbackCategory).map((category) => (
          <label key={category} className={styles['category']}>
            <input
              type="checkbox"
              {...register('categories', {
                validate: (value) =>
                  value.length > 0 || 'Please select at least one option'
              })}
              value={category}
            />
            {category}
          </label>
        ))}
        {errors.categories && (
          <p className={styles['error-message']}>{errors.categories.message}</p>
        )}
      </div>

      <label className={styles['extra-information']}>
        Optional: How can the response be improved?{' '}
        <textarea
          placeholder="Enter more information here..."
          {...register('feedbackMessage')}
        />
      </label>

      <div className={styles['form-controls']}>
        <button
          type="button"
          onClick={handleCancelClick}
          className={styles.button}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={styles['submit-button']}
          disabled={!isValid || loading}
        >
          Submit{' '}
          {loading && <CircularProgress size={'14px'} color="secondary" />}
        </button>
      </div>
    </form>
  )
}

export default FeedbackForm
