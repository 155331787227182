import { questionnaireGql } from '../../../../data/gql-gen/questionnaire'

export const MOVE_QUESTIONNAIRE_ENTRY = questionnaireGql(/* GraphQL */ `
  mutation moveQuestionnaireEntryV2($input: UpdateQuestionnaireEntryV2Input!) {
    updateQuestionnaireEntryV2(input: $input) {
      position
      number
      __typename
    }
  }
`)
