import { theme } from '@focaldata/cin-ui-components'
import {
  ChartType,
  Tooltip,
  TooltipModel,
  TooltipPositionerFunction
} from 'chart.js'

const TOOLTIP_CARET_ID = 'chartjs-tooltip-caret'

declare module 'chart.js' {
  interface TooltipPositionerMap {
    custom: TooltipPositionerFunction<ChartType>
  }
}

// Bottom tooltip positioner that follows the cursor
// Keeping this here for future reference
Tooltip.positioners.custom = (elements, position) => {
  if (!elements.length) {
    return false
  }
  let offset = 0

  // adjust the offset left or right depending on the event position
  if (elements[0].element.x / 2 > position.x) {
    offset = 20
  } else {
    offset = -20
  }

  return {
    x: position.x + offset,
    y: position.y
  }
}

export const createCaret = (tooltipModel: TooltipModel<'bar'>): HTMLElement => {
  let caretEl = document.getElementById(TOOLTIP_CARET_ID)

  if (caretEl) {
    // remove existing caret to create styles from scratch and escape any styling collisions
    caretEl.remove()
  }

  caretEl = document.createElement('div')
  caretEl.id = TOOLTIP_CARET_ID

  Object.assign(caretEl.style, {
    position: 'absolute',
    border: `${tooltipModel.options.caretSize}px solid transparent`
  })

  Object.assign(caretEl.style, {
    top: `${-2 * parseFloat(tooltipModel.options.caretSize.toString())}px`,
    left: '50%',
    marginLeft: `${
      -0.5 * parseFloat(tooltipModel.options.caretSize.toString())
    }px`,
    borderBottomColor: tooltipModel.options.backgroundColor
  })

  return caretEl
}

// eslint-disable-next-line max-statements
export const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    Object.assign(tooltipEl.style, {
      background: 'rgba(0, 0, 0, 0.8)',
      borderRadius: '3px',
      color: 'white',
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
      transition: 'all .1s ease'
    })

    const table = document.createElement('table')

    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

// Ignoring the max statements rule because this is a copy of the default tooltip handler
// We'll need to refactor this if the default tooltip handler requires more enhancements
// eslint-disable-next-line max-statements
export const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0

    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    const bodyLines = tooltip.body.map((b: any) => b.lines)

    const tableHead = document.createElement('thead')

    titleLines.forEach((title: any) => {
      const tr = document.createElement('tr')

      tr.style.borderWidth = '0'

      const th = document.createElement('th')

      Object.assign(th.style, {
        borderWidth: '0',
        textAlign: tooltip.options.titleAlign
      })

      const text = document.createTextNode(title)

      th.appendChild(text)
      tr.appendChild(th)
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement('tbody')

    // eslint-disable-next-line max-statements
    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i]

      const span = document.createElement('span')

      Object.assign(span.style, {
        background: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: '2px',
        marginRight: '10px',
        height: '10px',
        width: '10px',
        display: 'inline-block'
      })

      const tr = document.createElement('tr')

      tr.style.backgroundColor = 'inherit'
      tr.style.borderWidth = '0'

      const td = document.createElement('td')

      td.style.borderWidth = '0'

      const text = document.createTextNode(body)

      td.appendChild(span)
      td.appendChild(text)
      tr.appendChild(td)
      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector('table')

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)

    const caretEl = createCaret(tooltip)

    tooltipEl.append(caretEl)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  Object.assign(tooltipEl.style, {
    opacity: 1,
    left: `${positionX + tooltip.caretX}px`,
    top: `${positionY + tooltip.caretY}px`,
    font: tooltip.options.bodyFont.string,
    padding: `${tooltip.options.padding}px ${tooltip.options.padding}px`,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    weight: 500,
    lineHeight: 1
  })
}
