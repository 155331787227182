import {
  Dialog,
  DialogMaxWidth,
  Grid,
  Input,
  InputVariant,
  Select,
  SelectVariant,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useMemo, useState } from 'react'
import { emptyArr } from '../../../../constants/misc'
import { useAudienceCountries } from '../../../../hooks/audience/useAudienceCountries'
import {
  CountryOption,
  reorderCountriesDropDown
} from '../../../../utils/HelperFunctions'
import { PositiveIntegerNumberFormatCustom } from '../../../../utils/NumberFormatCustom'

const DEFAULT_OPTION: CountryOption = {
  id: 'GB-132',
  countryCode: 'GB',
  name: 'United Kingdom',
  value: 'GB-en'
}

const EMPTY_COUNTRY_OPTION: CountryOption = {
  id: 'empty',
  countryCode: '',
  name: '',
  value: ''
}

export type SaveMarketData = {
  marketTitle: string
  countryCode: CountryOption['countryCode']
  language: CountryOption['value']
  completes: number
}

export const AddMarketsDialog = ({
  open,
  onSave,
  onClose
}: {
  open: boolean
  onSave: (data: SaveMarketData) => void
  onClose: () => void
}) => {
  const [marketTitle, setMarketTitle] = useState('')
  const [country, setCountry] = useState<CountryOption>(EMPTY_COUNTRY_OPTION)
  // completes default value should be empty string for correct functionality for resetting the field
  const [completes, setCompletes] = useState<number | ''>('')
  const [addedMarkets, setAddedMarkets] = useState<string[]>([])
  const { data: dataCountries } = useAudienceCountries()

  const countriesOptions = useMemo(
    () => reorderCountriesDropDown(dataCountries ?? emptyArr),
    [dataCountries]
  )

  const options = useMemo(
    () => (countriesOptions.length === 0 ? [DEFAULT_OPTION] : countriesOptions),
    [countriesOptions]
  )

  const handleMarketTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMarketTitle(event.target.value)
  }

  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedCountry: CountryOption =
      options.find(
        (option: CountryOption) => option.value === event.target.value
      ) || DEFAULT_OPTION

    setCountry(selectedCountry)
  }

  const handleCompletesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCompletes(value ? Number(event.target.value) : '')
  }

  const savingDisabled = !marketTitle.trim() || !country.value || !completes

  const handleSaveButtonClick = () => {
    if (savingDisabled) {
      return
    }
    onSave({
      marketTitle,
      countryCode: country.countryCode,
      language: country.value,
      completes
    })
    resetDialog()
    setAddedMarkets([...addedMarkets, marketTitle])
  }

  const resetDialog = () => {
    setMarketTitle('')
    setCountry(EMPTY_COUNTRY_OPTION)
    setCompletes('')
    setAddedMarkets([])
  }

  const handleCloseDialog = () => {
    onClose()
    resetDialog()
  }

  return (
    <Dialog
      title="Add Markets"
      fullWidth
      maxWidth={DialogMaxWidth.Sm}
      open={open}
      primaryButtonText="Save market"
      primaryButtonDisabled={savingDisabled}
      primaryButtonClick={handleSaveButtonClick}
      transparentBackground
      onClickOutside={handleCloseDialog}
      onClose={handleCloseDialog}
    >
      <Grid container direction="column">
        <Grid item sx={{ mb: 3 }}>
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Market title
          </Text>
          <Input
            fullWidth
            variant={InputVariant.Filled}
            value={marketTitle}
            onChange={handleMarketTitleChange}
          />
        </Grid>
        <Grid item sx={{ mb: 3 }}>
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Country
          </Text>
          <Select
            fullWidth
            optionValue={country.value}
            onChange={handleCountryChange}
            options={options}
            variant={SelectVariant.Filled}
          />
        </Grid>
        <Grid item sx={{ mb: 3 }}>
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Number of completes
          </Text>
          <Input
            value={completes}
            onChange={handleCompletesChange}
            fullWidth
            customFormat={PositiveIntegerNumberFormatCustom}
            variant={InputVariant.Filled}
          />
        </Grid>
        {addedMarkets.length > 0 ? (
          <Text>
            Markets added ({addedMarkets.length}): {addedMarkets.join(', ')}
          </Text>
        ) : null}
      </Grid>
    </Dialog>
  )
}
