import { NavigateNext } from '@mui/icons-material'
import MiuBreadcrumbs from '@mui/material/Breadcrumbs'
import React from 'react'
import BreadcrumbLink, { BreadcrumbLinkVariant } from './BreadcrumbLink'
import useStyles from './Breadcrumbs.styles'

export interface BreadcrumbsOption {
  name: string
  route?: string
  linkVariant: BreadcrumbLinkVariant
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}
interface Props extends React.InputHTMLAttributes<HTMLAnchorElement> {
  options: BreadcrumbsOption[]
}

const Breadcrumbs: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { options }: Props = props

  return (
    <MiuBreadcrumbs
      separator={
        <NavigateNext fontSize="small" className={classes.separatorStyle} />
      }
    >
      {options.map((option) => {
        return (
          <BreadcrumbLink
            key={option.name}
            variant={option.linkVariant}
            onClick={option.onClick}
          >
            {option.name}
          </BreadcrumbLink>
        )
      })}
    </MiuBreadcrumbs>
  )
}

export default Breadcrumbs
