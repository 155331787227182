import { useGetDraftCombinedQuestion } from './DraftCombinedQuestionState/DraftCombinedQuestion.hooks'

const isEmpty = (value: string) => value.trim().length === 0

const hasEmptyValue = (values: { value: string }[]) =>
  values.some(({ value }) => isEmpty(value))

const hasUnmappedResponseOption = (
  questions: { responseOptions: { mappingId?: string | null }[] }[]
) =>
  questions.some(({ responseOptions }) =>
    responseOptions.some(({ mappingId }) => !mappingId)
  )

const getValidationErrors = (validation: {
  hasEmptyTitle: boolean
  hasEmptyResponseOption: boolean
  hasUnmappedMarketOption: boolean
}): string[] => {
  const validationErrors = []

  if (validation.hasEmptyTitle) {
    validationErrors.push('Combined question title cannot be empty')
  }

  if (validation.hasEmptyResponseOption) {
    validationErrors.push('All response options must have values')
  }

  if (validation.hasUnmappedMarketOption) {
    validationErrors.push(
      'All market response options must be mapped using mapping numbers'
    )
  }

  return validationErrors
}

export const useResponseOptionsMappingPageValidation = (): {
  isValid: boolean
  validationErrors: string[]
} => {
  const { draftCombinedQuestion } = useGetDraftCombinedQuestion()
  const {
    title = '',
    mappedResponseOptions = [],
    marketQuestions = []
  } = draftCombinedQuestion ?? {}

  const validationErrors = getValidationErrors({
    hasEmptyTitle: isEmpty(title),
    hasEmptyResponseOption: hasEmptyValue(mappedResponseOptions),
    hasUnmappedMarketOption: hasUnmappedResponseOption(marketQuestions)
  })

  return {
    isValid: validationErrors.length === 0,
    validationErrors
  }
}
