import {
  Grid,
  Paper,
  PaperGroupItem,
  Skeleton
} from '@focaldata/cin-ui-components'
import React from 'react'
import useStyles from './AudienceQuestionCard.styles'

const AudienceQuestionCardLoading: React.FC = () => {
  const { classes } = useStyles()

  return (
    <Grid item className={classes.audienceCard}>
      <Paper>
        <PaperGroupItem>
          <Grid container direction="row" justifyContent="space-between">
            <div className={classes.titleContainer}>
              <Grid container direction="column">
                <Grid item container direction="row">
                  <Skeleton height={30} width={80} />
                </Grid>
                <Grid item>
                  <Skeleton height={40} width={160} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </PaperGroupItem>
        <PaperGroupItem>
          <div className={classes.loadingContentPlaceholder} />
        </PaperGroupItem>
      </Paper>
    </Grid>
  )
}

export default AudienceQuestionCardLoading
