import {
  ButtonType,
  LinkButton,
  Logo,
  SpinnerButton,
  TextSize,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import Grid from '@mui/material/Grid'
import React from 'react'
import styles from '../Auth.module.css'
import useStyles from '../Auth.styles'

interface Props {
  disabled?: boolean
  onClickButton: () => void
}
const ForgotPasswordSucessful: React.FC<Props> = (props: Props) => {
  const { disabled, onClickButton } = props

  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  return (
    <Grid className={styles.page} container justifyContent="center">
      <Grid className={classes.maxWidth}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Logo width={42} type="icon" />
          <h1 className={styles.title}>Check your email</h1>
          <p className={styles.subtitle}>
            You will receive an email with instructions about how to reset your
            password in a few minutes.
          </p>
          <SpinnerButton
            onClick={onClickButton}
            className={classNames(classes.button, classes.gridItem)}
            fullWidth
            buttonType={ButtonType.Submit}
            disabled={disabled}
          >
            Resend email
          </SpinnerButton>
          <Grid
            container
            justifyContent="center"
            className={classNames(classes.helperText, classes.gridItem)}
          >
            <Grid className={classes.paddingRight}>
              <p className={classNames(textClasses.default)}>
                Need help? Contact
              </p>
            </Grid>
            <LinkButton
              size={TextSize.m}
              weight={TextWeight.Bold}
              noPadding
              underlined
            >
              support@focaldata.com
            </LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordSucessful
