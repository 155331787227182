import {
  Select,
  SelectVariant,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import IntervalInput from '../../../../components/IntervalInput/IntervalInput'
import {
  DraftQuestionnaireEntry,
  QuestionSettingCode
} from '../../../../data/model/questionnaire'
import useRemoveQuestionnaireSetting from '../../../../hooks/questionnaire/useRemoveQuestionnaireSetting'
import { useUpdateQuestionnaireEntry } from '../../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import useStyles from './QuestionCardFooter.styles'
import {
  MAX_GREATHER_THAT_RO_COUNT_ERROR,
  MULTI_SELECT_MAXIMUM_SELECTED,
  MULTI_SELECT_MAXIMUM_SELECTED_OPTION,
  MULTI_SELECT_OPTION,
  MULTI_SELECT_RANGE_OPTION,
  multiSelectRangeOptions
} from './constants'
import { dropDownOptions } from './utils'

interface Props {
  entry: DraftQuestionnaireEntry
  responseOptionsCount?: number
  choiceLimit?: number
  choiceIntervalMin?: number
  choiceIntervalMax?: number
  onChangeChoiceLimit: (settingValue: number) => void
  onChangeIntervalRange?: (min: number, max: number) => void
}

const MultiSelectChoiceLimit: React.FC<Props> = (props: Props) => {
  const {
    entry,
    responseOptionsCount = 0,
    choiceLimit = 0,
    choiceIntervalMin,
    choiceIntervalMax,
    onChangeChoiceLimit,
    onChangeIntervalRange
  } = props

  const { entryItem } = entry

  if (!('settingValues' in entryItem)) {
    throw new Error(
      `Invalid entry of type "${entry.entryType}". Settings can be updated only on entries which have settingValues.`
    )
  }

  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id)

  const optionValue =
    choiceIntervalMin !== undefined && choiceIntervalMax
      ? MULTI_SELECT_RANGE_OPTION
      : MULTI_SELECT_MAXIMUM_SELECTED_OPTION

  const [multiSelectOptionValue, setMultiSelectOptionValue] =
    useState(optionValue)

  const [intervalError, setIntervalError] = useState<string>('')
  const removeQuestionnaireValue = useRemoveQuestionnaireSetting(entry)

  const onMultiSelectOptionChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const eventValue = parseInt(event.target.value, 10)
    setMultiSelectOptionValue(eventValue)
    if (eventValue === MULTI_SELECT_MAXIMUM_SELECTED_OPTION) {
      removeQuestionnaireValue(
        [
          QuestionSettingCode.ChoiceIntervalMin,
          QuestionSettingCode.ChoiceIntervalMax,
          QuestionSettingCode.ChoiceLimit
        ],
        'no-cache'
      )
    }
    if (eventValue === MULTI_SELECT_RANGE_OPTION) {
      await updateQuestionnaireEntry({
        settingValues: entryItem.settingValues
          .map(({ code, value }) => {
            return {
              questionSettingCode: code,
              settingValue: value
            }
          })
          .filter(({ questionSettingCode }) => {
            return questionSettingCode !== QuestionSettingCode.ChoiceLimit
          })
          .concat([
            {
              questionSettingCode: QuestionSettingCode.ChoiceIntervalMin,
              settingValue: '0'
            },
            {
              questionSettingCode: QuestionSettingCode.ChoiceIntervalMax,
              settingValue: responseOptionsCount.toString()
            }
          ])
      })
    }
  }

  const onSelectedChoiceOptionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const eventValue = parseInt(event.target.value, 10)
    onChangeChoiceLimit(eventValue)
  }

  const handleIntervalChange = (min: number, max: number) => {
    setIntervalError('')
    onChangeIntervalRange?.(min, max)
  }

  const handleOnError = (error?: string) => {
    setIntervalError(error || '')
  }

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <div className="fd-grid fd-container fd-justify-content-end fd-spacing-1">
      <div className="fd-grid fd-item">
        <Select
          ariaLabel={MULTI_SELECT_OPTION}
          optionValue={multiSelectOptionValue}
          minWidth={142}
          options={multiSelectRangeOptions}
          variant={SelectVariant.Filled}
          onChange={onMultiSelectOptionChange}
        />
      </div>
      <div className="fd-grid fd-item">
        {multiSelectOptionValue === MULTI_SELECT_MAXIMUM_SELECTED_OPTION && (
          <Select
            ariaLabel={MULTI_SELECT_MAXIMUM_SELECTED}
            optionValue={choiceLimit}
            minWidth={142}
            options={dropDownOptions(responseOptionsCount)}
            variant={SelectVariant.Filled}
            onChange={onSelectedChoiceOptionChange}
          />
        )}
        {multiSelectOptionValue === MULTI_SELECT_RANGE_OPTION && (
          <IntervalInput
            min={choiceIntervalMin}
            max={choiceIntervalMax}
            rangeLimit={responseOptionsCount}
            invalidRangeErrorMessage={MAX_GREATHER_THAT_RO_COUNT_ERROR}
            onIntervalChanged={handleIntervalChange}
            onError={handleOnError}
          />
        )}
      </div>
      <div className="fd-grid fd-container fd-item fd-justify-content-end">
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeMs,
            textClasses.weightRegular,
            classes.errorText
          )}
        >
          {intervalError}
        </p>
      </div>
    </div>
  )
}

export default MultiSelectChoiceLimit
