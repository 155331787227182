import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import LoadingbarHorizontal from '../../controls/LoadingBarHorizontal'
import useStyles from './styles'

interface Props {
  optionsBar?: JSX.Element
  resultCards: JSX.Element
  filters?: JSX.Element
  isLoading?: boolean
}

const ResultsPageLayout: React.FC<Props> = (props: Props) => {
  const { optionsBar, resultCards, filters, isLoading } = props
  const { classes } = useStyles()

  return (
    <>
      <LoadingbarHorizontal isLoading={isLoading} />
      <Grid container className={classes.root}>
        <Grid
          item
          xs={8}
          className={classes.centerContainer}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12} className={classes.optionsBar}>
            {optionsBar}
          </Grid>
          <Grid item xs={12} className={classes.resultsCard}>
            {resultCards}
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.filtersContainer}>
          {filters}
        </Grid>
      </Grid>
    </>
  )
}

export default ResultsPageLayout
