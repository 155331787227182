import { Theme, makeStyles } from '@focaldata/cin-ui-components'
import { PROJECT_TITLE_HEADER_HEIGHT } from '../MultiMarketPage'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      padding: theme.spacing(1, 2),
      maxHeight: `calc(100vh - ${PROJECT_TITLE_HEADER_HEIGHT}px)`,
      overflow: 'auto'
    },
    backLinkWrapper: {
      '& > a svg': {
        transform: 'rotateY(180deg)'
      }
    }
  }
})
