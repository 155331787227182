import { Theme } from '@focaldata/cin-ui-components'
import { ChartOptions, TooltipItem } from 'chart.js'
import { BarChartData } from './Chart.model'

const MAX_LABEL_ROW_LENGTH = 40
const NUMBER_OF_TICKS = 5
const BAR_HEIGHT = 30
const BAR_MARGIN = 10
const BAR_HEIGHT_WITH_MARGIN = BAR_HEIGHT + BAR_MARGIN
const LEGEND_HEIGHT = 40
const CHART_MARGIN_Y = 20 + 20 - BAR_MARGIN

export const formatEachThreeDigitsWithComma = (digits: string): string => {
  if (digits.length <= 3) {
    return digits
  }

  const exceptThreeLastDigits = digits.slice(0, -3)
  const threeLastDigits = digits.slice(-3)

  return `${formatEachThreeDigitsWithComma(
    exceptThreeLastDigits
  )},${threeLastDigits}`
}

const getMaxStringLessThan: (myString: string, maxLength: number) => string = (
  myString,
  maxLength
) => {
  const shards: string[] = myString.split(' ')
  const constructed: string[] = []
  let len = 0

  shards.forEach((shard) => {
    len += shard.length + 1
    if (len <= maxLength) {
      constructed.push(shard)
    }
  })

  return constructed.join(' ')
}

export const roundFloatingMultipleOfFive: (floatingNumb: number) => number = (
  floatingNumb: number
) => {
  return (Math.ceil(parseFloat(floatingNumb.toFixed(2)) * 20) * 5) / 100
}

export const calculateTickStepSizeDefault: (maxBarValue: number) => number = (
  maxBarValue: number
) => {
  const largestOptions = roundFloatingMultipleOfFive(maxBarValue)

  const nonRoundedTickStep = largestOptions / NUMBER_OF_TICKS
  const roundedTickStep = roundFloatingMultipleOfFive(nonRoundedTickStep)

  return roundedTickStep
}

const calculateMaxTickDefault: (maxBarValue: number) => number = (
  maxBarValue: number
) => {
  const roundedLargestOptions = calculateTickStepSizeDefault(maxBarValue)
  const actualNrOfTicks = Math.ceil(maxBarValue / roundedLargestOptions)

  return roundedLargestOptions * actualNrOfTicks
}

export const formatTooltipLabelDefault = (
  item: TooltipItem<'bar'>
): string | string[] => {
  const itemValue = item.raw as string
  const { totalSamples } = item.dataset as any
  const { completesPerRow } = item.dataset as any

  let ratio = 1

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (completesPerRow && item.dataIndex !== undefined)
    ratio = totalSamples / completesPerRow[item.dataIndex]

  const optionValue = (Number(itemValue) / ratio).toString()
  const responsesPerBar = optionValue
    ? formatEachThreeDigitsWithComma(
        (parseFloat(optionValue) * totalSamples).toFixed(0)
      )
    : 'N/A'

  const percentPerBar = itemValue
    ? (parseFloat(itemValue) * 100).toFixed(1)
    : undefined

  return `${percentPerBar || 'N/A'}% ${responsesPerBar} responses`
}

export const formatTooltipLabelGroupedChart = (
  item: TooltipItem<'bar'>
): string | string[] => {
  const optionValue = item.raw as string
  const { totalSamples } = item.dataset as any
  const { completesPerRow } = item.dataset as any
  let rowCompletes = totalSamples
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (completesPerRow && item.dataIndex !== undefined)
    rowCompletes = completesPerRow[item.dataIndex]

  const responsesPerBar = optionValue
    ? formatEachThreeDigitsWithComma(
        (parseFloat(optionValue) * rowCompletes).toFixed(0)
      )
    : 'N/A'

  const percentPerBar = optionValue
    ? (parseFloat(optionValue) * 100).toFixed(1)
    : 'N/A'

  return `${percentPerBar}% ${responsesPerBar} responses`
}

export const formatTooltipTitle: (item: TooltipItem<'bar'>[]) => string = (
  item: TooltipItem<'bar'>[]
) => item[0].label

export const getBarColor: (index: number) => string = (index) => {
  const colors: string[] = [
    '#14465A',
    '#FF6E5A',
    '#59A14F',
    '#EDC948',
    '#B9C8CE',
    '#F28E2B',
    '#B07AA1',
    '#4E79A7',
    '#FFD4CE',
    '#9C755F',
    '#58B5DA',
    '#F8E9B5',
    '#A5D09F',
    '#F3F6F7',
    '#F8C28C',
    '#DEE2E6',
    '#88AAAA',
    '#F88DF6',
    '#52F4AE',
    '#E9FF70'
  ]

  return colors[index]
}

export const percentFormatterDefault = (barValue: string | number): string => {
  const barPercent = `${(Number(barValue) * 100).toFixed(0)}%`

  return `${barPercent}`
}

type CustomChartOptions = Required<
  Pick<
    ChartOptions<'bar'>,
    | 'maintainAspectRatio'
    | 'responsive'
    | 'animation'
    | 'datasets'
    | 'plugins'
    | 'layout'
    | 'scales'
  >
> &
  ChartOptions<'bar'>

export const getDefaultOptions: (
  theme: Theme,
  maxBarValue: number
) => CustomChartOptions = (theme, maxBarValue) => {
  const maxTickDefault = calculateMaxTickDefault(maxBarValue)
  const tickSizeDefault = calculateTickStepSizeDefault(maxBarValue)
  const pluginOptions = {
    datalabels: {
      display: true,
      clamp: true,
      clip: false,
      font: {
        family: theme.typography.fontFamily,
        size: theme.typography.fontSize * 0.75,
        weight: 500
      },
      formatter: (value: number) => percentFormatterDefault(value)
    },
    tooltip: {
      callbacks: {
        title: formatTooltipTitle,
        label: formatTooltipLabelDefault
      }
    }
  }
  const layoutOptions = {
    padding: {
      top: 0,
      bottom: 0,
      left: 22,
      right: 35
    }
  }
  const options: CustomChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 100
    },
    datasets: {
      bar: {
        barThickness: BAR_HEIGHT
      }
    },
    plugins: pluginOptions,
    layout: layoutOptions,
    scales: {
      x: {
        grid: {
          display: false
        },
        min: 0,
        max: maxTickDefault,
        ticks: {
          stepSize: tickSizeDefault,
          display: false,
          padding: 5,
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.fontFamily,
            size: theme.typography.fontSize * 0.875
          },
          callback: (value: string | number) => percentFormatterDefault(value)
        }
      },
      y: {
        title: {
          display: false
        },
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: theme.typography.fontFamily,
            size: theme.typography.fontSize * 0.75
          },
          color: theme.palette.text.primary,
          callback(tickValue): string | string[] {
            const label =
              (this as any).getLabelForValue(tickValue as number) || ''

            if (label.length > 2 * MAX_LABEL_ROW_LENGTH) {
              const firstLine = getMaxStringLessThan(
                label,
                MAX_LABEL_ROW_LENGTH
              )

              return [
                firstLine,
                `${label.slice(
                  firstLine.length,
                  2 * MAX_LABEL_ROW_LENGTH - 3
                )}...`
              ]
            }
            if (label.length > MAX_LABEL_ROW_LENGTH) {
              const firstLine = getMaxStringLessThan(
                label,
                MAX_LABEL_ROW_LENGTH
              )

              return [firstLine, label.slice(firstLine.length)]
            }

            return label
          }
        }
      }
    }
  }

  return options
}

export const calculateChartHeight = (bars: number) =>
  bars * BAR_HEIGHT_WITH_MARGIN + CHART_MARGIN_Y

export const calculateChartHeightWithLegend = (bars: number) => {
  return calculateChartHeight(bars) + LEGEND_HEIGHT
}

export const calculateGroupedChartHeight = (groups: number, bars: number) => {
  return bars * BAR_HEIGHT_WITH_MARGIN * groups + LEGEND_HEIGHT + CHART_MARGIN_Y
}

export const getAdjustedMaxBarValueIfNeeded = (
  maxBarValue: number,
  data: BarChartData
): number => {
  const dataTotalsPerBar = data.datasets[0].data.map((_, i) => {
    return data.datasets.reduce((acc, dataset) => {
      return dataset.data[i] + acc
    }, 0)
  })

  const maxBarValueFound = Math.max(...dataTotalsPerBar)
  const shouldAdjustBars = maxBarValueFound < maxBarValue

  return shouldAdjustBars ? maxBarValueFound : maxBarValue
}
