import { Grid, Skeleton } from '@focaldata/cin-ui-components'
import React from 'react'

const LoadingFilters: React.FC = () => {
  return (
    <Grid container direction="column">
      <Grid item container direction="row">
        <Skeleton height={30} width={120} />
      </Grid>
      <Grid item>
        <Skeleton height={40} width={200} />
      </Grid>
      <Grid item>
        <Skeleton height={40} width={220} />
      </Grid>
      <Grid item container direction="row">
        <Skeleton height={30} width={120} />
      </Grid>
      <Grid item>
        <Skeleton height={40} width={200} />
      </Grid>
      <Grid item>
        <Skeleton height={40} width={220} />
      </Grid>
    </Grid>
  )
}

export default LoadingFilters
