import { Theme, makeStyles } from '@focaldata/cin-ui-components'

interface StyleProps {
  maxWidth?: number | 'max-content'
  maxWidthPercent?: string
  height?: number
  flexWrap?: string
}

const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    layoutStyle: {
      maxWidth: props.maxWidth || props.maxWidthPercent,
      height: props.height || 45,
      flexWrap: 'nowrap'
    },
    divider: {
      height: theme.spacing(4)
    },
    child: {
      height: '100%'
    }
  })
)

export default useStyles
