import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { LogAmplitudeEvent, SetUserId } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { Login as LoginControl } from '../../../components/Auth'
import { Login } from '../../../data/rest/accountUser'
import { useLoginRedirect } from '../../../hooks/useLoginRedirect'
import UserSession from '../../../utils/UserSession'
import { AccessToken, setAccountId } from '../../../utils/authUtils'

const successStatus = {
  ok: 200,
  created: 201
}

const failStatus = {
  unauthorized: 401,
  notFound: 404
}

const LoginComponent = () => {
  const navigate = useNavigate()
  const { loginRedirect } = useLoginRedirect()

  const handleLogin = async (email: string, password: string) => {
    try {
      const response = await Login(email, password)
      if (response.status === successStatus.ok) {
        UserSession.setEmail(response.data.emailAddress)
        AccessToken.set(response.data.accessToken)
        const gotAccountId = await setAccountId(
          response.data.emailAddress,
          response.data.accessToken
        )
        SetUserId(response.data.emailAddress)
        LogAmplitudeEvent(EventType.Loggedin)

        loginRedirect()

        return gotAccountId
      }
    } catch (error: unknown) {
      const ex = error as AxiosError
      if (ex.response && ex.response.status === failStatus.unauthorized) {
        return new Error(failStatus.unauthorized.toString())
      }
      if (ex.response && ex.response.status === failStatus.notFound) {
        return new Error(failStatus.notFound.toString())
      }
      return new Error(ex.message)
    }
    return false
  }

  const handleGoToRegister: () => void = () => {
    navigate('/signup')
  }

  const handleForgotPassword: () => void = () => {
    navigate('/forgot-password')
  }

  return (
    <LoginControl
      onLogin={handleLogin}
      onForgotPassword={handleForgotPassword}
      onGoToRegister={handleGoToRegister}
    />
  )
}

export default LoginComponent
