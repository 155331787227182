import { QuestionMediaArgsInput } from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { useUpdateMatrixRow } from './MatrixQuestionRows.hooks'

export const useMatrixRowMediaActions = (entryId: string) => {
  const updateMatrixRow = useUpdateMatrixRow(entryId, {
    fetchPolicy: 'no-cache'
  })

  const setMatrixRowMedia = (
    questionId: string,
    media: QuestionMediaArgsInput | null
  ) => {
    updateMatrixRow(
      {
        questionId,
        media: {
          value: media
        }
      },
      {
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            media
              ? 'updateMatrixRowV2 - add media'
              : 'updateMatrixRowV2 - remove media',
            error
          )
        }
      }
    )
  }

  const removeMatrixRowMedia = (questionId: string) =>
    setMatrixRowMedia(questionId, null)

  return {
    setMatrixRowMedia,
    removeMatrixRowMedia
  }
}
