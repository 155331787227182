import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'

import AudienceQuestionCardLoading from '../AudienceQuestionCard/AudienceQuestionCardLoading'
import useStyles from './SurveyAudienceListLoading.styles'

const SurveyAudienceListLoading: React.FC = () => {
  const { classes } = useStyles({})
  return (
    <Grid item className={classes.cardList}>
      <AudienceQuestionCardLoading />
      <AudienceQuestionCardLoading />
    </Grid>
  )
}

export default SurveyAudienceListLoading
