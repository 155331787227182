import { useFragment, useReactiveVar } from '@apollo/client'
import debounce from 'lodash/debounce'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import { TEXT_CARD } from '../../../data/gql/questionnaire/fragments/DraftTextCardItem'
import { LoggerErrorType } from '../../../data/logger'
import { DraftTextCardItem } from '../../../data/model/questionnaire'
import { useDuplicateQuestionnaireEntry } from '../../../hooks/questionnaire/useDuplicateQuestionnaireEntry'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { WithEntry, propsAreEqual } from '../../../utils/questionnaireUtils'
import { CardTitleControl } from '../CardTitle'
import { getTextFromRawTitleStyling } from '../CardTitle/CardTitle.utils'
import { pipingMarkerAddedVar } from '../PipingDialog/PipingDialog.hooks'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import TextCardControl from './TextCard.control'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const TextCard: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const { validateBasicQuestionText } = useQuestionnaireValidation()
  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id, {
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateTextCard',
        error
      )
    }
  })

  // const questionnaireId = useDraftQuestionnaireIdCache()
  const entryItem = entry.entryItem as DraftTextCardItem

  const isTextCardEmpty = validateBasicQuestionText(entryItem.textCardLk)

  const { textCardLk } = entryItem
  const { data: textCardData } = useFragment({
    fragment: TEXT_CARD,
    fragmentName: 'TextCard',
    from: { __typename: 'TextCard', textCardId: textCardLk },
    canonizeResults: true
  })

  const [titleStyling, setTitleStyling] = useState(textCardData.titleStyling)
  const pipingMarkerAdded = useReactiveVar(pipingMarkerAddedVar)

  useEffect(() => {
    if (pipingMarkerAdded[textCardLk]) {
      pipingMarkerAddedVar({ [textCardLk]: false })
      setTitleStyling(textCardData.titleStyling)
    }
  }, [pipingMarkerAdded, textCardData.titleStyling, textCardLk])

  const bodyState = useRef(textCardData.body)

  useEffect(() => {
    bodyState.current = textCardData.body
  }, [textCardData.body])

  const { duplicateQuestionnaireEntry } = useDuplicateQuestionnaireEntry(
    entry.id
  )

  const updateTextCardDetails = useCallback(
    (rawTitleStyling: string, newBody?: string) => {
      updateQuestionnaireEntry({
        updateTextCard: {
          title: getTextFromRawTitleStyling(rawTitleStyling),
          titleStyling: rawTitleStyling,
          subtitle: '',
          body: newBody || bodyState.current || ''
        }
      })
    },
    [updateQuestionnaireEntry]
  )

  const handleOnEditorChangeDebounced = useMemo(
    () =>
      debounce((newRawTitleStyling: string) => {
        updateTextCardDetails(newRawTitleStyling)
      }, 1000),
    [updateTextCardDetails]
  )

  const handleOnEditorChange = (newRawTitleStyling: string) => {
    setTitleStyling(newRawTitleStyling)
    handleOnEditorChangeDebounced(newRawTitleStyling)
  }

  const handleOnChangeBody = (newBody: string) => {
    bodyState.current = newBody
    updateTextCardDetails((titleStyling || textCardData.title) ?? '', newBody)
  }

  return (
    <TextCardControl
      entry={entry}
      questionCardHeader={
        <SurveyQuestionCardHeaderContainer
          entry={entry}
          hasError={isTextCardEmpty}
          onClickDuplicateIcon={() =>
            duplicateQuestionnaireEntry({
              position: entry.position + 1
            })
          }
          isMultipleChoice={false}
          titleContent={
            <CardTitleControl
              hasEditor
              shouldHandlePasteManually
              hasError={isTextCardEmpty}
              titleValue={textCardData.title}
              titleStyling={titleStyling ?? undefined}
              ariaLabel="Text card question header"
              shouldTitleInputFocus={shouldTitleInputFocus}
              placeholder="Type in your instructions here"
              helperText="Please add a question title"
              onFocus={() => questionBeingEditedId(entry.id)}
              onEditorChange={handleOnEditorChange}
            />
          }
          entries={flatDraftQuestionnaireEntries}
        />
      }
      defaultBody={textCardData.body}
      onChangeBody={handleOnChangeBody}
    />
  )
}

export default memo(TextCard, propsAreEqual)
