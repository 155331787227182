import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  Text,
  TextDisplay,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { pluralise } from '../../../utils/chartUtils'
import useStyles from './DialogDeleteSection.styles'

interface Props {
  isOpened: boolean
  sectionName: string
  numberOfEntries: number
  onClose: () => void
  onDeleteAll: () => void
  onDeleteWithoutQuestions: () => void
}
const DialogDeleteSection = ({
  isOpened,
  sectionName,
  numberOfEntries,
  onClose,
  onDeleteAll,
  onDeleteWithoutQuestions
}: Props) => {
  const { classes } = useStyles()
  const questionTextPluralise = pluralise(numberOfEntries, 'question')

  return (
    <Dialog
      title={`Delete ${sectionName}`}
      open={isOpened}
      onClose={onClose}
      maxWidth={DialogMaxWidth.Sm}
      variant={DialogVariant.DoubleButton}
      primaryButtonText="Keep questions"
      primaryButtonLoading={false}
      primaryButtonClick={onDeleteWithoutQuestions}
      secondaryButtonDisabled={false}
      secondaryButtonText="Delete all"
      secondaryButtonClick={onDeleteAll}
    >
      <Grid container direction="column">
        <Grid item>
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            {`${sectionName} includes ${questionTextPluralise}.`}
          </Text>
        </Grid>
        <Grid item className={classes.infoContent}>
          <Text
            size={TextSize.m}
            display={TextDisplay.Inline}
            highlight={TextHighlight.Background}
          >
            {`You can delete ${sectionName} and all included
            ${questionTextPluralise} or delete section only and keep the
            ${questionTextPluralise}`}
          </Text>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DialogDeleteSection
