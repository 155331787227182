import { makeStyles } from '@focaldata/cin-ui-components'
import { MULTI_MARKET_HEADER_HEIGHT } from '../MultiMarketPage'

const subheaderHeight = 48
const subheaderShadowHeight = 4
const footerHeight = 56

export const useStyles = makeStyles()(() => {
  return {
    subheaderWrapper: {
      marginBottom: subheaderShadowHeight
    },
    questionaireWrapper: {
      height: `calc(100vh - ${MULTI_MARKET_HEADER_HEIGHT}px - ${subheaderHeight}px - ${subheaderShadowHeight}px - ${footerHeight}px)`
    }
  }
})
