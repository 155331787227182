import {
  Grid,
  LinkButton,
  Paper,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import { CSSProperties, ReactNode, useEffect } from 'react'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import FdChatReviewAudience from './FdChatReviewAudience'
import FdChatReviewObjectives from './FdChatReviewObjectives'
import FdChatReviewQuantQuestions from './FdChatReviewQuantQuestions'
import FdChatReviewQuote from './FdChatReviewQuote'

export const ReviewSection = ({
  title,
  sectionLinkText,
  onSectionLinkClick,
  children,
  style,
  headerRightComponent
}: {
  title: string
  sectionLinkText?: string
  onSectionLinkClick?: () => void
  children: ReactNode
  style?: CSSProperties
  headerRightComponent?: ReactNode
}) => (
  <Paper style={{ borderRadius: '8px', ...style }}>
    <Grid container justifyContent={'space-between'} padding={'22px'}>
      <Text weight={TextWeight.SemiBold} size={TextSize.ml}>
        {title}
      </Text>
      {sectionLinkText && onSectionLinkClick && (
        <LinkButton onClick={onSectionLinkClick} underlined noPadding>
          {sectionLinkText}
        </LinkButton>
      )}
      {headerRightComponent}
    </Grid>
    <Divider />
    <div style={{ padding: '24px' }}>{children}</div>
  </Paper>
)

export const FdChatReview = () => {
  const surveyId = useSurveyId()

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedFdChatReviewPage, { surveyId })
  }, [surveyId])

  return (
    <Grid padding={'24px 32px'}>
      <div style={{ marginBottom: '24px' }}>
        <Text size={TextSize.lxl} weight={TextWeight.Bold}>
          Review your project before launching
        </Text>
      </div>
      <Grid container spacing={'24px'}>
        <Grid item xs={12} lg={8}>
          <div style={{ marginBottom: '24px' }}>
            <FdChatReviewAudience showEditLink />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <FdChatReviewQuantQuestions showEditLink />
          </div>
          <FdChatReviewObjectives showEditLink />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FdChatReviewQuote />
        </Grid>
      </Grid>
    </Grid>
  )
}
