import { PureQueryOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { SoftLaunchSurveyOrder } from './SoftLaunch.model'

export interface SoftLaunchSurveyOrderData {
  surveyOrder: SoftLaunchSurveyOrder
}

export const GET_SURVEY_ORDER_SOFT_LAUNCH = gql`
  query softLaunchSurveyOrder($surveyId: ID!) {
    surveyOrder(surveyId: $surveyId) {
      surveyId
      panelSupplierOrders {
        orderId
        panelSupplierOrderId {
          value
        }
        status
        orderNumber
        createdDate
        sinceDate
      }
      isSoftLaunch
      customerCPI
    }
  }
`

export const getSurveyOrderSoftLaunchRefetchQuery: (
  surveyId: string
) => PureQueryOptions = (surveyId) => {
  return {
    query: GET_SURVEY_ORDER_SOFT_LAUNCH,
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    }
  }
}
