import React, { useState } from 'react'
import useStyles from './styles'

interface Props {
  entryCard: JSX.Element | null
  bottomActions?: JSX.Element
}

const GenericEntryLayout: React.FC<Props> = (props: Props) => {
  const { classes, cx: classNames } = useStyles()

  const { entryCard, bottomActions } = props

  const [isOverQuestionCard, setIsOverQuestionCard] = useState<boolean>(false)
  const [isFocusQuestionCard, setIsFocusQuestionCard] = useState<boolean>(false)

  const handleOnMouseEnter = () => {
    setIsOverQuestionCard(true)
  }
  const handleOnMouseLeave = () => {
    setIsOverQuestionCard(false)
  }
  const handleOnFocus = () => {
    setIsFocusQuestionCard(true)
  }
  const handleOnBlur = () => {
    setIsFocusQuestionCard(false)
  }

  const shouldDisplayAddQuestionIcon = isFocusQuestionCard || isOverQuestionCard

  return (
    <div
      data-testid="question-card-wrapper"
      className={classNames(
        'fd-grid',
        'fd-container',
        shouldDisplayAddQuestionIcon
          ? classes.questionLayoutContainerOver
          : classes.questionLayoutContainerNotOver
      )}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    >
      <div className="fd-grid fd-item fd-xs-12">{entryCard}</div>
      {shouldDisplayAddQuestionIcon && (
        <div
          className={classNames(
            'fd-grid',
            'fd-item',
            'fd-xs-12',
            classes.bottomActionsLayout
          )}
        >
          {bottomActions}
        </div>
      )}
    </div>
  )
}

export default GenericEntryLayout
