import { useGetProjectId } from '../modules/Project/Project.hooks'
import { useProjectId } from './useProjectId'

export const useProjectIdValidation = () => {
  const projectIdInUrl = useProjectId()
  const projectIdForSurvey = useGetProjectId()

  // only compare project ids if `projectIdForSurvey` exists, otherwise consider `projectIdInUrl` valid
  const isProjectIdValid =
    !projectIdForSurvey || projectIdForSurvey === projectIdInUrl

  return { isProjectIdValid }
}
