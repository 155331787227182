import { Text } from '@focaldata/cin-ui-components'
import { useNavigate } from 'react-router-dom'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import AudienceSummaryContainer from '../../../../modules/Audience/AudienceSummary/AudienceSummary.container'
import { ReviewSection } from './FdChatReview'

const FdChatReviewQuantQuestions = ({
  showEditLink
}: {
  showEditLink?: boolean
}) => {
  const surveyId = useSurveyId()
  const navigate = useNavigate()

  return (
    <ReviewSection
      title="Audience questions"
      sectionLinkText={showEditLink ? 'Edit questions' : undefined}
      onSectionLinkClick={
        showEditLink
          ? () =>
              navigate(`/fdchat/${surveyId}/setup/audience`, {
                state: { showAudienceQuestions: true }
              })
          : undefined
      }
    >
      <Text>
        The following response options qualify respondents for your project.
      </Text>
      <AudienceSummaryContainer />
    </ReviewSection>
  )
}

export default FdChatReviewQuantQuestions
