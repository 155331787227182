import { useState } from 'react'
import { useAppSelector } from '../App.store'
import { emptyArr } from '../constants/misc'
import {
  selectResponseOptionsByQuestion,
  selectSettingsByQuestion
} from '../modules/Questionnaire/Questionnaire.slice'
import { calculateLengthOfInterview } from '../utils/LOIutils'
import useAudienceQuestionsCounter from './audience/useAudienceQuestionsCounter'
import useGetDraftQuestionnaire from './questionnaire/useGetDraftQuestionnaire'
import { useDebounceEffect } from './useDebounce'

const useCalculateLengthOfInterview = () => {
  const [calculatedLoi, setCalculatedLoi] = useState<number>()
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const settingsByQuestion = useAppSelector(selectSettingsByQuestion)

  // we cannot rely on cache here since useGetDraftQuestionnaire query sets questionnaire data in redux and we rely on client-side validation which may become incorrect. example: removing text from response option sets validation error to redux without updating cache then this query reads outdated cache and updates redux store based on it which is wrong and makes valid questionnare invalid or vice versa
  const { draftQuestionnaire } = useGetDraftQuestionnaire('network-only')

  const { totalNumberAudienceQuestionsAddedToSurvey } =
    useAudienceQuestionsCounter()

  const questionnaireEntries = draftQuestionnaire?.entries ?? emptyArr

  useDebounceEffect(
    () => {
      if (
        questionnaireEntries.length > 0 ||
        totalNumberAudienceQuestionsAddedToSurvey > 0
      ) {
        const calculatedLengthOfInterview = calculateLengthOfInterview(
          questionnaireEntries,
          totalNumberAudienceQuestionsAddedToSurvey,
          responseOptionsByQuestion,
          settingsByQuestion
        )
        setCalculatedLoi(calculatedLengthOfInterview)
      }
    },
    [
      questionnaireEntries,
      totalNumberAudienceQuestionsAddedToSurvey,
      responseOptionsByQuestion
    ],
    { delay: 2000 }
  )

  return calculatedLoi
}

export default useCalculateLengthOfInterview
