import React from 'react'
import { LoadingBar, LoadingBarColor } from '../../components/LoadingBar'
import { useStyles } from './styles'

interface Props {
  isLoading?: boolean
}

const LoadingbarHorizontal: React.FC<Props> = (props: Props) => {
  const { isLoading = false }: Props = props

  const { classes, cx: classNames } = useStyles()

  return (
    <LoadingBar
      barColor={LoadingBarColor.Primary}
      className={classNames(classes.loadingBar, {
        [classes.loadingBarDisplay]: isLoading,
        [classes.loadingBarNoDisplay]: !isLoading
      })}
      isLoading={isLoading}
    />
  )
}

export default LoadingbarHorizontal
