import { Grid, Skeleton, textStyleUtils } from '@focaldata/cin-ui-components'
import React from 'react'

const SurveyCompletionProgressLoading: React.FC = () => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid direction="row" container justifyContent="center" alignItems="center">
      <p
        className={classNames(
          textClasses.default,
          textClasses.sizeMs,
          textClasses.marginRight
        )}
      >
        Completes:{' '}
      </p>
      <Skeleton height={40} width={120} />
    </Grid>
  )
}

export default SurveyCompletionProgressLoading
