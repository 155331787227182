import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  cardContentStyle: {
    padding: theme.spacing(2),
    minHeight: theme.spacing(9.75)
  },
  cardStyle: {
    cursor: 'pointer',
    borderLeft: `${theme.spacing(0.25)} solid transparent`,
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(1.25),
    width: '100%',
    '&:hover': {
      borderLeft: `${theme.spacing(0.25)} solid ${
        theme.palette.secondary.main
      }`,
      boxShadow: `
        ${theme.spacing(0.25)}
        ${theme.spacing(0.25)}
        ${theme.spacing(1)}
        ${theme.spacing(0.25)}
        rgba(0, 0, 0, 0.1)`
    }
  },
  statusDiv: {
    marginRight: theme.spacing(1)
  },
  listItemStyle: {
    width: '100%',
    paddingBottom: theme.spacing(1)
  },
  projectDetails: {
    marginTop: theme.spacing(0.5)
  },
  projectItem: {
    maxWidth: '25%'
  },
  noTextDecoration: {
    textDecoration: 'none'
  },
  projectTypeLabel: {
    borderRadius: theme.spacing(2),
    borderColor: additionalPalette.grey.main,
    margin: theme.spacing(0, 1),
    fontWeight: 'normal'
  }
}))

export default useStyles
