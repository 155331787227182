import axios, { AxiosResponse, CancelToken } from 'axios'
import { useEffect, useState } from 'react'
import { ChartEntry } from '../data/model/chart'
import { SelectedFilter, WeightingScheme } from '../data/model/results'
import { isURL } from '../utils/HelperFunctions'
import { AccessToken } from '../utils/authUtils'
import requestWithRetry from '../utils/requestWithRetry'

const SUMMARY_ENDPOINT = `${
  process.env.REACT_APP_URL_SURVEY_RESULTS || '/v2/survey-results'
}/api/v1.0/summary`

interface SummaryData {
  data: ChartEntry[]
  weightingScheme: WeightingScheme | undefined
  error: boolean
  loading: boolean
}

interface SummaryApiData {
  results: ChartEntry[]
  weightingScheme: WeightingScheme
  totalChartsCount: number | null
}

const useSummaryData = (
  surveyId: string,
  filters?: SelectedFilter[]
): SummaryData => {
  const [chartData, setChartData] = useState<ChartEntry[]>([])
  const [weightingScheme, setWeightingScheme] = useState<WeightingScheme>()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    setLoading(true)

    getSummaryData(surveyId, undefined, undefined, filters, source.token)
      .then((data) => {
        if (data) {
          setError(false)
          setChartData(data.results)
          setWeightingScheme(data.weightingScheme)
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      source.cancel('Filter cancel')
    }
  }, [filters, surveyId])

  return { data: chartData, weightingScheme, error, loading }
}

export default useSummaryData

export const getSummaryData = async (
  surveyId: string,
  offset?: number,
  limit?: number,
  filters?: SelectedFilter[],
  cancelToken?: CancelToken
) => {
  const responseFilters = filters?.filter((filter) => !!filter.questionId) || []
  const forkFilters =
    filters
      ?.filter((filter) => !!filter.forkId)
      .map((fl) => {
        return {
          ...fl,
          branchNumbers: fl.branchNumbers?.map((br) => Number.parseInt(br, 10))
        }
      }) || []
  const postPayload = {
    surveyId,
    responseFilters,
    forkFilters,
    offset,
    limit
  }

  const getConfig = () => {
    return {
      headers: {
        ...AccessToken.requestHeaders(),
        'content-type': 'application/json'
      },
      cancelToken
    }
  }

  const requestUrl = `${
    isURL(SUMMARY_ENDPOINT) ? '' : process.env.REACT_APP_REST_DOMAIN
  }${SUMMARY_ENDPOINT}`

  return requestWithRetry({
    method: 'post',
    url: requestUrl,
    payload: postPayload,
    getConfig
  })
    .then((data: AxiosResponse<SummaryApiData>) => {
      return data.data
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error('Error fetching summary data')
      }
    })
}
