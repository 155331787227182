import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { LogAmplitudeEvent } from '../../../../../amplitude'
import { EventType } from '../../../../../amplitude/eventType'
import ResultsOptionsControl from '../../../../../controls/ResultsOptions'
import { SurveyState } from '../../../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../../../data/logger'
import { SelectedFilter } from '../../../../../data/model/results'
import useDownloadSurveyDoc from '../../../../../hooks/useDownloadSurveyDoc'
import useGetSurveyFieldwork from '../../../../../hooks/useGetSurveyFieldwork'
import { getSummaryData } from '../../../../../hooks/useSummaryData'
import { useSurveyId } from '../../../../../hooks/useSurveyId'
import DuplicateProject from '../../../../../modules/Project/DuplicateProject'
import DialogEndProject from '../../../../../modules/Project/EndProject'
import FollowUp from '../../../../../modules/Project/FollowUp'
import PauseProject from '../../../../../modules/Project/PauseProject'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData
} from '../../../../../modules/Project/Project.query'
import RequestData from '../../../../../modules/Project/RequestData'
import RequestRawData from '../../../../../modules/Project/RequestRawData/RequestRawData'
import DialogExportToPowerPoint from '../../../../../modules/Results/DialogExportToPowerPoint/DialogExportToPowerPoint'
import { DialogPublishResults } from '../../../../../modules/Results/DialogPublishResults'
import {
  captureApolloError,
  handleShowPreview
} from '../../../../../utils/HelperFunctions'
import { downloadXlsxFile } from '../../../../../utils/chartUtils'
import { downloadPptxFile } from '../../../../../utils/pptxUtils'
import { isMultiMarketProjectType } from '../../../../../utils/projectUtils'
interface Props {
  disableShowQuotas: boolean
  surveyState: SurveyState
  onShowQuotasChange: (showQuotas: boolean) => void
  selectedFilters: SelectedFilter[]
}

const ResultsOptions: React.FC<Props> = (props: Props) => {
  const surveyId = useSurveyId()
  const {
    surveyState,
    disableShowQuotas = false,
    onShowQuotasChange,
    selectedFilters
  } = props
  const [isDialogFollowUpOpen, setIsDialogFollowUpOpen] =
    useState<boolean>(false)
  const [isDialogDataTablesOpen, setIsDialogDataTablesOpen] =
    useState<boolean>(false)
  const [isDialogRawDataOpen, setIsDialogRawDataOpen] = useState<boolean>(false)
  const [isDialogDuplicateOpen, setIsDialogDuplicateOpen] =
    useState<boolean>(false)
  const [isDialogPauseOpen, setIsDialogPauseOpen] = useState<boolean>(false)
  const [isEndDialogueOpen, setIsEndDialogueOpen] = useState<boolean>(false)
  const [isExportToPptxDialogOpen, setIsExportToPptxDialogOpen] =
    useState<boolean>(false)
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState<boolean>(false)
  const { data: projectData } = useQuery<ProjectData>(PROJECT_BY_SURVEY_ID, {
    context: { clientName: 'dashboard' },
    variables: {
      surveyId
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'projectBySurveyId',
        error
      )
    }
  })

  const { exportSurvey } = useDownloadSurveyDoc()

  const { readSurveyFieldwork } = useGetSurveyFieldwork()

  const selectedProject = projectData?.project
  const isMultiMarket =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    projectData && isMultiMarketProjectType(projectData?.project.projectType)

  const handleOnClickFollowUp: () => void = () => {
    setIsDialogFollowUpOpen(!isDialogFollowUpOpen)
  }
  const handleClickDownloadChartData: () => void = () => {
    getSummaryData(surveyId, undefined, undefined, selectedFilters).then(
      (data) => {
        if (data && data.results.length) {
          downloadXlsxFile({
            data: data.results,
            fileName: `${projectData?.project.name}.xlsx`
          })

          LogAmplitudeEvent(EventType.DownloadedChartData, { surveyId })
        }
      }
    )
  }

  const openExportToPptxDialog = (): void => {
    setIsExportToPptxDialogOpen(true)
  }

  const handleExportToPptx = (): void => {
    getSummaryData(surveyId, undefined, undefined, selectedFilters).then(
      (data) => {
        const surveyFiledwork = readSurveyFieldwork()

        if (data && data.results.length && surveyFiledwork) {
          downloadPptxFile(
            data.results,
            surveyFiledwork.fieldworkDates,
            projectData?.project.name
          )

          LogAmplitudeEvent(EventType.DownloadAsPptx, { surveyId })
          setIsExportToPptxDialogOpen(false)
        }
      }
    )
  }
  const handleOnClickRawData: () => void = () => {
    setIsDialogRawDataOpen(!isDialogRawDataOpen)
  }
  const handleOnClickDataTables: () => void = () => {
    setIsDialogDataTablesOpen(!isDialogDataTablesOpen)
  }

  const handleDuplicate: () => void = () => {
    if (!isDialogDuplicateOpen) {
      LogAmplitudeEvent(EventType.DuplicatedProject, {
        surveyId,
        eventSource: 'results_page'
      })
    }
    return setIsDialogDuplicateOpen(!isDialogDuplicateOpen)
  }
  const handleClickPause: () => void = () => {
    LogAmplitudeEvent(EventType.PausedProject, {
      surveyId,
      eventSource: 'results_page'
    })

    setIsDialogPauseOpen(!isDialogPauseOpen)
  }
  const handleClickEnd: () => void = () => {
    return setIsEndDialogueOpen(!isEndDialogueOpen)
  }
  const handleClickPreview: () => void = () => {
    handleShowPreview(surveyId)
  }
  const handleClickPublishResults = (): void => {
    setIsPublishDialogOpen(true)
  }

  return (
    <>
      <ResultsOptionsControl
        onClickPause={handleClickPause}
        onClickEnd={handleClickEnd}
        onClickDuplicate={handleDuplicate}
        onClickPreview={handleClickPreview}
        onClickExportSurvey={exportSurvey}
        onClickChartData={handleClickDownloadChartData}
        onClickRawData={handleOnClickRawData}
        onClickDataTables={handleOnClickDataTables}
        onShowQuotasChange={onShowQuotasChange}
        onClickFollowUp={handleOnClickFollowUp}
        onClickPublishResults={handleClickPublishResults}
        onClickExportToPptx={openExportToPptxDialog}
        surveyState={surveyState}
        disableShowQuotas={disableShowQuotas}
        isMultiMarket={isMultiMarket}
      />
      <FollowUp
        isDialogFollowUpOpen={isDialogFollowUpOpen}
        setIsDialogFollowUpOpen={setIsDialogFollowUpOpen}
      />
      <RequestData
        isDialogDataTablesOpen={isDialogDataTablesOpen}
        setIsDialogDataTablesOpen={setIsDialogDataTablesOpen}
      />
      <RequestRawData
        isDialogOpen={isDialogRawDataOpen}
        setIsDialogOpen={setIsDialogRawDataOpen}
      />
      <DuplicateProject
        isDialogDuplicateOpen={isDialogDuplicateOpen}
        setIsDialogDuplicateOpen={setIsDialogDuplicateOpen}
        selectedProject={selectedProject}
      />
      <PauseProject
        isOpen={isDialogPauseOpen}
        toggleIsOpen={setIsDialogPauseOpen}
        selectedProject={selectedProject}
      />
      <DialogEndProject
        isOpen={isEndDialogueOpen}
        toggleIsOpen={setIsEndDialogueOpen}
        selectedProject={selectedProject}
      />
      <DialogExportToPowerPoint
        isDialogOpen={isExportToPptxDialogOpen}
        onExportToPptx={handleExportToPptx}
        onClose={() => setIsExportToPptxDialogOpen(false)}
      />
      <DialogPublishResults
        isDialogOpen={isPublishDialogOpen}
        onClose={() => setIsPublishDialogOpen(false)}
      />
    </>
  )
}

export default ResultsOptions
