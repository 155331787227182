import {
  Grid,
  Skeleton,
  Text,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React from 'react'

const AudiencePresetSelectLoading: React.FC = () => {
  return (
    <>
      <Grid item>
        <Text size={TextSize.m} weight={TextWeight.SemiBold}>
          Audience
        </Text>
      </Grid>
      <Grid item>
        <Skeleton width={345} height={45} />
      </Grid>
    </>
  )
}

export default AudiencePresetSelectLoading
