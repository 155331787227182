import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { LoggerErrorType } from '../data/logger'
import { DataFormat, OutputType } from '../data/model/shared'
import { SurveyParams } from '../data/model/surveyParams'
import {
  PROJECT_BY_SURVEY_ID,
  ProjectData
} from '../modules/Project/Project.query'
import { captureApolloError } from '../utils/HelperFunctions'
import UserSession from '../utils/UserSession'
import axios from '../utils/axiosWithRetry'
import useGetUserAndAccount from './useGetUserAndAccount'

const slackNotifierURI = process.env.REACT_APP_SLACK_NOTIFIER || ''

interface Data {
  handleRequestData: (
    dataFormat: DataFormat,
    outputType: OutputType,
    isDataTables: boolean
  ) => Promise<string>
}

const useSlackNotification: () => Data = () => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const email = UserSession.getEmail() || 'email N/A'

  const { userName, account } = useGetUserAndAccount()

  const { data: dataProject } = useQuery<ProjectData>(PROJECT_BY_SURVEY_ID, {
    variables: {
      surveyId
    },
    context: { clientName: 'dashboard' },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloQuery,
        'projectBySurveyId',
        error
      )
    }
  })

  const projectTitle = dataProject?.project.name || 'projectTitle N/A'

  const accountName = account || 'account name N/A'

  const handleRequestData = async (
    dataFormat: DataFormat,
    outputType: OutputType,
    isDataTables: boolean
  ) => {
    const requestType = isDataTables ? 'Data Tables' : 'Raw data'

    const messageData = {
      text: `
        ${userName} from ${accountName} requested ${requestType} for ${projectTitle}\n
        Email: ${email}\n
        Project: ${projectTitle}, ${surveyId}\n
        Request: ${requestType}, ${dataFormat}, ${outputType}
      `
    }

    const res = await axios.post(
      slackNotifierURI,
      JSON.stringify(messageData),
      {
        withCredentials: false,
        transformRequest: [
          (data) => {
            return data
          }
        ]
      }
    )
    if (res.status === 200) {
      return res.data
    }

    throw new Error()
  }

  return { handleRequestData }
}

export default useSlackNotification
