import { PinResponse } from '@focaldata/cin-ui-components'
import React from 'react'
import { PinnedItemType } from './PinCustomAudienceResponse.model'

interface Props {
  itemType: PinnedItemType
  isPinned: boolean
  handleTogglePinned: () => void
}

const getTooltipMessage = (
  itemType: PinnedItemType,
  pinned: boolean
): string => {
  switch (itemType) {
    case PinnedItemType.ResponseOption:
    default: {
      if (pinned) {
        return 'Unpin response option'
      }

      return 'Pin response option'
    }
  }
}

const PinCustomAudienceResponseContainer: React.FC<Props> = ({
  itemType,
  isPinned,
  handleTogglePinned
}: Props) => {
  return (
    <PinResponse
      tooltip={getTooltipMessage(itemType, isPinned)}
      onTogglePinned={handleTogglePinned}
    />
  )
}

export default PinCustomAudienceResponseContainer
