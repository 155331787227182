import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const REMOVE_RESPONSE_OPTION = questionnaireGql(/* GraphQL */ `
  mutation removeResponseOptionV2($input: RemoveResponseOptionV2Input!) {
    removeResponseOption: removeResponseOptionV2(input: $input) {
      ...DraftEntryResponseOption
    }
  }
`)

export const REMOVE_RESPONSE_OPTION_WITH_SETTINGS_UPDATE = questionnaireGql(
  /* GraphQL */ `
    mutation removeResponseOptionWithSettingsUpdate(
      $removeResponseOptionInput: RemoveResponseOptionV2Input!
      $updateQuestionnaireEntryInput: UpdateQuestionnaireEntryV2Input!
    ) {
      removeResponseOption: removeResponseOptionV2(
        input: $removeResponseOptionInput
      ) {
        ...DraftEntryResponseOption
      }
      updateQuestionnaireEntryV2(input: $updateQuestionnaireEntryInput) {
        ...DraftQuestionnaireEntry
      }
    }
  `
)
