import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'

export interface StyleProps {
  isSelected: boolean | undefined
}

const useStyles = makeStyles<{ styleProps?: StyleProps }>()((
  theme,
  { styleProps: props }
) => {
  const { palette, typography } = theme
  const sectionTitle = {
    fontFamily: typography.fontFamily,
    fontSize: typography.body1.fontSize,
    fontWeight: typography.fontWeightRegular as any,
    color: palette.text.secondary,
    margin: 0,
    lineHeight: 1.5
  }
  const backgroundColorDefault = {
    backgroundColor: theme.palette.background.default
  }
  const sectionText = {
    color: palette.text.primary,
    fontSize: typography.subtitle2.fontSize,
    fontWeight: typography.fontWeightMedium as any
  }
  const infoContainer = {
    width: '100%',
    padding: theme.spacing(1.5, 0, 1.5, 2.25)
  }

  return {
    listItemSection: {
      backgroundColor: additionalPalette.lightBlue.main,
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      marginTop: theme.spacing(0.75),
      '&[class*=-sectionItemSelected]': {
        backgroundColor: theme.palette.background.default
      }
    },
    listItemSectionDragging: {
      backgroundColor: theme.palette.background.default,
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      '& svg:not(:first-of-type)': {
        color: additionalPalette.lightGrey.main
      },
      '& .MuiListItem-root': {
        backgroundColor: theme.palette.background.default
      }
    },
    draggableDiv: {
      width: '100%',
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      backgroundColor: props?.isSelected
        ? theme.palette.background.default
        : additionalPalette.transparent,
      '&:hover': {
        backgroundColor: theme.palette.background.default
      },
      '&:focus': {
        outline: 0
      }
    },
    nonDraggableDiv: {
      width: '100%',
      borderRadius: `${theme.spacing(2.75)} 0px 0px ${theme.spacing(2.75)}`,
      backgroundColor: props?.isSelected
        ? theme.palette.background.default
        : additionalPalette.transparent,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer'
      },
      '&:focus': {
        outline: 0
      }
    },
    dragHandle: {
      color: additionalPalette.lightBlue.main
    },
    infoContainer,
    icon: {
      color: theme.palette.error.main
    },
    sectionTitle,
    sectionItemError: {
      color: palette.error.main
    },
    sectionItemSelected: {
      color: palette.secondary.main
    },
    sectionContainer: {
      borderRadius: theme.spacing(2.75, 0, 0, 2.75),
      '&[class*=-sectionItemSelected]': {
        ...backgroundColorDefault,
        '& [class*=-draggableDiv]': {
          ...backgroundColorDefault
        },
        '& [class*=sectionHeaderStyle] svg': {
          color: additionalPalette.lightGrey.main
        },
        '& [class*=-listItem] svg:not([class*=-error])': {
          color: additionalPalette.lightGrey.main
        }
      },
      '&:hover': {
        ...backgroundColorDefault,
        '& [class*=sectionHeaderStyle] svg': {
          color: additionalPalette.lightGrey.main
        },
        '& [class*=-listItem] svg:not([class*=-error])': {
          color: additionalPalette.lightGrey.main
        },
        '& [class*=listStyle] [class*=draggableDiv]': {
          ...backgroundColorDefault,
          '&:hover .MuiGrid-root:last-child svg:not(:first-of-type)': {
            color: palette.text.primary
          }
        }
      }
    },
    sectionHeader: {
      padding: 0,
      '& [class*=sectionTitle]': {
        ...sectionText
      }
    },
    sectionHeaderStyle: {
      borderTop: theme.spacing(0.125),
      borderTopStyle: 'solid',
      borderTopColor: palette.secondary.dark,
      borderBottom: theme.spacing(0.125),
      borderBottomStyle: 'solid',
      borderBottomColor: palette.secondary.light,
      fontWeight: typography.fontWeightMedium as any,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      marginLeft: theme.spacing(2.25)
    },
    sectionFooter: {
      textTransform: 'uppercase',
      '& [class*=sectionTitle]': {
        ...sectionText
      }
    },
    sectionFooterStyle: {
      borderBottom: theme.spacing(0.125),
      borderBottomStyle: 'solid',
      borderBottomColor: palette.secondary.light,
      padding: theme.spacing(1.5, 0),
      marginLeft: theme.spacing(2.25)
    }
  }
})

export default useStyles
