import {
  Grid,
  Text,
  TextHighlight,
  TextSize
} from '@focaldata/cin-ui-components'

import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import useStyles from './DialogDeleteProject.styles'

const DialogDeleteProject = ({
  projectType
}: {
  projectType?: ProjectType
}) => {
  const { classes } = useStyles()

  return (
    <Grid container direction="column">
      <Grid item>
        <Text
          size={TextSize.ml}
          className={classes.infoDuplicate}
          highlight={TextHighlight.Background}
        >
          This will delete the project, along with the following:
          <ul>
            {projectType === ProjectType.MultiMarketV2 ? (
              <li>All the markets</li>
            ) : (
              <li>The audience you&apos;ve chosen</li>
            )}

            <li>The survey questions you&apos;ve scripted</li>
          </ul>
        </Text>
      </Grid>
    </Grid>
  )
}

export default DialogDeleteProject
