import {
  Grid,
  InfoIcon,
  Select,
  SelectVariant,
  Text,
  TextSize,
  TextWeight,
  TooltipPlacement
} from '@focaldata/cin-ui-components'
import React from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { SurveyParams } from '../../../data/model/surveyParams'
import {
  AudiencePreset,
  CUSTOM_AUDIENCE_PRESET_NAME
} from '../../../hooks/audience/usePresetAudience'
import { isDemoUser } from '../../../utils/authUtils'
import { getPresetsSelectItems } from '../utils'

interface Props {
  presets: AudiencePreset[]
  selectedPresetName?: string
  isSelectedPresetWeighted?: boolean
  onPresetChosen: (newAudiencePreset: AudiencePreset) => void
}

const AudiencePresetSelect: React.FC<Props> = (props: Props) => {
  const {
    presets,
    selectedPresetName,
    isSelectedPresetWeighted,
    onPresetChosen
  }: Props = props
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const newOptions = getPresetsSelectItems(presets)

  React.useEffect(() => {
    if (isDemoUser()) {
      const preset = presets.find((pr) => pr.name === selectedPresetName)

      if (preset) {
        onPresetChosen(preset)
      }
    }
  }, [onPresetChosen, presets, selectedPresetName])

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    const preset = presets.find(
      (pr) => pr.presetAudienceId === event.target.value
    )
    if (preset) {
      onPresetChosen(preset)

      LogAmplitudeEvent(
        preset.name === CUSTOM_AUDIENCE_PRESET_NAME
          ? EventType.SelectedCustomAudience
          : EventType.SelectedPresetAudience,
        {
          surveyId,
          oldPresetAudience: selectedPresetName,
          selectedPresetAudience: preset.name,
          isWeighted: preset.isWeighted
        }
      )
    }
  }

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs="auto">
          <Text size={TextSize.m} weight={TextWeight.SemiBold}>
            Audience
          </Text>
        </Grid>
        <Grid item xs="auto">
          <InfoIcon
            tooltipPlacement={TooltipPlacement.Top}
            text={
              isDemoUser()
                ? 'Complex audiences available on request'
                : "Select one of our preset audiences. Then make any changes in the editor. Or start from scratch with a custom audience. Please email support@focaldata.com if you need help setting up your audience or want to add other preset audiences to the list. We'll respond immediately. "
            }
          />
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Select
          ariaLabel="Audience preset select"
          variant={SelectVariant.Filled}
          defaultOptionValue={selectedPresetName}
          optionValue={selectedPresetName}
          maxMenuHeight={300}
          optionSuffix={isSelectedPresetWeighted ? 'Weighted' : undefined}
          options={newOptions}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </>
  )
}

export default AudiencePresetSelect
