import { useEffect, useState } from 'react'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import CompleteChartList from '../../../../controls/CompleteChartList'
import { useCompleteChartListData } from '../../../../controls/CompleteChartList/CompleteChartList.hooks'
import QuotaCards from '../../../../controls/QuotaCards'
import { SurveyState } from '../../../../data/gql-gen/dashboard/graphql'
import { SelectedFilter } from '../../../../data/model/results'
import { useFieldworkAudience } from '../../../../hooks/audience/useFieldworkAudience'
import useGetSurveyQuotas from '../../../../hooks/useGetSurveyQuotas'
import useGetSurveyState from '../../../../hooks/useGetSurveyState'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import ResultsPageLayout from '../../../../layouts/ResultsPageLayout'
import { TablePagination } from './DefaultMuiTablePagination'
import { ResultsFilters } from './ResultsFilters'
import { ResultsOptions } from './ResultsOptions'

export const ITEMS_PER_PAGE = 50

const Results = () => {
  const surveyId = useSurveyId()
  const [showQuotas, setShowQuotas] = useState<boolean>()
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [page, setPage] = useState(0)
  const { chartData, totalCount, summaryDataLoading } =
    useCompleteChartListData(surveyId, selectedFilters, page, rowsPerPage)

  const { fieldworkAudience } = useFieldworkAudience()

  const areQuotasApplied = !!fieldworkAudience?.quotas.length
  const {
    getSurveyQuotas,
    quotaResults,
    loading: quotaResultsLoading
  } = useGetSurveyQuotas({
    surveyId
  })
  const quotaResultsAndType = quotaResults?.map((quotaResult) => {
    return {
      ...quotaResult,
      type: chartData.find((sdEntity) => sdEntity.id === quotaResult.id)
        ?.questionType
    }
  })
  const { surveyState = SurveyState.Live } = useGetSurveyState()

  const disableShowQuotas = !areQuotasApplied

  useEffect(() => {
    LogAmplitudeEvent(EventType.ViewedResultsPage, { surveyId })
  }, [surveyId])

  const handleOnShowQuotasChange = (showQuotas: boolean) => {
    if (showQuotas && quotaResultsAndType === undefined) {
      getSurveyQuotas(surveyId)
    }
    setShowQuotas(showQuotas)
  }

  const handleUpdateFilters = (filters: SelectedFilter[]) => {
    setSelectedFilters(filters)
    setPage(0)
  }

  const paginationControl = chartData.length ? (
    <TablePagination
      count={totalCount}
      labelRowsPerPage="Charts per page"
      page={page}
      onPageChange={(e, page) => setPage(page)}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
      showFirstButton
      showLastButton
    />
  ) : undefined

  return (
    <ResultsPageLayout
      isLoading={summaryDataLoading}
      optionsBar={
        <ResultsOptions
          disableShowQuotas={disableShowQuotas}
          surveyState={surveyState}
          onShowQuotasChange={handleOnShowQuotasChange}
          selectedFilters={selectedFilters}
        />
      }
      resultCards={
        <>
          {showQuotas && (
            <QuotaCards
              quotaResults={quotaResultsAndType}
              quotaResultsLoading={quotaResultsLoading}
              areQuotasApplied={areQuotasApplied}
            />
          )}
          {paginationControl}
          <CompleteChartList
            chartData={chartData}
            isLoading={summaryDataLoading}
            showAudienceCriteriaQuestions
          />
          {paginationControl}
        </>
      }
      filters={
        <ResultsFilters
          selectedFilters={selectedFilters}
          setSelectedFilters={handleUpdateFilters}
        />
      }
    />
  )
}

export default Results
