import {
  Grid,
  Paper,
  PaperGroupItem,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import {
  BarChart,
  BarChartData,
  GroupedChart,
  MeanChart,
  StackedBarChart,
  WordCloudChart
} from '../../components/Chart'
import {
  ChartEntry,
  EntryType,
  QuestionKind,
  QuestionType
} from '../../data/model/chart'
import useMediaUrlAndThumbnailUrl from '../../data/rest/media/media'
import {
  convertEntryDataOrFilteredRowsToBarChartData,
  convertEntryDataToGroupedChartData,
  convertEntryDataToMeanChartData,
  convertEntryDataToWordCloudChartData,
  convertMatrixEntryDataToStackedChartData,
  convertRankedEntryDataDefaultOptionsToBarChartData,
  convertRankedEntryDataToStackedChartData,
  pluralise
} from '../../utils/chartUtils'
import { matrixHasDifferentRowCounts } from '../../utils/resultsUtils'
import ResultsChartHeader from '../ResultsChartHeader'
import useStyles from './styles'

export interface Props {
  entry: ChartEntry
  positionLabel?: string
  chartTitle?: string
  completes?: number
}

const getQuestionLabelForEntryType: (entry: ChartEntry) => string = (entry) => {
  switch (entry.entryType) {
    case EntryType.MatrixEntryType:
      return entry.isMultipleChoice
        ? 'Matrix multiple select'
        : 'Matrix single select'
    case EntryType.QuestionEntryType:
      switch (entry.questionType) {
        case QuestionType.ranked:
          return 'Ranked question'
        case QuestionType.free_text:
          return 'Free text'
        case QuestionType.scale:
          return 'Slider'
        case QuestionType.max_diff:
          return 'Max Diff'
        case QuestionType.basic:
        default:
          return entry.isMultipleChoice ? 'Multi select' : 'Single select'
      }
    default:
      return 'Single select'
  }
}

const DefaultOptionsAsText = ({
  defaultOptionsBarChartData
}: {
  defaultOptionsBarChartData: BarChartData
}) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const defaultOptionsAsText = defaultOptionsBarChartData.labels.map(
    (label, index) => {
      const numOfResponses =
        defaultOptionsBarChartData.datasets[0].data[index] *
        defaultOptionsBarChartData.totalSamples
      const responseText = pluralise(numOfResponses, 'response')
      return (
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line react/jsx-key
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeS,
            textClasses.displayBlock
          )}
          key={label.toString()}
        >
          {label}: {numOfResponses.toFixed()} {responseText}
        </p>
      )
    }
  )

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return defaultOptionsBarChartData &&
    defaultOptionsBarChartData.labels.length > 0 ? (
    <>{defaultOptionsAsText}</>
  ) : null
}

const getChartForEntryType: (entry: ChartEntry) => JSX.Element | undefined = (
  entry
) => {
  if (
    entry.entryType === EntryType.MatrixEntryType &&
    !entry.isMultipleChoice
  ) {
    const convertedData = convertMatrixEntryDataToStackedChartData(entry)
    return (
      <Grid container direction="row">
        <StackedBarChart data={convertedData} />
      </Grid>
    )
  }
  if (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.ranked
  ) {
    const responseOptionsBarChartData =
      convertRankedEntryDataToStackedChartData(entry)
    const defaultOptionsBarChartData =
      convertRankedEntryDataDefaultOptionsToBarChartData(entry)

    return (
      <>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {responseOptionsBarChartData &&
          responseOptionsBarChartData.datasets.length > 0 && (
            <Grid container direction="row">
              <StackedBarChart data={responseOptionsBarChartData} />
            </Grid>
          )}
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {responseOptionsBarChartData &&
          defaultOptionsBarChartData.datasets.length > 0 && (
            <Grid container direction="column">
              <DefaultOptionsAsText
                defaultOptionsBarChartData={defaultOptionsBarChartData}
              />
            </Grid>
          )}
      </>
    )
  }
  if (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.scale
  ) {
    return <MeanChart data={convertEntryDataToMeanChartData(entry)} />
  }
  if (
    (entry.entryType === EntryType.QuestionEntryType &&
      entry.questionType === QuestionType.basic) ||
    entry.questionKind === QuestionKind.Audience
  ) {
    const convertedData = convertEntryDataOrFilteredRowsToBarChartData(entry)

    return (
      <Grid container direction="row">
        <BarChart data={convertedData} colorIndex={entry.entryPosition} />
      </Grid>
    )
  }
  if (entry.entryType === EntryType.MatrixEntryType && entry.isMultipleChoice) {
    const convertedData = convertEntryDataToGroupedChartData(entry)
    return (
      <Grid container direction="row">
        <GroupedChart data={convertedData} />
      </Grid>
    )
  }
  if (
    entry.entryType === EntryType.QuestionEntryType &&
    entry.questionType === QuestionType.free_text &&
    entry.questionKind === QuestionKind.Questionnaire
  ) {
    const data = convertEntryDataToWordCloudChartData(entry)
    if (data.words.length === 0) return undefined
    return <WordCloudChart data={data} />
  }
  return undefined
}

const BasicQuestionChartCard: React.FC<Props> = (props: Props) => {
  const { positionLabel, chartTitle, completes, entry }: Props = props
  const { textStyling } = entry
  const { classes } = useStyles()
  const questionLabel: string = getQuestionLabelForEntryType(entry)
  const { resizedMediaUrl, resizedThumbnailUrl } = useMediaUrlAndThumbnailUrl(
    entry.mediaUrl,
    entry.mediaType
  )

  const matrixWithDifferentRowCounts =
    entry.entryType === EntryType.MatrixEntryType &&
    entry.isMultipleChoice &&
    matrixHasDifferentRowCounts(entry)

  return (
    <Grid container className={classes.root}>
      <Paper>
        <ResultsChartHeader
          positionLabel={positionLabel}
          questionLabel={questionLabel}
          chartTitle={chartTitle}
          chartRawTitle={textStyling}
          completes={matrixWithDifferentRowCounts ? undefined : completes}
          mediaType={entry.mediaType}
          mediaUrl={resizedMediaUrl}
          thumbnailUrl={resizedThumbnailUrl}
        />
        {entry.samplesCollected > 0 && (
          <PaperGroupItem>{getChartForEntryType(entry)}</PaperGroupItem>
        )}
      </Paper>
    </Grid>
  )
}

export default memo(BasicQuestionChartCard)
