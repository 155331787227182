import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  buttonRoot: {
    padding: theme.spacing(0.5, 1.5, 0.5, 2.5)
  },
  buttonRootTransparent: {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default'
    }
  },
  list: {
    padding: 0
  },
  menu: {
    borderRadius: theme.spacing(0.25),
    marginTop: 1
  },
  menuButton: {
    borderRadius: 0,
    border: 'none',
    '&.MuiButton-outlinedSecondary.Mui-disabled': {
      border: 'none'
    },
    '&:hover': {
      border: 'none'
    },
    height: '100%'
  },
  option: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  optionText: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  leftSubtitle: {
    paddingRight: theme.spacing(1),
    textTransform: 'none'
  },
  title: {
    overflow: 'hidden',
    textTransform: 'none'
  },
  listItem: {
    '&.MuiListItem-root': {
      padding: 0
    }
  },
  error: {
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(0.75, 0.25, 0.75, 0.75),
    borderRadius: theme.spacing(0.25),
    cursor: 'pointer'
  },
  errorText: {
    color: theme.palette.background.default
  }
}))

export default useStyles
