import amplitude from 'amplitude-js'
import { GetAccountByAccountId } from '../data/rest/accountUser'
import UserSession from '../utils/UserSession'
import { AccessToken, isFocaldataUser } from '../utils/authUtils'

export const InitAmplitude = () => {
  const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY

  if (apiKey) {
    amplitude.getInstance().init(apiKey)
  }
}

export const SetUserId = (currentUserId?: string | null) => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    const userEmail = currentUserId || UserSession.getEmail()
    if (userEmail) {
      amplitude.getInstance().setUserId(userEmail)
    }
  }
}
// TODO: refactor this after some time.
// Once access_token will expire those properties will be set durin login
export const SetUserProperties = (
  currentAccountId?: string,
  currentAccountName?: string,
  callback?: () => void
) => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    const accountId = currentAccountId || UserSession.maybeGetAccountId()
    const accountName = currentAccountName || UserSession.getAccountName()
    const source = 'dashboard'
    const token = AccessToken.get()
    // If user has an access_token generated before adding amplitude then accountName is not present in local_storage
    // and we call the account endpoint to get the name for accountId
    if (!accountName && token && accountId) {
      GetAccountByAccountId(token, accountId).then((account) => {
        UserSession.setAccountName(account.data.name)
        amplitude.getInstance().setUserProperties({
          accountId,
          accountName: account.data.name,
          source
        })
        // If LogAmplitudeEvent called this function then callback?.() will log the event (accountId and accountName are set now)
        callback?.()
      })
    } else {
      amplitude.getInstance().setUserProperties({
        accountId,
        accountName,
        source
      })
      callback?.()
    }
  }
}

export const LogAmplitudePublicEvent = (event: string, data?: any) => {
  const userEmail = UserSession.getEmail()
  // Do not try to log event if api key is not set or user has @focaldata.com account
  if (
    process.env.REACT_APP_AMPLITUDE_API_KEY &&
    (!isFocaldataUser() || process.env.REACT_APP_AMPLITUDE_TRACK_ME)
  ) {
    // If user has an access_token generated before adding amplitude some details might not be set
    // otherwise details are set during login so the event can be logged
    if (!amplitude.getInstance().options.userId) {
      SetUserId(userEmail)
      SetUserProperties(undefined, undefined, () =>
        amplitude.getInstance().logEvent(event, data)
      )
    } else {
      amplitude.getInstance().logEvent(event, data)
    }
  }
}

export const LogAmplitudeEvent = (event: string, data?: any) => {
  const userEmail = UserSession.getEmail()
  // Do not log event if the user email is missing in order to gather events only from logged in users
  if (!userEmail) {
    return
  }

  LogAmplitudePublicEvent(event, {
    ...data,
    pageUrl: window.location.href,
    pagePath: window.location.pathname
  })
}
