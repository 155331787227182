import { MutationHookOptions, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import {
  DraftQuestionnaireEntry,
  DuplicateQuestionnaireEntryV2Input,
  DuplicateQuestionnaireEntryV2Mutation
} from '../../data/gql-gen/questionnaire/graphql'
import { DUPLICATE_QUESTIONNAIRE_ENTRY } from '../../data/gql/questionnaire/mutations/duplicateQuestionnaireEntry'
import { LoggerErrorType } from '../../data/logger'
import { scrollToQuestionCard } from '../../modules/Questionnaire/QuestionCommon/QuestionCommon.utils'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftQuestionnaire from './useGetDraftQuestionnaire'

export const useDuplicateQuestionnaireEntry = (
  entryId: string,
  options?: MutationHookOptions
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { refetchQuestionnaire } = useGetDraftQuestionnaire()

  const [duplicateQuestionnaireEntryMutation] =
    useMutation<DuplicateQuestionnaireEntryV2Mutation>(
      DUPLICATE_QUESTIONNAIRE_ENTRY,
      {
        context: { clientName: 'questionnaire' },
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'duplicateQuestionnaireEntryV2',
            error
          )
        },
        ...options
      }
    )

  const duplicateQuestionnaireEntry = useCallback(
    (
      input: Omit<
        DuplicateQuestionnaireEntryV2Input,
        'projectId' | 'surveyId' | 'entryId'
      >,
      options?: MutationHookOptions
    ) => {
      return duplicateQuestionnaireEntryMutation({
        variables: {
          input: {
            projectId,
            surveyId,
            entryId,
            ...input
          }
        },
        onCompleted: async ({ duplicateQuestionnaireEntryV2 }) => {
          const entry = duplicateQuestionnaireEntryV2 as DraftQuestionnaireEntry
          await refetchQuestionnaire()
          scrollToQuestionCard(entry.id)
          questionBeingEditedId(undefined)
          LogAmplitudeEvent(EventType.DuplicatedQuestion, {
            projectId,
            surveyId,
            entryId
          })
        },
        ...options
      })
    },
    [
      duplicateQuestionnaireEntryMutation,
      projectId,
      surveyId,
      entryId,
      refetchQuestionnaire
    ]
  )

  return { duplicateQuestionnaireEntry }
}
