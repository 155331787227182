import { ScrollTo } from '@focaldata/cin-ui-components'
import defer from 'lodash/defer'
import delay from 'lodash/delay'
import { getScrollId } from '../Questionnaire.utils'
import { CONTAINER_ID_QUESTIONNAIRE_CONTENT } from '../constants'

export const scrollToQuestionCard = (
  entryId: string,
  scrollIdSuffix?: string
) => {
  defer(() =>
    delay(
      () =>
        ScrollTo(
          getScrollId(entryId, scrollIdSuffix),
          CONTAINER_ID_QUESTIONNAIRE_CONTENT
        ),
      400
    )
  )
}
