import { useMutation } from '@apollo/client'
import { ClipboardEvent } from 'react'
import {
  PasteAudienceMemberTextFieldsV2Mutation,
  PasteAudienceMemberTextFieldsV2MutationVariables
} from '../../data/gql-gen/questionnaire/graphql'
import PASTE_IN_AUDIENCE_TITLE_V2 from '../../data/gql/questionnaire/mutations/pasteInAudienceTitleV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { splitText } from '../copyPaste/utils'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const usePasteCustomCriterionV2 = (
  questionId: string,
  currentText: string,
  onChange?: (inputText: string) => void
) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [pasteCustomCriterionV2] = useMutation<
    PasteAudienceMemberTextFieldsV2Mutation,
    PasteAudienceMemberTextFieldsV2MutationVariables
  >(PASTE_IN_AUDIENCE_TITLE_V2, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
    onError(error) {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'pasteCustomCriterionV2',
        error
      )
    }
  })

  return (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { title: text, responseOptions: responseOptionValues = [] } =
      splitText(event.clipboardData.getData('text')) ?? {}

    if (text) {
      const textAreaNode = event.target as HTMLTextAreaElement
      const { selectionStart, selectionEnd } = textAreaNode

      const newText = currentText
        .slice(0, selectionStart)
        .concat(text, currentText.slice(selectionEnd))

      onChange?.(newText)
      return pasteCustomCriterionV2({
        variables: {
          input: {
            projectId,
            surveyId,
            memberId: questionId,
            text,
            responseOptionValues
          }
        }
      })
    }
  }
}
