import { useReactiveVar } from '@apollo/client'
import { memo, useContext } from 'react'
import { useMount, useUnmount } from 'react-use'
import { DraftQuestionnaireEntry } from '../../../data/model/questionnaire'
import { questionNewlyCreatedNumber } from '../../../hooks/useResetNewlyCreatedEntry'
import GenericEntryLayout from '../../../layouts/GenericEntryLayout'
import {
  addQuestionTransactionDatadog,
  deleteQuestionTransactionDatadog,
  duplicateQuestionTransactionDatadog
} from '../../../tracking/perf/transactions'
import { AddQuestionMenu } from '../AddQuestionMenu'
import { scrollToQuestionCard } from '../QuestionCommon/QuestionCommon.utils'
import QuestionnaireContext, {
  setSidebarSelectedEntryId
} from '../Questionnaire.context'
import { getNextEntryPosition } from '../Questionnaire.utils'
import { getEntryCard } from './GenericEntry.utils'

interface Props {
  entry: DraftQuestionnaireEntry
}

const GenericEntryContainer = ({ entry }: Props) => {
  const newlyAddedEntryNumber = useReactiveVar(questionNewlyCreatedNumber)
  const { dispatch, questionnaireState } = useContext(QuestionnaireContext)

  useMount(() => {
    // if entry card was mounted just after selection in sidebar
    // this is a workaround to handle wrong position after clicking on entry in sidebar related to different heights between placeholder cards and rendered cards
    if (typeof questionnaireState.sidebarSelectedEntryId === 'string') {
      scrollToQuestionCard(questionnaireState.sidebarSelectedEntryId)
      dispatch(setSidebarSelectedEntryId(undefined))
    }
    addQuestionTransactionDatadog.end()
    duplicateQuestionTransactionDatadog.end()
  })

  useUnmount(() => {
    deleteQuestionTransactionDatadog.end()
  })

  return (
    <GenericEntryLayout
      entryCard={getEntryCard(entry, newlyAddedEntryNumber)}
      bottomActions={
        <AddQuestionMenu
          showSectionMenu={!entry.sectionId}
          position={getNextEntryPosition(entry)}
          sectionId={entry.sectionId}
        />
      }
    />
  )
}

export default memo(GenericEntryContainer)
