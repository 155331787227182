import {
  Option,
  Select,
  SelectIconColor,
  SelectVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'

interface Props {
  maxIterations: number
  maxIterationOptions: Option[]
  onChangeMaxIterations: (maxIterations: number) => void
}

const LoopIterationsSelectorComponent = ({
  maxIterations,
  maxIterationOptions,
  onChangeMaxIterations
}: Props) => {
  return (
    <Select
      variant={SelectVariant.Underlined}
      width={50}
      textSize={TextSize.ms}
      selectedTextHighlight={TextHighlight.Standard}
      selectedTextWeight={TextWeight.SemiBold}
      options={maxIterationOptions}
      optionValue={maxIterations}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeMaxIterations(Number(event.target.value))
      }}
      iconColor={SelectIconColor.Secondary}
    />
  )
}

export default LoopIterationsSelectorComponent
