import { SelectOption } from '@focaldata/cin-ui-components'
import { SettingValue } from '../../../data/model/questionnaire'

export const freeTextStructureOptions: SelectOption[] = [
  {
    id: '0',
    name: 'Free text',
    value: SettingValue.Unstructured.toString()
  },
  {
    id: '1',
    name: 'Postal code',
    value: SettingValue.PostCode.toString()
  },
  {
    id: '2',
    name: 'Email',
    value: SettingValue.Email.toString()
  },
  {
    id: '3',
    name: 'Number',
    value: SettingValue.Numeric1.toString()
  }
]

export const limitOptions: SelectOption[] = [
  {
    id: '0',
    name: 'At most 100 characters (approx 30 words)',
    value: SettingValue.OneHundred
  },
  {
    id: '1',
    name: 'At most 200 characters (approx 50 words)',
    value: SettingValue.TwoHundred
  },
  {
    id: '2',
    name: 'At most 800 characters (approx 200 words)',
    value: SettingValue.EightHundred
  }
]

export const minOptions: SelectOption[] = [
  {
    id: '0',
    name: 'At least 2 characters (e.g. Yes or No responses)',
    value: SettingValue.Two
  },
  {
    id: '1',
    name: 'At least 300 characters (approx 80 words)',
    value: SettingValue.ThreeHundred
  },
  {
    id: '2',
    name: 'At least 500 characters (approx 130 words)',
    value: SettingValue.FiveHundred
  }
]

export const postalCodeValidationOptions: SelectOption[] = [
  {
    id: '0',
    name: 'Sector only',
    infoText:
      'Respondents will be asked to enter their postcode without the last two letters, e.g. N1 6AH -> N1 6, EH8 8AJ -> EH8 8, WC2B 4AB -> WC2B 4',
    value: SettingValue.PostCode
  },
  {
    id: '1',
    name: 'Full',
    infoText:
      'Respondents will be asked to enter their full postcode, e.g. N1 6AH, EH8 8AJ, WC2B 4AB',
    value: SettingValue.PostCodeFull
  }
]

export const INPUT_FORMAT_SELECTOR = 'Input format selector'
export const MIN_CHARACTER_LIMIT_SELECTOR = 'Min character limit selector'
export const MAX_CHARACTER_LIMIT_SELECTOR = 'Max character limit selector'
export const POSTAL_CODE_VALIDATION_SELECTOR = 'Postal code validation selector'
