import { ApolloError } from '@apollo/client'
import { QuestionMediaArgsInput } from '../../../../data/gql-gen/questionnaire/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useUpdateQuestionnaireEntry } from '../../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import { captureApolloError } from '../../../../utils/HelperFunctions'

export const useSetQuestionMedia = (entryId: string) => {
  const { refetchQuestionnaire } = useGetDraftQuestionnaire()

  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entryId, {
    onError: (error: ApolloError) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateQuestionMedia',
        error
      )
    },
    onCompleted: refetchQuestionnaire
  })

  const setQuestionMedia = (questionMedia: QuestionMediaArgsInput | null) =>
    updateQuestionnaireEntry({
      questionMedia: {
        value: questionMedia
      }
    })

  return setQuestionMedia
}
