import { Grid } from '@focaldata/cin-ui-components'
import React from 'react'
import {
  InfoPanel,
  InfoPanelItem,
  InfoPanelVariant
} from '../../../components/InfoPanel'
import { TextHighlight, TextWeight } from '../../../constants/text'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import useStyles from './SurveyStats.styles'

interface Props {
  estimatedDeliveryInDays: number | null | undefined
  isFeasible: boolean | undefined
  invalidAudience: boolean | undefined
  text: string
}

interface ErrorText {
  paragraph1: string
  bulletPoint1: string
  bulletPoint2: string
  bulletPoint3: string
  paragraph2: string
}

const invalidText: ErrorText = {
  paragraph1:
    'Sorry, your survey cannot be launched due to an omission or error in the Audience section. Go back to Edit Targeting and Screening and make sure that:',
  bulletPoint1: 'all your quotas sum to 100%',
  bulletPoint2: 'no individual quota is set at 0%',
  bulletPoint3: 'there are any errors in your qualification settings',
  paragraph2: 'If you need additional support'
}

const infeasibleText: ErrorText = {
  paragraph1:
    "Sorry, your audience is infeasible. This means you can't get the sample size and specifications you want.",
  bulletPoint1: 'Reduce the number of completes',
  bulletPoint2: 'Adjust or remove quotas',
  bulletPoint3: 'Adjust or remove targeting criteria',
  paragraph2: "If you still can't make your target audience feasible"
}

const AudienceErrorPanel: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { invalidAudience, isFeasible, estimatedDeliveryInDays, text } = props

  const { entryHasValidationErrors } = useQuestionnaireValidation()
  const { draftQuestionnaire } = useGetDraftQuestionnaire()
  const entriesWithError = draftQuestionnaire?.audienceEntries
    .filter((entry) => entryHasValidationErrors(entry.id))
    .map((entry) => {
      return {
        entryId: entry.id,
        entryText: `A${entry.contextPosition + 1}`
      }
    })

  const suggestions = !!entriesWithError?.length && (
    <>
      Please check the following audience question cards for errors: <br />
      <>
        {entriesWithError.map(({ entryId, entryText }, i) => (
          <span
            key={`suggestion-error-span-${entryId}`}
            style={{ cursor: 'pointer', color: '#ff0505' }}
            role="button"
            tabIndex={-1}
          >
            {entryText}
            {i === entriesWithError.length - 1 ? '' : ', '}
          </span>
        ))}
      </>
    </>
  )

  const loading =
    isFeasible === undefined ||
    invalidAudience === undefined ||
    estimatedDeliveryInDays === undefined
  const showErrorInfoPanel = invalidAudience || !isFeasible
  let errorText = {
    paragraph1: '',
    bulletPoint1: '',
    bulletPoint2: '',
    bulletPoint3: '',
    paragraph2: ''
  }

  if (invalidAudience) {
    errorText = invalidText
  } else {
    errorText = infeasibleText
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.rightChild}
    >
      <Grid item xs="auto">
        {!loading && showErrorInfoPanel && (
          <InfoPanel title={text} variant={InfoPanelVariant.Error}>
            <InfoPanelItem>
              <Grid container className={classes.feasibilityInfoPanel}>
                <Grid item xs={12} className={classes.paddingBottom}>
                  <p style={{ color: TextHighlight.Background }}>
                    {errorText.paragraph1}
                  </p>
                </Grid>
                <Grid item xs={12} className={classes.paddingLeft}>
                  <p style={{ fontWeight: TextWeight.SemiBold }}>
                    &#x2022; {errorText.bulletPoint1}
                  </p>
                </Grid>
                <Grid item xs={12} className={classes.paddingLeft}>
                  <p style={{ fontWeight: TextWeight.SemiBold }}>
                    &#x2022; {errorText.bulletPoint2}
                  </p>
                </Grid>
                <Grid item xs={12} className={classes.paddingLeft}>
                  <p style={{ fontWeight: TextWeight.SemiBold }}>
                    &#x2022; {errorText.bulletPoint3}
                  </p>
                </Grid>
                {suggestions && (
                  <p
                    className={classes.paddingBottom}
                    style={{ color: TextHighlight.Background }}
                  >
                    {suggestions}
                  </p>
                )}
                <Grid item xs={12} className={classes.paddingTop}>
                  <p style={{ color: TextHighlight.Background }}>
                    {errorText.paragraph2}, please email{' '}
                    <span className={classes.inlineSemiBold}>
                      support@focaldata.com
                    </span>{' '}
                    and we&apos;ll get back to you shortly.
                  </p>
                </Grid>
              </Grid>
            </InfoPanelItem>
          </InfoPanel>
        )}
      </Grid>
    </Grid>
  )
}

export default AudienceErrorPanel
