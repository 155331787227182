import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../App.store'
import { DraftSectionItem } from '../../../components/Section/Section.model'
import {
  DraftQuestionnaire,
  EntryType,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { isSettingEnabled } from '../../../utils/questionnaireUtils'
import { questionnaireLoaded } from '../Questionnaire.slice'
import {
  Section,
  SectionsRandomisationState
} from './SectionsRandomisation.model'

const initialState: SectionsRandomisationState = {
  sections: []
}

const sectionsRandomisationSlice = createSlice({
  name: 'sectionsRandomisation',
  initialState,
  reducers: {
    sectionChecked(
      state: SectionsRandomisationState,
      {
        payload
      }: PayloadAction<{
        id: string
        checked: boolean
      }>
    ) {
      const sectionFound = state.sections.find(({ id }) => id === payload.id)

      if (sectionFound) {
        sectionFound.checked = payload.checked
      }
    },
    randomisedSectionsSet(
      state: SectionsRandomisationState,
      { payload }: PayloadAction<Array<Section['id']>>
    ) {
      state.sections.forEach((section) => {
        const randomisationEnabled = payload.includes(section.id)
        section.sectionsRandomisationEnabled = randomisationEnabled
        section.checked = randomisationEnabled
      })
    },
    sectionsRandomisationRemoved(state: SectionsRandomisationState) {
      state.sections.forEach((section) => {
        section.sectionsRandomisationEnabled = false
        section.checked = false
      })
    },
    sectionsSelectionSetToInitial(state: SectionsRandomisationState) {
      state.sections.forEach((section) => {
        section.checked = section.sectionsRandomisationEnabled
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      questionnaireLoaded,
      (state, { payload }: PayloadAction<DraftQuestionnaire>) => {
        state.sections = payload.entries
          .filter(({ entryType }) => entryType === EntryType.SectionEntryType)
          .map(({ entryItem, contextPosition }) => {
            const { sectionId, settingValues } = entryItem as DraftSectionItem
            const sectionsRandomisationEnabled = isSettingEnabled(
              settingValues,
              QuestionSettingCode.SectionsRandomisation
            )
            return {
              id: sectionId,
              title: `Section ${contextPosition + 1}`,
              sectionsRandomisationEnabled,
              checked: sectionsRandomisationEnabled
            }
          })
      }
    )
  }
})

export const selectSections = (state: RootState): Section[] => {
  return state.sectionsRandomisation.sections
}

export const selectCheckedSectionsIds = (
  state: RootState
): Array<Section['id']> => {
  return state.sectionsRandomisation.sections
    .filter(({ checked }) => checked)
    .map(({ id }) => id)
}

export const selectHasSectionsForRandomisation = (
  state: RootState
): boolean => {
  return selectSections(state).length > 1
}

const selectSectionsRandomisationSections = (state: RootState) =>
  state.sectionsRandomisation.sections

export const selectRandomisedSections = createSelector(
  [selectSectionsRandomisationSections],
  (sections) =>
    sections.filter(
      ({ sectionsRandomisationEnabled }) => sectionsRandomisationEnabled
    )
)

export const {
  sectionChecked,
  randomisedSectionsSet,
  sectionsRandomisationRemoved,
  sectionsSelectionSetToInitial
} = sectionsRandomisationSlice.actions

export default sectionsRandomisationSlice.reducer
