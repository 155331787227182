export const SCROLL_ID_PREFIX = 'scroll-id-'
export const CONTAINER_ID_QUESTIONNAIRE_CONTENT =
  'scrollable-content-questionnaire'
export const CUSTOM_QUESTION_KEY = '-custom-criteria'
export const DEFAULT_CARD_PLACEHOLDER_HEIGHT = 500
export const MAILTO_LINK = 'support@focaldata.com'

export const scaleConfig = {
  Agree1To5: [
    'Strongly disagree',
    'Somewhat disagree',
    'Neutral',
    'Somewhat agree',
    'Strongly agree'
  ]
}
