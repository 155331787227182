import { useContext } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import QuestionnaireContext, {
  closeRandomiseSectionsDialog
} from '../../Questionnaire.context'
import { useSetSectionsRandomisation } from '../SectionsRandomisation.hooks'
import {
  sectionChecked,
  sectionsSelectionSetToInitial,
  selectCheckedSectionsIds,
  selectSections
} from '../SectionsRandomisation.slice'
import { DialogRandomiseSectionsComponent } from './DialogRandomiseSections.component'

export const DialogRandomiseSections = () => {
  const { questionnaireState, dispatch: dispatchToContext } =
    useContext(QuestionnaireContext)
  const { isRandomiseSectionsDialogOpened } = questionnaireState
  const sections = useAppSelector(selectSections)
  const checkedSectionsIds = useAppSelector(selectCheckedSectionsIds)

  const dispatch = useAppDispatch()
  const setSectionsRandomisation = useSetSectionsRandomisation()

  const handleSaveRandomiseSections = () => {
    dispatchToContext(closeRandomiseSectionsDialog())
    setSectionsRandomisation(checkedSectionsIds)
  }

  const handleCloseRandomiseSections = () => {
    dispatchToContext(closeRandomiseSectionsDialog())
    dispatch(sectionsSelectionSetToInitial())
  }

  const handleCheckSection = (id: string, checked: boolean) => {
    dispatch(sectionChecked({ id, checked }))
  }

  return (
    <DialogRandomiseSectionsComponent
      isOpened={isRandomiseSectionsDialogOpened}
      sections={sections}
      hasValidSelection={checkedSectionsIds.length > 1}
      onClose={handleCloseRandomiseSections}
      onSaveRandomiseSections={handleSaveRandomiseSections}
      onCheckSection={handleCheckSection}
    />
  )
}
