import { Respondent } from '@focaldata/askai-client'
import Avatar from '../Avatar/Avatar'
import styles from './RespondentCard.module.css'

export type RespondentCardType = {
  number: number
  onClick?: () => void
  src?: string
} & Respondent

const RespondentCard = ({
  age,
  gender,
  location,
  number,
  onClick,
  src
}: RespondentCardType) => {
  return (
    <button
      className={styles['respondent-card']}
      onClick={onClick}
      data-dd-action-name="Respondent card"
    >
      <Avatar initials={`P${number}`} src={src} />
      <div>
        <p className={styles.name}>Participant #{number}</p>
        {(gender || age || location) && (
          <span className={styles['meta-data']}>
            {[gender, age, location].filter((metadata) => metadata).join(', ')}
          </span>
        )}
      </div>
    </button>
  )
}

export default RespondentCard
