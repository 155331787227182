import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  descriptionTitle: {
    paddingRight: theme.spacing(0.5)
  },
  subtitle: {
    paddingBottom: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(0, 1.5)
  },
  mediaUploader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1)
  }
})

export default useStyles
