import { memo, useContext } from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import { draftQuestionnaireRefetchQuery } from '../../../data/gql/questionnaire/queries'
import { EntryType } from '../../../data/model/questionnaire'
import useDraftQuestionnaireIdCache from '../../../hooks/localState/useDraftQuestionnaireIdCache'
import { useRemoveQuestionnaireEntries } from '../../../hooks/questionnaire/useRemoveQuestionnaireEntries'
import useChildFirstRender from '../../../hooks/useChildFirstRender'
import { useDisplayLogicDetector } from '../../../hooks/useDisplayLogicDetector.hooks'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { deleteQuestionTransactionDatadog } from '../../../tracking/perf/transactions'
import QuestionnaireContext, { unselectCard } from '../Questionnaire.context'
import DeleteQuestionButton from './DeleteQuestionButton.control'

interface Props {
  entryType: EntryType
  entryId: string
}

const DeleteQuestionButtonContainer = ({ entryType, entryId }: Props) => {
  const questionnaireId = useDraftQuestionnaireIdCache()
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const getQPrefixForQsWithDisplayLogicBasedOnQuestion =
    useDisplayLogicDetector()

  const { dispatch } = useContext(QuestionnaireContext)

  const removeQuestionnaireEntries = useRemoveQuestionnaireEntries()

  const handleDeleteQuestionCard = () => {
    deleteQuestionTransactionDatadog.start()
    removeQuestionnaireEntries([entryId], {
      refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
      onCompleted: () => {
        questionBeingEditedId(entryId)
      }
    })
  }

  let dialogTitle = 'Delete this card?'
  let deleteDialogText =
    'Please confirm you want to delete this card. This will permanently remove the card and any routing from it.'

  if (entryType === EntryType.ForkEntryType) {
    dialogTitle = 'Delete this fork?'
    deleteDialogText =
      'Please confirm you want to delete this fork and associated groups. This will permanently remove the fork and the groups plus any display logic connected to them.'
  }

  const {
    setInnerState: setDeleteDialogOpen,
    setOuterState: deleteQuestion,
    innerState: deleteDialogOpen
  } = useChildFirstRender({
    setOuterState: handleDeleteQuestionCard,
    initialState: false
  })

  const handleClickDeleteIcon = () => {
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  const handleClickDeleteQuestion = () => {
    setDeleteDialogOpen(false)
    deleteQuestion()
    dispatch(unselectCard(entryId))

    LogAmplitudeEvent(EventType.DeletedQuestion, {
      surveyId,
      questionnaireId
    })
  }

  const questionsPrefix = entryId
    ? getQPrefixForQsWithDisplayLogicBasedOnQuestion(entryId)
    : []

  if (questionsPrefix.length > 0) {
    deleteDialogText = `Deleting this question will also delete the display logic related to this question at ${questionsPrefix.join(
      ', '
    )}.`
  }

  return (
    <DeleteQuestionButton
      dialogTitle={dialogTitle}
      deleteDialogText={deleteDialogText}
      isDialogOpen={deleteDialogOpen}
      openDialog={handleClickDeleteIcon}
      closeDialog={handleCloseDeleteDialog}
      deleteQuestion={handleClickDeleteQuestion}
    />
  )
}

export default memo(DeleteQuestionButtonContainer)
