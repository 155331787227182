import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  root: {
    marginTop: `-${theme.spacing(6)}`
  },
  button: {
    marginRight: `-${theme.spacing(7)}`,
    position: 'absolute'
  }
})

export default useStyles
