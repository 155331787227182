import { DEFAULT_LOI } from '../modules/Survey/SurveyStats/SurveyStats.utils'
import { useFieldwork } from './useFieldwork'

const useGetLoi = (): number => {
  const { fieldwork } = useFieldwork()
  const loi = fieldwork?.lengthOfInterview.adjusted || DEFAULT_LOI

  return loi
}

export default useGetLoi
