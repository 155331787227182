import { ExternalHyperlink, ImageRun, Paragraph, TextRun } from 'docx'
import {
  DraftQuestionItem,
  DraftTextCardItem,
  MediaType
} from '../../data/model/questionnaire'
import {
  getImageDimensionsAsync,
  getScaledImageDimensions
} from '../imageDimensions'

const MAX_THUMBNAIL_WIDTH = 150
const MAX_THUMBNAIL_HEIGHT = 150
const FULL_SIZE_IMAGE_ARGUMENT = 's0'

const addImageThumbnail = async (mediaUrl: string): Promise<Paragraph[]> => {
  const blob = await (await fetch(mediaUrl).then((r) => r.blob())).arrayBuffer()

  const { height, width } = await getImageDimensionsAsync(mediaUrl)
  const { height: scaledHeight, width: scaledWidth } = getScaledImageDimensions(
    width,
    height,
    MAX_THUMBNAIL_WIDTH,
    MAX_THUMBNAIL_HEIGHT
  )

  const questionImageTextParagraph = new Paragraph({
    children: [new TextRun('Question image:')]
  })
  const mediaInfoParagraph = new Paragraph({
    children: [
      new ImageRun({
        data: blob,
        transformation: {
          width: scaledWidth,
          height: scaledHeight
        }
      })
    ]
  })

  return [questionImageTextParagraph, mediaInfoParagraph]
}

const addVideoUrl = (mediaUrl: string): Paragraph => {
  const mediaInfoParagraph = new Paragraph({
    children: [
      new ExternalHyperlink({
        children: [
          new TextRun('Video URL: '),
          new TextRun({
            text: mediaUrl,
            style: 'Hyperlink'
          })
        ],
        link: mediaUrl
      })
    ]
  })

  return mediaInfoParagraph
}

export const addMediaIfNeeded = async (
  entryItem: DraftQuestionItem | DraftTextCardItem,
  paragraphs: Paragraph[]
) => {
  if (entryItem.questionMedia) {
    const { mediaType, mediaUrl, renderedMediaUrl } = entryItem.questionMedia

    if (mediaType === MediaType.Image) {
      paragraphs.push(
        ...(await addImageThumbnail(
          `${renderedMediaUrl}=${FULL_SIZE_IMAGE_ARGUMENT}`
        ))
      )
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (mediaType === MediaType.Video) {
      paragraphs.push(addVideoUrl(mediaUrl))
    }
  }
}
