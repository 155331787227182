import { useState } from 'react'

import { popFirst } from '../utils/array/popFirst'

interface KeyValuePair<T> {
  key: number
  value: T
}

function synchroniseKeyedArrayState<T>({
  prevValues,
  defaultValues,
  setPrevValues,
  keyedValues,
  setKeyedValues
}: {
  prevValues: T[]
  defaultValues: T[]
  setPrevValues: (newPrevValue: T[]) => void
  keyedValues: KeyValuePair<T>[]
  setKeyedValues: (newEntries: KeyValuePair<T>[]) => void
}): number {
  let nextKey = Math.max(...keyedValues.map(({ key }) => key)) + 1

  if (prevValues !== defaultValues) {
    setPrevValues(defaultValues)

    const newKeyedValues: KeyValuePair<T>[] = []
    let keyedValuesCopy = [...keyedValues]

    for (const value of defaultValues) {
      const [match, remainingKeyedValues] = popFirst(
        keyedValuesCopy,
        (entry) => entry.value === value
      )

      keyedValuesCopy = remainingKeyedValues

      if (match) {
        newKeyedValues.push(match)
        continue
      }

      newKeyedValues.push({ key: nextKey, value })
      nextKey += 1
    }

    if (newKeyedValues.length !== keyedValues.length) {
      setKeyedValues(newKeyedValues)
      return nextKey
    }

    for (let i = 0; i < newKeyedValues.length; i++) {
      if (newKeyedValues[i].value !== keyedValues[i].value) {
        setKeyedValues(newKeyedValues)
        return nextKey
      }
    }
  }

  return nextKey
}

export function useAutoKeyedArray<T>(
  defaultValues: T[],
  onChange: (values: T[]) => void
) {
  const [keyedValues, setKeyedValues] = useState<KeyValuePair<T>[]>(() =>
    defaultValues.map((value, i) => ({ key: i, value }))
  )
  const [prevValues, setPrevValues] = useState(defaultValues)

  const nextKey = synchroniseKeyedArrayState({
    prevValues,
    defaultValues,
    setPrevValues,
    keyedValues,
    setKeyedValues
  })

  return {
    keyedValues,
    insertAt: (position: number, value: T) => {
      const newKeyedValues = [
        ...keyedValues.slice(0, position),
        { key: nextKey, value },
        ...keyedValues.slice(position)
      ]
      setKeyedValues(newKeyedValues)
      onChange(newKeyedValues.map(({ value }) => value))
    },
    insertEnd: (value: T) => {
      const newKeyedValues = [...keyedValues, { key: nextKey, value }]
      setKeyedValues(newKeyedValues)
      onChange(newKeyedValues.map(({ value }) => value))
    },
    removeAt: (position: number) => {
      const newKeyedValues = [
        ...keyedValues.slice(0, position),
        ...keyedValues.slice(position + 1)
      ]
      setKeyedValues(newKeyedValues)
      onChange(newKeyedValues.map(({ value }) => value))
    },
    updateAt: (position: number, value: T) => {
      const newKeyedValues = keyedValues.map((keyedValue, i) =>
        position === i ? { key: keyedValue.key, value } : keyedValue
      )
      setKeyedValues(newKeyedValues)
      onChange(newKeyedValues.map(({ value }) => value))
    }
  }
}
