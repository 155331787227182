import { MediaType } from './questionnaire'

export enum QuestionType {
  basic = 'basic',
  matrix = 'matrix',
  ranked = 'ranked',
  free_text = 'free_text',
  standard_audience = 'standard_audience',
  custom_audience = 'custom_audience',
  scale = 'scale',
  max_diff = 'max_diff',
  none = 'none'
}

export enum EntryType {
  QuestionEntryType = 'QuestionEntryType',
  MatrixEntryType = 'MatrixEntryType',
  ForkEntryType = 'ForkEntryType'
}

export enum QuestionKind {
  Audience = 'Audience',
  Questionnaire = 'Questionnaire',
  None = 'None'
}

// top level (the entry)
export interface ChartEntry {
  id: string // for matrix: matrixTitleId, question, ranked: questionId
  entryNumber: number
  text: string // for matrix: matrixTitle, question, ranked: questionText
  textStyling?: string | null
  entryPosition: number
  contextPosition: number // <-- starts with 0 but should start with 1
  questionType: QuestionType
  entryType: EntryType
  questionKind: QuestionKind
  isMultipleChoice: boolean
  choicesLimit?: number // <-- applies to matrix_multiple + basic_multiple
  choicesRequired?: number // <-- applies to ranked (+ multiple_choice)
  hasRandomisedOptions: boolean // <-- applies to all
  hasRandomisedRows: boolean // <-- only applies to matrix
  mediaName: string
  mediaType: MediaType
  mediaUrl: string
  samplesWanted: number // <-- total samples wanted specified in fieldwork. This is the same for all entries as it is per survey
  samplesCollected: number // <-- Sum of responses for a given question. This may differ per respondent since some will be filtered out per quality/quota
  samplesCollectedFiltered: number // <-- Sum of responses for a given question with the filter settings.
  weightedSamplesCollected?: number
  weightedSamplesCollectedFiltered?: number
  scaleInfo: {
    minLabel: string
    minValue: number
    maxLabel: string
    maxValue: number
  } | null
  loopSourceOptionId: string | null // <- only applies to entries generated for looping
  rows: Row[]
}

// level 2, the 'row'
export interface Row {
  id: string // for matrix: questionId, for question, ranked: responseOptionId
  text: string // for matrix: questionText, for question, ranked: responseOptionText
  position: number
  samplesCollected: number
  samplesCollectedFiltered: number
  weightedSamplesCollected: number
  weightedSamplesCollectedFiltered: number
  rowItems: RowItem[]
}

// level 3, the stacked/grouped data
export interface RowItem {
  id: string // for matrix: responseOptionId, for question: responseOptionId, for ranked: rank
  text: string // for matrix, question: responseOptionText, for ranked: rank + 1
  position: number // for matrix, question: responseOptionPosition, for ranked: rank
  completes: number // <-- number of respondents selected this responseChoice
  weightedCompletes?: number // <-- these will be disregarded if there is no weighting scheme present
}
