import Skeleton from '@mui/material/Skeleton'
import { useFdChatProgress } from '../../FdChatProgressProvider.context'
import Accordion from '../Accordion/Accordion'
import styles from './MethodologyCard.module.css'

const sections = [
  {
    summary: 'How our AI interviews are structured?',
    details: (
      <>
        <p>
          Traditional qual, semi-structured interviews are usually centred
          around a discussion guide that includes 3-5 topics that the interview
          should cover. Each topic is usually centred around a broad open-ended
          question, e.g. “Can you tell me about ... (something specific)?”.
        </p>
        <p>
          The interviewer then follows up with different probing and
          clarification questions, such as “Can you say a little more about X?”.
          When a topic is well covered, the interviewer moves on to the next
          topic in the topic guide with a new broad open-ended question, taking
          previous points covered in the interview into account. each question
          is determined by the interviewer.
        </p>
        <p>
          Our AI interviewer is designed to follow this structure. A user shares
          their 3-5 research topics that they would like the interviews to
          cover. They also share any other context that would be useful for the
          interviewer to know e.g additional themes to explore or any useful
          wider context.
        </p>
        <p>
          Each interview follows the same structure: an introduction, a section
          on each topic and a closing section. In the introduction section, the
          interviewer will ask respondents to introduce themselves and look to
          build rapport with interviewees. In the main interview sections, the
          interviewer will ask each respondent the research question as the user
          has entered it and then will ask follow-up probing questions to
          explore that topic. The interviewer will spend roughly the same amount
          of time on each research topic. To end the interview, the interviewer
          will ask respondents if they have anything else they want to add that
          hasn&apos;t been said and then then the interview will end.
        </p>
        <p>
          This structure ensures that there is some consistency in questions
          from each respondent being asked the same initial topic question but
          also that breadth and depth is achieved as each interview will explore
          the topic differently based on how the respondent answers.
        </p>
        <p>
          To ensure that our interviewer follows the design structure of our
          interviews, we share with our AI interviewer detailed interview rules
          e.g all questions you ask should be short, succinct and to the point.
          Avoid asking long questions or multiple questions at once. We are
          constantly refining this based on our experience and testing of the
          product.
        </p>
        <p>
          To guarantee our interviewer can elicit rich and detailed responses,
          our AI interviewer is instructed to strictly adhere to methodological
          best practices for qualitative research e.g actively listen to what is
          said and sense what might remain unsaid but is worth exploring.
          Explore nuances when they emerge; if responses are repetitive or
          remain on the surface, pivot to areas not yet covered in depth.
        </p>
      </>
    )
  },
  {
    summary: 'How do the interviews work?',
    details: (
      <p>
        Our AI interviews leverage LLMs to conduct the interviews. We are
        currently using GPT-4o (July. 2024) but may in the future use other
        models. Respondents are told they will be interviewed by an AI. Once
        they start the study, the AI interviewer will ask them a question and
        then they will type their response. Every time they submit an answer, a
        new question is generated by the AI interviewer. They can exit the
        interview at any time.
      </p>
    )
  },
  {
    summary: 'How good are our interviews?',
    details: (
      <>
        <p>
          It is really hard to measure the quality of interviews as they can be
          quite a subjective measure. That said, we are very happy with the
          quality of our interviews currently. This is based on two measures.
        </p>
        <div>
          <h4 className={styles['accordion-details-subheading']}>
            Depth and richness of response
          </h4>
          <p>
            Whilst it varies depending on the topic, quality of research
            questions inputted and engagement of the audience, we see on average
            between 24-28 words per minute for our interviews. Given the average
            typing speed of 44 words per minute and the need to read each
            question before responding, this is a good amount of depth per
            interview. We do not generally see a drop-off as the interview
            progresses, suggesting responses don&apos;t get worse as the
            interview progresses.
          </p>
        </div>
        <div>
          <h4 className={styles['accordion-details-subheading']}>
            Respondent experience
          </h4>
          <p>
            We asked respondents to rate the quality of our interviews after a
            30-minute interview with our AI interviewer. The survey was
            conducted with 50 respondents of all ages, demographics and
            locations across the UK.
          </p>
          <ul className={styles['accordion-details-list']}>
            <li className={styles['accordion-details-list-item']}>
              96% of respondents rated the overall experience of the interview
              with Sage AI as positive or extremely positive
            </li>
            <li className={styles['accordion-details-list-item']}>
              100% rated the conversation with sage as natural or extremely
              natural
            </li>
            <li className={styles['accordion-details-list-item']}>
              40% said they strongly prefer or prefer communicating with Sage AI
              vs an actual human interviewer
            </li>
            <li className={styles['accordion-details-list-item']}>
              100% said they&apos;d be interested in participating in another AI
              interview again
            </li>
          </ul>
        </div>
      </>
    )
  }
]

const MethodologyCard = () => {
  const { interviewsCollected, interviewsWanted, loading } = useFdChatProgress()

  return (
    <div className={styles['methodology-card']}>
      <h2 className={styles.title}>Methodology & data quality</h2>
      <p>
        {loading ? (
          <Skeleton />
        ) : (
          `Our AI facilitator conducted ${interviewsCollected}/${interviewsWanted} one-on-one semi-structured interviews based on your project brief.`
        )}
      </p>
      <div>
        {sections.map((section, index) => (
          <Accordion
            id={`methodology-accordion-${index}`}
            summaryContent={
              <h3 className={styles['accordion-summary']}>{section.summary}</h3>
            }
            detailsContent={
              <div className={styles['accordion-details']}>
                {section.details}
              </div>
            }
            padding={'10px 0px'}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default MethodologyCard
