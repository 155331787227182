import { useDraftQuestionnaireEntry } from '../../../hooks/questionnaire/useDraftQuestionnaireEntry'
import useLoopingConfigData from './useLoopingConfigData'

export const useLoopSpecificDisplayLogicData = (entryId: string) => {
  const entry = useDraftQuestionnaireEntry(entryId)
  const { loopingConfig } = useLoopingConfigData(entry?.sectionId) ?? {}
  const loopSpecificDisplayLogic =
    loopingConfig?.loopSpecificDisplayLogics?.find(
      (logic) => logic.entryNumber === entry?.number
    )

  return loopSpecificDisplayLogic
}
