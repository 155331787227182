import { PureQueryOptions, gql } from '@apollo/client/core'
import { FieldworkAudience } from '../../../model/audience'
import { CriterionValidationErrors } from '../../../model/fieldwork'
import DRAFT_AUDIENCE from '../fragments/draftAudience'

export interface AudienceData {
  draftAudience: DraftAudience
}

export interface DraftAudience {
  surveyId: string
  createdDate: Date
  sinceDate: Date
  members: FieldworkAudience[]
  validationErrors: CriterionValidationErrors[]
}

/**
 * @deprecated You should use useQuery/useFragment with the *specific* query you want to use instead of this hook.
 */
export const GET_AUDIENCES = gql`
  query draftAudienceV2($projectId: ID!, $surveyId: ID!) {
    draftAudience: draftAudienceV2(projectId: $projectId, surveyId: $surveyId) {
      ...DraftAudience
    }
  }
  ${DRAFT_AUDIENCE}
`

export const draftAudienceRefetchQuery: (
  projectId: string,
  surveyId: string
) => PureQueryOptions = (projectId, surveyId) => {
  return {
    query: GET_AUDIENCES,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId }
  }
}
