import { PureQueryOptions } from '@apollo/client'
import { questionnaireGql } from '../../../gql-gen/questionnaire'

export const GET_FEASIBILITY_ESTIMATE = questionnaireGql(/* GraphQL */ `
  query getFeasibilityEstimateV2(
    $projectId: ID!
    $surveyId: ID!
    $lengthOfInterview: Int
    $recalculate: Boolean
  ) {
    getFeasibilityEstimate: getFeasibilityEstimateV2(
      projectId: $projectId
      surveyId: $surveyId
      loi: $lengthOfInterview
      recalculate: $recalculate
    ) {
      isFeasible
      feasibility
      estimatedDeliveryTime
      infeasibleQuotas {
        quota
        feasibleCounts
      }
      errors {
        field
        message
      }
      pollingStatus
      pollingMessage
    }
  }
`)

export const getFeasibilityEstimateRefetchQuery = (
  projectId: string,
  surveyId: string,
  lengthOfInterview: number
): PureQueryOptions => {
  return {
    query: GET_FEASIBILITY_ESTIMATE,
    context: { clientName: 'questionnaire' },
    variables: { projectId, surveyId, lengthOfInterview }
  }
}
