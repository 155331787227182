import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  randomiseSectionsHeader: {
    padding: theme.spacing(2, 2, 2, 4),
    background: theme.palette.background.paper,
    boxShadow: `0 0 ${theme.spacing(0.5)} ${theme.spacing(
      0.25
    )} rgba(0,0,0,0.1)`,
    zIndex: 2
  },
  randomiseSectionsIcon: {
    padding: 0
  },
  removeRandomisationButton: {
    '& .MuiButton-root': {
      minWidth: 0
    },
    '& .MuiButton-root p': {
      lineHeight: 0
    },
    '& .MuiButton-root svg': {
      fontSize: theme.typography.subtitle1.fontSize
    },
    margin: 0,
    textAlign: 'right'
  }
})

export default useStyles
