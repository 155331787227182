import {
  Button,
  ButtonVariant,
  Grid,
  Skeleton,
  Text
} from '@focaldata/cin-ui-components'
import { useFieldworkData } from '../../../../modules/Audience/Audience.hooks'
import { useFdChatPrice, useInterviewLink } from '../FdChat.hooks'
import { getFdChatDatesFormatted } from '../FdChat.utils'
import { ReviewSection } from './FdChatReview'

const FdChatReviewQuote = () => {
  const { fieldworkData, loading: loadingFieldwork } = useFieldworkData()
  const { respondentFee, dataProcessingPerInterview, total } = useFdChatPrice()

  if (loadingFieldwork) return <Skeleton variant="rectangular" height={350} />

  return (
    <ReviewSection title="Quote">
      <Grid
        container
        direction={'column'}
        component={'ul'}
        style={{ listStyle: 'none', padding: 0, margin: 0 }}
        rowGap={'10px'}
      >
        <li>
          <Text>
            <b>Number of interviews:</b>{' '}
            {fieldworkData?.samplesNeeded.unadjusted}
          </Text>
        </li>
        <li>
          <Text>
            <b>Respondent fee per interview:</b> £
            {respondentFee.toLocaleString('en-GB')}
          </Text>
        </li>
        <li>
          <Text>
            <b>Data processing fee per interview:</b> £
            {dataProcessingPerInterview.toLocaleString('en-GB')}
          </Text>
        </li>
        <li>
          <Text>
            <b>Cost per interview:</b> £
            {(respondentFee + dataProcessingPerInterview).toLocaleString(
              'en-GB'
            )}
          </Text>
        </li>
        <li>
          <Text>
            <b>Total estimated cost:</b> £{total.toLocaleString('en-GB')} (GBP)
          </Text>
        </li>
        <li>
          <Text>
            <b>Estimated completion date:</b>{' '}
            {getFdChatDatesFormatted().formattedEndDate}
          </Text>
        </li>
      </Grid>
      <Grid container gap={'10px'} marginTop={'50px'}>
        <FdChatPreviewInterviewButton />
      </Grid>
    </ReviewSection>
  )
}

export default FdChatReviewQuote

export const FdChatPreviewInterviewButton = () => {
  const link = useInterviewLink({ preview: true })

  const handlePreviewInterview = () => {
    window.open(link, '_blank')?.focus()
  }

  return (
    <Button
      variant={ButtonVariant.PrimaryOutlined}
      onClick={handlePreviewInterview}
    >
      Preview interview
    </Button>
  )
}
