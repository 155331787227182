import { gql } from '@apollo/client/core'
import DRAFT_TEXT_CARD_ITEM from './DraftTextCardItem'
import DRAFT_FORK from './draftForkItem'
import DRAFT_LOOPING_CONFIG from './draftLoopingConfig'

export const DRAFT_SECTION_ITEM = gql`
  fragment DraftSectionItem on DraftSectionItem {
    __typename
    title
    sectionId
    entries {
      id
      number
      position
      contextPosition
      questionKind
      sectionId
      entryType
      entryId @client
      entryItem {
        ...DraftEntryItem
      }
      forks {
        forkId
        branchNumber
      }
      loopSpecificDisplayLogic {
        entryNumber
        sourceIds
      }
    }
    settingValues {
      code
      value
      createdDate
      sinceDate
    }
    isLoopingEnabled @client
    sectionMaskingRules {
      ...DraftMaskingRule
    }
    loopingConfig {
      ...DraftLoopingConfig
    }
  }
  fragment DraftEntryItem on DraftEntryItem {
    ...DraftMatrixItem
    ...DraftTextCardItem
    ... on DraftForkItem {
      fork {
        ...DraftFork
      }
      __typename
    }
    ...DraftQuestionItem
  }
  ${DRAFT_FORK}
  ${DRAFT_LOOPING_CONFIG}
  ${DRAFT_TEXT_CARD_ITEM}
`
