import MatrixItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/MatrixItem'
import MatrixRow from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/MatrixRow'
import QuestionItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/QuestionItem'
import TextCardItem from '@focaldata/cin-ui-components/lib/components/SurveyQuestion/Common/Model/TextCardItem'
import { ToWords } from 'to-words'
import { useAppSelector } from '../../App.store'
import { DraftMatrixRow } from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  EntryType,
  MatrixTitle,
  MediaType,
  Question
} from '../../data/model/questionnaire'
import { selectResponseOptionsByQuestion } from '../../modules/Questionnaire/Questionnaire.slice'
import {
  getChoiceIntervalMax,
  getChoiceIntervalMin,
  getChoiceLimit,
  getRankChoiceRequisite,
  getResponseOptions
} from '../../utils/questionnaireUtils'
import useDebounce from '../useDebounce'

interface Loc {
  next: string
  instructions: string
}
interface MatrixLoc {
  next: string
  matrixInstructions: {
    singleSelectDesktop: string
    multiSelectDesktop: string
    singleSelectMobile: string
    multiSelectMobile: string
  }
}

interface MatrixLoc {
  next: string
  matrixInstructions: {
    singleSelectDesktop: string
    multiSelectDesktop: string
    singleSelectMobile: string
    multiSelectMobile: string
  }
}

interface RankedLoc {
  next: string
  instructions: string
  tapToRemove: string
  dragToReorder: string
  tapToSelect: string
}

interface FreeTextLoc {
  next: string
  instructions: string
  typeYourAnswerHere: string
  characterLimit: string
}

export const loc = { next: 'Next', instructions: 'Select one' }

export const scaleLoc = {
  next: 'Next',
  instructions: 'Drag the button to rate'
}

const convertNumberToWord: (number: number) => string = (number) => {
  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false
    }
  })
  const word = toWords.convert(number).toLowerCase()
  return word
}
const getInstructions: (
  choiceLimit?: number,
  min?: number,
  max?: number,
  responseOptionCount?: number
) => string = (choiceLimit, min, max, responseOptionCount) => {
  if (min !== undefined && max) {
    if (min === max) return `Select ${choiceLimit || max} answers`
    if (min !== 0) return `Select between ${min} and ${max} answers`
  }
  return choiceLimit ||
    (min === 0 && max && responseOptionCount && max < responseOptionCount)
    ? `Select up to ${convertNumberToWord(choiceLimit || max || 0)} answers`
    : 'Select all that apply'
}

export const getRankedLoc: (entryItem: DraftQuestionItem) => RankedLoc = (
  entryItem
) => {
  const defaultRankChoiceRequisiteOption = {
    id: '0',
    name: 'All ranked',
    value: 0
  }

  const rankChoiceRequisite = getRankChoiceRequisite(entryItem)
  const defaultRankChoiceRequisiteValue = rankChoiceRequisite
    ? parseInt(rankChoiceRequisite, 10)
    : defaultRankChoiceRequisiteOption.value

  return {
    next: 'Next',
    instructions: getInstructions(defaultRankChoiceRequisiteValue),
    tapToRemove: 'TAP TO REMOVE',
    dragToReorder: 'DRAG TO REORDER',
    tapToSelect: 'TAP TO SELECT'
  }
}

export const getFreeTextLoc: () => FreeTextLoc = () => {
  return {
    next: 'Next',
    instructions: 'Enter your answer in the box below',
    typeYourAnswerHere: 'Type your answer here...',
    characterLimit: 'characters remaining'
  }
}

export const getMultiChoiceLoc: (entryItem: DraftQuestionItem) => Loc = (
  entryItem
) => {
  const defaultOption = {
    id: '0',
    name: 'No limit',
    value: 0
  }

  const choiceLimit = getChoiceLimit(entryItem)
  const choiceIntervalMin = getChoiceIntervalMin(entryItem)
  const choiceIntervalMax = getChoiceIntervalMax(entryItem)
  const responseOptionCount = entryItem.responseOptions.length

  const defaultOptionValue = choiceLimit
    ? parseInt(choiceLimit, 10)
    : defaultOption.value

  return {
    next: 'Next',
    instructions: getInstructions(
      defaultOptionValue,
      choiceIntervalMin,
      choiceIntervalMax,
      responseOptionCount
    )
  }
}

export const getMatrixMultiChoiceLoc: (
  entryItem: DraftMatrixItem
) => MatrixLoc = (entryItem) => {
  const defaultOption = {
    id: '0',
    name: 'No limit',
    value: 0
  }

  const choiceLimit = getChoiceLimit(entryItem)
  const choiceIntervalMin = getChoiceIntervalMin(entryItem)
  const choiceIntervalMax = getChoiceIntervalMax(entryItem)
  const responseOptionCount = entryItem.responseOptions.length

  const defaultOptionValue = choiceLimit
    ? parseInt(choiceLimit, 10)
    : defaultOption.value

  return {
    next: 'Next',
    matrixInstructions: {
      singleSelectDesktop: 'Select one answer per row',
      multiSelectDesktop: `${getInstructions(
        defaultOptionValue,
        choiceIntervalMin,
        choiceIntervalMax,
        responseOptionCount
      )} per row`,
      singleSelectMobile: 'Select one answer',
      multiSelectMobile: getInstructions(
        defaultOptionValue,
        choiceIntervalMin,
        choiceIntervalMax,
        responseOptionCount
      )
    }
  }
}

export const getTextInstructionsLoc = (entryItem: TextCardItem): Loc => {
  return {
    next: 'Next',
    instructions: entryItem.textCard.body
  }
}

const useTransformDraftQuestionnaireToPreviewData = (
  draftQuestionnaireEntries: DraftQuestionnaireEntry[] | undefined
) => {
  const debouncedQuestionnaireEntries = useDebounce(
    draftQuestionnaireEntries,
    500
  )
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const getMatrixRows = (draftMatrixRows: DraftMatrixRow[]): MatrixRow[] => {
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return draftMatrixRows?.map((draftMatrixRow) => {
      const matrixRow: MatrixRow = {
        position: draftMatrixRow.position,
        renderedPosition: draftMatrixRow.position,
        question: {
          questionId: draftMatrixRow.question?.questionId ?? '',
          text: draftMatrixRow.question?.text ?? ''
        },
        questionMedia: {
          mediaType: draftMatrixRow.questionMedia?.mediaType || MediaType.Image,
          mediaUrl: draftMatrixRow.questionMedia?.mediaUrl || '',
          renderedMediaUrl: draftMatrixRow.questionMedia?.renderedMediaUrl ?? ''
        }
      }
      return matrixRow
    })
  }

  const getPreviewEntries = (
    draftQuestionnaireEntries: DraftQuestionnaireEntry[]
  ) => {
    return draftQuestionnaireEntries.reduce<
      { item: QuestionItem | MatrixItem | TextCardItem; id: string }[]
    >((acc, entry) => {
      if (entry.entryType === EntryType.QuestionEntryType) {
        const entryItem = entry.entryItem
        const {
          question = {} as Question,
          questionLk,
          questionMedia,
          questionScale,
          questionTypeCode,
          settingValues
        } = entryItem
        const item: QuestionItem = {
          question: {
            ...question,
            titleStyling: question.textStyling ?? undefined
          },
          questionMedia,
          questionScale,
          questionTypeCode,
          responseOptions: getResponseOptions(
            entryItem,
            responseOptionsByQuestion[questionLk],
            {
              showInitialValues: true
            }
          ),
          settingValues,
          number: entry.number
        }
        return [...acc, { item, id: entry.id }]
      }
      if (entry.entryType === EntryType.MatrixEntryType) {
        const entryItem = entry.entryItem
        const {
          matrixTitle = {} as MatrixTitle,
          matrixTitleLk,
          settingValues
        } = entryItem

        const item: MatrixItem = {
          matrixTitle: {
            ...matrixTitle,
            titleStyling: matrixTitle.titleStyling ?? undefined
          },
          matrixRows: getMatrixRows(entryItem.matrixRows),
          responseOptions: getResponseOptions(
            entryItem,
            responseOptionsByQuestion[matrixTitleLk],
            {
              showInitialValues: true
            }
          ),
          settingValues,
          number: entry.number
        }
        return [...acc, { item, id: entry.id }]
      }
      if (entry.entryType === EntryType.TextCardEntryType) {
        const entryItem = entry.entryItem
        const item: TextCardItem = {
          textCard: {
            textCardId: entryItem.textCardLk,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            title: entryItem.textCard?.title,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            titleStyling: entryItem.textCard?.titleStyling ?? undefined,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            subtitle: entryItem.textCard?.subtitle,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            body: entryItem.textCard?.body
          },
          questionMedia: entryItem.questionMedia,
          number: entry.number
        }
        return [...acc, { item, id: entry.id }]
      }
      return acc
    }, [])
  }
  const previewEntries = getPreviewEntries(debouncedQuestionnaireEntries ?? [])
  return {
    previewEntries
  }
}

export default useTransformDraftQuestionnaireToPreviewData
