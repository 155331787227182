import styles from './Avatar.module.css'

const Avatar = ({
  initials,
  src,
  alt = '',
  width = 32,
  height = 32,
  style,
  className,
  ...rest
}: { initials: string } & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  const classNames = `${styles.avatar}${className ? ` ${className}` : ''}`

  if (src)
    return (
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={classNames}
        style={style}
        {...rest}
      />
    )

  return (
    <div
      className={classNames}
      style={{
        width,
        height,
        ...style
      }}
    >
      <span className={styles.initials}>{initials}</span>
    </div>
  )
}

export default Avatar
