import AccountUser from './accountUser'

const AccountUserService = new AccountUser()

const GetAccountsByEmail = (accessToken: string, email: string) =>
  AccountUserService.getAccountsByEmail(accessToken, email)

const GetAccountByAccountId = (accessToken: string, accountId: string) =>
  AccountUserService.getAccountsByAccountId(accessToken, accountId)

const GetUserByEmail = (accessToken: string, email: string) =>
  AccountUserService.getUserByEmail(accessToken, email)

const Login = (email: string, password: string) =>
  AccountUserService.login(email, password)

const Refresh = () => AccountUserService.refresh()

const RequestAccess = (
  firstName: string,
  lastName: string,
  email: string,
  accountName: string
) => AccountUserService.requestAccess(firstName, lastName, email, accountName)

const AutoSignup = (
  firstName: string,
  lastName: string,
  email: string,
  accountName: string,
  password: string,
  autoSignupId: string
) =>
  AccountUserService.autoSignup(
    firstName,
    lastName,
    email,
    accountName,
    password,
    autoSignupId
  )

const RequestInviteSignup = (
  inviteId: string,
  firstName: string,
  lastName: string,
  password: string
) => AccountUserService.inviteSignup(inviteId, firstName, lastName, password)

const CreateInviteRequest = (
  accessToken: string,
  accountId: string,
  creatorEmailAddress: string,
  inviteeEmailAddresses: string[]
) =>
  AccountUserService.createInvite(
    accessToken,
    accountId,
    creatorEmailAddress,
    inviteeEmailAddresses
  )

const GetInviteDetailsRequest = (inviteId: string) => {
  return AccountUserService.getInviteDetails(inviteId)
}

const VerifyAutoSignup = (autoSignupId: string) => {
  return AccountUserService.verifyAutoSignup(autoSignupId)
}

const SignUp = (requestId: string, password: string) =>
  AccountUserService.signUp(requestId, password)

const CreateResetPassword = (email: string) =>
  AccountUserService.createResetPassword(email)

const ResetPassword = (resetPasswordId: string, password: string) =>
  AccountUserService.resetPassword(resetPasswordId, password)

const IsResetPasswordValid = (resetPasswordId: string) =>
  AccountUserService.isResetPasswordValid(resetPasswordId)

export {
  GetAccountByAccountId,
  GetAccountsByEmail,
  GetUserByEmail,
  Login,
  Refresh,
  RequestAccess,
  SignUp,
  VerifyAutoSignup,
  AutoSignup,
  RequestInviteSignup,
  CreateInviteRequest,
  GetInviteDetailsRequest,
  CreateResetPassword,
  ResetPassword,
  IsResetPasswordValid
}
