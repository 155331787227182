import { SelectOption } from '@focaldata/cin-ui-components'
import {
  DraftMaskingRule,
  ForkTag,
  MaskingRuleClause,
  MaskingRuleInput
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftForkItem,
  DraftQuestionItem,
  EntryType,
  QuestionKind,
  QuestionTypeCode
} from '../../../../data/model/questionnaire'
import { composeSelectedQuestionLk } from '../../../../utils/HelperFunctions'
import { chain } from '../../../../utils/lodashChain'
import { truncateText } from '../../../../utils/questionnaireUtils'
import { DisplayLogicForkTag } from '../../SurveyQuestionCardHeader/ClauseBlock'
import { MaskingRuleForDialog, MaskingRuleNegation } from '../MaskingButton'
import { DraftMatrixItem, Question } from '../MaskingDialog'

export const getOptions: (
  questions: Question[] | undefined,
  questionPosition: number,
  allowForks?: boolean
) => SelectOption[] = (questions, questionPosition, allowForks) =>
  questions?.reduce((acc, question) => {
    if (
      question.questionKind === QuestionKind.QuestionnaireKind &&
      question.position >= questionPosition
    ) {
      return acc
    }
    if (question.entryType === EntryType.QuestionEntryType) {
      const entryItem = question.entryItem as DraftQuestionItem
      if (
        ![
          QuestionTypeCode.Basic,
          QuestionTypeCode.CustomAudience,
          QuestionTypeCode.StandardAudience
        ].includes(entryItem.questionTypeCode)
      ) {
        return acc
      }
      const prefix =
        question.questionKind === QuestionKind.QuestionnaireKind ? 'Q' : 'A'
      const item = {
        id: entryItem.questionLk,
        name: `${prefix}${question.contextPosition + 1} ${truncateText(
          150,
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          entryItem.question?.text
        )}`,
        value: entryItem.questionLk
      }
      acc.push(item)
    }
    if (question.entryType === EntryType.MatrixEntryType) {
      const entryItem = question.entryItem as DraftMatrixItem
      entryItem.matrixRows.forEach((row, index) => {
        const item = {
          id: composeSelectedQuestionLk(
            row.questionLk,
            entryItem.matrixTitleLk
          ),
          name: `Q${question.contextPosition + 1}.${index + 1} ${truncateText(
            150,
            // @todo Legacy eslint violation – fix this when editing
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            row.question?.text
          )}`,
          value: composeSelectedQuestionLk(
            row.questionLk,
            entryItem.matrixTitleLk
          )
        }
        acc.push(item)
      })
    }
    if (question.entryType === EntryType.ForkEntryType && allowForks) {
      const { fork } = question.entryItem as DraftForkItem
      const item = {
        id: `A/B ${fork.forkId}`,
        name: fork.name,
        value: fork.forkId
      }
      acc.push(item)
    }

    return acc
  }, [] as SelectOption[]) || []

export const getChecked: (
  responseOptionLk: string,
  maskingRule: MaskingRuleForDialog
) => boolean = (responseOptionLk, maskingRule) => {
  const checked = maskingRule.sourceResponseOptions.some(
    (mk) => mk === responseOptionLk
  )

  return checked
}

export const getOperatorOptions: () => SelectOption[] = () => {
  return [
    {
      id: '0',
      name: 'AND',
      value: MaskingRuleClause.And
    },
    {
      id: '1',
      name: 'OR',
      value: MaskingRuleClause.Or
    }
  ]
}

export const getNegationOptions: () => SelectOption[] = () => [
  {
    id: '0',
    name: 'is one of',
    value: MaskingRuleNegation.IS_ONE_OF
  },
  {
    id: '1',
    name: 'is not one of',
    value: MaskingRuleNegation.IS_NOT_ONE_OF
  }
]

export const convertMaskingRulesForDialog: (
  maskingRules: DraftMaskingRule[]
) => MaskingRuleForDialog[] = (maskingRules) =>
  maskingRules.map(({ clause, negated, maskingKeys }) => ({
    clause,
    negated,
    matrixTitleLk: maskingKeys[0].matrixTitleLk,
    questionLk: maskingKeys[0].questionLk,
    sourceResponseOptions: maskingKeys.map((key) => key.responseOptionLk)
  })) as MaskingRuleForDialog[]

export const convertMaskingRulesForBackend: (
  maskingRules: MaskingRuleForDialog[]
) => MaskingRuleInput[] = (maskingRules) =>
  maskingRules.map(
    ({ clause, negated, questionLk, matrixTitleLk, sourceResponseOptions }) => {
      return {
        clause,
        negated,
        maskingKeys: sourceResponseOptions.map((responseOptionLk) => ({
          questionId: questionLk,
          responseOptionId: responseOptionLk,
          matrixTitleId: matrixTitleLk ?? null
        }))
      }
    }
  )

export const getMaskingRulesFromForks: (
  forks: ForkTag[] | null | undefined
) => MaskingRuleForDialog[] = (forks) => {
  const displayLogicForks: DisplayLogicForkTag[] = chain(forks)
    .groupBy((fork) => fork.forkId)
    .map((value, key) => ({
      forkId: key,
      branchNumbers: value.map((tag) => tag.branchNumber)
    }))
    .value()

  return displayLogicForks.map(
    (fork) =>
      ({
        clause: MaskingRuleClause.And,
        questionLk: fork.forkId,
        sourceResponseOptions: fork.branchNumbers.map((branch) =>
          branch.toString()
        ),
        negated: false,
        isSourceFork: true
      }) as MaskingRuleForDialog
  )
}

export const convertMaskingRulesToForkTags = (
  maskingRules: MaskingRuleForDialog[]
) =>
  maskingRules.flatMap((fork) =>
    fork.sourceResponseOptions.map(
      (branch): ForkTag => ({
        forkId: fork.questionLk,
        branchNumber: parseInt(branch, 10),
        __typename: 'ForkTag'
      })
    )
  )
