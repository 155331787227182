import jwtDecode from 'jwt-decode'
import psl from 'psl'
import { z } from 'zod'
import { SetUserProperties } from '../amplitude'
import { Account } from '../data/model/accountUser'
import { GetAccountsByEmail } from '../data/rest/accountUser'
import { Datadog } from '../tracking/datadog'
import UserSession from './UserSession'
import { RemixBrowserCookie } from './remixBrowserCookie'

const ACCESS_TOKEN_KEY = 'fd_access_token'

export const AccessToken = {
  get() {
    return RemixBrowserCookie.get<string>(ACCESS_TOKEN_KEY)
  },

  mustGet() {
    const token = this.get()

    if (!token) {
      throw new Error('Token missing')
    }

    return token
  },

  set(token: string) {
    const parseedHostname = psl.parse(window.location.hostname)
    RemixBrowserCookie.set(ACCESS_TOKEN_KEY, token, {
      domain:
        parseedHostname.error || !parseedHostname.domain
          ? window.location.hostname
          : parseedHostname.domain, // This cookie needs to be accessed cross-domain (e.g. *.focaldata.com)
      expires: new Date(Date.now() + 60 * 60 * 24 * 400 * 1000) // 400 days is the maximum cookie expiry
    })
  },

  clear() {
    RemixBrowserCookie.clear(ACCESS_TOKEN_KEY)
  },

  verify():
    | { token: string; valid: true }
    | { token?: undefined; valid: false } {
    const token = this.get()

    if (token) {
      const currentDateTime = new Date()
      const expiryDateTime = new Date(
        jwtDecode<{ exp: number }>(token).exp * 1000
      )
      const valid = expiryDateTime > currentDateTime

      return valid ? { token, valid } : { valid: false }
    } else {
      return { valid: false }
    }
  },

  requestHeaders() {
    const { token, valid } = this.verify()

    if (valid) {
      return this.requestHeadersWithToken(token)
    } else {
      return {}
    }
  },

  requestHeadersWithToken(maybeToken?: string) {
    const token = maybeToken ?? this.mustGet()
    return { authorization: `Bearer ${token}` }
  },

  /**
   * We previously stored access token in localStorage. To avoid forcing everyone
   * to re-login on deploy, let's migrate the access token from localStorage to cookies.
   *
   * @todo Remove after 2024-08-01
   */
  migrate() {
    const value = localStorage.getItem('access_token')

    if (value) {
      this.set(value)
      localStorage.removeItem('access_token')
    }
  }
}

export const setAccountId: (
  email: string,
  accessToken: string
) => Promise<boolean> = async (email, accessToken) => {
  const responseAccount = await GetAccountsByEmail(accessToken, email)
  const accounts: Account[] = responseAccount.data.slice()
  const { accountId, name: accountName } = accounts[0]
  if (accountId) {
    UserSession.setAccountId(accountId)
    UserSession.setAccountName(accountName)
    SetUserProperties(accountId, accountName)
    Datadog.updateUserSession({
      id: email,
      email,
      accountId,
      accountName
    })
    return true
  }

  return false
}

export const getLoginRedirectPath = (): string => {
  const { pathname } = window.location
  const targetPath = pathname === '/login' ? '/projects' : pathname
  return `/login?target=${targetPath}`
}

export const isFocaldataUser = (): boolean => {
  const email = UserSession.getEmail() ?? ''
  return email.endsWith('@focaldata.com')
}

export const isDemoUser = (): boolean => {
  const email = UserSession.getEmail() ?? ''
  return (
    isFocaldataUser() &&
    (email.startsWith('demo+fdchat') || email.startsWith('fdchat+demo'))
  )
}

/**
 * Checks if the provided email matches the email associated with the current user session.
 * @param email The email to check against the current user's email.
 * @returns `true` if the provided email matches the current user's email, `false` otherwise.
 */
export const isUserWithEmail = (email: string): boolean => {
  return email.toLowerCase() === UserSession.getEmail()?.toLowerCase()
}

const projectSetupHostSchema = z.string().url()
const envProjectSetupHost =
  process.env.REACT_APP_PROJECT_SETUP_HOST || 'https://app.focaldata.dev' //TODO: RESTORE THIS IS IT IS ONLY USED FOR STAGING
export const projectSetupHost = (
  process.env.NODE_ENV === 'development'
    ? projectSetupHostSchema.default('http://localhost:3000')
    : projectSetupHostSchema
).parse(envProjectSetupHost.replace(/\/$/, ''))

const FD_PREFERRED_APP_COOKIE_KEY = 'fd_preferred_app'

export const FocaldataPreferredApp = {
  get() {
    return RemixBrowserCookie.get<string>(FD_PREFERRED_APP_COOKIE_KEY)
  },

  set(app: 'coworker' | 'dashboard') {
    const parsedHostname = psl.parse(window.location.hostname)
    RemixBrowserCookie.set(FD_PREFERRED_APP_COOKIE_KEY, app, {
      domain:
        parsedHostname.error || !parsedHostname.domain
          ? window.location.hostname
          : parsedHostname.domain, // This cookie needs to be accessed cross-domain (e.g. *.focaldata.com)
      expires: new Date(Date.now() + 60 * 60 * 24 * 400 * 1000) // 400 days is the maximum cookie expiry
    })
  }
}

export const appTargetSchema = z.enum([
  'project_setup',
  'audience_picker',
  'dashboard',
  'coworker'
])

export const targetDefaults: Record<z.infer<typeof appTargetSchema>, string> = {
  project_setup: '/',
  audience_picker: '/audience-picker',
  dashboard: '/projects',
  coworker: '/'
}

export interface LoginLocationState {
  firstLogin?: boolean
}
