import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import Divider from '@mui/material/Divider'
import React from 'react'
import useSubheaderSizing from '../../hooks/useSubheaderSizing'
import useStyles from './styles'

interface Props {
  fieldworkPeriod?: string
  countryCode?: string
  costMenuDropdown?: JSX.Element
  isMultiMarket?: boolean
}

const CompleteInfoHeader: React.FC<Props> = (props: Props) => {
  const {
    fieldworkPeriod,
    countryCode = 'UK',
    costMenuDropdown,
    isMultiMarket = false
  }: Props = props
  const { subheaderRightSideMaxWidth, isSubheaderCondensed } =
    useSubheaderSizing({ isPublic: false })
  const { classes } = useStyles({
    styleProps: { width: subheaderRightSideMaxWidth }
  })

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={
        !isMultiMarket ? classes.container : classes.multiMarketContainer
      }
    >
      {!isMultiMarket && (
        <>
          <Grid item xs="auto">
            <Grid container item direction="row">
              {!isSubheaderCondensed && (
                <p
                  className={classNames(
                    textClasses.default,
                    textClasses.sizeMs,
                    textClasses.marginRight,
                    textClasses.displayInline
                  )}
                >
                  Country:{' '}
                </p>
              )}
              <p
                className={classNames(
                  textClasses.default,
                  textClasses.displayInline,
                  textClasses.sizeMs,
                  textClasses.weightSemiBold
                )}
              >
                {countryCode}
              </p>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.dividerFielderwork}
            />
          </Grid>
        </>
      )}
      <Grid item xs="auto">
        <Grid container item direction="row">
          {!isSubheaderCondensed && (
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeMs,
                textClasses.marginRight,
                textClasses.alignCenter
              )}
            >
              Fieldwork:{' '}
            </p>
          )}
          <p
            className={classNames(
              textClasses.default,
              textClasses.sizeMs,
              textClasses.alignCenter,
              textClasses.weightSemiBold
            )}
          >
            {fieldworkPeriod}
          </p>
        </Grid>
      </Grid>
      {!isMultiMarket && (
        <>
          <Grid item>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.dividerCost}
            />
          </Grid>
          <Grid item xs="auto" className={classes.costContainer}>
            {costMenuDropdown}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CompleteInfoHeader
