import { useContext, useState } from 'react'
import { ImExEntryDtoInput } from '../../../data/gql-gen/questionnaire/graphql'
import { RawQuestionnaireEntryInput } from '../../../hooks/import/models'
import { useImportQuestionnaireV2 } from '../../../hooks/questionnaire/useImportQuestionnaireV2'
import { useProjectId } from '../../../hooks/useProjectId'
import QuestionnaireContext, {
  closeImportDialog
} from '../Questionnaire.context'

export const useDialogHooks = (resetPreview: () => void) => {
  const { questionnaireState, dispatch } = useContext(QuestionnaireContext)
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const projectId = useProjectId()

  const { importQuestionnaireV2 } = useImportQuestionnaireV2()

  const handleDialogClose = () => {
    dispatch(closeImportDialog())
    resetPreview()
  }

  const handleDialogSave = async (entries: RawQuestionnaireEntryInput[]) => {
    await importQuestionnaireV2({
      variables: {
        input: {
          projectId,
          entries: entries as unknown as ImExEntryDtoInput[]
        }
      }
    })
    handleDialogClose()
  }

  return {
    isDialogOpen: questionnaireState.isImportDialogOpened,
    isImportButtonDisabled,
    setIsImportButtonDisabled,
    handleDialogClose,
    isLoading,
    setIsLoading,
    handleDialogSave
  }
}
