import { makeStyles } from '@focaldata/cin-ui-components'

export const useStyles = makeStyles()(() => {
  return {
    responseOptionsGroupsWrapper: {
      padding: '2px',
      height: 'calc(100vh - 328px)',
      overflow: 'auto'
    }
  }
})
