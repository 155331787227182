import {
  IconButton,
  IconName,
  IconSize,
  PercentageInput,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { useEffect, useState } from 'react'
import {
  percentToProportion,
  proportionToPercent
} from '../../../utils/HelperFunctions'
import useStyles from './Branch.styles'

interface Props {
  defaultPercent: number
  label: string
  hideDelete?: boolean
  isLast?: boolean
  onPercentChange?: (newPercent: number) => void
  onDeleteBranch?: () => void
}

const Branch: React.FC<Props> = (props: Props) => {
  const {
    defaultPercent,
    label,
    isLast = false,
    hideDelete = false,
    onPercentChange,
    onDeleteBranch
  }: Props = props
  const { classes, cx: classNames } = useStyles()
  const { classes: textClasses } = textStyleUtils.useTextStyles()

  const [percentage, setPercentage] = useState<number>(
    proportionToPercent(defaultPercent)
  )

  useEffect(() => {
    setPercentage(proportionToPercent(defaultPercent))
  }, [defaultPercent])

  const handleChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const percentageValue = Number.parseFloat(target.value)
    setPercentage(percentageValue)
    if (onPercentChange) {
      onPercentChange(percentToProportion(percentageValue))
    }
  }

  return (
    <div
      className={classNames(
        'fd-grid fd-container fd-row fd-align-items-center',
        classes.container,
        {
          [classes.lastBranch]: isLast
        }
      )}
    >
      <div className="fd-grid fd-container fd-item fd-xs-1">
        <p
          className={classNames(
            textClasses.default,
            textClasses.marginRight,
            textClasses.weightSemiBold,
            classes.letter
          )}
        >
          {label}
        </p>
      </div>
      <div className={classNames('fd-grid fd-item', classes.input)}>
        <PercentageInput value={percentage} onChange={handleChange} />
      </div>
      <p
        className={classNames(
          textClasses.default,
          textClasses.highlightBackground,
          textClasses.weightSemiBold,
          classes.percentText
        )}
      >
        %
      </p>
      {!hideDelete && (
        <IconButton
          iconName={IconName.DeleteOutline}
          onClick={onDeleteBranch}
          iconSize={IconSize.Large}
          smallHoverShadowSize
        />
      )}
    </div>
  )
}

export default Branch
