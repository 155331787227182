import { useReactiveVar } from '@apollo/client'
import range from 'lodash/range'
import { useContext, useEffect, useRef, useState } from 'react'
import { useMount, useUnmount } from 'react-use'
import { questionBeingEditedId } from '../../../apollo/apolloClient'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useResetNewlyCreatedEntry from '../../../hooks/useResetNewlyCreatedEntry'
import QuestionnaireContext, {
  resetEntriesToRender,
  setEntriesToRender
} from '../Questionnaire.context'
import { getScrollId, onScrollStop } from '../Questionnaire.utils'
import {
  CONTAINER_ID_QUESTIONNAIRE_CONTENT,
  DEFAULT_CARD_PLACEHOLDER_HEIGHT
} from '../constants'
import {
  getLastVisibleEntryIndexUpdated,
  getPrevNextEntrySiblings,
  isEntryAboveViewportTop,
  isEntryBelowViewportBottom
} from './QuestionsList.utils'

export const useChangeCardVisibilityOnScroll = (): ((
  index: number,
  isVisible: boolean
) => void) => {
  const { flattenedEntries } = useGetDraftQuestionnaire()

  const lastVisibleEntryIndexRef = useRef(0)
  const {
    dispatch: dispatchToContext,
    questionnaireState: { entriesToRender }
  } = useContext(QuestionnaireContext)
  const [visibileEntryIndex, setFocusedEntryIndex] = useState<number>(0)

  useMount(() => {
    const scrollWrapperNode = document.getElementById(
      CONTAINER_ID_QUESTIONNAIRE_CONTENT
    )
    const updateVisibleCards = () => {
      let newEntriesToRender = entriesToRender

      if (lastVisibleEntryIndexRef.current > 2) {
        const { prevEntryNode, nextEntryNode } = getPrevNextEntrySiblings(
          lastVisibleEntryIndexRef.current
        )

        // if entry before currently visible entry is outside the viewport at the top, it means current entry is at the top. show only current entry and 4 next entries
        if (prevEntryNode && isEntryAboveViewportTop(prevEntryNode)) {
          newEntriesToRender = range(
            lastVisibleEntryIndexRef.current,
            lastVisibleEntryIndexRef.current + 5
          )
          // if entry after currently visible entry is outside the viewport at the bottom, it means current entry is at the bottom. show only current entry and 4 previious entries
        } else if (nextEntryNode && isEntryBelowViewportBottom(nextEntryNode)) {
          newEntriesToRender = range(
            lastVisibleEntryIndexRef.current - 4,
            lastVisibleEntryIndexRef.current + 1
          )
          // in all other scenarios show entries in range [-3, +3) where current entry is 0
        } else {
          newEntriesToRender = range(
            lastVisibleEntryIndexRef.current - 3,
            lastVisibleEntryIndexRef.current + 3
          )
        }
      }
      dispatchToContext(setEntriesToRender(newEntriesToRender))
      setFocusedEntryIndex(lastVisibleEntryIndexRef.current)
    }
    return onScrollStop(updateVisibleCards, scrollWrapperNode)
  })

  useUnmount(() => {
    dispatchToContext(resetEntriesToRender())
  })

  useResetNewlyCreatedEntry(visibileEntryIndex)

  const entryBeingEditedId = useReactiveVar(questionBeingEditedId)

  useEffect(() => {
    if (entryBeingEditedId) {
      const entryBeingEditedPosition = flattenedEntries.findIndex(
        (entry) => entry.id === entryBeingEditedId
      )
      if (entryBeingEditedPosition) {
        setFocusedEntryIndex(entryBeingEditedPosition)
      }
    }
  }, [entryBeingEditedId, flattenedEntries])

  const handleChangeVisible = (index: number, isVisible: boolean) => {
    lastVisibleEntryIndexRef.current = getLastVisibleEntryIndexUpdated({
      lastVisibleEntryIndex: lastVisibleEntryIndexRef.current,
      scrolledEntryIndex: index,
      isVisible
    })
  }

  return handleChangeVisible
}

export type PlaceholderHeightsById = Record<string, number>

export const usePlaceHolderHeightsById = (): PlaceholderHeightsById => {
  const placeholderHeightsById = useRef<PlaceholderHeightsById>({})
  const { flattenedEntries } = useGetDraftQuestionnaire()
  const {
    questionnaireState: { entriesToRender }
  } = useContext(QuestionnaireContext)

  useEffect(() => {
    entriesToRender.forEach((entryIndex) => {
      const entryId = flattenedEntries[entryIndex]?.id

      if (typeof entryId === 'string') {
        const entryNode = document.querySelector(
          `[name=${getScrollId(entryId)}]`
        )
        placeholderHeightsById.current[entryId] =
          entryNode?.getBoundingClientRect().height ??
          DEFAULT_CARD_PLACEHOLDER_HEIGHT
      }
    })
  }, [entriesToRender, flattenedEntries])

  return placeholderHeightsById.current
}
