import { Grid } from '@focaldata/cin-ui-components'
import { validate } from 'uuid'
import ErrorPage, { ErrorType } from '../../../../controls/ErrorPage'
import usePrepareApolloMiddleware from '../../../../hooks/usePrepareApolloMiddleware'
import { useProjectId } from '../../../../hooks/useProjectId'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import NavigationSubheaderLayout from '../../../../layouts/NavigationSubheaderLayout'
import { AudienceContextProvider } from '../../../../modules/Audience/Audience.context'
import { Questionnaire } from '../../../../modules/Questionnaire'
import ImportFromFileDialog from '../../../../modules/Questionnaire/DocxImport/DocxImportDialog'
import { QuestionnaireContextProvider } from '../../../../modules/Questionnaire/Questionnaire.context'
import { ActionsMenuContainer } from '../../Survey/ActionsMenu'
import { DeselectAllCardsContainer } from '../../Survey/DeselectAllCards'
import { QuestionsBulkDeleteButtonContainer } from '../../Survey/QuestionsBulkDeleteButton'
import { QuestionsSelectorCounterContainer } from '../../Survey/QuestionsSelectorCounter'
import { useStyles } from './MasterSurveyPage.styles'

export const MasterSurveyPage = () => {
  const { classes } = useStyles()
  const surveyId = useSurveyId()
  const projectId = useProjectId()
  usePrepareApolloMiddleware()

  const isProjectIdValid = validate(projectId)
  const isSurveyIdValid = validate(surveyId)

  if (!isProjectIdValid || !isSurveyIdValid) {
    return <ErrorPage errorType={ErrorType.NotFound404} />
  }

  return (
    <AudienceContextProvider>
      <QuestionnaireContextProvider>
        <div className={classes.subheaderWrapper}>
          <NavigationSubheaderLayout
            rightChild={
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <DeselectAllCardsContainer />
                <QuestionsBulkDeleteButtonContainer />
                <QuestionsSelectorCounterContainer />
                <ImportFromFileDialog />
                <ActionsMenuContainer />
              </Grid>
            }
          />
        </div>
        <div className={classes.questionaireWrapper}>
          <Questionnaire />
        </div>
      </QuestionnaireContextProvider>
    </AudienceContextProvider>
  )
}
