import {
  additionalPalette,
  makeStyles,
  theme
} from '@focaldata/cin-ui-components'

const separatorWidth = 14

const inputStyles = {
  width: `calc(50% - ${separatorWidth / 2}px)`
}

const useStyles = makeStyles()({
  ageRangeInput: {
    ...inputStyles,
    '& fieldset': {
      border: `1px solid ${additionalPalette.lightGrey.main} !important`
    }
  },
  ageRangeInputError: {
    ...inputStyles,
    '& fieldset': {
      border: `1px solid ${theme.palette.error.main} !important`
    }
  },
  ageRangeInputSeparator: {
    display: 'inline-block',
    width: separatorWidth,
    height: 2,
    background: additionalPalette.lightGrey.main,
    margin: '0 10px'
  },
  completesCaption: {
    marginLeft: theme.spacing(4)
  },
  hidden: {
    visibility: 'hidden'
  },
  displayNone: {
    display: 'none'
  },
  errorMessage: {
    marginBottom: theme.spacing(1.5)
  }
})

export default useStyles
