/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { textStyleUtils } from '@focaldata/cin-ui-components'
import useStyles from './StagingInfoBanner.styles'

interface Props {
  branch: string
  version: string
  onClose: () => void
}

const StagingInfoLabel = ({ branch, version, onClose }: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  const { classes } = useStyles()

  return (
    <div className={classes.infoLabel}>
      <div className={classes.infoLabelTextWrapper}>
        <p
          className={classNames(
            textClasses.default,
            textClasses.displayInline,
            textClasses.highlightInherit
          )}
        >
          You are testing the app using{' '}
          <span
            title="click to copy"
            onClick={() => navigator.clipboard.writeText(branch)}
            className={classes.copyText}
          >
            {branch}
          </span>{' '}
          branch against{' '}
          <span
            title="click to copy"
            onClick={() => navigator.clipboard.writeText(version)}
            className={classes.copyText}
          >
            {version}
          </span>{' '}
          version
        </p>{' '}
        (
        <a
          className={classNames(
            textClasses.default,
            textClasses.highlightInherit
          )}
          onClick={(e) => e.stopPropagation()}
          target="blank"
          href={`https://github.com/focaldata/cin-fd-surveys/compare/${version}..${branch}`}
        >
          <span
            className={classNames(
              textClasses.default,
              textClasses.sizeM,
              textClasses.weightSemiBold,
              textClasses.alignCenter,
              textClasses.displayInline,
              textClasses.highlightInherit
            )}
          >
            see diff
          </span>
        </a>
        )
      </div>
      <button type="button" className={classes.closeButton} onClick={onClose}>
        ✕
      </button>
    </div>
  )
}

export default StagingInfoLabel
