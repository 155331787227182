import { useMutation } from '@apollo/client'
import React, { Dispatch, SetStateAction } from 'react'
import EditProjectNameDialog from '../../../../controls/EditProjectNameDialog'
import { UpdateProjectMutationVariables } from '../../../../data/gql-gen/dashboard/graphql'
import { LoggerErrorType } from '../../../../data/logger'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { UPDATE_PROJECT, UpdateProjectData } from '../../Project.mutations'

interface Props {
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  selectedProjectId?: string
  selectedProjectName: string
}
const EditProjectName: React.FC<Props> = (props: Props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    selectedProjectId,
    selectedProjectName
  } = props

  const [updateProject] = useMutation<
    UpdateProjectData,
    UpdateProjectMutationVariables
  >(UPDATE_PROJECT, {
    context: { clientName: 'dashboard' },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'updateProject', error)
    },
    refetchQueries: ['projectById', 'projectBySurveyId', 'searchProjects']
  })

  const handleOnClickSave = (title: string) => {
    if (selectedProjectId)
      updateProject({
        variables: {
          projectId: selectedProjectId,
          name: title
        }
      })
  }

  return (
    <EditProjectNameDialog
      isDialogOpen={isDialogOpen}
      setIsDialogOpen={setIsDialogOpen}
      onClickSave={(title: string) => {
        handleOnClickSave(title)
      }}
      projectTitle={selectedProjectName}
    />
  )
}

export default EditProjectName
