import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: theme.spacing(0.5),
    alignItems: 'center'
  },
  checkBox: {
    zIndex: 0
  },
  title: {
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(0.75)
  },
  numbers: {
    textAlign: 'right'
  },
  fullWidth: {
    width: '100%'
  },
  deleteIcon: {
    marginRight: `-${theme.spacing(2)}`
  }
}))

export default useStyles
