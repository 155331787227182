import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { ChartEntry } from '../../../data/model/chart'
import {
  ForkFilter,
  SearchableFilterOption,
  SelectedFilter,
  WeightingScheme
} from '../../../data/model/results'
import requestWithRetry from '../../../utils/requestWithRetry'
import { transformForkDataIntoSearchableFilterOption } from '../../../utils/resultsUtils'

interface Vars {
  surveyId: string
  filters?: SelectedFilter[]
}

interface ResponseData {
  results: ChartEntry[]
  weightingScheme: WeightingScheme
}
interface SummaryData {
  loading: boolean
  error: boolean
  weightingScheme: WeightingScheme | undefined
  data: ChartEntry[]
}

const SUMMARY_ENDPOINT = '/summary'

export const usePublicSummary = ({ surveyId, filters }: Vars): SummaryData => {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [weightingScheme, setWeightingScheme] = useState<WeightingScheme>()
  const [data, setData] = useState<ChartEntry[]>([])

  useEffect(() => {
    setLoading(true)

    // Extract the fork filters in case there are any
    const responseFilters =
      filters?.filter((filter) => !!filter.questionId) || []
    const forkFilters =
      filters
        ?.filter((filter) => !!filter.forkId)
        .map((fl) => {
          return {
            ...fl,
            branchNumbers: fl.branchNumbers?.map((br) =>
              Number.parseInt(br, 10)
            )
          }
        }) || []

    const postPayload = {
      surveyId,
      responseFilters,
      forkFilters
    }

    requestWithRetry({
      method: 'post',
      url: `${process.env.REACT_APP_PUBLIC_REST_DOMAIN}${SUMMARY_ENDPOINT}`,
      payload: postPayload
    })
      .then((data: AxiosResponse<ResponseData>) => {
        if (data.status >= 200 && data.status < 400) {
          setError(false)
          setData(data.data.results)
          setWeightingScheme(data.data.weightingScheme)
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [surveyId, filters])

  return { loading, error, weightingScheme, data }
}

interface Vars {
  surveyId: string
}

interface ResponseData {
  filters: SearchableFilterOption[]
  forkFilters: ForkFilter[]
}

interface FilterData {
  error: boolean
  loading: boolean
  data: SearchableFilterOption[]
}

const FILTERS_ENDPOINT = '/filters'

export const usePublicFilters = ({ surveyId }: Vars): FilterData => {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<SearchableFilterOption[]>([])

  useEffect(() => {
    setLoading(true)

    requestWithRetry({
      method: 'get',
      url: `${process.env.REACT_APP_PUBLIC_REST_DOMAIN}${FILTERS_ENDPOINT}/${surveyId}`
    })
      .then((data: AxiosResponse<ResponseData>) => {
        if (data.status >= 200 && data.status < 400) {
          setError(false)
          setData([
            ...transformForkDataIntoSearchableFilterOption(
              data.data.forkFilters
            ),
            ...data.data.filters
          ])
        } else {
          setError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [surveyId])

  return { data, error, loading }
}
