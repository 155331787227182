import { Grid, textStyleUtils } from '@focaldata/cin-ui-components'
import { MeanChartData } from '../Chart.model'
import useStyles from './MeanChart.styles'

const MeanChart = ({ data }: { data: MeanChartData }) => {
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const mean = (
    <p
      className={classNames(
        textClasses.default,
        classes.meanSection,
        classes.meanText
      )}
    >
      {data.mean}
    </p>
  )

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      style={{ minHeight: 200 }}
    >
      <Grid item>
        <p className={classNames(textClasses.default, textClasses.sizeM)}>
          Mean
        </p>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classes.meanContainer}
      >
        {data.scale ? (
          <>
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeS,
                classes.meanSection,
                classes.meanLabel,
                classes.meanLabelMin
              )}
            >
              {data.scale.minValue}
              <br />
              {data.scale.minLabel}
            </p>
            <hr className={classes.meanLine} />
            {mean}
            <hr className={classes.meanLine} />
            <p
              className={classNames(
                textClasses.default,
                textClasses.sizeS,
                classes.meanSection,
                classes.meanLabel,
                classes.meanLabelMax
              )}
            >
              {data.scale.maxValue}
              <br />
              {data.scale.maxLabel}
            </p>
          </>
        ) : (
          mean
        )}
      </Grid>
    </Grid>
  )
}

export default MeanChart
