import {
  LinkButton,
  Menu,
  MenuOption,
  MenuVariant,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import React, { memo } from 'react'
import { WithEntry } from '../../../../utils/questionnaireUtils'
import useStyles from './QuestionCardFooter.styles'

interface Props extends WithEntry {
  addButtonText?: string
  showAddScale?: boolean
  rankChoiceRequisite?: JSX.Element
  multiSelectChoiceLimit?: JSX.Element
  onAddScale?: () => void
  onClickAddResponseOption: () => void
}

const QuestionCardFooter: React.FC<Props> = (props: Props) => {
  const {
    addButtonText = 'Add response',
    showAddScale = false,
    rankChoiceRequisite = null,
    multiSelectChoiceLimit = null,
    onAddScale,
    onClickAddResponseOption
  } = props

  const { classes, cx: classNames } = useStyles()

  const scaleResponsesMenuOptions: MenuOption[] = [
    {
      id: 0,
      options: [
        {
          text: 'Agree (1-5)',
          onClick: () => {
            if (onAddScale) {
              onAddScale()
            }
          }
        }
      ]
    }
  ]

  return (
    <div
      className={classNames(
        'fd-grid',
        'fd-container',
        'fd-row',
        'fd-justify-content-between',
        'fd-align-items-center',
        classes.root
      )}
    >
      <div className="fd-grid fd-item fd-xs">
        <div
          className={classNames('fd-grid', 'fd-container', classes.addButton)}
        >
          <LinkButton
            highlight={TextHighlight.Emphasis}
            size={TextSize.m}
            weight={TextWeight.SemiBold}
            onClick={onClickAddResponseOption}
            noPadding
          >
            {addButtonText}
          </LinkButton>
          {showAddScale && (
            <div
              className={classNames(
                'fd-grid',
                'fd-item',
                'fd-xs-auto',
                classes.addScale
              )}
            >
              <Menu
                menuOptions={scaleResponsesMenuOptions}
                title="Replace with scale"
                variant={MenuVariant.SecondaryLink}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('fd-grid', 'fd-item', {
          'fd-xs': !!multiSelectChoiceLimit || !!rankChoiceRequisite
        })}
      >
        {multiSelectChoiceLimit}
        {rankChoiceRequisite}
      </div>
    </div>
  )
}

export default memo(QuestionCardFooter)
