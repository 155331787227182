import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  ForkTag,
  LogicPropositionType,
  ReplaceQuestionLogicInputLogicInput,
  ReplaceQuestionLogicInputPropositionInput
} from '../../../data/gql-gen/questionnaire/graphql'
import { EntryType } from '../../../data/model/questionnaire'
import {
  ClauseBlock,
  Operator,
  hasDisplayLogic
} from '../../../utils/questionLogic'
import { entryAsUnion } from '../../../utils/questionnaireUtils'
import { useDraftQuestionnaireEntry } from '../useDraftQuestionnaireEntry'
import { useUpdateQuestionnaireEntry } from '../useUpdateQuestionnaireEntry'

const buildQuestionLogic = (
  clauseBlocks: ClauseBlock[],
  operator: Operator
) => {
  const logic: ReplaceQuestionLogicInputLogicInput[][] = []

  for (const block of clauseBlocks) {
    if (block.questionLk && block.propositionEntry) {
      let group: ReplaceQuestionLogicInputLogicInput[]

      const propositionEntry = entryAsUnion(block.propositionEntry)

      switch (operator) {
        case Operator.Or:
          group = logic.at(-1) ?? []
          logic[Math.max(0, logic.length - 1)] = group
          break

        case Operator.And:
          group = []
          logic.push(group)
      }

      for (const responseOptionLk of block.propositionResponseOptionLks) {
        let propositionType: LogicPropositionType
        let proposition: ReplaceQuestionLogicInputPropositionInput

        switch (propositionEntry.entryType) {
          case EntryType.QuestionEntryType: {
            propositionType = LogicPropositionType.QuestionResponseOptionType
            proposition = {
              questionResponseOption: {
                questionLk: propositionEntry.entryItem.questionLk,
                responseOptionLk
              }
            }
            break
          }

          case EntryType.MatrixEntryType:
            {
              propositionType = LogicPropositionType.MatrixResponseOptionType
              proposition = {
                matrixResponseOption: {
                  matrixTitleLk: propositionEntry.entryItem.matrixTitleLk,
                  questionLk: block.questionLk,
                  responseOptionLk
                }
              }
            }
            break

          default:
            throw new Error(
              `Cannot build proposition for entry type ${propositionEntry.entryType}`
            )
        }

        group.push({
          propositionType,
          proposition,
          negated: block.negated,
          propositionRef: {
            propositionId: uuidv4(),
            clauseNumber: logic.length - 1
          }
        })
      }
    }
  }

  return logic
}

const useQuestionLogic = (entryId: string) => {
  const [loading, setLoading] = useState(false)
  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entryId)
  const entry = useDraftQuestionnaireEntry(entryId)

  const removeLogic = async () => {
    if (entry && hasDisplayLogic(entry)) {
      await updateQuestionnaireEntry({
        forks: [],
        logic: []
      })
    }
  }

  const setQuestionLogic = async (
    clauseBlocks: ClauseBlock[],
    operator: Operator,
    forkTags: ForkTag[]
  ) => {
    setLoading(true)

    await updateQuestionnaireEntry({
      forks: forkTags,
      logic: buildQuestionLogic(clauseBlocks, operator)
    })

    setLoading(false)
  }
  return {
    setQuestionLogic,
    removeLogic,
    loading
  }
}

export default useQuestionLogic
