import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  dividerGrid: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  paperContainer: {
    marginTop: theme.spacing(4)
  },
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  countryContainer: {
    marginTop: `-${theme.spacing(1.25)}`
  },
  languageContainer: {
    marginBottom: theme.spacing(1)
  }
})

export default useStyles
