import { defaultOption, defaultRankChoiceRequisiteOption } from './constants'

export const dropDownOptions = (length: number) =>
  Array.from({ length }, (_, index) => {
    if (index === 0) {
      return defaultOption
    }
    return {
      id: index.toString(),
      name: `${index} response limit`,
      value: index
    }
  }).filter((option) => option.value !== 1)

// We filter out the 2nd option as we don't want to show 'rank 1'
export const rankChoiceRequisiteOptions = (length: number) =>
  Array.from({ length }, (_, index) => {
    if (index === 0) {
      return defaultRankChoiceRequisiteOption
    }
    return {
      id: index.toString(),
      name: `Top ${index} ranked`,
      value: index
    }
  }).filter((option) => option.value !== 1)
