import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  linkButton: {
    padding: theme.spacing(2, 0, 0, 0)
  },
  subtitleContainer: {
    paddingBottom: theme.spacing(2.5)
  },
  errorText: {
    marginTop: theme.spacing(0.4),
    color: theme.palette.error.main,
    maxWidth: theme.spacing(42)
  }
})

export default useStyles
