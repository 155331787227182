import { KebabMenuOption } from '@focaldata/cin-ui-components'
import { useContext } from 'react'
import useDownloadSurveyDoc from '../../../../hooks/useDownloadSurveyDoc'
import QuestionnaireContext, {
  openImportDialog
} from '../../../../modules/Questionnaire/Questionnaire.context'
import { isFocaldataUser } from '../../../../utils/authUtils'
import { ActionsMenu } from './ActionsMenu.control'

const ActionsMenuContainer = (): JSX.Element => {
  const { exportSurvey } = useDownloadSurveyDoc()

  const { dispatch } = useContext(QuestionnaireContext)

  const handleOpenImportDialog = () => {
    dispatch(openImportDialog())
  }

  const actionMenuOptions: KebabMenuOption[] = [
    {
      id: 0,
      textItem: 'Export survey (.doc)',
      onClickItem: exportSurvey
    }
  ]

  if (isFocaldataUser()) {
    actionMenuOptions.push({
      id: 1,
      textItem: 'Import survey (.docx)',
      onClickItem: handleOpenImportDialog
    })
  }

  return <ActionsMenu actionMenuOptions={actionMenuOptions} />
}

export default ActionsMenuContainer
