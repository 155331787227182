const errorText = {
  notFound404: {
    errorCode: '404',
    title: "Oops, the page you're looking for does not exist.",
    subtitle: 'You may want to head back to your dashboard.',
    contactInfo:
      "If you think something is broken, report a problem by emailing support@focaldata.com. We'll get back to you immediately.",
    buttonText: 'Go to dashboard'
  },
  serverError503: {
    errorCode: '503',
    title: "Oops, the page you're looking for does not exist.",
    subtitle: 'You may want to head back to your dashboard.',
    contactInfo:
      "If you think something is broken, report a problem by emailing support@focaldata.com. We'll get back to you immediately."
  },
  notFound404Public: {
    errorCode: '404',
    title: "Oops, the survey you're looking for does not exist.",
    subtitle: 'You may want to head back to your dashboard.',
    contactInfo:
      "If you think something is broken, report a problem by emailing support@focaldata.com. We'll get back to you immediately.",
    buttonText: 'Go to focaldata'
  }
}

export default errorText
