import {
  LinkButton,
  TextHighlight,
  TextWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import groupBy from 'lodash/groupBy'
import React from 'react'
import { useAppSelector } from '../../../App.store'
import { ForkTag } from '../../../data/gql-gen/questionnaire/graphql'
import {
  DraftLogicClauseProposition,
  DraftQuestionnaireEntry,
  LogicPropositionType
} from '../../../data/model/questionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import {
  Operator,
  QuestionLogicTextData,
  getDefaultOperator,
  getMatrixLogicTextData,
  getQuestionLogicTextData
} from '../../../utils/questionLogic'
import {
  ResponseOptionsByQuestion,
  selectResponseOptionsByQuestion
} from '../Questionnaire.slice'
import LoopSpecificDisplayLogicHeader from './LoopBasedDisplayLogicHeader'
import useStyles from './styles'
import { getForkItem, questionTextTo } from './util'

interface Props {
  questionLogic: DraftLogicClauseProposition[][] | undefined
  forks: ForkTag[]
  entries: DraftQuestionnaireEntry[]
  entry: DraftQuestionnaireEntry | undefined
  onEditDisplayLogic?: () => void
  onRemoveDisplayLogic?: () => void
  onEditLoopBasedDisplayLogic?: () => void
  onRemoveLoopBasedDisplayLogic?: () => void
}

const getAndLogicItemFromEntry = (
  clause: DraftLogicClauseProposition[],
  entries: DraftQuestionnaireEntry[],
  responseOptionsByQuestion: ResponseOptionsByQuestion
): JSX.Element | null => {
  const lead = clause[0]
  let logicTextdata: QuestionLogicTextData | undefined
  switch (lead.propositionType) {
    case LogicPropositionType.QuestionResponseOptionType: {
      logicTextdata = getQuestionLogicTextData(
        clause,
        entries,
        responseOptionsByQuestion
      )
      break
    }
    case LogicPropositionType.MatrixResponseOptionType: {
      logicTextdata = getMatrixLogicTextData(
        clause,
        entries,
        responseOptionsByQuestion
      )
      break
    }
    default:
      return null
  }

  if (!logicTextdata) {
    return null
  }

  const negationText = lead.negated ? 'is NOT' : 'is'

  const { questionText, roTexts } = logicTextdata

  return questionTextTo({
    questionText,
    roTexts,
    negationText
  })
}

const DisplayLogicHeader: React.FC<Props> = (props: Props) => {
  const {
    questionLogic,
    forks,
    entries,
    entry,
    onEditDisplayLogic,
    onRemoveDisplayLogic,
    onEditLoopBasedDisplayLogic,
    onRemoveLoopBasedDisplayLogic
  }: Props = props
  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )

  const showSummary =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (questionLogic && questionLogic.length > 0) || (forks && forks.length > 0)

  const defaultOperator = getDefaultOperator(entry)

  const { validateQuestionLogic } = useQuestionnaireValidation()

  const hasLogicError = entry && validateQuestionLogic(entry.entryId)

  return (
    <>
      {!!entry?.number && !!entry.sectionId && (
        <LoopSpecificDisplayLogicHeader
          key="loop-based-display-logic-header"
          entryId={entry.entryId}
          sectionId={entry.sectionId}
          onEditDisplayLogic={onEditLoopBasedDisplayLogic}
          onRemoveDisplayLogic={onRemoveLoopBasedDisplayLogic}
        />
      )}
      {showSummary && (
        <div
          key="display-logic-header"
          className={classNames(
            'fd-grid',
            'fd-container',
            'fd-row',
            'fd-justify-content-between',
            classes.container,
            { [classes.error]: hasLogicError }
          )}
          data-testid="display-logic-header"
        >
          <div className="fd-grid fd-container fd-item fd-xs-10 fd-column">
            <p
              className={classNames(
                textClasses.default,
                textClasses.weightSemiBold
              )}
            >
              Display this question
            </p>
            {hasLogicError ? (
              <p className={classNames(textClasses.highlightError)}>
                Invalid display logic. Please add a source for display logic.
              </p>
            ) : (
              <>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {forks?.map((fork: ForkTag, index: number) => (
                  <div className="fd-grid fd-item" key={fork.forkId}>
                    {getForkItem(fork, entries)}
                    {index !== forks.length - 1 && (
                      <p
                        className={classNames(
                          textClasses.default,
                          textClasses.highlightBackground
                        )}
                      >
                        OR{' '}
                      </p>
                    )}
                    {index === forks.length - 1 &&
                      questionLogic &&
                      questionLogic.length > 0 && (
                        <p
                          className={classNames(
                            textClasses.default,
                            textClasses.highlightBackground
                          )}
                        >
                          AND
                        </p>
                      )}
                  </div>
                ))}
                {defaultOperator === Operator.And &&
                  questionLogic?.map(
                    (clause: DraftLogicClauseProposition[], index: number) => (
                      <div
                        className="fd-grid fd-item"
                        key={clause[0].propositionRef.clauseNumber}
                      >
                        {index !== 0 && (
                          <p
                            className={classNames(
                              textClasses.default,
                              textClasses.highlightBackground
                            )}
                          >
                            AND{' '}
                          </p>
                        )}
                        {getAndLogicItemFromEntry(
                          clause,
                          entries,
                          responseOptionsByQuestion
                        )}
                      </div>
                    )
                  )}
                {defaultOperator === Operator.Or &&
                  questionLogic?.map(
                    (clause: DraftLogicClauseProposition[]) => {
                      const questionPropositionsByPropositionLk = groupBy(
                        clause,
                        'proposition.questionLk'
                      )

                      return Object.keys(
                        questionPropositionsByPropositionLk
                      ).flatMap((lk, index) => {
                        const propositions =
                          questionPropositionsByPropositionLk[lk]
                        return (
                          <div className="fd-grid fd-item" key={lk}>
                            {index !== 0 && (
                              <p
                                className={classNames(
                                  textClasses.default,
                                  textClasses.highlightBackground
                                )}
                              >
                                OR{' '}
                              </p>
                            )}
                            {getAndLogicItemFromEntry(
                              propositions,
                              entries,
                              responseOptionsByQuestion
                            )}
                          </div>
                        )
                      })
                    }
                  )}
              </>
            )}
          </div>
          <div className="fd-grid fd-container fd-item fd-xs-2 fd-justify-content-flex-end fd-align-items-center">
            <div
              className={classNames(
                'fd-grid',
                'fd-item',
                'fd-xs-auto',
                classes.edit
              )}
            >
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.SemiBold}
                onClick={() => {
                  if (onEditDisplayLogic) {
                    onEditDisplayLogic()
                  }
                }}
              >
                Edit
              </LinkButton>
            </div>
            <div className="fd-grid fd-item fd-xs-auto">
              <LinkButton
                highlight={TextHighlight.Background}
                weight={TextWeight.SemiBold}
                onClick={() => {
                  if (onRemoveDisplayLogic) {
                    onRemoveDisplayLogic()
                  }
                }}
              >
                Remove
              </LinkButton>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DisplayLogicHeader
