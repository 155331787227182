import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  configContainer: {
    position: 'relative',
    marginTop: theme.spacing(8),
    '&:before': {
      content: '""',
      display: 'block',
      width: `calc(100% + ${theme.spacing(8)})`,
      position: 'absolute',
      top: theme.spacing(-4),
      left: theme.spacing(-4),
      borderTop: '1px solid #E5EAF5'
    },
    '& > ul': {
      maxHeight: 264,
      overflowY: 'auto'
    }
  },
  topDelimiter: {
    '&:before': {
      content: '""',
      display: 'block',
      width: `calc(100% + ${theme.spacing(8)})`,
      position: 'relative',
      top: theme.spacing(-2),
      left: theme.spacing(-4),
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(4)
    }
  },
  iterationAndLoopBasis: {
    height: theme.spacing(3),
    marginTop: theme.spacing(2.5),
    '& svg': {
      marginTop: 0
    }
  },
  marginRight8: {
    marginRight: 8
  },
  infoIcon: {
    position: 'relative',
    top: theme.spacing(0.5)
  },
  staticLoopingSection: {
    marginTop: theme.spacing(1)
  }
})

export default useStyles
