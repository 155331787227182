import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  selectContainer: {
    width: theme.spacing(37.5)
  },
  checkboxPostalCodeFull: {
    marginLeft: theme.spacing(1)
  },
  selectContainerError: {
    borderWidth: theme.spacing(0.125),
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
    borderRadius: theme.spacing(0.25)
  },
  responseRange: {
    position: 'relative',
    paddingBottom: theme.spacing(2)
  },
  responseRangeContainer: {
    paddingBottom: theme.spacing(2)
  },
  responseRangeSelectContainer: {
    width: theme.spacing(43.75)
  },
  responseRangeMaxContainer: {
    paddingLeft: theme.spacing(5)
  },
  infoIcon: {
    position: 'relative',
    top: theme.spacing(0.5)
  }
})

export default useStyles
