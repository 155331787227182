import { ApolloCache } from '@apollo/client'
import { produce } from 'immer'
import {
  Project,
  SurveyOrderStatus
} from '../../../data/gql-gen/dashboard/graphql'
import { UpdateSurveyOrderData } from '../../../data/gql/order/mutations/updateSurveyOrder'
import { SurveyOrder } from '../../../data/model/order'

export const CompleteOrderCacheUpdate: (
  cache: ApolloCache<UpdateSurveyOrderData>,
  selectedProject: Project | undefined
) => void = (cache) => {
  cache.modify({
    fields: {
      surveyOrder(existing) {
        return produce(existing, (draft: SurveyOrder) => {
          draft.status = SurveyOrderStatus.Completed
        })
      }
    }
  })
}
