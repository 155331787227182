import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSurveyResult from '../../../apollo/localState/useSurveyResult'
import CompleteChartList from '../../../controls/CompleteChartList'
import { Filters, SearchResultEntry } from '../../../controls/Filters'
import QuotaCards from '../../../controls/QuotaCards'
import { ProjectType } from '../../../data/gql-gen/dashboard/graphql'
import { ChartEntry, QuestionKind } from '../../../data/model/chart'
import { ProjectMetadata, SelectedFilter } from '../../../data/model/results'
import { SurveyParams } from '../../../data/model/surveyParams'
import { usePipingMarkerTransformer } from '../../../hooks/usePipingMarkerTransformer'
import ResultsPageLayout from '../../../layouts/ResultsPageLayout'
import { chain } from '../../../utils/lodashChain'
import {
  constructSearchableFilterOptions,
  getDefaultFilterOptions,
  getSelectedFilterCount,
  orderSelectedFilterOptions,
  replaceCompletesOnWeightedData
} from '../../../utils/resultsUtils'
import { usePublicFilters } from './PublicResultsContent.hooks'
import PublicResultsOptionsContainer from './PublicResultsOptions/PublicResultsOptions.container'

interface Props {
  loadingMetadata: boolean
  projectMetadata: ProjectMetadata | undefined
  summaryData: ChartEntry[]
  summaryLoading: boolean
  showWeightedData: boolean
  selectedFilters: SelectedFilter[]
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilter[]>>
}

const PublicResultsContainer: React.FC<Props> = ({
  loadingMetadata,
  projectMetadata,
  summaryData,
  summaryLoading,
  showWeightedData,
  selectedFilters,
  setSelectedFilters
}: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const [showQuotas, setShowQuotas] = useState<boolean>()

  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    SearchResultEntry[]
  >([])

  const { data: filterOptionsData, loading: loadingFilters } = usePublicFilters(
    { surveyId }
  )
  const { getCardTitleDisplayView } = usePipingMarkerTransformer(summaryData)
  const sortedEntries = useMemo(
    () =>
      chain(summaryData)
        .sortBy('entryPosition')
        .partition({ questionKind: QuestionKind.Audience })
        .value()
        .flat()
        .map((entry) => ({
          ...entry,
          ...replaceCompletesOnWeightedData(entry, showWeightedData),
          text: getCardTitleDisplayView(entry.text),
          textStyling: getCardTitleDisplayView(entry.textStyling ?? '')
        })),
    [summaryData, getCardTitleDisplayView, showWeightedData]
  )

  useEffect(() => {
    if (filterOptionsData.length > 0) {
      setSelectedFilterOptions(getDefaultFilterOptions(filterOptionsData))
    }
  }, [filterOptionsData])
  const { writeSurveyResult } = useSurveyResult()

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!projectMetadata || !summaryData) {
    return null
  }

  const isMultiMarket = projectMetadata.projectType === ProjectType.MultiMarket
  const surveyHasQuotas =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    projectMetadata.quotas && projectMetadata.quotas.length > 0

  writeSurveyResult(sortedEntries)

  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const quotaResultsAndType = projectMetadata.quotas?.map((quotaResult) => {
    return {
      ...quotaResult,
      type: summaryData.find((sdEntity) => sdEntity.id === quotaResult.id)
        ?.questionType
    }
  })
  const filterCount = getSelectedFilterCount(selectedFilters)
  const searchableChartEntries =
    constructSearchableFilterOptions(filterOptionsData)
  const entryFilterOptions = orderSelectedFilterOptions(selectedFilterOptions)

  return (
    <ResultsPageLayout
      isLoading={summaryLoading || loadingFilters || loadingMetadata}
      optionsBar={
        <PublicResultsOptionsContainer
          isMultiMarket={isMultiMarket}
          projectMetadata={projectMetadata}
          disableShowQuotas={!surveyHasQuotas}
          setShowQuotas={setShowQuotas}
        />
      }
      resultCards={
        <>
          {showQuotas && (
            <QuotaCards
              quotaResults={quotaResultsAndType}
              quotaResultsLoading={loadingMetadata}
              areQuotasApplied={surveyHasQuotas}
            />
          )}
          <CompleteChartList
            chartData={sortedEntries}
            isLoading={summaryLoading}
            showAudienceCriteriaQuestions
          />
        </>
      }
      filters={
        <Filters
          isLoading={loadingFilters}
          isPublic
          filterCount={filterCount}
          searchableChartEntries={searchableChartEntries}
          entryFilterOptions={entryFilterOptions}
          selectedFilterOptions={selectedFilterOptions}
          setSelectedFilterOptions={setSelectedFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      }
    />
  )
}

export default PublicResultsContainer
