import { gql } from '@apollo/client/core'

export const DRAFT_LOOPING_CONFIG = gql`
  fragment DraftLoopingConfig on DraftLoopingConfig {
    maxIterations
    loopBasis
    sourceEntryNumber
    loopRandomisationEnabled
    prioritizedResponseOptionIds
    matrixLoopingConfig {
      qualifyingResponseOptionIds
      sourceMatrixRowQuotas {
        questionId
        quotaTarget
      }
    }
    loopSpecificDisplayLogics {
      entryNumber
      sourceIds
    }
  }
`

export default DRAFT_LOOPING_CONFIG
