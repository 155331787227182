import { useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import {
  DraftEntryResponseOption,
  EntryType,
  SettingInputInput,
  UpdateResponseOptionV2Input
} from '../../../../data/gql-gen/questionnaire/graphql'
import { CREATE_RESPONSE_OPTION } from '../../../../data/gql/questionnaire/mutations/createResponseOption'
import { REMOVE_RESPONSE_OPTION_WITH_SETTINGS_UPDATE } from '../../../../data/gql/questionnaire/mutations/removeEntryResponseOption'
import { UPDATE_RESPONSE_OPTION } from '../../../../data/gql/questionnaire/mutations/updateResponseOption'
import { LoggerErrorType } from '../../../../data/logger'
import {
  DraftMatrixItem,
  DraftQuestionItem,
  DraftQuestionnaireEntry,
  PositionTextSelection,
  QuestionKind,
  QuestionSettingCode
} from '../../../../data/model/questionnaire'
import useCopyPasteComplete from '../../../../hooks/copyPaste/useCopyPasteComplete'
import { useDraftQuestionnaireEntry } from '../../../../hooks/questionnaire/useDraftQuestionnaireEntry'
import useGetDraftQuestionnaire from '../../../../hooks/questionnaire/useGetDraftQuestionnaire'
import { useProjectId } from '../../../../hooks/useProjectId'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { chain } from '../../../../utils/lodashChain'
import {
  checkIfMultipleChoice,
  getChoiceIntervalMax,
  getChoiceIntervalMin,
  getChoiceLimit
} from '../../../../utils/questionnaireUtils'
import {
  responseOptionCreated,
  responseOptionRouteSet,
  responseOptionRouteUnset,
  selectResponseOptionsByQuestion
} from '../../Questionnaire.slice'
import { flattenEntries } from '../../Questionnaire.utils'
import { getRoutingMenuItemText } from './ResponseOptions.utils'

export const useResponseOptionActions = (entry: DraftQuestionnaireEntry) => {
  const entryId = entry.id
  const entryItem = entry.entryItem as DraftQuestionItem
  const { questionLk } = entryItem
  const { pasteToResponseOption } = useCopyPasteComplete(questionLk)

  const pasteInResponseOption = useCallback(
    async (
      text: string,
      position: number,
      positionSelectedText: PositionTextSelection
    ) => {
      questionBeingEditedId(entryId)
      await pasteToResponseOption(
        text,
        questionLk,
        position,
        positionSelectedText
      )
    },
    [questionLk, entryId, pasteToResponseOption]
  )

  return {
    pasteInResponseOption
  }
}

export const useRemoveResponseOptionWithSettingsUpdate = (entryId: string) => {
  const projectId = useProjectId()
  const entry = useDraftQuestionnaireEntry(entryId)
  const responseOptions = useResponseOptions(entryId)
  const { refetchQuestionnaire } = useGetDraftQuestionnaire()

  if (!entry) {
    throw new Error(
      'Entry not found. cannot use useRemoveResponseOptionWithSettingsUpdate'
    )
  }

  const isQuestionOrMatrixEntry = [
    EntryType.QuestionEntryType,
    EntryType.MatrixEntryType
  ].includes(entry.entryType)

  if (!isQuestionOrMatrixEntry) {
    throw new Error(
      'Entry is not a question or matrix entry. useRemoveResponseOptionWithSettingsUpdate should only be used for questions with response options'
    )
  }

  const entryItem = entry.entryItem as DraftQuestionItem | DraftMatrixItem

  const responseOptionsCountAfterDeletion = responseOptions.length - 1

  const intervalMin = getChoiceIntervalMin(entryItem)
  const intervalMax = getChoiceIntervalMax(entryItem)
  const choiceLimitSettingValue = getChoiceLimit(entryItem)
  let updatedSettings: SettingInputInput[] | undefined

  // If interval settings exist, calculate the new interval after deletion
  if (intervalMin !== undefined && intervalMax !== undefined) {
    // calculate new intervals after deletion such that min and max belong to [0..responseOptionsCountAfterDeletion] range
    const newIntervalMin =
      intervalMin < responseOptionsCountAfterDeletion ? intervalMin : 0
    const newIntervalMax = Math.min(
      intervalMax,
      responseOptionsCountAfterDeletion
    )

    updatedSettings = entryItem.settingValues.map((setting) => {
      return {
        questionSettingCode: setting.code,
        settingValue: (() => {
          switch (setting.code) {
            case QuestionSettingCode.ChoiceIntervalMin:
              return newIntervalMin.toString()
            case QuestionSettingCode.ChoiceIntervalMax:
              return newIntervalMax.toString()
            default:
              return setting.value.toString()
          }
        })()
      }
    })
  } else if (
    // If choice limit is exceeded after deletion, remove the limit setting; limit setting makes sense only when it is less than number of response options, e.g. "select 2 out of 3 options"
    choiceLimitSettingValue &&
    responseOptionsCountAfterDeletion <= parseInt(choiceLimitSettingValue, 10)
  ) {
    updatedSettings = entryItem.settingValues
      .filter((setting) => {
        return setting.code !== QuestionSettingCode.ChoiceLimit
      })
      .map((setting) => {
        return {
          questionSettingCode: setting.code,
          settingValue: setting.value
        }
      })
  }

  const [removeResponseOptionMutation] = useMutation(
    REMOVE_RESPONSE_OPTION_WITH_SETTINGS_UPDATE,
    {
      context: { clientName: 'questionnaire' },
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'removeResponseOptionwithSettingsUpdateMutation',
          error
        )
      },
      onCompleted() {
        questionBeingEditedId(entry.id)
        refetchQuestionnaire()
      }
    }
  )

  const removeResponseOption = (responseOptionId: string) => {
    return removeResponseOptionMutation({
      variables: {
        removeResponseOptionInput: {
          projectId,
          entryId,
          responseOptionId
        },
        updateQuestionnaireEntryInput: {
          projectId,
          entryId,
          settingValues: updatedSettings
        }
      }
    })
  }

  return removeResponseOption
}

export const useCreateResponseOption = (entryId: string) => {
  const projectId = useProjectId()
  const entry = useDraftQuestionnaireEntry(entryId)
  const dispatch = useAppDispatch()

  const [createResponseOptionMutation] = useMutation(CREATE_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createResponseOptionMutation',
        error
      )
    }
  })

  const createResponseOption = (position: number) => {
    return createResponseOptionMutation({
      variables: {
        input: {
          projectId,
          entryId,
          position
        }
      },
      onCompleted: (data) => {
        dispatch(
          responseOptionCreated({
            questionLk: entryId,
            position,
            responseOption:
              data.createResponseOptionV2 as DraftEntryResponseOption
          })
        )
        questionBeingEditedId(entry?.id)
      },
      update: async (_, { data }) => {
        if (data) {
          responseOptionLkNewlyAdded(
            (data.createResponseOptionV2 as DraftEntryResponseOption)
              .responseOptionLk
          )
        }
      }
    })
  }

  return createResponseOption
}

export const useUpdateResponseOption = (entryId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { refetchQuestionnaire } = useGetDraftQuestionnaire()
  const entry = useDraftQuestionnaireEntry(entryId)

  const [updateResponseOptionMutation] = useMutation(UPDATE_RESPONSE_OPTION, {
    context: { clientName: 'questionnaire' },
    fetchPolicy: 'no-cache'
  })

  const updateResponseOption = (
    input: Omit<
      UpdateResponseOptionV2Input,
      'projectId' | 'surveyId' | 'entryId'
    >
  ) => {
    return updateResponseOptionMutation({
      variables: {
        input: {
          projectId,
          surveyId,
          entryId,
          ...input
        }
      },
      onCompleted: () => {
        questionBeingEditedId(entry?.id)
      },
      onError: (error) => {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'updateResponseOption',
          error
        )
        refetchQuestionnaire()
      }
    })
  }

  return updateResponseOption
}

export const useSetRouting = (entryId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const updateResponseOption = useUpdateResponseOption(entryId)
  const dispatch = useAppDispatch()
  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flattenAllEntries = flattenEntries(draftQuestionnaireEntries)

  const setRouting = (
    responseOptionId: string,
    targetNumber: number | null
  ) => {
    if (targetNumber === null) {
      dispatch(
        responseOptionRouteUnset({
          questionLk: entryId,
          responseOptionLk: responseOptionId,
          flattenAllEntries
        })
      )
      updateResponseOption({
        responseOptionId,
        route: null
      })
    } else {
      dispatch(
        responseOptionRouteSet({
          questionLk: entryId,
          responseOptionLk: responseOptionId,
          targetEntryNumber: targetNumber,
          flattenAllEntries
        })
      )
      updateResponseOption({
        responseOptionId,
        route: {
          value: targetNumber
        }
      })

      LogAmplitudeEvent(EventType.AddedSkipLogic, {
        projectId,
        surveyId,
        entryId,
        responseOptionId
      })
    }
  }

  return setRouting
}

export const useResponseOptions = (entryId: string) => {
  const responseOptionsByQuestion = useAppSelector(
    selectResponseOptionsByQuestion
  )
  return useMemo(
    // responseOptionsByQuestion is set asynchronously, so we need to check if it is already filled with data, otherwise we'll have undefined and error which is not handled by typecheck
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => responseOptionsByQuestion[entryId] ?? [],
    [entryId, responseOptionsByQuestion]
  )
}

export const useFilteredRoutingMenuItems = (
  flatEntries: DraftQuestionnaireEntry[] | undefined,
  currentEntry: DraftQuestionnaireEntry
) => {
  const responseOptions = useResponseOptions(currentEntry.id)
  const currentEntryExistingRouting = responseOptions.find((ro) => ro.route)
  const isMultipleChoice = checkIfMultipleChoice(
    (currentEntry.entryItem as DraftQuestionItem).settingValues
  )
  return useMemo(
    () =>
      chain(flatEntries)
        .filter(({ questionKind, number, position, entryType }) => {
          const isQuestionAfterCurrentEntry =
            questionKind === QuestionKind.QuestionnaireKind &&
            position > currentEntry.position &&
            entryType !== EntryType.ForkEntryType

          // only show the the existing routing menu item for multi-select questions when routing exists in siblings
          return isMultipleChoice && !!currentEntryExistingRouting
            ? isQuestionAfterCurrentEntry &&
                number === currentEntryExistingRouting.route?.targetNumber
            : isQuestionAfterCurrentEntry
        })
        .map((menuEntry) => ({
          id: menuEntry.number.toString(),
          text: getRoutingMenuItemText(menuEntry),
          number: menuEntry.number,
          entryType: menuEntry.entryType,
          position: menuEntry.position,
          contextPosition: menuEntry.contextPosition
        }))
        .value(),
    [
      currentEntry.position,
      currentEntryExistingRouting,
      flatEntries,
      isMultipleChoice
    ]
  )
}
