import { questionnaireGql } from '../../../data/gql-gen/questionnaire'

export const MatrixQuestionScreeningDialog_DraftMatrixItemFragment =
  questionnaireGql(/* GraphQL */ `
    fragment MatrixQuestionScreeningDialog_DraftMatrixItemFragment on DraftMatrixItem {
      matrixTitleLk
      matrixRows {
        questionLk
      }
      responseOptions {
        responseOptionLk
        responseOption {
          value
        }
      }
      matrixQualification {
        qualifyingOptions
        matrixRowQualifyingCondition {
          numberOfQualifyingRows
        }
      }
    }
  `)
