import {
  Grid,
  Input,
  InputWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo, useEffect, useState } from 'react'
import { PositiveIntegerNumberFormatCustom } from '../../../utils/NumberFormatCustom'
import useStyles from './CompletesInput.styles'

interface Props {
  defaultCompletes: number | undefined
  disabled: boolean
  inputLabel?: string
  saveCompletes: (completes: number | undefined) => void
  fullWidth?: boolean
}

const getCompletes: (completes: string) => number | undefined = (completes) => {
  return completes === '' ? undefined : parseInt(completes, 10)
}

const CompletesInput: React.FC<Props> = (props: Props) => {
  const {
    defaultCompletes,
    disabled,
    inputLabel = 'Completes:',
    saveCompletes
  } = props

  const [completes, setCompletes] = useState(defaultCompletes)

  useEffect(() => {
    setCompletes(defaultCompletes)
  }, [defaultCompletes])

  const { classes } = useStyles()
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const handleSaveCompletes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCompletes = getCompletes(event.target.value)
    if (newCompletes) {
      setCompletes(newCompletes)
    }
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    saveCompletes?.(newCompletes)
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <p
        className={classNames(
          textClasses.default,
          textClasses.marginRight,
          textClasses.sizeMs
        )}
      >
        {inputLabel}
      </p>
      <Input
        ariaLabel="Completes"
        onBlur={handleSaveCompletes}
        disabled={disabled}
        inputWeight={InputWeight.SemiBold}
        value={completes}
        maxValueLength={5}
        className={classes.input}
        dataCy="subheader-project-wanted-completes"
        customFormat={PositiveIntegerNumberFormatCustom}
      />
    </Grid>
  )
}

export default memo(CompletesInput)
