import dayjs from 'dayjs'
import { getStartAndEndDatesFormatted } from '../../../containers/Page/Survey/Confirmation/Confirmation.utils'
import { Increments } from '../../../data/gql-gen/questionnaire/graphql'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useGetFeasibilityEstimateData } from '../../../hooks/useGetFeasibilityEstimateData'
import { useSurveyId } from '../../../hooks/useSurveyId'
import IncrementalFieldworkControl from './IncrementalFiedlwork.control'

const IncrementalFieldworkContainer: React.FC = () => {
  const surveyId = useSurveyId()

  const { fieldwork } = useFieldwork()
  const { estimatedDeliveryTime } = useGetFeasibilityEstimateData()

  const incrementalFieldwork = fieldwork?.samplesNeededIncreaseFrequency
  const startDate = fieldwork?.duration.startDate.toString()
  const { formattedEndDate } = getStartAndEndDatesFormatted(
    startDate,
    estimatedDeliveryTime
  )
  const minimumEndDate = dayjs(formattedEndDate).toDate()

  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const infoText =
    'This allows you to collect your sample size incrementally over a period of days or weeks (inclusive). Your total sample size will be divided and collected over the number of days or weeks you have specified. The date you would like the increments to end cannot be before the estimated completion date of the project.'

  const handleIncrementalFieldworkSelection: (
    increments: Increments | null
  ) => void = (increments: Increments | null) => {
    updateFieldworkV2({
      variables: {
        input: {
          surveyId,
          panelSupplierCode: 'cint',
          increments: {
            value: increments
          },
          softLaunch: {
            samplesNeeded: 0
          }
        }
      }
    })
  }

  const handleEndDateChange: (endDate: Date) => void = (endDate) => {
    updateFieldworkV2({
      variables: {
        input: {
          surveyId,
          panelSupplierCode: 'cint',
          endDate: endDate,
          startDate: new Date()
        }
      }
    })
  }

  return (
    <IncrementalFieldworkControl
      onIncrementalFieldworkSelection={handleIncrementalFieldworkSelection}
      incrementalFieldwork={incrementalFieldwork}
      minimumEndDate={minimumEndDate}
      endDate={fieldwork?.duration.endDate}
      onEndDateChange={handleEndDateChange}
      infoText={infoText}
    />
  )
}

export default IncrementalFieldworkContainer
