import { useMutation } from '@apollo/client'
import { PauseOrderCacheUpdate } from '../../../apollo/cacheModify/Project/pauseOrder'
import { PauseSurveyCacheUpdate } from '../../../apollo/cacheModify/Project/pauseSurvey'
import {
  Project,
  SurveyOrderStatus,
  SurveyState,
  UpdateSurveyOrderMutationVariables
} from '../../../data/gql-gen/dashboard/graphql'
import {
  UPDATE_SURVEY_ORDER,
  UpdateSurveyOrderData
} from '../../../data/gql/order/mutations/updateSurveyOrder'
import { LoggerErrorType } from '../../../data/logger'
import { captureApolloError } from '../../../utils/HelperFunctions'
import { useUpdateSurvey } from '../../Survey/Survey.hooks'
import DialogPauseProject from './DialogPauseProject'

interface Props {
  isOpen: boolean
  toggleIsOpen: (open: boolean) => void
  selectedProject?: Project
}

const PauseProject = ({ isOpen, toggleIsOpen, selectedProject }: Props) => {
  const { updateSurvey } = useUpdateSurvey()

  const isLive: boolean =
    (selectedProject &&
      selectedProject.surveys[0].state === SurveyState.Live) ||
    false

  const handleUpdateSurvey: () => void = () => {
    if (selectedProject) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const surveyId = selectedProject?.surveys[0].surveyId
      updateSurvey({
        variables: {
          surveyId,
          state: isLive ? SurveyState.Paused : SurveyState.Live
        },
        update: (cache) => {
          PauseSurveyCacheUpdate(cache, selectedProject, isLive)
        }
      })
    }
  }

  const [updateSurveyOrder] = useMutation<
    UpdateSurveyOrderData,
    UpdateSurveyOrderMutationVariables
  >(UPDATE_SURVEY_ORDER, {
    context: { clientName: 'dashboard' },
    update: (cache) => {
      PauseOrderCacheUpdate(cache, isLive)
    },
    onCompleted: () => {
      handleUpdateSurvey()
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'updateSurveyOrder',
        error
      )
    }
  })

  const handleUpdateSurveyOrder: () => void = () => {
    if (selectedProject) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const surveyId = selectedProject?.surveys[0].surveyId
      updateSurveyOrder({
        variables: {
          surveyId,
          orderStatus: isLive
            ? SurveyOrderStatus.Paused
            : SurveyOrderStatus.Live
        }
      })
    }
    toggleIsOpen(false)
  }

  return (
    <DialogPauseProject
      isLive={isLive}
      isOpen={isOpen}
      toggleOpenDialog={toggleIsOpen}
      onPauseProject={handleUpdateSurveyOrder}
    />
  )
}

export default PauseProject
