import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client'
import { SearchCategoriesV2Query } from '../../data/gql-gen/questionnaire/graphql'
import { DraftAudience } from '../../data/gql/questionnaire/queries/audiences'
import { SEARCH_AUDIENCE_CATEGORIES } from '../../data/gql/questionnaire/queries/searchCategories'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'
import useGetDraftAudience from './useGetDraftAudience'

interface Data {
  searchCategories: SearchCategoriesV2Query['searchCategories']
  loadingSearchAudience: boolean
  audienceQuestions: DraftAudience | undefined
  refetchCategories: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<SearchCategoriesV2Query>>
}

const useStandardAudienceListGqlOperations = (searchCriteria: string): Data => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const {
    data: audienceCategories,
    refetch: refetchCategories,
    loading: loadingSearchAudience
  } = useQuery(SEARCH_AUDIENCE_CATEGORIES, {
    context: { clientName: 'questionnaire' },
    variables: {
      projectId,
      surveyId,
      searchCriteria
    },
    skip: !searchCriteria,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const { draftAudience: audienceQuestions } = useGetDraftAudience({
    notifyOnNetworkStatusChange: true
  })

  return {
    searchCategories: audienceCategories?.searchCategories,
    loadingSearchAudience,
    audienceQuestions,
    refetchCategories
  }
}

export default useStandardAudienceListGqlOperations
