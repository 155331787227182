import { Theme, makeStyles } from '@focaldata/cin-ui-components'

const combinedHeaderHeight = 97
const combinedHeaderHeightOneSubheader = 49
const footerHeight = 56

interface StyleProps {
  isQuestionnairePage?: boolean
}

export const useStyles = makeStyles<{ styleProps: StyleProps }>()(
  (theme: Theme, { styleProps: props }) => ({
    contentWithTwoSubheaders: {
      height: `calc(100vh - ${combinedHeaderHeight}px - ${footerHeight}px)`,
      overflowY: props.isQuestionnairePage ? 'hidden' : 'auto'
    },
    contentWithOneSubheader: {
      height: `calc(100vh - ${combinedHeaderHeightOneSubheader}px - ${footerHeight}px)`,
      overflowY: 'auto'
    },
    contentWithOneSubheaderNoFooter: {
      height: `calc(100vh - ${combinedHeaderHeightOneSubheader}px)`
    }
  })
)
