import {
  Input,
  InputVariant,
  Select,
  SelectIconColor,
  SelectVariant,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { ChangeEvent, useState } from 'react'
import { ResponseOptionsLogic } from '../../../data/gql-gen/fieldwork/graphql'
import { useDebounceEffect } from '../../../hooks/useDebounce'
import { NumberFormatCustom } from '../../../utils/NumberFormatCustom'
import { useCustomCriterionValidationErrors } from '../AudienceQuestionCard/useCustomCriterionValidationErrors'
import { useCustomCriterionOptionLogicActions } from './CustomCriterionOptionLogic.hooks'
import { useLoopBasisOptions } from './useLoopBasisOptions'
import { isLoopQuotaPriorityCondition } from './useLoopSourceMatches'

export const MaxDisplayedOptionsSettings = ({
  questionLk,
  responseOptionsLogic
}: {
  questionLk: string
  responseOptionsLogic: ResponseOptionsLogic
}) => {
  const loopBasisOptions = useLoopBasisOptions()
  const {
    hasInvalidResponseOptionsToDisplayError,
    hasNoLoopBassisSelectedError
  } = useCustomCriterionValidationErrors(questionLk)

  const [maxDisplayedOptionsValue, setMaxDisplayedOptionsValue] = useState(
    responseOptionsLogic.maxResponseOptionsToDisplay
  )
  const [loopBasisOptionValue, setLoopBasisOptionValue] = useState(
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    isLoopQuotaPriorityCondition(responseOptionsLogic?.condition)
      ? responseOptionsLogic.condition.sectionId
      : loopBasisOptions[0].value
  )

  const setCustomCriterionOptionsLogic = useCustomCriterionOptionLogicActions()

  const triggerUpdateMaxDisplayedOptions = async () => {
    await setCustomCriterionOptionsLogic(questionLk, {
      maxResponseOptionsToDisplay: maxDisplayedOptionsValue
    })
  }

  useDebounceEffect(
    () => {
      triggerUpdateMaxDisplayedOptions()
    },
    maxDisplayedOptionsValue,
    { delay: 1000 }
  )

  const handleChangeMaxDisplayedOptionsValue = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const maxDisplayedOptionsValue = Number.parseInt(e.target.value, 10)
    setMaxDisplayedOptionsValue(maxDisplayedOptionsValue)
  }

  const handleChangeLoopBasisOption = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      e.target.value === 'Random'
        ? {
            setAsRandomised: true
          }
        : {
            loopQuotaPriority: {
              sectionId: e.target.value
            }
          }
    setLoopBasisOptionValue(e.target.value)
    await setCustomCriterionOptionsLogic(questionLk, value)
  }

  return (
    <>
      <Input
        type="number"
        value={maxDisplayedOptionsValue}
        onChange={handleChangeMaxDisplayedOptionsValue}
        variant={InputVariant.Outlined}
        width={50}
        isError={hasInvalidResponseOptionsToDisplayError}
        helperText=""
        customFormat={NumberFormatCustom}
      />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Text size={TextSize.ms} highlight={TextHighlight.Background}>
          using
        </Text>
      </div>
      <Select
        variant={SelectVariant.Outlined}
        minWidth={100}
        noWhiteSpaceAfterSelection
        hasError={hasNoLoopBassisSelectedError}
        textSize={TextSize.ms}
        selectedTextHighlight={TextHighlight.Standard}
        selectedTextWeight={TextWeight.SemiBold}
        options={loopBasisOptions}
        optionValue={loopBasisOptionValue}
        onChange={handleChangeLoopBasisOption}
        iconColor={SelectIconColor.Secondary}
      />
    </>
  )
}
