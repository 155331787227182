import { questionnaireGql } from '../../../gql-gen/questionnaire'
import { FieldworkAudienceCategoryWithOptions } from '../../../gql-gen/questionnaire/graphql'

export interface FieldworkSearchAudienceCategoryData {
  searchCategories: FieldworkAudienceCategoryWithOptions[]
}

export const SEARCH_AUDIENCE_CATEGORIES = questionnaireGql(/* GraphQL */ `
  query searchCategoriesV2(
    $projectId: ID!
    $surveyId: ID!
    $searchCriteria: String
  ) {
    searchCategories: searchCategoriesV2(
      projectId: $projectId
      surveyId: $surveyId
      searchCriteria: $searchCriteria
    ) {
      category {
        code
        name
        createdDate
      }
      standardCriteria {
        criterion {
          code
          name
          question {
            questionId
            text
            createdDate
          }
          createdDate
        }
        state
        options {
          code
          responseOption {
            responseOptionId
            rotype
            value
            createdDate
          }
          createdDate
          sinceDate
        }
      }
    }
  }
`)
