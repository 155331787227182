import { makeStyles, theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()({
  id: {
    paddingRight: theme.spacing(1.5)
  },
  questionType: {
    paddingRight: theme.spacing(1)
  },
  questionTypeText: {
    paddingRight: theme.spacing(2)
  },
  actionContainer: {
    margin: theme.spacing(0, -1.5, 0, 0)
  }
})

export default useStyles
