import { forwardRef } from 'react'
import CloseIcon from '../../../../../../../assets/close.svg'
import styles from './Modal.module.css'

const Modal = forwardRef<
  HTMLDialogElement,
  {
    children: React.ReactNode
    onCloseIconClick?: () => void
  } & React.DialogHTMLAttributes<HTMLDialogElement>
>(({ children, onCloseIconClick, ...rest }, ref) => {
  return (
    <dialog ref={ref} className={styles.modal} {...rest}>
      {onCloseIconClick && (
        <button onClick={onCloseIconClick} className={styles['close-button']}>
          <img src={CloseIcon} alt="Close modal" />
        </button>
      )}
      {children}
    </dialog>
  )
})

Modal.displayName = 'Modal'
export default Modal
