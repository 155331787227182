import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  highlight: {
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}))

export default useStyles
