import {
  Dialog,
  DialogMaxWidth,
  DialogVariant,
  Grid,
  Input,
  InputVariant,
  InputWeight,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import { memo } from 'react'

interface Props {
  countdownSeconds?: string
  savingDisabled: boolean
  onChange: (value: string) => void
  onClose: () => void
  onSave: () => void
}

const QuestionTimerDialogComponent = ({
  countdownSeconds,
  savingDisabled,
  onChange,
  onClose,
  onSave
}: Props) => {
  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()
  return (
    <Dialog
      open
      title="Set question timer"
      fullWidth
      maxWidth={DialogMaxWidth.Sm}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonDisabled={savingDisabled}
      primaryButtonClick={onSave}
      variant={DialogVariant.SingleButton}
    >
      <Grid display="flex" alignItems="center">
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.highlightEmphasis,
            textClasses.displayInline
          )}
        >
          Enable <strong>Next</strong> button after{' '}
        </p>
        <Grid marginX={1}>
          <Input
            value={countdownSeconds}
            variant={InputVariant.Outlined}
            inputWeight={InputWeight.SemiBold}
            width={55}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => !e.target.value && onChange('0')}
          />
        </Grid>
        <p
          className={classNames(
            textClasses.default,
            textClasses.sizeM,
            textClasses.highlightEmphasis,
            textClasses.displayInline
          )}
        >
          seconds
        </p>
      </Grid>
    </Dialog>
  )
}

export default memo(QuestionTimerDialogComponent)
