import { Respondent } from '@focaldata/askai-client'
import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import SourcesImg from '../../../../../../../assets/fdchat/results/sources.svg'
import { useFdChatProgress } from '../../../../../../../modules/FdChat/FdChatProgressProvider.context'
import { getUserImage } from '../../../../FdChat.utils'
import Avatar from '../Avatar/Avatar'
import styles from './SourcesCard.module.css'

const SourcesCard = ({
  loading,
  hasError,
  onSourcesCardClick,
  sources
}: {
  loading?: boolean
  hasError?: boolean
  onSourcesCardClick: () => void
  sources?: Respondent[]
}) => {
  const { loading: fdChatProgressLoading, interviewsCollected } =
    useFdChatProgress()
  const numberOfSourcesToShow = sources?.length
    ? Math.min(sources.length, interviewsCollected)
    : interviewsCollected

  return (
    <button
      className={styles['sources-card']}
      onClick={onSourcesCardClick}
      data-dd-action-name="Sources card"
      style={{ pointerEvents: loading || hasError ? 'none' : 'auto' }}
    >
      <div className={styles.header}>
        {loading ? (
          <CircularProgress size={'16px'} />
        ) : (
          <img src={SourcesImg} alt="" className={styles.icon} />
        )}
        <h2 className={styles.title}>Sources</h2>
        <div className={styles.avatars}>
          {loading || hasError ? (
            <Skeleton
              variant="rounded"
              width={108}
              height={24}
              animation={hasError ? false : 'pulse'}
            />
          ) : (
            <>
              {sources?.slice(0, 5).map((source, i) => (
                <Avatar
                  src={getUserImage({
                    userId: source.focaldata_respondent_id,
                    gender: source.gender
                  })}
                  initials={`P${i + 1}`}
                  style={{
                    border: '1px solid var(--silver)',
                    marginLeft: '-6px'
                  }}
                  width={24}
                  height={24}
                  key={source.focaldata_respondent_id}
                />
              ))}
              {numberOfSourcesToShow > 5 ? (
                <span className={styles['extra-sources']}>
                  +{numberOfSourcesToShow - 5}
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>
      <p className={styles.summary}>
        {loading || fdChatProgressLoading || hasError ? (
          <Skeleton
            sx={{ fontSize: '16px' }}
            width={'60%'}
            animation={hasError ? false : 'pulse'}
          />
        ) : (
          `We analysed ${interviewsCollected} interviews and found ${numberOfSourcesToShow} that were relevant to your question.`
        )}
      </p>
    </button>
  )
}

export default SourcesCard
