import { useApolloClient, useQuery } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import axios from 'axios'
import { useState } from 'react'
import { AUDIENCE_CHANGED_SINCE_LAST_SLACK_IRS_NOTIFICATION_QUERY } from '../apollo/localState/audienceChangedSinceLastSlackIRSNotification'
import { writeAudienceChangedSinceLastSlackIRSNotification } from '../apollo/localState/writeAudienceChangedSinceLastSlackIRSNotification'
import { CustomAudience } from '../data/model/irNotification'
import { isURL } from '../utils/HelperFunctions'
import UserSession from '../utils/UserSession'
import { AccessToken } from '../utils/authUtils'
import { getIRNotificationCustomAudience } from '../utils/irNotificationUtils'
import useGetDraftAudience from './audience/useGetDraftAudience'
import useDisplayedLOI from './localState/useDisplayedLOI'
import { useGetFeasibilityEstimateData } from './useGetFeasibilityEstimateData'
import usePollCostQuote from './usePollCostQuote'
import usePollIncidenceRate from './usePollIncidenceRate'
import { useSurveyId } from './useSurveyId'

const IR_SLACK_NOTIFICATION_ENDPOINT = `${
  process.env.REACT_APP_URL_DASHBOARD || '/v1/dashboard'
}/api/v1.0/adjustIRSlackNotification`

interface IRNotificationApiVars {
  accountId: string
  projectId: string
  incidenceRate: string
  cpi: string
  loi: number
  surveyId: string
  country: string
  userEmailAddress: string
  isFeasible: boolean
  screeningQuestions?: CustomAudience[]
}

const useIRSlackNotification = () => {
  const surveyId = useSurveyId()
  const accountId = UserSession.maybeGetAccountId()
  const [hasError, setHasError] = useState(false)
  const apolloClient = useApolloClient()
  const { data: apolloAudienceChangedQuery } = useQuery(
    AUDIENCE_CHANGED_SINCE_LAST_SLACK_IRS_NOTIFICATION_QUERY,
    { context: { clientName: 'local' } }
  )
  const customAudienceChangedSinceLastNotification =
    apolloAudienceChangedQuery?.audienceChangedSinceLastIRSNotification ?? false

  const { getIncidenceRate } = usePollIncidenceRate()
  const { isFeasible, country, lengthOfInterview } =
    useGetFeasibilityEstimateData()
  const { draftAudience } = useGetDraftAudience()
  const screeningQuestions = getIRNotificationCustomAudience(
    draftAudience?.members
  )

  const incidenceRate = getIncidenceRate().stringIncidenceRate
  const { displayedLOI } = useDisplayedLOI()
  const { getCostQuoteData } = usePollCostQuote(displayedLOI)
  const { costPerIncidence } = getCostQuoteData
  const cpi = costPerIncidence.toFixed(2) || '...'

  const postIRSlackNotification = () => {
    if (customAudienceChangedSinceLastNotification) {
      const postPayload = {
        accountId,
        surveyId,
        country,
        incidenceRate,
        cpi,
        loi: lengthOfInterview,
        userEmailAddress: UserSession.getEmail(),
        isFeasible,
        screeningQuestions
      }

      const axiosConfig = {
        headers: {
          ...AccessToken.requestHeaders(),
          'content-type': 'application/json'
        }
      }

      const requestUrl = `${
        isURL(IR_SLACK_NOTIFICATION_ENDPOINT)
          ? ''
          : process.env.REACT_APP_REST_DOMAIN
      }${IR_SLACK_NOTIFICATION_ENDPOINT}`

      axios
        .post<IRNotificationApiVars>(requestUrl, postPayload, axiosConfig)
        .then(() => {
          setHasError(false)
          writeAudienceChangedSinceLastSlackIRSNotification(
            apolloClient.cache,
            false
          )
        })
        .catch((error) => {
          datadogLogs.logger.error('Post IR slack notification failed', {
            error,
            postPayload
          })
          setHasError(true)
        })
    }
  }

  return { postIRSlackNotification, hasError }
}

export default useIRSlackNotification
